import type { ApolloError } from '@apollo/client'
import { getError, isFormErrorCode } from '@retire/utils'
import type { GraphQLError } from 'graphql'

import type { TErrors } from './types'

export const getErrors = <T extends Record<string, string> = Record<string, string>>(
  error: ApolloError | Error | Partial<TErrors>
): TErrors<T> => {
  let formErrors = {}
  if ('formErrors' in error) {
    formErrors = error.formErrors || {}
  }
  if ('graphQLErrors' in error && error.graphQLErrors) {
    error.graphQLErrors.forEach(({ extensions: { code }, path, message }: GraphQLError) => {
      const gqlErrorCode = (['string', 'undefined'].includes(typeof code) ? code : undefined) as string | undefined

      if (isFormErrorCode(gqlErrorCode) && path) {
        const key = path[path.length - 1]
        formErrors[key] = message
      }
    })
  }
  const generalError = !Object.keys(formErrors).length ? getError(error as ApolloError | Error) : undefined

  return {
    formErrors,
    generalError,
  }
}
