import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import type { TSegment } from './types'

const segmentStyles = css<TSegment>`
  ${({
    theme: {
      segment: { borderColor, borderRadius, borderWidth, paddingDesktop, backgroundColor, marginBottom, paddingTouch },
    },
    transparent,
    whiteBackground,
  }) => css`
    border-radius: ${borderRadius};
    background-color: ${whiteBackground ? backgroundColor : 'none'};

    ${!transparent &&
    `
      border-color: ${borderColor};
      border-width: ${borderWidth};
      border-style: solid;
    `}
    position: relative;

    margin: auto 0 ${transparent ? '0' : marginBottom};
    padding: ${paddingTouch};

    ${mediaStyles({ padding: paddingDesktop }, { min: Breakpoint.small })}
  `}
`

/** Segment component for blocks of content */
export const Segment = styled.div<TSegment>`
  ${() => segmentStyles}
`
