import { InMemoryCache } from '@apollo/client'
import { mergeObjects } from '@retire/utils'

const defaultTypePolicy = {
  merge: (existing = {}, incoming = {}) => mergeObjects(existing, incoming, true),
}

export const retireGQLCache = new InMemoryCache({
  typePolicies: {
    AdminFees: defaultTypePolicy,
    AllowanceWithYear: defaultTypePolicy,
    BankDetails: defaultTypePolicy,
    Beneficiary: defaultTypePolicy,
    CustomerService: defaultTypePolicy,
    Enrolment: defaultTypePolicy,
    EligibilityRequirements: defaultTypePolicy,
    FlexibleIncome: defaultTypePolicy,
    FlexibleIncomePotDetails: defaultTypePolicy,
    Holdings: defaultTypePolicy,
    Inheritance: defaultTypePolicy,
    LaterLife: defaultTypePolicy,
    LaterLifeAge: defaultTypePolicy,
    LifetimeAllowance: defaultTypePolicy,
    Plan: defaultTypePolicy,
    PotAmount: defaultTypePolicy,
    PotLocks: defaultTypePolicy,
    PotDetails: defaultTypePolicy,
    Projections: defaultTypePolicy,
    RainyDay: defaultTypePolicy,
    SentMessage: defaultTypePolicy,
    Settings: defaultTypePolicy,
    SuitabilityCheck: defaultTypePolicy,
    User: defaultTypePolicy,
    WithdrawalAmount: defaultTypePolicy,
  },
})
