import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import { Headline } from '../Typography'
import type { TNotification } from './types'

export const IconWrapper = styled.div`
  ${({
    theme: {
      notification: { iconSize },
      spacing,
    },
  }) => css`
    position: relative;
    min-width: ${iconSize.desktop};
    width: ${iconSize.desktop};
    height: ${iconSize.desktop};
    margin-right: ${spacing.medium};

    ${mediaStyles(
      { minWidth: iconSize.mobile, width: iconSize.mobile, height: iconSize.mobile },
      {
        max: Breakpoint.small,
      }
    )}

    svg {
      position: absolute;
      inset: 0;
      max-width: 100%;
      max-height: 100%;
    }
  `}
`

export const SNotification = styled.div<Pick<TNotification, 'hasBorder' | 'type' | 'id'>>`
  ${({ theme: { notification }, hasBorder, type }) => css`
    border-radius: ${notification.borderRadius};
    background-color: ${notification.color[type]};
    padding: ${notification.padding.desktop};

    ${mediaStyles({ padding: notification.padding.mobile }, { max: Breakpoint.small })}

    border: ${hasBorder ? `2px solid ${notification.border[type]}` : '0'};

    ${Headline} {
      color: ${notification.headlineColor};
    }

    display: flex;
  `}
`
