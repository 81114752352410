import { Currency } from '@retire/components/Formatter/Currency'
import { Label } from '@retire/components/Forms/hocs/withFormLabels'
import { PartialLoader } from '@retire/components/PartialLoader'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { PotName } from '@retire/constants/pots'
import { MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY } from '@retire/gql/projections/queries'
import { useEstimateIncomeModal } from '@retire/hooks/useEstimateIncomeModal'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { HeadlineLevel } from '@retire/theme/typography'
import { toPennies } from '@retire/utils/currency'
import { isLaterLifePotDataValid } from '@retire/utils/pots'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { IncomeValue, SLoadingContainer } from '../../../PotEstimatedMonthlyIncome/index.styled'
import type { TPotEstimatedMonthlyIncome } from '../../../PotEstimatedMonthlyIncome/types'

export const PotEstimatedMonthlyIncome: FC<TPotEstimatedMonthlyIncome> = ({ amount, startAge }) => {
  const { t } = useTranslationWithOptions('illustration')
  const { Modal, ModalLink } = useEstimateIncomeModal(PotName.laterLife)

  const skipLaterLifePotProjection = !isLaterLifePotDataValid({
    startAge: Number(startAge),
    potAmount: toPennies(amount),
  })
  const { data, loading } = useQueryWithError(MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY, {
    variables: {
      laterLifePotAmount: toPennies(amount),
      laterLifePotStartAge: Number(startAge),
    },
    skip: skipLaterLifePotProjection,
  })
  const projectedMonthlyIncome = data?.projections?.monthlyLaterLifeAmount

  if (loading) {
    return (
      <SLoadingContainer>
        <Spacer right="medium">
          <PartialLoader size="medium" />
        </Spacer>
        {t('steps.potSteps.potConfiguration.projection.fetching', 'Fetching projections')}
      </SLoadingContainer>
    )
  }

  return projectedMonthlyIncome ? (
    <>
      <Label as="p">{t('steps.potSteps.potConfiguration.projection.title', 'Monthly income (before tax)')}</Label>
      <IncomeValue level={HeadlineLevel.h2} as="h4">
        <Trans
          i18nKey="steps.potSteps.laterLifePot.projection.amount"
          components={{ currency: <Currency precision={0} /> }}
        >
          {t(
            'steps.potSteps.laterLifePot.projection.amount',
            'Projected monthly income is {{projectedMonthlyIncome}}',
            {
              projectedMonthlyIncome,
            }
          )}
        </Trans>
      </IncomeValue>
      <Copy as="p">
        {t('steps.potSteps.laterLifePot.projection.description', 'from {{startAge}} to life', {
          startAge,
        })}
      </Copy>
      <Spacer bottom="mediumSmall" />
      <ModalLink />
      <Modal />
    </>
  ) : null
}
