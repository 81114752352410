import { Link } from '@retire/components/Link/presentation'
import { UnorderedList } from '@retire/components/List'
import { Notification } from '@retire/components/Notification'
import type { FC } from 'react'
import { useCallback, useRef } from 'react'
import { Trans } from 'react-i18next'

import { useModalWithTranslation } from '../useModalWithTranslation'
import { useTranslationWithOptions } from '../useTranslationWithOptions'
import type { TUseEstimateIncomeModal } from './types'

export const useEstimateIncomeModal: TUseEstimateIncomeModal = potName => {
  const { Modal, onShowModal } = useModalWithTranslation()
  const modalOpenerRef = useRef<HTMLButtonElement>(null)

  const ModalLink: FC = () => {
    const { t } = useTranslationWithOptions('common')

    const openModal = useCallback(() => {
      onShowModal({
        title: t(`pots.modals.${potName}.estimateIncome.title`, `How we estimate your income for ${potName}`),
        content: (
          <Trans
            components={{
              unorderedList: <UnorderedList />,
              listItem: <li />,
            }}
          >
            {t(`pots.modals.${potName}.estimateIncome.content`, `Content for ${potName}`)}
          </Trans>
        ),
        modalOpenerRef,
      })
    }, [t])

    return (
      <Notification
        type="notice"
        title={
          <Link ref={modalOpenerRef} as="a" onClick={openModal}>
            {t(`pots.modals.${potName}.estimateIncome.title`, `How we estimate your income for ${potName}`)}
          </Link>
        }
        hasBorder
      />
    )
  }

  return {
    ModalLink,
    Modal,
  }
}
