import type { PotName } from '@retire/constants'
import { useGetPotDisplayName, useTranslationWithOptions } from '@retire/hooks'
import { toCurrency } from '@retire/utils'
import { useMemo } from 'react'

import { STitleContainer } from '../../TitleContainer/index.styled'
import type { TPotMoveMoney } from './types'

export const useTableDetails = (fromPots: TPotMoveMoney[] = [], toPotName: PotName | undefined) => {
  const { t } = useTranslationWithOptions('dashboard')
  const { getPotDisplayLongName } = useGetPotDisplayName()

  const columnHeaders = useMemo(
    () => [
      { content: t('pots.checkAndConfirm.tables.columns.from', 'From'), width: '25%' },
      { content: t('pots.checkAndConfirm.tables.columns.to', 'To'), width: '25%' },
      {
        content: t('pots.checkAndConfirm.tables.columns.amount', 'Withdrew/Moved amount'),
        width: '50%',
      },
    ],
    [t]
  )

  const rows = useMemo(
    () =>
      fromPots.map(({ potName, amount }) => ({
        cells: [
          {
            content: <STitleContainer>{getPotDisplayLongName(potName)}</STitleContainer>,
          },
          {
            content: (
              <STitleContainer>
                {toPotName
                  ? getPotDisplayLongName(toPotName)
                  : t('pots.checkAndConfirm.tables.cells.externalAccount', 'Withdrawal')}
              </STitleContainer>
            ),
          },
          { content: toCurrency(amount) },
        ],
      })),
    [fromPots, getPotDisplayLongName, t, toPotName]
  )

  return { columnHeaders, rows }
}
