import { useQuery } from '@apollo/client'
import { PotName } from '@retire/constants/pots'
import {
  MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY,
  MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY,
} from '@retire/gql/projections/queries'
import { getPotAmountKeyFromPotName, isFlexibleIncomePotDataValid, isLaterLifePotDataValid } from '@retire/utils/pots'

import { useIllustrationPlanData } from '../../../../../hooks/useIllustrationPlanData'
import type { TUsePotDetails } from './types'

export const usePotDetails: TUsePotDetails = potName => {
  const potAmountField = getPotAmountKeyFromPotName(potName)
  const {
    illustrationPlanData: {
      regularIncomeStartAge,
      regularIncomeEndAge,
      laterLifeAge,
      [potAmountField]: allocatedAmount,
    },
    loadingIllustrationPlanData,
  } = useIllustrationPlanData()
  let startAge = 0
  let endAge = 0
  if (potName === PotName.flexibleIncome) {
    startAge = regularIncomeStartAge || 0
    endAge = regularIncomeEndAge || 0
  } else if (potName === PotName.laterLife) {
    startAge = laterLifeAge || 0
  }

  const skipFlexibleIncomePotProjection = !isFlexibleIncomePotDataValid({
    startAge,
    endAge,
    potAmount: allocatedAmount,
  })
  const { data: flexibleIncomeProjectionData } = useQuery(MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotAmount: allocatedAmount || 0,
      flexibleIncomePotStartAge: startAge,
      flexibleIncomePotEndAge: endAge,
    },
    skip: potName !== PotName.flexibleIncome || loadingIllustrationPlanData || skipFlexibleIncomePotProjection,
  })

  const skipLaterLifePotProjection = !isLaterLifePotDataValid({
    startAge,
    potAmount: allocatedAmount,
  })
  const { data: laterLifeProjectionData } = useQuery(MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY, {
    variables: {
      laterLifePotAmount: allocatedAmount || 0,
      laterLifePotStartAge: startAge,
    },
    skip: potName !== PotName.laterLife || loadingIllustrationPlanData || skipLaterLifePotProjection,
  })

  const monthlyIncome =
    flexibleIncomeProjectionData?.projections?.monthlyFlexibleIncomeAmount ||
    laterLifeProjectionData?.projections?.monthlyLaterLifeAmount ||
    0

  return {
    monthlyIncome,
    allocatedAmount: allocatedAmount || 0,
    startAge,
    endAge,
  }
}
