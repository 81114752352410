import type { FC } from 'react'
import { Col, Container, Row } from 'react-grid-system'

import { CustomHeaderConsumer } from '../PageLayout/context'
import { HamburgerMenu } from './HamburgerMenu'
import { SHeader, SLeftSection, SRightSection, SWrapper } from './presentation.styled'
import type { THeader } from './types'

export const Header: FC<THeader> = ({ content, children, shouldShowNavigation }) => {
  return (
    <SHeader>
      <Container>
        <Row>
          <Col>
            <SWrapper>
              <CustomHeaderConsumer>
                {({ customHeader }) =>
                  customHeader || (
                    <>
                      <SLeftSection>{content}</SLeftSection>
                      <SRightSection>{shouldShowNavigation ? <HamburgerMenu /> : children}</SRightSection>
                    </>
                  )
                }
              </CustomHeaderConsumer>
            </SWrapper>
          </Col>
        </Row>
      </Container>
    </SHeader>
  )
}
