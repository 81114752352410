import { LabelValue } from '@retire/components'
import { useTranslationWithOptions, useUserDetails } from '@retire/hooks'
import type { FC } from 'react'

import { Section, SubSection } from '../Common'

export const YourDetails: FC = () => {
  const { bornOn, forename, surname } = useUserDetails().data

  const { t } = useTranslationWithOptions('common')

  return (
    <Section>
      <SubSection>
        <LabelValue label={t('formLabels.name', 'Your details - full name')} value={`${forename} ${surname}`} />
        <LabelValue label={t('formLabels.birthDate', 'Your details - birth date')} value={bornOn} />
      </SubSection>
    </Section>
  )
}
