import { Currency } from '@retire/components/Formatter/Currency'
import { FormNumberInput } from '@retire/components/Forms/NumberInput'
import { PotName } from '@retire/constants/pots'
import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { isRequired } from '@retire/utils'
import { toCurrency, toPennies, toPounds } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'
import { usePotInsufficientSavings } from '../../../../hooks/usePotInsufficientSavings'
import type { TInheritancePotConfigurationFormFields } from './types'

export const InheritancePotConfiguration: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.savingsPots })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const {
    loadingIllustrationPlanData,
    illustrationPlanData,
    savePartialIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
  } = useIllustrationPlanData()
  const { remainingSavings } = illustrationPlanData

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  const isEnsignUser = useIsEnsignUser()

  usePotInsufficientSavings({
    remainingSavings,
    potName: PotName.inheritance,
    insufficientSavingsPath: paths.illustration.journeyInheritancePotInsufficientSavings,
    skip: loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData,
  })

  const {
    [PotName.inheritance]: { minAmount },
  } = useSettings()

  const methods = useForm<TInheritancePotConfigurationFormFields>({ mode: 'onChange' })
  const {
    watch,
    formState: { isValid },
  } = methods
  const amount = watch('amount')

  const onNext = useCallback(
    async () =>
      await savePartialIllustrationPlanData(
        {
          ...illustrationPlanData,
          inheritanceAmount: toPennies(amount),
        },
        isEnsignUser ? paths.illustration.journeyInheritancePotFunds : paths.illustration.journeyRainyDayPotIntro
      ),
    [amount, illustrationPlanData, isEnsignUser, savePartialIllustrationPlanData]
  )
  const onBack = useCallback(() => navigate(paths.illustration.journeyInheritancePotIntro), [navigate])

  const rules = useMemo(
    () => ({
      min: {
        value: toPounds(minAmount),
        message: t('steps.potSteps.potConfiguration.validations.min', 'Amount must be {{minAmount}} or above', {
          minAmount: toCurrency(minAmount || 0),
        }),
      },
      max: {
        value: toPounds(remainingSavings),
        message: t('steps.potSteps.potConfiguration.validations.max', 'Amount must be {{maxAmount}} or below', {
          maxAmount: toCurrency(remainingSavings),
        }),
      },
      validate: {
        required: isRequired(),
      },
    }),
    [minAmount, remainingSavings, t]
  )

  return (
    <FormProvider {...methods}>
      <JourneyStepLayout
        title={t('steps.potSteps.inheritancePot.title', `Choose how much to put in ${PotName.inheritance} pot`)}
        onNext={onNext}
        nextText={t('steps.potSteps.potConfiguration.actions.accept', 'Accept')}
        disableNext={!isValid}
        onBack={onBack}
        backText={t('steps.potSteps.potConfiguration.actions.cancel', 'Cancel')}
        loading={loadingIllustrationPlanData}
      >
        <FormNumberInput
          name="amount"
          isCurrency
          label={t('steps.potSteps.inheritancePot.amount.label', 'Pot amount')}
          subLabel={
            minAmount && (
              <Trans
                i18nKey="steps.potSteps.inheritancePot.amount.subLabel"
                components={[<Currency key="minimum-value" />]}
              >
                {t('steps.potSteps.inheritancePot.amount.subLabel', 'Minimum amount is {{minAmount}}', {
                  minAmount: minAmount,
                })}
              </Trans>
            )
          }
          rules={rules}
        />
      </JourneyStepLayout>
    </FormProvider>
  )
}
