import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Link } from '../../../Link'
import { Spacer } from '../../../Spacer'
import { SearchField } from './constants'
import { SearchInput } from './SearchInput'
import { SearchResults } from './SearchResults'
import type { TSearch, TSearchFormFields } from './types'

export const Search: FC<TSearch> = ({
  setManualMode,
  onResultClick,
  searchResults,
  isSearchError,
  searchValue,
  ...searchInputProps
}) => {
  const { t } = useTranslationWithOptions('common')

  const methods = useForm<TSearchFormFields>({
    defaultValues: {
      [SearchField.postcode]: searchValue,
    },
    mode: 'onChange',
  })

  const { setError, clearErrors } = methods

  useEffect(() => {
    if (isSearchError) {
      setError(SearchField.postcode, {
        message: `${t('form.contactDetails.address.list.noResult', 'No result error')}`,
      })
    } else {
      clearErrors(SearchField.postcode)
    }
  }, [clearErrors, isSearchError, setError, t])

  return (
    <FormProvider {...methods}>
      <SearchInput
        {...searchInputProps}
        searchValue={searchValue}
        name={SearchField.postcode}
        label={t('form.contactDetails.address.postcode.label', 'Search input label')}
      />
      {!isSearchError && searchValue && !!searchResults.length && (
        <Spacer top="mediumSmall">
          <SearchResults onResultClick={onResultClick} searchResults={searchResults} />
        </Spacer>
      )}
      <Spacer top="mediumSmall">
        <Link as="button" onClick={setManualMode}>
          {t('form.contactDetails.address.links.enterManually', 'Enter address manually')}
        </Link>
      </Spacer>
    </FormProvider>
  )
}
