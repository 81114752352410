import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'

import { Spacer } from '../../Spacer'
import { Headline } from '../../Typography'

export const CardTitle: FC = ({ children }) => (
  <Spacer bottom="large">
    <Headline level={HeadlineLevel.h2} as="h2">
      {children}
    </Headline>
  </Spacer>
)
