export enum ErrorCode {
  // received from BE
  internal = 'INTERNAL_ERROR_CODE',
  forbidden = 'FORBIDDEN_ERROR',
  unauthorized = 'UNAUTHORIZED',
  network = 'NETWORK',
  notFound = 'NOT_FOUND',
  form = 'FORM_ERROR',
  validation = 'VALIDATION',
  processing = 'PROCESSING',
  // only FE
  unknown = 'UNKNOWN',
  retries = 'MAX_RETRIES_REACHED',
}

export const FORM_ERROR_CODES = [ErrorCode.validation, ErrorCode.form]

export const GLOBAL_ERROR_EXCLUDED_CODES = [ErrorCode.form, ErrorCode.validation]

export const SAVING_ERROR_EXCLUDED_CODES = [ErrorCode.retries, ErrorCode.unauthorized]
