import { useMutation } from '@apollo/client'
import { Copy, NavButtons, Spacer, UnorderedList } from '@retire/components'
import { USER_BRIEF_DETAILS_QUERY } from '@retire/gql/user/queries'
import { useLocationAlerts, useTranslationWithOptions } from '@retire/hooks'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'

import { REGULAR_INCOME_PAUSED_MUTATION } from '../../gql'

export const PauseFlexibleIncome: FC = () => {
  useActivatePageOverlay()
  const { t } = useTranslationWithOptions('dashboard')
  const { navigateWithAlerts } = useLocationAlerts()

  const onCompleted = useCallback(() => {
    navigateWithAlerts(paths.dashboard.flexibleIncomePotDetails, [
      {
        id: 'regular_income_paused',
        type: 'success',
        title: t('pots.common.pauseFlexibleIncome.success', 'Regular income paused'),
      },
    ])
  }, [navigateWithAlerts, t])

  const [onPauseIncomeClick] = useMutation(REGULAR_INCOME_PAUSED_MUTATION, {
    variables: {
      regularIncomePaused: true,
    },
    onCompleted,
    refetchQueries: [{ query: USER_BRIEF_DETAILS_QUERY }],
  })
  const onBackClick = useCallback(
    () => navigateWithAlerts(paths.dashboard.flexibleIncomePotDetails, []),
    [navigateWithAlerts]
  )

  return (
    <PageTemplate fullWidth title={t('pots.common.pauseFlexibleIncome.title', 'Pause monthly income')}>
      <>
        <Copy as="p">
          {t(
            'pots.common.pauseFlexibleIncome.descriptionOne',
            'You can stop taking monthly withdrawals from your flexible income pot whenever you want.'
          )}
        </Copy>
        <Spacer top="medium" />
        <Copy as="p">{t('pots.common.pauseFlexibleIncome.descriptionTwo', 'While withdrawals are paused:')}</Copy>
        <Spacer top="medium" />
        <Copy as="div">
          <Trans components={{ list: <UnorderedList />, listItem: <li /> }}>
            {t(
              'pots.common.pauseFlexibleIncome.descriptionList',
              '<list><listItem>your savings will still be invested</listItem><listItem>we will not show an estimated monthly income</listItem></list>'
            )}
          </Trans>
        </Copy>
        <NavButtons
          onNext={onPauseIncomeClick}
          onBack={onBackClick}
          nextText={t('pots.common.pauseFlexibleIncome.actions.pause', 'Pause income')}
          backText={t('pots.common.pauseFlexibleIncome.actions.back', 'Back')}
        />
      </>
    </PageTemplate>
  )
}
