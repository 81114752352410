import { PotType } from '@retire/gql/__generated__/graphql'
import { FUND_DETAILS_QUERY } from '@retire/gql/fundDetails/queries'
import { PLAN_QUERY } from '@retire/gql/plan/queries'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'

import { RESET_ILLUSTRATION_PLAN_MUTATION } from '../../gql/mutations'
import { ILLUSTRATION_PLAN_QUERY } from '../../gql/queries'
import type { TUseIllustrationPlanResetDataResult } from './types'

export const useIllustrationPlanResetData = (): TUseIllustrationPlanResetDataResult => {
  const [resetIllustrationPlanData, { loading: resettingIllustrationPlanData }] = useMutationWithError(
    RESET_ILLUSTRATION_PLAN_MUTATION,
    {
      refetchQueries: [
        { query: FUND_DETAILS_QUERY, variables: { potType: PotType.Inheritance } },
        { query: ILLUSTRATION_PLAN_QUERY },
        { query: PLAN_QUERY },
      ],
      awaitRefetchQueries: true,
    }
  )

  return {
    resetIllustrationPlanData,
    resettingIllustrationPlanData,
  }
}
