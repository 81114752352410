import { useQuery } from '@apollo/client'
import { Loading } from '@retire/components/Loading'
import { UserState } from '@retire/gql/__generated__/graphql'
import { INIT_QUERY } from '@retire/gql/user/queries'
import { useUpdateUserState } from '@retire/hooks/useUpdateUserState'
import { mainRootPath, paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ContinueApplication: FC = () => {
  const { data } = useQuery(INIT_QUERY)
  const navigate = useNavigate()
  const { updateUserState } = useUpdateUserState({
    onError: () => navigate(paths.hygienePages.serverError),
  })

  useEffect(() => {
    if (data) {
      if (data.user.state === UserState.SuitabilityCheck) {
        void updateUserState({ variables: { state: UserState.IllustrationCreation } })
      } else {
        navigate(mainRootPath)
      }
    }
  }, [data, navigate, updateUserState])

  return <Loading />
}
