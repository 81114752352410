import { styled } from '@retire/theme/styledComponents'
import { DEFAULT_TEXT_ALIGN } from '@retire/utils'

import { DEFAULT_PROPS } from './constants'
import type { TCopy } from './types'

export const Copy = styled.div<TCopy>`
  white-space: pre-wrap;
  font-style: normal;

  ${({
    as,
    color,
    textAlign = DEFAULT_TEXT_ALIGN,
    level = DEFAULT_PROPS.level,
    weight = DEFAULT_PROPS.weight,
    theme: {
      colors,
      spacing,
      typography: { copy },
      utilities,
    },
  }) => `
    font-family: ${copy.family};
    color: ${color ? colors[color] : copy.color};
    text-align: ${textAlign};
    font-size: ${copy.level[level].size}};
    line-height: ${copy.level[level].lineHeight}};
    font-weight: ${utilities.weight[weight]};

    ${
      ['ul', 'ol'].includes(as)
        ? `
          list-style: ${as === 'ul' ? 'disc' : 'decimal'};
          margin-left: 20px;

          li {
            padding-bottom: ${spacing.medium};
          }
        `
        : ''
    }
  `}
`
