import { CopyLevel } from '@retire/theme'
import { sentenceCase } from '@retire/utils'
import type { FC, MouseEvent } from 'react'
import { useCallback, useRef } from 'react'

import { Icon, IconContainer } from '../../../Icon'
import { Copy } from '../../../Typography'
import { useIconColor } from './hooks'
import { SInput, SLabel, SOption } from './presentation.styled'
import type { TOption } from './types'

export const Option: FC<TOption> = ({ id, name, type, value, description, selected, disabled, label, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { iconColor, setFocus, setHover } = useIconColor({ disabled, selected })
  const labelId = `label-${id}`

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault() // to avoid 2 onClick calls
      if (!disabled) {
        onChange && onChange(value)
        inputRef.current && inputRef.current.focus()
      }
    },
    [disabled, onChange, value]
  )

  const onFocus = useCallback(() => setFocus(true), [setFocus])
  const onBlur = useCallback(() => setFocus(false), [setFocus])
  const onMouseEnter = useCallback(() => setHover(true), [setHover])
  const onMouseLeave = useCallback(() => setHover(false), [setHover])

  return (
    <SOption
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      title={label}
    >
      <SLabel id={labelId} htmlFor={id} selected={selected} disabled={disabled}>
        <SInput
          aria-labelledby={labelId}
          ref={inputRef}
          type={type}
          id={id}
          name={name}
          checked={selected}
          disabled={disabled}
          readOnly
        />
        <IconContainer size="small" withSpacing>
          <Icon iconType={sentenceCase(type) as TIconTypes} iconColors={{ base: iconColor }} selected={selected} />
        </IconContainer>
        <span>
          {label}
          {typeof description === 'string' ? (
            <Copy as="p" level={CopyLevel.body1}>
              {description}
            </Copy>
          ) : (
            description
          )}
        </span>
      </SLabel>
    </SOption>
  )
}
