import { styled } from '@retire/theme/styledComponents'

import { Spacer } from '../../Spacer'
import { DEFAULT_PROPS } from '../constants'
import type { TTDCell } from './types'

export const STable = styled.table`
  width: 100%;

  tr td:not(:first-child) {
    text-align: right;
  }
`

export const SCaptionContent = styled(Spacer)`
  ${({
    theme: {
      table: { caption },
    },
  }) => `
    text-align: ${caption.textAlign};
    color: ${caption.color};
    line-height: ${caption.lineHeight};
    font-size: ${caption.fontSize};
    font-family: ${caption.fontFamily};
    font-weight: ${caption.fontWeight};
  `}
`

export const STDCell = styled.td<TTDCell>`
  vertical-align: ${({ verticalAlign = DEFAULT_PROPS.verticalAlign }) => verticalAlign};
  ${({
    theme: {
      table: { content },
    },
    isHeader,
  }) => `
    font-family: ${content.fontFamily};
    font-size: ${content.fontSize};
    line-height: ${content.lineHeight};
    color: ${content.color};
    font-weight: ${isHeader ? content.headers.fontWeight : content.fontWeight}
  `}

  :nth-child(even) {
    text-align: right;
  }
`
