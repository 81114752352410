import { PotName } from '@retire/constants/pots'
import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types/icons'
import type { FC } from 'react'

export const Inheritance: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={`${PotName.inheritance}-icon`}
    >
      <g stroke="none" fill="none" strokeWidth="1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke={base} strokeWidth="2.3">
          <path d="M11.2499886,10 L11.2499886,2.87833333 C11.2466667,1.98166667 11.9716667,1.25 12.8683333,1.24666667 C12.8683333,1.24666667 12.8683333,1.24666667 12.8683333,1.24666667 L29.035,1.24666667 L38.7416781,11.03 L38.7416781,37.1166667 C38.745,38.0133333 38.02,38.7433333 37.1233333,38.7466667 L26.25,38.7466667" />
          <path d="M28.75,1.24666667 L28.75,9.58 C28.75,10.5 29.4966667,11.2466667 30.4166667,11.2466667 L38.75,11.2466667" />
          <path d="M34.6733333,17.4966667 L21.25,17.4966667" />
          <path d="M34.6733333,32.4966667 L30,32.4966667" />
          <path d="M34.6733333,24.9966667 L23.75,24.9966667" />
          <polyline points="1.25 33.7466667 2.5 38.7466667 20 38.7466667 21.25 33.7466667" />
          <path d="M16.25,28.7466667 L13.75,28.7466667 L13.75,24.3033333 C16.1416667,22.9233333 16.9616667,19.865 15.58,17.4733333 C14.2,15.0816667 11.1416667,14.2616667 8.75,15.6433333 C6.35833333,17.025 5.53833333,20.0816667 6.92,22.4733333 C7.35833333,23.2333333 7.99,23.865 8.75,24.3033333 L8.75,28.7466667 L6.25,28.7466667 C3.48833333,28.7466667 1.25,30.985 1.25,33.7466667 L21.25,33.7466667 C21.25,30.985 19.0116667,28.7466667 16.25,28.7466667 Z" />
        </g>
      </g>
    </svg>
  )
}
