import { Copy, FormDate, FormOptionsGroup, FormTextInput, Link, Spacer, Twister } from '@retire/components'
import { PersonalDetail } from '@retire/constants'
import { Gender } from '@retire/gql/__generated__/graphql'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { isRequired } from '@retire/utils'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

export const MainDetails: FC = () => {
  const { t } = useTranslationWithOptions(['enrolment', 'common'])
  const {
    customerService: { phoneNumber: CSDisplayPhoneNumber, email: CSEmail },
  } = usePublicSettings()
  const CSEmailLink = <Link as="a" href={`mailto:${CSEmail}`} />

  return (
    <>
      <FormTextInput
        name={PersonalDetail.forename}
        autocomplete="given-name"
        rules={{
          validate: {
            required: isRequired(t('common:form.personalDetails.forename.validation', 'Firstname is required')),
          },
        }}
        label={t('personalDetails.sections.mainDetails.form.forename', 'Forename')}
        required
      />
      <Spacer bottom="large" />
      <FormTextInput
        name={PersonalDetail.surname}
        autocomplete="family-name"
        rules={{
          validate: {
            required: isRequired(t('common:form.personalDetails.surname.validation', 'Lastname is required')),
          },
        }}
        label={t('personalDetails.sections.mainDetails.form.surname', 'Surname')}
        required
      />
      <Spacer bottom="large" />
      <FormDate
        name={PersonalDetail.DOB}
        label={t('personalDetails.sections.mainDetails.form.bornOn', 'Date of birth')}
        disabled
      />
      <Spacer bottom="large" />
      <FormOptionsGroup
        name={PersonalDetail.gender}
        options={[
          {
            value: Gender.Female,
            label: t('common:form.personalDetails.gender.value.Female', 'Female'),
          },
          {
            value: Gender.Male,
            label: t('common:form.personalDetails.gender.value.Male', 'Male'),
          },
        ]}
        label={t('personalDetails.sections.mainDetails.form.gender.label', 'Gender')}
        subLabel={t('personalDetails.sections.mainDetails.form.gender.sublabel')}
        rules={{
          validate: {
            required: isRequired(t('common:form.personalDetails.gender.validation', 'Gender is required')),
          },
        }}
      />
      <Twister title={t('personalDetails.sections.mainDetails.form.gender.twister.title', 'Gender twister')}>
        <Copy as="p">
          <Trans
            components={{
              EmailLink: CSEmailLink,
            }}
          >
            {t(
              'personalDetails.sections.mainDetails.form.gender.twister.description',
              'Gender twister details, email {{email}}, phone number: {{phoneNumber}}',
              {
                email: CSEmail,
                phoneNumber: CSDisplayPhoneNumber,
              }
            )}
          </Trans>
        </Copy>
      </Twister>
      <Spacer bottom="large" />
      <FormTextInput
        name={PersonalDetail.NIN}
        label={t('common:form.personalDetails.nino.label', 'NIN')}
        subLabel={t('common:form.personalDetails.nino.subLabel', 'NIN sublabel')}
        disabled
      />
      <Spacer top="medium">
        <Twister title={t('common:form.personalDetails.nino.twister.title', 'NIN twister')}>
          {t('common:form.personalDetails.nino.twister.description', 'NIN twister details')}
        </Twister>
      </Spacer>
    </>
  )
}
