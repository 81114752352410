import { Copy, Headline, Link, OrderedList, Segment, Spacer, UnorderedList } from '@retire/components'
import { PotName } from '@retire/constants'
import { useModalWithTranslation, useTranslationWithOptions } from '@retire/hooks'
import { useSettings } from '@retire/hooks/useSettings'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { HeadlineLevel, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { createRef, useCallback } from 'react'
import { Trans } from 'react-i18next'

export const BeforeYouContinue: FC = () => {
  const { t } = useTranslationWithOptions('illustration')
  const { Modal: IncomeModal, onShowModal } = useModalWithTranslation()
  const modalOpenerRef = createRef<HTMLButtonElement>()
  const { inactivePeriodInDays } = useSettings()

  const openModal = useCallback(
    () =>
      onShowModal({
        title: t(
          `common:pots.modals.${PotName.flexibleIncome}.estimateIncome.title`,
          `How we estimate your income for ${PotName.flexibleIncome}`
        ),
        content: (
          <Trans
            components={{
              unorderedList: <UnorderedList />,
              listItem: <li />,
            }}
          >
            {t(
              `common:pots.modals.${PotName.flexibleIncome}.estimateIncome.content`,
              `Content for ${PotName.flexibleIncome}`
            )}
          </Trans>
        ),
        modalOpenerRef,
      }),
    [onShowModal, t, modalOpenerRef]
  )

  return (
    <Segment whiteBackground>
      <ContentWrapper>
        <Headline level={HeadlineLevel.h2} as="h2" color={TypographyColor.primary}>
          {t('yourIllustration.beforeYouContinue.title', 'Before you continue title')}
        </Headline>
        <Spacer bottom="large" />
        <Copy as="div">
          <Trans
            components={{
              list: <OrderedList />,
              listItem: <li />,
              modalLink: <Link ref={modalOpenerRef} as="a" noDecoration onClick={openModal} />,
            }}
          >
            {t(
              'yourIllustration.beforeYouContinue.description',
              '<modalLink>See income details</modalLink>{{inactivePeriodInDays}}',
              {
                validForDays: inactivePeriodInDays,
              }
            )}
          </Trans>
        </Copy>
      </ContentWrapper>
      <IncomeModal />
    </Segment>
  )
}
