import type { FC } from 'react'

import { SInput } from '../index.styled'
import { InputGroup } from './presentation.styled'
import type { TSortCode } from './types'

export const SortCode: FC<TSortCode> = ({
  id,
  ariaDescribedBy,
  ariaLabelledBy,
  ariaInvalid,
  inputProps,
  ...restProps
}) => (
  <InputGroup>
    {inputProps.map(({ name, ...props }) => (
      <SInput
        {...restProps}
        {...props}
        type="number"
        aria-describedby={ariaDescribedBy}
        aria-labelledby={ariaLabelledBy}
        aria-invalid={ariaInvalid}
        maxLength={2}
        min="0"
        max="99"
        key={name}
        id={`${id}-${name}`}
      />
    ))}
  </InputGroup>
)
