import { Segment } from '@retire/components/Segment'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { TPageTemplate } from '@retire/templates/PageTemplate/types'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import type { TStepWrapper } from './types'

export const StepWrapper: FC<TStepWrapper> = props => {
  const { children, onBack, onNext, nextUrl, backUrl } = props
  const navigate = useNavigate()
  const onNextUrl = () => nextUrl && navigate(nextUrl)
  const onBackUrl = () => backUrl && navigate(backUrl)

  const stepProps: TPageTemplate = {
    ...props,
    onNext: onNext || onNextUrl,
    onBack: onBack || onBackUrl,
  }

  return (
    <PageTemplate {...stepProps} fullWidth>
      <Segment whiteBackground>{children}</Segment>
    </PageTemplate>
  )
}
