export const widthsMap = {
  '1/8': `${100 / 8}%`,
  '1/4': '25%',
  '1/3': `${100 / 3}%`,
  '1/2': '50%',
  '2/3': `${200 / 3}%`,
  '3/4': '75%',
  full: '100%',
  fluid: '0',
  content: 'auto',
}
