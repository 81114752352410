import { graphql } from '@retire/gql/__generated__/gql'

// @TODO: remove this, and use USER_ENROLMENT_QUERY instead https://smartpension.atlassian.net/browse/CM-1410
export const USER_COBS_QUERY = graphql(`
  query getUserCobs {
    user {
      id
      ...cobs__User
    }
  }
`)
