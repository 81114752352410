import { graphql } from '@retire/gql/__generated__/gql'

export const BANK_DETAILS_MUTATION = graphql(`
  mutation createBankDetails(
    $accountName: String!
    $accountNumber: String!
    $sortCode: String!
    $bankReference: String
  ) {
    createBankDetails(
      accountName: $accountName
      accountNumber: $accountNumber
      sortCode: $sortCode
      bankReference: $bankReference
    ) {
      accountName
      accountNumber
      sortCode
      bankReference
    }
  }
`)
