import { Divider } from '@retire/components/Divider'
import { FormNumberInput } from '@retire/components/Forms'
import { NavButtons } from '@retire/components/NavButtons'
import { Spacer } from '@retire/components/Spacer'
import { Copy, Headline } from '@retire/components/Typography'
import { PotName } from '@retire/constants/pots'
import { NONNUMERIC_CHAR_REGEX } from '@retire/constants/regex'
import { MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY } from '@retire/gql/projections/queries'
import { useBlockOnKeyDown } from '@retire/hooks/useBlockOnKeyDown'
import { useBlockOnMaxLength } from '@retire/hooks/useBlockOnMaxLength'
import { useBlockOnPaste } from '@retire/hooks/useBlockOnPaste'
import { useFormDefaultValues } from '@retire/hooks/useFormDefaultValues'
import { useLaterLifePotAgeRules } from '@retire/hooks/useLaterLifePotAgeRules'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { HeadlineLevel } from '@retire/theme/typography'
import { toCurrency } from '@retire/utils/currency'
import { isLaterLifePotDataValid } from '@retire/utils/pots'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { usePotsContext } from '../../../hooks/usePotsContext'
import type { TLaterLifePotManageIncomeReviewState } from '../types'
import type { TLaterLifePotPeriodFormFields } from './types'

export const LaterLifePotPeriod: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const {
    pots: {
      [PotName.laterLife]: {
        allocatedAmount: laterLifePotAmount = 0,
        projectedMonthlyIncome: currentMonthlyIncomeAmount = 0,
      },
    },
  } = usePotsContext()
  const {
    data: { currentAge, laterLifeAge: currentStartAge },
    loading: loadingUserDetails,
  } = useUserDetails()
  const {
    [PotName.laterLife]: { minStartAge: ageRangeMin, maxStartAge },
  } = useSettings()
  const [defaultValues, setDefaultValues] = useFormDefaultValues<TLaterLifePotPeriodFormFields>({
    initialValues: { startAge: String(currentStartAge) },
  })

  const methods = useForm<TLaterLifePotPeriodFormFields>({
    mode: 'onChange',
    defaultValues,
  })

  const {
    formState: { isValid, isValidating },
    handleSubmit,
    watch,
  } = methods

  const { startAge } = watch()

  const minStartAge = Math.max(ageRangeMin, currentAge)
  const safeStartAge = useMemo(() => {
    const intStartAge = Number(startAge) || 0

    return Math.min(Math.max(intStartAge, minStartAge), maxStartAge)
  }, [startAge, minStartAge, maxStartAge])

  const skipLaterLifePotProjection = !isLaterLifePotDataValid({
    startAge: safeStartAge,
    potAmount: laterLifePotAmount,
  })
  const { data, loading: projectionLoading } = useQueryWithError(MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY, {
    variables: {
      laterLifePotStartAge: safeStartAge,
      laterLifePotAmount,
    },
    skip: !isValid || isValidating || skipLaterLifePotProjection,
  })
  const projectedMonthlyIncomeAmount = data?.projections?.monthlyLaterLifeAmount ?? 0

  const onContinue = handleSubmit(() => {
    const reviewTableData: TLaterLifePotManageIncomeReviewState = {
      startAge: {
        current: currentStartAge,
        new: Number(startAge),
      },
      monthlyIncome: {
        current: currentMonthlyIncomeAmount,
        new: projectedMonthlyIncomeAmount,
      },
    }
    setDefaultValues({ startAge })

    navigate(paths.dashboard.laterLifePotManageIncomePeriodReview, {
      state: reviewTableData,
    })
  })

  const onQuit = useCallback(() => {
    navigate(paths.dashboard.laterLifePotDetails)
  }, [navigate])

  const { startAgeRules } = useLaterLifePotAgeRules()

  const blockOnMaxLength = useBlockOnMaxLength(2)
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_CHAR_REGEX, blockOnMaxLength)
  const onPaste = useBlockOnPaste({ maxLength: 2 })

  const loading = useMemo(() => projectionLoading || loadingUserDetails, [projectionLoading, loadingUserDetails])

  return (
    <FormProvider {...methods}>
      <PageTemplate
        fullWidth
        title={t('pots.common.manageIncome.title', 'Manage your income')}
        onQuit={onQuit}
        loading={loading}
      >
        <Copy as="p">{t('pots.common.manageIncome.startAge', 'Start age')}</Copy>
        <Spacer top="medium" />
        <FormNumberInput
          name="startAge"
          inputWidth="250px"
          width="250px"
          autoFocus
          rules={startAgeRules}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
        />
        <Spacer top="medium" />
        <Divider bottom="large" />
        {isValid && (
          <>
            <Copy as="p">{t('pots.common.manageIncome.monthlyAmount', 'Monthly amount (before tax)')}</Copy>
            <Headline level={HeadlineLevel.h3} as="h3">
              {toCurrency(projectedMonthlyIncomeAmount, 0)}
            </Headline>
            <Spacer bottom="mediumSmall" />
            <Divider bottom="large" />
          </>
        )}

        <NavButtons
          onNext={onContinue}
          disableNext={loading || !isValid}
          nextText={t('pots.common.manageIncome.actions.continue', 'Continue')}
        />
      </PageTemplate>
    </FormProvider>
  )
}
