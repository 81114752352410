import { Twister } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { useCallback } from 'react'

import type { TDetailsTwister } from './types'

export const DetailsTwister: FC<TDetailsTwister> = ({ transactionId, isOpen, onToggle }) => {
  const { t } = useTranslationWithOptions('dashboard')
  const onToggleOpen = useCallback(() => {
    onToggle(transactionId)
  }, [onToggle, transactionId])

  return (
    <Twister
      title={t(
        `transactionList.tables.actions.${!isOpen ? 'view' : 'hide'}Details`,
        `${!isOpen ? 'View' : 'Hide'} details`
      )}
      noTitleWrap
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
    />
  )
}
