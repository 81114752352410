import { styled } from '@retire/theme/styledComponents'

export const InputGroup = styled.fieldset`
  display: flex;

  > * {
    max-width: 5.375rem;
  }

  > *:nth-child(1),
  > *:nth-child(2) {
    margin-right: ${props => props.theme.spacing.small};
  }
`
