import { styled } from '@retire/theme/styledComponents'

export const SSelect = styled.select`
  max-width: 100%;

  ${({
    theme: {
      addressLookup: {
        border,
        select: { fontSize, marginTop },
      },
    },
  }) => `
    margin-top: ${marginTop};
    font-size: ${fontSize};
    border-radius: ${border.radius};
    border-width: ${border.width};
    border-style: ${border.style};
    border-color: ${border.color};
  `}
`
