import type { UserState } from '@retire/gql/__generated__/graphql'

import type { TUserDetails } from './types'

export const DEFAULT_USER_DETAILS: TUserDetails = {
  forename: '',
  surname: '',
  email: '',
  unconfirmedEmail: '',
  laterLifeAge: 0,
  bornOn: '',
  state: null as unknown as UserState,
  currentAge: 0,
  valuationAmount: 0,
  regularIncomeAmount: null,
  regularIncomeEndAge: 0,
  regularIncomeStartAge: 0,
  regularIncomePaused: false,
}
