import { Divider } from '@retire/components/Divider'
import { AddressLookup } from '@retire/components/Forms/AddressLookup'
import { FormPhoneInput } from '@retire/components/Forms/PhoneInput'
import { Spacer } from '@retire/components/Spacer'
import { Headline } from '@retire/components/Typography/Headline'
import { ContactField } from '@retire/constants/user'
import type { TIsCheckAndConfirmOrigin } from '@retire/features-shared/Account/types'
import { useForm } from '@retire/hooks/useForm'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { HeadlineLevel } from '@retire/theme/typography'
import { isRequired, isTelephone } from '@retire/utils/rules'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { MainDetails } from '../../components/MainDetails'
import { StepWrapper } from '../../components/StepWrapper'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { ADDRESS_FIELDS, DEFAULT_USE_FORM_ARGS, REQUIRED_ADDRESS_FIELDS } from './constants'
import type { TPersonalDetails } from './types'

export const PersonalDetails: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.personalDetails })
  const { t } = useTranslationWithOptions('enrolment')
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  const isCheckAndConfirmOrigin = !!(locationState as TIsCheckAndConfirmOrigin)?.isCheckAndConfirmOrigin

  const onSubmit = useCallback(() => {
    navigate(isCheckAndConfirmOrigin ? paths.enrolment.checkAndConfirm : paths.enrolment.bankOrBuildingSociety)
  }, [navigate, isCheckAndConfirmOrigin])

  const { methods, handleSubmit, isLoading } = useForm<TPersonalDetails>({ ...DEFAULT_USE_FORM_ARGS, onSubmit })
  const countryISOCode = methods.watch(ContactField.telephoneCountryCode)

  const phoneInputRules = useMemo(
    () => ({
      validate: {
        required: isRequired(
          t('common:form.contactDetails.telephone.validations.required', 'Phone number is required')
        ),
        format: isTelephone({
          countryISOCode,
          message: t('common:form.contactDetails.telephone.validations.format', 'Phone number is in incorrect format'),
        }),
      },
    }),
    [countryISOCode, t]
  )

  return (
    <FormProvider {...methods}>
      <StepWrapper
        title={t('personalDetails.title', 'About you')}
        nextText={t('general.actions.next', 'Next')}
        backText={t('general.actions.back', 'Back')}
        loading={isLoading}
        onNext={handleSubmit}
        backUrl={paths.enrolment.emailCheck}
      >
        <MainDetails />
        <Divider top="large" bottom="large" />
        <Spacer bottom="large">
          <Headline level={HeadlineLevel.h3} as="h2">
            {t('personalDetails.sections.contactDetails.title', 'Contact details')}
          </Headline>
        </Spacer>
        <AddressLookup isManualMode fields={ADDRESS_FIELDS} requiredFields={REQUIRED_ADDRESS_FIELDS} />
        <Spacer bottom="large" />
        <FormPhoneInput
          label={t('common:form.contactDetails.telephone.label', 'Telephone')}
          countryCodeName={ContactField.telephoneCountryCode}
          name={ContactField.telephone}
          rules={phoneInputRules}
        />
      </StepWrapper>
    </FormProvider>
  )
}
