import { Button, Divider, Spacer } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import type { TBeneficiariesList } from '@retire/features-shared/Account'
import { BeneficiaryForm } from '@retire/features-shared/Account'
import { useTranslationWithOptions } from '@retire/hooks'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

export const BeneficiariesList: FC<TBeneficiariesList> = ({ beneficiaries, onCancel, onSave, onAdd, onRemove }) => {
  const { t } = useTranslationWithOptions(['account', 'common'])

  return (
    <PageTemplate
      title={t('common:account.beneficiaries.actions.edit.label', 'Edit your beneficiaries')}
      onNext={onSave}
      nextText={t('actions.save', 'Save')}
      onBack={onCancel}
      backText={t('actions.cancel', 'Cancel')}
      fullWidth
    >
      {beneficiaries.map((_, index) => (
        <BeneficiaryForm
          // eslint-disable-next-line react/no-array-index-key
          key={`beneficiary-${index}`}
          index={index}
          removeItem={onRemove}
        />
      ))}
      <Spacer top="medium">
        <Button buttonStyleType={ButtonStyleType.secondary} onClick={onAdd}>
          {t(
            `common:account.beneficiaries.actions.add.${!beneficiaries.length ? 'label' : 'anotherLabel'}`,
            `Add ${!beneficiaries.length ? '' : 'another '}beneficiary`
          )}
        </Button>
        <Divider top="large" bottom="large" />
      </Spacer>
    </PageTemplate>
  )
}
