import { HorizontalPotCard } from '@retire/components/Pot/HorizontalPotCard'
import { Spacer } from '@retire/components/Spacer'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { Fragment, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePotsContext } from '../../../hooks/usePotsContext'
import { useSections } from './hooks/useSections'
import type { TPotManagementListItem } from './types'

export const PotManagementListItem: FC<TPotManagementListItem> = ({ potName }) => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const {
    pots: {
      [potName]: { isLockedForBuys, isLockedForSells },
    },
  } = usePotsContext()
  const sections = useSections({ potName })
  const openDetailPage = useCallback(() => {
    navigate(paths.dashboard[`${potName}Details`])
  }, [navigate, potName])

  const isLocked = isLockedForBuys || isLockedForSells

  return (
    <Fragment key={`${potName}-horizontal-pot`}>
      <HorizontalPotCard
        potName={t(`pots.${potName}.title`, `${potName} title {{locked}}`, {
          locked: isLocked ? t('pots.common.lockedShort', 'locked') : '',
        })}
        variant={potName}
        stackActionOnMobile
        description={t(`pots.${potName}.subTitle`, `${potName} subtitle`)}
        openDetailPage={openDetailPage}
        sections={sections}
        isLocked={isLocked}
      />
      <Spacer bottom="large" />
    </Fragment>
  )
}
