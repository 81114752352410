import { PartialLoader } from '@retire/components'
import type { TBeneficiaries } from '@retire/features-shared/Account'
import {
  beneficiariesServices,
  BeneficiaryRemovalConfirmation,
  getEmptyBeneficiary,
} from '@retire/features-shared/Account'
import { useForm, useLocationAlerts, useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { FormProvider, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { BeneficiariesList } from './BeneficiariesList'

export const Beneficiaries: FC = () => {
  const { t } = useTranslationWithOptions('common')
  const navigate = useNavigate()
  const { navigateWithAlerts } = useLocationAlerts()
  const [removalConfirmation, setRemovalConfirmation] = useState<number>()

  const onSubmit = useCallback(() => {
    navigateWithAlerts(paths.account.manage, [
      {
        id: 'updated-beneficiaries',
        type: 'success',
        title: t('common:account.beneficiaries.notification', 'Beneficiaries updated'),
      },
    ])
  }, [navigateWithAlerts, t])

  const { methods, handleSubmit, isFetchLoading } = useForm<TBeneficiaries>({
    ...beneficiariesServices,
    onSubmit,
    hookFormOptions: {
      defaultValues: {
        beneficiaries: [],
      },
    },
  })

  const { append, remove } = useFieldArray({
    name: 'beneficiaries',
    ...methods,
  })

  const { watch } = methods

  const onRemovalConfirmationCancel = useCallback(() => setRemovalConfirmation(undefined), [])
  const onRemovalConfirmationSave = useCallback(() => {
    if (removalConfirmation !== undefined) {
      remove(removalConfirmation)
    }
    setRemovalConfirmation(undefined)
  }, [removalConfirmation, remove])
  const onCancel = useCallback(() => navigate(paths.account.manage), [navigate])

  const beneficiaries = watch('beneficiaries')
  const onAdd = useCallback(() => {
    append(getEmptyBeneficiary(beneficiaries))
  }, [append, beneficiaries])

  if (isFetchLoading) {
    return <PartialLoader />
  }
  if (removalConfirmation !== undefined) {
    const beneficiaryRemovalConfirmationName = watch(`beneficiaries.${removalConfirmation}.name`)

    return (
      <BeneficiaryRemovalConfirmation
        name={beneficiaryRemovalConfirmationName}
        onCancel={onRemovalConfirmationCancel}
        onSave={onRemovalConfirmationSave}
      />
    )
  }
  return (
    <FormProvider {...methods}>
      <BeneficiariesList
        beneficiaries={Object.values(beneficiaries)}
        onCancel={onCancel}
        onSave={handleSubmit}
        onAdd={onAdd}
        onRemove={setRemovalConfirmation}
      />
    </FormProvider>
  )
}
