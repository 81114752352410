import { styled } from '@retire/theme/styledComponents'

export const SPageOverlay = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background: ${({ theme }) => theme.colors.white};
`

export const SInnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const SButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
