import { Copy, Spacer, TransList } from '@retire/components'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

export const ContactSupport: FC = () => {
  const { t } = useTranslationWithOptions('account')
  const {
    customerService: { phoneNumber },
  } = usePublicSettings()

  return (
    <PageTemplate title={t('contactSupport.pageTitle', 'Contact support title')}>
      <Copy as="p">
        {t('contactSupport.description.contactInfo', 'Call us on {{phoneNumber}}', {
          phoneNumber,
        })}
      </Copy>
      <Spacer bottom="small" />
      <Copy as="p">{t('contactSupport.description.requiredDocsIntro', 'Contact support docs intro')}</Copy>
      <Spacer bottom="small" />
      <TransList>{t('contactSupport.description.requiredDocsList', 'Contact support docs list')}</TransList>
      <Spacer bottom="medium" />
      <Copy as="p">{t('contactSupport.description.summary', 'Contact support summary')}</Copy>
    </PageTemplate>
  )
}
