import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { Address } from './Address'
import type { TOnResultClick } from './Search'
import { Search } from './Search'
import type { TAddressLookup } from './types'

export const AddressLookup: FC<TAddressLookup> = ({
  isManualMode,
  isSearchError,
  triggerSearch,
  onResultClick,
  onSearch,
  onSearchChange,
  searchValue,
  searchResults,
  fields,
  requiredFields,
}) => {
  const [mode, setMode] = useState<'manual' | 'search'>(isManualMode ? 'manual' : 'search')
  const containerRef = useRef<HTMLDivElement>(null)
  const isInitialRender = useRef(true)

  useEffect(() => {
    setMode(isManualMode ? 'manual' : 'search')
  }, [isManualMode])

  useEffect(() => {
    !isInitialRender.current && containerRef.current?.scrollIntoView()
    isInitialRender.current = false
  }, [mode])

  const onResultClickWithModeSet: TOnResultClick = useCallback(
    (resultId, clickAction) => {
      clickAction === 'Retrieve' && setMode('manual')
      onResultClick(resultId)
    },
    [onResultClick]
  )

  const setModeToSearch = useCallback(() => setMode('search'), [])
  const setModeToManual = useCallback(() => setMode('manual'), [])

  return (
    <div ref={containerRef}>
      {mode === 'manual' ? (
        <Address requiredFields={requiredFields} setSearchMode={setModeToSearch} fields={fields} />
      ) : (
        <Search
          isSearchError={isSearchError}
          triggerSearch={triggerSearch}
          setManualMode={setModeToManual}
          onResultClick={onResultClickWithModeSet}
          onSearch={onSearch}
          onSearchChange={onSearchChange}
          searchResults={searchResults}
          searchValue={searchValue}
          requiredFields={requiredFields}
        />
      )}
    </div>
  )
}
