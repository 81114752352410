import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { Paragraph } from '../Common/Paragraph'
import { Section } from '../Common/Section'
import { SubSection } from '../Common/SubSection'

export const Tax: FC = () => {
  const { t } = useTranslationWithOptions('plan')
  const {
    lumpSumAllowance: { amount: lumpSumAllowanceAmount },
    lumpSumAndDeathBenefitAllowance: { amount: lumpSumAndDeathBenefitAllowanceAmount },
  } = useSettings()

  return (
    <Section>
      <SubSection>
        <Paragraph>
          <Trans components={{ bold: <strong /> }}>
            {t(
              'tax.description',
              'Tax description - LumpSumAllowance amount {{lumpSumAllowanceAmount}}, LumpSumAndDeathBenefitAllowance amount {{lumpSumAndDeathBenefitAllowanceAmount}}',
              {
                lumpSumAllowanceAmount: toCurrency(lumpSumAllowanceAmount, 0),
                lumpSumAndDeathBenefitAllowanceAmount: toCurrency(lumpSumAndDeathBenefitAllowanceAmount, 0),
              }
            )}
          </Trans>
        </Paragraph>
      </SubSection>
    </Section>
  )
}
