import { PotName } from '@retire/constants/pots'

import { PotType } from '../__generated__/graphql'
import type { TFundPotTypeMap } from './types'

export const FUND_POT_TYPES: TFundPotTypeMap = {
  [PotName.flexibleIncome]: PotType.FlexibleIncome,
  [PotName.laterLife]: PotType.LaterLife,
  [PotName.inheritance]: PotType.Inheritance,
  [PotName.rainyDay]: PotType.RainyDay,
}
