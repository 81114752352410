import type { FC } from 'react'
import { createContext } from 'react'

import { usePageBarColor } from '../../hooks'
import type { TPageBarColorContext } from './types'

export const PageBarColorContext = createContext<TPageBarColorContext>({
  color: undefined,
  setColor: () => null,
})

export const PageBarColorProvider: FC = ({ children }) => {
  const barColor = usePageBarColor()

  return <PageBarColorContext.Provider value={barColor}>{children}</PageBarColorContext.Provider>
}

export const PageBarColorConsumer = PageBarColorContext.Consumer
