import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import { DEFAULT_PROPS } from './constants'
import type { TButton } from './types'

export const SButton = styled.button<TButton>`
  ${({
    buttonStyleType,
    disabled,
    lockWidth,
    size = DEFAULT_PROPS.size,
    theme: {
      general: { transitionDuration },
      buttons,
    },
  }) => css`
    border-radius: ${buttons.border.radius};
    border-style: ${buttons.border.style};
    border-width: ${buttons.border.width};
    font-family: ${buttons.family};
    font-size: ${buttons.size[size].size};
    line-height: ${buttons.size[size].lineHeight};
    font-weight: ${buttons.weight};
    white-space: nowrap;
    padding: ${buttons.padding[size].top} ${buttons.padding[size].sides};
    transition-property: background, border, color, fill;
    transition-duration: ${transitionDuration};
    cursor: pointer;

    ${lockWidth
      ? null
      : mediaStyles({ display: 'block', width: '100%', whiteSpace: 'pre-wrap' }, { max: Breakpoint.small })}

    ${disabled
      ? `
          background: ${buttons.disabled.backgroundColor};
          border-color: ${buttons.disabled.borderColor};
          color: ${buttons.disabled.color};
          fill: ${buttons.disabled.color};
          cursor: default;
        `
      : `
          background: ${buttons.styleType[buttonStyleType].default.backgroundColor};
          border-color: ${buttons.styleType[buttonStyleType].default.borderColor};
          color: ${buttons.styleType[buttonStyleType].default.color};
          fill: ${buttons.styleType[buttonStyleType].default.color};

          &:hover {
            background: ${buttons.styleType[buttonStyleType].hover.backgroundColor};
            border-color: ${buttons.styleType[buttonStyleType].hover.borderColor};
            color: ${buttons.styleType[buttonStyleType].hover.color};
            fill: ${buttons.styleType[buttonStyleType].hover.color};
          }

          &:focus {
            background: ${buttons.styleType[buttonStyleType].focus.backgroundColor};
            border-color: ${buttons.styleType[buttonStyleType].focus.borderColor};
            color: ${buttons.styleType[buttonStyleType].focus.color};
            fill: ${buttons.styleType[buttonStyleType].focus.color};
            outline: none;
          }

          &:active {
            background: ${buttons.styleType[buttonStyleType].active.backgroundColor};
            border-color: ${buttons.styleType[buttonStyleType].active.borderColor};
            color: ${buttons.styleType[buttonStyleType].active.color};
            fill: ${buttons.styleType[buttonStyleType].active.color};
            outline: none;
          }
        `};
  `}
`
