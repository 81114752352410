import { useEffect, useState } from 'react'

export const useIsOffline = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOffline(!navigator.onLine)
    }

    window.addEventListener('online', handleStatusChange)

    window.addEventListener('offline', handleStatusChange)

    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [isOffline])

  return isOffline
}
