import { TypographyColor } from '@retire/theme/colors'
import { CopyLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { Container, Row } from 'react-grid-system'

import { Spacer } from '../../Spacer'
import { Copy } from '../../Typography/Copy'
import type { TLabelValue } from './types'

export const LabelValue: FC<TLabelValue> = ({ label, value }) => (
  <Container>
    <Row>
      <Copy as="span" level={CopyLevel.caption}>
        {label}
      </Copy>
      <Spacer left="extraSmall">
        <Copy as="span" color={TypographyColor.hyperlink} level={CopyLevel.caption}>
          {value}
        </Copy>
      </Spacer>
    </Row>
  </Container>
)
