import { gql } from '@apollo/client'

export const SUITABILITY_CHECK_MUTATION = gql`
  mutation updateUserSuitabilityCheck(
    $willContributeToPension: Boolean!
    $awareOfTaxFreeLumpSum: Boolean!
    $needSpecialInvestmentOptions: Boolean!
    $hasSeenWarningScreen: Boolean!
  ) {
    userUpdate(
      suitabilityCheck: {
        willContributeToPension: $willContributeToPension
        awareOfTaxFreeLumpSum: $awareOfTaxFreeLumpSum
        needSpecialInvestmentOptions: $needSpecialInvestmentOptions
        hasSeenWarningScreen: $hasSeenWarningScreen
      }
      input: {}
    ) {
      id
      suitabilityCheck {
        hasSeenWarningScreen
      }
    }
  }
`
