import { PotName } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

import { BalanceCard } from '../../components/PotDetails/BalanceCard/presentation'
import { InheritancePotFundsCard } from '../../components/PotDetails/InheritancePotFundsCard'
import { PageTitle } from '../../components/PotDetails/PageTitle/presentation'
import { PendingAmount } from '../../components/PotDetails/PendingAmount/presentation'
import { WhatItsForCard } from '../../components/PotDetails/WhatItsForCard/presentation'
import { usePendingTransactions, usePotDetails } from '../../hooks'

export const InheritancePotDetails: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const {
    loadingPotDetails,
    potDetails: { isLockedForSells, isLockedForBuys, allocatedAmount = 0 },
  } = usePotDetails(PotName.inheritance)
  const {
    loading: loadingPendingTransactions,
    data: { pendingAmounts },
  } = usePendingTransactions()

  const potLocked = isLockedForSells || isLockedForBuys
  const title = t('pots.inheritancePot.title', `${PotName.inheritance} details`, {
    ...(potLocked && { locked: t('pots.common.lockedShort', 'locked') }),
  })

  return (
    <PageTemplate
      fullWidth
      title={<PageTitle title={title} variant={PotName.inheritance} />}
      loading={loadingPotDetails}
    >
      <WhatItsForCard potName={PotName.inheritance} />
      <BalanceCard balance={allocatedAmount} buttonText={t('pots.common.balanceCard.actions.moveMoney', 'Move money')}>
        <PendingAmount loading={loadingPendingTransactions} pendingAmount={pendingAmounts[PotName.inheritance]} />
      </BalanceCard>
      <InheritancePotFundsCard />
    </PageTemplate>
  )
}
