import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import { Paragraph, Section, SubSection } from '../Common'

export const Leaving: FC = () => {
  const { t } = useTranslationWithOptions('plan')

  return (
    <Section>
      <SubSection>
        <Paragraph>{t('leaving.description', 'Leaving description')}</Paragraph>
      </SubSection>
    </Section>
  )
}
