import { Link } from '@retire/components/Link'
import { IDS, LinkSize, LinkVariant } from '@retire/constants'
import { scrollToElementWithID } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { Container } from 'react-grid-system'

import { SSkipContent, SWrapper } from './presentation.styled'

export const SkipBanner: FC = ({ children }) => {
  const [visible, setVisible] = useState(false)

  const onFocus = useCallback(() => setVisible(true), [])
  const onBlur = useCallback(() => setVisible(false), [])

  return (
    <SSkipContent visible={visible}>
      <Container>
        <SWrapper>
          <Link
            as="a"
            variant={LinkVariant.dark}
            size={LinkSize.large}
            onFocus={onFocus}
            onClick={scrollToElementWithID(IDS.mainContent, () => setVisible(false))}
            onBlur={onBlur}
            noDecoration
          >
            {children}
          </Link>
        </SWrapper>
      </Container>
    </SSkipContent>
  )
}
