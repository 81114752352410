import { Card, CardTitle } from '@retire/components/Card'
import { UnorderedList } from '@retire/components/List'
import { Notification } from '@retire/components/Notification'
import { Copy } from '@retire/components/Typography/Copy'
import { useFeesModal } from '@retire/hooks/useFeesModal'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { generateDefaultTranslationString } from '@retire/utils/strings'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import type { TTransactionsHowItWorksCard } from './types'

export const TransactionsHowItWorksCard: FC<TTransactionsHowItWorksCard> = ({ phoneNumber }) => {
  const { t, tWithoutDefaultString } = useTranslationWithOptions('dashboard')
  const { FeesModal, FeesModalLink } = useFeesModal()

  return (
    <Card>
      <CardTitle>{t('transactionList.howItWorks.title', 'How it works')}</CardTitle>
      <Copy as="span">
        <Trans
          components={[
            <span />,
            <UnorderedList>
              <li />
              <li />
            </UnorderedList>,
          ]}
        >
          {t(
            'transactionList.howItWorks.description',
            'Your money is invested in a changing market, call us on {{phoneNumber}}',
            { phoneNumber }
          )}
        </Trans>
      </Copy>
      <Notification
        title={
          <Trans components={{ feesModalLink: <FeesModalLink /> }}>
            {tWithoutDefaultString(
              'transactionList.howItWorks.fees',
              `<feesModalLink>${generateDefaultTranslationString('Fees text')}</feesModalLink>`
            )}
          </Trans>
        }
        type="notice"
        hasBorder
      />
      <FeesModal />
    </Card>
  )
}
