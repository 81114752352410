import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import type { ReactElement } from 'react'
import { cloneElement } from 'react'
import { Outlet } from 'react-router-dom'

import { DashboardNotFound } from '../../pages/DashboardNotFound'

/**
 * This component manages:
 * - if user is not an ensign user: display 404
 * - if user is ensign user: render children
 */
export const EnsignUserAuthOutlet = ({ children }: { children?: ReactElement }) => {
  const isEnsignUser = useIsEnsignUser()

  if (isEnsignUser) {
    if (children) {
      return cloneElement(children, { children: <Outlet /> })
    }
    return <Outlet />
  }

  return <DashboardNotFound />
}
