export const loader = {
  thickness: {
    extraLarge: '0.3125rem',
    large: '0.25rem',
    medium: '0.1875rem',
    small: '0.125rem',
    extraSmall: '0.125rem',
  },
  size: {
    extraLarge: '2.9375rem',
    large: '2rem',
    medium: '1.3125rem',
    small: '0.875rem',
    extraSmall: '0.625rem',
  },
}
