import { graphql } from '../__generated__/gql'

export const USER_STATE_MUTATION = graphql(`
  mutation updateUserState($state: UserState!) {
    userUpdate(input: { state: $state }) {
      id
      state
    }
  }
`)

export const USER_CONTACT_DETAILS_MUTATION = graphql(`
  mutation updateUserContactDetails($input: UserAttributes!) {
    userUpdate(input: $input) {
      id
      ...contact__User
      ...address__User
    }
  }
`)
