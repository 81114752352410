import { graphql } from '../__generated__/gql'

export const MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY = graphql(`
  query getMonthlyFlexibleIncomeAmountProjection(
    $flexibleIncomePotAmount: Int!
    $flexibleIncomePotStartAge: Int!
    $flexibleIncomePotEndAge: Int!
  ) {
    projections {
      monthlyFlexibleIncomeAmount(
        potAmount: $flexibleIncomePotAmount
        endAge: $flexibleIncomePotEndAge
        startAge: $flexibleIncomePotStartAge
      )
    }
  }
`)

export const MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY = graphql(`
  query getMonthlyLaterLifeAmountProjection($laterLifePotAmount: Int!, $laterLifePotStartAge: Int!) {
    projections {
      monthlyLaterLifeAmount(potAmount: $laterLifePotAmount, startAge: $laterLifePotStartAge)
    }
  }
`)

export const LATER_LIFE_AGE_PROJECTION_QUERY = graphql(`
  query getLaterLifeAgeProjection(
    $flexibleIncomePotAmount: Int!
    $flexibleIncomePotCustomMonthlyAmount: Int!
    $flexibleIncomePotStartAge: Int!
    $flexibleIncomePotEndAge: Int!
  ) {
    projections {
      laterLifeAge(
        potAmount: $flexibleIncomePotAmount
        monthlyIncome: $flexibleIncomePotCustomMonthlyAmount
        retirementAge: $flexibleIncomePotStartAge
        requestedLaterLifeAge: $flexibleIncomePotEndAge
      ) {
        projectedLaterLifeAge
        surplusAmount
        shortfallAmount
      }
    }
  }
`)
