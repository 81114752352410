import { graphql } from '@retire/gql/__generated__/gql'

export const SIMPLE_PLAN_QUERY = graphql(`
  query getSimplePlan {
    plan {
      taxFreeLumpSum
      flexibleIncomeMonthlyAmount
    }
  }
`)
