import { useTranslationWithOptions } from '@retire/hooks'
import { StyleWeight } from '@retire/theme'
import type { FC } from 'react'

import { Spacer } from '../Spacer'
import { Copy } from '../Typography/Copy'
import { DEFAULT_PROPS } from './constants'
import { SRating, SRatingBox, SRatingContainer, SRightFloatedCopy } from './presentation.styled'
import type { TRiskRating } from './types'

export const RiskRating: FC<TRiskRating> = ({
  rating: chosenRating,
  maxRating = DEFAULT_PROPS.maxRating,
  withTitle = DEFAULT_PROPS.withTitle,
}) => {
  const ratings = Array.from({ length: maxRating }, (_, index) => ++index)
  const { t } = useTranslationWithOptions('common')

  return (
    <>
      {withTitle && (
        <Spacer bottom="medium">
          <Copy as="span" weight={StyleWeight.bold}>
            {t('riskRating.rating', 'Risk rating: {{chosenRating}}', { chosenRating })}
          </Copy>
        </Spacer>
      )}
      <SRatingContainer>
        <SRatingBox>
          {ratings.map(rating => {
            const chosen = chosenRating === rating

            return (
              <SRating key={rating} chosen={chosen}>
                {rating}
              </SRating>
            )
          })}
        </SRatingBox>
        <Copy as="span">{t('riskRating.lowerRiskLabel', 'Risk rating lower label')}</Copy>
        <SRightFloatedCopy as="span">{t('riskRating.higherRiskLabel', 'Risk rating higher label')}</SRightFloatedCopy>
      </SRatingContainer>
    </>
  )
}
