import { Link } from '@retire/components/Link'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { mainRootPath, paths } from '@retire/navigation/paths'
import { NotFoundTemplate } from '@retire/templates/NotFoundTemplate'
import { SListLink } from '@retire/templates/NotFoundTemplate/index.styled'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

export const DashboardNotFound: FC = () => {
  const { t } = useTranslationWithOptions(['common', 'dashboard'])

  return (
    <NotFoundTemplate
      title={t('common:hygienePages.notFound.title', 'Dashboard Not found title')}
      links={
        <>
          <Trans i18nKey="hygienePages.notFound.content">
            {t('hygienePages.notFound.content', 'Dashboard Not found content')}
          </Trans>
          <SListLink>
            <Trans
              i18nKey="dashboard:hygienePages.notFound.links"
              components={{
                dashboard: <Link as="a" key="link-dashboard" href={paths.dashboard.board} />,
                inbox: <Link as="a" key="link-inbox" href={paths.inbox.messages} />,
                settings: <Link as="a" key="link-settings" href={paths.account.manage} />,
              }}
            >
              {t('dashboard:hygienePages.notFound.links', 'Dashboard Not found links')}
            </Trans>
          </SListLink>
        </>
      }
      buttonLabel={t('dashboard:hygienePages.notFound.button', 'Dashboard Not found button')}
      buttonPath={mainRootPath}
    />
  )
}
