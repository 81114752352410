import { PotName } from '@retire/constants/pots'
import { isLessThanOrEqualToMaxValue, isMoreThanOrEqualToMinValue, isRequired } from '@retire/utils/rules'
import { useMemo } from 'react'

import { useSettings } from '../useSettings'
import { useTranslationWithOptions } from '../useTranslationWithOptions'
import { useUserDetails } from '../useUserDetails'
import type { TUseLaterLifePotAgeRules } from './types'

export const useLaterLifePotAgeRules: TUseLaterLifePotAgeRules = () => {
  const { t } = useTranslationWithOptions('common')
  const {
    [PotName.laterLife]: { minStartAge: settingsMinStartAge, maxStartAge: settingsMaxStartAge },
  } = useSettings()
  const {
    data: { currentAge: currentUserAge },
    loading: userDetailsLoading,
  } = useUserDetails()

  const startAgeCopyVariables = useMemo(
    () => ({
      minAge: Math.max(settingsMinStartAge, currentUserAge),
      maxAgePlusOne: settingsMaxStartAge + 1,
    }),
    [currentUserAge, settingsMaxStartAge, settingsMinStartAge]
  )

  const startAgeRules = useMemo(
    () => ({
      validate: {
        required: isRequired(),
        aboveMinStartAge: isMoreThanOrEqualToMinValue({
          minValue: Math.max(settingsMinStartAge, currentUserAge),
          message: t(
            'form.laterLifePot.startAge.validations.minAge',
            'Start age must be more than {{minAgeMinusOne}} (and less than {{maxAgePlusOne}})',
            {
              minAgeMinusOne: Math.max(settingsMinStartAge, currentUserAge) - 1,
              maxAgePlusOne: settingsMaxStartAge + 1,
            }
          ),
        }),
        belowMaxStartAge: isLessThanOrEqualToMaxValue({
          maxValue: settingsMaxStartAge,
          message: t(
            'form.laterLifePot.startAge.validations.maxAge',
            'Start age must be less than {{maxAgePlusOne}} (and equal or more than {{minAge}})',
            startAgeCopyVariables
          ),
        }),
      },
    }),
    [currentUserAge, settingsMaxStartAge, settingsMinStartAge, startAgeCopyVariables, t]
  )

  return {
    startAgeRules,
    loading: userDetailsLoading,
  }
}
