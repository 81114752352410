import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import type { TIcon } from '../../Icon/types'
import { IconTitle } from './IconTitle'

export const Radio: FC<TIconColors & Pick<TIcon, 'selected'>> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { selected, base = primary } = props

  return (
    <svg x="0px" y="0px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Radio" />
      <path
        fill={base}
        d="M11,2c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S6,2,11,2 M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0z"
      />
      {selected && (
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <circle fill={base} cx="11" cy="11" r="5.6" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
    </svg>
  )
}
