import type { FC } from 'react'

import { useAddressLookup } from './hooks'
import { AddressLookup } from './presentation'
import type { TAddressLookupContainer } from './types'

export const AddressLookupContainer: FC<TAddressLookupContainer> = props => {
  const { setSearchValue, ...hookProps } = useAddressLookup()

  return <AddressLookup {...props} {...hookProps} onSearchChange={setSearchValue} />
}
