import { FormDropdown, Spacer } from '@retire/components'
import { SelectedSubLabels } from '@retire/components/Forms/Dropdown/SelectedSubLabels'
import { SelectedValueWithSubLabels } from '@retire/components/MobileDropdown/SelectedValueWithSubLabels'
import { useTranslationWithOptions } from '@retire/hooks'
import isTouchDevice from 'is-touch-device'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import { FROM_INPUT_NAME, TO_INPUT_NAME } from '../constants'
import { SDropdownWrapper } from '../DropdownWrapper'
import { usePotOptions } from '../hooks/usePotOptions'

export const MoveMoneyTo: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const isMobile = isTouchDevice()

  const [selectedFromPot, selectedPot] = useWatch({ name: [FROM_INPUT_NAME, TO_INPUT_NAME] })

  const options = usePotOptions({ disabledPot: selectedFromPot })

  const { subLabels: selectedPotSubLabels, label: selectedPotLabel } = useMemo(
    () => options.find(({ value }) => value === selectedPot) || { subLabels: undefined, label: '' },
    [options, selectedPot]
  )
  const renderSelectedValue = useCallback(
    () => <SelectedValueWithSubLabels label={selectedPotLabel} subLabels={selectedPotSubLabels} />,
    [selectedPotLabel, selectedPotSubLabels]
  )

  const rules = { required: true }

  return (
    <Spacer top={isMobile ? 'large' : 'extraLarge'}>
      <FormDropdown
        label={t('pots.common.moveMoneyBetweenPots.toSection.title', 'To')}
        name={TO_INPUT_NAME}
        placeholder={t('pots.common.moveMoneyBetweenPots.toSection.placeholder', 'Choose a pot to move money to')}
        options={options}
        withMobileDropdown
        renderSelectedValue={isMobile ? renderSelectedValue : undefined}
        mobileDropdownOverlayTitle={t('pots.common.moveMoneyBetweenPots.toSection.mobileDropdownOverlayTitle')}
        SDropdownWrapper={SDropdownWrapper}
        rules={rules}
      />
      {!isMobile && <SelectedSubLabels subLabels={selectedPotSubLabels} />}
    </Spacer>
  )
}
