import { useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { NotFoundTemplate } from '@retire/templates/NotFoundTemplate'
import type { FC } from 'react'

export const NotFoundMessage: FC = () => {
  const { t } = useTranslationWithOptions('inbox')

  return (
    <NotFoundTemplate
      title={t('hygienePages.notFound.title', 'Message Not found title')}
      buttonLabel={t('hygienePages.notFound.button', 'Message Not found button')}
      buttonPath={paths.inbox.messages}
    />
  )
}
