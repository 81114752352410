import { graphql } from '@retire/gql/__generated__/gql'

export const USER_EMAIL_MUTATION = graphql(`
  mutation updateUserEmail($email: String!) {
    userUpdate(input: { unconfirmedEmail: $email }) {
      id
      unconfirmedEmail
    }
  }
`)
