import { getCallingCodeFromISOCode, getCountryFromISOCode } from '@retire/utils'
import { countries } from 'countries-list'
import type { CountryCode } from 'libphonenumber-js'

import type { TOption } from '../Dropdown'

const getOptionView = (ISOCode: CountryCode, underline: boolean): TOption => {
  const country = getCountryFromISOCode(ISOCode)
  return {
    value: ISOCode,
    label: `${country.emoji} ${country.name} (+${country.phone})`,
    underline,
  }
}

const getSelectedOptionView = (ISOCode: CountryCode) => {
  const country = getCountryFromISOCode(ISOCode)
  if (!country) {
    return ''
  }
  return `${getCountryFromISOCode(ISOCode).emoji} +${getCallingCodeFromISOCode(ISOCode)}`
}

const getCountryOptions = (suggestedCountriesISOCodes: CountryCode[] = []): TOption[] => {
  const options: TOption[] = []
  const isSuggestedCountry = (ISOCode: CountryCode) => suggestedCountriesISOCodes.includes(ISOCode)
  ;(Object.keys(countries) as CountryCode[]).forEach(ISOCode => {
    if (!isSuggestedCountry(ISOCode)) {
      options.push(getOptionView(ISOCode, false))
    }
  })
  suggestedCountriesISOCodes
    .concat()
    .reverse()
    .forEach((ISOCode: CountryCode, index: number) => {
      options.unshift(getOptionView(ISOCode, index === 0))
    })
  return options
}

export { getCountryOptions, getSelectedOptionView }
