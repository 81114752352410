import { colors } from './colors'
import { utilities } from './utilities'

export const PRIMARY_FAMILY = 'lato, sans-serif'
const SECONDARY_FAMILY = 'museo-sans, sans-serif'

export enum CopyLevel {
  body1 = 'body1',
  body2 = 'body2',
  subLabel = 'subLabel',
  caption = 'caption',
}

export enum HeadlineLevel {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
}

const copyLevels: Record<CopyLevel, Record<'size' | 'lineHeight', string>> = {
  body1: {
    size: '1rem',
    lineHeight: '1.5rem',
  },
  body2: {
    size: '1.125rem',
    lineHeight: '1.6875rem',
  },
  subLabel: {
    size: '1rem',
    lineHeight: '1.5rem',
  },
  caption: {
    size: '0.875rem',
    lineHeight: '1.3125rem',
  },
}

const headlineLevels: Record<
  HeadlineLevel,
  Record<'family' | 'size' | 'letterSpacing' | 'lineHeight' | 'weight', string> & {
    mobile: Record<'size' | 'letterSpacing' | 'lineHeight', string>
  }
> = {
  h1: {
    family: SECONDARY_FAMILY,
    size: '2.75rem',
    letterSpacing: '-0.0625rem',
    lineHeight: '3.375rem',
    weight: utilities.weight.extraRegular,
    mobile: {
      size: '2rem',
      letterSpacing: '-0.03125rem',
      lineHeight: '2.4375rem',
    },
  },
  h2: {
    family: SECONDARY_FAMILY,
    size: '2rem',
    letterSpacing: '-0.03125rem',
    lineHeight: '2.5rem',
    weight: utilities.weight.extraRegular,
    mobile: {
      size: '1.5rem',
      letterSpacing: '-0.01875rem',
      lineHeight: '1.875',
    },
  },
  h3: {
    family: SECONDARY_FAMILY,
    size: '1.75rem',
    letterSpacing: '-0.01875rem',
    lineHeight: '2.125rem',
    weight: utilities.weight.extraRegular,
    mobile: {
      size: '1.25rem',
      letterSpacing: '-0.01875rem',
      lineHeight: '1.3125',
    },
  },
  h4: {
    family: PRIMARY_FAMILY,
    size: '1.25rem',
    letterSpacing: '0rem',
    lineHeight: '1.75rem',
    weight: utilities.weight.semiBold,
    mobile: {
      size: '1.25rem',
      letterSpacing: '0rem',
      lineHeight: '1.75rem',
    },
  },
}

export const typography = {
  copy: {
    family: PRIMARY_FAMILY,
    color: colors.neutral60,
    level: copyLevels,
  },
  headline: {
    color: colors.primary,
    level: headlineLevels,
  },
}
