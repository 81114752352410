import { Loading } from '@retire/components/Loading'
import { Spacer } from '@retire/components/Spacer'
import { useGlobalLoadingContext } from '@retire/hooks/useGlobalLoadingContext'
import type { FC } from 'react'
import { useMemo } from 'react'

import { Overlay } from '../../Overlay'

export const GlobalLoadingOverlay: FC = () => {
  const { title, subTitle } = useGlobalLoadingContext()

  const icon = useMemo(() => (title ? 'SmallLogo' : 'Logo'), [title])

  return (
    <Overlay title={title} subTitle={subTitle} icon={icon}>
      <Spacer bottom="large" />
      <Loading />
    </Overlay>
  )
}
