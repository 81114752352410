import { useGlobalErrorContext, useTranslationWithOptions } from '@retire/hooks'
import { isRetriesErrorCode } from '@retire/utils'
import type { FC } from 'react'

import { Overlay } from '../../Overlay'

export const GlobalErrorOverlay: FC = () => {
  const { globalError, resetGlobalError } = useGlobalErrorContext()
  const isRetriesError = isRetriesErrorCode(globalError?.code)
  const { t } = useTranslationWithOptions('common')

  if (isRetriesError) {
    return (
      <Overlay
        icon="Error"
        title={t('errors.retries.title', 'Something went wrong')}
        subTitle={t('errors.retries.caption', 'An error happened')}
      />
    )
  }
  return (
    <Overlay
      icon="Error"
      title={t('errors.global.title', 'Something went wrong')}
      subTitle={t('errors.global.caption', 'An error happened')}
      onNext={resetGlobalError}
      nextText={t('actions.back', 'Retry')}
    />
  )
}
