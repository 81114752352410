import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Plus: FC<TIconColors> = props => {
  const {
    colors: { primary, white },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg fill={base} x="0px" y="0px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Plus" />
      <g>
        <g>
          <g transform="translate(-143.000000, -1419.000000)">
            <g transform="translate(137.000000, 1411.000000)">
              <g transform="translate(4.000000, 6.000000)">
                <g transform="translate(2.000000, 2.000000)">
                  <ellipse cx="10.9" cy="10.9" rx="10.9" ry="10.9" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <path
          d="M16.4,9.5h-4.2v-4c0-0.7-0.6-1.2-1.2-1.2S9.7,4.8,9.7,5.5v4h-4c-0.7,0-1.2,0.6-1.2,1.2S5,12,5.7,12h4v4.4
		c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2V12h4.2c0.7,0,1.2-0.6,1.2-1.2S17.1,9.5,16.4,9.5z"
          fill={white}
        />
      </g>
    </svg>
  )
}
