import type { MutationHookOptions, OperationVariables } from '@apollo/client'
import { useMutation } from '@apollo/client'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { TErrorTitle } from '@retire/types'
import { getError, isRetriesErrorCode, isSavingErrorCode } from '@retire/utils'
import type { DocumentNode } from 'graphql'

import { useGlobalErrorContext } from '../useGlobalErrorContext'
import { useLocationAlerts } from '../useLocationAlerts'
import { useTranslationWithOptions } from '../useTranslationWithOptions'

export const useMutationWithError = <TData, TVariables extends OperationVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { errorTitle, ...options }: MutationHookOptions<TData, TVariables> & TErrorTitle = {}
) => {
  const { t } = useTranslationWithOptions('common')
  const { addAlert } = useLocationAlerts()
  const { globalError, setGlobalError } = useGlobalErrorContext()

  return useMutation<TData, TVariables>(mutation, {
    ...options,
    onError: error => {
      /*
       * IMPORTANT
       * if you pass an "onError" option to the callback,
       * the global "onError" logic below is override
       */
      const generalError = getError(error)
      console.error('There was an error', generalError)
      if (isRetriesErrorCode(generalError.code)) {
        globalError !== generalError && setGlobalError(generalError)
      } else if (isSavingErrorCode(generalError.code)) {
        addAlert({
          id: 'saving-error',
          type: 'error',
          title: errorTitle || t('errors.saving.title', 'Saving error'),
          message: t('errors.saving.message', 'Try again'),
        })
      }
      options?.onError && options?.onError(error)
    },
  })
}
