import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

void i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      debug: false,
      ns: ['common'],
      fallbackLng: 'en-GB',
      supportedLngs: ['en-GB'],
      interpolation: {
        escapeValue: true,
        skipOnVariables: false,
      },
    },
    () => {
      document.body.dir = i18n.dir()
    }
  )

export { i18n }
