import { ArrowDown } from './ArrowDown'
import { ArrowRight } from './ArrowRight'
import { Attachment } from './Attachment'
import { Check } from './Check'
import { Checkbox } from './Checkbox'
import { Clock } from './Clock'
import { Close } from './Close'
import { Edit } from './Edit'
import { Error } from './Error'
import { Exit } from './Exit'
import { External } from './External'
import { FlexibleIncome } from './FlexibleIncome'
import { HamburgerMenu } from './HamburgerMenu'
import { Info } from './Info'
import { Inheritance } from './Inheritance'
import { LaterLife } from './LaterLife'
import { Logo } from './Logo'
import { MercerLogo } from './MercerLogo'
import { Minus } from './Minus'
import { Notice } from './Notice'
import { Pdf } from './Pdf'
import { Plus } from './Plus'
import { PotIn } from './PotIn'
import { PotOut } from './PotOut'
import { Radio } from './Radio'
import { RainyDay } from './RainyDay'
import { SmallLogo } from './SmallLogo'
import { SmartLogo } from './SmartLogo'
import { Success } from './Success'
import { TaxFreeWithdrawal } from './TaxFreeWithdrawal'
import { Warning } from './Warning'
import { WindowNext } from './WindowNext'

declare global {
  type TIconPositions = 'left' | 'right'
  type TIconTypes =
    | 'ArrowDown'
    | 'ArrowRight'
    | 'Attachment'
    | 'Check'
    | 'Checkbox'
    | 'Clock'
    | 'Close'
    | 'Edit'
    | 'Error'
    | 'Exit'
    | 'External'
    | 'FlexibleIncome'
    | 'HamburgerMenu'
    | 'Info'
    | 'Inheritance'
    | 'LaterLife'
    | 'Logo'
    | 'MercerLogo'
    | 'Minus'
    | 'Notice'
    | 'Pdf'
    | 'Plus'
    | 'PotIn'
    | 'PotOut'
    | 'Radio'
    | 'RainyDay'
    | 'SmallLogo'
    | 'SmartLogo'
    | 'Success'
    | 'TaxFreeWithdrawal'
    | 'Warning'
    | 'WindowNext'
}

export const Icons = {
  ArrowDown,
  ArrowRight,
  Attachment,
  Check,
  Checkbox,
  Clock,
  Close,
  Edit,
  Error,
  Exit,
  External,
  FlexibleIncome,
  HamburgerMenu,
  Info,
  Inheritance,
  LaterLife,
  Logo,
  MercerLogo,
  Minus,
  Notice,
  Pdf,
  Plus,
  PotIn,
  PotOut,
  Radio,
  RainyDay,
  SmallLogo,
  SmartLogo,
  Success,
  TaxFreeWithdrawal,
  Warning,
  WindowNext,
}
