import { FormTextInput } from '@retire/components/Forms/TextInput'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { useForm } from '@retire/hooks/useForm'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { CopyLevel } from '@retire/theme/typography'
import { differentFromValue, isEmail, isRequired } from '@retire/utils/rules'
import type { FC } from 'react'
import { useCallback } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { StepWrapper } from '../../components/StepWrapper'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { emailChangingServices } from './services'
import type { TEmailFormFields } from './types'

export const EmailChanging: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.email })
  const { t } = useTranslationWithOptions('enrolment')
  const navigate = useNavigate()
  const { email, unconfirmedEmail } = useUserDetails().data
  const onSubmit = useCallback(() => navigate(paths.enrolment.emailChanged), [navigate])

  const { methods, handleSubmit, isLoading } = useForm<TEmailFormFields>({
    ...emailChangingServices,
    onSubmit,
  })

  return (
    <FormProvider {...methods}>
      <StepWrapper
        title={t('emailChanging.title', 'Email changing')}
        nextText={t('emailChanging.actions.next', 'Next')}
        backText={t('general.actions.back', 'Back')}
        onNext={handleSubmit}
        backUrl={unconfirmedEmail ? paths.enrolment.emailChanged : paths.enrolment.emailCheck}
        loading={isLoading}
      >
        <Spacer bottom="extraLarge">
          <Copy as="p" level={CopyLevel.body1}>
            {t('emailChanging.description', 'Description')}
          </Copy>
        </Spacer>
        <FormTextInput
          name="email"
          type="email"
          rules={{
            validate: {
              required: isRequired(t('emailChanging.validations.required', 'New email is required')),
              format: isEmail(t('emailChanging.validations.required', 'Has to follow email format')),
              notWorkEmail: differentFromValue({
                forbiddenValue: email,
                message: t('emailChanging.validations.notWork', 'This is a work email'),
              }),
            },
          }}
          label={t('emailChanging.label', 'New email')}
          autocomplete="email"
          required
        />
      </StepWrapper>
    </FormProvider>
  )
}
