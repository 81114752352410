import { FormOptionsGroup } from '@retire/components/Forms/OptionsGroup'
import { FundCardContent } from '@retire/components/FundCardContent'
import { UnorderedList } from '@retire/components/List'
import { SelectableCard } from '@retire/components/SelectableCard/presentation'
import { PotName } from '@retire/constants/pots'
import { useFundDetails } from '@retire/hooks/useFundDetails'
import { useGetPotDisplayName } from '@retire/hooks/useGetPotDisplayName'
import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'
import { usePotInsufficientSavings } from '../../../../hooks/usePotInsufficientSavings'
import type { TFlexibleIncomePotIntroFormFields } from './types'

export const FlexibleIncomePotIntro: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.incomePots })
  const isEnsignUser = useIsEnsignUser()
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const {
    loadingIllustrationPlanData,
    illustrationPlanData,
    savePartialIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
  } = useIllustrationPlanData()

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      flexibleIncomeAmount: null,
      regularIncomeStartAge: null,
      regularIncomeEndAge: null,
      laterLifeAmount: null,
      laterLifeAge: null,
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  usePotInsufficientSavings({
    remainingSavings: illustrationPlanData.remainingSavings,
    potName: PotName.flexibleIncome,
    insufficientSavingsPath: paths.illustration.journeyFlexibleIncomePotInsufficientSavings,
    skip: loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData,
  })
  const { fundDetails, loading: loadingFundDetails } = useFundDetails(PotName.flexibleIncome)
  const { getPotDisplayName } = useGetPotDisplayName()

  const options = useMemo(
    () => [
      {
        label: t('steps.potSteps.potIntro.actions.usePot', 'Use pot'),
        value: true,
      },
      {
        label: t('steps.potSteps.potIntro.actions.doNotUsePot', 'Do not use pot'),
        value: false,
      },
    ],
    [t]
  )

  const inputName = 'option'
  const methods = useForm<TFlexibleIncomePotIntroFormFields>()
  const { watch } = methods
  const option = watch(inputName)

  const onNext = useCallback(async () => {
    if (option) {
      navigate(paths.illustration.journeyFlexibleIncomePotConfiguration)
    } else {
      await savePartialIllustrationPlanData(
        {
          ...illustrationPlanData,
          flexibleIncomeAmount: 0,
        },
        paths.illustration.journeyLaterLifePotIntro
      )
    }
  }, [illustrationPlanData, navigate, option, savePartialIllustrationPlanData])
  const onBack = useCallback(() => navigate(paths.illustration.journeyIncomePotsIntro), [navigate])

  const description = useMemo(() => {
    if (isEnsignUser) {
      return {
        transKey: `steps.potSteps.potIntro.description.${PotName.flexibleIncome}.ensignUser`,
        defaultString: `Pot intro description ${PotName.flexibleIncome} for Ensign user`,
      }
    }
    return {
      transKey: `steps.potSteps.potIntro.description.${PotName.flexibleIncome}.standardUser`,
      defaultString: `Pot intro description ${PotName.flexibleIncome} for standard user`,
    }
  }, [isEnsignUser])

  return (
    <FormProvider {...methods}>
      <JourneyStepLayout
        title={t('steps.potSteps.potIntro.title', `Pot intro title ${PotName.flexibleIncome}`, {
          potName: getPotDisplayName(PotName.flexibleIncome).toLowerCase(),
        })}
        description={
          <Trans components={{ bold: <strong />, list: <UnorderedList />, listItem: <li /> }}>
            {t(description.transKey, description.defaultString)}
          </Trans>
        }
        onNext={onNext}
        nextText={t('common.buttons.continue', 'Continue')}
        disableNext={option === undefined}
        onBack={onBack}
        backText={t('common.buttons.back', 'Back')}
        loading={loadingIllustrationPlanData || loadingFundDetails}
      >
        <ContentWrapper>
          <SelectableCard>
            <FundCardContent {...fundDetails} />
          </SelectableCard>
          <FormOptionsGroup name={inputName} fullWidth options={options} />
        </ContentWrapper>
      </JourneyStepLayout>
    </FormProvider>
  )
}
