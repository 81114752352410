import type { CountryCode } from 'libphonenumber-js'

export enum ContactField {
  email = 'email',
  telephone = 'telephone',
  telephoneCountryCode = 'telephoneCountryCode',
}

// note: fields order determines getAddressString string value
export enum AddressField {
  line1 = 'addressLine1',
  line2 = 'addressLine2',
  line3 = 'addressLine3',
  city = 'city',
  county = 'county',
  postcode = 'postcode',
}

export enum PersonalDetail {
  forename = 'forename',
  surname = 'surname',
  gender = 'gender',
  NIN = 'nationalInsuranceNumber',
  DOB = 'bornOn',
}

export const SUGGESTED_COUNTRIES_ISO_CODES: CountryCode[] = ['GB', 'FR', 'IT', 'PT', 'ES']

export const AGE_MAX_LENGTH = 2
