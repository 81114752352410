import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Warning: FC<TIconColors> = props => {
  const {
    colors: { warning, white },
  } = useThemeContext()
  const { base = warning } = props

  return (
    <svg x="0px" y="0px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Warning" />
      <g>
        <defs>
          <rect x="-465.8" y="-2790" width="1440" height="4347" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
      </g>
      <g>
        <defs>
          <rect x="-465.8" y="-2790" width="1440" height="4347" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
      </g>
      <g>
        <defs>
          <ellipse cx="11" cy="11" rx="11" ry="10.9" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
        <g>
          <defs>
            <rect x="-190.8" y="-735.8" width="1453.7" height="1364.1" />
          </defs>
          <clipPath>
            <use style={{ overflow: 'visible' }} />
          </clipPath>
        </g>
      </g>
      <path style={{ fill: base }} d="M11,22c6.1,0,11-4.9,11-11S17.1,0,11,0S0,4.9,0,11S4.9,22,11,22z" />
      <rect x="9.8" y="4.6" style={{ fill: white }} width="2.4" height="8.1" />
      <circle style={{ fill: white }} cx="11" cy="15.8" r="1.3" />
    </svg>
  )
}
