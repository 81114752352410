import { Currency } from '@retire/components/Formatter/Currency'
import { FormNumberInput } from '@retire/components/Forms/NumberInput'
import { Spacer } from '@retire/components/Spacer'
import { PotName } from '@retire/constants/pots'
import { NONNUMERIC_CHAR_REGEX } from '@retire/constants/regex'
import { AGE_MAX_LENGTH } from '@retire/constants/user'
import { useBlockOnKeyDown } from '@retire/hooks/useBlockOnKeyDown'
import { useBlockOnMaxLength } from '@retire/hooks/useBlockOnMaxLength'
import { useBlockOnPaste } from '@retire/hooks/useBlockOnPaste'
import { useFlexibleIncomePotAgeValidationRules } from '@retire/hooks/useFlexibleIncomePotAgeValidationRules'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { isRequired } from '@retire/utils'
import { toCurrency, toPennies, toPounds } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'
import { usePotInsufficientSavings } from '../../../../hooks/usePotInsufficientSavings'
import { PotEstimatedMonthlyIncome } from './PotEstimatedMonthlyIncome'
import type { TFlexibleIncomePotConfigurationFormFields } from './types'

export const FlexibleIncomePotConfiguration: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.incomePots })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const {
    [PotName.flexibleIncome]: { minAmount },
  } = useSettings()

  const {
    loadingIllustrationPlanData,
    illustrationPlanData,
    savePartialIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
  } = useIllustrationPlanData()
  const { remainingSavings } = illustrationPlanData

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      flexibleIncomeAmount: null,
      regularIncomeStartAge: null,
      regularIncomeEndAge: null,
      laterLifeAmount: null,
      laterLifeAge: null,
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  usePotInsufficientSavings({
    remainingSavings,
    potName: PotName.flexibleIncome,
    insufficientSavingsPath: paths.illustration.journeyFlexibleIncomePotInsufficientSavings,
    skip: loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData,
  })

  const methods = useForm<TFlexibleIncomePotConfigurationFormFields>({ mode: 'onChange' })
  const {
    trigger: triggerValidation,
    watch,
    formState: { isValid, isValidating },
  } = methods
  const amount = watch('amount')
  const startAge = watch('startAge')
  const endAge = watch('endAge')

  useEffect(() => {
    void triggerValidation() // re-trigger validation of the whole fields
  }, [endAge, startAge, triggerValidation])

  const { startAgeRules, endAgeRules } = useFlexibleIncomePotAgeValidationRules(startAge, endAge)
  const amountRules = useMemo(
    () => ({
      min: {
        value: toPounds(minAmount),
        message: t('steps.potSteps.potConfiguration.validations.min', 'Amount must be {{minAmount}} or above', {
          minAmount: toCurrency(minAmount || 0),
        }),
      },
      max: {
        value: toPounds(remainingSavings),
        message: t('steps.potSteps.potConfiguration.validations.max', 'Amount must be {{maxAmount}} or below', {
          maxAmount: toCurrency(remainingSavings),
        }),
      },
      validate: {
        required: isRequired(),
      },
    }),
    [minAmount, remainingSavings, t]
  )

  const blockOnMaxLength = useBlockOnMaxLength(AGE_MAX_LENGTH)
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_CHAR_REGEX, blockOnMaxLength)
  const onPaste = useBlockOnPaste({ maxLength: AGE_MAX_LENGTH })

  const onNext = useCallback(
    async () =>
      await savePartialIllustrationPlanData(
        {
          ...illustrationPlanData,
          flexibleIncomeAmount: toPennies(amount),
          regularIncomeStartAge: startAge ? Number(startAge) : null,
          regularIncomeEndAge: endAge ? Number(endAge) : null,
        },
        paths.illustration.journeyLaterLifePotIntro
      ),
    [amount, endAge, illustrationPlanData, savePartialIllustrationPlanData, startAge]
  )
  const onBack = useCallback(() => navigate(paths.illustration.journeyFlexibleIncomePotIntro), [navigate])

  return (
    <FormProvider {...methods}>
      <JourneyStepLayout
        title={t('steps.potSteps.flexibleIncomePot.title', `Choose how much to put in ${PotName.flexibleIncome} pot`)}
        onNext={onNext}
        nextText={t('steps.potSteps.potConfiguration.actions.accept', 'Accept')}
        disableNext={!isValid}
        onBack={onBack}
        backText={t('steps.potSteps.potConfiguration.actions.cancel', 'Cancel')}
        loading={loadingIllustrationPlanData}
      >
        <FormNumberInput
          name="amount"
          isCurrency
          label={t('steps.potSteps.flexibleIncomePot.amount.label', 'Pot amount')}
          subLabel={
            <Trans
              i18nKey="steps.potSteps.flexibleIncomePot.amount.subLabel"
              components={[<Currency key="minimum-value" />]}
            >
              {t('steps.potSteps.flexibleIncomePot.amount.subLabel', 'Minimum amount is {{minAmount}}', {
                minAmount,
              })}
            </Trans>
          }
          rules={amountRules}
        />
        <Spacer bottom="medium" />
        <FormNumberInput
          name="startAge"
          label={t('steps.potSteps.flexibleIncomePot.startAge.label', 'Pot start age')}
          precision={0}
          withoutThousandDelimiter
          required
          rules={startAgeRules}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
        />
        <Spacer bottom="medium" />
        <FormNumberInput
          name="endAge"
          label={t('steps.potSteps.flexibleIncomePot.endAge.label', 'Pot end age')}
          precision={0}
          withoutThousandDelimiter
          required
          rules={endAgeRules}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
        />
        {!!amount && isValid && !isValidating && (
          <PotEstimatedMonthlyIncome amount={amount} startAge={startAge} endAge={endAge} />
        )}
      </JourneyStepLayout>
    </FormProvider>
  )
}
