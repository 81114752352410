import { useFocus } from '../hooks'
import { ConfirmModal } from './presentation'
import type { TConfirmModalContainer } from './types'

export const ConfirmModalContainer = (props: TConfirmModalContainer) => {
  const { modalOpenerRef } = props
  const buttonRef = useFocus<HTMLButtonElement>(modalOpenerRef)

  return <ConfirmModal buttonRef={buttonRef} {...props} />
}
