import { Divider } from '@retire/components/Divider'
import { Link } from '@retire/components/Link'
import { NavButtons } from '@retire/components/NavButtons'
import { Notification } from '@retire/components/Notification'
import { PageTitle } from '@retire/components/PageTitle'
import { Spacer } from '@retire/components/Spacer'
import { PotName } from '@retire/constants/pots'
import { useFormDefaultValues } from '@retire/hooks/useFormDefaultValues'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { HeadlineLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { TConfirmPageLocationState, TMoveMoneyFormFields } from '../../components/MoveMoneyBetweenPots'
import {
  AmountOptionsGroup,
  IncomePotEstimatedChanges,
  MoveMoneyFrom,
  MoveMoneyTo,
} from '../../components/MoveMoneyBetweenPots'
import { usePotsContext } from '../../hooks/usePotsContext'

export const MoveMoneyBetweenPots: FC = () => {
  useActivatePageOverlay()
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const { pots } = usePotsContext()
  const {
    data: { currentAge, regularIncomeEndAge },
  } = useUserDetails()

  const [defaultValues, setDefaultValues] = useFormDefaultValues<TMoveMoneyFormFields>({
    checkValuesValidity: ({ fromPot, toPotOrAccount }: TMoveMoneyFormFields) => {
      if (fromPot && pots[fromPot]?.isLockedForBuys) {
        return false
      } else if (toPotOrAccount && pots[toPotOrAccount]?.isLockedForSells) {
        return false
      } else if (!toPotOrAccount && !fromPot) {
        return false
      }
      return true
    },
  })

  const methods = useForm<TMoveMoneyFormFields>({
    mode: 'all',
    defaultValues,
  })

  const { fromPot: selectedFromPot, toPotOrAccount: selectedToPot } = methods.watch()

  const onSubmit = useCallback(
    ({ fromPot, toPotOrAccount, amount, moveMoneyMethod }: TMoveMoneyFormFields) => {
      setDefaultValues({ fromPot, toPotOrAccount, amount, moveMoneyMethod })

      navigate(paths.dashboard.confirmMoveMoneyRequest, {
        state: {
          fromPot,
          toPotOrAccount,
          amount,
        },
      } as TConfirmPageLocationState)
    },
    [navigate, setDefaultValues]
  )

  const onContinueClick = methods.handleSubmit(onSubmit)
  const onCancelClick = useCallback(() => navigate(-1), [navigate])
  const handleQuit = useCallback(() => navigate(-2), [navigate])

  const hasReachedRegularIncomeEndAge = useMemo(
    () => selectedToPot === PotName.flexibleIncome && currentAge > regularIncomeEndAge,
    [currentAge, regularIncomeEndAge, selectedToPot]
  )
  const navigateToFlexibleIncomePotManageIncomePeriod = useCallback(() => {
    navigate(paths.dashboard.flexibleIncomePotManageIncomePeriod)
  }, [navigate])

  return (
    <FormProvider {...methods}>
      <PageTemplate
        fullWidth
        title={t('pots.common.moveMoneyBetweenPots.title', 'Move money title')}
        onQuit={handleQuit}
      >
        {hasReachedRegularIncomeEndAge && (
          <Notification
            title={t(
              'dashboard:pots.common.moveMoneyBetweenPots.hasReachedRegularIncomeEndAge.notification.title',
              'Your current age is higher than your regular income end age.'
            )}
            type="error"
            hasBorder
          >
            <Trans components={{ periodLink: <Link as="a" onClick={navigateToFlexibleIncomePotManageIncomePeriod} /> }}>
              {t(
                'dashboard:pots.common.moveMoneyBetweenPots.hasReachedRegularIncomeEndAge.notification.content',
                'Please change <periodLink>your regular income end age</periodLink> before continuing with this.'
              )}
            </Trans>
          </Notification>
        )}
        <Spacer bottom="large" />
        <MoveMoneyFrom />
        <MoveMoneyTo />
        <Spacer top="extraLarge" />
        <Divider />
        <Spacer bottom="extraLarge" />
        <PageTitle
          title={t('pots.common.moveMoneyBetweenPots.amountSection.title', 'Amount')}
          headlineAs="h2"
          headlineLevel={HeadlineLevel.h2}
        />
        <AmountOptionsGroup />
        <Spacer bottom="extraLarge" />
        <IncomePotEstimatedChanges potName={selectedFromPot} />
        <IncomePotEstimatedChanges potName={selectedToPot} />
        <NavButtons
          onNext={onContinueClick}
          onBack={onCancelClick}
          disableNext={!methods.formState.isValid || hasReachedRegularIncomeEndAge}
          nextText={t('pots.common.moveMoneyBetweenPots.continue', 'Continue')}
          backText={t('pots.common.moveMoneyBetweenPots.back', 'Back')}
        />
      </PageTemplate>
    </FormProvider>
  )
}
