import { styled } from '@retire/theme/styledComponents'

export const SAddressResult = styled.li`
  border: none;
  font-family: ${({ theme: { buttons } }) => buttons.family};
  font-size: ${({ theme: { buttons } }) => buttons.size.default.size};
  background-color: ${props => props.theme.addressLookup.results.backgroundColor};
  padding: ${props => props.theme.spacing.medium};
  cursor: pointer;
  text-align: left;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background: ${props => props.theme.addressLookup.results.alternateBackgroundColor};
  }

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.addressLookup.results.borderColor};
  }
`
