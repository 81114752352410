import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

import { AllSavingsAssigned } from '../../../components/Journey/Ready/AllSavingsAssigned/presentation'
import { PreviewRemaining } from '../../../components/Journey/Ready/PreviewRemaining/presentation'
import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'

export const Ready: FC = () => {
  const { t } = useTranslationWithOptions('illustration')
  const {
    loadingIllustrationPlanData,
    illustrationPlanData: { remainingSavings },
  } = useIllustrationPlanData()

  if (loadingIllustrationPlanData) {
    return <PageTemplate title={t('pageTitle.journey', 'Journey page title')} fullWidth loading />
  }

  return remainingSavings === 0 ? <AllSavingsAssigned /> : <PreviewRemaining />
}
