import type { TIconSizes } from '@retire/types/icons'

import { colors } from './colors'
import { icons } from './icons'
import { spacing } from './spacing'
import { utilities } from './utilities'

export const forms = {
  label: {
    fontWeight: utilities.weight.semiBold,
    fontSize: '1.125rem',
    lineHeight: '1.687rem',
    color: colors.neutralDark,
  },
  subLabel: {
    fontWeight: utilities.weight.regular,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: colors.neutral,
  },
  shared: {
    border: utilities.border,
    states: {
      default: {
        backgroundColor: colors.white,
        borderColor: colors.neutral30,
        color: colors.primary,
      },
      hover: {
        borderColor: colors.neutral50,
      },
      focus: {
        borderColor: colors.primary,
      },
      active: {
        borderColor: colors.secondary,
      },
      disabled: {
        backgroundColor: colors.neutral20,
        borderColor: colors.neutral30,
        color: colors.neutral50,
      },
      error: {
        borderColor: colors.error,
      },
    },
  },
  dropdown: {
    paddingSides: spacing.medium,
    iconSize: icons.size.small,
    states: {
      active: {
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        color: colors.white,
      },
    },
    menuList: {
      height: '200px',
      scrollbar: utilities.scrollbar,
    },
    option: {
      fontSize: '1.125rem',
      border: {
        ...utilities.border,
        width: '1px',
        color: colors.neutral30,
      },
      divider: {
        color: colors.primary,
      },
      chosen: {
        color: colors.primary,
      },
      padding: `${spacing.medium} ${spacing.large}`,
      states: {
        default: {
          color: colors.neutral50,
        },
        selected: {
          backgroundColor: colors.infoBackground,
          color: colors.primary,
          fontWeight: utilities.weight.bold,
        },
        active: {
          backgroundColor: colors.rowHover,
          color: colors.primary,
          fontWeight: utilities.weight.semiBold,
        },
      },
    },
  },
  errors: {
    color: colors.error,
    fontSize: '1rem',
    fontWeight: utilities.weight.bold,
    lineHeight: '1.5rem',
    iconSize: 'extraSmall' as TIconSizes,
  },
  input: {
    fontSize: '1.125rem',
    textArea: {
      fontSize: '1rem',
    },
    fontWeight: utilities.weight.semiBold,
    padding: spacing.medium,
    paddingRight: spacing.none,
    percent: {
      width: '100px',
    },
    prefix: {
      paddingSides: spacing.extraLarge,
    },
  },
  checkboxradio: {
    spacing: spacing.small,
    states: {
      default: {
        backgroundColor: 'transparent',
        iconColor: colors.neutral30,
      },
      hover: {
        iconColor: colors.neutral50,
      },
      selected: {
        backgroundColor: colors.rowHover,
        borderColor: colors.secondary,
        iconColor: colors.primary,
      },
      focus: {
        iconColor: colors.primary,
      },
      disabled: {
        borderColor: colors.neutral20,
      },
    },
  },
  switcher: {
    activeBg: colors.primary,
    inactiveBg: colors.neutral10,
    width: '3.125rem',
    height: '1.875rem',
    borderWidth: '2px',
  },
}
