import { PotName } from '@retire/constants/pots'
import {
  isConditionTrueOrStrictlyLessThanMaxValue,
  isConditionTrueOrStrictlyMoreThanMinValue,
  isLessThanOrEqualToMaxValue,
  isMoreThanOrEqualToMinValue,
  isRequired,
} from '@retire/utils/rules'
import { useMemo } from 'react'

import { useSettings } from '../useSettings'
import { useTranslationWithOptions } from '../useTranslationWithOptions'
import { useUserDetails } from '../useUserDetails'
import type { TUseFlexibleIncomePotAgeValidationRules } from './types'

export const useFlexibleIncomePotAgeValidationRules: TUseFlexibleIncomePotAgeValidationRules = (
  currentStartAge,
  currentEndAge
) => {
  const { t } = useTranslationWithOptions('common')

  const {
    [PotName.flexibleIncome]: {
      minStartAge: settingsMinStartAge,
      maxStartAge: settingsMaxStartAge,
      minEndAge: settingsMinEndAge,
      maxEndAge: settingsMaxEndAge,
    },
  } = useSettings()
  const {
    data: { currentAge: currentUserAge },
  } = useUserDetails()
  const minStartAgeValue = Math.max(settingsMinStartAge, currentUserAge)

  const startAgeCopyVariables = useMemo(
    () => ({
      minAgeMinusOne: minStartAgeValue - 1,
      maxAgePlusOne: settingsMaxStartAge + 1,
    }),
    [settingsMaxStartAge, minStartAgeValue]
  )
  const startAgeRules = useMemo(
    () => ({
      validate: {
        required: isRequired(),
        aboveMinStartAge: isMoreThanOrEqualToMinValue({
          minValue: minStartAgeValue,
          message: t(
            'form.flexibleIncomePot.startAge.validations.minAge',
            'Start age must be more than {{minAgeMinusOne}} (and less than {{maxAgePlusOne}})',
            startAgeCopyVariables
          ),
        }),
        belowMaxStartAge: isLessThanOrEqualToMaxValue({
          maxValue: settingsMaxStartAge,
          message: t(
            'form.flexibleIncomePot.startAge.validations.maxAge',
            'Start age must be less than {{maxAgePlusOne}} (and more than {{minAgeMinusOne}})',
            startAgeCopyVariables
          ),
        }),
        belowEndAge: isConditionTrueOrStrictlyLessThanMaxValue({
          maxValue: Number(currentEndAge),
          message: '',
          condition: !currentEndAge,
        }),
      },
    }),
    [currentEndAge, settingsMaxStartAge, minStartAgeValue, startAgeCopyVariables, t]
  )

  const endAgeCopyVariables = useMemo(
    () => ({
      minAgeMinusOne: Math.max(settingsMinEndAge, currentUserAge + 1, Number(currentStartAge) + 1) - 1,
      maxAgePlusOne: settingsMaxEndAge + 1,
    }),
    [currentStartAge, currentUserAge, settingsMaxEndAge, settingsMinEndAge]
  )

  const endAgeRules = useMemo(
    () => ({
      validate: {
        required: isRequired(),
        aboveMinEndAge: isMoreThanOrEqualToMinValue({
          minValue: Math.max(settingsMinEndAge, currentUserAge + 1),
          message: t(
            'form.flexibleIncomePot.endAge.validations.minAge',
            'End age must be more than {{minAgeMinusOne}} (and less than {{maxAgePlusOne}})',
            endAgeCopyVariables
          ),
        }),
        belowMaxEndAge: isLessThanOrEqualToMaxValue({
          maxValue: settingsMaxEndAge,
          message: t(
            'form.flexibleIncomePot.endAge.validations.maxAge',
            'End age must be less than {{maxAgePlusOne}} (and more than {{minAgeMinusOne}})',
            endAgeCopyVariables
          ),
        }),
        aboveStartAge: isConditionTrueOrStrictlyMoreThanMinValue({
          minValue: Number(currentStartAge),
          message: t(
            'form.flexibleIncomePot.endAge.validations.minAge',
            'End age must be more than {{minAgeMinusOne}}',
            endAgeCopyVariables
          ),
          condition: !currentStartAge,
        }),
      },
    }),
    [currentStartAge, currentUserAge, settingsMaxEndAge, settingsMinEndAge, endAgeCopyVariables, t]
  )

  return {
    startAgeRules,
    endAgeRules,
  }
}
