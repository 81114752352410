import { PotName } from '@retire/constants/pots'
import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types/icons'
import type { FC } from 'react'

export const FlexibleIncome: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={`${PotName.flexibleIncome}-icon`}
    >
      <path
        d="M28.5716 5H4.28585C2.7079 5 1.42871 6.27919 1.42871 7.85714V25C1.42871 26.578 2.7079 27.8571 4.28585 27.8571H28.5716C30.1495 27.8571 31.4287 26.578 31.4287 25V7.85714C31.4287 6.27919 30.1495 5 28.5716 5Z"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4283 20.714C18.7952 20.714 20.714 18.7952 20.714 16.4283C20.714 14.0614 18.7952 12.1426 16.4283 12.1426C14.0614 12.1426 12.1426 14.0614 12.1426 16.4283C12.1426 18.7952 14.0614 20.714 16.4283 20.714Z"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 35.0003H35.7143C36.472 35.0003 37.1988 34.6993 37.7346 34.1634C38.2704 33.6276 38.5714 32.9009 38.5714 32.1431V17.8574"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
