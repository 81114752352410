import { LabelValue } from '@retire/components/LabelValue/presentation'
import { UnorderedList } from '@retire/components/List'
import { RiskRating } from '@retire/components/RiskRating/presentation'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { usePlanContext } from '../../../hooks/usePlanContext'
import { Bold } from '../Common/Bold/presentation'
import { HeadingThree } from '../Common/HeadingThree/presentation'
import { Paragraph } from '../Common/Paragraph/presentation'
import { Section } from '../Common/Section/presentation'
import { SubSection } from '../Common/SubSection/presentation'
import type { TLaterLifePot } from './types'

export const LaterLifePot: FC<TLaterLifePot> = ({ fundDetails: { name, riskLevel, description } }) => {
  const { t } = useTranslationWithOptions('plan')
  const {
    plan: { laterLifeAge, laterLifeMonthlyAmount, laterLifeAmount },
  } = usePlanContext()

  return (
    <Section>
      <SubSection>
        <Paragraph>
          {t('laterLifePot.description.paragraphOne', 'Later life pot - description paragraph one')}
        </Paragraph>
        <Paragraph>
          {t('laterLifePot.description.paragraphTwo', 'Later life pot - description paragraph two')}
        </Paragraph>
      </SubSection>
      <SubSection>
        <HeadingThree>{t('laterLifePot.youHaveAskedFor.heading', 'Later life pot - subheading')}</HeadingThree>
        <LabelValue
          label={t('laterLifePot.youHaveAskedFor.valueLabel', 'Later life pot - amount')}
          value={toCurrency(laterLifeAmount)}
        />
        <LabelValue
          label={t(
            `laterLifePot.youHaveAskedFor.monthlyIncomeLabel.${laterLifeMonthlyAmount ? 'default' : 'none'}`,
            `${
              laterLifeMonthlyAmount ? 'Later life pot - income from {{laterLifeAge}}' : 'Later life pot - no income'
            }`,
            {
              laterLifeAge,
            }
          )}
          value={toCurrency(laterLifeMonthlyAmount, 0)}
        />
        {!!laterLifeMonthlyAmount && (
          <Spacer bottom="medium">
            <Copy as="div">
              <Trans
                components={{ bold: <Bold />, list: <UnorderedList key="list" />, listItem: <li key="listItem" /> }}
              >
                {t(
                  'laterLifePot.youHaveAskedFor.description',
                  'Later life pot - you will receive income from {{laterLifeAge}}',
                  {
                    laterLifeAge,
                  }
                )}
              </Trans>
            </Copy>
          </Spacer>
        )}
      </SubSection>
      <SubSection>
        <HeadingThree>
          {t('laterLifePot.howYourMoneyIsInvested.heading', 'Later life pot - how your money is invested heading')}
        </HeadingThree>
        <Paragraph>
          <Trans components={[<Bold key="bold" />]}>
            {t('laterLifePot.howYourMoneyIsInvested.description', 'Later life pot - how your money is invested (p1)', {
              fundName: name,
            })}
          </Trans>
        </Paragraph>
        <Paragraph>{description}</Paragraph>
      </SubSection>
      <SubSection>
        <RiskRating rating={riskLevel} />
      </SubSection>
    </Section>
  )
}
