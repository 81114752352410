import { useMutation } from '@apollo/client'
import { Copy, Headline, Link, Segment, Spacer } from '@retire/components'
import { DateFormat } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { HeadlineLevel, TypographyColor } from '@retire/theme'
import { formatDate } from '@retire/utils'
import { sanitize } from 'dompurify'
import type { FC } from 'react'
import { useEffect } from 'react'

import { MARK_MESSAGE_READ_MUTATION } from '../../gql/mutations'
import { MESSAGES_QUERY, UNREAD_MESSAGES_QUERY } from '../../gql/queries'
import { SMessage } from './presentation.styled'
import type { TMessage } from './types'

export const Message: FC<TMessage> = ({ id, sentAt, subject, body, read, files }) => {
  const { t } = useTranslationWithOptions('inbox')
  const [markMessageAsRead] = useMutation(MARK_MESSAGE_READ_MUTATION, {
    refetchQueries: [{ query: MESSAGES_QUERY }, { query: UNREAD_MESSAGES_QUERY }],
    errorPolicy: 'ignore',
  })

  useEffect(() => {
    !read && void markMessageAsRead({ variables: { id } })
  }, [id, markMessageAsRead, read])

  return (
    <Segment whiteBackground>
      <Headline level={HeadlineLevel.h3} as="h1">
        {subject}
      </Headline>
      <Spacer top="large" bottom="large">
        <div>
          <Copy as="label">
            <strong>{t('message.from', 'From')}</strong>
          </Copy>
          <Copy as="span">{t('message.fromValue', 'Smart')}</Copy>
        </div>
        <div>
          <Copy as="label">
            <strong>{t('message.date', 'Date')}</strong>
          </Copy>
          <Copy as="span">{formatDate(sentAt, DateFormat.longDatetime)}</Copy>
        </div>
      </Spacer>
      <ContentWrapper>
        <SMessage dangerouslySetInnerHTML={{ __html: body ? sanitize(body) : '' }} as="div" />
      </ContentWrapper>
      {files && files.length > 0 && (
        <Spacer top="large" bottom="large">
          <Headline color={TypographyColor.neutralDark} level={HeadlineLevel.h4} as="h2">
            {t('message.attachments', 'Attachments')}
          </Headline>
          {files.map(file => (
            <Spacer top="medium" key={file.filename}>
              <Link as="a" href={sanitize(file.url)} target="_blank">
                {file.filename}
              </Link>
            </Spacer>
          ))}
        </Spacer>
      )}
    </Segment>
  )
}
