import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

export const Clock: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <path
        fill="none"
        stroke={base}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2.1176"
        d="M1.176 16c0 3.931 1.562 7.702 4.342 10.482s6.55 4.342 10.482 4.342c3.931 0 7.702-1.562 10.482-4.342s4.342-6.55 4.342-10.482c0-3.931-1.562-7.702-4.342-10.482s-6.55-4.342-10.482-4.342c-3.931 0-7.702 1.562-10.482 4.342s-4.342 6.55-4.342 10.482v0z"
      />
      <path
        fill="none"
        stroke={base}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2.1176"
        d="M16 16v-5.294"
      />
      <path
        fill="none"
        stroke={base}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2.1176"
        d="M16 16l6.617 6.618"
      />
    </svg>
  )
}
