import type { TMergeObject, TObject, TObjectOrArray } from '@retire/types/object'
import { cloneDeep, isEmpty, mergeWith } from 'lodash'

export const getNextObjectKey = <TO extends TObject = TObject>(
  object: TO,
  currentKey: number | string
): number | string | undefined => {
  let found = false
  let nextKey: string | number | undefined

  Object.keys(object).forEach(key => {
    if (found && !nextKey) {
      nextKey = key
    }
    found = key === currentKey
  })
  return nextKey
}

export const filterObjectKeys = <TO extends TObjectOrArray = TObjectOrArray>(
  objectOrArray: TO,
  keys: string[],
  exclusiveMode = false
): TO => {
  if (Array.isArray(objectOrArray)) {
    return objectOrArray.map(data => filterObjectKeys(data, keys, exclusiveMode)) as TO
  }
  const reviver = (key: string, value: unknown) => {
    if (exclusiveMode) {
      // keys are the one to be kept
      return key && !keys.includes(key) ? undefined : value
    } else {
      // keys are to be excluded
      return keys.includes(key) ? undefined : value
    }
  }
  return JSON.parse(JSON.stringify({ ...objectOrArray }), reviver)
}

export const mergeObjects = <
  TInput1 extends TMergeObject = TMergeObject,
  TInput2 extends TMergeObject = TMergeObject,
  TOutput extends TMergeObject = TMergeObject,
>(
  existing: TInput1,
  incoming: TInput2,
  overrideArrays = false
): TOutput => {
  const mergeWithCustom = (obj1: TMergeObject, obj2: TMergeObject) => mergeObjects(obj1, obj2, overrideArrays)
  let merged: TMergeObject

  if (isEmpty(existing) || null === existing || 'string' === typeof incoming) {
    // if existing is empty or string, incoming value is fully overriding it
    merged = cloneDeep(incoming)
  } else if (Array.isArray(existing) && Array.isArray(incoming)) {
    // if existing/incoming are arrays
    if (overrideArrays) {
      // override existing with incoming if requested
      merged = cloneDeep(incoming)
    } else {
      // - new incoming entries (indexes are not in existing) will be added
      // - common indexes will be merged
      merged = []
      const newEntries = incoming.slice(existing.length)
      existing.forEach((_, index) => {
        if (merged !== undefined) {
          if (index in incoming) {
            merged[index] =
              typeof existing[index] === 'object'
                ? mergeWith(cloneDeep(existing[index]), cloneDeep(incoming[index]), mergeWithCustom)
                : cloneDeep(existing[index])
          } else {
            merged[index] = cloneDeep(existing[index])
          }
        }
      })
      merged = [...merged, ...newEntries]
    }
  } else if (typeof existing === 'object') {
    // if existing is an object, run mergeObjects recursively on its keys
    merged = mergeWith({ ...existing }, { ...incoming }, mergeWithCustom)
  } else {
    // for other cases, existing value is kept
    merged = cloneDeep(existing)
  }
  return merged as TOutput
}
