import { disableAnimations, toCurrency } from '@retire/utils'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import { DEFAULT_PROPS } from './constants'
import type { TCurrencyComponent } from './types'

export const Currency: FC<TCurrencyComponent> = ({
  value,
  children,
  currency = DEFAULT_PROPS.currency,
  precision = DEFAULT_PROPS.precision,
  shouldAnimateNumber = DEFAULT_PROPS.shouldAnimateNumber,
  numberAnimationCounts = DEFAULT_PROPS.numberAnimationCounts,
  animationTimeLapse = DEFAULT_PROPS.animationTimeLapse,
  isSubUnit = DEFAULT_PROPS.isSubUnit,
  CustomFraction,
}) => {
  const givenValue = Number(children || value || 0)
  const currentValue = useRef<number | null>(null)
  const isAnimated = shouldAnimateNumber && !disableAnimations

  if (currentValue.current === null || !isAnimated) {
    currentValue.current = givenValue
  }

  const [dynamicValue, setDynamicValue] = useState<number>(currentValue.current)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    const changeDiff = Math.abs(givenValue - Number(currentValue.current))
    const intervalValue = changeDiff / Number(numberAnimationCounts)

    if (givenValue === currentValue.current) {
      return
    }

    const remainingDiff = Math.abs(dynamicValue - givenValue)

    if (remainingDiff < intervalValue) {
      setDynamicValue(givenValue)
      currentValue.current = givenValue
      return
    }

    if (dynamicValue !== givenValue) {
      timeout = setTimeout(() => {
        if (dynamicValue < givenValue) {
          const addedValue = dynamicValue + intervalValue
          setDynamicValue(addedValue > givenValue ? givenValue : addedValue)
        } else {
          const deductedValue = dynamicValue - intervalValue
          setDynamicValue(deductedValue < givenValue ? givenValue : deductedValue)
        }
      }, animationTimeLapse)
    }
    return () => {
      // avoid "Can't perform a React state update on an unmounted component" error
      timeout && clearTimeout(timeout)
    }
  }, [givenValue, dynamicValue, animationTimeLapse, numberAnimationCounts])

  return (
    <>
      {toCurrency(isAnimated ? dynamicValue : currentValue.current, precision, currency, isSubUnit)
        .split(/(\.\d*)/g)
        .map((digits, index) =>
          index === 1 && CustomFraction ? (
            // eslint-disable-next-line react/no-array-index-key
            <CustomFraction key={index}>{digits}</CustomFraction>
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>{digits}</span>
          )
        )}
    </>
  )
}
