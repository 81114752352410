import { graphql } from '@retire/gql/__generated__/gql'

export const MESSAGES_QUERY = graphql(`
  query getMessages {
    sentMessages {
      ...body__SentMessage
    }
  }
`)

export const UNREAD_MESSAGES_QUERY = graphql(`
  query getUnreadMessages {
    sentMessages {
      id
      read
    }
  }
`)

export const MESSAGE_QUERY = graphql(`
  query getMessage($id: ID!) {
    sentMessage(id: $id) {
      ...body__SentMessage
    }
  }
`)
