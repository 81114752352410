import { Card, Copy, Headline, Spacer } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { CopyLevel, HeadlineLevel, StyleWeight } from '@retire/theme'
import { TypographyColor } from '@retire/theme/colors'
import type { FC } from 'react'

import type { TOverviewCard } from './types'

export const OverviewCard: FC<TOverviewCard> = ({ amount, date }) => {
  const { t } = useTranslationWithOptions('dashboard')

  return (
    <Card dataCy="overview-card">
      <Headline as="h3" level={HeadlineLevel.h3}>
        {t('overviewCard.title', 'Pension savings')}
      </Headline>
      <Spacer bottom="medium" />
      <Copy as="p" level={CopyLevel.body2} weight={StyleWeight.semiBold} color={TypographyColor.primary}>
        {amount}
      </Copy>
      <Copy as="p" level={CopyLevel.body2} weight={StyleWeight.semiBold} color={TypographyColor.neutral}>
        {date}
      </Copy>
    </Card>
  )
}
