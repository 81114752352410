import { TEST_IDS } from '@retire/constants'
import { withUserTracking } from '@retire/hocs'
import type { FC } from 'react'
import { Container } from 'react-grid-system'

import { SContainer, SOverlay, SPageContainer } from '../common.styled'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { OutageBanner } from '../OutageBanner'
import { SkipBanner } from '../SkipBanner'
import { CustomHeaderProvider, PageBarColorConsumer, PageBarColorProvider } from './context'
import { useIsOffline } from './hooks/useIsOffline'
import { SSection } from './presentation.styled'
import type { TPageLayout } from './types'

const PageLayoutComponent: FC<TPageLayout> = ({ hideFooter, whiteBg, children, ...headerProps }) => {
  const isOffline = useIsOffline()

  return (
    <SContainer>
      {isOffline && <OutageBanner />}
      <SContainer>
        {isOffline && <SOverlay data-testid={TEST_IDS.components.outageBannerOverlay} />}
        <SkipBanner />
        <CustomHeaderProvider>
          <Header {...headerProps} />
          <PageBarColorProvider>
            <PageBarColorConsumer>
              {({ color }) => (
                <SSection color={color} whiteBg={whiteBg}>
                  <Container>
                    <SPageContainer>{children}</SPageContainer>
                  </Container>
                </SSection>
              )}
            </PageBarColorConsumer>
          </PageBarColorProvider>
        </CustomHeaderProvider>
        {!hideFooter && <Footer />}
      </SContainer>
    </SContainer>
  )
}

export const PageLayout = withUserTracking(PageLayoutComponent)
