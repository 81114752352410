import { UserState } from '@retire/gql/__generated__/graphql'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { mainRootPath } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { escapeExceptApostrophe } from '@retire/utils/strings'
import type { FC } from 'react'
import { Navigate } from 'react-router-dom'

export const AwaitingApproval: FC = () => {
  const { t } = useTranslationWithOptions('enrolment')
  const {
    data: { state, forename },
    loading,
  } = useUserDetails()

  if (!loading && state !== UserState.AwaitingApproval) {
    return <Navigate to={mainRootPath} replace />
  }

  return (
    <PageTemplate
      title={t('awaitingApproval.title', {
        firstName: forename,
        interpolation: { escape: escapeExceptApostrophe },
      })}
      subTitle={t('awaitingApproval.description')}
      loading={loading}
    />
  )
}
