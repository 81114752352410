import { styled } from '@retire/theme/styledComponents'

export const SIconButton = styled.button`
  display: flex;
  align-items: center;
  background-color: inherit;
  border: none;
  cursor: pointer;
  padding: 0;
`
