import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import type { THeadline } from './types'

export const heading = css`
  font-style: normal;
  vertical-align: text-bottom;
`

export const Headline = styled.div<THeadline>`
  ${({
    theme: {
      colors,
      typography: { headline },
    },
    color,
    center,
    level,
  }) => css`
    position: relative;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    font-family: ${headline.level[level].family};
    font-size: ${headline.level[level].size};
    line-height: ${headline.level[level].lineHeight};
    letter-spacing: ${headline.level[level].letterSpacing};
    font-weight: ${headline.level[level].weight};
    justify-content: ${center ? 'center' : 'start'};
    color: ${color ? colors[color] : headline.color};

    ${mediaStyles(
      {
        fontSize: headline.level[level].mobile.size,
        lineHeight: headline.level[level].mobile.lineHeight,
        letterSpacing: headline.level[level].mobile.letterSpacing,
      },
      { max: Breakpoint.small }
    )}}
  `}
`
