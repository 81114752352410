import { PotName } from '@retire/constants/pots'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

import { BalanceCard } from '../../components/PotDetails/BalanceCard/presentation'
import { LaterLifePotFundsCard } from '../../components/PotDetails/LaterLifePotFundsCard'
import { LaterLifePotMonthlyIncomeCard } from '../../components/PotDetails/LaterLifePotMonthlyIncomeCard/presentation'
import { PageTitle } from '../../components/PotDetails/PageTitle/presentation'
import { PendingAmount } from '../../components/PotDetails/PendingAmount/presentation'
import { WhatItsForCard } from '../../components/PotDetails/WhatItsForCard/presentation'
import { usePendingTransactions } from '../../hooks/usePendingTransactions'
import { usePotDetails } from '../../hooks/usePotDetails'

export const LaterLifePotDetails: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')

  const {
    loadingPotDetails,
    potDetails: { isLockedForSells, isLockedForBuys, allocatedAmount = 0, projectedMonthlyIncome, laterLifeAge },
  } = usePotDetails(PotName.laterLife)
  const {
    loading: loadingPendingTransactions,
    data: { pendingAmounts },
  } = usePendingTransactions()

  const potLocked = isLockedForSells || isLockedForBuys
  const title = t('pots.laterLifePot.title', `${PotName.laterLife} details`, {
    ...(potLocked && { locked: t('pots.common.lockedShort', 'locked') }),
  })

  return (
    <PageTemplate fullWidth title={<PageTitle title={title} variant={PotName.laterLife} />} loading={loadingPotDetails}>
      <WhatItsForCard potName={PotName.laterLife} />
      <BalanceCard balance={allocatedAmount} buttonText={t('pots.common.balanceCard.actions.moveMoney', 'Move money')}>
        <PendingAmount loading={loadingPendingTransactions} pendingAmount={pendingAmounts[PotName.laterLife]} />
      </BalanceCard>
      <LaterLifePotMonthlyIncomeCard
        allocatedAmount={allocatedAmount}
        startAge={laterLifeAge}
        monthlyIncomeAmount={projectedMonthlyIncome}
      >
        {/* @TODO: will be brought back in this ticket https://smartpension.atlassian.net/browse/VK-508 */}
        {/* <GetTransfer /> */}
      </LaterLifePotMonthlyIncomeCard>
      <LaterLifePotFundsCard />
    </PageTemplate>
  )
}
