import { styled } from '@retire/theme/styledComponents'

import { DEFAULT_PROPS } from './constants'
import type { TFullScreenModal } from './types'

export const SFullScreenModal = styled.div<TFullScreenModal>`
  display: flex;
  justify-content: ${({ justifyContent = DEFAULT_PROPS.justifyContent }) => justifyContent};
  align-items: ${({ alignItems = DEFAULT_PROPS.alignItems }) => alignItems};
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.modal.standardModal.backgroundColor};
  overflow: hidden scroll;
  z-index: 1000;
`
