import { Headline, Spacer } from '@retire/components'
import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'

import type { THeadingTwo } from '../types'

export const HeadingTwo: FC<THeadingTwo> = props => (
  <Spacer bottom="extraLarge">
    <Headline level={HeadlineLevel.h3} as="h2" {...props} />
  </Spacer>
)
