import { styled } from '@retire/theme/styledComponents'

import type { TSelect } from '../types'
import { CLASSNAME_PREFIX } from './constants'

export const SSelectWrapper = styled.div<Pick<TSelect, 'disabled' | 'isOpen'>>`
  ${({
    theme: {
      forms: {
        input: { fontSize },
        shared: {
          border: menuBorder,
          states: {
            active: { borderColor: activeBorderColor },
            disabled: { color: disabledColor, backgroundColor: disabledBackgroundColor },
          },
        },
        dropdown: {
          menuList: { height, scrollbar },
          states: {
            active: { color: dropdownActiveColor, backgroundColor: dropdownActiveBackgroundColor },
          },
          option: {
            chosen: { color: chosenOptionColor },
            fontSize: optionFontSize,
            padding: optionPadding,
            border: optionBorder,
            states: {
              default: { color: optionDefaultColor },
              active: {
                backgroundColor: optionActiveBackgroundColor,
                color: optionActiveColor,
                fontWeight: optionActiveFontWeight,
              },
            },
          },
        },
      },
      spacing,
      utilities: { weight },
    },
    isOpen,
  }) => `
    .${CLASSNAME_PREFIX}__control {
      padding: ${spacing.mediumSmall} ${spacing.medium};
      cursor: pointer;
      background-color: transparent;
      box-shadow: none;
      border-width: 0;
      border-radius: 0;
      flex-wrap: nowrap;

      &.${CLASSNAME_PREFIX}__control--is-disabled {
        background-color: ${disabledBackgroundColor};

        .${CLASSNAME_PREFIX}__placeholder {
          color: ${disabledColor};
        }
      }

      &.${CLASSNAME_PREFIX}__control--menu-is-open {
        background-color: ${dropdownActiveBackgroundColor};
      }
    }

    .${CLASSNAME_PREFIX}__single-value, .${CLASSNAME_PREFIX}__placeholder, .${CLASSNAME_PREFIX}__input {
      color: ${isOpen ? dropdownActiveColor : chosenOptionColor};
      max-width: none;

      &.${CLASSNAME_PREFIX}__single-value--is-disabled {
        color: ${disabledColor};
      }
    }

    .${CLASSNAME_PREFIX}__single-value {
      overflow: visible;
      position: static;
      transform: none;
      top: 0;
    }

    .${CLASSNAME_PREFIX}__value-container {
      padding-left: 0;
      font-size: ${fontSize};
      font-weight: ${weight.semiBold};
      font-family: inherit;
      flex-wrap: nowrap;
      overflow: visible;

      .${CLASSNAME_PREFIX}__input-container {
        color: ${isOpen ? dropdownActiveColor : chosenOptionColor};
      }
    }

    .${CLASSNAME_PREFIX}__menu-list {
      height: ${height};
      border-width: ${menuBorder.width};
      border-style: ${menuBorder.style};
      border-radius: ${menuBorder.radius};
      border-color: ${activeBorderColor};
      padding: 0;

      ::-webkit-scrollbar {
        width: ${scrollbar.width};
      }
      ::-webkit-scrollbar-track {
        background: ${scrollbar.backgroundColor};
      }
      ::-webkit-scrollbar-thumb {
        background: ${scrollbar.color};
        border-radius: ${scrollbar.borderRadius};
      }
    }

    .${CLASSNAME_PREFIX}__option {
      padding: ${optionPadding};
      font-size: ${optionFontSize};
      font-family: inherit;
      cursor: pointer;
      color: ${optionDefaultColor};
      border-bottom: ${optionBorder.width} ${optionBorder.style} ${optionBorder.color};

      &:hover, &.${CLASSNAME_PREFIX}__option--is-focused {
        color: ${optionActiveColor};
        background-color: ${optionActiveBackgroundColor};
        font-weight: ${optionActiveFontWeight};
      }
    }
  `}
`
