import type { FC, ReactNode } from 'react'
import { createContext, useCallback, useMemo, useState } from 'react'

import type { TCustomHeaderContext } from './types'

export const CustomHeaderContext = createContext<TCustomHeaderContext>({
  customHeader: undefined,
  setCustomHeader: () => null,
  resetCustomHeader: () => null,
  hideCustomHeader: () => null,
  showCustomHeader: () => null,
})

export const CustomHeaderProvider: FC = ({ children }) => {
  const [header, setHeader] = useState<ReactNode>()
  const [hidden, setHidden] = useState(false)
  const setCustomHeader = useCallback((customHeader: ReactNode) => setHeader(customHeader), [])
  const resetCustomHeader = useCallback(() => setHeader(undefined), [])
  const hideCustomHeader = useCallback(() => setHidden(true), [])
  const showCustomHeader = useCallback(() => setHidden(false), [])
  const value = useMemo(
    () => ({
      customHeader: hidden ? undefined : header,
      setCustomHeader,
      resetCustomHeader,
      hideCustomHeader,
      showCustomHeader,
    }),
    [hidden, header, setCustomHeader, resetCustomHeader, hideCustomHeader, showCustomHeader]
  )

  return <CustomHeaderContext.Provider value={value}>{children}</CustomHeaderContext.Provider>
}

export const CustomHeaderConsumer = CustomHeaderContext.Consumer
