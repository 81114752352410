import type { SyntheticEvent } from 'react'
import { forwardRef } from 'react'

import { SLink } from './presentation.styled'
import type { TLink } from './types'

export const Link = forwardRef<HTMLButtonElement, TLink>((props, ref) => {
  const { disabled, target, href, onClick, children, rel } = props
  const hrefProp =
    disabled || !href
      ? {
          onClick: (e: SyntheticEvent) => {
            e.preventDefault()
            !disabled && onClick && onClick(e)
          },
          href: '#',
        }
      : {}

  return (
    <SLink
      ref={ref}
      title={String(children)}
      {...props}
      {...hrefProp}
      rel={target === '_blank' ? 'noopener noreferrer' : rel}
    />
  )
})

Link.displayName = 'Link'
