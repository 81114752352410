import { CopyLevel, StyleWeight, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { components } from 'react-select'

import { Copy } from '../../../../../Typography'
import type { TOptionProps } from './types'

export const Option: FC<TOptionProps> = ({ data: { subLabels = [], label, isDisabled }, ...props }) => (
  <components.Option data={{ subLabels, label, isDisabled }} {...props}>
    <Copy
      as="div"
      level={CopyLevel.body2}
      color={TypographyColor[isDisabled ? 'neutral' : 'primary']}
      weight={StyleWeight.semiBold}
    >
      {label}
    </Copy>
    {subLabels.map(subLabel => (
      <Copy color={TypographyColor.neutral} as="div" key={subLabel} level={CopyLevel.subLabel}>
        {subLabel}
      </Copy>
    ))}
  </components.Option>
)
