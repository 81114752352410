import { Spacer } from '@retire/components/Spacer'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { FlexibleIncomePotReviewTable } from '../FlexibleIncomePotReviewTable/presentation'
import { ROWS_BY_ORDER } from './constants'
import { USER_CUSTOM_MONTHLY_INCOME_MUTATION } from './gql/mutations'
import type { TFlexibleIncomePotAmountReview } from './types'

export const FlexibleIncomePotAmountReview: FC<TFlexibleIncomePotAmountReview> = ({
  startAge,
  endAge,
  monthlyIncome,
  onShowModal,
}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const [updateMonthlyIncome, { loading: updatingMonthlyIncome }] = useMutationWithError(
    USER_CUSTOM_MONTHLY_INCOME_MUTATION
  )

  const onContinue = useCallback(async () => {
    await updateMonthlyIncome({
      variables: {
        regularIncomeAmount: monthlyIncome.new,
        regularIncomeStartAge: startAge.new,
        regularIncomeEndAge: endAge.new,
      },
      onCompleted: () => {
        onShowModal()
      },
    })
  }, [endAge.new, monthlyIncome.new, onShowModal, startAge.new, updateMonthlyIncome])

  const onQuit = useCallback(() => {
    navigate(-3)
  }, [navigate])

  const onBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <PageTemplate
      fullWidth
      title={t('pots.common.reviewChanges.title', 'Review')}
      onNext={onContinue}
      onQuit={onQuit}
      onBack={onBack}
      nextText={t('pots.common.reviewChanges.actions.complete', 'Complete')}
      backText={t('pots.common.reviewChanges.actions.back', 'Back')}
      loading={updatingMonthlyIncome}
    >
      <Spacer top="large" bottom="large">
        <FlexibleIncomePotReviewTable
          rowsByOrder={ROWS_BY_ORDER}
          startAge={startAge}
          endAge={endAge}
          monthlyIncome={monthlyIncome}
        />
      </Spacer>
    </PageTemplate>
  )
}
