import type { FC } from 'react'
import { Fragment } from 'react'

import { Divider } from '../../Divider'
import { Spacer } from '../../Spacer'
import type { TTable } from '../types'
import { SCaptionContent, STable, STDCell } from './presentation.styled'
import type { TTDCell } from './types'

export const MobileTable: FC<TTable> = ({ rows, columnHeaders, verticalAlign, showExtremeBorders }) => {
  const hasRowHeaders = rows.some(row => row.rowHeader && row.rowHeader.content)
  const Cell: FC<TTDCell> = ({ children, bottom, ...props }) => (
    <STDCell verticalAlign={verticalAlign} {...props}>
      <Spacer bottom={bottom || 'medium'}>{children}</Spacer>
    </STDCell>
  )
  return (
    <>
      {rows &&
        rows.map(({ cells, rowHeader, beforeMobileRow, subCells }, rowIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Spacer key={rowIndex} bottom="medium">
            {showExtremeBorders && !beforeMobileRow && <Divider bottom="large" />}
            <STable>
              {rowHeader && rowHeader.content && (
                <caption>
                  <SCaptionContent bottom="medium">{rowHeader.content}</SCaptionContent>
                </caption>
              )}
              <tbody>
                {beforeMobileRow && (
                  <tr>
                    <Cell colSpan={2}>{beforeMobileRow}</Cell>
                  </tr>
                )}
                {cells.map(({ content }, cellIndex) => {
                  const columnHeaderIndex = hasRowHeaders ? cellIndex + 1 : cellIndex
                  const { content: columnHeaderContent } = columnHeaders[columnHeaderIndex]
                  const subContent = subCells && subCells[cellIndex] && subCells[cellIndex].content
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={`${rowIndex}-${cellIndex}`}>
                      <tr>
                        {!!columnHeaderContent && (
                          <Cell bottom={subContent ? 'none' : undefined} isHeader>
                            {columnHeaderContent}
                          </Cell>
                        )}
                        <Cell bottom={subContent ? 'none' : undefined}>{content}</Cell>
                      </tr>
                      {subContent ? (
                        <tr>
                          {!!columnHeaderContent && <Cell isHeader />}
                          <Cell>{subContent}</Cell>
                        </tr>
                      ) : null}
                    </Fragment>
                  )
                })}
              </tbody>
            </STable>
            {(!showExtremeBorders && rowIndex === rows.length - 1) || <Divider />}
          </Spacer>
        ))}
    </>
  )
}
