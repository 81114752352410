import { ButtonStyleType } from '@retire/constants/button'
import type { FC } from 'react'
import { useCallback } from 'react'

import { Button } from '../Button'
import { DEFAULT_PROPS } from './constants'
import { SNavButtons, SNextButton } from './presentation.styled'
import type { TNavButtons } from './types'

export const NavButtons: FC<TNavButtons> = ({
  onBack,
  backText,
  onNext,
  nextText,
  disableNext,
  nextButtonRef,
  backButtonRef,
  nextStyleType = DEFAULT_PROPS.nextStyleType,
  justifyContent = DEFAULT_PROPS.justifyContent,
}) => {
  const onClick = useCallback(
    event => {
      event.preventDefault()
      onNext && onNext()
    },
    [onNext]
  )

  return (
    <SNavButtons justifyContent={justifyContent}>
      {onNext && nextText && (
        <SNextButton>
          <Button
            ref={nextButtonRef}
            buttonStyleType={nextStyleType}
            onClick={onClick}
            disabled={disableNext}
            type="submit"
          >
            {nextText}
          </Button>
        </SNextButton>
      )}
      {onBack && backText && (
        <Button ref={backButtonRef} buttonStyleType={ButtonStyleType.secondary} onClick={onBack}>
          {backText}
        </Button>
      )}
    </SNavButtons>
  )
}
