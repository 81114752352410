import type { TPickAndRequired } from '@retire/types'
import {
  DEFAULT_CURRENCY,
  DEFAULT_DECIMAL_DELIMITER,
  DEFAULT_PRECISION,
  DEFAULT_THOUSAND_DELIMITER,
} from '@retire/utils'

import type { TFormNumberInput } from './types'

export const DEFAULT_PROPS: TPickAndRequired<
  TFormNumberInput,
  'thousandDelimiter' | 'decimalDelimiter' | 'currency' | 'precision' | 'rules'
> = {
  thousandDelimiter: DEFAULT_THOUSAND_DELIMITER,
  decimalDelimiter: DEFAULT_DECIMAL_DELIMITER,
  currency: DEFAULT_CURRENCY,
  precision: DEFAULT_PRECISION,
  rules: {},
}
