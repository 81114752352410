import { DatePart, PersonalDetail } from '@retire/constants'
import type { Beneficiary } from '@retire/gql/__generated__/graphql'
import { USER_BRIEF_DETAILS_QUERY } from '@retire/gql/user/queries'
import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'
import { filterObjectKeys } from '@retire/utils'

import type {
  TBeneficiariesMutationResponse,
  TBeneficiariesMutationVariables,
  TBeneficiariesQueryResponse,
} from './gql'
import { USER_BENEFICIARIES_MUTATION, USER_BENEFICIARIES_QUERY } from './gql'
import type { TBeneficiaries } from './types'
import { checkBeneficiariesShares, removeEmptyValuesFromBeneficiaries } from './utils'

const fetchData: TFormFetchData<TBeneficiaries> = async ({ apolloQuery }) => {
  const {
    data: {
      user: { beneficiaries },
    },
  } = await apolloQuery<TBeneficiariesQueryResponse>({ query: USER_BENEFICIARIES_QUERY })

  return { formData: { beneficiaries: filterObjectKeys<Beneficiary[]>(beneficiaries, ['__typename']) } }
}

const submitData: TFormSubmitData<TBeneficiaries> = async ({
  formData: { beneficiaries: initialData },
  apolloMutate,
}) => {
  const beneficiaries = removeEmptyValuesFromBeneficiaries(
    filterObjectKeys<Beneficiary[]>(initialData, [
      `${PersonalDetail.DOB}-${DatePart.day}`,
      `${PersonalDetail.DOB}-${DatePart.month}`,
      `${PersonalDetail.DOB}-${DatePart.year}`,
    ])
  ).map(beneficiary => ({ ...beneficiary, percentage: Number(beneficiary.percentage) }))

  // check percentages before submitting
  checkBeneficiariesShares(beneficiaries)
  await apolloMutate<TBeneficiariesMutationResponse, TBeneficiariesMutationVariables>({
    mutation: USER_BENEFICIARIES_MUTATION,
    variables: { beneficiaries },
    refetchQueries: [{ query: USER_BENEFICIARIES_QUERY }, { query: USER_BRIEF_DETAILS_QUERY }],
  })

  return { beneficiaries }
}

export const beneficiariesServices = {
  fetchData,
  submitData,
}
