import { Copy, UnorderedList } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { Paragraph, Section, SubSection } from '../Common'

export const Planning: FC = () => {
  const { t } = useTranslationWithOptions('plan')

  return (
    <Section>
      <SubSection>
        <Paragraph>{t('planning.description.paragraphOne', 'Planning - Income to last.')}</Paragraph>
      </SubSection>
      <SubSection>
        <Copy as="div">
          <Trans
            components={{
              list: <UnorderedList />,
              listItem: <li />,
            }}
          >
            {t('planning.description.paragraphTwo', 'Planning for later life items')}
          </Trans>
        </Copy>
      </SubSection>
    </Section>
  )
}
