import { colors } from './colors'
import { spacing } from './spacing'
import { utilities } from './utilities'

export const segment = {
  backgroundColor: colors.white,
  backgroundColorDefault: colors.white,
  borderColor: colors.neutral30,
  borderRadius: utilities.border.radius,
  borderWidth: '1px',
  marginBottom: spacing.large,
  paddingDesktop: spacing.large,
  paddingTouch: spacing.medium,
}
