import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

export const Edit: FC<TIconColors> = props => {
  const {
    colors: { error, warning, white },
  } = useThemeContext()
  const { base = error, accent = warning } = props

  return (
    <svg viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="edit-clippath">
          <rect style={{ fill: 'none' }} x="0.28" y="0.05" width="55.49" height="55.49" />
        </clipPath>
        <clipPath id="edit-clippath-3">
          <path
            style={{
              clipPath: 'url(#edit-clippath)',
              clipRule: 'evenodd',
              fill: 'none',
            }}
            d="M28,55.54A27.75,27.75,0,1,0,.28,27.79,27.74,27.74,0,0,0,28,55.54Z"
          />
        </clipPath>
        <clipPath id="edit-clippath-4">
          <path
            style={{
              clipPath: 'url(#edit-clippath)',
              clipRule: 'evenodd',
              fill: 'none',
            }}
            d="M23.86,40.4,16.75,41l-.62-7.11,13-15.45,7.73,6.48-13,15.46ZM34,12.66l7.73,6.48L38.45,23l-7.73-6.49L34,12.66Z"
          />
        </clipPath>
      </defs>
      <title>declaration_icon</title>
      <g style={{ fill: base, clipPath: 'url(#edit-clippath-3)' }}>
        <rect style={{ fill: accent }} x="-9.63" y="-9.86" width="75.31" height="75.31" />
      </g>
      <g style={{ fill: base, clipPath: 'url(#edit-clippath-4)' }}>
        <rect
          style={{ fill: white }}
          x="0.99"
          y="13.51"
          width="52.61"
          height="29.91"
          transform="translate(-12.05 31.07) rotate(-50)"
        />
      </g>
    </svg>
  )
}
