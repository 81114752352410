import { mainRootPath } from '@retire/navigation/paths'
import type { FC } from 'react'

import { SLogoContainer } from './presentation.styled'
import type { TLogoContainer } from './types'

export const LogoContainer: FC<TLogoContainer> = ({ children, dataCy, onClick }) => (
  <SLogoContainer
    as={onClick ? 'a' : 'div'}
    href={mainRootPath}
    onClick={onClick}
    clickable={!!onClick}
    data-cy={dataCy}
  >
    {children}
  </SLogoContainer>
)
