import { AddressField } from '@retire/constants'
import { USER_CONTACT_DETAILS_MUTATION } from '@retire/gql/user/mutations'
import {
  INIT_QUERY,
  USER_BRIEF_DETAILS_QUERY,
  USER_CONTACT_DETAILS_QUERY,
  USER_FULL_DETAILS_QUERY,
  USER_NAME_QUERY,
} from '@retire/gql/user/queries'
import type { TContactDetails } from '@retire/gql/user/types'
import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'

const fetchData: TFormFetchData<TContactDetails> = async ({ apolloQuery }) => {
  const {
    data: { user },
  } = await apolloQuery({ query: USER_CONTACT_DETAILS_QUERY })

  return { formData: user }
}

const submitData: TFormSubmitData<TContactDetails> = async ({ formData, apolloMutate }) => {
  const { telephone, telephoneCountryCode, addressLine1, addressLine2, addressLine3, city, county, postcode } = formData
  await apolloMutate({
    mutation: USER_CONTACT_DETAILS_MUTATION,
    variables: {
      input: {
        telephone,
        telephoneCountryCode,
        [AddressField.line1]: addressLine1,
        [AddressField.line2]: addressLine2,
        [AddressField.line3]: addressLine3,
        [AddressField.city]: city,
        [AddressField.county]: county,
        [AddressField.postcode]: postcode,
      },
    },
    refetchQueries: [
      { query: INIT_QUERY },
      { query: USER_NAME_QUERY },
      { query: USER_BRIEF_DETAILS_QUERY },
      { query: USER_FULL_DETAILS_QUERY },
    ],
  })

  return formData
}

export const contactDetailsServices = {
  fetchData,
  submitData,
}
