import type { ApolloError, Operation } from '@apollo/client'
import { isApolloError } from '@apollo/client'
import {
  ErrorCode,
  FORM_ERROR_CODES,
  GLOBAL_ERROR_EXCLUDED_CODES,
  SAVING_ERROR_EXCLUDED_CODES,
} from '@retire/constants'
import type { TErrorCode, TGeneralError } from '@retire/types'
import { GraphQLError } from 'graphql'
import Rollbar from 'rollbar'

import { sentenceCase } from '../strings'

export const createError = (message: string, code: ErrorCode = ErrorCode.internal) => {
  const error = new Error(message)
  error.name = code
  return error
}

export const getErrorCode = (error: ApolloError | Error): TErrorCode => {
  if (isApolloError(error)) {
    return (
      (error.graphQLErrors && (error.graphQLErrors[0]?.extensions?.code as ErrorCode)) ||
      (error.networkError &&
        ((Object.values(ErrorCode).includes(error.networkError.name as ErrorCode) && error.networkError.name) ||
          ErrorCode.network)) ||
      ErrorCode.unknown
    )
  }
  return error.name || ErrorCode.unknown
}

const getErrorMessage = (error: ApolloError | Error) => {
  if (isApolloError(error)) {
    return (
      (error.graphQLErrors && sentenceCase(error.graphQLErrors[0]?.message || '')) ||
      (error.networkError && sentenceCase(error.networkError.message || '')) ||
      ''
    )
  }
  return error.message || ''
}

export const getError = (error: ApolloError | Error): TGeneralError => ({
  code: getErrorCode(error),
  message: getErrorMessage(error),
})

export const isRetriesErrorCode = (code?: string) => code === ErrorCode.retries

export const isFormErrorCode = (code?: string) => code && FORM_ERROR_CODES.includes(code as ErrorCode)

export const isSavingErrorCode = (code?: string) => code && !SAVING_ERROR_EXCLUDED_CODES.includes(code as ErrorCode)

export const isGlobalErrorCode = (code?: string) => code && !GLOBAL_ERROR_EXCLUDED_CODES.includes(code as ErrorCode)

export const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV !== 'development' || process.env.REACT_APP_ROLLBAR_ACTIVE === 'true',
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_SOURCE_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore: (_, args) => {
    const [errorMessage] = args
    // ignore errors due to unlogged user
    return [
      'Cannot return null for non-nullable field Query.user',
      'Cannot return null for non-nullable field Query.sentMessages',
      'not logged in',
    ].includes(errorMessage as string)
  },
})

export const reportRollbarError = (error: GraphQLError | Error, operation?: Operation) => {
  if (error instanceof GraphQLError) {
    const code = error?.extensions?.code as ErrorCode
    if (!isFormErrorCode(code) && isSavingErrorCode(code) && code !== ErrorCode.network) {
      rollbar.debug(error.message, {
        operationName: operation?.operationName,
        operationVariables: operation?.variables,
        ...error,
      })
    }
  } else {
    rollbar.error(error.message, error)
  }
}
