import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const ArrowRight: FC<TIconColors> = props => {
  const {
    colors: { neutral60 },
  } = useThemeContext()
  const { base = neutral60 } = props

  return (
    <svg width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="ArrowRight" />
      <path d="M2 2L12.9091 14.2203L2 26" stroke={base} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
