import { Loading } from '@retire/components/Loading'
import { NotFoundByArea } from '@retire/features/HygienePages/pages/NotFoundByArea'
import type { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useValidPathsForState } from '../hooks/useValidPathsForState'
import { mainRootPath } from '../paths'

/**
 * This component manages the '/' path
 *   redirecting users that are not logged in to the login page
 *   or redirecting logged user to the area of the application that matches their current state
 */
export const DefaultRoute: FC = () => {
  const { pathname } = useLocation()
  const isRootPath = pathname === mainRootPath
  const { error, loading, validPaths } = useValidPathsForState(!isRootPath)

  if (isRootPath) {
    if (loading) {
      return <Loading />
    }
    if (validPaths) {
      // redirect user to the first accessible path in the journey
      return <Navigate to={validPaths[0]} replace />
    }
    if (!error) {
      return <Navigate to={mainRootPath} replace />
    }
    return null
  }

  return <NotFoundByArea />
}
