import { graphql } from '@retire/gql/__generated__/gql'

export const USER_FULL_DETAILS_MUTATION = graphql(`
  mutation updateUserFullDetails($input: UserAttributes!) {
    userUpdate(input: $input) {
      id
      ...name__User
      ...contact__User
      ...address__User
      gender
    }
  }
`)
