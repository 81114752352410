import { Icon, Spacer } from '@retire/components'
import type { FC } from 'react'
import { Row } from 'react-grid-system'

import { LowerFooterLinks } from './LowerFooterLinks'
import { SSmartLogo } from './presentation.styled'

export const LowerFooter: FC = () => (
  <>
    <Row>
      <Spacer bottom="mediumSmall">
        <SSmartLogo>
          <Icon iconType="SmartLogo" />
        </SSmartLogo>
      </Spacer>
    </Row>
    <LowerFooterLinks />
  </>
)
