import type { FC } from 'react'

import { JourneyOverviewAmount } from '../Amount'
import { JourneyOverviewDetails } from '../Details'
import type { TTotalSavingsDetails } from './types'

export const TotalSavingsDetails: FC<TTotalSavingsDetails> = ({ valuationAmount }) => (
  <JourneyOverviewDetails>
    <JourneyOverviewAmount amount={valuationAmount} />
  </JourneyOverviewDetails>
)
