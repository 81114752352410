import type { TIconContainer } from '@retire/components/Icon/types'
import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

export const SIconContainer = styled.div<TIconContainer>`
  ${({
    theme: {
      icons: { size: iconSize },
      spacing,
    },
  }) => css`
    width: ${iconSize.mediumSmall};
    height: ${iconSize.mediumSmall};
    margin-right: ${spacing.small};

    ${mediaStyles(
      {
        width: iconSize.medium,
        height: iconSize.medium,
        marginRight: spacing.medium,
      },
      {
        min: Breakpoint.small,
      }
    )}
  `}
`
