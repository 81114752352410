import {
  AdditionalPotName,
  POT_NAMES_BY_TYPE,
  PotName,
  PotType,
  TaxFreeLumpSumWithdrawalState,
} from '@retire/constants'
import { PotColor } from '@retire/theme'
import type { TAllPotVariants, TPotAmounts } from '@retire/types'

export const getPotAmountKeyFromPotName = (potName: PotName) => potName.replace('Pot', 'Amount') as keyof TPotAmounts

export const getTFLSStateFromAmount = (taxFreeLumpSumAmount: number | null) => {
  switch (taxFreeLumpSumAmount) {
    case null:
      return TaxFreeLumpSumWithdrawalState.undecided
    case 0:
      return TaxFreeLumpSumWithdrawalState.untaken
    default:
      return TaxFreeLumpSumWithdrawalState.taken
  }
}

export const getTFLSAmount = (totalSavings: number, tflsDecision: TaxFreeLumpSumWithdrawalState) => {
  const TAX_FREE_PERCENTAGE = 0.25
  const { taken, untaken } = TaxFreeLumpSumWithdrawalState
  switch (tflsDecision) {
    case taken:
      return Math.floor(totalSavings * TAX_FREE_PERCENTAGE)
    case untaken:
      return 0
    default:
      return null
  }
}

export const getTFLSTheoricalAmount = (totalSavings: number) =>
  getTFLSAmount(totalSavings, TaxFreeLumpSumWithdrawalState.taken)

export const getPotAllocatedPercentage = (allocatedAmount: number, availableSavings: number) =>
  allocatedAmount / availableSavings

export const getPotColor = (variant?: TAllPotVariants): PotColor => {
  switch (variant) {
    case PotName.flexibleIncome:
      return PotColor.flexibleIncomePot

    case PotName.inheritance:
      return PotColor.inheritancePot

    case PotName.laterLife:
      return PotColor.laterLifePot

    case PotName.rainyDay:
      return PotColor.rainyDayPot

    case AdditionalPotName.taxFree:
      return PotColor.transparent

    default:
      return PotColor.disabled
  }
}

export const extractPotName = (value: string | null): PotName | undefined =>
  value !== null ? Object.values(PotName).find(pot => pot === value) : undefined

export const isIncomePot = (potName: string): boolean => POT_NAMES_BY_TYPE[PotType.income].some(pot => pot === potName)

export const isFlexibleIncomePotDataValid = ({
  startAge,
  endAge,
  potAmount,
}: {
  startAge?: number
  endAge?: number
  potAmount?: number | null
}) => Boolean(startAge && endAge && potAmount && potAmount > 0 && startAge > 0 && endAge > 0 && startAge < endAge)

export const isLaterLifePotDataValid = ({ startAge, potAmount }: { startAge?: number; potAmount?: number | null }) =>
  Boolean(startAge && potAmount && potAmount > 0 && startAge > 0)
