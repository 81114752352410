import { paths } from '@retire/navigation/paths'
import { matchPath, useLocation } from 'react-router-dom'

import { usePageOverlay } from '../usePageOverlay'

export const useWithSimpleHeader = () => {
  const { pathname } = useLocation()
  const { isOverlay } = usePageOverlay()
  const simpleHeaderPathPatterns: string[] = [paths.hygienePages.serverError]

  return isOverlay || simpleHeaderPathPatterns.some(pathPattern => matchPath(pathPattern, pathname))
}
