import { Key } from '@retire/constants'
import type { KeyboardEvent, RefObject } from 'react'
import { useCallback } from 'react'

// Important: refs must be ordered in the same way as their elements appear in the DOM
export const useFocusPreviousInputOnBackspace = (refs: RefObject<HTMLInputElement | null | undefined>[]) => {
  const keyDownHandler = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== Key.Backspace) {
        return
      }

      const targetElement = e.target as HTMLInputElement
      const index = refs.findIndex(ref => {
        return ref.current?.isEqualNode(targetElement)
      })

      if (index > 0 && targetElement.value === '' && !targetElement.validity.badInput) {
        const previousRef = refs[index - 1]

        previousRef.current?.focus()
        e.preventDefault()
      }
    },
    [refs]
  )

  return keyDownHandler
}
