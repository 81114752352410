import { UserState } from '@retire/gql/__generated__/graphql'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { escapeExceptApostrophe } from '@retire/utils'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const WelcomeBack: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslationWithOptions('enrolment')
  const {
    data: { state, forename },
    loading,
  } = useUserDetails()

  const buttonOnClick = useCallback(() => {
    if (!loading && state === UserState.AwaitingEmailConfirmation) {
      navigate(paths.enrolment.emailChanged)
    } else {
      navigate(paths.enrolment.personalDetails)
    }
  }, [state, loading, navigate])

  const {
    customerService: { phoneNumber },
  } = usePublicSettings()

  return (
    <PageTemplate
      title={t('welcomeBack.heading', 'Enrolment Welcome Back heading', {
        forename,
        interpolation: { escape: escapeExceptApostrophe },
      })}
      subTitle={t('welcomeBack.subheading', 'Enrolment Welcome Back subheading', {
        phoneNumber,
      })}
      nextText={t('welcomeBack.button', 'Enrolment Welcome Back button')}
      onNext={buttonOnClick}
      loading={loading}
    />
  )
}
