import { CopyLevel, TypographyColor } from '@retire/theme'
import type { TAria } from '@retire/types'
import { getDisplayName } from '@retire/utils'
import type { ComponentType } from 'react'
import { forwardRef } from 'react'

import { Spacer } from '../../../Spacer'
import { Copy } from '../../../Typography'
import type { TWrappedComponent } from '../types'
import { SInputLabel, SInputSubLabel } from './presentation.styled'
import type { TWithFormLabels } from './types'

export const withFormLabels = <TProps extends TWithFormLabels>(
  WrappedComponent: ComponentType<TWrappedComponent<TProps>>
) => {
  const Wrapped = forwardRef<HTMLInputElement, TProps>((props, ref) => {
    const { label, subLabel, footNote, ...componentProps } = props
    const id = props.name.trim()
    const labelId = `label-${id}`
    const subLabelId = `sublabel-${id}`
    const ariaLabels: TAria = {}

    if (label || subLabel) {
      ariaLabels.ariaLabelledBy = subLabel ? `${labelId} ${subLabelId}` : labelId
    } else {
      ariaLabels.ariaLabel = labelId
    }

    return (
      <div role="group">
        {label ? (
          <Spacer bottom={subLabel ? 'extraSmall' : 'mediumSmall'}>
            <SInputLabel id={labelId}>{label}</SInputLabel>
          </Spacer>
        ) : (
          <div id={labelId} />
        )}
        {subLabel && (
          <Spacer bottom="small">
            <SInputSubLabel id={subLabelId}>{subLabel}</SInputSubLabel>
          </Spacer>
        )}
        <WrappedComponent id={id} ref={ref} {...ariaLabels} {...componentProps} />
        {footNote && (
          <Spacer top="extraSmall">
            <Copy as="span" level={CopyLevel.caption} color={TypographyColor.neutral}>
              {footNote}
            </Copy>
          </Spacer>
        )}
      </div>
    )
  })

  Wrapped.displayName = `WithFormLabels(${getDisplayName(WrappedComponent)})`
  return Wrapped
}
