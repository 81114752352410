import type { FC } from 'react'

import { Question } from '../Question'
import type { TCobs, TQuestionList } from './types'

export const QuestionList: FC<TQuestionList> = ({ cobsQuestions }) => {
  return (
    <>
      {Object.keys(cobsQuestions).map((question, index) => {
        let answer = null
        if (cobsQuestions[question] !== null) {
          answer = !!cobsQuestions[question]
        }
        return <Question order={index + 1} question={question as TCobs} value={answer} key={`question-${index + 1}`} />
      })}
    </>
  )
}
