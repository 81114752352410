import { FormDropdown, Spacer } from '@retire/components'
import { SelectedSubLabels } from '@retire/components/Forms/Dropdown/SelectedSubLabels'
import { SelectedValueWithSubLabels } from '@retire/components/MobileDropdown/SelectedValueWithSubLabels'
import { useTranslationWithOptions } from '@retire/hooks'
import isTouchDevice from 'is-touch-device'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { AMOUNT_OPTIONS_GROUP_NAME, FROM_INPUT_NAME, TO_INPUT_NAME } from '../constants'
import { SDropdownWrapper } from '../DropdownWrapper'
import { usePotOptions } from '../hooks/usePotOptions'

export const MoveMoneyFrom: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const isMobile = isTouchDevice()
  const { resetField } = useFormContext()

  const options = usePotOptions({ disableEmptyPots: true })

  const [fromPot, toPot] = useWatch({ name: [FROM_INPUT_NAME, TO_INPUT_NAME] })

  const { subLabels: selectedPotSubLabels, label: selectedPotLabel } = useMemo(
    () => options.find(({ value }) => value === fromPot) || { subLabels: undefined, label: '' },
    [options, fromPot]
  )
  const renderSelectedValue = useCallback(
    () => <SelectedValueWithSubLabels label={selectedPotLabel} subLabels={selectedPotSubLabels} />,
    [selectedPotLabel, selectedPotSubLabels]
  )
  const onChange = useCallback(
    value => {
      if (value === toPot) {
        resetField(TO_INPUT_NAME)
      }
      resetField(AMOUNT_OPTIONS_GROUP_NAME)
    },
    [resetField, toPot]
  )

  const rules = { required: true }

  return (
    <Spacer top={isMobile ? 'large' : 'extraLarge'}>
      <FormDropdown
        label={t('pots.common.moveMoneyBetweenPots.fromSection.title', 'From')}
        name={FROM_INPUT_NAME}
        placeholder={t('pots.common.moveMoneyBetweenPots.fromSection.placeholder', 'Choose a pot to move money from')}
        options={options}
        withMobileDropdown
        renderSelectedValue={isMobile ? renderSelectedValue : undefined}
        mobileDropdownOverlayTitle={t('pots.common.moveMoneyBetweenPots.fromSection.mobileDropdownOverlayTitle')}
        onChange={onChange}
        SDropdownWrapper={SDropdownWrapper}
        rules={rules}
      />
      {!isMobile && <SelectedSubLabels subLabels={selectedPotSubLabels} />}
    </Spacer>
  )
}
