import { styled } from '@retire/theme/styledComponents'

import type { TDropdownWrapper, TOuterWrapper } from './types'

export const SOuterWrapper = styled.div<TOuterWrapper>`
  position: relative;
  display: flex;
  ${({ isMobile }) => (isMobile ? 'height: 100%;' : '')}
`

export const SDropdownWrapper = styled.div<TDropdownWrapper>`
  width: auto;

  & + div {
    display: flex;
  }

  ${({
    theme: {
      forms: {
        shared: {
          border: { radius: borderRadius, width: borderWidth, style: borderStyle },
          states: {
            active: { borderColor: activeBorderColor },
            default: { borderColor: defaultBorderColor },
            disabled: { backgroundColor: disabledBackgroundColor },
            error: { borderColor: errorBorderColor },
            hover: { borderColor: hoverBorderColor },
          },
        },
        dropdown: {
          states: {
            active: { borderColor: dropdownActiveBorderColor, backgroundColor: dropdownActiveBackgroundColor },
          },
        },
      },
    },
    disabled,
    invalid,
    isOpen,
  }) => `
    border-width: ${borderWidth};
    border-style: ${borderStyle};
    border-radius: ${borderRadius};
    border-color: ${invalid ? errorBorderColor : isOpen ? dropdownActiveBorderColor : defaultBorderColor};
    background-color: ${disabled ? disabledBackgroundColor : isOpen ? dropdownActiveBackgroundColor : ''};

    ${
      !isOpen &&
      !disabled &&
      `
      &:focus, &:focus-within {
        border-color: ${activeBorderColor};
      }
      &:hover {
        border-color: ${hoverBorderColor};
      }
    `
    }
  `}
`
