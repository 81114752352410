import { styled } from '@retire/theme/styledComponents'

import { Label } from '../Forms'
import type { TStepNumber } from './types'

export const SSectionNameWrapper = styled.div`
  display: flex;
`

export const SSectionName = styled(Label)`
  color: ${({ theme: { colors } }) => colors.neutral};
`

export const SStepNumberWrapper = styled.div`
  margin-right: ${props => props.theme.overview.numbers.marginRight};
`

export const SStepNumber = styled.div<TStepNumber>`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  border-style: solid;
  border-width: ${props => props.theme.overview.numbers.borderWidth};
  border-radius: 50%;
  line-height: 1;
  font-weight: ${props => props.theme.overview.numbers.fontWeight};

  ${({
    theme: {
      overview: { numbers },
    },
    isInProgress,
    isCompleted,
    isVisible,
  }) => {
    const colorSource = isCompleted ? numbers.completed : isInProgress ? numbers.inProgress : numbers.default
    return `
      width: ${numbers.size};
      height: ${numbers.size};
      font-size: ${numbers.fontSize};
      color: ${colorSource.color};
      border-color: ${colorSource.borderColor};
      background-color: ${colorSource.backgroundColor};
      visibility: ${isVisible ? 'inherit' : 'hidden'};
      `
  }}
`
