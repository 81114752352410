import { useListBox } from '@react-aria/listbox'
import { useListState } from '@react-stately/list'
import { useEffect, useMemo, useRef } from 'react'

import { ListBox } from './presentation'
import type { TListBoxContainer } from './types'
import { getSubmitOnKeyPress } from './utils'

export const ListBoxContainer = ({ searchResults, onResultClick, ...listProps }: TListBoxContainer) => {
  const state = useListState(listProps)
  const ref = useRef<HTMLUListElement>(null)
  const { listBoxProps, labelProps } = useListBox(listProps, state, ref)

  const optionProps = useMemo(
    () =>
      [...state.collection].map((item, idx) => {
        const { id, next } = searchResults[idx]

        return {
          key: item.key,
          item,
          state,
          onKeyPress: getSubmitOnKeyPress(id, next, onResultClick),
          onClick: () => onResultClick(id, next),
        }
      }),
    [onResultClick, searchResults, state]
  )

  useEffect(() => {
    ref.current?.focus()
  }, [searchResults])

  return <ListBox ref={ref} optionProps={optionProps} listBoxProps={listBoxProps} labelProps={labelProps} />
}
