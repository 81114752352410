import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { FC } from 'react'

export const SmartLogo: FC = () => {
  const {
    colors: { white },
  } = useThemeContext()

  return (
    <svg fill={white} x="0px" y="0px" viewBox="0 0 546.5 157.6" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Smart</title>
      <g>
        <g>
          <g>
            <path
              fill={white}
              d="M211.7,109.1c4.8,8.2,13.6,14.8,26.7,14.8c13.6,0,21.6-6.1,21.6-16.3c0-11.6-11.6-14.7-24.2-17.7
				c-13.3-3.1-27.1-7.5-27.1-25c0-13.9,12.6-24.2,29.5-24.2c18.2,0,29.1,9.5,29.1,15.2c0,3.9-5.2,6-5.4,5.6
				c-4.1-7.2-12.6-12.8-23.5-12.8c-12.2,0-20.7,6.4-20.7,15.9c0,11.3,9.2,13.6,22.6,16.7c14,3.3,28.8,8.4,28.8,25.8
				c0,15-12.1,24.8-30.6,24.8c-19.6,0-32.2-10.9-32.2-17.3C206.2,110.8,211.4,108.7,211.7,109.1z"
            />
            <path
              fill={white}
              d="M281.2,66c0-0.3,1.4-1,3-1c3,0,5.7,1.5,5.7,8.6v1.6c4.5-6.5,11.7-11.3,20.4-11.3c10.2,0,18,4.8,21.5,13.9
				c4.4-7.8,12.2-13.9,23-13.9c16.3,0,25,9.4,25,27.3v35.4c0,3.3-1.6,4.2-3.9,4.2h-1c-2.3,0-3.9-1-3.9-4.2V91.8
				c0-12.9-5.6-19.6-16.7-19.6c-8,0-15.5,5.3-19.3,13.1v41.3c0,3.3-1.6,4.2-3.9,4.2h-1c-2.3,0-3.9-1-3.9-4.2V92.5
				c0-13.6-5.6-20.3-16.7-20.3c-8,0-15.5,5.3-19.3,13.1v41.3c0,3.3-1.6,4.2-3.9,4.2h-1c-2.3,0-3.9-1-3.9-4.2V66L281.2,66L281.2,66z"
            />
            <path
              fill={white}
              d="M435.7,91.7h1.4v-2.3c0-11.2-6.5-17.3-18.2-17.3c-10.5,0-17.1,5.3-20.7,12c0,0.1-5-0.4-5-5.2
				c0-4.6,9.2-15,26.4-15c16.5,0,26.2,9.5,26.2,25.2v40.8c0,0.3-1.4,1-3,1c-3,0-5.6-1.5-5.6-8.6v-2.4c-5.2,7.5-13.9,12-24.2,12
				c-14.1,0-22.3-7.2-22.3-18.1C390.7,98.7,405.7,92.4,435.7,91.7z M413.8,124.5c13.3,0,23.3-8.3,23.3-19.9v-6l-3.8,0.1
				c-22.2,0.8-33.6,4.8-33.6,15C399.6,120.3,404.9,124.5,413.8,124.5z"
            />
            <path
              fill={white}
              d="M460.7,66c0-0.3,1.4-1,3-1c3,0,5.7,1.5,5.7,8.6v3.8c2.7-8.8,8.4-13.5,17.1-13.5c6,0,9.4,2.4,9.4,6.7
				c0,3.3-2.2,4.9-2.4,4.6c-1.9-1.6-4.2-2.7-7.9-2.7c-11.6,0-16,12.1-16,28.7v25.4c0,3.3-1.6,4.2-3.9,4.2h-1c-2.3,0-3.9-1-3.9-4.2
				L460.7,66L460.7,66z"
            />
            <path
              fill={white}
              d="M510.7,73.5h-6c-3.3,0-4.2-1.2-4.2-3.5v-0.9c0-2.3,1-3.5,4.2-3.5h6V54.3c0-3.3,1.6-4.2,3.9-4.2h1
				c2.3,0,3.8,1,3.8,4.2v11.3h17.3c3.3,0,4.2,1.2,4.2,3.5v0.8c0,2.3-1,3.5-4.2,3.5h-17.3v36.9c0,9.5,2.4,14,9.7,14
				c4.5,0,7.8-1.9,10.1-4.8c0.3-0.3,3,1,3,4.4c0,3.7-4.8,8-13.3,8c-12.6,0-18.1-7.2-18.1-20.7V73.5
				C510.8,73.5,510.7,73.5,510.7,73.5z"
            />
          </g>
        </g>
        <path
          fill={white}
          d="M78.8,0C35.4,0,0,35.3,0,78.8s35.3,78.8,78.8,78.8c43.4,0,78.8-35.4,78.8-78.8S122.3,0,78.8,0z M109.9,55.6
		c-6.4,1-12.8-4.9-14.1-13.3c-1.4-8.4,2.8-16,9.2-17s12.8,4.9,14.1,13.3C120.5,47,116.4,54.6,109.9,55.6z M86.9,78.8
		c-8.6-6.8-25.8-9.7-49.5-4.4c-0.5,0.1-0.8-0.6-0.3-0.8c18-10.1,40.7-13,51.2-12.9c15,0,32.6,8.1,19.1,33.4
		c-13,24.3-34.7,38.7-67.6,44.7c-1.1,0.2-2.3-0.1-3.2-0.7c0,0,0,0,0,0c-0.3-0.2-0.2-0.6,0.1-0.7c24.6-7.3,40.7-20.8,48.6-33.2
		C96.1,86.9,87.5,79.3,86.9,78.8z"
        />
      </g>
    </svg>
  )
}
