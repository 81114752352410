import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import { DEFAULT_PROPS } from './constants'
import type { TIcon, TIconContainer } from './types'

export const SIcon = styled.i<TIcon>`
  display: inline-flex;

  ${({
    iconSize = DEFAULT_PROPS.iconSize,
    theme: {
      icons: { size },
    },
  }) => `
    svg {
      width: auto;
      height: ${size[iconSize]};
    }
  `}
`

export const SIconContainer = styled.span<TIconContainer>`
  display: inline-block;
  vertical-align: middle;

  ${({
    theme: {
      icons: { size: iconSize },
      spacing: { mediumSmall },
    },
    size,
    withSpacing,
  }) => css`
    width: ${iconSize[size === 'mini' ? 'mini' : 'small']};
    height: ${iconSize[size === 'mini' ? 'mini' : 'small']};
    margin-right: ${withSpacing ? mediumSmall : '0px'};

    ${mediaStyles(
      {
        width: iconSize[size || 'medium'],
        height: iconSize[size || 'medium'],
      },
      {
        min: Breakpoint.small,
      }
    )}
  `}
`
