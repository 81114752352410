import type { FC } from 'react'

import { Icons } from '../assets/icons'
import { SIcon } from './index.styled'
import type { TIcon } from './types'

export const Icon: FC<TIcon> = ({ iconType, iconColors, selected, title, ...props }) => {
  const BasicIcon = Icons[iconType]
  return (
    <SIcon iconType={iconType} {...props}>
      <BasicIcon {...iconColors} selected={selected} title={title} />
    </SIcon>
  )
}
