import type { FC } from 'react'
import { useCallback } from 'react'

import { SelectableCard } from '../../../SelectableCard/presentation'
import type { TSelectableCardGroup } from './types'

export const SelectableCardGroup: FC<TSelectableCardGroup> = ({ value, optionValue, children, onChange }) => {
  const isOptionSelected = value === optionValue
  const onClick = useCallback(() => {
    onChange && onChange(optionValue)
  }, [onChange, optionValue])

  return (
    <SelectableCard onClick={onClick} selected={isOptionSelected}>
      {children}
    </SelectableCard>
  )
}
