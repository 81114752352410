import { Spacer } from '@retire/components'
import type { FC } from 'react'
import { Visible } from 'react-grid-system'

export const JourneyOverviewDetails: FC = ({ children }) => (
  <>
    <Visible xs sm md>
      <Spacer left="extraLarge">{children}</Spacer>
    </Visible>
    <Visible lg xl>
      {children}
    </Visible>
  </>
)
