import { UnorderedList } from '@retire/components/List'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../hooks/useJourneyHeader/constants'

export const PotsIntro: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.intros })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const {
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
    loadingIllustrationPlanData,
  } = useIllustrationPlanData()

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      flexibleIncomeAmount: null,
      regularIncomeStartAge: null,
      regularIncomeEndAge: null,
      laterLifeAmount: null,
      laterLifeAge: null,
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  const onNext = useCallback(() => navigate(paths.illustration.journeyIncomePotsIntro), [navigate])
  const onBack = useCallback(() => navigate(paths.illustration.journeyTaxFreeLumpSumWithdrawal), [navigate])

  return (
    <JourneyStepLayout
      title={t('steps.interstitial.title', 'PotsIntro step title')}
      description={
        <Trans components={{ list: <UnorderedList />, listItem: <li /> }}>
          {t('steps.interstitial.description', 'PotsIntro step description')}
        </Trans>
      }
      onNext={onNext}
      nextText={t('common.buttons.continue', 'Continue')}
      backText={t('common.buttons.back', 'Back')}
      onBack={onBack}
      loading={loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData}
    />
  )
}
