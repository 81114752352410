import { styled } from '@retire/theme/styledComponents'

import { CommonHeaderStyle } from '../common.styled'

export const SOutageHeader = styled.header`
  background-color: ${props => props.theme.colors.primary};
  padding: ${props => props.theme.header.paddingTop} 0;
  ${CommonHeaderStyle};
  max-width: 100%;
`
