import { FormSelectableCardsGroup } from '@retire/components/Forms/SelectableCardsGroup'
import { FundCardContent } from '@retire/components/FundCardContent'
import { Link } from '@retire/components/Link'
import { UnorderedList } from '@retire/components/List'
import { NavButtons } from '@retire/components/NavButtons'
import { PotName } from '@retire/constants/pots'
import { PotType } from '@retire/gql/__generated__/graphql'
import { useAreYouSureModal } from '@retire/hooks/useAreYouSureModal'
import { useFormDefaultValues } from '@retire/hooks/useFormDefaultValues'
import { usePotFunds } from '@retire/hooks/usePotFunds'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { usePotsContext } from '../../hooks/usePotsContext'
import { INVESTMENT_GUIDE_URL } from './constants'
import type { TInheritancePotFundsFormFields, TInheritancePotFundsReviewState } from './types'

const fundRules = { required: true }

export const InheritancePotFunds = () => {
  useActivatePageOverlay()
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const { loading: loadingPotFunds, availableFunds, currentFund } = usePotFunds(PotType.Inheritance)
  const {
    pots: {
      [PotName.inheritance]: { isLockedForBuys, isLockedForSells },
    },
  } = usePotsContext()
  const isLocked = isLockedForBuys || isLockedForSells

  const [defaultValues, setDefaultValues] = useFormDefaultValues<TInheritancePotFundsFormFields>({
    initialValues: { fundId: currentFund?.id || '' },
  })
  const { showQuitModal, QuitModal } = useAreYouSureModal({
    onConfirm: () => navigate(paths.dashboard.inheritancePotDetails),
  })

  useEffect(() => {
    setDefaultValues({ fundId: currentFund?.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFund?.id])

  useEffect(() => {
    if (isLocked) {
      navigate(paths.dashboard.inheritancePotDetails, { replace: true })
    }
  }, [isLocked, navigate])

  const methods = useForm<TInheritancePotFundsFormFields>({
    mode: 'all',
    defaultValues,
  })
  const {
    formState: { isValid },
    handleSubmit,
    watch,
  } = methods

  const onSubmit = useCallback(
    ({ fundId }: TInheritancePotFundsFormFields) => {
      const newSelectedFund = availableFunds.find(({ id }) => id === fundId)
      if (!newSelectedFund) return

      setDefaultValues({ fundId })

      const state: TInheritancePotFundsReviewState = { currentFund, newSelectedFund }
      navigate(paths.dashboard.inheritancePotFundsReview, {
        state,
      })
    },
    [availableFunds, currentFund, navigate, setDefaultValues]
  )

  const onContinueClick = handleSubmit(onSubmit)

  const options = useMemo(
    () =>
      availableFunds.map(fund => ({
        value: fund.id,
        content: <FundCardContent {...fund} />,
      })),
    [availableFunds]
  )

  const newSelectedFund = watch('fundId')
  const disableNext = !isValid || !newSelectedFund || newSelectedFund === currentFund?.id

  return (
    <FormProvider {...methods}>
      <PageTemplate
        fullWidth
        title={t('pots.inheritancePot.investmentFunds.configure.title', 'Change funds')}
        subTitle={
          <Trans
            components={{
              list: <UnorderedList />,
              listItem: <li />,
              guideLink: <Link as="a" target="_blank" inline bold href={INVESTMENT_GUIDE_URL} />,
            }}
          >
            {t(
              'pots.inheritancePot.investmentFunds.configure.subTitle',
              '<guideLink>Read our investment guide (PDF) to learn more about the funds</guideLink>'
            )}
          </Trans>
        }
        onQuit={showQuitModal}
        loading={loadingPotFunds}
      >
        <FormSelectableCardsGroup fullWidth rules={fundRules} name="fundId" options={options} />
        <NavButtons
          onNext={onContinueClick}
          disableNext={disableNext}
          nextText={t('pots.inheritancePot.investmentFunds.continue', 'Continue')}
          backText={t('pots.inheritancePot.investmentFunds.back', 'Back')}
        />
      </PageTemplate>
      <QuitModal />
    </FormProvider>
  )
}
