import { useQueryWithError, useTranslationWithOptions } from '@retire/hooks'
import { PathParam, paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { NotFoundMessage } from '../../components'
import { MESSAGE_QUERY } from '../../gql/queries'
import { Message } from './presentation'

export const MessageDetailContainer: FC = () => {
  const { t } = useTranslationWithOptions('inbox')
  const navigate = useNavigate()
  const messageId = useParams()[PathParam.message]
  const { data, loading } = useQueryWithError(MESSAGE_QUERY, {
    variables: { id: String(messageId) },
    skip: !messageId || !Number(messageId),
  })
  const message = data?.sentMessage
  const onBack = useCallback(() => navigate(paths.inbox.messages), [navigate])

  if (!loading && !message) {
    return <NotFoundMessage />
  }

  return (
    <PageTemplate loading={loading} backText={t('message.actions.back', 'Back')} onBack={onBack} fullWidth>
      {message && <Message {...message} />}
    </PageTemplate>
  )
}
