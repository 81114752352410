import { Link } from '@retire/components/Link'
import { LinkVariant } from '@retire/constants/link'
import { PotName } from '@retire/constants/pots'
import { useLocationAlerts } from '@retire/hooks/useLocationAlerts'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { useCallback, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { usePotsContext } from '../usePotsContext'

export const useBoardNotifications = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const { addAlert } = useLocationAlerts()
  const navigate = useNavigate()
  const {
    pots: {
      [PotName.flexibleIncome]: { allocatedAmount: flexibleIncomePotAmount = 0 },
      [PotName.laterLife]: { allocatedAmount: laterLifePotAmount = 0 },
    },
  } = usePotsContext()
  const {
    data: { laterLifeAge, regularIncomeEndAge },
    loading: loadingUserDetails,
  } = useUserDetails()

  const openManageIncome = useCallback(() => {
    navigate(paths.dashboard.flexibleIncomePotManageIncome)
  }, [navigate])

  const areIncomePotsInUse = flexibleIncomePotAmount && laterLifePotAmount

  useEffect(() => {
    if (laterLifeAge > regularIncomeEndAge && areIncomePotsInUse) {
      addAlert(
        {
          title: (
            <Trans components={[<Link as="a" onClick={openManageIncome} variant={LinkVariant.dark} />]}>
              {t(
                'alerts.gapBetweenIncome',
                'You have a gap between your Flexible income and your chosen Later life age.'
              )}
            </Trans>
          ),
          type: 'info',
          id: 'gap-between-income',
        },
        false
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laterLifeAge, regularIncomeEndAge, t, areIncomePotsInUse])

  return {
    loadingBoardNotifications: loadingUserDetails,
  }
}
