import { css, styled } from '@retire/theme/styledComponents'

import type { TTable } from '../types'
import { DEFAULT_PROPS } from './constants'
import type { TTDCell, TTHCell } from './types'

export const STRow = styled.tr<{ hideBorder?: boolean }>`
  ${({ hideBorder, theme: { table } }) => `
      border-top: ${hideBorder ? '0' : table.border};
    }
  `}

  th,
  td {
    ${({ theme: { table } }) => `
      font-family: ${table.content.fontFamily};
      padding: ${table.content.padding};
    `}

    :last-child {
      padding-right: 0;
    }

    :first-child {
      padding-left: 0;
    }
  }
`

export const STable = styled.table<Pick<TTable, 'showExtremeBorders'>>`
  width: 100%;

  ${STRow}:last-child {
    td {
      border-bottom: ${({ showExtremeBorders }) => (showExtremeBorders ? undefined : '0')};
    }
  }
`

const cellStyles = css<TTDCell>`
  ${({
    theme: {
      table: { content },
    },
    textAlign = DEFAULT_PROPS.textAlign,
    verticalAlign = DEFAULT_PROPS.verticalAlign,
  }) => `
    text-align: ${textAlign};
    vertical-align: ${verticalAlign};
    font-weight: ${content.fontWeight};
    color: ${content.color};
    font-size: ${content.fontSize};
    line-height: ${content.lineHeight};
  `}
`

export const STDCell = styled.td<TTDCell>`
  ${cellStyles}
`

export const STDHeaderCell = styled(STDCell)`
  ${({
    theme: {
      table: {
        content: { headers, rowHeaders },
      },
    },
  }) => `
    font-weight: ${headers.fontWeight};
    color: ${rowHeaders.color};
    font-size: ${rowHeaders.fontSize};
    line-height: ${rowHeaders.lineHeight};
  `}
`

export const STHCell = styled.th<TTHCell>`
  ${cellStyles}
  ${({
    theme: {
      table: {
        content: { headers, columnHeaders },
      },
    },
    width = DEFAULT_PROPS.width,
  }) => `
    width: ${width};
    font-weight: ${headers.fontWeight};
    font-size: ${columnHeaders.fontSize};
    line-height: ${columnHeaders.lineHeight};
  `}
`
