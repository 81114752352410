import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { Copy } from '../Typography'
import { TransItem } from './TransItem'
import type { TTransList } from './types'

export const TransList: FC<TTransList> = ({ children, isOrdered }) => {
  // automatically count items from children
  const items = children.match(/(<\d*>((?!<\/?\d*>).)*<\/\d*>)/gi)
  const totalItems: number = items ? items.length : 0

  return (
    <Copy as={isOrdered ? 'ol' : 'ul'}>
      <Trans
        components={[...new Array(totalItems)].map(key => (
          <TransItem key={`list-${key}`} />
        ))}
      >
        {children}
      </Trans>
    </Copy>
  )
}
