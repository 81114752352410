import { CopyLevel } from '@retire/theme'
import type { FC } from 'react'

import { Spacer } from '../../Spacer'
import { DEFAULT_PROPS } from './constants'
import { SContainer, SText } from './presentation.styled'
import type { TTag } from './types'

export const Tag: FC<TTag> = ({ children, variant = DEFAULT_PROPS.variant }) => (
  <SContainer variant={variant}>
    <Spacer top="extraSmall" right="small" bottom="extraSmall" left="small">
      <SText as="span" level={CopyLevel.body1}>
        {children}
      </SText>
    </Spacer>
  </SContainer>
)
