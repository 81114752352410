import type { StyledComponent, ThemedStyledComponentsModule, ThemedStyledProps } from 'styled-components'
import * as styledComponents from 'styled-components'

import type { theme } from '.'

const {
  default: styled,
  css,
  keyframes,
  createGlobalStyle,
  withTheme,
  ThemeProvider,
  ThemeContext,
  ThemeConsumer,
} = styledComponents as unknown as ThemedStyledComponentsModule<typeof theme>

export type { StyledComponent, ThemedStyledProps }
export { css, keyframes, styled, ThemeConsumer, ThemeContext, ThemeProvider, withTheme }

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
    display: flex;
    margin: 0;
    padding: 0;
    line-height: 1.5rem;

    ${({
      theme: {
        general: {
          font: { family, weight, style, size },
          backgroundColor,
        },
      },
    }) => css`
      font-family: ${family};
      font-weight: ${weight};
      font-style: ${style};
      font-size: ${size};
      background-color: ${backgroundColor} !important;
    `}
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  :root {
    font-size: 1rem;
  }

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`
