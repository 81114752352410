import { gql } from '@apollo/client'

export const USER_BENEFICIARIES_QUERY = gql`
  query getUserBeneficiaries {
    user {
      id
      beneficiaries {
        name
        bornOn
        relation
        percentage
      }
    }
  }
`
