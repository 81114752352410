import { Link, NavButtons, Spacer } from '@retire/components'
import { UserState } from '@retire/gql/__generated__/graphql'
import { useMutationWithError, useTranslationWithOptions, useUpdateUserState } from '@retire/hooks'
import { useProgressHeader } from '@retire/hooks/useProgressHeader'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { signOut } from '@retire/utils'
import type { FC } from 'react'
import { useCallback } from 'react'

import { BeforeYouContinue, Fees, SaveAndExit } from '../../components'
import { JourneyOverview } from '../../components/YourIllustration/JourneyOverview'
import { CONFIRM_ILLUSTRATION_PLAN_MUTATION } from '../../gql'
import { useIllustrationPlanData } from '../../hooks/useIllustrationPlanData'
import { useSaveAndExitPage } from '../../hooks/useSaveAndExitPage'

export const YourIllustration: FC = () => {
  const { updateUserState, loading: updatingUserState } = useUpdateUserState()
  const { t } = useTranslationWithOptions('illustration')
  const { isSaveAndExitPage, showSaveAndExitPage, hideSaveAndExitPage } = useSaveAndExitPage()
  const { loadingIllustrationPlanData, resetIllustrationPlanData } = useIllustrationPlanData()

  const [confirmIllustrationPlanMutation, { loading: confirmingIllustrationPlanMutation }] = useMutationWithError(
    CONFIRM_ILLUSTRATION_PLAN_MUTATION,
    {
      onCompleted: () => updateUserState({ variables: { state: UserState.InitialNewPlanCreation } }),
    }
  )

  const resetJourney = useCallback(
    () =>
      resetIllustrationPlanData({
        onCompleted: () => updateUserState({ variables: { state: UserState.IllustrationCreation } }),
      }),
    [resetIllustrationPlanData, updateUserState]
  )

  useProgressHeader({
    currentStep: 0,
    totalSteps: 0,
  })

  return (
    <>
      {!isSaveAndExitPage ? (
        <PageTemplate
          title={t('yourIllustration.title', 'Your illustration page title')}
          fullWidth
          loading={loadingIllustrationPlanData || confirmingIllustrationPlanMutation || updatingUserState}
        >
          <Spacer top="extraLarge" />
          <JourneyOverview />
          <Spacer top="extraLarge" />
          <Fees />
          <Spacer top="extraLarge" />
          <BeforeYouContinue />
          <Spacer top="extraLarge" />
          <NavButtons
            backText={t('yourIllustration.actions.startAgain', 'Start again')}
            nextText={t('yourIllustration.actions.seeYourPlan', 'See your plan')}
            onBack={resetJourney}
            onNext={confirmIllustrationPlanMutation}
          />
          <Spacer top="extraLarge" />
          <Link as="a" onClick={showSaveAndExitPage}>
            {t('yourIllustration.actions.saveAndExit', 'Save and exit')}
          </Link>
        </PageTemplate>
      ) : (
        <SaveAndExit onNext={signOut} onCancel={hideSaveAndExitPage} />
      )}
    </>
  )
}
