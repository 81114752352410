import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import type { FC } from 'react'
import { useCallback } from 'react'

import { Link } from '../Link'
import { Copy } from '../Typography/Copy'
import type { TQuitAndReturnButton } from './types'

export const QuitAndReturnButton: FC<TQuitAndReturnButton> = ({ onQuit, quitText }) => {
  const { t } = useTranslationWithOptions('common')

  const handleClick = useCallback(
    event => {
      event.preventDefault()
      onQuit && onQuit(event)
    },
    [onQuit]
  )

  return (
    <Copy as="span">
      <Link as="button" inline onClick={handleClick}>
        {quitText || t('actions.quit', 'Quit and return to the start')}
      </Link>
    </Copy>
  )
}
