export enum BankDetail {
  accountName = 'accountName',
  accountNumber = 'accountNumber',
  sortCode = 'sortCode',
  bankReference = 'bankReference',
}

export const MIN_ACCOUNT_NUMBER_LENGTH = 6
export const MAX_ACCOUNT_NUMBER_LENGTH = 8

export const MIN_BANK_REFERENCE_NUMBER_LENGTH = 1
export const MAX_BANK_REFERENCE_NUMBER_LENGTH = 18
