import { Copy, Link } from '@retire/components'
import { useScrollToTop, useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { SBeforeYouJoin } from './presentation.styled'

export const BeforeYouJoin: FC = () => {
  const { t } = useTranslationWithOptions('enrolment')
  useScrollToTop()

  return (
    <SBeforeYouJoin>
      <Trans
        i18nKey="description"
        components={{
          list: <ul />,
          listItem: <li />,
          mapsLink: <Link as="a" href={t('common:externalLinks.maps', 'MaPS link')} target="_blank" />,
          pensionWiseLink: (
            <Link as="a" href={t('common:externalLinks.pensionWise.main', 'Pension Wise link')} target="_blank" />
          ),
          paragraph: <Copy as="p" />,
        }}
      >
        {t('cobs.beforeYouJoin.description', 'Description')}
      </Trans>
    </SBeforeYouJoin>
  )
}
