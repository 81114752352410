import { getDisplayName } from '@retire/utils'
import type { ComponentType } from 'react'
import { forwardRef, useCallback } from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller, useFormContext } from 'react-hook-form'

import { InlineError } from '../../../InlineError'
import { Spacer } from '../../../Spacer'
import { ErrorCell } from './presentation.styled'
import type { TWithFormError } from './types'

export const withFormError = <TProps extends TWithFormError>(WrappedComponent: ComponentType<TProps>) => {
  const Wrapped = forwardRef<HTMLInputElement, TProps>((props, ref) => {
    const { name, rules = {}, onBlur, onChange, fullWidth, value, displayError = true } = props
    const { control } = useFormContext()
    const errorId = `error-${name}`

    const render = useCallback(
      ({
        field: { onBlur: onFormBlur, onChange: onFormChange, ref: _ref, ...formProps },
        fieldState: { invalid: isInvalid, error },
      }) => {
        const invalid = isInvalid && !!error?.message
        const componentProps: TProps = {
          ariaDescribedBy: errorId,
          ariaInvalid: invalid,
          invalid,
          ref,
          ...formProps,
          ...props,
          onBlur: () => {
            onBlur && onBlur()
            onFormBlur()
          },
          onChange: eventOrValue => {
            onChange && onChange(eventOrValue)
            onFormChange(eventOrValue)
          },
        }

        return displayError ? (
          <ErrorCell>
            <Row>
              <Col sm={12} md={fullWidth ? 12 : 7} xl={fullWidth ? 12 : 5}>
                <WrappedComponent {...componentProps} />
              </Col>
            </Row>
            <Row>
              <Col sm={12} id={errorId} role={invalid ? 'alert' : undefined}>
                {invalid && (
                  <Spacer bottom="mediumSmall" top="mediumSmall">
                    <InlineError>{error?.message}</InlineError>
                  </Spacer>
                )}
              </Col>
            </Row>
          </ErrorCell>
        ) : (
          <WrappedComponent {...componentProps} />
        )
      },
      [displayError, errorId, fullWidth, onBlur, onChange, props, ref]
    )

    return <Controller control={control} name={name} rules={rules} defaultValue={value} render={render} />
  })

  Wrapped.displayName = `WithFormError(${getDisplayName(WrappedComponent)})`
  return Wrapped
}
