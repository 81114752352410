import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { mainRootPath } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const EmailVerified: FC = () => {
  const { t } = useTranslationWithOptions('enrolment')
  const navigate = useNavigate()
  const onNext = useCallback(() => navigate(mainRootPath), [navigate])

  return (
    <PageTemplate
      title={t('emailVerified.title')}
      titleIconType="Success"
      subTitle={t('emailVerified.description')}
      nextText={t('emailVerified.actions.continue')}
      onNext={onNext}
    />
  )
}
