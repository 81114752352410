import { FormSelectableCardsGroup } from '@retire/components/Forms/SelectableCardsGroup'
import { FundCardContent } from '@retire/components/FundCardContent'
import { UnorderedList } from '@retire/components/List'
import { PotName } from '@retire/constants/pots'
import { PotType } from '@retire/gql/__generated__/graphql'
import { FUND_DETAILS_QUERY } from '@retire/gql/fundDetails/queries'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { usePotFunds } from '@retire/hooks/usePotFunds'
import { AVAILABLE_FUNDS_FOR_POT_QUERY } from '@retire/hooks/usePotFunds/queries'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { SELECT_FUND_FOR_POT_MUTATION } from './gql/mutations'
import type { TInheritancePotFundsFormFields } from './types'

const fundRules = { required: true }
export const InheritancePotFunds: FC = () => {
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const methods = useForm<TInheritancePotFundsFormFields>({ mode: 'all' })
  const {
    formState: { isValid },
    handleSubmit,
  } = methods

  const [selectFundForPot, { loading: selectFundForPotLoading }] = useMutationWithError(SELECT_FUND_FOR_POT_MUTATION, {
    refetchQueries: [
      { query: AVAILABLE_FUNDS_FOR_POT_QUERY, variables: { potType: PotType.Inheritance } },
      { query: FUND_DETAILS_QUERY, variables: { potType: PotType.Inheritance } },
    ],
    onCompleted: () => navigate(paths.illustration.journeyRainyDayPotIntro),
  })

  const onSubmit = useCallback(
    async ({ fundId }) => {
      if (fundId) {
        await selectFundForPot({
          variables: {
            fundId,
            potType: PotType.Inheritance,
          },
        })
      }
    },
    [selectFundForPot]
  )
  const onNext = handleSubmit(onSubmit)
  const onBack = useCallback(() => navigate(paths.illustration.journeyInheritancePotConfiguration), [navigate])
  const { loading: fundsLoading, availableFunds } = usePotFunds(PotType.Inheritance)

  const options = useMemo(
    () =>
      availableFunds.map(fund => ({
        value: fund.id,
        content: <FundCardContent {...fund} />,
      })),
    [availableFunds]
  )

  return (
    <FormProvider {...methods}>
      <JourneyStepLayout
        title={t('steps.potSteps.inheritancePot.funds.title', `Choose a fund for your ${PotName.inheritance} pot`)}
        description={
          <Trans components={{ list: <UnorderedList />, listItem: <li /> }}>
            {t('steps.potSteps.inheritancePot.funds.description', `Choose a fund for your ${PotName.inheritance} pot`)}
          </Trans>
        }
        nextText={t('common.buttons.continue', 'Continue')}
        disableNext={!isValid}
        onNext={onNext}
        onBack={onBack}
        backText={t('steps.potSteps.potConfiguration.actions.cancel', 'Back')}
        loading={fundsLoading || selectFundForPotLoading}
      >
        <FormSelectableCardsGroup fullWidth rules={fundRules} name="fundId" options={options} />
      </JourneyStepLayout>
    </FormProvider>
  )
}
