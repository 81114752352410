import type { TCurrency } from '@retire/types'

import type { TAmountOrNull } from './types'

const DEFAULT_CURRENCY: TCurrency = '£'
const DEFAULT_PRECISION = 2
const DEFAULT_DECIMAL_DELIMITER = '.'
const DEFAULT_THOUSAND_DELIMITER = ','
const SUBUNITS_IN_A_UNIT = 100

const toCurrency = (
  value?: TAmountOrNull,
  precision = DEFAULT_PRECISION,
  currency: TCurrency = DEFAULT_CURRENCY,
  isSubUnit = true
) => {
  const numericValue = Number(value)
  if (Number.isNaN(numericValue)) {
    return ''
  }
  const ultimateValue = isSubUnit ? numericValue / SUBUNITS_IN_A_UNIT : numericValue

  return `${currency}${ultimateValue.toLocaleString(undefined, {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  })}`
}

const toPennies = (amount?: TAmountOrNull) => {
  const numericAmount = Number(amount)
  if (Number.isNaN(numericAmount)) {
    return 0
  }
  return Math.round(Number(amount) * SUBUNITS_IN_A_UNIT)
}

const toPounds = (amount?: TAmountOrNull, precision = DEFAULT_PRECISION) => {
  const numericAmount = Number(amount)
  if (Number.isNaN(numericAmount) || !Number.isInteger(numericAmount)) {
    return 0
  }
  return parseFloat((Number(amount) / SUBUNITS_IN_A_UNIT).toFixed(precision))
}

export {
  DEFAULT_CURRENCY,
  DEFAULT_DECIMAL_DELIMITER,
  DEFAULT_PRECISION,
  DEFAULT_THOUSAND_DELIMITER,
  SUBUNITS_IN_A_UNIT,
  toCurrency,
  toPennies,
  toPounds,
}
