import { styled } from '@retire/theme/styledComponents'
import Modal from 'react-modal'

export const SOverlay = styled(Modal)`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
`

export const SDropdownContent = styled.div`
  ${({ theme: { spacing } }) => `
    padding: ${spacing.extraLarge} ${spacing.medium};
  `}
`
