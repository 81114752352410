import { PotName } from '@retire/constants/pots'
import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types/icons'
import type { FC } from 'react'

export const RainyDay: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={`${PotName.rainyDay}-icon`}
    >
      <g stroke="none" fill="none" strokeWidth="1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(2.000000, 2.000000)" stroke={base} strokeWidth="2.3">
          <path d="M-3.55271368e-15,6.17765625 C8.236875,-2.05921875 21.594375,-2.05921875 29.83125,6.17765625 C38.068125,14.4145312 38.068125,27.7720312 29.83125,36.0089062 L29.64375,34.0382812 C29.203125,29.1857812 24.913125,25.6082812 20.05875,26.0470312 C19.99875,26.0526562 19.940625,26.0582812 19.880625,26.0657812 C19.880625,20.5776562 15.43125,16.1282812 9.943125,16.1282812 C10.486875,11.2851562 7.00125,6.91828125 2.158125,6.37640625 C2.098125,6.36890625 2.038125,6.36328125 1.978125,6.35765625 L-3.55271368e-15,6.17765625 Z" />
          <path d="M16.978125,19.0401563 L1.989375,34.0195313 C0.890625,35.1182813 0.890625,36.8995313 1.989375,37.9982813 C3.088125,39.0970313 4.869375,39.0970313 5.968125,37.9982813" />
          <path d="M29.83125,6.17765625 L31.820625,4.18828125" />
        </g>
      </g>
    </svg>
  )
}
