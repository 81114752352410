import type { TNotificationTypes } from '@retire/components/Notification/types'
import type { TAlert } from '@retire/types'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import type { TLocationAlertsContext, TLocationStateAlerts } from './types'

export const LocationAlertsContext = createContext<TLocationAlertsContext>({
  alerts: undefined,
  addAlert: () => null,
  resetAlerts: () => null,
})

export const LocationAlertsProvider: FC = ({ children }) => {
  const { state } = useLocation()
  const locationAlerts: TAlert[] = useMemo(() => (state as TLocationStateAlerts)?.alerts || [], [state])
  const [alerts, setAlerts] = useState(locationAlerts)

  useEffect(() => {
    locationAlerts.length && !isEqual(locationAlerts, alerts) && setAlerts(locationAlerts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationAlerts])

  const addAlert = useCallback(
    (alert: TAlert, atTop = true) => {
      const alertsWithoutDuplicates = (alerts || []).filter(({ id }) => alert.id !== id)
      setAlerts(atTop ? [alert, ...alertsWithoutDuplicates] : [...alertsWithoutDuplicates, alert])
    },
    [alerts]
  )

  const resetAlerts = useCallback(
    (type?: TNotificationTypes) =>
      setAlerts(type ? (alerts || []).filter(alert => alert.type !== type) : locationAlerts || []),
    [alerts, locationAlerts]
  )

  const value = useMemo(
    () => ({
      alerts,
      addAlert,
      resetAlerts,
    }),
    [alerts, addAlert, resetAlerts]
  )

  return <LocationAlertsContext.Provider value={value}>{children}</LocationAlertsContext.Provider>
}
