import type { TFundDetails } from '@retire/types/funds'

import type { TIllustrationPlanData } from '../../../types'
import {
  isFlexibleIncomePotConfigured,
  isInheritancePotConfigured,
  isInheritancePotFundsConfigured,
  isLaterLifePotConfigured,
  isRainyDayPotConfigured,
  isTFLSConfigured,
} from '../../../utils'

export const isPotIntroStepAccessible = (planData: TIllustrationPlanData) => {
  return isTFLSConfigured(planData)
}

export const isLaterLifePotStepAccessible = (planData: TIllustrationPlanData) => {
  return isPotIntroStepAccessible(planData) && isFlexibleIncomePotConfigured(planData)
}

export const isSavingsPotIntroStepAccessible = (planData: TIllustrationPlanData) => {
  return isLaterLifePotStepAccessible(planData) && isLaterLifePotConfigured(planData)
}

export const isRainyDayPotStepAccessible = (
  planData: TIllustrationPlanData,
  isEnsignUser: boolean,
  inheritancePotFunds?: TFundDetails[]
) => {
  return (
    isSavingsPotIntroStepAccessible(planData) &&
    isInheritancePotConfigured(planData) &&
    (isEnsignUser ? isInheritancePotFundsConfigured({ availableFunds: inheritancePotFunds, ...planData } || {}) : true)
  )
}

export const isReadyStepAccessible = (
  planData: TIllustrationPlanData,
  isEnsignUser: boolean,
  inheritancePotFunds?: TFundDetails[]
) => {
  return isRainyDayPotStepAccessible(planData, isEnsignUser, inheritancePotFunds) && isRainyDayPotConfigured(planData)
}
