import { CardTitle, UnorderedList } from '@retire/components'
import { Card } from '@retire/components/Card/presentation'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { Trans } from 'react-i18next'

export const HowItWorksCard = () => {
  const { t } = useTranslationWithOptions('dashboard')

  return (
    <Card>
      <CardTitle>{t('pots.common.moveMoneyBetweenPots.howItWorks.title', 'Move money how it works')}</CardTitle>
      <Trans
        components={[
          <span key="1" />,
          <UnorderedList key="factors">
            <li key="fund" />
            <li key="account" />
            <li key="percentages" />
            <li key="empyt" />
          </UnorderedList>,
          <span key="2" />,
          <UnorderedList key="factors">
            <li key="time" />
            <li key="period" />
            <li key="changes" />
          </UnorderedList>,
        ]}
      >
        {t('pots.common.moveMoneyBetweenPots.howItWorks.content')}
      </Trans>
    </Card>
  )
}
