import { PotName } from '@retire/constants/pots'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

import { BalanceCard } from '../../components/PotDetails/BalanceCard/presentation'
import { FlexibleIncomePotFundsCard } from '../../components/PotDetails/FlexibleIncomePotFundsCard'
import { FlexibleIncomePotMonthlyIncomeCard } from '../../components/PotDetails/FlexibleIncomePotMonthlyIncomeCard/presentation'
import { PageTitle } from '../../components/PotDetails/PageTitle/presentation'
import { PendingAmount } from '../../components/PotDetails/PendingAmount/presentation'
import { WhatItsForCard } from '../../components/PotDetails/WhatItsForCard/presentation'
import { useFlexibleIncomePotAges } from '../../hooks/useFlexibleIncomePotAges'
import { usePendingTransactions } from '../../hooks/usePendingTransactions'
import { usePotDetails } from '../../hooks/usePotDetails'

export const FlexibleIncomePotDetails: FC = () => {
  const {
    loadingPotDetails,
    potDetails: {
      isLockedForSells,
      isLockedForBuys,
      allocatedAmount = 0,
      projectedMonthlyIncome,
      projectedLaterLifeSurplus,
    },
  } = usePotDetails(PotName.flexibleIncome)
  const {
    data: { regularIncomeAmount },
  } = useUserDetails()
  const {
    loading: loadingPendingTransactions,
    data: { pendingAmounts },
  } = usePendingTransactions()

  const { t } = useTranslationWithOptions('dashboard')
  const { regularIncomePaused } = useUserDetails().data
  const { startAge, endAge } = useFlexibleIncomePotAges()
  const potLocked = isLockedForSells || isLockedForBuys
  const title = t('pots.flexibleIncomePot.title', `${PotName.flexibleIncome} details`, {
    ...(potLocked && { locked: t('pots.common.lockedShort', 'locked') }),
  })

  return (
    <PageTemplate
      fullWidth
      title={<PageTitle title={title} variant={PotName.flexibleIncome} />}
      loading={loadingPotDetails}
    >
      <WhatItsForCard potName={PotName.flexibleIncome} />
      <BalanceCard balance={allocatedAmount} buttonText={t('pots.common.balanceCard.actions.moveMoney', 'Move money')}>
        <PendingAmount loading={loadingPendingTransactions} pendingAmount={pendingAmounts[PotName.flexibleIncome]} />
      </BalanceCard>
      <FlexibleIncomePotMonthlyIncomeCard
        allocatedAmount={allocatedAmount}
        monthlyIncomeAmount={regularIncomeAmount || projectedMonthlyIncome}
        startAge={startAge}
        endAge={endAge}
        leftOverAtAge={projectedLaterLifeSurplus}
        regularIncomePaused={regularIncomePaused}
      />
      <FlexibleIncomePotFundsCard />
    </PageTemplate>
  )
}
