import { styled } from '@retire/theme/styledComponents'

import { CommonHeaderStyle } from '../common.styled'

export const SSkipContent = styled.header<{ visible: boolean }>`
  background-color: ${props => props.theme.colors.secondary};
  padding: ${props => props.theme.header.paddingTop} 0;
  position: ${({ visible }) => (visible ? 'static' : 'absolute')};
  left: -10000px;
  top: auto;
  width: auto;
  height: auto;
  overflow: hidden;
  ${CommonHeaderStyle};
`

export const SWrapper = styled.div`
  display: flex;
`
