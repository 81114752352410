import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import { Paragraph, Section, SubSection } from '../Common'
import type { TYourInvestments } from './types'

export const YourInvestments: FC<TYourInvestments> = ({ fundDetails: { providerName } }) => {
  const { t } = useTranslationWithOptions('plan')

  return (
    <Section>
      <SubSection>
        <Paragraph>
          {t('yourInvestments.description.paragraphOne', 'About the investment fund {{fundProvider}}.', {
            fundProvider: providerName,
            interpolation: { escapeValue: false },
          })}
        </Paragraph>
        <Paragraph>
          {t('yourInvestments.description.paragraphTwo', 'Warning the fund can go down as well as up.')}
        </Paragraph>
      </SubSection>
    </Section>
  )
}
