import { DateFormat } from '@retire/constants'
import type { TDate } from '@retire/types'
import differenceInYears from 'date-fns/differenceInYears'
import format from 'date-fns/format'

export * from './constants'

export const guessCurrentAge = (birthDate: string): number => differenceInYears(Date.now(), new Date(birthDate))

export const guessBornOnDate = (currentAge: number): string => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - currentAge)
  return formatDate(date.getTime(), DateFormat.yearMonthDay)
}

export const formatDate = (date: string | number, formatType: DateFormat = DateFormat.datetime): string => {
  if (date && Number.isNaN(new Date(date).getTime())) {
    console.error(`The given date ${date} is invalid`)
    return date as string
  }
  let pattern
  switch (formatType) {
    case DateFormat.longDate:
      pattern = 'dd MMM yyyy' // 01 Feb 1965
      break

    case DateFormat.longDatetime:
      pattern = 'dd MMM yyyy HH:mm' // 01 Feb 1965 13:30
      break

    case DateFormat.date:
      pattern = 'dd/MM/yyyy' // 01/02/1965
      break

    case DateFormat.time:
      pattern = 'HH:mm' // 13:30
      break

    case DateFormat.longTime:
      pattern = 'HH:mm:ss' // 13:30:15
      break

    case DateFormat.shortYear: // 65
      pattern = 'yy'
      break

    case DateFormat.yearMonthDay:
      pattern = 'yyyy-MM-dd' // 1965-02-01
      break

    case DateFormat.datetime:
    default:
      pattern = 'dd/MM/yyyy HH:mm' // 01/02/1965 13:30
      break
  }
  return format(date ? new Date(date) : new Date(), pattern)
}

export const getDateArray = (date: string) => {
  if (date && date.split('-').length === 3) {
    return date.split('-')
  }
  return ['', '', '']
}

export const getDateObject = (date: string): TDate => {
  const dateArray = getDateArray(date)
  return {
    year: dateArray[0],
    month: dateArray[1],
    day: dateArray[2],
  }
}

export const getDateFromObject = (dateObject: TDate) =>
  `${dateObject?.year || ''}-${dateObject?.month || ''}-${dateObject?.day || ''}`

export const checkDate = ({ day, month, year }: TDate) => {
  const d = Number(day)
  const m = Number(month)
  const y = Number(year)

  if (d || m || y) {
    if (m < 1 || m > 12) {
      return false
    }
    if (d < 1 || d > 31) {
      return false
    }
    if (y <= 999) {
      return false
    }
    if ((m === 4 || m === 6 || m === 9 || m === 11) && d === 31) {
      return false
    }
    if (m === 2) {
      const isleap = y % 4 === 0 && (y % 100 !== 0 || y % 400 === 0)
      if (d > 29 || (d === 29 && !isleap)) {
        return false
      }
    }
  }
  return true
}
