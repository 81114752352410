import type { TPotValueSection } from '@retire/components'
import { PotCurrency } from '@retire/components'
import { PotName, PotType } from '@retire/constants'
import { useTranslationWithOptions, useUserDetails } from '@retire/hooks'
import { CopyLevel } from '@retire/theme'
import type { ReactNode } from 'react'

import { useFlexibleIncomePotAges } from '../../../../../hooks/useFlexibleIncomePotAges'
import { usePotsContext } from '../../../../../hooks/usePotsContext'
import { SPotAgeSection } from './presentation.styled'
import type { TUseSections } from './types'

export const useSections = ({ potName }: TUseSections): TPotValueSection[] => {
  const { t } = useTranslationWithOptions('dashboard')
  const {
    pots: {
      [potName]: { allocatedAmount, customMonthlyIncome, potType, projectedMonthlyIncome },
    },
  } = usePotsContext()
  const { startAge: flexibleIncomePotStartAge, endAge: flexibleIncomePotEndAge } = useFlexibleIncomePotAges()
  const {
    data: { laterLifeAge, regularIncomePaused },
  } = useUserDetails()

  const sections: TPotValueSection[] = [
    {
      title: t('pots.common.valueTitle', `${potName} value title`),
      value: <PotCurrency value={allocatedAmount} />,
    },
  ]

  if (potType === PotType.income) {
    let value: ReactNode
    if (allocatedAmount === 0) {
      value = 'N/A'
    } else if (potName === PotName.flexibleIncome && regularIncomePaused) {
      value = t(`pots.${potName}.monthlyIncome.none`, `${potName} no monthly income`)
    } else {
      value = <PotCurrency value={customMonthlyIncome || projectedMonthlyIncome} />
    }
    sections.push({
      title: t(`pots.${potName}.monthlyIncome.title`, `${potName} monthly income title`),
      value,
      ageSection: !!allocatedAmount && (
        <SPotAgeSection as="span" level={CopyLevel.body1}>
          {t(
            `pots.${potName}.monthlyIncome.age`,
            `${potName} - flexibleIncomePotStartAge: {{flexibleIncomePotStartAge}}, flexibleIncomePotEndAge: {{flexibleIncomePotEndAge}}, laterLifePotStartAge: {{laterLifePotStartAge}}`,
            {
              flexibleIncomePotStartAge,
              flexibleIncomePotEndAge,
              laterLifePotStartAge: laterLifeAge,
            }
          )}
        </SPotAgeSection>
      ),
    })
  }

  return sections
}
