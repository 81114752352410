import type { FC } from 'react'
import { components } from 'react-select'

import type { TSingleValue } from './types'

export const SingleValue: FC<TSingleValue> = ({ data: { value, label }, renderSelectedValue, ...props }) => (
  <components.SingleValue data={{ value, label }} {...props}>
    {renderSelectedValue && value ? renderSelectedValue(value) : label}
  </components.SingleValue>
)
