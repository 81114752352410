import { styled } from '@retire/theme/styledComponents'

import type { TSLogoContainer } from './types'

export const SLogoContainer = styled.a<TSLogoContainer>`
  display: flex;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  margin-right: ${({ theme }) => theme.header.logo.marginRight};

  svg {
    height: ${({ theme }) => theme.header.logo.height};
  }
`
