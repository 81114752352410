import type { PotColor } from '@retire/theme'
import { styled } from '@retire/theme/styledComponents'

export const SPotNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SDotWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SDot = styled.div<{ potColor: PotColor }>`
  width: ${({ theme }) => theme.pot.colorBar.width};
  height: ${({ theme }) => theme.pot.colorBar.width};
  border-radius: 50%;
  background-color: ${({ theme, potColor }) => theme.pot.potColor[potColor]}}
`
