import type { TPickAndRequired } from '@retire/types'
import { DEFAULT_CURRENCY, DEFAULT_PRECISION } from '@retire/utils'

import type { TCurrencyComponent } from './types'

export const DEFAULT_PROPS: TPickAndRequired<
  TCurrencyComponent,
  'currency' | 'precision' | 'shouldAnimateNumber' | 'animationTimeLapse' | 'numberAnimationCounts' | 'isSubUnit'
> = {
  currency: DEFAULT_CURRENCY,
  precision: DEFAULT_PRECISION,
  shouldAnimateNumber: true,
  animationTimeLapse: 30,
  numberAnimationCounts: 20,
  isSubUnit: true,
}
