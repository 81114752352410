import { useFeatureFlag } from '@retire/hooks/useFeatureFlag'
import { useModalWithTranslation } from '@retire/hooks/useModalWithTranslation'
import { useRiskLevelModalParams } from '@retire/hooks/useRiskLevelModalParams'
import { useTotalExpenseRatioModalParams } from '@retire/hooks/useTotalExpenseRatioModalParams'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'
import { createRef, useCallback } from 'react'

import { Divider } from '../Divider'
import { FundFactSheetLink } from '../FundFactSheetLink/presentation'
import { Link } from '../Link'
import { Spacer } from '../Spacer'
import { Copy } from '../Typography/Copy'
import { Headline } from '../Typography/Headline'
import { LabelValue } from './LabelValue/presentation'
import type { TFundCardContent } from './types'

export const FundCardContent: FC<TFundCardContent> = ({
  annualManagementCharge,
  description,
  factsheetUrl,
  name,
  riskLevel,
  totalExpenseRatio,
}) => {
  const { t } = useTranslationWithOptions('common')
  const isTotalExpenseRatioEnabled = useFeatureFlag('enable_total_expense_ratio')
  const { Modal: RiskLevelModal, onShowModal: showRiskLevelModal } = useModalWithTranslation()
  const { Modal: TotalExpenseRatioModal, onShowModal: showTotalExpenseRatioModal } = useModalWithTranslation()
  const riskLevelModalParams = useRiskLevelModalParams()
  const riskLevelModalOpenerRef = createRef<HTMLButtonElement>()
  const totalExpenseRatioModalParams = useTotalExpenseRatioModalParams()
  const totalExpenseRatioModalOpenerRef = createRef<HTMLButtonElement>()
  const openRiskLevelModal = useCallback(
    () =>
      showRiskLevelModal({
        ...riskLevelModalParams,
        modalOpenerRef: riskLevelModalOpenerRef,
      }),
    [showRiskLevelModal, riskLevelModalParams, riskLevelModalOpenerRef]
  )
  const openTotalExpenseRatioModal = useCallback(
    () =>
      showTotalExpenseRatioModal({
        ...totalExpenseRatioModalParams,
        modalOpenerRef: totalExpenseRatioModalOpenerRef,
      }),
    [showTotalExpenseRatioModal, totalExpenseRatioModalParams, totalExpenseRatioModalOpenerRef]
  )

  return (
    <>
      <Headline level={HeadlineLevel.h4} as="h4">
        {name}
      </Headline>
      <Spacer bottom="medium" top="medium">
        <Copy as="span">{description}</Copy>
      </Spacer>
      <Spacer top="small" bottom="small">
        <LabelValue label={t('fundCardContent.riskLevel', 'Risk level:')} value={String(riskLevel)} />
      </Spacer>
      {/* @TODO: https://smartpension.atlassian.net/browse/CM-1745 */}
      {!isTotalExpenseRatioEnabled && (
        <Spacer top="small" bottom="small">
          <LabelValue
            label={t('fundCardContent.charge', 'Annual management charge:')}
            value={`${annualManagementCharge}%`}
          />
        </Spacer>
      )}
      {isTotalExpenseRatioEnabled && (
        <Spacer top="small" bottom="small">
          <LabelValue
            label={t('fundCardContent.totalExpenseRatio', 'Total expense ratio:')}
            value={`${totalExpenseRatio}%`}
          />
        </Spacer>
      )}
      <Spacer top="medium" bottom="medium">
        <Link as="button" ref={riskLevelModalOpenerRef} onClick={openRiskLevelModal} inline>
          {t('fundCardContent.riskLevelNotificationLink', 'How we define the risk level')}
        </Link>
      </Spacer>
      {isTotalExpenseRatioEnabled && (
        <Spacer top="medium" bottom="medium">
          <Link as="button" ref={totalExpenseRatioModalOpenerRef} onClick={openTotalExpenseRatioModal} inline>
            {t('fundCardContent.totalExpenseRatioNotificationLink', 'What is the total expense ratio?')}
          </Link>
        </Spacer>
      )}
      {factsheetUrl && (
        <Spacer top="small" bottom="small">
          <Divider />
          <Spacer bottom="medium" />
          <FundFactSheetLink url={factsheetUrl} />
        </Spacer>
      )}
      <RiskLevelModal />
      <TotalExpenseRatioModal />
    </>
  )
}
