import { Button, Spacer } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PageTemplate } from '../PageTemplate'
import type { TNotFoundTemplate } from './types'

export const NotFoundTemplate: FC<TNotFoundTemplate> = ({ title, links, buttonPath, buttonLabel }) => {
  const { t } = useTranslationWithOptions('common')
  const navigate = useNavigate()

  const onClick = useCallback(() => buttonPath && navigate(buttonPath), [buttonPath, navigate])

  return (
    <PageTemplate
      title={title}
      subTitle={
        <span>
          {links}
          {buttonLabel && buttonPath && (
            <Button buttonStyleType={ButtonStyleType.primary} onClick={onClick}>
              {buttonLabel}
            </Button>
          )}
          <Spacer bottom="extraLarge" />
          <Trans
            i18nKey="caption"
            components={{
              bold: <strong />,
            }}
          >
            {t('hygienePages.notFound.caption', 'Not found caption')}
          </Trans>
        </span>
      }
    />
  )
}
