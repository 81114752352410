import { AuthOutlet } from '@retire/navigation/AuthOutlet'
import { paths } from '@retire/navigation/paths'
import type { RouteObject } from 'react-router-dom'

import { ContinueApplication, NonEligible, ServerError } from './pages'

export const hygienePagesRoutes: RouteObject[] = [
  {
    path: paths.hygienePages.continueApplication,
    element: (
      <AuthOutlet>
        <ContinueApplication />
      </AuthOutlet>
    ),
  },
  {
    path: paths.hygienePages.serverError,
    element: <ServerError />,
  },
  { path: paths.hygienePages.nonEligible, element: <NonEligible /> },
]
