import { useGlobalErrorContext } from '@retire/hooks/useGlobalErrorContext'
import { useGlobalLoadingContext } from '@retire/hooks/useGlobalLoadingContext'
import { useSessionCountdownContext } from '@retire/hooks/useSessionCountdownContext'
import { mainRootPath } from '@retire/navigation/paths'
import type { FC, SyntheticEvent } from 'react'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

import { GlobalErrorOverlay } from './GlobalErrorOverlay'
import { GlobalLoadingOverlay } from './GlobalLoadingOverlay'
import { useIsUserLive } from './hooks/useIsUserLive'
import { useNoFooterPage } from './hooks/useNoFooterPage'
import { usePageOverlay } from './hooks/usePageOverlay'
import { usePageTitle } from './hooks/usePageTitle'
import { useWithLeavePageConfirmation } from './hooks/useWithLeavePageConfirmation'
import { useWithSimpleHeader } from './hooks/useWithSimpleHeader'
import { LeavePageConfirmation } from './LeavePageConfirmation'
import { PageLayout } from './presentation'
import { SessionCountdown } from './SessionCountdown'

export const PageLayoutContainer: FC = ({ children, ...props }) => {
  const { globalError } = useGlobalErrorContext()
  const { isGlobalLoading } = useGlobalLoadingContext()
  const { aboutToExpire } = useSessionCountdownContext()
  const { isOverlay } = usePageOverlay()
  const { title } = usePageTitle()
  const navigate = useNavigate()
  const isUserLive = useIsUserLive()
  const isSimpleHeaderPage = useWithSimpleHeader()
  const isNoFooterPage = useNoFooterPage()

  const leavePage = useCallback(() => navigate(mainRootPath), [navigate])
  const { isLeavePageConfirmationVisible, toggleLeavePageConfirmation } = useWithLeavePageConfirmation({ leavePage })
  const pageContent = isLeavePageConfirmationVisible ? (
    <LeavePageConfirmation hideConfirmation={toggleLeavePageConfirmation} leavePage={leavePage} />
  ) : (
    children
  )
  const onLogoClick = useCallback(
    () =>
      isUserLive && !isLeavePageConfirmationVisible
        ? (e: SyntheticEvent) => {
            e.preventDefault()
            toggleLeavePageConfirmation()
          }
        : undefined,
    [isLeavePageConfirmationVisible, isUserLive, toggleLeavePageConfirmation]
  )

  if (globalError) {
    return <GlobalErrorOverlay />
  }

  if (isGlobalLoading) {
    return <GlobalLoadingOverlay />
  }

  if (aboutToExpire) {
    return <SessionCountdown />
  }

  return (
    <PageLayout
      {...props}
      whiteBg={isOverlay && !isLeavePageConfirmationVisible}
      onLogoClick={onLogoClick}
      hideFooter={isNoFooterPage || isLeavePageConfirmationVisible}
      onlyLogoVisible={isLeavePageConfirmationVisible || isSimpleHeaderPage}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {pageContent}
    </PageLayout>
  )
}
