import { useTranslationWithOptions } from '../useTranslationWithOptions'
import type { TUseTotalExpenseRatioModalParamsReturn } from './types'

export const useTotalExpenseRatioModalParams = (): TUseTotalExpenseRatioModalParamsReturn => {
  const { t } = useTranslationWithOptions('common')

  return {
    title: t('totalExpenseRatio.modalContent.title', 'What is the total expense ratio?'),
    content: t(
      'totalExpenseRatio.modalContent.content',
      'We charge a percentage of your investments for managing and investing your pension.'
    ),
    components: {
      bold: <strong />,
    },
  }
}
