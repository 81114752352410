import isTouchDevice from 'is-touch-device'
import type { FC, ReactElement } from 'react'
import { Children, cloneElement, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { MobileDropdown } from '../../MobileDropdown'
import { DEFAULT_PROPS } from './constants'
import { useDropdown } from './hooks/useDropdown'
import { NativeSelect } from './NativeSelect'
import { Select } from './Select'
import type { TDropdown } from './types'

export const Dropdown: FC<TDropdown> = ({
  mobileDropdownOverlayTitle,
  name,
  disabled,
  ariaDescribedBy,
  ariaLabelledBy,
  ariaInvalid,
  invalid,
  value: selectedValue,
  renderSelectedValue,
  options,
  placeholder,
  children,
  onChange,
  withMobileDropdown,
  SDropdownWrapper = DEFAULT_PROPS.SDropdownWrapper,
  SOuterWrapper = DEFAULT_PROPS.SOuterWrapper,
}) => {
  const { iconColor, setIsOpen, isOpen } = useDropdown({ disabled })
  const { setValue, getValues } = useFormContext()
  useEffect(() => {
    // selecting the default option
    //   - if a value is provided via react-hook-form use that value as default
    //   - if not and there is no value and placeholder yet select the first option
    if (!selectedValue && !placeholder) {
      const hookFormValue = getValues(name)
      if (hookFormValue) {
        onChange && onChange(hookFormValue)
      } else {
        onChange && onChange(options[0]?.value)
        setValue(name, options[0]?.value)
      }
    }
  }, [placeholder, selectedValue, options, onChange, setValue, name, getValues])

  const selectedOption = options.find(({ value }) => value === selectedValue)
  const selectProps = {
    invalid,
    disabled,
    ariaDescribedBy,
    ariaLabelledBy,
    ariaInvalid,
    renderSelectedValue,
    options,
    placeholder,
    onChange,
    isOpen,
    setIsOpen,
    iconColor,
    selectedOption,
  }
  const childrenProps = {
    ariaLabelledBy,
    ariaDescribedBy,
  }
  const isMobile = isTouchDevice()
  const mobileDropdownOptions = useMemo(
    () =>
      options.map(({ label, value, description, isDisabled }) => ({
        label,
        value: value || label,
        description,
        disabled: isDisabled,
      })),
    [options]
  )

  return (
    <>
      {withMobileDropdown && isMobile ? (
        <MobileDropdown
          renderSelectedValue={renderSelectedValue}
          value={selectedValue}
          placeholder={placeholder}
          options={mobileDropdownOptions}
          onChange={onChange}
          title={mobileDropdownOverlayTitle}
          name={name}
        />
      ) : (
        <SOuterWrapper role="group" isMobile={isMobile}>
          <SDropdownWrapper
            tabIndex={-1}
            aria-haspopup="listbox"
            isOpen={isOpen}
            selectedOption={selectedOption}
            disabled={disabled}
            invalid={invalid}
          >
            {isMobile ? <NativeSelect {...selectProps} /> : <Select {...selectProps} />}
          </SDropdownWrapper>
          {Children.map(children, child => cloneElement(child as ReactElement, childrenProps))}
        </SOuterWrapper>
      )}
    </>
  )
}
