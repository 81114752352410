import { styled } from '@retire/theme/styledComponents'

import { SLink } from '../presentation.styled'
import type { TIconHolder, TIconLink } from './types'

export const SLinkWithIcon = styled(SLink)<TIconLink>`
  padding: 0 ${({ theme }) => theme.spacing.extraSmall};
  margin-left: ${({ position, theme }) => (position === 'left' ? theme.links.icon.padding : '0')};
  margin-right: ${({ position, theme }) => (position === 'right' ? theme.links.icon.padding : '0')};
`

export const IconHolder = styled.div<TIconHolder>`
  display: inline-block;
  width: ${({ size, theme }) => theme.links.icon.size[size || 'normal']};
  padding-left: ${({ theme }) => theme.links.icon.padding};
  position: relative;

  svg {
    width: ${({ size, theme }) => theme.links.icon.size[size || 'normal']} !important;
    height: ${({ size, theme }) => theme.links.icon.size[size || 'normal']} !important;
    ${({ position }) => (position === 'left' ? 'left: 0' : 'right: 0')};
    position: absolute;
    top: ${({ theme }) => theme.links.icon.topOffset};
  }
`
