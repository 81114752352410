import { AddressField } from '@retire/constants'
import { findAddress, retrieveAddress } from '@retire/services/LocationService'
import type { TAddressData } from '@retire/types'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import type { TAddressResult } from '../../Search'

export const useAddressLookup = (): {
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  isSearchError: boolean
  onResultClick: (id: string) => void
  triggerSearch: (searchTerm: string) => void
  searchResults: TAddressResult[]
} => {
  const [searchResults, setSearchResults] = useState<TAddressResult[]>()
  const [searchValue, setSearchValue] = useState('')
  const [isSearchError, setIsSearchError] = useState(false)
  const { setValue } = useFormContext<TAddressData>()

  const onResultClick = async (id: string) => {
    const clickedResult = searchResults?.find(item => item.id === id)
    if (clickedResult && clickedResult.next === 'Find') {
      const result = await findAddress({ id })
      setSearchResults(result)
      setIsSearchError(!result)
    } else {
      const addressData = await retrieveAddress(id)
      const getAddressValue = (key: string): string =>
        addressData && key in addressData ? addressData[key] : undefined
      const addressValues = {} as TAddressData
      Object.values(AddressField).forEach(addressField => {
        const addressValue = getAddressValue(addressField)
        addressValues[addressField] = addressValue
        setValue(addressField, addressValue, { shouldDirty: true })
      })
    }
  }

  const triggerSearch = async (searchTerm: string) => {
    setIsSearchError(false)
    setSearchResults([])
    const result = await findAddress({ searchTerm })
    setSearchResults(result)
    setIsSearchError(!result.length)
  }

  return {
    searchValue,
    setSearchValue,
    isSearchError,
    onResultClick,
    triggerSearch,
    searchResults: searchResults || [],
  }
}
