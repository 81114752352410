import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

export const CommonHeaderStyle = css`
  ${({
    theme: {
      header: { paddingTopMobile, paddingHorizontalMobile, paddingHorizontalMedium },
    },
  }) => css`
    ${mediaStyles(
      { paddingLeft: paddingHorizontalMedium, paddingRight: paddingHorizontalMedium },
      {
        max: Breakpoint.extraLarge,
      }
    )}
    ${mediaStyles({ padding: `${paddingTopMobile} ${paddingHorizontalMobile}` }, { max: Breakpoint.small })}
  `}
`

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
`

export const SPageContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    padding: ${spacing.extraLarge} 0 ${spacing.extraExtraLarge};

    ${mediaStyles({ padding: `${spacing.large} ${spacing.small} ${spacing.extraLarge}` }, { max: Breakpoint.small })}
  `}
`

export const SOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: ${({ theme }) => theme.colors.neutral50};
  opacity: 0.5;
`
