import { Table, Tag } from '@retire/components'
import { DateFormat } from '@retire/constants'
import { useGetPotDisplayName } from '@retire/hooks/useGetPotDisplayName'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { formatDate } from '@retire/utils'
import type { Property } from 'csstype'
import type { FC } from 'react'

import { getPotNameFromInstructionPot } from '../../gql'
import { useTransactionDetails } from '../../hooks'
import { EMPTY_DETAILS } from '../../hooks/useTransactionDetails/constants'
import { TransactionDetail } from '../TransactionDetail'
import { CancelButton, DetailsTwister } from './components'
import { SPotNameWrapper } from './presentation.styled'
import type { TTransactionTable } from './types'
import { getFormattedAmount, isCancellable, isCancelled, isFailed, isPaidIn, isPaidOut } from './utils'

export const TransactionTable: FC<TTransactionTable> = ({
  translationKey,
  displayAmount,
  transactions,
  onCancelClick,
  showDetails,
  hideDate,
  showExtremeBorders,
}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const { getPotDisplayLongName } = useGetPotDisplayName()
  const { allDetails, onToggle, detailsVisibility } = useTransactionDetails(transactions)

  return (
    <Table
      title={t(`transactionList.tables.title.${translationKey}`, `Table title ${translationKey}`)}
      columnHeaders={[
        { content: t('transactionList.tables.headers.date'), width: '20%', hidden: hideDate },
        { content: t('transactionList.tables.headers.from'), width: '25%' },
        { content: t('transactionList.tables.headers.to'), width: '25%' },
        {
          content: t(`transactionList.tables.headers.amount.${translationKey}`),
          width: onCancelClick || showDetails ? '25%' : '30%',
        },
        { content: '', width: onCancelClick || showDetails ? '5%' : undefined },
      ].filter(({ hidden }) => !hidden)}
      rows={transactions.map(transaction => {
        const fromPot = getPotNameFromInstructionPot(transaction.sellPotType)
        const toPot = getPotNameFromInstructionPot(transaction.buyPotType)

        const isPaidInTransaction = isPaidIn(transaction)
        const isPaidOutTransaction = isPaidOut(transaction)
        const isFailedTransaction = isFailed(transaction)
        const isCancelledTransaction = isCancelled(transaction)

        const failedTag = isFailedTransaction && (
          <Tag variant="error">{t('transactionList.tables.cells.failed', 'Failed flag')}</Tag>
        )
        const cancelledTag = isCancelledTransaction && (
          <Tag variant="default">{t('transactionList.tables.cells.cancelled', 'Cancelled flag')}</Tag>
        )

        const {
          data: { sell: sellDetail, buy: buyDetail },
        } = allDetails[transaction.id] || EMPTY_DETAILS
        const hideDetails = detailsVisibility[transaction.id]

        const cancelButton = onCancelClick && isCancellable(transaction) && (
          <CancelButton onCancelClick={onCancelClick} transactionId={transaction.id} />
        )

        const detailsTwister = showDetails && (
          <DetailsTwister transactionId={transaction.id} isOpen={!hideDetails} onToggle={onToggle} />
        )

        return {
          cells: [
            { content: formatDate(transaction.createdAt, DateFormat.longDate), hidden: hideDate },
            {
              content: (
                <SPotNameWrapper>
                  {isPaidInTransaction
                    ? t('transactionList.tables.cells.paidIn', 'Paid in')
                    : getPotDisplayLongName(fromPot)}
                </SPotNameWrapper>
              ),
            },
            {
              content: (
                <SPotNameWrapper>
                  {isPaidOutTransaction
                    ? t('transactionList.tables.cells.paidOut', 'Paid out')
                    : getPotDisplayLongName(toPot)}
                </SPotNameWrapper>
              ),
            },
            {
              content: getFormattedAmount(transaction, isCancelledTransaction ? 'amount' : displayAmount),
            },
            {
              content: cancelButton || cancelledTag || failedTag || detailsTwister,
              textAlign: 'right' as Property.TextAlign,
            },
          ].filter(({ hidden }) => !hidden),
          subCells: !hideDetails
            ? [
                { content: undefined, hidden: hideDate },
                {
                  content: sellDetail && <TransactionDetail detail={sellDetail} />,
                  verticalAlign: 'top',
                },
                {
                  content: buyDetail && <TransactionDetail detail={buyDetail} />,
                  verticalAlign: 'top',
                },
                { content: undefined },
                { content: '' },
              ].filter(({ hidden }) => !hidden)
            : undefined,
        }
      })}
      showExtremeBorders={showExtremeBorders}
    />
  )
}
