import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Exit: FC = () => (
  <svg x="0px" y="0px" viewBox="0 0 18.2 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <IconTitle iconType="Exit" />
    <path
      d="M10.7,8.9l5.2-5.2c0.5-0.5,0.5-1.3,0-1.8c-0.5-0.5-1.3-0.5-1.8,0L8.9,7.1L3.7,1.9c-0.5-0.5-1.3-0.5-1.8,0
		s-0.5,1.3,0,1.8l5.2,5.2l-5.2,5.2c-0.5,0.5-0.5,1.3,0,1.8c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4l5.2-5.2l5.2,5.2
		c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.8L10.7,8.9z"
    />
  </svg>
)
