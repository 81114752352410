import { PotName } from '@retire/constants'
import type { ComponentType, FC } from 'react'
import { useFormState, useWatch } from 'react-hook-form'

import { AMOUNT_INPUT_NAME } from '../../constants'
import type { TPotNameOrBankAccount } from '../../types'
import type { TIncomePot } from './types'

export const withIncomePot = (Component: ComponentType<TIncomePot>) => {
  const Wrapped: FC<{ potName: TPotNameOrBankAccount }> = props => {
    const amount = useWatch({ name: AMOUNT_INPUT_NAME })
    const { potName } = props
    const { isValid } = useFormState()
    const isIncomePot = potName === PotName.flexibleIncome || potName === PotName.laterLife

    return isIncomePot && amount && isValid ? <Component {...props} potName={potName} /> : <></>
  }

  return Wrapped
}
