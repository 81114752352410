import { Button } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { useCallback } from 'react'

import type { TCancelButton } from './types'

export const CancelButton: FC<TCancelButton> = ({ onCancelClick, transactionId }) => {
  const { t } = useTranslationWithOptions('dashboard')

  const onClick = useCallback(() => onCancelClick(transactionId), [onCancelClick, transactionId])

  return (
    <Button buttonStyleType={ButtonStyleType.secondary} size="small" onClick={onClick} lockWidth>
      {t('transactionList.tables.actions.cancel', 'Cancel transaction')}
    </Button>
  )
}
