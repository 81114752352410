import { forwardRef } from 'react'

import { Icon } from '../../Icon'
import { DEFAULT_PROPS } from './constants'
import { IconHolder, SLinkWithIcon } from './presentation.styled'
import type { TIconLink } from './types'

export const IconLink = forwardRef<HTMLButtonElement, TIconLink>(({ children, ...props }, ref) => {
  const { iconColors, iconType, position, size = DEFAULT_PROPS.size, target, rel } = props

  return (
    <SLinkWithIcon
      ref={ref}
      title={String(children)}
      {...props}
      rel={target === '_blank' ? 'noopener noreferrer' : rel}
    >
      {position === 'right' && children}
      <IconHolder position={position} size={size}>
        <Icon iconType={iconType} {...iconColors} />
      </IconHolder>
      {position === 'left' && children}
    </SLinkWithIcon>
  )
})

IconLink.displayName = 'IconLink'
