import { getUrlWithQueryParam } from '../url'
import { getLinkerParameter } from './utils'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer?: unknown[]
    [key: string]: unknown
  }
}

export const decorateUrl = (urlString: string) => {
  try {
    const linkerParameter = getLinkerParameter()
    const newUrl = getUrlWithQueryParam(linkerParameter, urlString)
    return newUrl.toString()
  } catch (error) {
    console.error('[GA error]', error)
    return urlString // In case of errors, default to the undecorated url
  }
}

export const assignUserId = (userId: string) => {
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      userId,
    })
  } catch (error) {
    console.error('[GA error]', error)
  }
}
