import { TaxFreeLumpSumWithdrawalState } from '@retire/constants/pots'

import type { TIllustrationPlanData } from '../../types'

export const DEFAULT_ILLUSTRATION_PLAN_CONFIGURABLE_DATA: Pick<
  TIllustrationPlanData,
  | 'taxFreeLumpSumWithdrawalState'
  | 'flexibleIncomeAmount'
  | 'regularIncomeStartAge'
  | 'regularIncomeEndAge'
  | 'laterLifeAmount'
  | 'laterLifeAge'
  | 'inheritanceAmount'
  | 'rainyDayAmount'
> = {
  taxFreeLumpSumWithdrawalState: TaxFreeLumpSumWithdrawalState.undecided,
  flexibleIncomeAmount: null,
  regularIncomeStartAge: null,
  regularIncomeEndAge: null,
  laterLifeAmount: null,
  laterLifeAge: null,
  inheritanceAmount: null,
  rainyDayAmount: null,
}

export const DEFAULT_ILLUSTRATION_PLAN_DATA: TIllustrationPlanData = {
  ...DEFAULT_ILLUSTRATION_PLAN_CONFIGURABLE_DATA,
  valuationAmount: 0,
  remainingSavings: 0,
  partiallyUpdatedAt: null,
  valuationUpdatedAt: null,
}
