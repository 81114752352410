import { Copy, Headline, Segment, Spacer } from '@retire/components'
import { useFeesModal, useTranslationWithOptions } from '@retire/hooks'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { HeadlineLevel, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

export const Fees: FC = () => {
  const { t } = useTranslationWithOptions('illustration')
  const { FeesModal, FeesModalLink } = useFeesModal()

  return (
    <Segment whiteBackground>
      <ContentWrapper>
        <Headline level={HeadlineLevel.h2} as="h2" color={TypographyColor.primary}>
          {t('yourIllustration.fees.title', 'Your illustration fees title')}
        </Headline>
        <Spacer bottom="medium" />
        <Copy as="div">
          <Trans components={[<FeesModalLink />]}>
            {t('yourIllustration.fees.subTitle', '<0>See fees details</0>')}
          </Trans>
        </Copy>
      </ContentWrapper>
      <FeesModal />
    </Segment>
  )
}
