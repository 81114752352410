import { SNavButtons, SNextButton } from '@retire/components/NavButtons/presentation.styled'
import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

export const Wrapper = styled.div`
  ${SNavButtons} ${SNextButton} {
    border: none;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const ContentWrapper = styled.div`
  ${({ theme: { colors, spacing, breakpoints } }) => css`
    color: ${colors.neutralDark};
    white-space: break-spaces;
    margin-bottom: ${spacing.large};

    ${mediaStyles({ width: `${breakpoints.medium}px` }, { min: Breakpoint.large })}
    ${mediaStyles({ width: 'initial' }, { max: Breakpoint.large })}
  `}
`

export const IconWrapper = styled.div`
  margin-left: -${props => props.theme.spacing.medium};
  margin-top: -${props => props.theme.spacing.medium};
`
