import { paths } from '@retire/navigation/paths'
import { useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import type { TWithLeavePageConfirmation, TWithLeavePageConfirmationResult } from './types'

export const useWithLeavePageConfirmation = ({
  leavePage,
}: TWithLeavePageConfirmation): TWithLeavePageConfirmationResult => {
  const { pathname } = useLocation()
  const withLeaveConfirmationPathPatterns: string[] = [paths.dashboard.cancelTransaction]
  const isPageWithLeaveConfirmation = withLeaveConfirmationPathPatterns.some(pathPattern =>
    matchPath(pathPattern, pathname)
  )
  const [leaveConfirmationVisible, setLeaveConfirmationVisible] = useState(false)

  const toggleLeavePageConfirmation = () => {
    if (!isPageWithLeaveConfirmation) {
      leavePage()
      return
    }
    setLeaveConfirmationVisible(!leaveConfirmationVisible)
  }

  return {
    isLeavePageConfirmationVisible: leaveConfirmationVisible,
    toggleLeavePageConfirmation,
  }
}
