import { Column } from '@retire/components'
import { Breakpoint } from '@retire/theme'
import { styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'
import Modal from 'react-modal'

export const SMenuModal = styled(Modal)`
  position: absolute;
  inset: 0 0 auto auto;
  height: 100%;
  min-width: 354px;
  max-width: 100vw;
  background-color: ${({
    theme: {
      header: { backgroundColor },
    },
  }) => backgroundColor};
  outline: 0;

  transform: translateX(100%);
  transition: transform 400ms ease-in-out;

  &.ReactModal__Content--after-open {
    transform: translateX(0);
  }

  &.ReactModal__Content--before-close {
    transform: translateX(100%);
  }

  ${mediaStyles(
    {
      width: '100vw',
    },
    {
      max: Breakpoint.small,
    }
  )}
`

export const SNavBarLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ theme: { spacing } }) => `
    padding: ${spacing.medium};
    margin-bottom: ${spacing.extraLarge}
  `};
`

export const SUserInfoLayout = styled(Column)`
  ${({ theme: { spacing } }) => `
    padding: ${spacing.extraLarge} 0;
  `};
`
