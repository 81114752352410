import { PotName } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

import { BalanceCard } from '../../components/PotDetails/BalanceCard/presentation'
import { PageTitle } from '../../components/PotDetails/PageTitle/presentation'
import { PendingAmount } from '../../components/PotDetails/PendingAmount/presentation'
import { RainyDayPotFundsCard } from '../../components/PotDetails/RainyDayPotFundsCard'
import { WhatItsForCard } from '../../components/PotDetails/WhatItsForCard/presentation'
import { usePendingTransactions } from '../../hooks/usePendingTransactions'
import { usePotDetails } from '../../hooks/usePotDetails'

export const RainyDayPotDetails: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const {
    loadingPotDetails,
    potDetails: { isLockedForSells, isLockedForBuys, allocatedAmount = 0 },
  } = usePotDetails(PotName.rainyDay)
  const {
    loading: loadingPendingTransactions,
    data: { pendingAmounts },
  } = usePendingTransactions()

  const potLocked = isLockedForSells || isLockedForBuys
  const title = t('pots.rainyDayPot.title', `${PotName.rainyDay} details`, {
    ...(potLocked && { locked: t('pots.common.lockedShort', 'locked') }),
  })

  return (
    <PageTemplate fullWidth title={<PageTitle title={title} variant={PotName.rainyDay} />} loading={loadingPotDetails}>
      <WhatItsForCard potName={PotName.rainyDay} />
      <BalanceCard
        balance={allocatedAmount}
        buttonText={t('pots.common.balanceCard.actions.withdrawOrMoveMoney', 'Withdraw or move money')}
      >
        <PendingAmount loading={loadingPendingTransactions} pendingAmount={pendingAmounts[PotName.rainyDay]} />
      </BalanceCard>
      <RainyDayPotFundsCard />
    </PageTemplate>
  )
}
