import { FormOptionsGroup } from '@retire/components'
import { toPounds } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { usePotsContext } from '../../../hooks/usePotsContext'
import {
  AMOUNT_INPUT_NAME,
  AMOUNT_OPTIONS_GROUP_NAME,
  AmountToMoveMethod,
  FROM_INPUT_NAME,
  TO_INPUT_NAME,
} from '../constants'
import { useAmountOptions } from '../hooks/useAmountOptions'
import type { TMoveMoneyFormFields, TPotNameOrBankAccount } from '../types'

const rules = { required: true }

export const AmountOptionsGroup: FC = () => {
  const { setValue } = useFormContext()
  const [customAmount, setCustomAmount] = useState('')
  const { pots } = usePotsContext()

  const [fromPot, toPotOrAccount, moveMoneyMethod] = useWatch<TMoveMoneyFormFields>({
    name: [FROM_INPUT_NAME, TO_INPUT_NAME, AMOUNT_OPTIONS_GROUP_NAME],
  })

  const options = useAmountOptions({
    disabled: !fromPot || !toPotOrAccount,
    fromPotBalance: pots[fromPot]?.allocatedAmount,
    moveMoneyMethod: moveMoneyMethod as AmountToMoveMethod,
    setCustomAmount,
    toPotOrAccount: toPotOrAccount as TPotNameOrBankAccount,
  })

  const onChange = useCallback(
    selectedMethod => {
      const fromPotBalance = String(toPounds(pots[fromPot]?.allocatedAmount))
      const value = selectedMethod === AmountToMoveMethod.fullPotBalance ? fromPotBalance : customAmount

      setValue(AMOUNT_INPUT_NAME, value, { shouldValidate: true })
    },
    [customAmount, fromPot, pots, setValue]
  )

  return <FormOptionsGroup name={AMOUNT_OPTIONS_GROUP_NAME} options={options} rules={rules} onChange={onChange} />
}
