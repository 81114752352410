export const LATER_LIFE_MINIMAL_GUARANTEED_INCOME = 450000

export enum PotName {
  flexibleIncome = 'flexibleIncomePot',
  laterLife = 'laterLifePot',
  inheritance = 'inheritancePot',
  rainyDay = 'rainyDayPot',
}

export enum PotType {
  income = 'income',
  savings = 'savings',
}

export enum TaxFreeLumpSumWithdrawalState {
  undecided = 'undecided',
  taken = 'taken',
  untaken = 'untaken',
}

export enum AdditionalPotName {
  taxFree = 'taxFree',
}

export const ALL_POT_NAMES: PotName[] = Object.values(PotName)

export const POT_NAMES_BY_TYPE = {
  [PotType.income]: [PotName.flexibleIncome, PotName.laterLife],
  [PotType.savings]: [PotName.inheritance, PotName.rainyDay],
}
