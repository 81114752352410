import { AuthOutlet } from '@retire/navigation/AuthOutlet'
import { paths, roots } from '@retire/navigation/paths'
import type { RouteObject } from 'react-router-dom'

import { BeforeYouStart, CheckItsRightForYou } from './pages'

export const suitabilityCheckRoutes: RouteObject[] = [
  {
    path: roots.suitabilityCheck,
    element: <AuthOutlet />,
    children: [
      { path: paths.suitabilityCheck.check, element: <BeforeYouStart /> },
      { path: paths.suitabilityCheck.confirm, element: <CheckItsRightForYou /> },
    ],
  },
]
