import { LinkSize } from '@retire/constants'
import { styled } from '@retire/theme/styledComponents'

import { DEFAULT_PROPS } from './constants'
import type { TLink } from './types'

export const SLink = styled.button<TLink>`
  border-radius: ${props => props.theme.forms.shared.border.radius};
  padding: 0 ${props => (props.inline ? 0 : props.theme.spacing.extraSmall)};
  font-family: inherit;
  border: none;
  background: none;
  letter-spacing: normal;
  cursor: pointer;
  color: ${({ theme, variant = DEFAULT_PROPS.variant }) => theme.links[variant].states.default.color};
  text-decoration: ${({ noDecoration, theme, variant = DEFAULT_PROPS.variant }) =>
    noDecoration ? 'none' : theme.links[variant].states.default.textDecoration};

  ${({ inline, bold, theme: { footer, buttons, utilities }, size }) =>
    inline
      ? `
          font-size: inherit;
          font-weight: inherit;
          display: inline;
          font-weight: ${bold ? utilities.weight.bold : utilities.weight.regular};
        `
      : `
          display: inline-block;
          line-height: ${size === LinkSize.large ? footer.linkLineHeight : 'inherit'};
          font-size: ${buttons.size[size === LinkSize.large ? 'default' : 'small'].size};
          font-weight: ${
            bold ? utilities.weight.bold : size === LinkSize.large ? utilities.weight.semiBold : buttons.weight
          };
    `}

  ${({ disabled, variant = DEFAULT_PROPS.variant, theme: { links } }) =>
    disabled
      ? `
        color: ${links.disabled.color};`
      : `
        &:hover {
          color: ${links[variant].states.hover.color};
          path {
            stroke: ${links[variant].states.hover.color};
          }
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px
            ${links[variant].states.focus.borderColor};
        }

        &:active {
          color: ${links[variant].states.active.color};
          outline: none;
        }
      `}
`
