import { Divider, FormOptionsGroup, NavButtons, Spacer } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ManageIncomeAction } from './constants'
import type { TChooseAction, TChooseActionFormFields } from './types'

const rules = { required: true }

export const ChooseAction: FC<TChooseAction> = ({ onContinue, onQuit }) => {
  const { t } = useTranslationWithOptions('dashboard')

  const methods = useForm<TChooseActionFormFields>({
    mode: 'all',
    defaultValues: {},
  })

  const actionOptions = useMemo(
    () => [
      { value: ManageIncomeAction.amount, label: t('pots.common.manageIncome.actionOptions.amount', 'Amount') },
      { value: ManageIncomeAction.time, label: t('pots.common.manageIncome.actionOptions.time', 'Age') },
    ],

    [t]
  )
  const onContinueClick = methods.handleSubmit(data => onContinue(data))

  return (
    <FormProvider {...methods}>
      <PageTemplate fullWidth title={t('pots.common.manageIncome.title', 'Manage your income')} onQuit={onQuit}>
        <Spacer top="large" bottom="large">
          <FormOptionsGroup name="action" options={actionOptions} rules={rules} />
        </Spacer>
        <Divider bottom="large" />
        <NavButtons
          onNext={onContinueClick}
          disableNext={!methods.formState.isValid}
          nextText={t('pots.common.manageIncome.actions.continue', 'Continue')}
        />
      </PageTemplate>
    </FormProvider>
  )
}
