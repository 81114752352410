import { LocationAlertsContext } from '@retire/contexts/locationAlerts/context'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import type { TUseLocationAlerts } from './types'

export const useLocationAlerts = (): TUseLocationAlerts => {
  const { alerts, addAlert, resetAlerts } = useContext(LocationAlertsContext)
  const navigate = useNavigate()
  const navigateWithAlerts = useCallback(
    (to, navigateAlerts) => navigate(to, { state: { alerts: navigateAlerts } }),
    [navigate]
  )

  return {
    alerts,
    navigateWithAlerts,
    addAlert,
    resetAlerts,
  }
}
