import { Button } from '@retire/components/Button'
import { Card, CardTitle } from '@retire/components/Card'
import { DataRow } from '@retire/components/DataRow'
import { Notification } from '@retire/components/Notification'
import { Spacer } from '@retire/components/Spacer'
import { ButtonStyleType } from '@retire/constants/button'
import { PotName } from '@retire/constants/pots'
import { useEstimateIncomeModal } from '@retire/hooks/useEstimateIncomeModal'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { Container, Row, Visible } from 'react-grid-system'
import { useNavigate } from 'react-router-dom'

import { ToggleIncomeLink } from './components/ToggleIncomeLink/presentation'
import type { TFlexibleIncomePotMonthlyIncomeCard } from './types'

export const FlexibleIncomePotMonthlyIncomeCard: FC<TFlexibleIncomePotMonthlyIncomeCard> = ({
  allocatedAmount,
  monthlyIncomeAmount = 0,
  startAge,
  endAge,
  leftOverAtAge = 0,
  regularIncomePaused,
}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const formattedMonthlyIncomeAmount = useMemo(() => toCurrency(monthlyIncomeAmount), [monthlyIncomeAmount])
  const formattedLeftOverAtAge = useMemo(() => toCurrency(leftOverAtAge), [leftOverAtAge])
  const { Modal, ModalLink } = useEstimateIncomeModal(PotName.flexibleIncome)
  const manageYourIncome = useCallback(() => {
    navigate(paths.dashboard.flexibleIncomePotManageIncome)
  }, [navigate])

  return (
    <Card>
      <CardTitle>{t('pots.common.monthlyIncomeCard.title', 'Monthly income')}</CardTitle>
      {!allocatedAmount ? (
        <Notification
          type="notice"
          title={t('pots.common.monthlyIncomeCard.notUsed', 'Move money to the pot to manage the income')}
          hasBorder
        />
      ) : (
        <>
          <ModalLink />
          <DataRow
            name={t('pots.common.monthlyIncomeCard.allocatedAmount', 'Allocated amount')}
            value={
              regularIncomePaused ? t('pots.common.monthlyIncomeCard.paused', 'Paused') : formattedMonthlyIncomeAmount
            }
          />
          {!regularIncomePaused && (
            <>
              <DataRow name={t('pots.common.monthlyIncomeCard.startAge', 'Start age')} value={`${startAge}`} />
              <DataRow name={t('pots.common.monthlyIncomeCard.endAge', 'End age')} value={`${endAge}`} />
              <DataRow
                name={t('pots.common.monthlyIncomeCard.leftOverAmount', 'Left over amount at age {{laterLifeAge}}', {
                  laterLifeAge: endAge,
                })}
                value={formattedLeftOverAtAge}
              />
            </>
          )}
          <Container>
            <Spacer left="small" />
            <Row>
              <Button onClick={manageYourIncome} buttonStyleType={ButtonStyleType.secondary}>
                {t('pots.common.monthlyIncomeCard.actions.manageIncome', 'Manage income')}
              </Button>
              <Visible xs sm>
                <Container>
                  <Spacer top="medium" bottom="small" />
                  <ToggleIncomeLink regularIncomePaused={regularIncomePaused} />
                </Container>
              </Visible>
              <Visible md lg xl xxl>
                <Spacer left="large" />
                <ToggleIncomeLink regularIncomePaused={regularIncomePaused} />
              </Visible>
            </Row>
          </Container>
          <Modal />
        </>
      )}
    </Card>
  )
}
