import { FUND_DETAILS_QUERY, FUND_POT_TYPES } from '@retire/gql'
import type { FundDetails } from '@retire/gql/__generated__/graphql'

import { useQuerySelector } from '../useQuerySelector'
import type { TUseFundDetails } from './types'

export const useFundDetails: TUseFundDetails = (potName, skip = false) => {
  const { data, loading } = useQuerySelector(
    {
      query: FUND_DETAILS_QUERY,
      options: {
        variables: {
          potType: FUND_POT_TYPES[potName],
        },
        skip,
      },
    },
    result => result,
    {
      fundDetails: <FundDetails>{},
    }
  )

  return { fundDetails: data.fundDetails, loading }
}
