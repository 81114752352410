import { styled } from '@retire/theme/styledComponents'

import { DEFAULT_PROPS } from './constants'
import type { TIsOpen, TTwister } from './types'

export const STwisterWrapper = styled.details<Pick<TTwister, 'bottom' | 'isOpen'>>`
  display: inline-flex;
  flex-direction: column;

  ${({ bottom = DEFAULT_PROPS.bottom, theme: { twister, spacing } }) => `
    font-family: ${twister.fontFamily};
    font-size: ${twister.fontSize};
    font-weight: ${twister.fontWeight};
    margin-bottom: ${spacing[bottom]};
  `}
`

export const SSummary = styled.summary<TIsOpen & Pick<TTwister, 'noTitleWrap'>>`
  display: flex;
  font-weight: ${({ theme: { utilities } }) => utilities.weight.bold};
  position: relative;

  &::-webkit-details-marker {
    display: none;
  }

  u {
    vertical-align: top;
  }

  ${({ isOpen, noTitleWrap, theme: { twister } }) => `
    border-radius: ${twister.border.radius};
    color: ${isOpen ? twister.summary.active.color : twister.summary.default.color};
    padding: ${twister.summary.padding};
    ${noTitleWrap ? 'white-space: nowrap;' : ''}

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${twister.summary.focus.borderColor};
    }

    &:hover {
      cursor: pointer;
      color: ${twister.summary.hover.color};
    }
  `}
`

export const SContent = styled.div`
  display: flex;
  margin-top: ${({ theme: { spacing } }) => spacing.medium};
`

export const SLeftStrip = styled.div`
  ${({
    theme: {
      twister: { leftStrip },
      spacing,
    },
  }) => `
    background-color: ${leftStrip.color};
    border-radius: calc(${leftStrip.width} / 2);
    margin: ${spacing.extraSmall} ${spacing.medium} ${spacing.extraSmall} ${spacing.small};
    min-width: ${leftStrip.width};
  `}
`
