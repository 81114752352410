import { pendingRequestsCounter, signedIn } from '@retire/gql/client/vars'
import { paths } from '@retire/navigation/paths'

import { decorateUrl } from '../analytics'
import { apiFetchWithRetry } from '../api'
import { getDecodedCookie } from '../cookies'
import { createError, reportRollbarError } from '../errors'
import { getUrlWithQueryParam } from '../url'
import { MAX_SIGNOUT_TRIES } from './constants'

export const isActiveSession = () => signedIn()

export const startSession = () => signedIn(true)

export const endSession = () => signedIn(false)

// sign OUT current user
// 1. fetch SmartPension signOut url
// 2. then call the navigateToSignIn method to properly redirect to signIn form page
export const signOut = async (doThrowError = false) => {
  try {
    await apiFetchWithRetry(paths.authentication.signOut, MAX_SIGNOUT_TRIES)
  } catch (error) {
    reportRollbarError(createError(`Attempt to signout failed: ${error}`))
    if (doThrowError) {
      throw createError(`Attempt to signout failed: ${error}`)
    }
  }
  navigateToSignIn()
}

// sign IN current user
// 1. try to get current CSRF token (received from API)
//    if no CSRF token is found: thrown an error
// 2. generate a fake form, to POST the CSRF token to SmartPension signIn form
//    also, request an eventual redirection after signIn, through "redirect" GET param
export const navigateToSignIn = (redirectToPath?: string) => {
  const handleNavigation = (interval: NodeJS.Timeout) => {
    clearInterval(interval)
    try {
      const authToken = getDecodedCookie('_csrf_token')
      if (!authToken) {
        const error = createError('No authenticity token cookie found')
        reportRollbarError(error)
        throw error
      }
      const form = document.createElement('form')
      form.style.display = 'none'
      form.method = 'POST'
      form.action = decorateUrl(
        redirectToPath
          ? getUrlWithQueryParam(['redirect', redirectToPath], paths.authentication.signIn).toString()
          : paths.authentication.signIn
      )
      const input = document.createElement('input')
      input.name = 'authenticity_token'
      input.value = authToken
      form.append(input)
      document.body.append(form)
      form.submit()
    } catch (error) {
      console.error(error)
    }
  }
  // check every 50ms for pending requests
  const interval: NodeJS.Timeout = setInterval(() => !pendingRequestsCounter() && handleNavigation(interval), 50)
  // safeguard to 2sec-max check
  setTimeout(() => handleNavigation(interval), 2000)
}
