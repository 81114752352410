import { StyleWeight, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { useCallback, useState } from 'react'

import { Icons } from '../assets/icons'
import { Link } from '../Link'
import { Spacer } from '../Spacer'
import { Copy } from '../Typography'
import { IconWrapper, SNotification } from './index.styled'
import type { TNotification } from './types'

export const Notification: FC<TNotification> = ({
  children,
  focusable,
  title,
  collapseText,
  expandText,
  asAlert,
  ...props
}) => {
  const { type } = props
  const C = Icons[type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()]
  const [collapsed, setCollapsed] = useState(false)

  const onLinkClick = useCallback(() => setCollapsed(previous => !previous), [])

  return (
    <SNotification {...props} role={asAlert ? 'alert' : undefined} tabIndex={focusable ? 0 : undefined}>
      <IconWrapper>
        <C />
      </IconWrapper>
      <div>
        <Copy as="p" weight={StyleWeight.bold} color={TypographyColor.primary}>
          {title}
        </Copy>
        {!collapsed && <Copy as="div">{children}</Copy>}
        {expandText && collapseText && children && (
          <Copy as="div" weight={StyleWeight.bold}>
            {!collapsed && <Spacer top="medium" />}
            <Link as="a" onClick={onLinkClick} inline>
              {collapsed ? expandText : collapseText}
            </Link>
          </Copy>
        )}
      </div>
    </SNotification>
  )
}

export * from './types'
