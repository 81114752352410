import { forwardRef } from 'react'

import { DEFAULT_PROPS } from './constants'
import { SButton } from './index.styled'
import type { TButton } from './types'

export const Button = forwardRef<HTMLButtonElement, TButton>((props, ref) => (
  <SButton ref={ref} title={String(props.children)} {...props} />
))

Button.displayName = 'Button'
Button.defaultProps = DEFAULT_PROPS
