import type { TCobsQuestions } from '../../components/QuestionList/types'

export const EMPTY_COBS: TCobsQuestions = {
  medicalCondition: null,
  hadFinancialAdvice: null,
  hadImpartialAdvice: null,
  awareOfInflation: null,
  financialDependents: null,
  performedResearch: null,
  expectsRegularIncome: null,
  awareOfTaxation: null,
  awareOfFeesAndCharges: null,
  awareOfStateBenefits: null,
  awareOfInvestmentScamsProtection: null,
  awareOfDebtEffects: null,
}

export const PERFECT_COBS: TCobsQuestions = {
  medicalCondition: false,
  hadFinancialAdvice: true,
  hadImpartialAdvice: true,
  awareOfInflation: true,
  financialDependents: false,
  performedResearch: true,
  expectsRegularIncome: false,
  awareOfTaxation: true,
  awareOfFeesAndCharges: true,
  awareOfStateBenefits: true,
  awareOfInvestmentScamsProtection: true,
  awareOfDebtEffects: true,
}
