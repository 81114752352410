import { onPastePrevent } from '@retire/utils'
import { useCallback } from 'react'

import type { TUseBlockOnPaste } from './types'

export const useBlockOnPaste = ({ maxLength, preventCriteria, onPaste }: TUseBlockOnPaste) =>
  useCallback(
    e => {
      let isPrevented = false
      if (maxLength !== undefined) {
        isPrevented = e.target.value.concat(e.clipboardData.getData('Text')).length > maxLength && e.preventDefault()
      }
      if (preventCriteria !== undefined) {
        isPrevented = onPastePrevent(e, preventCriteria)
      }
      if (onPaste && !isPrevented) {
        onPaste(e)
      }
    },
    [maxLength, onPaste, preventCriteria]
  )
