import type { FC } from 'react'
import { createContext, useMemo, useState } from 'react'

import type { TPageOverlayContext } from './types'

export const PageOverlayContext = createContext<TPageOverlayContext>({
  isOverlay: false,
  activateOverlay: () => null,
})

export const PageOverlayProvider: FC = ({ children }) => {
  const [isOverlay, activateOverlay] = useState(false)
  const value = useMemo(
    () => ({
      isOverlay,
      activateOverlay,
    }),
    [isOverlay, activateOverlay]
  )

  return <PageOverlayContext.Provider value={value}>{children}</PageOverlayContext.Provider>
}

export const PageOverlayConsumer = PageOverlayContext.Consumer
