import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const ArrowDown: FC<TIconColors> = props => {
  const {
    colors: { neutral60 },
  } = useThemeContext()
  const { base = neutral60 } = props

  return (
    <svg x="0px" y="0px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="ArrowDown" />
      <path
        d="M15.1,7l-5.3,5.5c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L2.9,7C2.6,6.6,2.6,6,3,5.6c0.2-0.2,0.4-0.3,0.7-0.3h10.7
	c0.6,0,1,0.4,1,1C15.3,6.6,15.2,6.8,15.1,7z"
        fill={base}
      />
    </svg>
  )
}
