import { TaxFreeLumpSumWithdrawalState } from '@retire/constants/pots'
import { UserState } from '@retire/gql/__generated__/graphql'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { useUpdateUserState } from '@retire/hooks/useUpdateUserState'
import { paths } from '@retire/navigation/paths'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ILLUSTRATION_PLAN_MUTATION } from '../../gql/mutations'
import { ILLUSTRATION_PLAN_QUERY } from '../../gql/queries'
import type { TIllustrationPlanData } from '../../types'
import type { TUseIllustrationPlanSaveFinalDataResult } from './types'

export const useIllustrationPlanSaveFinalData = (): TUseIllustrationPlanSaveFinalDataResult => {
  const navigate = useNavigate()
  const { updateUserState } = useUpdateUserState({
    onCompleted: () => navigate(paths.illustration.yourIllustration),
  })

  const [illustrationPlanMutation, { loading: savingFinalIllustrationPlanData }] = useMutationWithError(
    ILLUSTRATION_PLAN_MUTATION,
    {
      refetchQueries: [{ query: ILLUSTRATION_PLAN_QUERY }],
      awaitRefetchQueries: true,
    }
  )

  const saveFinalIllustrationPlanData = useCallback(
    async (data: TIllustrationPlanData) => {
      await illustrationPlanMutation({
        variables: {
          taxFreeLumpSumSelected: data.taxFreeLumpSumWithdrawalState === TaxFreeLumpSumWithdrawalState.taken,
          flexibleIncomeAmount: data.flexibleIncomeAmount as number,
          regularIncomeStartAge: data.regularIncomeStartAge,
          regularIncomeEndAge: data.regularIncomeEndAge,
          laterLifeAmount: data.laterLifeAmount as number,
          laterLifeAge: data.laterLifeAge,
          inheritanceAmount: data.inheritanceAmount as number,
          rainyDayAmount: data.rainyDayAmount as number,
        },
        onCompleted: async () => {
          await updateUserState({ variables: { state: UserState.InitialIllustrationManagement } })
        },
      })
    },
    [illustrationPlanMutation, updateUserState]
  )

  return {
    saveFinalIllustrationPlanData,
    savingFinalIllustrationPlanData,
  }
}
