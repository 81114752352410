import { graphql } from '@retire/gql/__generated__/gql'

export const CANCEL_INSTRUCTION_MUTATION = graphql(`
  mutation cancelInstruction($id: ID!) {
    cancelInstruction(id: $id) {
      id
      state
    }
  }
`)
