import { PotName } from '@retire/constants/pots'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { toPennies } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { LaterLifePotConfigurationForm } from '../../../../components/Journey/IncomePots/LaterLifePotConfiguration/LaterLifePotConfigurationForm'
import type { TLaterLifePotConfigurationFormFields } from '../../../../components/Journey/IncomePots/LaterLifePotConfiguration/LaterLifePotConfigurationForm/types'
import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'
import { usePotInsufficientSavings } from '../../../../hooks/usePotInsufficientSavings'

export const LaterLifePotConfiguration: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.incomePots })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const {
    loadingIllustrationPlanData,
    illustrationPlanData,
    savePartialIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
  } = useIllustrationPlanData()

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      laterLifeAmount: null,
      laterLifeAge: null,
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  usePotInsufficientSavings({
    remainingSavings: illustrationPlanData.remainingSavings,
    potName: PotName.laterLife,
    insufficientSavingsPath: paths.illustration.journeyLaterLifePotInsufficientSavings,
    skip: loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData,
  })

  const methods = useForm<TLaterLifePotConfigurationFormFields>({ mode: 'onChange' })
  const {
    watch,
    formState: { isValid },
  } = methods
  const amount = watch('amount')
  const startAge = watch('startAge')

  const onNext = useCallback(
    async () =>
      await savePartialIllustrationPlanData(
        {
          ...illustrationPlanData,
          laterLifeAmount: toPennies(amount),
          laterLifeAge: startAge ? Number(startAge) : null,
        },
        paths.illustration.journeySavingsPotsIntro
      ),
    [amount, illustrationPlanData, savePartialIllustrationPlanData, startAge]
  )
  const onBack = useCallback(() => navigate(paths.illustration.journeyLaterLifePotIntro), [navigate])

  return (
    <FormProvider {...methods}>
      <JourneyStepLayout
        title={t('steps.potSteps.laterLifePot.title', `Choose how much to put in ${PotName.laterLife} pot`)}
        onNext={onNext}
        nextText={t('steps.potSteps.potConfiguration.actions.accept', 'Accept')}
        disableNext={!isValid}
        onBack={onBack}
        backText={t('steps.potSteps.potConfiguration.actions.cancel', 'Cancel')}
        loading={loadingIllustrationPlanData}
      >
        <LaterLifePotConfigurationForm methods={methods} />
      </JourneyStepLayout>
    </FormProvider>
  )
}
