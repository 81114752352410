import { Loading } from '@retire/components/Loading'
import { Segment } from '@retire/components/Segment'
import { DateFormat } from '@retire/constants/date'
import { AccountSummaryTemplate } from '@retire/features-shared/Account/templates/AccountSummary'
import { USER_BRIEF_DETAILS_QUERY } from '@retire/gql/user/queries'
import { usePlanDetails } from '@retire/hooks/usePlanDetails'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { formatDate } from '@retire/utils/date'
import { getPensionCommencementDetailsFromPlanData } from '@retire/utils/pension-commencement'
import { getAddressString } from '@retire/utils/strings'
import { getFormattedTelephone } from '@retire/utils/telephone'
import type { FC } from 'react'
import { useMemo } from 'react'

export const AccountManagement: FC = () => {
  const { t } = useTranslationWithOptions(['account', 'common'])
  const { data: userData, loading: userLoading } = useQueryWithError(USER_BRIEF_DETAILS_QUERY)
  const { data: planDetails, loading: planLoading } = usePlanDetails()
  const settings = useSettings()
  const pensionCommencementDetails = useMemo(
    () => planDetails && getPensionCommencementDetailsFromPlanData({ ...planDetails, ...settings }),
    [planDetails, settings]
  )

  if (userLoading || planLoading) {
    return <Loading />
  }
  if (userData && pensionCommencementDetails) {
    const {
      user: {
        title,
        forename,
        surname,
        bornOn,
        nationalInsuranceNumber: nino,
        email,
        telephone,
        telephoneCountryCode,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        postcode,
        policyNumber,
        gender,
        beneficiaries,
        ltaAbolished,
      },
    } = userData

    return (
      <PageTemplate
        title={t('accountManagement.pageTitle', 'Account title')}
        subTitle={t('accountManagement.subTitle', 'Account subtitle')}
        fullWidth
      >
        <Segment whiteBackground>
          <AccountSummaryTemplate
            personal={{
              showCustomerService: true,
              heading: t('common:account.personalDetails.title', 'Personal details'),
              details: {
                title,
                fullName: `${forename} ${surname}`,
                bornOn: formatDate(bornOn, DateFormat.date),
                gender,
                genderInfo: true,
                nino,
              },
            }}
            contact={{
              heading: t('common:account.contactDetails.title', 'Contact details'),
              details: {
                address: getAddressString({ addressLine1, addressLine2, addressLine3, city, postcode }),
                email,
                phoneNumber: getFormattedTelephone(telephone, telephoneCountryCode),
              },
              button: {
                path: paths.account.manageContactDetails,
                label: t('common:account.contactDetails.action', 'Edit contact details'),
              },
            }}
            policy={{
              details: {
                policyNumber,
              },
            }}
            payment={{
              heading: t('common:account.paymentDetails.title', 'Payment details'),
              showCustomerService: true,
            }}
            security
            beneficiaries={{
              heading: t('common:account.beneficiaries.title', 'Beneficiaries'),
              subTitle: t('accountManagement.sections.beneficiaries.caption', 'Beneficiaries caption'),
              twister: {
                title: t('accountManagement.sections.beneficiaries.learnMore.title', 'Learn more title'),
                children: t('accountManagement.sections.beneficiaries.learnMore.details', 'Learn more details'),
              },
              list: beneficiaries,
              button: {
                path: paths.account.manageBeneficiaries,
                label: t('common:account.beneficiaries.actions.edit.label', 'Edit beneficiaries'),
              },
            }}
            funds={{ details: pensionCommencementDetails, ltaAbolished }}
          />
        </Segment>
      </PageTemplate>
    )
  }
  return null
}
