import { useTranslationWithOptions } from '../useTranslationWithOptions'

export const useGreetingLabel = () => {
  const { t } = useTranslationWithOptions('common')

  const currentHour = new Date().getHours()
  let key = 'greeting.goodEvening'

  if (currentHour >= 5 && currentHour < 12) {
    key = 'greeting.goodMorning'
  } else if (currentHour >= 12 && currentHour < 17) {
    key = 'greeting.goodAfternoon'
  }

  return t(key, key)
}
