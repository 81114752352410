import { Button } from '@retire/components/Button'
import { Link } from '@retire/components/Link'
import { Notification } from '@retire/components/Notification'
import { Segment } from '@retire/components/Segment'
import { Spacer } from '@retire/components/Spacer'
import { SuspenseLoader } from '@retire/components/SuspenseLoader'
import { ButtonStyleType } from '@retire/constants/button'
import { PotName } from '@retire/constants/pots'
import { UserState } from '@retire/gql/__generated__/graphql'
import { useFundDetails } from '@retire/hooks/useFundDetails'
import { usePlanDetails } from '@retire/hooks/usePlanDetails'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUpdateUserState } from '@retire/hooks/useUpdateUserState'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { getPensionCommencementDetailsFromPlanData } from '@retire/utils/pension-commencement'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@smartpension/bright__experimental'
import type { FC } from 'react'
import { Fragment, useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'

import {
  About,
  AccordionHeadingTwo,
  FlexibleIncomePot,
  FurtherInformation,
  InheritancePot,
  KeepingTrack,
  LaterLifePot,
  Lead,
  Leaving,
  LumpSumAndDeathBenefitAllowance,
  Planning,
  RainyDayPot,
  SpacedHR,
  SubSection,
  Summary,
  Tax,
  WhenYouDie,
  YourDetails,
  YourInvestments,
} from '../../components/YourPlanInFull'
import { PlanProvider } from '../../context/PlanContext'
import type { TPlanContext } from '../../context/PlanContext/types'

export const YourPlanInFull: FC = () => {
  const { t } = useTranslationWithOptions('plan')
  const { updateUserState, loading: loadingStateChange } = useUpdateUserState({
    errorTitle: t('common:errors.general', 'Loading error'),
  })
  const { data: planDetails, loading: loadingPlan } = usePlanDetails({ fetchPolicy: 'network-only' })
  const settings = useSettings()
  const { fundDetails: flexibleIncomeFundDetails, loading: flexibleIncomeFundDetailsLoading } = useFundDetails(
    PotName.flexibleIncome
  )
  const { fundDetails: laterLifeFundDetails, loading: laterLifeFundDetailsLoading } = useFundDetails(PotName.laterLife)
  const { fundDetails: inheritanceFundDetails, loading: inheritanceFundDetailsLoading } = useFundDetails(
    PotName.inheritance
  )
  const { fundDetails: rainyDayFundDetails, loading: rainyDayFundDetailsLoading } = useFundDetails(PotName.rainyDay)
  const fullPlanDetails = useMemo(
    () => ({
      ...planDetails,
      funds: planDetails ? getPensionCommencementDetailsFromPlanData({ ...planDetails, ...settings }) : {},
    }),
    [planDetails, settings]
  )

  const onBack = useCallback(
    () => updateUserState({ variables: { state: UserState.InitialIllustrationManagement } }),
    [updateUserState]
  )

  const onNext = useCallback(
    () => updateUserState({ variables: { state: UserState.InitialEnrolment } }),
    [updateUserState]
  )

  const components = useMemo(
    () => ({
      mapsLink: <Link as="a" href={t('common:externalLinks.maps', 'MaPS link')} key="maps-link" target="_blank" />,
      pensionWiseLink: (
        <Link
          as="a"
          href={t('common:externalLinks.pensionWise.gettingHelp', 'Pension Wise link')}
          key="pensionwise-link"
          target="_blank"
        />
      ),
    }),
    [t]
  )

  const sections = [
    { title: t('details.heading', 'Your details heading'), component: <YourDetails /> },
    { title: t('summary.heading', 'Summary heading'), component: <Summary /> },
    {
      title: t('lumpSumAndDeathBenefitAllowance.heading', 'Lump sum and Death benefit allowance heading'),
      component: <LumpSumAndDeathBenefitAllowance />,
    },
    {
      title: t('flexibleIncomePot.heading', 'Flexible income pot heading'),
      component: <FlexibleIncomePot fundDetails={flexibleIncomeFundDetails} />,
    },
    {
      title: t('laterLifePot.heading', 'Later life pot heading'),
      component: <LaterLifePot fundDetails={laterLifeFundDetails} />,
    },
    {
      title: t('inheritancePot.heading', 'Inheritance pot heading'),
      component: <InheritancePot fundDetails={inheritanceFundDetails} />,
    },
    {
      title: t('rainyDayPot.heading', 'Rainy day pot heading'),
      component: <RainyDayPot fundDetails={rainyDayFundDetails} />,
    },
    { title: t('furtherInformation.heading', 'Further Information heading'), component: <FurtherInformation /> },
    { title: t('tax.heading', 'Tax heading'), component: <Tax /> },
    { title: t('leaving.heading', 'Leaving heading'), component: <Leaving /> },
    {
      title: t('yourInvestments.heading', 'Your investments heading'),
      component: <YourInvestments fundDetails={flexibleIncomeFundDetails} />,
    },
    {
      title: t('keepingTrack.heading', 'Keeping track heading'),
      component: <KeepingTrack fundDetails={flexibleIncomeFundDetails} />,
    },
    { title: t('planning.heading', 'Planning for later life heading'), component: <Planning /> },
    { title: t('whenYouDie.heading', 'When you die heading'), component: <WhenYouDie /> },
    { title: t('about.heading', 'About heading'), component: <About /> },
  ]

  return (
    <SuspenseLoader
      isLoading={
        loadingPlan ||
        loadingStateChange ||
        !fullPlanDetails ||
        flexibleIncomeFundDetailsLoading ||
        laterLifeFundDetailsLoading ||
        inheritanceFundDetailsLoading ||
        rainyDayFundDetailsLoading
      }
    >
      <PlanProvider value={fullPlanDetails as TPlanContext}>
        <PageTemplate
          title={t('yourplaninfull.title', 'Full plan title')}
          subTitle={t('yourplaninfull.description', 'Description')}
          onBack={onBack}
          backText={t('actions.back', 'Back')}
          onNext={onNext}
          nextText={t('actions.startEnrolment', 'Start enrolment')}
          fullWidth
        >
          <Notification
            title={
              <Trans components={components}>
                {t(
                  'yourplaninfull.notice',
                  'Notice <mapsLink>MaPS link</mapsLink> <pensionWiseLink>pensionWise link</pensionWiseLink>'
                )}
              </Trans>
            }
            type="info"
            hasBorder
          />
          <Spacer bottom="large" />
          <Segment whiteBackground>
            <Lead refNumber={fullPlanDetails?.plan?.referenceNumber || ''} />
            <SubSection>
              <Button buttonStyleType={ButtonStyleType.secondary} onClick={window.print} lockWidth>
                {t('actions.printButton', 'Print button')}
              </Button>
            </SubSection>
            <Accordion defaultIndex={[0, 1, 2]}>
              {sections.map(({ title, component }, index) => {
                return (
                  <Fragment key={title}>
                    {sections.length > index && <SpacedHR />}
                    <AccordionItem key={title}>
                      <AccordionButton aria-expanded={true}>
                        <AccordionHeadingTwo>{`${index + 1}. ${title}`}</AccordionHeadingTwo>
                      </AccordionButton>
                      <AccordionPanel>{component}</AccordionPanel>
                    </AccordionItem>
                  </Fragment>
                )
              })}
            </Accordion>
          </Segment>
        </PageTemplate>
      </PlanProvider>
    </SuspenseLoader>
  )
}
