import { Item } from '@react-stately/collections'
import type { FC } from 'react'

import type { TSearchResults } from '../types'
import { ListBox } from './ListBox'

export const Select: FC<TSearchResults> = ({ searchResults, onResultClick }) => (
  <ListBox aria-label="address-selector" searchResults={searchResults} onResultClick={onResultClick}>
    {searchResults.map(({ id, label }) => (
      <Item key={id}>{label}</Item>
    ))}
  </ListBox>
)
