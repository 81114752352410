import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

export const PotIn: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { accent, base = primary } = props

  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2581 1.61386C16.257 0.722545 15.5528 0.00105619 14.6829 -5.3958e-05L11.0083 -5.42793e-05C10.1394 0.00105572 9.43525 0.723655 9.43417 1.61386L9.43417 10.7468C9.43417 10.9 9.31284 11.0243 9.16334 11.0243L6.02167 11.0243C5.34242 10.9699 4.74984 11.4905 4.69675 12.1865C4.66642 12.5894 4.82567 12.9823 5.12575 13.2443L11.7319 20.0096C12.3548 20.619 13.3363 20.619 13.9593 20.0096L20.5654 13.2387C21.0843 12.7881 21.1472 11.9911 20.7073 11.4594C20.4506 11.1509 20.0649 10.9866 19.6695 11.0188L16.5278 11.0188C16.3783 11.0199 16.2581 10.8967 16.257 10.7435C16.257 10.7424 16.257 10.7424 16.257 10.7413L16.2581 1.61386Z"
        fill={accent || base}
      />
      <path
        d="M0 21.5212C0.00216667 23.9942 1.95758 25.9978 4.37125 26H21.6287C24.0424 25.9978 25.9978 23.9942 26 21.5212V19.4378C25.9794 18.6874 25.3684 18.0958 24.635 18.118C23.9319 18.1391 23.3664 18.7174 23.3469 19.4378V21.5179C23.3458 22.4891 22.5767 23.2772 21.6287 23.2783H4.37125C3.42333 23.2772 2.65417 22.4891 2.65308 21.5179V19.4378C2.6325 18.6874 2.0215 18.0958 1.28917 18.1169C0.585 18.138 0.0205833 18.7174 0 19.4378V21.5212Z"
        fill={base}
      />
    </svg>
  )
}
