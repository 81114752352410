import { InstructionType } from '@retire/gql/__generated__/graphql'

export const EMPTY_DETAILS = {
  data: {
    sell: undefined,
    buy: undefined,
  },
}

export const INSTRUCTION_TYPES = [
  InstructionType.Adjustment,
  InstructionType.RegularPayment,
  InstructionType.Withdrawal,
  InstructionType.TaxFreeCashPay,
  InstructionType.MoveInUnit,
]
