import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import { Paragraph, Section, SubSection } from '../Common'
import type { TKeepingTrack } from './types'

export const KeepingTrack: FC<TKeepingTrack> = ({ fundDetails: { name } }) => {
  const { t } = useTranslationWithOptions('plan')

  return (
    <Section>
      <SubSection>
        <Paragraph>{t('keepingTrack.description.paragraphOne', 'Keeping Track - Income to last.')}</Paragraph>
        <Paragraph>
          {t(
            'keepingTrack.description.paragraphTwo',
            'Estimate is based on monthly income and performance of {{fundName}}.',
            {
              fundName: name,
            }
          )}
        </Paragraph>
      </SubSection>
    </Section>
  )
}
