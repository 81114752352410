import { ButtonStyleType } from '@retire/constants/button'
import { useTranslationWithOptions } from '@retire/hooks'
import { isRequired } from '@retire/utils'
import type { ChangeEvent, FC } from 'react'
import { useCallback } from 'react'

import { Button } from '../../../../Button'
import { Spacer } from '../../../../Spacer'
import { FormTextInput } from '../../../TextInput'
import type { TSearchInput } from './types'

export const SearchInput: FC<TSearchInput> = ({
  requiredFields,
  onSearch,
  onSearchChange,
  searchValue,
  triggerSearch,
  ...props
}) => {
  const { t } = useTranslationWithOptions('common')
  const onChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onSearchChange(value),
    [onSearchChange]
  )

  const onSearchWithValidate = useCallback(() => {
    triggerSearch(searchValue)
    onSearch && onSearch()
  }, [onSearch, searchValue, triggerSearch])

  const required = !!requiredFields?.length

  return (
    <FormTextInput
      {...props}
      inputWidth="150px"
      onChange={onChange}
      rules={{
        validate: required
          ? {
              required: isRequired(t('form.contactDetails.address.postcode.validation', 'search input is not valid')),
            }
          : undefined,
      }}
      addOnRight={
        <Spacer left="small">
          <Button buttonStyleType={ButtonStyleType.primary} onClick={onSearchWithValidate} lockWidth>
            {t('form.contactDetails.address.buttons.findAddress', 'Find address')}
          </Button>
        </Spacer>
      }
      autocomplete="postal-code"
      required={required}
    />
  )
}
