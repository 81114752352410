import { LabelValue } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { toCurrency } from '@retire/utils'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { usePlanContext } from '../../../hooks'
import { Bold, Section, SubSection } from '../Common'

export const Summary: FC = () => {
  const { t } = useTranslationWithOptions('plan')

  const {
    plan: {
      valuationAmount,
      taxFreeLumpSum,
      flexibleIncomeAmount,
      flexibleIncomeMonthlyAmount,
      regularIncomeStartAge,
      regularIncomeEndAge,
      laterLifeAmount,
      laterLifeMonthlyAmount,
      laterLifeAge,
      inheritanceAmount,
      rainyDayAmount,
    },
  } = usePlanContext()

  const transferredAmount = valuationAmount - (taxFreeLumpSum || 0)

  return (
    <Section>
      <SubSection>
        <LabelValue
          label={t('summary.totalPensionPot.title', 'Summary total pension pot title')}
          value={toCurrency(valuationAmount)}
        />

        <LabelValue
          label={t('summary.taxFreeLumpSum.title', 'Summary TFLS title')}
          value={toCurrency(taxFreeLumpSum)}
          description={
            taxFreeLumpSum
              ? t('summary.taxFreeLumpSum.chosen', 'Summary TFLS chosen')
              : t('summary.taxFreeLumpSum.notChosen', 'Summary TFLS not chosen')
          }
        />

        <LabelValue
          label={t('summary.transferred.title', 'Summary transferred title')}
          value={toCurrency(transferredAmount)}
          description={t('summary.transferred.description', 'Summary transferred description')}
        />

        <LabelValue
          label={t('summary.flexibleIncomePot.title', 'Summary flexible income pot title')}
          value={toCurrency(flexibleIncomeAmount)}
          description={
            <Trans components={{ bold: <Bold /> }}>
              {t(
                `summary.flexibleIncomePot.description.${
                  flexibleIncomeMonthlyAmount ? 'withMonthlyAmount' : 'withoutMonthlyAmount'
                }`,
                'Summary flexible income pot: {{projectedMonthlyIncome}} from {{regularIncomeStartAge}} to {{regularIncomeEndAge}}',
                {
                  projectedMonthlyIncome: toCurrency(flexibleIncomeMonthlyAmount, 0),
                  regularIncomeStartAge,
                  regularIncomeEndAge,
                }
              )}
            </Trans>
          }
        />

        <LabelValue
          label={t('summary.laterLifePot.title', 'Summary later life pot title')}
          value={toCurrency(laterLifeAmount)}
          description={
            <Trans components={{ bold: <Bold /> }}>
              {t(
                `summary.laterLifePot.description.${
                  laterLifeMonthlyAmount ? 'withMonthlyAmount' : 'withoutMonthlyAmount'
                }`,
                'Summary later life pot: {{projectedMonthlyIncome}} from {{laterLifeAge}}',
                {
                  projectedMonthlyIncome: toCurrency(laterLifeMonthlyAmount, 0),
                  laterLifeAge,
                }
              )}
            </Trans>
          }
        />

        <LabelValue
          label={t('summary.inheritancePot.title', 'Summary inheritance pot title')}
          value={toCurrency(inheritanceAmount)}
        />
        <LabelValue
          label={t('summary.rainyDayPot.title', 'Summary inheritance pot description')}
          value={toCurrency(rainyDayAmount)}
        />
      </SubSection>
    </Section>
  )
}
