import { Link } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import type { TToggleIncomeLink } from './types'

export const ToggleIncomeLink: FC<TToggleIncomeLink> = ({ regularIncomePaused }) => {
  const navigate = useNavigate()
  const { t } = useTranslationWithOptions('dashboard')

  const pauseIncome = useCallback(() => {
    navigate(paths.dashboard.pauseFlexibleIncomePot)
  }, [navigate])

  const restartIncome = useCallback(() => {
    navigate(paths.dashboard.restartFlexibleIncomePot)
  }, [navigate])

  return regularIncomePaused ? (
    <Link onClick={restartIncome} as="button">
      {t('pots.common.monthlyIncomeCard.actions.restartIncome', 'Restart income')}
    </Link>
  ) : (
    <Link onClick={pauseIncome} as="button">
      {t('pots.common.monthlyIncomeCard.actions.pauseIncome', 'Pause income')}
    </Link>
  )
}
