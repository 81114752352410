import {
  ErrorCode,
  MAX_BENEFICIARY_SHARE_VALUE,
  MIN_BENEFICIARY_SHARE_VALUE,
  TOTAL_BENEFICIARIES_SHARE_VALUES,
} from '@retire/constants'
import type { Beneficiary } from '@retire/gql/__generated__/graphql'
import { i18n } from '@retire/i18n'
import { clamp, createError, EMPTY_DATE_VALUE, generateDefaultTranslationString } from '@retire/utils'

import type { TBeneficiaryFormErrors } from './types'

export const getEmptyBeneficiary = (beneficiaries: Beneficiary[]): Beneficiary => {
  const remainingPercentage = clamp(
    TOTAL_BENEFICIARIES_SHARE_VALUES -
      beneficiaries.reduce((prev, cur) => {
        return prev + cur.percentage
      }, 0),
    0,
    TOTAL_BENEFICIARIES_SHARE_VALUES
  )
  return { name: '', bornOn: '', percentage: remainingPercentage }
}

export const mergeBeneficiariesFormData = (formData: Beneficiary[], updatedFormData: Beneficiary[]): Beneficiary[] => {
  const addedFormData = updatedFormData.slice(formData.length)
  const newFormData = new Array<Beneficiary>()
  formData.forEach((beneficiary, index) => {
    newFormData[index] =
      updatedFormData[index] && typeof beneficiary === 'object'
        ? { ...beneficiary, ...updatedFormData[index] }
        : beneficiary
  })
  return [...newFormData, ...addedFormData]
}

export const removeEmptyValuesFromBeneficiaries = (beneficiaries: Beneficiary[]): Beneficiary[] =>
  [...beneficiaries].map(beneficiary => {
    const cleanedBeneficiary = { ...beneficiary }
    Object.keys(cleanedBeneficiary).forEach(key => {
      if (['', null, undefined, key === 'bornOn' ? EMPTY_DATE_VALUE : undefined].includes(cleanedBeneficiary[key])) {
        delete cleanedBeneficiary[key]
      }
    })
    return cleanedBeneficiary
  })

export const checkBeneficiariesShares = (beneficiaries: Beneficiary[]) => {
  if (!beneficiaries.length) {
    return
  }
  if (
    beneficiaries.reduce((sum, current) => sum + Number(current.percentage), 0) !== TOTAL_BENEFICIARIES_SHARE_VALUES
  ) {
    throw createError(
      i18n.t(
        'common:account.beneficiaries.form.percentage.validations.total',
        generateDefaultTranslationString('Total of shares must be {{total}}'),
        { total: TOTAL_BENEFICIARIES_SHARE_VALUES }
      ),
      ErrorCode.form
    )
  }
  const formErrors: Array<TBeneficiaryFormErrors> = []
  beneficiaries.forEach(({ percentage }, index) => {
    if (percentage < MIN_BENEFICIARY_SHARE_VALUE || percentage > MAX_BENEFICIARY_SHARE_VALUE) {
      formErrors[index] = {
        percentage: i18n.t(
          'common:account.beneficiaries.form.percentage.validations.range',
          generateDefaultTranslationString('Share must be between {{min}} and {{max}}%'),
          {
            min: MIN_BENEFICIARY_SHARE_VALUE,
            max: MAX_BENEFICIARY_SHARE_VALUE,
          }
        ),
      }
    }
  })
  if (formErrors.length) {
    throw { formErrors }
  }
}
