import { NavButtons } from '@retire/components/NavButtons/presentation'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { HowItWorksCard } from '../../components/MoveMoneyBetweenPotsHowItWorks/HowItWorksCard/presentation'

export const MoveMoneyBetweenPotsHowItWorks = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()

  const onContinueClick = useCallback(() => {
    navigate(paths.dashboard.moveMoneyBetweenPots)
  }, [navigate])

  const onQuitClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <PageTemplate fullWidth onQuit={onQuitClick}>
      <HowItWorksCard />
      <NavButtons onNext={onContinueClick} nextText={t('pots.common.moveMoneyBetweenPots.continue', 'Continue')} />{' '}
    </PageTemplate>
  )
}
