import { InstructionType } from '@retire/gql/__generated__/graphql'

import type { TTransactions } from './types'

export const DISPLAYED_INSTRUCTION_TYPES: InstructionType[] = [
  InstructionType.Adjustment,
  InstructionType.RegularPayment,
  InstructionType.Withdrawal,
  InstructionType.MoveMoney,
  InstructionType.Buy,
  InstructionType.TaxFreeCashPay,
  InstructionType.MoveInUnit,
]

export const DEFAULT_TRANSACTIONS: TTransactions = {
  inProgressTransactions: [],
  completedTransactions: [],
}
