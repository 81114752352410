import { graphql } from '@retire/gql/__generated__/gql'

export const INSTRUCTIONS_QUERY = graphql(`
  query getInstructions($instructionTypes: [InstructionType!]!, $states: [InstructionPublicState!]) {
    instructions(instructionTypes: $instructionTypes, states: $states) {
      id
      instructionType
      state
      buyPotType
      sellPotType
      amount
      settledAmount
      sellFull
      createdAt
    }
  }
`)

export const HOLDINGS_QUERY = graphql(`
  query getHoldings {
    holdings {
      flexibleIncomeAmount
      laterLifeAmount
      rainyDayAmount
      inheritanceAmount
    }
    potLocks {
      flexibleIncome {
        lockedForBuys
        lockedForSells
      }
      laterLife {
        lockedForBuys
        lockedForSells
      }
      rainyDay {
        lockedForBuys
        lockedForSells
      }
      inheritance {
        lockedForBuys
        lockedForSells
      }
    }
  }
`)

export const INSTRUCTION_DETAILS_QUERY = graphql(`
  query getInstructionDetails($instructionIds: [ID!]!) {
    instructionDetails(instructionIds: $instructionIds) {
      id
      investments {
        type
        fundName
        fundUnit
        fundPrice
      }
    }
  }
`)
