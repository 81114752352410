import { Link } from '@retire/components/Link'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { INIT_QUERY, USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { CopyLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { USER_EMAIL_MUTATION } from '../EmailChanging/gql/mutations'

export const EmailChanged: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.email })
  const { t } = useTranslationWithOptions('enrolment')
  const { data, loading: userDataLoading } = useQueryWithError(INIT_QUERY)
  const navigate = useNavigate()
  const [resendEmail, { loading }] = useMutationWithError(USER_EMAIL_MUTATION, {
    errorTitle: t('emailChanged.errors.general', 'Sending email error'),
    refetchQueries: [{ query: USER_FULL_DETAILS_QUERY }, { query: INIT_QUERY }],
  })

  const email = data?.user.unconfirmedEmail
  const onResendClick = useCallback(() => resendEmail({ variables: { email: email || '' } }), [email, resendEmail])
  const onReenterClick = useCallback(() => navigate(paths.enrolment.emailChanging), [navigate])

  return (
    <PageTemplate title={t('emailChanged.title', 'Email changed')} loading={loading || userDataLoading}>
      <Spacer bottom="medium">
        <Copy as="p" level={CopyLevel.body1}>
          <Trans components={[<Link inline as="a" key="link-1" noDecoration />]}>
            {t('emailChanged.description', 'Description', { email })}
          </Trans>
        </Copy>
      </Spacer>
      <Spacer bottom="medium">
        <Copy as="p">
          <Link as="a" onClick={onResendClick}>
            {t('emailChanged.links.resend', 'Resend email')}
          </Link>
        </Copy>
      </Spacer>
      <Copy as="p">
        <Link as="a" onClick={onReenterClick}>
          {t('emailChanged.links.reenter', 'Enter email again')}
        </Link>
      </Copy>
    </PageTemplate>
  )
}
