import { Key } from '@retire/constants'

import type { TNextAction, TOnResultClick } from '../../../types'
import type { TLIKeyboardCallback } from './types'

export const getSubmitOnKeyPress =
  (id: string, next: TNextAction, onResultClick: TOnResultClick): TLIKeyboardCallback =>
  e => {
    if (e.key === Key.Enter) {
      onResultClick(id, next)
    }
  }
