import { AuthOutlet } from '@retire/navigation/AuthOutlet'
import { paths, roots } from '@retire/navigation/paths'
import type { RouteObject } from 'react-router-dom'

import {
  AwaitingApproval,
  BankOrBuildingSociety,
  CheckAndConfirm,
  COBS,
  Declaration,
  EmailChanged,
  EmailChanging,
  EmailCheck,
  EmailResending,
  EmailResent,
  Introduction,
  MoneyLeftOver,
  PersonalDetails,
  ThankYou,
  WelcomeBack,
} from './pages'
import { LSAOrLSDBAQuestion } from './pages/LSAOrLSDBAQuestion'

export const enrolmentRoutes: RouteObject[] = [
  {
    path: roots.enrolment,
    element: <AuthOutlet />,
    children: [
      { path: paths.enrolment.introduction, element: <Introduction /> },
      { path: paths.enrolment.welcomeBack, element: <WelcomeBack /> },
      { path: paths.enrolment.emailCheck, element: <EmailCheck /> },
      { path: paths.enrolment.emailChanging, element: <EmailChanging /> },
      { path: paths.enrolment.emailChanged, element: <EmailChanged /> },
      { path: paths.enrolment.emailResending, element: <EmailResending /> },
      { path: paths.enrolment.emailResent, element: <EmailResent /> },
      { path: paths.enrolment.personalDetails, element: <PersonalDetails /> },
      { path: paths.enrolment.bankOrBuildingSociety, element: <BankOrBuildingSociety /> },
      { path: paths.enrolment.moneyLeftOver, element: <MoneyLeftOver /> },
      { path: paths.enrolment.checkAndConfirm, element: <CheckAndConfirm /> },
      { path: paths.enrolment.lsaOrLsdQuestion, element: <LSAOrLSDBAQuestion /> },
      { path: paths.enrolment.cobs, element: <COBS /> },
      { path: paths.enrolment.declaration, element: <Declaration /> },
      { path: paths.enrolment.thankYou, element: <ThankYou /> },
      { path: paths.enrolment.awaitingApproval, element: <AwaitingApproval /> },
    ],
  },
]
