import { PotName } from '@retire/constants'
import { useTranslationWithOptions, useUserDetails } from '@retire/hooks'
import { CopyLevel, HeadlineLevel, TypographyColor } from '@retire/theme'
import type { FC } from 'react'

import { Spacer } from '../../../Spacer'
import { Copy } from '../../../Typography'
import { PotValue } from '../../VerticalPotCard'
import { SPotValueWrapper } from '../presentation.styled'
import type { TPotValueSection } from './types'

export const PotValueSection: FC<TPotValueSection> = ({
  title,
  value,
  subValue = null,
  ageSection,
  variant: potName,
}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const {
    data: { regularIncomePaused: isRegularIncomePaused },
  } = useUserDetails()

  const isPaused = potName === PotName.flexibleIncome && isRegularIncomePaused

  return (
    <div>
      <Spacer bottom="large" />
      <Copy as="span" color={TypographyColor.primary} level={CopyLevel.caption}>
        {title}
      </Copy>
      <SPotValueWrapper>
        <PotValue level={HeadlineLevel.h2} as="h4">
          {isPaused && ageSection ? t('pots.common.paused', 'Paused') : value}
        </PotValue>
        {!isPaused && ageSection}
      </SPotValueWrapper>
      {subValue}
    </div>
  )
}
