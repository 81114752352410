import { UnorderedList } from '@retire/components/List'

import { useTranslationWithOptions } from '../useTranslationWithOptions'
import type { TUseRiskLevelModalParamsReturn } from './types'

export const useRiskLevelModalParams = (): TUseRiskLevelModalParamsReturn => {
  const { t } = useTranslationWithOptions('common')

  return {
    title: t('riskRating.modalContent.title', 'How we define the risk level title'),
    content: t('riskRating.modalContent.content', 'How we define the risk level content'),
    components: {
      bold: <strong />,
      list: <UnorderedList />,
      listItem: <li />,
    },
  }
}
