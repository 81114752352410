import type { MutationHookOptions } from '@apollo/client'
import type { UpdateUserStateMutation, UpdateUserStateMutationVariables } from '@retire/gql/__generated__/graphql'
import { USER_STATE_MUTATION } from '@retire/gql/user/mutations'
import { INIT_QUERY, USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'
import { getStateDefaultPath } from '@retire/navigation/utils'
import type { TErrorTitle } from '@retire/types'
import { useNavigate } from 'react-router-dom'

import { useMutationWithError } from '../useMutationWithError'
import type { TUseUpdateUserStateResult } from './types'

export const useUpdateUserState = (
  options?: MutationHookOptions<UpdateUserStateMutation, UpdateUserStateMutationVariables> & TErrorTitle
): TUseUpdateUserStateResult => {
  const navigate = useNavigate()

  const [updateUserState, { loading }] = useMutationWithError(USER_STATE_MUTATION, {
    refetchQueries: [{ query: USER_FULL_DETAILS_QUERY }, { query: INIT_QUERY }],
    onCompleted: data => data.userUpdate?.state && navigate(getStateDefaultPath(data.userUpdate.state)),
    ...options,
  })
  return { updateUserState, loading }
}
