import { Spacer } from '@retire/components/Spacer'
import { PotName } from '@retire/constants/pots'
import type { FC } from 'react'

import { isInheritancePotConfigured, isRainyDayPotConfigured } from '../../../utils'
import { JourneyOverviewAmount } from '../Amount'
import { JourneyOverviewDetails } from '../Details'
import { PotDetails } from '../PotDetails'
import type { TSavingsPotsStepDetails } from './types'

export const SavingsPotsStepDetails: FC<TSavingsPotsStepDetails> = ({
  inheritanceAmount,
  rainyDayAmount,
  isSavingsPotsStepCompleted,
  isSavingsPotsStepInProgress,
}) => {
  if (!isSavingsPotsStepInProgress && !isSavingsPotsStepCompleted) {
    return (
      <JourneyOverviewDetails>
        <JourneyOverviewAmount amount={0} />
      </JourneyOverviewDetails>
    )
  } else if (!isInheritancePotConfigured) {
    return null
  }

  return (
    <>
      {isInheritancePotConfigured({ inheritanceAmount }) && (
        <Spacer key={PotName.inheritance} top="none">
          <PotDetails potName={PotName.inheritance} />
        </Spacer>
      )}
      {isRainyDayPotConfigured({ rainyDayAmount }) && (
        <Spacer key={PotName.rainyDay} top="medium">
          <PotDetails potName={PotName.rainyDay} />
        </Spacer>
      )}
    </>
  )
}
