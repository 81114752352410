import { StyleWeight, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

import { Spacer } from '../Spacer'
import { Twister } from '../Twister'
import { Copy } from '../Typography/Copy'
import type { TDataRow } from './types'

export const DataRow: FC<TDataRow> = ({ name, value, twister, valueDescription, valueFallback = '-' }) => (
  <Spacer top="medium" bottom="medium">
    <Row>
      <Col xs={12} md={5}>
        <Copy as="span" weight={StyleWeight.semiBold} color={TypographyColor.neutral}>
          {name}
        </Copy>
      </Col>
      <Col xs={12} md={7}>
        <Copy as="div" weight={StyleWeight.semiBold} color={TypographyColor.primary}>
          {value || valueFallback}
        </Copy>
        {valueDescription && (
          <Spacer bottom="small">
            <Copy as="div">{valueDescription}</Copy>
          </Spacer>
        )}
      </Col>
    </Row>
    {twister && (
      <Spacer top="small">
        <Twister {...twister} />
      </Spacer>
    )}
  </Spacer>
)
