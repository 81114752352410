import type { TSettings } from '@retire/contexts/coreData/types'
import type { TPlanQueryResponse } from '@retire/gql/plan/types'
import type { TPensionCommencementDetails } from '@retire/types/pension-commencement'

export const getPensionCommencementDetailsFromPlanData = ({
  plan: { taxFreeLumpSum, valuationAmount },
  lifetimeAllowance,
}: TPlanQueryResponse & TSettings): TPensionCommencementDetails => {
  const taxFreeLumpSumAmount = taxFreeLumpSum || 0
  const lifetimeAllowanceAmount = lifetimeAllowance?.amount

  return {
    fundsAmountAtStart: valuationAmount - taxFreeLumpSumAmount,
    lifetimeAllowancePercentAtStart: (valuationAmount - taxFreeLumpSumAmount) / lifetimeAllowanceAmount,
    taxFreeLumpSumAmount,
    lifetimeAllowancePercent: taxFreeLumpSumAmount / lifetimeAllowanceAmount,
    lumpSumAllowanceAmountAtStart: 0,
    lumpSumAllowanceAmount: taxFreeLumpSumAmount,
  }
}
