import { NavButtons } from '@retire/components/NavButtons'
import { Spacer } from '@retire/components/Spacer'
import { Headline } from '@retire/components/Typography'
import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'

import { ContentWrapper, HeaderWrapper, Wrapper } from './presentation.styled'
import type { TConfirmModal } from './types'

export const ConfirmModal: FC<TConfirmModal> = ({
  ariaLabelledBy,
  ariaDescribedBy,
  children,
  onClose,
  title,
  confirmText,
  closeText,
  onConfirm,
  keyHandler,
  buttonRef,
}) => {
  return (
    <Wrapper onKeyDown={keyHandler}>
      <HeaderWrapper>
        <Spacer bottom="medium">
          <Headline level={HeadlineLevel.h3} as="h1" id={ariaLabelledBy}>
            {title}
          </Headline>
        </Spacer>
      </HeaderWrapper>
      <ContentWrapper id={ariaDescribedBy}>{children}</ContentWrapper>
      <NavButtons
        onBack={onClose}
        backText={closeText}
        onNext={onConfirm}
        nextText={confirmText}
        nextButtonRef={buttonRef}
      />
    </Wrapper>
  )
}
