import { ErrorCode } from '@retire/constants/errors'
import { useCallback, useState } from 'react'

import { useGlobalErrorContext } from '../useGlobalErrorContext'
import type { TUsePromiseWithError } from './types'

export const usePromiseWithError: TUsePromiseWithError = (errorMessage, callback) => {
  const { setGlobalError } = useGlobalErrorContext()
  const [loading, setLoading] = useState(false)

  const promise = useCallback(async () => {
    try {
      setLoading(true)
      callback && (await callback())
    } catch {
      setGlobalError({ message: errorMessage, code: ErrorCode.internal })
    }
    setLoading(false)
  }, [callback, errorMessage, setGlobalError])

  return {
    loading,
    promise,
  }
}
