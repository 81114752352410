import type { PotName } from '@retire/constants'
import type { Instruction, InstructionType } from '@retire/gql/__generated__/graphql'

import { getPotNameFromInstructionPot } from '../gql'

export const getTransactionsTotalAmountForPot = (
  transactions: Instruction[],
  potName: PotName,
  instructionType?: InstructionType
) => {
  let amount = 0
  transactions
    .filter(instruction => !instructionType || instructionType === instruction.instructionType)
    .forEach(instruction => {
      const fromPotName = getPotNameFromInstructionPot(instruction.sellPotType)
      const toPotName = getPotNameFromInstructionPot(instruction.buyPotType)
      if (fromPotName === potName) {
        amount -= instruction.amount || 0
      }
      if (toPotName === potName) {
        amount += instruction.amount || 0
      }
    })
  return amount
}
