import { graphql } from '@retire/gql/__generated__/gql'

graphql(`
  fragment moneyInstruction__Instruction on Instruction {
    instructionType
    buyPotType
    sellPotType
    amount
    sellFull
  }
`)
