import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'

export const useFocus = <TElement extends HTMLDivElement | HTMLButtonElement>(
  modalOpenerRef?: RefObject<TElement>
): RefObject<TElement> => {
  const initialFocusRef = useRef<TElement>(null)

  useEffect(() => {
    // focus on first action element on modal opening
    initialFocusRef?.current?.focus()
    const modalOpenRefCurrent = modalOpenerRef?.current
    return () => {
      // focus on opener link on modal closure
      modalOpenRefCurrent?.focus()
    }
  }, [modalOpenerRef])

  return initialFocusRef
}
