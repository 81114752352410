import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { mainRootPath } from '@retire/navigation/paths'
import { NotFoundTemplate } from '@retire/templates/NotFoundTemplate'
import type { FC } from 'react'

export const IllustrationNotFound: FC = () => {
  const { t } = useTranslationWithOptions('illustration')

  return (
    <NotFoundTemplate
      title={t('common:hygienePages.notFound.title', 'Illustration Not found title')}
      buttonLabel={t('hygienePages.notFound.button', 'Illustration Not found button')}
      buttonPath={mainRootPath}
    />
  )
}
