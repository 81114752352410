import { Divider } from '@retire/components/Divider'
import { Currency } from '@retire/components/Formatter/Currency'
import { Label } from '@retire/components/Forms/hocs/withFormLabels'
import { PartialLoader } from '@retire/components/PartialLoader'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { PotName } from '@retire/constants/pots'
import { MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY } from '@retire/gql/projections/queries'
import { useEstimateIncomeModal } from '@retire/hooks/useEstimateIncomeModal'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { HeadlineLevel } from '@retire/theme/typography'
import { toPennies } from '@retire/utils/currency'
import { isFlexibleIncomePotDataValid } from '@retire/utils/pots'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import {
  IncomeValue,
  SLoadingContainer,
} from '../../../../../components/Journey/PotEstimatedMonthlyIncome/index.styled'
import type { TPotEstimatedMonthlyIncome } from '../../../../../components/Journey/PotEstimatedMonthlyIncome/types'

export const PotEstimatedMonthlyIncome: FC<TPotEstimatedMonthlyIncome> = ({ amount, startAge, endAge }) => {
  const { t } = useTranslationWithOptions('illustration')
  const { Modal, ModalLink } = useEstimateIncomeModal(PotName.flexibleIncome)

  const skipFlexibleIncomePotProjection = !isFlexibleIncomePotDataValid({
    startAge: Number(startAge),
    endAge: Number(endAge),
    potAmount: toPennies(amount),
  })
  const { data, loading } = useQueryWithError(MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotAmount: toPennies(amount),
      flexibleIncomePotStartAge: Number(startAge),
      flexibleIncomePotEndAge: Number(endAge),
    },
    skip: skipFlexibleIncomePotProjection,
  })
  const projectedMonthlyIncome = data?.projections?.monthlyFlexibleIncomeAmount

  if (loading) {
    return (
      <>
        <Divider top="medium" bottom="mediumSmall" />
        <SLoadingContainer>
          <Spacer right="medium">
            <PartialLoader size="medium" />
          </Spacer>
          {t('steps.potSteps.potConfiguration.projection.fetching', 'Fetching projections')}
        </SLoadingContainer>
      </>
    )
  }

  return projectedMonthlyIncome ? (
    <>
      <Divider top="medium" bottom="mediumSmall" />
      <Label as="p">{t('steps.potSteps.potConfiguration.projection.title', 'Monthly income (before tax)')}</Label>
      <IncomeValue level={HeadlineLevel.h2} as="h4">
        <Trans
          i18nKey="steps.potSteps.flexibleIncomePot.projection.amount"
          components={{ currency: <Currency precision={0} /> }}
        >
          {t(
            'steps.potSteps.flexibleIncomePot.projection.amount',
            'Projected monthly income is {{projectedMonthlyIncome}}',
            {
              projectedMonthlyIncome,
            }
          )}
        </Trans>
      </IncomeValue>
      <Copy as="p">
        {t('steps.potSteps.flexibleIncomePot.projection.description', 'from {{startAge}} to {{endAge}}', {
          startAge,
          endAge,
        })}
      </Copy>
      <Spacer bottom="mediumSmall" />
      <ModalLink />
      <Modal />
    </>
  ) : null
}
