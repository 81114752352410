import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { toCurrency } from '@retire/utils/currency'
import { toPercentage } from '@retire/utils/strings'
import type { FC } from 'react'

import { usePlanContext } from '../../../hooks/usePlanContext'
import { Paragraph } from '../Common/Paragraph'
import { Section } from '../Common/Section'
import { SubSection } from '../Common/SubSection'

export const LumpSumAndDeathBenefitAllowance: FC = () => {
  const { t } = useTranslationWithOptions('plan')

  const {
    plan: { valuationAmount, taxFreeLumpSum: taxFreeLumpSumAmount },
  } = usePlanContext()
  const {
    lumpSumAllowance: { amount: lumpSumAllowanceAmount },
    lumpSumAndDeathBenefitAllowance: { amount: lumpSumAndDeathBenefitAllowanceAmount },
  } = useSettings()

  return (
    <>
      <Section>
        <SubSection>
          <Paragraph>
            {t(
              'lumpSumAndDeathBenefitAllowance.description',
              'LumpSumAllowance amount {{lumpSumAllowanceAmount}}, LumpSumAllowance percentage {{lumpSumAllowancePercent}}, LumpSumAndDeathBenefitAllowance amount {{lumpSumAndDeathBenefitAllowanceAmount}}, TaxFreeLumpSum amount {{taxFreeLumpSumAmount}}',
              {
                lumpSumAllowanceAmount: toCurrency(lumpSumAllowanceAmount, 0),
                lumpSumAllowancePercent: toPercentage(valuationAmount / lumpSumAllowanceAmount),
                lumpSumAndDeathBenefitAllowanceAmount: toCurrency(lumpSumAndDeathBenefitAllowanceAmount, 0),
                taxFreeLumpSumAmount: toCurrency(taxFreeLumpSumAmount, 0),
              }
            )}
          </Paragraph>
        </SubSection>
      </Section>
    </>
  )
}
