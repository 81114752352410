import { FormTextInput } from '@retire/components/Forms/TextInput'
import { Icon } from '@retire/components/Icon'
import { Link } from '@retire/components/Link'
import { OrderedList, UnorderedList } from '@retire/components/List'
import { Spacer } from '@retire/components/Spacer'
import { Headline } from '@retire/components/Typography/Headline'
import { MEMBERS_BOOKLET_QUERY } from '@retire/gql/membersBooklet/queries'
import type { TMembersBookletQueryResponse } from '@retire/gql/membersBooklet/types'
import { USER_NAME_QUERY } from '@retire/gql/user/queries'
import { useForm } from '@retire/hooks/useForm'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { TypographyColor } from '@retire/theme/colors'
import { HeadlineLevel } from '@retire/theme/typography'
import { toCurrency } from '@retire/utils/currency'
import { isRequired, matchesValue } from '@retire/utils/rules'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { StepWrapper } from '../../components/StepWrapper'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { SContent, SHeadline } from './presentation.styled'
import { declarationServices } from './services'
import type { TDeclarationFormFields } from './types'

const Content: FC<{ heading?: string }> = ({ children, heading }) => (
  <ContentWrapper>
    {!!heading && (
      <Spacer bottom="large">
        <Headline color={TypographyColor.primary} as="h2" level={HeadlineLevel.h3}>
          {heading}
        </Headline>
      </Spacer>
    )}
    <Spacer bottom="large">{children}</Spacer>
  </ContentWrapper>
)

export const Declaration: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.declaration })
  const { t } = useTranslationWithOptions('enrolment')

  const { data: userNameData, loading: userNameLoading } = useQueryWithError(USER_NAME_QUERY)
  const {
    lumpSumAllowance: { amount: lumpSumAllowanceAmount },
    lumpSumAndDeathBenefitAllowance: { amount: lumpSumAndDeathBenefitAllowanceAmount },
    moneyPurchaseAnnualAllowance: { amount: moneyPurchaseAnnualAllowanceAmount },
  } = useSettings()
  const { data: bookletData, loading: bookletLoading } =
    useQueryWithError<TMembersBookletQueryResponse>(MEMBERS_BOOKLET_QUERY)

  const userName = useMemo(
    () => (userNameData ? `${userNameData.user.forename} ${userNameData.user.surname}` : ''),
    [userNameData]
  )
  const bookletURL = bookletData?.membersBooklet?.url

  const navigate = useNavigate()

  const onSubmit = useCallback(() => navigate(paths.enrolment.thankYou), [navigate])
  const { methods, handleSubmit, isLoading } = useForm<TDeclarationFormFields>({
    ...declarationServices,
    onSubmit,
    customSaveErrorTitle: 'Saving declaration error',
  })

  const transComponent = {
    list: <UnorderedList />,
    signatureList: <OrderedList />,
    listItem: <li />,
    privacyPolicy: <Link as="a" href={t('common:footer.help.privacy.url', 'Privacy URL')} target="_blank" />,
    termsofUse: <Link as="a" href={t('common:externalLinks.termsOfUse', 'Terms of use URL')} target="_blank" />,
    memberBooklet: <Link as="a" href={bookletURL} target="_blank" />,
  }

  return (
    <FormProvider {...methods}>
      <StepWrapper
        title={t('declaration.title', 'Declaration')}
        subTitle={t('declaration.subTitle', 'Please, read and sign the declaration')}
        nextText={t('declaration.actions.next', 'Next')}
        backText={t('general.actions.back', 'Back')}
        loading={isLoading || userNameLoading || bookletLoading}
        onNext={handleSubmit}
        backUrl={paths.enrolment.cobs}
      >
        <SContent>
          <Content>
            <Trans components={transComponent}>{t('declaration.description.main.info', 'Main information')}</Trans>
          </Content>
          <Content heading={t('declaration.description.investment.title', 'Investments title')}>
            <Trans components={transComponent}>
              {t(
                'declaration.description.investment.info',
                'Investments information: MoneyPurchaseAnnualAllowance amount: {{moneyPurchaseAnnualAllowanceAmount}})',
                {
                  moneyPurchaseAnnualAllowanceAmount: toCurrency(moneyPurchaseAnnualAllowanceAmount, 0),
                }
              )}
            </Trans>
          </Content>
          <Content heading={t('declaration.description.payments.title', 'Payments title')}>
            <Trans components={transComponent}>
              {t('declaration.description.payments.info', 'Payments information')}
            </Trans>
          </Content>
          <Content heading={t('declaration.description.allowances.title', 'Allowances title')}>
            <Trans components={transComponent}>
              {t(
                'declaration.description.allowances.info',
                'Allowances info: LumpSumAndDeathBenefitAllowance amount = {{lumpSumAndDeathBenefitAllowanceAmount}}, LumpSumAllowance amount = {{lumpSumAllowanceAmount}}, MoneyPurchaseAnnualAllowance amount = {{moneyPurchaseAnnualAllowanceAmount}})',
                {
                  lumpSumAllowanceAmount: toCurrency(lumpSumAllowanceAmount, 0),
                  lumpSumAndDeathBenefitAllowanceAmount: toCurrency(lumpSumAndDeathBenefitAllowanceAmount, 0),
                  moneyPurchaseAnnualAllowanceAmount: toCurrency(moneyPurchaseAnnualAllowanceAmount, 0),
                }
              )}
            </Trans>
          </Content>
          <Content heading={t('declaration.description.dataProtection.title', 'Data protection title')}>
            <Trans components={transComponent}>
              {t('declaration.description.dataProtection.info', 'Data protection information')}
            </Trans>
          </Content>
          <Content>
            <SHeadline as="h3" level={HeadlineLevel.h3}>
              <Icon iconType="Edit" />
              {t('declaration.form.title', 'Signature title')}
            </SHeadline>
            <Trans components={transComponent}>{t('declaration.form.description', 'Signature description')}</Trans>
            <FormTextInput
              name="signature"
              rules={{
                validate: {
                  required: isRequired(t('declaration.form.signature.validation.required', 'Signature is required')),
                  signatureMatch: matchesValue({
                    matchingValue: userName,
                    message: t('declaration.form.signature.validation.noMatch', 'Signature must match'),
                    isCaseSensitive: false,
                  }),
                },
              }}
              label={t('declaration.form.signature.label', 'Full name')}
              required
            />
          </Content>
        </SContent>
      </StepWrapper>
    </FormProvider>
  )
}
