import { beneficiariesServices } from '@retire/features-shared/Account/components/Beneficiaries/services'
import type { TBeneficiaries } from '@retire/features-shared/Account/components/Beneficiaries/types'
import { getEmptyBeneficiary } from '@retire/features-shared/Account/components/Beneficiaries/utils'
import { BeneficiaryRemovalConfirmation } from '@retire/features-shared/Account/features/BeneficiaryRemovalConfirmation'
import { useForm } from '@retire/hooks/useForm'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { FormProvider, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { BeneficiariesList } from '../../components/BeneficiariesList'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'

export const MoneyLeftOver: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.moneyLeftOver })
  const navigate = useNavigate()
  const [removalConfirmation, setRemovalConfirmation] = useState<number>()
  const onSubmit = useCallback(() => navigate(paths.enrolment.checkAndConfirm), [navigate])

  const { methods, handleSubmit, isFetchLoading } = useForm<TBeneficiaries>({
    ...beneficiariesServices,
    onSubmit,
    hookFormOptions: {
      defaultValues: {
        beneficiaries: [],
      },
    },
  })

  const { append, remove } = useFieldArray({
    name: 'beneficiaries',
    ...methods,
  })

  const { watch } = methods

  const onRemovalConfirmationCancel = useCallback(() => setRemovalConfirmation(undefined), [])
  const onRemovalConfirmationSave = useCallback(() => {
    if (removalConfirmation !== undefined) {
      remove(removalConfirmation)
    }
    setRemovalConfirmation(undefined)
  }, [removalConfirmation, remove])

  const beneficiaries = watch('beneficiaries')
  const onAdd = useCallback(() => {
    append(getEmptyBeneficiary(beneficiaries))
  }, [append, beneficiaries])

  if (removalConfirmation !== undefined) {
    const beneficiaryRemovalConfirmationName = watch(`beneficiaries.${removalConfirmation}.name`)

    return (
      <BeneficiaryRemovalConfirmation
        name={beneficiaryRemovalConfirmationName}
        onCancel={onRemovalConfirmationCancel}
        onSave={onRemovalConfirmationSave}
      />
    )
  }

  if (isFetchLoading) {
    // not using StepWrapper 'loading' here as there's currently some problem with bornOn field
    // if rendering BeneficiariesList (and so StepWrapper and FormProvider), we have randomly
    // an extra render with emptied bornOn fields
    // VK-47: to be reviewed, after/if getting rid of formMachine here
    return null
  }

  return (
    <FormProvider {...methods}>
      <BeneficiariesList
        beneficiaries={Object.values(beneficiaries)}
        onSave={handleSubmit}
        onAdd={onAdd}
        onRemove={setRemovalConfirmation}
      />
    </FormProvider>
  )
}
