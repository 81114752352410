import { useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ThankYouModalContent } from '../ThankYouModalContent'

export const MoveMoneyThankYou: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()

  const onNext = useCallback(() => navigate(paths.dashboard.board, { replace: true }), [navigate])

  return (
    <ThankYouModalContent
      title={t('pots.common.thankYou.title', "We've received your request")}
      buttonText={t('pots.common.thankYou.actions.goToDashboard', 'Back to dashboard')}
      onButtonClick={onNext}
    />
  )
}
