import { styled } from '@retire/theme/styledComponents'

import { DEFAULT_PROPS } from './constants'
import type { TSpacer } from './types'

// note: using span instead of div, to be able to use it in <Trans> components
export const Spacer = styled.span<TSpacer>`
  margin-top: ${({ top = DEFAULT_PROPS.top, theme }) => theme.spacing[top]};
  margin-right: ${({ right = DEFAULT_PROPS.right, theme }) => theme.spacing[right]};
  margin-bottom: ${({ bottom = DEFAULT_PROPS.bottom, theme }) => theme.spacing[bottom]};
  margin-left: ${({ left = DEFAULT_PROPS.left, theme }) => theme.spacing[left]};
  display: ${props => (props.inline ? 'inline-block' : 'block')};
`
