import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { PotName } from '@retire/constants/pots'
import { useGetPotDisplayName } from '@retire/hooks/useGetPotDisplayName'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { TypographyColor } from '@retire/theme/colors'
import { StyleWeight } from '@retire/theme/utilities'
import { toCurrency } from '@retire/utils/currency'
import { getPotColor } from '@retire/utils/pots'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans } from 'react-i18next'

import { JourneyOverviewAmount } from '../Amount'
import { JourneyOverviewDetails } from '../Details'
import { usePotDetails } from './hooks/usePotDetails'
import { SJourneyOverviewPotDetailsDot, SJourneyOverviewPotDetailsName } from './index.styled'
import type { TJourneyOverviewPotDetails } from './types'

const ProjectionDetails: FC<TJourneyOverviewPotDetails> = ({ potName }) => {
  const { t } = useTranslationWithOptions('illustration')
  const { monthlyIncome, startAge, endAge } = usePotDetails(potName)

  if (potName === PotName.flexibleIncome) {
    return (
      <Copy as="div" color={TypographyColor.neutral}>
        <Trans components={{ bold: <strong /> }}>
          {t(
            'overview.steps.incomePots.details.flexibleIncomePot',
            '{{monthlyIncome}} from {{startAge}} to {{endAge}}',
            {
              monthlyIncome: toCurrency(monthlyIncome, 0),
              startAge,
              endAge,
            }
          )}
        </Trans>
      </Copy>
    )
  } else if (potName === PotName.laterLife) {
    return (
      <Copy as="div" color={TypographyColor.neutral}>
        <Trans components={{ bold: <strong /> }}>
          {t('overview.steps.incomePots.details.laterLifePot', '{{monthlyIncome}} from {{startAge}} to life', {
            monthlyIncome: toCurrency(monthlyIncome, 0),
            startAge,
          })}
        </Trans>
      </Copy>
    )
  }

  return null
}

export const PotDetails: FC<TJourneyOverviewPotDetails> = ({ potName }) => {
  const { t } = useTranslationWithOptions('illustration')
  const { allocatedAmount } = usePotDetails(potName)
  const { getPotDisplayName } = useGetPotDisplayName()

  return (
    <Row data-cy={potName}>
      <Col xs={12} lg={3}>
        <Spacer left="extraLarge">
          <SJourneyOverviewPotDetailsName as="div" weight={StyleWeight.bold} color={TypographyColor.neutral}>
            <SJourneyOverviewPotDetailsDot color={getPotColor(potName)} />
            {getPotDisplayName(potName)}
          </SJourneyOverviewPotDetailsName>
        </Spacer>
      </Col>
      <Col xs={12} lg={9}>
        <JourneyOverviewDetails>
          {allocatedAmount ? (
            <>
              <JourneyOverviewAmount amount={allocatedAmount} />
              <ProjectionDetails potName={potName} />
            </>
          ) : (
            <Copy as="span" weight={StyleWeight.bold} color={TypographyColor.primary}>
              {t('overview.steps.incomePots.details.notUsed', 'details not used')}
            </Copy>
          )}
        </JourneyOverviewDetails>
      </Col>
    </Row>
  )
}
