import { Segment, Spacer } from '@retire/components'
import { UserState } from '@retire/gql/__generated__/graphql'
import { useForm, useTranslationWithOptions, useUpdateUserState } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Question } from '../../components'
import type { TComplianceQuestions, TSuitabilityCheck } from '../../gql'
import { suitabilityCheckServices } from './services'
import { shouldShowWarningScreen } from './utils'

export const BeforeYouStart: FC = () => {
  const { t } = useTranslationWithOptions('suitabilityCheck')
  const navigate = useNavigate()
  const { updateUserState, loading } = useUpdateUserState()
  const onSubmit = useCallback(
    async (data: TSuitabilityCheck) => {
      shouldShowWarningScreen(data)
        ? navigate(paths.suitabilityCheck.confirm)
        : await updateUserState({ variables: { state: UserState.IllustrationCreation } })
    },
    [navigate, updateUserState]
  )

  const { methods, handleSubmit, isLoading } = useForm<TSuitabilityCheck>({ ...suitabilityCheckServices, onSubmit })
  const { hasSeenWarningScreen, ...questions } = methods.getValues()
  const { watch } = methods

  // rhf works on the basis of refs and not on state.
  // To check if actually all fields are set we need to introduce state to make sure that the component actually re-renders.
  const [isDisabled, setIsDisabled] = useState(true)
  useEffect(() => {
    const subscription = watch(({ hasSeenWarningScreen: _, ...formValue }) => {
      setIsDisabled(Object.values(formValue).some(answer => answer === null))
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <FormProvider {...methods}>
      <PageTemplate
        title={t('beforeYouStart.title', 'Before you start title')}
        subTitle={t('beforeYouStart.subTitle', 'Before you start subtitle')}
        disableNext={isDisabled}
        nextText={t('beforeYouStart.actions.check', 'Before you start check text')}
        onNext={handleSubmit}
        loading={loading || isLoading}
        fullWidth
      >
        <Segment whiteBackground>
          <ContentWrapper>
            {Object.keys(questions).map((question, index, array) => (
              <div key={question}>
                <Question index={index + 1} name={question as keyof TComplianceQuestions} />
                {index !== array.length - 1 && <Spacer top="extraLarge" />}
              </div>
            ))}
          </ContentWrapper>
        </Segment>
      </PageTemplate>
    </FormProvider>
  )
}
