import { getSearchParams } from '@retire/utils'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { paths } from '../../paths'

const getRedirectPath = (error: string | null) => {
  switch (error) {
    case 'userCreateError':
      return paths.account.contactSupport

    case 'userOauthDenyError':
      return paths.account.deniedAuthentication

    case 'userNonEligibleError':
      return paths.hygienePages.nonEligible

    default:
      return ''
  }
}

export const useErrorRedirect = () => {
  const navigate = useNavigate()
  const urlParams = getSearchParams()

  useEffect(() => {
    if (urlParams.has('error')) {
      const redirectPath = getRedirectPath(urlParams.get('error'))
      redirectPath && navigate(redirectPath)
    }
  }, [navigate, urlParams])
}
