import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const HamburgerMenu: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="HamburgerMenu" />
      <path d="M3 7H21" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 12H21" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 17H21" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
