import { styled } from '@retire/theme/styledComponents'

import { DEFAULT_PROPS } from './constants'
import type { TDivider } from './types'

export const SDivider = styled.hr<TDivider>`
  border: none;
  height: 1px;
  ${({ top = DEFAULT_PROPS.top, bottom = DEFAULT_PROPS.bottom, theme: { spacing, colors } }) => `
    color: ${colors.neutralLight};
    background-color: ${colors.neutralLight};
    margin-top: ${spacing[top]};
    margin-bottom: ${spacing[bottom]};
  `}
`
