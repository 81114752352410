import type { TLink } from '@retire/components'
import { Link, Spacer } from '@retire/components'
import { LinkSize, LinkVariant } from '@retire/constants'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { CopyLevel } from '@retire/theme'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

import { SFooterCopy } from '../../presentation.styled'

export const UpperFooterLinks: FC = () => {
  const { t } = useTranslationWithOptions('common')
  const {
    customerService: { phoneNumber, uriPhoneNumber },
  } = usePublicSettings()

  const UpperFooterLink: FC<Pick<TLink, 'href'>> = ({ children, href }) => (
    <Link as="a" href={href} variant={LinkVariant.bright} size={LinkSize.large} target="_blank">
      {children}
    </Link>
  )

  return (
    <Row>
      <Col md={4}>
        {phoneNumber && (
          <UpperFooterLink href={uriPhoneNumber}>
            {t('footer.contactUs.call.title', 'Contact us phone number', {
              phoneNumber,
            })}
          </UpperFooterLink>
        )}
        <Spacer top="small">
          <SFooterCopy as="p" level={CopyLevel.body1}>
            {t('footer.contactUs.lines', 'Contact us lines')}
          </SFooterCopy>
        </Spacer>
      </Col>
    </Row>
  )
}
