import { generateDefaultTranslationString } from '@retire/utils'
import type { StringMap } from 'i18next'
import { useCallback, useMemo } from 'react'
import type { Namespace, UseTranslationOptions } from 'react-i18next'
import { useTranslation } from 'react-i18next'

import type { TUseTranslationWithOptions } from './types'

export const useTranslationWithOptions = (
  ns: Namespace,
  translationKey?: string,
  options?: UseTranslationOptions
): TUseTranslationWithOptions => {
  const { t, ...translation } = useTranslation(ns, options)

  const getTranslationFunction = useCallback(
    (localTranslationKey?: string, withoutDefaultString?: boolean) =>
      (transKey: string, defaultValueOrOptions?: string | StringMap, localOptions?: StringMap): string => {
        const key = localTranslationKey ? `${localTranslationKey}.${transKey}` : transKey
        return typeof defaultValueOrOptions === 'string' && defaultValueOrOptions !== '' && !withoutDefaultString
          ? t(key, generateDefaultTranslationString(defaultValueOrOptions), localOptions)
          : t(key, defaultValueOrOptions)
      },
    [t]
  )

  const memoizedT = useMemo(() => getTranslationFunction(translationKey), [getTranslationFunction, translationKey])
  const tWithoutKey = useMemo(() => getTranslationFunction(), [getTranslationFunction])
  const tWithoutDefaultString = useMemo(
    () => getTranslationFunction(translationKey, true),
    [getTranslationFunction, translationKey]
  )

  return {
    ...translation,
    t: memoizedT,
    tWithoutKey,
    tWithoutDefaultString,
  } as TUseTranslationWithOptions
}
