import { Copy, Link, TransList } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SSup } from './presentation.styled'

export const Introduction: FC = () => {
  const { t } = useTranslationWithOptions('enrolment')
  const navigate = useNavigate()
  const onNext = useCallback(() => navigate(paths.enrolment.emailCheck), [navigate])

  return (
    <PageTemplate
      title={
        <span>
          <Trans components={[<SSup key="TM" />]}>{t('introduction.title', 'Introduction')}</Trans>
        </span>
      }
      subTitle={t('introduction.subTitle', 'Introduction subtitle')}
      nextText={t('introduction.actions.getStarted', 'Get started')}
      onNext={onNext}
    >
      <TransList>{t('introduction.description', 'Introduction description')}</TransList>
      <Copy as="p" color={TypographyColor.neutralDark}>
        <Trans
          components={[
            <Link as="a" key="link" href={t('common:externalLinks.termsOfUse', 'Terms of use URL')} target="_blank" />,
          ]}
        >
          {t('introduction.terms', 'Smart Retire terms of use')}
        </Trans>
      </Copy>
    </PageTemplate>
  )
}
