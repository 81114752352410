import { Table } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import type { TMessageComponent } from '../types'

export const DesktopInbox: FC<{ messages: TMessageComponent[] }> = ({ messages }) => {
  const { t } = useTranslationWithOptions('inbox')

  return (
    <Table
      verticalAlign="middle"
      columnHeaders={[
        { content: t('inbox.table.header.date', 'Date'), width: '20%' },
        { content: t('inbox.table.header.from', 'From'), width: '20%' },
        { content: '', width: '2.5rem' },
        { content: t('inbox.table.header.subject', 'Subject') },
      ]}
      rows={messages.map(({ from, subject, sentAt, files }) => ({
        cells: [{ content: sentAt }, { content: from }, { content: files, textAlign: 'center' }, { content: subject }],
      }))}
    />
  )
}
