import { useEffect } from 'react'

import { usePageOverlay } from '../usePageOverlay'

export const useActivatePageOverlay = () => {
  const { activateOverlay } = usePageOverlay()

  useEffect(() => {
    activateOverlay(true)
    return () => activateOverlay(false)
  }, [activateOverlay])
}
