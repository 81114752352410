import { getPotIcon, IconContainer, Table } from '@retire/components'
import { PotName } from '@retire/constants'
import { useGetPotDisplayName, useTranslationWithOptions, useUserDetails } from '@retire/hooks'
import { formatDifference, isIncomePot, toCurrency } from '@retire/utils'
import type { FC } from 'react'
import { useMemo } from 'react'

import { useMoveMoneyMonthlyAmount } from '../../../hooks/useMoveMoneyMonthlyAmount'
import { usePotsContext } from '../../../hooks/usePotsContext'
import { STitleContainer } from '../TitleContainer/index.styled'
import type { TConfirmMoveMoneyPotTable } from './types'

export const ConfirmMoveMoneyPotTable: FC<TConfirmMoveMoneyPotTable> = ({
  potName,
  fromPotName,
  amount,
  isRegularIncomePaused,
}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const { getPotDisplayLongName } = useGetPotDisplayName()
  const PotIcon = getPotIcon(potName)

  const {
    pots: {
      [potName]: { allocatedAmount },
    },
  } = usePotsContext()
  const isFlexibleIncomePot = potName === PotName.flexibleIncome
  const {
    data: { laterLifeAge, regularIncomeEndAge },
  } = useUserDetails()

  const untilFromAge = isFlexibleIncomePot ? regularIncomeEndAge : laterLifeAge

  const { currentMonthlyAmount, afterMoveMonthlyAmount, afterMovePotAmount, penniesAmount, monthlyAmountDiff } =
    useMoveMoneyMonthlyAmount({
      potName,
      selectedFromPot: fromPotName,
      amount,
    })

  const columnHeaders = useMemo(
    () => [
      { content: '', width: '25%' },
      { content: t('pots.common.confirmMoveMoneyRequest.tables.columns.currentValue', 'Current'), width: '25%' },
      { content: t('pots.common.confirmMoveMoneyRequest.tables.columns.movedAmount', 'Changed'), width: '25%' },
      { content: t('pots.common.confirmMoveMoneyRequest.tables.columns.newValue', 'New'), width: '25%' },
    ],
    [t]
  )

  const memoizedRows = useMemo(() => {
    const rows = [
      {
        rowHeader: { content: t('pots.common.confirmMoveMoneyRequest.tables.rows.balance', 'Balance') },
        cells: [
          { content: toCurrency(allocatedAmount) },
          { content: formatDifference(penniesAmount) },
          { content: toCurrency(afterMovePotAmount) },
        ],
      },
    ]

    const isFlexibleIncomePaused = isFlexibleIncomePot && isRegularIncomePaused

    if (isIncomePot(potName) && !isFlexibleIncomePaused) {
      const ageTranslationKey = `pots.common.confirmMoveMoneyRequest.tables.cells.${
        isFlexibleIncomePot ? 'untilAge' : 'fromAge'
      }`
      const defaultAgeTranslation = `${isFlexibleIncomePot ? 'Until' : 'From'} age {{laterLifeAge}}: {{amount}}`

      rows.push({
        rowHeader: { content: t('pots.common.confirmMoveMoneyRequest.tables.rows.monthlyIncome', 'Monthly income') },
        cells: [
          {
            content: t(ageTranslationKey, defaultAgeTranslation, {
              amount: toCurrency(currentMonthlyAmount),
              laterLifeAge: untilFromAge,
            }),
          },
          { content: formatDifference(monthlyAmountDiff) },
          {
            content: t(ageTranslationKey, defaultAgeTranslation, {
              amount: toCurrency(afterMoveMonthlyAmount),
              laterLifeAge: untilFromAge,
            }),
          },
        ],
      })
    }

    return rows
  }, [
    t,
    allocatedAmount,
    penniesAmount,
    afterMovePotAmount,
    isFlexibleIncomePot,
    isRegularIncomePaused,
    potName,
    currentMonthlyAmount,
    untilFromAge,
    monthlyAmountDiff,
    afterMoveMonthlyAmount,
  ])

  return (
    <Table
      title={
        <STitleContainer>
          <IconContainer size="small" withSpacing>
            <PotIcon />
          </IconContainer>
          {getPotDisplayLongName(potName)}
        </STitleContainer>
      }
      columnHeaders={columnHeaders}
      rows={memoizedRows}
    />
  )
}
