import { useTranslationWithOptions } from '@retire/hooks'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

export const ServerError: FC = () => {
  const { t } = useTranslationWithOptions('common')

  return (
    <PageTemplate
      title={t('hygienePages.serverError.title', 'Server error title')}
      subTitle={
        <span>
          <Trans
            i18nKey="caption"
            components={{
              bold: <strong />,
            }}
          >
            {t('hygienePages.serverError.caption', 'Server error caption')}
          </Trans>
        </span>
      }
    />
  )
}
