import { Copy } from '@retire/components/Typography'
import { IDS } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { StyleWeight, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { useEffect } from 'react'

import { OutageBanner } from './presentation'

export const OutageBannerContainer: FC = () => {
  const { t } = useTranslationWithOptions('common')
  const blockKeyboard = (e: KeyboardEvent) => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  useEffect(() => {
    document.addEventListener('keydown', blockKeyboard)
    const outageBanner = document.getElementById(IDS.outageBanner)
    outageBanner && outageBanner.focus()
    return () => document.removeEventListener('keydown', blockKeyboard)
  }, [])

  return (
    <OutageBanner>
      <Copy as="p" weight={StyleWeight.bold} color={TypographyColor.white}>
        {t('internetOutageBanner.title', 'Outage title')}
      </Copy>
      <Copy as="p" color={TypographyColor.white}>
        {t('internetOutageBanner.description', 'Outage description')}
      </Copy>
    </OutageBanner>
  )
}
