import { NavButtons, Spacer } from '@retire/components'
import { INIT_QUERY, USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'
import { useMutationWithError, useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { LaterLifePotReviewTable } from '../LaterLifePotReviewTable/presentation'
import { ROWS_BY_ORDER } from './constants'
import { USER_LATER_LIFE_AGE_MUTATION } from './gql/mutations'
import type { TLaterLifePotPeriodReview } from './types'

export const LaterLifePotPeriodReview: FC<TLaterLifePotPeriodReview> = ({ startAge, monthlyIncome, onShowModal }) => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const [updateLaterLifeAge, { loading: updatingLaterLifeAge }] = useMutationWithError(USER_LATER_LIFE_AGE_MUTATION, {
    refetchQueries: [{ query: USER_FULL_DETAILS_QUERY }, { query: INIT_QUERY }],
    errorTitle: t('pots.common.reviewChanges.errors.laterLifeAgeError', 'Changing later life start age error'),
  })

  const onQuit = useCallback(() => {
    navigate(paths.dashboard.laterLifePotDetails)
  }, [navigate])

  const onContinue = useCallback(async () => {
    await updateLaterLifeAge({
      variables: { laterLifeAge: startAge.new },
      onCompleted: () => {
        onShowModal()
      },
    })
  }, [updateLaterLifeAge, startAge.new, onShowModal])

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <PageTemplate
      fullWidth
      title={t('pots.common.reviewChanges.title', 'Review')}
      onQuit={onQuit}
      loading={updatingLaterLifeAge}
    >
      <Spacer top="large" bottom="large">
        <LaterLifePotReviewTable rowsByOrder={ROWS_BY_ORDER} monthlyIncome={monthlyIncome} startAge={startAge} />
      </Spacer>

      <NavButtons
        onNext={onContinue}
        onBack={onCancel}
        nextText={t('pots.common.reviewChanges.actions.complete', 'Complete')}
        backText={t('pots.common.reviewChanges.actions.back', 'Back')}
      />
    </PageTemplate>
  )
}
