import type { FC } from 'react'

import { useSortCode } from './hooks'
import { SortCode } from './presentation'
import type { TSortCodeContainer } from './types'

export const SortCodeContainer: FC<TSortCodeContainer> = props => {
  const { value, onChange, name } = props
  const hooks = useSortCode(name, value, onChange)

  return <SortCode {...props} {...hooks} />
}
