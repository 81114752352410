import { Link } from '@retire/components/Link'
import { NavButtons } from '@retire/components/NavButtons'
import { Segment } from '@retire/components/Segment'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { Headline } from '@retire/components/Typography/Headline'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { HeadlineLevel } from '@retire/theme/typography'
import { signOut } from '@retire/utils/session'
import type { FC } from 'react'
import { useMatch } from 'react-router-dom'

import { useSaveAndExitPage } from '../../../hooks/useSaveAndExitPage'
import { SaveAndExit } from '../../SaveAndExit/presentation'
import { JourneyOverview } from '../JourneyOverview'
import type { TJourneyStepLayout } from './types'

export const JourneyStepLayout: FC<TJourneyStepLayout> = ({
  title,
  description,
  children,
  loading,
  hideOverview,
  ...navButtonsProps
}) => {
  const { t } = useTranslationWithOptions('illustration')
  const { isSaveAndExitPage, showSaveAndExitPage, hideSaveAndExitPage } = useSaveAndExitPage()
  const isTFLSPage = !!useMatch(paths.illustration.journeyTaxFreeLumpSumWithdrawal)

  return (
    <>
      {!isSaveAndExitPage ? (
        <PageTemplate title={t('pageTitle.journey', 'Journey page title')} fullWidth loading={loading}>
          {!hideOverview && <JourneyOverview />}
          <Spacer top="extraLarge" />
          <Segment whiteBackground>
            <ContentWrapper>
              <Headline level={HeadlineLevel.h2} as="h2">
                {title}
              </Headline>
              {description && (
                <Spacer top="medium">
                  <Copy as="div">{description}</Copy>
                </Spacer>
              )}
            </ContentWrapper>
            <Spacer top="medium" bottom="extraLarge">
              {children}
            </Spacer>
            <NavButtons {...navButtonsProps} />
          </Segment>
          {!isTFLSPage && (
            <Link as="a" onClick={showSaveAndExitPage}>
              {t('pageTitle.saveAndExit', 'Save and exit')}
            </Link>
          )}
        </PageTemplate>
      ) : (
        <SaveAndExit onNext={signOut} onCancel={hideSaveAndExitPage} />
      )}
    </>
  )
}
