import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import { Spacer } from '../../Spacer'
import { Headline } from '../../Typography'

export const SPotContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Wrapper = styled.div`
  ${({
    theme: {
      segment: { paddingTouch },
    },
  }) => css`
    display: flex;
    flex: 1;

    ${mediaStyles(
      { marginTop: 'auto', paddingTop: paddingTouch },
      {
        max: Breakpoint.medium,
        selector: Spacer,
      }
    )}
  `}
`

export const SPotNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const PotValue = styled(Headline)`
  align-items: baseline;
`

export const SHidden = styled.div`
  opacity: 0;
`

export const SLockedTextWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: flex-end;
`
