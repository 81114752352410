import { colors } from './colors'
import { spacing } from './spacing'
import { PRIMARY_FAMILY } from './typography'
import { utilities } from './utilities'

export const table = {
  caption: {
    fontFamily: PRIMARY_FAMILY,
    textAlign: 'left',
    color: colors.primary,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: utilities.weight.semiBold,
  },
  content: {
    fontFamily: PRIMARY_FAMILY,
    fontSize: '1rem',
    fontWeight: utilities.weight.regular,
    lineHeight: '1.5rem',
    color: colors.neutralDark,
    padding: `0 ${spacing.small}`,

    headers: {
      fontWeight: utilities.weight.semiBold,
    },

    rowHeaders: {
      color: colors.primary,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
    columnHeaders: {
      fontSize: '1.125rem',
      lineHeight: '1.688rem',
    },
  },
  border: `1px ${colors.neutral30} solid`,
}
