import {
  MAX_BENEFICIARY_SHARE_VALUE,
  MIN_BENEFICIARY_SHARE_VALUE,
  NONNUMERIC_DECIMAL_CHAR_REGEX,
} from '@retire/constants'
import { useBlockOnKeyDown, useBlockOnMaxLength } from '@retire/hooks'
import { isEmpty } from 'lodash'
import type { ChangeEvent, FC } from 'react'
import { useCallback } from 'react'

import { FormTextInput } from '../TextInput'
import type { TPercentInput } from './types'

export const PercentInput: FC<TPercentInput> = ({ onChange, ...props }) => {
  const blockOnMaxLength = useBlockOnMaxLength(5)
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_DECIMAL_CHAR_REGEX, blockOnMaxLength)
  const inputUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      onChange && onChange(isEmpty(value) ? 0 : Number.parseInt(value, 10)) // === integer only
    },
    [onChange]
  )

  return (
    <FormTextInput
      {...props}
      type="number"
      onKeyDown={onKeyDown}
      onChange={inputUpdate}
      size={35}
      suffix="%"
      width="100px"
      inputWidth="100px"
      textAlign="right"
      min={MIN_BENEFICIARY_SHARE_VALUE.toString()}
      max={MAX_BENEFICIARY_SHARE_VALUE.toString()}
    />
  )
}
