import { Divider } from '@retire/components/Divider'
import { FormNumberInput } from '@retire/components/Forms/NumberInput/presentation'
import { NavButtons } from '@retire/components/NavButtons/presentation'
import { Spacer } from '@retire/components/Spacer/index.styled'
import { Copy } from '@retire/components/Typography/Copy/index.styled'
import { Headline } from '@retire/components/Typography/Headline'
import { PotName } from '@retire/constants/pots'
import { NONNUMERIC_CHAR_REGEX } from '@retire/constants/regex'
import { MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY } from '@retire/gql/projections/queries'
import { useBlockOnKeyDown } from '@retire/hooks/useBlockOnKeyDown'
import { useBlockOnMaxLength } from '@retire/hooks/useBlockOnMaxLength'
import { useBlockOnPaste } from '@retire/hooks/useBlockOnPaste'
import { useFlexibleIncomePotAgeValidationRules } from '@retire/hooks/useFlexibleIncomePotAgeValidationRules'
import { useFormDefaultValues } from '@retire/hooks/useFormDefaultValues'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { HeadlineLevel } from '@retire/theme/typography'
import { toCurrency } from '@retire/utils/currency'
import { isFlexibleIncomePotDataValid } from '@retire/utils/pots'
import type { FC } from 'react'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { usePotsContext } from '../../../hooks/usePotsContext'
import type { TFlexibleIncomePotManageIncomeReviewState } from '../types'
import type { TFlexibleIncomePotPeriodFormFields } from './types'

export const FlexibleIncomePotPeriod: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()

  const {
    pots: {
      [PotName.flexibleIncome]: {
        allocatedAmount: flexibleIncomePotAmount = 0,
        customMonthlyIncome: currentMonthlyIncomeAmount = 0,
      },
    },
  } = usePotsContext()
  const {
    data: { currentAge: minStartAge, regularIncomeEndAge, regularIncomeStartAge },
    loading: loadingUserDetails,
  } = useUserDetails()

  const currentStartAge = Math.max(regularIncomeStartAge, minStartAge)

  const [defaultValues, setDefaultValues] = useFormDefaultValues<TFlexibleIncomePotPeriodFormFields>({
    initialValues: { startAge: String(currentStartAge), endAge: String(regularIncomeEndAge) },
  })

  const methods = useForm<TFlexibleIncomePotPeriodFormFields>({
    mode: 'onChange',
    defaultValues,
  })

  const {
    formState: { isValid, isValidating },
    handleSubmit,
    watch,
  } = methods

  const { startAge, endAge } = watch()

  const skipFlexibleIncomePotProjection = !isFlexibleIncomePotDataValid({
    startAge: Number(startAge),
    endAge: Number(endAge),
    potAmount: flexibleIncomePotAmount,
  })
  const { data, loading } = useQueryWithError(MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotAmount,
      flexibleIncomePotStartAge: Number(startAge),
      flexibleIncomePotEndAge: Number(endAge),
    },
    skip: !isValid || isValidating || skipFlexibleIncomePotProjection,
  })
  const projectedMonthlyIncomeAmount = data?.projections?.monthlyFlexibleIncomeAmount ?? 0

  const onContinue = handleSubmit(() => {
    const reviewTableData: TFlexibleIncomePotManageIncomeReviewState = {
      startAge: {
        current: currentStartAge,
        new: Number(startAge),
      },
      endAge: {
        current: regularIncomeEndAge,
        new: Number(endAge),
      },
      monthlyIncome: {
        current: currentMonthlyIncomeAmount,
        new: projectedMonthlyIncomeAmount,
      },
    }
    setDefaultValues({ startAge, endAge })

    navigate(paths.dashboard.flexibleIncomePotManageIncomePeriodReview, { state: reviewTableData })
  })

  const onQuit = useCallback(() => {
    navigate(-2)
  }, [navigate])

  const onBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const { startAgeRules, endAgeRules } = useFlexibleIncomePotAgeValidationRules(parseInt(startAge), parseInt(endAge))

  const blockOnMaxLength = useBlockOnMaxLength(2)
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_CHAR_REGEX, blockOnMaxLength)
  const onPaste = useBlockOnPaste({ maxLength: 2 })

  return (
    <FormProvider {...methods}>
      <PageTemplate
        fullWidth
        title={t('pots.common.manageIncome.timeTitle', 'Age')}
        onQuit={onQuit}
        loading={loadingUserDetails}
      >
        <Copy as="p">{t('pots.common.manageIncome.startAge', 'Start age')}</Copy>
        <Spacer top="medium" bottom="medium">
          <FormNumberInput
            name="startAge"
            inputWidth="250px"
            width="250px"
            autoFocus
            rules={startAgeRules}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            max="99"
          />
        </Spacer>
        <Copy as="p">{t('pots.common.manageIncome.endAge', 'End age')}</Copy>
        <Spacer top="medium" bottom="medium">
          <FormNumberInput
            name="endAge"
            inputWidth="250px"
            width="250px"
            rules={endAgeRules}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            max="99"
          />
        </Spacer>
        <Divider bottom="large" />
        {isValid && (
          <>
            <Copy as="p">{t('pots.common.manageIncome.monthlyAmount', 'Monthly amount (before tax)')}</Copy>
            <Headline level={HeadlineLevel.h3} as="h3">
              {toCurrency(projectedMonthlyIncomeAmount, 0)}
            </Headline>
            <Spacer bottom="mediumSmall" />
            <Divider bottom="large" />
          </>
        )}
        <NavButtons
          onNext={onContinue}
          onBack={onBack}
          disableNext={loading || !isValid}
          nextText={t('pots.common.manageIncome.actions.continue', 'Continue')}
          backText={t('pots.common.manageIncome.actions.back', 'Back')}
        />
      </PageTemplate>
    </FormProvider>
  )
}
