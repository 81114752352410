import { E_CHARS_REGEX } from '@retire/constants'
import { useBlockOnKeyDown, useBlockOnPaste } from '@retire/hooks'
import { isFormattedNumber, parseStringToDecimalWithDelimiters, parseStringToFloatWithPrecision } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useState } from 'react'

import type { TInputType } from '../TextInput'
import { FormTextInput } from '../TextInput'
import { DEFAULT_PROPS } from './constants'
import type { TFormNumberInput } from './types'

export const FormNumberInput: FC<TFormNumberInput> = props => {
  const {
    withoutThousandDelimiter,
    thousandDelimiter = DEFAULT_PROPS.thousandDelimiter,
    decimalDelimiter = DEFAULT_PROPS.decimalDelimiter,
    currency = DEFAULT_PROPS.currency,
    precision = DEFAULT_PROPS.precision,
    rules = DEFAULT_PROPS.rules,
    isCurrency,
    onKeyDown: onKeyDownFromProps,
    onPaste: onPasteFromProps,
  } = props
  const [type, setType] = useState<TInputType>('text')

  const formatValue = useCallback(
    (value: string) =>
      parseStringToDecimalWithDelimiters(value, {
        decimalDelimiter,
        thousandDelimiter: withoutThousandDelimiter ? '' : thousandDelimiter,
        precision,
      }),
    [decimalDelimiter, precision, thousandDelimiter, withoutThousandDelimiter]
  )

  const unformatValue = useCallback(
    (value: string) => parseStringToFloatWithPrecision(value, { decimalDelimiter, precision }),
    [decimalDelimiter, precision]
  )

  const onFocus = useCallback(() => setType('number'), [])
  const onBlur = useCallback(() => setType('text'), [])

  const onKeyDown = useBlockOnKeyDown(E_CHARS_REGEX, onKeyDownFromProps)
  const onPaste = useBlockOnPaste({ preventCriteria: E_CHARS_REGEX, onPaste: onPasteFromProps })

  return (
    <FormTextInput
      {...props}
      format={{
        input: formatValue,
        output: unformatValue,
      }}
      prefix={isCurrency ? currency : undefined}
      rules={{
        ...rules,
        validate: {
          formattedNumber: isFormattedNumber({
            decimalDelimiter,
            thousandDelimiter,
          }),
          ...rules.validate,
        },
      }}
      type={type}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
    />
  )
}
