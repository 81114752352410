import { addressLookup } from './addressLookup'
import { breakpoints } from './breakpoints'
import { buttons } from './buttons'
import { colors } from './colors'
import { footer } from './footer'
import { forms } from './forms'
import { general } from './general'
import { header } from './header'
import { icons } from './icons'
import { links } from './links'
import { loader } from './loader'
import { modal } from './modal'
import { notification } from './notification'
import { overview } from './overview'
import { pot } from './pot'
import { progressBar } from './progressBar'
import { segment } from './segment'
import { spacing } from './spacing'
import { table } from './table'
import { tag } from './tag'
import { twister } from './twister'
import { typography } from './typography'
import { utilities } from './utilities'

export const theme = {
  spacing,
  addressLookup,
  buttons,
  footer,
  forms,
  icons,
  typography,
  segment,
  breakpoints,
  twister,
  notification,
  header,
  general,
  modal,
  progressBar,
  pot,
  overview,
  table,
  tag,
  loader,
  colors,
  links,
  utilities,
}

export { Breakpoint } from './breakpoints'
export { TypographyColor } from './colors'
export { PotColor } from './pot'
export { CopyLevel, HeadlineLevel } from './typography'
export { StyleWeight } from './utilities'
