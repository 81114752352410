import { css, styled } from '@retire/theme/styledComponents'

import { Segment } from '../Segment'
import type { TSelectableCard } from './types'

export const SSelectableCard = styled(Segment)<TSelectableCard>`
  ${({
    theme: {
      segment: { backgroundColor, borderWidth, borderColor },
      forms: { shared },
      colors: { primary },
    },
    selected,
  }) => css`
    background-color: ${backgroundColor};
    border: none;
    box-shadow: inset ${borderColor} 0 0 0 ${borderWidth};

    ${selected
      ? `
      box-shadow: inset ${primary} 0 0 0 ${shared.border.width};
    `
      : ''};
  `}
`
