import { Divider } from '@retire/components/Divider'
import { FormOptionsGroup } from '@retire/components/Forms/OptionsGroup'
import { NavButtons } from '@retire/components/NavButtons'
import { Spacer } from '@retire/components/Spacer'
import { Table } from '@retire/components/Table'
import { PotName } from '@retire/constants'
import { PotType } from '@retire/gql/__generated__/graphql'
import { FUND_POT_TYPES } from '@retire/gql/fundDetails'
import { withLocationRouterStateValidation } from '@retire/hocs/withLocationRouterStateValidation'
import { useAreYouSureModal } from '@retire/hooks/useAreYouSureModal'
import { useFullScreenModal } from '@retire/hooks/useFullScreenModal'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { AVAILABLE_FUNDS_FOR_POT_QUERY } from '@retire/hooks/usePotFunds/queries'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import isTouchDevice from 'is-touch-device'
import { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ThankYouModal } from '../../components/ChangeInheritancePotFund/ThankYouModal/presentation'
import { HOLDINGS_QUERY } from '../../gql/queries'
import type { TInheritancePotFundsReviewState } from '../InheritancePotFunds/types'
import { ConfirmChoiceOptions } from './constants'
import { FUND_SWITCH_FOR_POT_MUTATION } from './gql/mutations'
import type { TConfirmChoiceFormFields } from './types'

export const InheritancePotFundsReview = withLocationRouterStateValidation<TInheritancePotFundsReviewState>(
  ({ currentFund, newSelectedFund }) => {
    useActivatePageOverlay()
    const { t } = useTranslationWithOptions('dashboard')
    const { Modal: ThankYouModalContainer, onShowModal: showThankYouModal } = useFullScreenModal()
    const { showQuitModal, QuitModal } = useAreYouSureModal({
      onConfirm: () => navigate(paths.dashboard.inheritancePotDetails),
    })
    const [updatePotFund, { loading: updatePotFundLoading }] = useMutationWithError(FUND_SWITCH_FOR_POT_MUTATION, {
      onCompleted: () => showThankYouModal(),
      refetchQueries: [
        { query: AVAILABLE_FUNDS_FOR_POT_QUERY, variables: { potType: PotType.Inheritance } },
        { query: HOLDINGS_QUERY },
      ],
    })

    const navigate = useNavigate()
    const methods = useForm<TConfirmChoiceFormFields>({
      mode: 'all',
    })
    const {
      formState: { isValid },
      handleSubmit,
    } = methods
    const isMobile = isTouchDevice()

    const onSubmit = useCallback(async () => {
      await updatePotFund({
        variables: {
          fundId: newSelectedFund.id,
          potType: FUND_POT_TYPES[PotName.inheritance] as PotType,
        },
      })
    }, [newSelectedFund.id, updatePotFund])
    const onCancelClick = useCallback(() => navigate(-1), [navigate])
    const onContinueClick = handleSubmit(onSubmit)

    const options = useMemo(
      () => [
        {
          value: ConfirmChoiceOptions.move,
          label: t('pots.inheritancePot.investmentFunds.review.confirmChoice.options.move', 'Move'),
        },
        {
          value: ConfirmChoiceOptions.period,
          label: t('pots.inheritancePot.investmentFunds.review.confirmChoice.options.period', 'Period'),
        },
        {
          value: ConfirmChoiceOptions.risk,
          label: t('pots.inheritancePot.investmentFunds.review.confirmChoice.options.risk', 'Risk'),
        },
        {
          value: ConfirmChoiceOptions.read,
          label: t('pots.inheritancePot.investmentFunds.review.confirmChoice.options.read', 'Read'),
        },
      ],
      [t]
    )
    const confirmChoiceRules = useMemo(
      () => ({
        validate: (selectedOptions?: ConfirmChoiceOptions[]) => selectedOptions?.length === options.length,
      }),
      [options.length]
    )

    const columnHeaders = useMemo(() => [{ content: '', width: '25%' }, { content: '' }], [])
    const memoizedRows = useMemo(
      () => [
        {
          rowHeader: { content: t('pots.inheritancePot.investmentFunds.review.columns.currentValue', 'Current') },
          cells: [{ content: currentFund?.name }],
        },
        {
          rowHeader: { content: t('pots.inheritancePot.investmentFunds.review.columns.newValue', 'New') },
          cells: [{ content: newSelectedFund.name }],
        },
      ],
      [currentFund?.name, newSelectedFund.name, t]
    )

    return (
      <FormProvider {...methods}>
        <PageTemplate
          fullWidth
          title={t('pots.inheritancePot.investmentFunds.review.title', 'Review')}
          onQuit={showQuitModal}
          loading={updatePotFundLoading}
        >
          {isMobile && <Divider bottom="large" top="large" />}
          <Table columnHeaders={columnHeaders} rows={memoizedRows} />
          <Divider bottom="extraLarge" />
          <Spacer bottom="large">
            <FormOptionsGroup
              fullWidth
              label={t(
                'pots.inheritancePot.investmentFunds.review.confirmChoice.label',
                'Confirm that you understand your choice'
              )}
              name="confirmChoice"
              options={options}
              multiple
              rules={confirmChoiceRules}
            />
          </Spacer>
          <NavButtons
            onNext={onContinueClick}
            onBack={onCancelClick}
            disableNext={!isValid}
            nextText={t('pots.inheritancePot.investmentFunds.complete', 'Complete')}
            backText={t('pots.inheritancePot.investmentFunds.back', 'Back')}
          />
        </PageTemplate>
        <ThankYouModalContainer>
          <ThankYouModal />
        </ThankYouModalContainer>
        <QuitModal />
      </FormProvider>
    )
  },
  ['newSelectedFund']
)
