import { useThemeContext } from '@retire/hooks/useThemeContext'
import { HeadlineLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Col, Row, Visible } from 'react-grid-system'

import { Divider } from '../Divider'
import { Icon } from '../Icon'
import { Link } from '../Link'
import { Notification } from '../Notification'
import { Segment } from '../Segment'
import { Spacer } from '../Spacer'
import { Headline } from '../Typography'
import { SSectionName, SSectionNameWrapper, SStepNumber, SStepNumberWrapper } from './index.styled'
import type { TOverview } from './types'

export const Overview: FC<TOverview> = ({
  title,
  sections,
  forceExpanded,
  expandText,
  collapseText,
  notification,
  expanded: controlledExpanded,
  onClick: controlledOnClick,
}) => {
  const [expanded, setExpanded] = useState(forceExpanded || controlledExpanded || false)
  const {
    colors: { white },
  } = useThemeContext()

  useEffect(() => {
    controlledExpanded !== undefined && setExpanded(forceExpanded || controlledExpanded)
  }, [controlledExpanded, forceExpanded])

  const onClick = useCallback(() => {
    if (controlledOnClick) {
      controlledOnClick()
    } else {
      setExpanded(previous => !previous)
    }
  }, [controlledOnClick])

  return (
    <Segment whiteBackground data-cy="overview">
      {expanded && (
        <>
          <Headline level={HeadlineLevel.h2} as="h4">
            {title}
          </Headline>
          {notification && (
            <Spacer top="medium">
              <Notification {...notification} />
            </Spacer>
          )}
          <Spacer bottom="extraLarge" />
        </>
      )}
      {sections.map(
        ({ key, name, isStep: isVisible, isInProgress, isCompleted, details, wrapOnDesktop = false }, idx, array) => {
          if (!expanded && idx !== array.length - 1) return null

          const stepNumber = array.filter(({ isStep }) => !!isStep).findIndex(section => section.name === name) + 1

          return (
            <Fragment key={key}>
              <Spacer top={expanded ? 'medium' : 'none'} bottom="medium">
                <Row data-cy={key}>
                  <Col xs={12} lg={wrapOnDesktop ? 12 : 3}>
                    <SSectionNameWrapper>
                      {expanded && (
                        <SStepNumberWrapper>
                          <SStepNumber isVisible={isVisible} isInProgress={isInProgress} isCompleted={isCompleted}>
                            {isCompleted ? (
                              <Icon iconType="Check" iconSize="micro" iconColors={{ base: white }} />
                            ) : (
                              stepNumber
                            )}
                          </SStepNumber>
                        </SStepNumberWrapper>
                      )}
                      <SSectionName>{name}</SSectionName>
                    </SSectionNameWrapper>
                  </Col>
                  {details && (
                    <Col xs={12} lg={wrapOnDesktop ? 12 : 9}>
                      <Visible xs sm md lg={wrapOnDesktop} xl={wrapOnDesktop}>
                        <Spacer top="medium" />
                      </Visible>
                      {details}
                    </Col>
                  )}
                </Row>
              </Spacer>
              {idx !== array.length - 1 && <Divider />}
            </Fragment>
          )
        }
      )}
      {!forceExpanded && expandText && collapseText && (
        <Link as="a" onClick={onClick}>
          {!expanded ? expandText : collapseText}
        </Link>
      )}
    </Segment>
  )
}
