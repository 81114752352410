import { useTranslationWithOptions } from '@retire/hooks'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

import type { TSaveAndExit } from './types'

export const SaveAndExit: FC<TSaveAndExit> = ({ onNext, onCancel }) => {
  useActivatePageOverlay()
  const { t } = useTranslationWithOptions('illustration')

  return (
    <PageTemplate
      title={t('saveAndExit.title', 'Save & Exit')}
      subTitle={t('saveAndExit.subTitle', 'Do you confirm?')}
      onNext={onNext}
      nextText={t('saveAndExit.actions.confirm', 'Confirm')}
      onBack={onCancel}
      backText={t('saveAndExit.actions.cancel', 'Cancel')}
      fullWidth
    />
  )
}
