import { colors } from './colors'
import { spacing } from './spacing'
import { utilities } from './utilities'

export const addressLookup = {
  border: {
    ...utilities.border,
    color: colors.secondary,
  },
  select: {
    fontSize: '1.125rem',
    marginTop: spacing.medium,
  },
  results: {
    alternateBackgroundColor: colors.rowHover,
    backgroundColor: 'white',
    borderColor: colors.neutral30,
    height: '347px',
    scrollbar: utilities.scrollbar,
  },
}
