import { paths } from '@retire/navigation/paths'
import { matchPath, useLocation } from 'react-router-dom'

import { usePageOverlay } from '../usePageOverlay'

export const useNoFooterPage = () => {
  const { pathname } = useLocation()
  const { isOverlay } = usePageOverlay()
  const noFooterPathPatterns: string[] = [paths.illustration.journey]

  return isOverlay || noFooterPathPatterns.some(pathPattern => matchPath(pathPattern, pathname))
}
