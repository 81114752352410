import type { TSpacing } from '@retire/types'

export const spacing: Record<TSpacing, string> = {
  extraExtraLarge: '84px',
  extraLarge: '32px',
  large: '24px',
  medium: '16px',
  mediumSmall: '12px',
  small: '8px',
  extraSmall: '4px',
  none: '0px',
}
