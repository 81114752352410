import { LabelValue } from '@retire/components/LabelValue/presentation'
import { UnorderedList } from '@retire/components/List'
import { RiskRating } from '@retire/components/RiskRating/presentation'
import { Copy } from '@retire/components/Typography'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { StyleWeight } from '@retire/theme'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { usePlanContext } from '../../../hooks/usePlanContext'
import { Bold } from '../Common/Bold/presentation'
import { HeadingThree } from '../Common/HeadingThree/presentation'
import { Paragraph } from '../Common/Paragraph/presentation'
import { Section } from '../Common/Section/presentation'
import { SubSection } from '../Common/SubSection/presentation'
import type { TFlexibleIncomePot } from './types'

export const FlexibleIncomePot: FC<TFlexibleIncomePot> = ({ fundDetails: { name, riskLevel, description } }) => {
  const { t } = useTranslationWithOptions('plan')
  const {
    annualPensionContributionAllowance: { amount: annualPensionContributionAllowanceAmount },
    moneyPurchaseAnnualAllowance: { amount: moneyPurchaseAnnualAllowanceAmount },
  } = useSettings()
  const {
    plan: { flexibleIncomeAmount, regularIncomeStartAge, regularIncomeEndAge, flexibleIncomeMonthlyAmount },
  } = usePlanContext()

  return (
    <Section>
      <SubSection>
        <Paragraph>
          {t('flexibleIncomePot.description.paragraphOne', 'Flexible Income pot - description (p1)')}
        </Paragraph>
        <Paragraph>
          {t('flexibleIncomePot.description.paragraphTwo', 'Flexible Income pot - description (p2)')}
        </Paragraph>
        <Paragraph>
          {t('flexibleIncomePot.description.paragraphThree', 'Flexible Income pot - description (p3)')}
        </Paragraph>
      </SubSection>
      <SubSection>
        <HeadingThree>
          {t('flexibleIncomePot.monthlyIncome.heading', 'Flexible Income pot - subheading (p0)')}
        </HeadingThree>
        <Copy as="div">
          <Trans
            components={{
              list: <UnorderedList />,
              listItem: <li />,
            }}
          >
            {t('flexibleIncomePot.monthlyIncome.description', 'Flexible Income pot - taking a monthly income list')}
          </Trans>
        </Copy>
        <Copy as="p" weight={StyleWeight.bold}>
          {t(
            'flexibleIncomePot.monthlyIncome.mpaa.heading',
            'Flexible Income pot - MoneyPurchaseAnnualAllowance heading'
          )}
        </Copy>
        <Copy as="div">
          <Trans
            components={{
              list: <UnorderedList />,
              listItem: <li />,
            }}
          >
            {t(
              'flexibleIncomePot.monthlyIncome.mpaa.description',
              'Flexible Income pot - MoneyPurchaseAnnualAllowance amount = {{moneyPurchaseAnnualAllowanceAmount}}',
              {
                moneyPurchaseAnnualAllowanceAmount: toCurrency(moneyPurchaseAnnualAllowanceAmount, 0),
                annualPensionContributionAllowanceAmount: toCurrency(annualPensionContributionAllowanceAmount, 0),
              }
            )}
          </Trans>
        </Copy>
      </SubSection>
      <SubSection>
        <HeadingThree>
          {t('flexibleIncomePot.youHaveAskedFor.heading', 'Flexible Income pot - amount heading')}
        </HeadingThree>
        <LabelValue
          label={t('flexibleIncomePot.youHaveAskedFor.valueLabel', 'Flexible Income pot - amount')}
          value={toCurrency(flexibleIncomeAmount)}
        />
        <LabelValue
          label={t('flexibleIncomePot.youHaveAskedFor.monthlyIncomeLabel', 'Flexible Income pot - income')}
          value={toCurrency(flexibleIncomeMonthlyAmount, 0)}
          description={
            flexibleIncomeMonthlyAmount && (
              <Trans components={[<Bold key="bold" />]}>
                {t(
                  'flexibleIncomePot.youHaveAskedFor.monthlyIncomeAgeRange',
                  'Flexible Income pot - Starting from age {{regularIncomeStartAge}} and ending at age {{regularIncomeEndAge}}.',
                  { regularIncomeStartAge, regularIncomeEndAge }
                )}
              </Trans>
            )
          }
        />
        {!!flexibleIncomeMonthlyAmount && (
          <Paragraph>
            {t('flexibleIncomePot.youHaveAskedFor.description', 'Flexible Income pot - you will receive an income')}
          </Paragraph>
        )}
      </SubSection>
      <SubSection>
        <HeadingThree>
          {t(
            'flexibleIncomePot.howYourMoneyIsInvested.heading',
            'Flexible Income pot - how your money is invested heading'
          )}
        </HeadingThree>
        <Paragraph>
          <Trans components={[<Bold key="bold" />]}>
            {t(
              'flexibleIncomePot.howYourMoneyIsInvested.description',
              'Flexible Income pot - how your money is invested (p1) in {{fundName}}',
              {
                fundName: name,
              }
            )}
          </Trans>
        </Paragraph>
        <Paragraph>{description}</Paragraph>
      </SubSection>
      <SubSection>
        <RiskRating rating={riskLevel} />
      </SubSection>
    </Section>
  )
}
