import { isEqual } from 'lodash'
import { useCallback, useState } from 'react'

import { DEFAULT_ILLUSTRATION_PLAN_CONFIGURABLE_DATA } from '../../context/IllustrationPlanDataContext/constants'
import type { TIllustrationPlanData } from '../../types'
import type { TUseRemoveSubsequentIllustrationJourneyStepsData } from './types'

// @TODO: review this hook implementation + usage https://smartpension.atlassian.net/browse/CM-1332
export const useRemoveSubsequentIllustrationJourneyStepsData: TUseRemoveSubsequentIllustrationJourneyStepsData = ({
  illustrationPlanData,
  savePartialIllustrationPlanData,
  resetIllustrationPlanData,
}) => {
  const [hasRemovedSubsequentIllustrationJourneyStepsData, setHasRemovedSubsequentIllustrationJourneyStepsData] =
    useState(false)
  const removeSubsequentIllustrationJourneyStepsData = useCallback(
    async (dataToRemove: Partial<TIllustrationPlanData> = DEFAULT_ILLUSTRATION_PLAN_CONFIGURABLE_DATA) => {
      const newData = { ...illustrationPlanData, ...dataToRemove }
      if (!isEqual(newData, illustrationPlanData)) {
        if (isEqual(dataToRemove, DEFAULT_ILLUSTRATION_PLAN_CONFIGURABLE_DATA)) {
          await resetIllustrationPlanData()
        } else {
          await savePartialIllustrationPlanData(newData)
        }
      }
      setHasRemovedSubsequentIllustrationJourneyStepsData(true)
    },
    [illustrationPlanData, resetIllustrationPlanData, savePartialIllustrationPlanData]
  )

  return {
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData: !hasRemovedSubsequentIllustrationJourneyStepsData,
  }
}
