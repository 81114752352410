import { useQuery } from '@apollo/client'
import { INIT_QUERY } from '@retire/gql/user/queries'
import { endSession, startSession } from '@retire/utils'
import { useEffect } from 'react'

export const useSessionCheck = () => {
  const { data, error, loading } = useQuery(INIT_QUERY)

  useEffect(() => {
    if (data) {
      startSession()
    } else if (error) {
      endSession()
    }
  }, [data, error])

  return { loading }
}
