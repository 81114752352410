import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { navigateToSignIn } from '@retire/utils/session'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const DeniedAuthentication: FC = () => {
  const { t } = useTranslationWithOptions('account')
  const navigate = useNavigate()

  const onNext = useCallback(() => {
    try {
      navigateToSignIn()
    } catch (error_) {
      navigate(paths.hygienePages.serverError)
    }
  }, [navigate])

  return (
    <PageTemplate
      title={t('deniedAuthentication.pageTitle', 'Denied authentication title')}
      titleIconType="Warning"
      subTitle={t('deniedAuthentication.description', 'Denied authentication description')}
      nextText={t('deniedAuthentication.actions.goBack', 'Denied authentication back')}
      onNext={onNext}
    />
  )
}
