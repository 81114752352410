import { LinkSize } from '@retire/constants/link'
import { useThemeContext } from '@retire/hooks/useThemeContext'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import type { FC } from 'react'
import { Container, Row } from 'react-grid-system'

import { Icon } from '../Icon'
import { Spacer } from '../Spacer'
import { SFundFactsheetLink } from './presentation.styled'
import type { TFundFactSheetLink } from './types'

export const FundFactSheetLink: FC<TFundFactSheetLink> = ({ url }) => {
  const { t } = useTranslationWithOptions('common')
  const {
    colors: { hyperlink },
  } = useThemeContext()

  return (
    <SFundFactsheetLink
      href={url}
      target="_blank"
      as="a"
      size={LinkSize.large}
      title={t('fundFactSheetLink.title', 'Fund fact sheet')}
    >
      <Container>
        <Row align="center">
          <Icon iconType="Pdf" iconColors={{ base: hyperlink }} />
          <Spacer left="small" />
          {t('fundFactSheetLink.title', 'Fund fact sheet')}
        </Row>
      </Container>
    </SFundFactsheetLink>
  )
}
