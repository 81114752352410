import type { FC } from 'react'
import { createContext, useMemo, useState } from 'react'

import type { TGlobalLoadingContext } from './types'

export const GlobalLoadingContext = createContext<TGlobalLoadingContext>({
  isGlobalLoading: false,
  activateGlobalLoading: () => null,
  deactivateGlobalLoading: () => null,
  title: '',
  subTitle: '',
})

export const GlobalLoadingProvider: FC = ({ children }) => {
  const [isGlobalLoading, setGlobalLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')

  const value = useMemo(
    () => ({
      isGlobalLoading,
      activateGlobalLoading: (newTitle = '', newSubTitle = '') => {
        if (!isGlobalLoading) {
          setGlobalLoading(true)
          setTitle(newTitle)
          setSubTitle(newSubTitle)
        }
      },
      deactivateGlobalLoading: () => {
        setGlobalLoading(false)
        setTitle('')
        setSubTitle('')
      },
      title,
      subTitle,
    }),
    [isGlobalLoading, subTitle, title]
  )

  return <GlobalLoadingContext.Provider value={value}>{children}</GlobalLoadingContext.Provider>
}
