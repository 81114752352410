import { Copy } from '@retire/components/Typography/Copy'
import { TaxFreeLumpSumWithdrawalState } from '@retire/constants/pots'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { TypographyColor } from '@retire/theme/colors'
import { StyleWeight } from '@retire/theme/utilities'
import { getTFLSAmount } from '@retire/utils/pots'
import type { FC } from 'react'

import { JourneyOverviewAmount } from '../Amount'
import { JourneyOverviewDetails } from '../Details'
import type { TTFLSStepDetails } from './types'

export const TFLSStepDetails: FC<TTFLSStepDetails> = ({ taxFreeLumpSumWithdrawalState, valuationAmount }) => {
  const { t } = useTranslationWithOptions('illustration')

  return (
    <JourneyOverviewDetails>
      {taxFreeLumpSumWithdrawalState === TaxFreeLumpSumWithdrawalState.untaken ? (
        <Copy as="span" weight={StyleWeight.bold} color={TypographyColor.primary}>
          {t('overview.steps.tfls.details.notTaken', 'Not taken')}
        </Copy>
      ) : (
        <JourneyOverviewAmount amount={getTFLSAmount(valuationAmount, taxFreeLumpSumWithdrawalState)} />
      )}
    </JourneyOverviewDetails>
  )
}
