import { Divider } from '@retire/components'
import { styled } from '@retire/theme/styledComponents'

export const SSection = styled.section`
  > * {
    max-width: 720px;
  }
  ${Divider} {
    max-width: initial;
  }
`
