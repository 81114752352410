import { TEST_IDS } from '@retire/constants'
import { ButtonStyleType } from '@retire/constants/button'
import { useBooleanState, useThemeContext, useTranslationWithOptions } from '@retire/hooks'
import { HeadlineLevel } from '@retire/theme'
import { preventDefault } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Container, Row } from 'react-grid-system'

import { Button } from '../Button'
import { Divider } from '../Divider'
import { OptionsGroup } from '../Forms/OptionsGroup/presentation'
import { Icon } from '../Icon'
import { Link } from '../Link'
import { Spacer } from '../Spacer'
import { Headline } from '../Typography'
import { SDropdownContent, SOverlay } from './index.styled'
import type { TMobileDropdown } from './types'

export const MobileDropdown: FC<TMobileDropdown> = ({
  renderSelectedValue,
  value: selectedValue,
  placeholder,
  onChange,
  options,
  title,
  name,
}) => {
  const { t } = useTranslationWithOptions('common')
  const {
    colors: { hyperlink },
  } = useThemeContext()

  const [isOpen, open, close] = useBooleanState()
  const [internalValue, setInternalValue] = useState(selectedValue)
  const isControlled = useMemo(() => !!selectedValue, [selectedValue])

  useEffect(() => {
    isControlled && setInternalValue(undefined)
  }, [isControlled])

  const onSelect = useCallback(
    value => {
      onChange && onChange(value)
      !isControlled && setInternalValue(value)
      close()
    },
    [onChange, isControlled, close]
  )

  const value = useMemo(() => selectedValue || internalValue, [internalValue, selectedValue])
  const optionsMap = useMemo(
    () => Object.fromEntries(options.map(({ value: optionValue, label }) => [optionValue, label])),
    [options]
  )

  return (
    <>
      <Container fluid>
        <div onClick={open} data-testid={TEST_IDS.components.mobileDropdownLabel}>
          <Row justify="between">
            {renderSelectedValue && value ? (
              renderSelectedValue(value)
            ) : (
              <Link onClick={preventDefault} bold inline as="button">
                {value ? optionsMap[value] : placeholder}
              </Link>
            )}

            <Icon iconType="ArrowRight" iconColors={{ base: hyperlink }} />
          </Row>
        </div>
      </Container>
      <Spacer bottom="mediumSmall" />
      <Divider />
      <SOverlay isOpen={isOpen} ariaHideApp={false}>
        <SDropdownContent>
          <Headline as="h1" level={HeadlineLevel.h1}>
            {title}
          </Headline>
          <Spacer bottom="large" />
          <OptionsGroup onChange={onSelect} name={name} value={value} options={options} />
          <Spacer top="large">
            <Button buttonStyleType={ButtonStyleType.secondary} onClick={close}>
              {t('actions.back', 'Back')}
            </Button>
          </Spacer>
        </SDropdownContent>
      </SOverlay>
    </>
  )
}
