import type { Breakpoint, theme } from '@retire/theme'
import type { ThemedStyledProps } from '@retire/theme/styledComponents'
import { kebabCase } from 'lodash'
import type { CSSProperties } from 'react'

import type { TMediaStylesOptions } from './types'

export const cssStyles = (style: CSSProperties) =>
  Object.keys(style).reduce((acc, key) => {
    const cssKey = kebabCase(key)
    const cssValue = style[key].replace("'", '')
    return `${acc}${cssKey}:${cssValue}; `
  }, '')

// Create "@media (min-width: XXpx)" or "@media (max-width: XXpx)" query
// This method needs to be encapsulated inside
// - a ThemedCssFunction (css)
// - a ThemedStyledFunction (styled)
export const mediaStyles =
  (style: CSSProperties, { min, max, selector }: TMediaStylesOptions) =>
  ({ theme: { breakpoints } }: ThemedStyledProps<unknown, typeof theme>) =>
    `
      @media (${min ? 'min' : 'max'}-width: ${breakpoints[(min || max) as Breakpoint]}px) {
        ${selector ? `${selector} {` : ''} ${cssStyles(style)}; ${selector ? '}' : ''}
      }
    `
