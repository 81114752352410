import { AuthOutlet } from '@retire/navigation/AuthOutlet'
import { paths, roots } from '@retire/navigation/paths'
import type { RouteObject } from 'react-router-dom'

import { JourneyAuthOutlet } from './components/Journey/JourneyAuthOutlet'
import { IllustrationPlanDataProvider } from './context/IllustrationPlanDataContext'
import { JourneyOverviewProvider } from './context/JourneyOverviewContext'
import { ContinueOrRestart } from './pages/Journey/ContinueOrRestart'
import { FlexibleIncomePotConfiguration } from './pages/Journey/IncomePots/FlexibleIncomePotConfiguration'
import { FlexibleIncomePotInsufficientSavings } from './pages/Journey/IncomePots/FlexibleIncomePotInsufficientSavings'
import { FlexibleIncomePotIntro } from './pages/Journey/IncomePots/FlexibleIncomePotIntro'
import { Intro as IncomePotsIntro } from './pages/Journey/IncomePots/Intro'
import { LaterLifePotConfiguration } from './pages/Journey/IncomePots/LaterLifePotConfiguration'
import { LaterLifePotInsufficientSavings } from './pages/Journey/IncomePots/LaterLifePotInsufficientSavings'
import { LaterLifePotIntro } from './pages/Journey/IncomePots/LaterLifePotIntro'
import { Intro } from './pages/Journey/Intro'
import { PotsIntro } from './pages/Journey/PotsIntro'
import { Ready } from './pages/Journey/Ready'
import { InheritancePotConfiguration } from './pages/Journey/SavingsPots/InheritancePotConfiguration'
import { InheritancePotFunds } from './pages/Journey/SavingsPots/InheritancePotFunds'
import { InheritancePotInsufficientSavings } from './pages/Journey/SavingsPots/InheritancePotInsufficientSavings'
import { InheritancePotIntro } from './pages/Journey/SavingsPots/InheritancePotIntro'
import { Intro as SavingsPotsIntro } from './pages/Journey/SavingsPots/Intro'
import { RainyDayPotConfiguration } from './pages/Journey/SavingsPots/RainyDayPotConfiguration'
import { RainyDayPotInsufficientSavings } from './pages/Journey/SavingsPots/RainyDayPotInsufficientSavings'
import { RainyDayPotIntro } from './pages/Journey/SavingsPots/RainyDayPotIntro'
import { TaxFreeLumpSum } from './pages/Journey/TaxFreeLumpSum'
import { YourIllustration } from './pages/YourIllustration'
import { YourPlanInFull } from './pages/YourPlanInFull'

export const illustrationRoutes: RouteObject[] = [
  {
    path: roots.illustration,
    element: <AuthOutlet />,
    children: [
      {
        path: paths.illustration.journey,
        element: (
          <IllustrationPlanDataProvider>
            <JourneyOverviewProvider>
              <JourneyAuthOutlet />
            </JourneyOverviewProvider>
          </IllustrationPlanDataProvider>
        ),
        children: [
          { path: paths.illustration.journeyContinueOrRestart, element: <ContinueOrRestart /> },
          { path: paths.illustration.journeyIntro, element: <Intro /> },
          { path: paths.illustration.journeyTaxFreeLumpSumWithdrawal, element: <TaxFreeLumpSum /> },
          { path: paths.illustration.journeyPotsIntro, element: <PotsIntro /> },
          { path: paths.illustration.journeyIncomePotsIntro, element: <IncomePotsIntro /> },
          {
            path: paths.illustration.journeyFlexibleIncomePotInsufficientSavings,
            element: <FlexibleIncomePotInsufficientSavings />,
          },
          {
            path: paths.illustration.journeyFlexibleIncomePotIntro,
            element: <FlexibleIncomePotIntro />,
          },
          {
            path: paths.illustration.journeyFlexibleIncomePotConfiguration,
            element: <FlexibleIncomePotConfiguration />,
          },
          {
            path: paths.illustration.journeyLaterLifePotInsufficientSavings,
            element: <LaterLifePotInsufficientSavings />,
          },
          { path: paths.illustration.journeyLaterLifePotIntro, element: <LaterLifePotIntro /> },
          {
            path: paths.illustration.journeyLaterLifePotConfiguration,
            element: <LaterLifePotConfiguration />,
          },
          { path: paths.illustration.journeySavingsPotsIntro, element: <SavingsPotsIntro /> },
          {
            path: paths.illustration.journeyInheritancePotInsufficientSavings,
            element: <InheritancePotInsufficientSavings />,
          },
          { path: paths.illustration.journeyInheritancePotIntro, element: <InheritancePotIntro /> },
          {
            path: paths.illustration.journeyInheritancePotConfiguration,
            element: <InheritancePotConfiguration />,
          },
          {
            path: paths.illustration.journeyInheritancePotFunds,
            element: <InheritancePotFunds />,
          },
          {
            path: paths.illustration.journeyRainyDayPotInsufficientSavings,
            element: <RainyDayPotInsufficientSavings />,
          },
          { path: paths.illustration.journeyRainyDayPotIntro, element: <RainyDayPotIntro /> },
          {
            path: paths.illustration.journeyRainyDayPotConfiguration,
            element: <RainyDayPotConfiguration />,
          },
          { path: paths.illustration.journeyReady, element: <Ready /> },
        ],
      },
      {
        path: paths.illustration.yourIllustration,
        element: (
          <IllustrationPlanDataProvider>
            <YourIllustration />
          </IllustrationPlanDataProvider>
        ),
      },
      {
        path: paths.illustration.yourPlanInFull,
        element: <YourPlanInFull />,
      },
    ],
  },
]
