import { ApolloClient, ApolloLink } from '@apollo/client'
import { isDev } from '@retire/utils'

import { retireGQLCache } from './cache'
import { afterwareLink, countersLink, errorLink, httpLink, processingLink } from './links'

export const retireGQLClient = new ApolloClient({
  cache: retireGQLCache,
  link: ApolloLink.from([processingLink, errorLink, countersLink, afterwareLink, httpLink]), // links order is important
  connectToDevTools: isDev(), // "Apollo Client Developer Tools" chrome extension
  defaultOptions: {
    mutate: {
      errorPolicy: 'none',
      awaitRefetchQueries: true, // avoid some "React state update on an unmounted component" errors
    },
  },
})
