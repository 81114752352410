import { useCallback, useState } from 'react'

import type { TUseBooleanStateReturn } from './types'

export const useBooleanState = (defaultState = false): TUseBooleanStateReturn => {
  const [state, setState] = useState(defaultState)
  const setTrue = useCallback(() => setState(true), [])
  const setFalse = useCallback(() => setState(false), [])

  return [state, setTrue, setFalse]
}
