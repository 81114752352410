import { Loading } from '@retire/components/Loading'
import type { ReactElement } from 'react'
import { cloneElement } from 'react'
import { matchPath, Navigate, Outlet, useLocation } from 'react-router-dom'

import { useValidPathsForState } from '../hooks/useValidPathsForState'
import { mainRootPath } from '../paths'

/**
 * This component manages:
 * - redirecting unlogged user to login page
 * - if current path is accessible to current user state: authorize its access
 * - if current path is not accessible to current user state: redirect user to 1st accessible path
 */
export const AuthOutlet = ({ children }: { children?: ReactElement }) => {
  const { pathname } = useLocation()
  const { error, loading, validPaths } = useValidPathsForState()
  const allowedPath = validPaths && validPaths.find(pathPattern => matchPath(pathPattern, pathname))

  if (loading) {
    return <Loading />
  }

  if (allowedPath) {
    if (children) {
      return cloneElement(children, { children: <Outlet /> })
    }
    return <Outlet />
  }

  // redirect user to the first accessible path in the journey
  if (validPaths) {
    return <Navigate to={validPaths[0]} replace />
  }

  if (!error) {
    return <Navigate to={mainRootPath} replace />
  }

  return null
}
