import './setupGrid'
import './i18n'

import { ApolloProvider } from '@apollo/client'
import { Loading } from '@retire/components'
import { retireGQLClient } from '@retire/gql'
import { RetireRouter } from '@retire/navigation'
import { theme } from '@retire/theme'
import { brightTheme } from '@retire/theme/bright'
import { GlobalStyle, ThemeProvider } from '@retire/theme/styledComponents'
import { rollbar } from '@retire/utils'
import { Provider as RollbarProvider } from '@rollbar/react'
import { BrightProvider } from '@smartpension/bright__experimental'
import { memo, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <RollbarProvider instance={rollbar} config={{}}>
      <BrightProvider theme={brightTheme}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={retireGQLClient}>
            <GlobalStyle />
            <Suspense fallback={<Loading withLogo />}>
              <BrowserRouter>
                <RetireRouter />
              </BrowserRouter>
            </Suspense>
          </ApolloProvider>
        </ThemeProvider>
      </BrightProvider>
    </RollbarProvider>
  )
}

const MemoizedApp = memo(App)

ReactDOM.render(<MemoizedApp />, document.querySelector('#root') as HTMLElement)
