import { getDisplayName } from '@retire/utils'
import type { FC } from 'react'

import { useConfirmMoveMoneyParams } from '../../../../components/ConfirmMoveMoneyRequest'
import { DashboardNotFound } from '../../../DashboardNotFound'
import type { TConfirmMoveMoneyRequest } from '../../types'

export const withValidUrlParams = (Component: FC<TConfirmMoveMoneyRequest>) => {
  const Wrapped = () => {
    const { fromPot, toPotOrAccount, amount } = useConfirmMoveMoneyParams()

    if (!fromPot || !toPotOrAccount || !amount) {
      return <DashboardNotFound />
    }
    return <Component fromPot={fromPot} toPotOrAccount={toPotOrAccount} amount={amount} />
  }

  Wrapped.displayName = `WithUrlParamsProtected(${getDisplayName(Component)})`
  return Wrapped
}
