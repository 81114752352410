import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'
import Modal from 'react-modal'

export const ModalWrapper = styled(Modal)`
  position: absolute;
  inset: 50% auto auto 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  pointer-events: auto;
  outline: none;
`

export const ModalBox = styled.div<{ isPlain: boolean }>`
  ${({
    isPlain,
    theme: {
      modal: {
        standardModal: { borderSize, borderColor, borderRadius, backgroundColor, paddingDesktop, paddingTouch },
      },
    },
  }) =>
    isPlain
      ? ''
      : css`
          border: ${borderSize} solid ${borderColor};
          border-radius: ${borderRadius};
          background-color: ${backgroundColor};
          position: relative;
          padding: ${paddingDesktop};
          &:focus {
            outline: none;
          }

          ${mediaStyles({ padding: paddingTouch }, { max: Breakpoint.small })}
        `}
`
