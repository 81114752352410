import { Button, Headline, Icon, Spacer } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'

import { DEFAULT_PROPS } from './constants'
import { SLayout } from './index.styled'
import type { TThankYouModalContent } from './types'

export const ThankYouModalContent: FC<TThankYouModalContent> = ({
  buttonText,
  iconType = DEFAULT_PROPS.iconType,
  onButtonClick,
  title,
}) => (
  <Spacer left="medium" right="medium">
    <SLayout justifyContent="center" alignItems="center">
      <Icon iconType={iconType} iconSize="large" />
      <Spacer top="large" bottom="large">
        <Headline level={HeadlineLevel.h1} as="h1">
          {title}
        </Headline>
      </Spacer>
      <Button onClick={onButtonClick} buttonStyleType={ButtonStyleType.primary}>
        {buttonText}
      </Button>
    </SLayout>
  </Spacer>
)
