import { styled } from '@retire/theme/styledComponents'

export const SBeforeYouJoin = styled.div`
  p {
    margin: ${props => props.theme.spacing.medium} 0;
  }

  ul {
    margin-left: ${props => props.theme.spacing.extraLarge};
    list-style-type: disc;

    li {
      margin-bottom: ${props => props.theme.spacing.mediumSmall};
    }
  }
`
