import { PageTitle, Spacer } from '@retire/components'
import { DateFormat, PotName } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { useGreetingLabel } from '@retire/hooks/useGreetingLabel'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { HeadlineLevel } from '@retire/theme'
import { formatDate, toCurrency } from '@retire/utils'
import type { FC } from 'react'

import { OverviewCard } from '../../components/OverviewCard/presentation'
import { PotManagementListItem } from '../../components/PotManagementList/PotManagementListItem'
import { useBoardNotifications } from '../../hooks/useBoardNotifications'
import { usePotsContext } from '../../hooks/usePotsContext'

export const Board: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const { totalPotsAmount } = usePotsContext()
  const { loadingBoardNotifications } = useBoardNotifications()
  const titleLabel = useGreetingLabel()

  return (
    <PageTemplate title={titleLabel} bottom="large" fullWidth loading={loadingBoardNotifications}>
      <Spacer top="large">
        <OverviewCard
          amount={toCurrency(totalPotsAmount)}
          date={t('overviewCard.date', {
            date: formatDate(new Date().getTime(), DateFormat.longDate),
          })}
        />
      </Spacer>

      <Spacer top="large" bottom="medium">
        <PageTitle title={t('management.title', 'Management title')} headlineAs="h2" headlineLevel={HeadlineLevel.h2} />
      </Spacer>
      <PotManagementListItem potName={PotName.flexibleIncome} />
      <PotManagementListItem potName={PotName.laterLife} />
      <PotManagementListItem potName={PotName.inheritance} />
      <PotManagementListItem potName={PotName.rainyDay} />
    </PageTemplate>
  )
}
