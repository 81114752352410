import { Copy, Icon, Spacer } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { StyleWeight, TypographyColor } from '@retire/theme'
import { useCallback, useState } from 'react'

import { Menu } from './Menu'
import { SIconButton } from './presentation.styled'

export const HamburgerMenu = () => {
  const { t } = useTranslationWithOptions('common')

  const [isOpen, setIsOpen] = useState(false)
  const onOpen = useCallback(() => setIsOpen(true), [])
  const onClose = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <SIconButton onClick={onOpen}>
        <Copy as="span" weight={StyleWeight.bold} color={TypographyColor.primary}>
          {t('nav.menu', 'Menu')}
        </Copy>
        <Spacer right="mediumSmall" />
        <Icon iconType="HamburgerMenu" />
      </SIconButton>
      <Menu isOpen={isOpen} onClose={onClose} />
    </>
  )
}
