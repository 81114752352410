import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

export const MercerLogo: FC<TIconColors> = props => {
  const {
    colors: { primary, mercer },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg viewBox="0 0 1100 119" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Mercer - Smart Retire</title>
      <g>
        <g>
          <path
            fill={mercer}
            d="M93,22.5c9.39,5.34,18.79,10.66,28.14,16.06,2.11,1.21,3.22,3.26,3.23,5.69.05,10.11.05,20.21,0,30.32-.01,2.57-1.25,4.61-3.47,5.89-8.63,5.02-17.28,10-25.95,14.96-2.12,1.21-4.51,1.36-6.56.23-6.46-3.54-12.8-7.31-19.46-11.14,12.72-9.75,25.29-19.38,37.85-29.01-.05-.1-.1-.2-.15-.3-.51.28-1.03.55-1.54.84-22.54,12.98-45.08,25.97-67.64,38.92-1.26.72-2.75,1.04-4.13,1.55h-.33c-1.62-.64-3.34-1.12-4.84-1.96-7.38-4.16-14.65-8.51-22.07-12.61-2.89-1.6-5.23-3.45-6.09-6.76v-31.33c.77-3.34,2.97-5.42,5.99-6.79,1-.46,1.92-1.1,2.88-1.64,7.6-4.3,15.2-8.6,22.79-12.89,1,0,2,0,3,0,6.95,3.89,13.9,7.78,21.07,11.79-12.71,9.75-25.23,19.34-37.74,28.93.69.02,1.22-.22,1.72-.51,17.35-9.99,34.68-20,52.04-29.97,6.05-3.47,12.16-6.83,18.24-10.24,1,0,2,0,3,0Z"
          />
          <path
            fill={mercer}
            d="M169.37,92.31h-11.9V26.77c1.98,0,3.96-.02,5.93,0,2.27.03,4.91-.59,6.69.37,1.72.93,2.56,3.5,3.76,5.36,5.94,9.24,11.87,18.49,17.81,27.74.33.51.68,1,1.14,1.68,2.29-3.52,4.49-6.89,6.68-10.27,5.11-7.87,10.23-15.72,15.3-23.61.62-.96,1.29-1.32,2.41-1.3,3.54.07,7.08.02,10.74.02v65.5h-11.64v-46.68c-.15-.06-.3-.12-.45-.18-7.67,11.86-15.34,23.72-23.16,35.81-7.77-12.03-15.39-23.84-23.02-35.65l-.31.07v46.66Z"
          />
          <path
            fill={mercer}
            d="M392.78,70.57c.87,5.04,3.16,8.75,7.41,10.98,5.95,3.13,12.24,2.99,18.45,1.06,3.24-1.01,6.26-2.73,9.45-4.16,0,.06.11.4.12.74.02,2.17-.14,4.35.05,6.5.17,1.89-.55,2.9-2.16,3.78-8.85,4.83-18.11,5.83-27.68,2.84-10.46-3.27-16.7-11.9-17.04-22.97-.25-8.15,1.66-15.53,7.63-21.45,8.8-8.7,24.16-9.39,33.13-1.43,5.47,4.86,7.46,11.25,7.58,18.36q.1,5.76-5.65,5.76c-9.67,0-19.33,0-29,0-.66,0-1.32,0-2.3,0ZM418.63,62.85c-.18-7.8-4.8-12.71-12-12.95-9.19-.3-13.33,6.89-13.69,12.95h25.69Z"
          />
          <path
            fill={mercer}
            d="M286.51,70.59h-36.43c.34,5.91,5.12,11.18,11.38,12.58,7.88,1.77,15.19.25,21.99-4.03.55-.34,1.09-.69,1.88-1.19,0,3.35.04,6.44-.04,9.53-.01.42-.48.99-.89,1.23-10.89,6.37-22.16,7.27-33.5,1.53-7.44-3.77-11.24-10.35-12.14-18.56-.66-6-.1-11.87,2.7-17.34,4.83-9.43,14.02-13.96,24.16-13.32,14.69.93,22.24,12.73,21.22,26.74-.06.87-.19,1.74-.31,2.84ZM275.75,62.84c.24-7.77-5.38-13.21-13.1-12.95-8.05.27-12.45,7.14-12.46,12.95h25.57Z"
          />
          <path
            fill={mercer}
            d="M374.32,80.73c0,3.24.03,6.23-.04,9.23,0,.43-.39,1.06-.77,1.25-9.16,4.48-23.84,3.71-31.71-4.35-4.37-4.47-6.5-9.88-7-15.96-.57-6.85.43-13.41,4.45-19.22,4.38-6.32,10.53-9.61,18.1-10.52,5.44-.65,10.58.37,15.6,2.38,1.04.42,1.43.93,1.4,2.07-.09,2.99-.03,5.99-.03,8.93-2.56-.87-5.01-1.85-7.55-2.53-3.2-.86-6.49-.89-9.71-.03-6.59,1.76-10.39,7.07-10.72,14.89-.35,8.2,3.82,14.42,10.97,16.18,3.79.93,7.6.77,11.34-.36,1.84-.56,3.65-1.25,5.67-1.96Z"
          />
          <path
            fill={mercer}
            d="M330.21,53.61c-2.33-.3-4.4-.7-6.49-.83-8.37-.51-13.9,4.03-14.35,12.41-.45,8.25-.28,16.54-.38,24.81,0,.72,0,1.43,0,2.26h-11.49v-49.58h11.08v9.49c1.43-1.97,2.55-3.9,4.02-5.49,4.55-4.92,10.29-5.72,16.52-4.26.43.1,1.03.74,1.04,1.15.08,3.32.05,6.65.05,10.04Z"
          />
          <path
            fill={mercer}
            d="M452.95,92.28h-11.5v-49.6h10.87v9.29c.11.04.22.08.33.12.22-.31.48-.61.65-.94,4.04-7.65,11.04-10.85,19.36-8.87.92.22,1.38.57,1.36,1.65-.08,3.21-.03,6.43-.03,9.65-2.34-.29-4.56-.71-6.8-.82-7.69-.4-12.68,3.7-13.87,11.34-.24,1.53-.35,3.09-.36,4.64-.04,7.22-.02,14.44-.02,21.66v1.9Z"
          />
        </g>
        <line x1="527.11" x2="527.11" y2="119" fill="none" stroke={base} strokeMiterlimit="10" />
        <g>
          <path
            fill={base}
            d="M916.79,35.62c0-1.7.85-2.55,2.56-2.55h14.73c2.69,0,4.78.12,6.28.35,1.36.19,2.7.57,3.96,1.12,2.59,1.14,4.77,3.03,6.28,5.42,1.55,2.43,2.33,5.39,2.33,8.9s-.86,6.63-2.59,9.36c-1.65,2.67-4.19,4.67-7.18,5.65v.15c.12.23.28.44.47.62.42.59.81,1.21,1.16,1.86l9.85,18.18c.51.88.61,1.6.27,2.17-.33.57-.99.85-1.98.85h-3.1c-1.45,0-2.46-.6-3.02-1.79l-10.7-19.96h-11.72v19.19c0,1.7-.85,2.55-2.56,2.55h-2.48c-1.71,0-2.56-.85-2.56-2.55v-49.51ZM935.4,59.29c3,0,5.37-.92,7.13-2.75,1.76-1.83,2.64-4.29,2.64-7.39,0-1.96-.43-3.69-1.28-5.19-.87-1.51-2.22-2.68-3.84-3.33-.8-.33-1.63-.57-2.48-.7-1.34-.18-2.68-.26-4.03-.23h-9.15v19.57h11.01Z"
          />
          <path
            fill={base}
            d="M956.18,68.11c0-3.1.5-5.91,1.51-8.43.93-2.4,2.33-4.6,4.11-6.46,1.76-1.8,3.87-3.21,6.2-4.14,2.48-.99,5.12-1.49,7.79-1.47,2.68,0,5.09.46,7.21,1.39,2.04.87,3.86,2.19,5.31,3.87,1.46,1.73,2.57,3.72,3.26,5.88.77,2.43,1.15,4.96,1.12,7.5,0,.34-.05.68-.18,1-.13.32-.31.61-.55.86-.24.26-.54.46-.87.59-.33.13-.68.2-1.03.18h-26.21c.06,1.95.51,3.87,1.32,5.65.72,1.58,1.75,3,3.02,4.18,1.22,1.13,2.66,1.99,4.23,2.55,1.6.57,3.3.86,5,.85,2.17,0,4.06-.35,5.66-1.04s2.84-1.38,3.72-2.05c1.55-.83,2.72-.54,3.49.85l.7,1.16c.41.67.55,1.3.43,1.9-.13.6-.53,1.1-1.2,1.51-.7.49-1.42.95-2.17,1.35-.96.53-1.96.98-2.99,1.35-1.21.44-2.46.79-3.72,1.05-1.44.29-2.91.43-4.38.42-3.1,0-5.92-.53-8.45-1.59-2.45-1-4.68-2.47-6.55-4.33-1.85-1.85-3.3-4.07-4.27-6.5-1.02-2.57-1.53-5.31-1.51-8.08ZM985.18,63.94c-.1-3.46-1.07-6.06-2.91-7.81-.87-.86-1.91-1.53-3.05-1.98-1.14-.45-2.35-.67-3.58-.65-2.9,0-5.39.92-7.48,2.75-2.09,1.83-3.42,4.4-3.99,7.7h21.01Z"
          />
          <path
            fill={base}
            d="M998.83,55.04h-2.72c-1.65,0-2.48-.85-2.48-2.55v-.99c0-1.7.85-2.55,2.56-2.55h2.79v-8.6c0-1.7.85-2.55,2.56-2.55h2.25c1.71,0,2.56.85,2.56,2.55v8.59h6.82c1.71,0,2.56.85,2.56,2.55v.99c0,1.7-.83,2.55-2.48,2.55h-6.9v17.41c0,1.86.26,3.35.78,4.49.42,1.01,1.07,1.91,1.9,2.63.7.59,1.52,1.03,2.4,1.28.77.22,1.57.36,2.37.43,1.03.1,1.74.34,2.13.7.39.36.58.98.58,1.86v1.64c0,1.7-1.04,2.55-3.1,2.55-1.89.01-3.78-.28-5.58-.85-1.71-.54-3.28-1.43-4.61-2.63-1.37-1.25-2.45-2.78-3.18-4.49-.79-1.81-1.19-3.95-1.19-6.42v-18.58Z"
          />
          <path
            fill={base}
            d="M1022.24,40.72c-1.71,0-2.56-.85-2.56-2.55v-2.55c0-1.7.85-2.55,2.56-2.55h2.63c1.71,0,2.56.85,2.56,2.55v2.55c0,1.7-.85,2.55-2.56,2.55h-2.63ZM1019.76,51.09c0-1.7.85-2.55,2.56-2.55h2.4c1.71,0,2.56.85,2.56,2.55v34.04c0,1.7-.85,2.55-2.56,2.55h-2.4c-1.71,0-2.56-.85-2.56-2.55v-34.04Z"
          />
          <path
            fill={base}
            d="M1034.42,51.09c0-1.7.85-2.55,2.56-2.55h2.17c1.7,0,2.55.85,2.56,2.55v4.49c0,.41-.01.8-.04,1.16-.02.31-.06.62-.12.93-.05.28-.08.56-.08.85h.15c.43-1.33,1.02-2.6,1.75-3.79.73-1.22,1.63-2.32,2.67-3.28,1.02-.95,2.19-1.73,3.45-2.32,1.3-.6,2.72-.9,4.15-.89,1.65,0,2.48.85,2.48,2.55v2.17c0,1.7-.93,2.55-2.79,2.55s-3.58.48-5,1.43c-1.43.96-2.65,2.21-3.57,3.67-1,1.58-1.71,3.32-2.13,5.15-.46,1.89-.7,3.82-.7,5.77v13.62c0,1.7-.85,2.55-2.56,2.55h-2.4c-1.7,0-2.56-.85-2.56-2.55v-34.05Z"
          />
          <path
            fill={base}
            d="M1054.58,68.11c0-3.1.5-5.91,1.51-8.43.93-2.4,2.33-4.6,4.11-6.46,1.76-1.8,3.87-3.21,6.2-4.14,2.48-.99,5.12-1.49,7.79-1.47,2.69,0,5.09.46,7.21,1.39,2.04.87,3.86,2.19,5.31,3.87,1.47,1.73,2.57,3.72,3.26,5.88.77,2.43,1.15,4.96,1.12,7.5,0,.34-.05.68-.18,1-.12.32-.31.61-.55.86-.24.26-.54.46-.87.59-.33.13-.68.2-1.03.18h-26.21c.06,1.95.51,3.87,1.32,5.65.72,1.58,1.75,3,3.02,4.18,1.22,1.13,2.66,1.99,4.23,2.55,1.61.57,3.3.86,5,.85,2.17,0,4.06-.35,5.66-1.04s2.84-1.38,3.72-2.05c1.55-.83,2.72-.54,3.49.85l.7,1.16c.41.67.55,1.3.43,1.9-.13.6-.53,1.1-1.2,1.51-.7.49-1.42.95-2.17,1.35-.96.53-1.96.98-2.99,1.35-1.21.44-2.46.79-3.72,1.05-1.44.29-2.91.43-4.38.42-3.1,0-5.92-.53-8.45-1.59-2.45-1-4.68-2.47-6.55-4.33-1.85-1.85-3.3-4.07-4.27-6.5-1.02-2.57-1.53-5.31-1.51-8.08ZM1083.59,63.94c-.1-3.46-1.07-6.06-2.91-7.81-.88-.86-1.91-1.53-3.05-1.98-1.14-.45-2.35-.67-3.58-.65-2.89,0-5.39.92-7.48,2.75-2.09,1.83-3.42,4.4-3.99,7.7h21.01Z"
          />
          <path
            fill={base}
            d="M704.76,82.97c-.48-.4-.82-.94-.97-1.55-.13-.57.09-1.24.66-2.01l1.32-1.86c.93-1.19,2.09-1.34,3.49-.46.46.36,1.08.79,1.86,1.28.86.53,1.75,1.01,2.68,1.43,1.09.49,2.22.89,3.37,1.19,1.32.35,2.67.51,4.03.5,2.95,0,5.29-.71,7.02-2.13,1.73-1.42,2.6-3.31,2.59-5.69,0-2.01-.65-3.66-1.94-4.95-1.44-1.39-3.09-2.55-4.89-3.44-1.96-1.01-4.08-1.98-6.35-2.93-2.21-.92-4.34-2.03-6.36-3.33-1.92-1.22-3.58-2.82-4.88-4.68-1.29-1.86-1.94-4.2-1.94-7.04-.04-2.11.39-4.19,1.28-6.1.89-1.91,2.2-3.59,3.84-4.92,1.68-1.36,3.6-2.39,5.66-3.06,2.25-.73,4.61-1.1,6.98-1.08,1.54,0,3.07.14,4.59.43,1.34.26,2.66.6,3.96,1.04,1.07.36,2.11.81,3.1,1.35.73.39,1.43.85,2.1,1.35,1.24.88,1.5,2.04.78,3.48l-1.09,1.93c-.46.83-1,1.25-1.59,1.28-.68,0-1.34-.2-1.9-.58-1.32-.76-2.68-1.44-4.07-2.05-1.68-.75-3.68-1.12-6.01-1.12-2.84,0-5.2.68-7.06,2.05-1.86,1.37-2.79,3.24-2.79,5.61,0,1.96.65,3.56,1.94,4.8,1.44,1.34,3.09,2.45,4.88,3.29,1.97.95,4.09,1.88,6.36,2.78,2.23.88,4.36,1.98,6.36,3.29,1.93,1.25,3.59,2.87,4.89,4.76,1.29,1.88,1.93,4.3,1.93,7.23.02,2.08-.38,4.14-1.16,6.07-.76,1.87-1.93,3.55-3.41,4.92-1.59,1.45-3.45,2.58-5.47,3.33-2.15.82-4.59,1.24-7.33,1.24-1.86,0-3.72-.19-5.55-.59-1.6-.35-3.17-.84-4.69-1.47-1.27-.53-2.51-1.15-3.69-1.86-.88-.52-1.72-1.1-2.51-1.74Z"
          />
          <path
            fill={base}
            d="M742.99,51.09c0-1.7.85-2.55,2.56-2.55h2.17c1.71,0,2.56.85,2.56,2.55v3.48c0,.52-.05,1.04-.16,1.55-.05.23-.08.46-.08.7h.16c.52-1.22,1.21-2.35,2.06-3.37.92-1.12,1.99-2.11,3.19-2.94,1.23-.86,2.55-1.56,3.96-2.09,1.42-.54,2.94-.82,4.46-.81,6.46,0,10.39,2.97,11.79,8.9h.16c.56-1.21,1.29-2.32,2.17-3.33.93-1.09,2-2.05,3.18-2.86,1.22-.84,2.55-1.5,3.96-1.97,1.47-.49,3.02-.74,4.57-.73,4.5,0,7.78,1.25,9.85,3.75,2.07,2.5,3.1,6.25,3.1,11.26v22.52c0,1.7-.85,2.55-2.56,2.55h-2.4c-1.71,0-2.56-.85-2.56-2.55v-20.88c0-1.29-.08-2.59-.27-3.87-.15-1.08-.49-2.13-1.01-3.1-.49-.88-1.22-1.6-2.09-2.09-.91-.51-2.09-.77-3.53-.77-1.71-.02-3.4.42-4.89,1.28-1.46.86-2.71,2.01-3.69,3.4-1.03,1.47-1.81,3.11-2.29,4.84-.51,1.79-.77,3.64-.78,5.49v15.71c0,1.7-.83,2.55-2.48,2.55h-2.48c-1.66,0-2.48-.85-2.48-2.55v-20.88c0-1.24-.07-2.48-.23-3.71-.13-1.09-.44-2.15-.93-3.13-.46-.91-1.17-1.67-2.06-2.19-.9-.53-2.11-.8-3.61-.8-1.75-.03-3.47.43-4.98,1.31-1.47.87-2.74,2.05-3.72,3.44-1.04,1.47-1.81,3.11-2.3,4.84-.51,1.76-.77,3.58-.78,5.42v15.71c0,1.7-.85,2.55-2.56,2.55h-2.41c-1.71,0-2.56-.85-2.56-2.55v-34.05Z"
          />
          <path
            fill={base}
            d="M805.8,77.01c0-2.01.39-3.75,1.16-5.22.75-1.44,1.81-2.69,3.1-3.68,1.34-1.01,2.82-1.81,4.39-2.4,1.65-.62,3.35-1.09,5.08-1.39,1.71-.3,3.43-.51,5.16-.62,1.68-.1,3.19-.16,4.54-.16h2.02v-1.01c0-1.59-.22-2.95-.66-4.06-.38-1.02-1.01-1.94-1.82-2.67-.79-.68-1.71-1.17-2.72-1.43-1.09-.29-2.21-.43-3.33-.42-1.78-.03-3.55.26-5.23.85-1.25.44-2.45.98-3.61,1.63-1.5.72-2.66.41-3.49-.93l-.62-1.08c-.83-1.55-.54-2.71.85-3.48,1.19-.66,2.86-1.39,5-2.17s4.72-1.17,7.72-1.16c4.91,0,8.71,1.3,11.4,3.91,2.69,2.6,4.03,6.41,4.03,11.41v22.2c0,1.7-.85,2.55-2.56,2.55h-1.94c-1.7,0-2.56-.85-2.56-2.55v-3.33c0-.26.02-.52.08-.77.05-.2.1-.41.15-.62h-.15c-.75,1.52-1.75,2.9-2.95,4.1-1.17,1.13-2.5,2.08-3.95,2.82-1.6.85-3.57,1.28-5.89,1.28-1.68,0-3.36-.26-4.96-.78-1.54-.48-2.98-1.26-4.23-2.28-1.21-1-2.2-2.24-2.91-3.64-.73-1.42-1.09-3.05-1.09-4.91ZM813.4,76.39c0,1.55.61,2.98,1.82,4.29,1.22,1.32,3.04,1.97,5.47,1.97,1.56.02,3.1-.36,4.46-1.12,1.3-.74,2.44-1.74,3.33-2.94.91-1.22,1.62-2.58,2.1-4.02.48-1.41.73-2.89.74-4.38v-1.7h-2.02c-1.4,0-3,.07-4.81.2-1.76.12-3.51.44-5.2.97-1.56.46-2.99,1.27-4.19,2.36-1.13,1.06-1.7,2.52-1.7,4.37Z"
          />
          <path
            fill={base}
            d="M845.35,51.09c0-1.7.85-2.55,2.56-2.55h2.17c1.71,0,2.56.85,2.56,2.55v4.49c0,.41-.01.8-.04,1.16-.02.31-.06.62-.12.93-.05.28-.08.57-.08.85h.16c.43-1.33,1.02-2.6,1.74-3.79.73-1.22,1.63-2.32,2.67-3.29,1.02-.95,2.19-1.73,3.45-2.32,1.3-.6,2.72-.9,4.15-.89,1.65,0,2.48.85,2.48,2.55v2.17c0,1.7-.93,2.55-2.79,2.55s-3.58.48-5,1.43c-1.43.96-2.65,2.22-3.57,3.68-.99,1.59-1.71,3.33-2.13,5.15-.46,1.89-.7,3.82-.7,5.77v13.62c0,1.7-.85,2.55-2.56,2.55h-2.4c-1.71,0-2.56-.85-2.56-2.55v-34.06Z"
          />
          <path
            fill={base}
            d="M876.06,55.04h-2.71c-1.65,0-2.48-.85-2.48-2.55v-.99c0-1.7.85-2.55,2.56-2.55h2.79v-8.6c0-1.7.85-2.55,2.56-2.55h2.25c1.71,0,2.56.85,2.56,2.55v8.59h6.82c1.71,0,2.56.85,2.56,2.55v.99c0,1.7-.83,2.55-2.48,2.55h-6.9v17.42c0,1.86.26,3.35.78,4.49.42,1.01,1.07,1.91,1.9,2.63.7.59,1.52,1.03,2.4,1.28.77.22,1.56.36,2.37.43,1.03.1,1.74.34,2.13.7.39.36.58.98.58,1.86v1.63c0,1.7-1.04,2.55-3.1,2.55-1.89.01-3.78-.28-5.58-.85-1.71-.54-3.28-1.44-4.61-2.63-1.37-1.25-2.46-2.78-3.19-4.49-.8-1.81-1.2-3.95-1.2-6.42v-18.58Z"
          />
          <path
            fill={base}
            d="M627.14,9.47c-28.18,0-51.03,22.8-51.03,50.92s22.85,50.92,51.03,50.92,51.03-22.8,51.03-50.92-22.85-50.92-51.03-50.92ZM642.93,66.7c-6.98,14.22-21.67,24.35-38.69,27.2-1.09.18-1.5-1.38-.45-1.76,6.5-2.34,19.07-8.75,25.48-18.86,5.16-7.54,8.35-22.48-23.35-19.14-1.03.11-1.48-1.52-.53-1.92,10.14-4.29,17.86-5.15,25.62-5.25,9.23.09,19.69,4.52,11.92,19.73ZM643.68,43.28c-4.07,0-7.38-3.64-7.38-8.12s3.3-8.12,7.38-8.12,7.38,3.64,7.38,8.12-3.32,8.12-7.38,8.12Z"
          />
        </g>
      </g>
    </svg>
  )
}
