import { Headline } from '@retire/components'
import { styled } from '@retire/theme/styledComponents'

export const SHeadline = styled(Headline)`
  display: flex;
  > *:first-child {
    padding-right: ${props => props.theme.spacing.medium};
  }
`

export const SContent = styled.div`
  white-space: break-spaces;
  color: ${({ theme: { colors } }) => colors.neutralDark};
`
