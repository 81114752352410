import { styled } from '@retire/theme/styledComponents'

import { Copy } from '../Typography'
import type { TSRating } from './types'

export const SRatingContainer = styled.div`
  display: inline-block;
`

export const SRatingBox = styled.div`
  border-radius: 1rem;
  padding: 0 1rem;
  background-color: ${({ theme: { colors } }) => colors.neutral30};
  color: ${({ theme: { colors } }) => colors.neutralDark};
`

export const SRating = styled.span<TSRating>`
  display: inline-block;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  background-color: ${({ chosen, theme: { colors } }) => (chosen ? colors.secondary : colors.neutral30)};
`

export const SRightFloatedCopy = styled(Copy)`
  float: right;
  white-space: nowrap;
`
