import type { MutableRefObject } from 'react'
import { createRef, forwardRef, useCallback, useEffect, useState } from 'react'

import { DEFAULT_PROPS } from './constants'
import { SWrapper } from './presentation.styled'
import type { TTextInput } from './types'

export const TextInput = forwardRef<HTMLInputElement, TTextInput>(
  (
    {
      ariaLabel,
      ariaLabelledBy,
      ariaDescribedBy,
      ariaInvalid,
      value = DEFAULT_PROPS.value,
      format,
      prefix,
      suffix,
      addOnRight,
      addOnLeft,
      Input = DEFAULT_PROPS.Input,
      onFocus,
      onBlur,
      onChange,
      width,
      inputWidth,
      type = DEFAULT_PROPS.type,
      ...props
    },
    ref
  ) => {
    const inputRef = (ref as MutableRefObject<HTMLInputElement | null>) || createRef<HTMLInputElement | null>()
    const [isFocused, setIsFocused] = useState(false)
    const [formattedValue, setFormattedValue] = useState(format?.input ? format.input(value) : value)

    useEffect(() => {
      if (format) {
        const newFormattedValue = isFocused ? format.output(value) : format.input(value)
        setFormattedValue(isFocused ? format.output(value) : format.input(value))
        // safeguard to avoid user filling more decimals than precision
        isFocused && newFormattedValue !== value && onChange && onChange(newFormattedValue)
      } else {
        setFormattedValue(value)
      }
    }, [value, isFocused, format, onChange])

    useEffect(() => {
      if (isFocused) {
        onFocus && onFocus()
      } else {
        onBlur && onBlur()
      }
    }, [isFocused, onBlur, onFocus])

    const onClick = useCallback(() => inputRef?.current?.focus(), [inputRef])
    const onInputFocus = useCallback(() => setIsFocused(true), [setIsFocused])
    const onBlurOrWheel = useCallback(() => setIsFocused(false), [setIsFocused])

    return (
      <SWrapper onClick={onClick} withPrefix={!!prefix && !suffix} withSuffix={!!suffix} wrapperWidth={width}>
        {!!addOnLeft && addOnLeft}
        {!!prefix && !suffix && <i>{prefix}</i>}
        <Input
          {...props}
          as="input"
          width={inputWidth}
          aria-describedby={ariaDescribedBy}
          aria-invalid={ariaInvalid}
          aria-labelledby={ariaLabelledBy}
          aria-label={ariaLabel}
          ref={inputRef}
          value={(isFocused ? value : formattedValue) || ''}
          size={35}
          onFocus={onInputFocus}
          onBlur={onBlurOrWheel}
          onWheel={onBlurOrWheel}
          onChange={onChange}
          type={type}
        />
        {!!suffix && <i>{suffix}</i>}
        {!!addOnRight && addOnRight}
      </SWrapper>
    )
  }
)

TextInput.displayName = 'TextInput'
