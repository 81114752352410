import { getPotIcon } from '@retire/components'
import type { FC } from 'react'

import { SIconContainer } from './components/IconContainer/index.styled'
import type { TPageTitle } from './types'

export const PageTitle: FC<TPageTitle> = ({ variant, title }) => {
  const Icon = getPotIcon(variant)

  return (
    <>
      <SIconContainer>
        <Icon />
      </SIconContainer>
      {title}
    </>
  )
}
