import { PotName } from '@retire/constants/pots'
import {
  MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY,
  MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY,
} from '@retire/gql/projections/queries'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { toPennies } from '@retire/utils/currency'
import { isFlexibleIncomePotDataValid, isLaterLifePotDataValid } from '@retire/utils/pots'

import { usePotsContext } from '../usePotsContext'
import type { TUseMoveMoneyMonthlyAmount, TUseMoveMoneyMonthlyAmountReturn } from './types'

export const useMoveMoneyMonthlyAmount = ({
  potName,
  selectedFromPot,
  amount,
}: TUseMoveMoneyMonthlyAmount): TUseMoveMoneyMonthlyAmountReturn => {
  const {
    pots: {
      [potName]: { allocatedAmount: currentPotAmount = 0, projectedMonthlyIncome = 0, customMonthlyIncome = 0 },
    },
  } = usePotsContext()
  const {
    data: {
      laterLifeAge: laterLifePotStartAge,
      regularIncomeEndAge: flexibleIncomePotEndAge,
      regularIncomeStartAge,
      currentAge,
    },
  } = useUserDetails()
  const flexibleIncomePotStartAge = Math.max(currentAge, regularIncomeStartAge)
  const isFromEstimation = potName === selectedFromPot
  const penniesAmount = isFromEstimation ? -toPennies(amount) : toPennies(amount)
  const afterMovePotAmount = currentPotAmount + penniesAmount

  const skipFlexibleIncomePotProjection = !isFlexibleIncomePotDataValid({
    startAge: flexibleIncomePotStartAge,
    endAge: flexibleIncomePotEndAge,
    potAmount: afterMovePotAmount,
  })
  const monthlyFlexibleIncomeAmount = useQueryWithError(MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotStartAge,
      flexibleIncomePotEndAge,
      flexibleIncomePotAmount: afterMovePotAmount,
    },
    skip: potName !== PotName.flexibleIncome || skipFlexibleIncomePotProjection,
  })?.data?.projections?.monthlyFlexibleIncomeAmount

  const skipLaterLifePotProjection = !isLaterLifePotDataValid({
    startAge: laterLifePotStartAge,
    potAmount: afterMovePotAmount,
  })
  const monthlyLaterLifeAmount = useQueryWithError(MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY, {
    variables: { laterLifePotStartAge, laterLifePotAmount: afterMovePotAmount },
    skip: potName !== PotName.laterLife || skipLaterLifePotProjection,
  })?.data?.projections?.monthlyLaterLifeAmount

  const currentMonthlyAmount = customMonthlyIncome || projectedMonthlyIncome
  const afterMoveMonthlyAmount = monthlyFlexibleIncomeAmount || monthlyLaterLifeAmount || 0
  const monthlyAmountDiff = afterMoveMonthlyAmount - currentMonthlyAmount

  return { currentMonthlyAmount, afterMoveMonthlyAmount, afterMovePotAmount, penniesAmount, monthlyAmountDiff }
}
