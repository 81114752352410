import { withLocationRouterStateValidation } from '@retire/hocs/withLocationRouterStateValidation'
import { useFullScreenModal } from '@retire/hooks/useFullScreenModal'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'

import { MoveMoneyThankYou } from '../../components'
import { FlexibleIncomePotPeriodReview } from '../../features/ManageIncome/FlexibleIncomePotPeriodReview/presentation'
import type { TFlexibleIncomePotManageIncomeReviewState } from '../../features/ManageIncome/types'

export const FlexibleIncomePotManageIncomePeriodReview =
  withLocationRouterStateValidation<TFlexibleIncomePotManageIncomeReviewState>(
    props => {
      const { Modal: FullScreenModal, onShowModal } = useFullScreenModal()

      useActivatePageOverlay()

      return (
        <>
          <FlexibleIncomePotPeriodReview {...props} onShowModal={onShowModal} />

          <FullScreenModal>
            <MoveMoneyThankYou />
          </FullScreenModal>
        </>
      )
    },
    ['startAge', 'endAge', 'monthlyIncome']
  )
