import type { ButtonStyleType } from '@retire/constants/button'

import { colors } from './colors'
import { PRIMARY_FAMILY } from './typography'
import { utilities } from './utilities'

const defaultButton = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
}

const styleTypes: Record<
  ButtonStyleType,
  Record<'active' | 'default' | 'focus' | 'hover', Record<'backgroundColor' | 'borderColor' | 'color', string>>
> = {
  primary: {
    active: {
      ...defaultButton,
      backgroundColor: colors.primary,
      color: colors.white,
    },
    default: {
      ...defaultButton,
      backgroundColor: colors.secondary,
      color: colors.primary,
    },
    focus: {
      backgroundColor: colors.secondary,
      borderColor: colors.primary,
      color: colors.primary,
    },
    hover: {
      ...defaultButton,
      backgroundColor: colors.buttonHover,
      color: colors.primary,
    },
  },
  secondary: {
    active: {
      ...defaultButton,
      backgroundColor: colors.primary,
      color: colors.white,
    },
    default: {
      backgroundColor: colors.white,
      borderColor: colors.neutral30,
      color: colors.primary,
    },
    focus: {
      backgroundColor: colors.white,
      borderColor: colors.primary,
      color: colors.primary,
    },
    hover: {
      backgroundColor: colors.white,
      borderColor: colors.neutral50,
      color: colors.primary,
    },
  },
  destructive: {
    active: {
      ...defaultButton,
      backgroundColor: colors.primary,
      color: colors.white,
    },
    default: {
      ...defaultButton,
      backgroundColor: colors.error,
      color: colors.white,
    },
    focus: {
      backgroundColor: colors.error,
      borderColor: colors.primary,
      color: colors.white,
    },
    hover: {
      ...defaultButton,
      backgroundColor: colors.destructiveHover,
      color: colors.white,
    },
  },
}

export const buttons = {
  border: utilities.border,
  family: PRIMARY_FAMILY,
  weight: utilities.weight.bold,
  size: {
    default: {
      lineHeight: '1.125rem',
      size: '1.125rem',
    },
    small: {
      lineHeight: '1.5rem',
      size: '1rem',
    },
  },
  padding: {
    default: {
      sides: '1.5rem',
      top: '1.0625rem',
    },
    small: {
      sides: '0.75rem',
      top: '0.375rem',
    },
  },
  disabled: {
    backgroundColor: colors.neutral10,
    borderColor: colors.neutral30,
    color: colors.neutral,
  },
  styleType: styleTypes,
}
