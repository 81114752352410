import { useCustomHeaderContext } from '@retire/layout/PageLayout/hooks/useCustomHeaderContext'
import { useCallback, useState } from 'react'

export const useSaveAndExitPage = () => {
  const { hideCustomHeader, showCustomHeader } = useCustomHeaderContext()
  const [isSaveAndExitPage, setIsSaveAndExitPage] = useState(false)

  const showSaveAndExitPage = useCallback(() => {
    setIsSaveAndExitPage(true)
    hideCustomHeader()
  }, [hideCustomHeader])
  const hideSaveAndExitPage = useCallback(() => {
    setIsSaveAndExitPage(false)
    showCustomHeader()
  }, [showCustomHeader])

  return {
    isSaveAndExitPage,
    showSaveAndExitPage,
    hideSaveAndExitPage,
  }
}
