import type { OperationVariables, QueryResult } from '@apollo/client'
import { useQuery } from '@apollo/client'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { ErrorCode } from '@retire/constants/errors'
import { getError, getErrorCode } from '@retire/utils/errors'
import type { DocumentNode } from 'graphql'
import { useEffect, useState } from 'react'

import { useGlobalErrorContext } from '../useGlobalErrorContext'
import { DEFAULT_POLL_CUMULATIVE_MAX_RETRIES, DEFAULT_POLL_INTERVAL, DEFAULT_POLL_MAX_RETRIES } from './constants'
import type { TUseQueryWithErrorOptions } from './types'

export const useQueryWithError = <TData, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { fetchPolicy, skipGlobalError, poll = false, ...options }: TUseQueryWithErrorOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables> => {
  const { setGlobalError } = useGlobalErrorContext()
  const [polling, setPolling] = useState(poll)
  const [pollCounter, setPollCounter] = useState(1)
  const maxRetries = poll ? DEFAULT_POLL_CUMULATIVE_MAX_RETRIES : undefined

  const { startPolling, stopPolling, loading, ...result } = useQuery<TData, TVariables>(query, {
    fetchPolicy: poll ? 'network-only' : fetchPolicy,
    ...options,
    context: { maxRetries },
    onCompleted: data => {
      cancelPolling()
      options.onCompleted && options.onCompleted(data)
    },
    onError: error => {
      if (poll && getErrorCode(error) === ErrorCode.processing && pollCounter < DEFAULT_POLL_MAX_RETRIES) {
        setPolling(true)
        setPollCounter(currentPollCounter => currentPollCounter + 1)
      } else {
        cancelPolling()
        options.onError && options.onError(error)
        !skipGlobalError && setGlobalError(getError(error))
      }
    },
  })

  const cancelPolling = () => {
    poll && setPolling(false)
    poll && stopPolling()
  }

  useEffect(() => {
    poll && startPolling(DEFAULT_POLL_INTERVAL)
    return () => {
      stopPolling()
    }
  }, [poll, startPolling, stopPolling])

  return { ...result, startPolling, stopPolling, loading: loading || polling }
}
