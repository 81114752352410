import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

export const useSyncPhoneValidations = (telephoneName: string, countryCodeName: string) => {
  const {
    setError,
    clearErrors,
    formState: {
      errors: { [telephoneName]: telephoneError, [countryCodeName]: countryCodeError },
    },
  } = useFormContext()

  useEffect(() => {
    if ((telephoneError && !countryCodeError) || telephoneError?.message !== countryCodeError?.message) {
      setError(countryCodeName, telephoneError)
    } else if (!telephoneError && countryCodeError) {
      clearErrors(countryCodeName)
    }
  }, [telephoneError, countryCodeError, setError, countryCodeName, clearErrors])
}
