import { Spacer } from '@retire/components'
import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'

import { SHeadline } from './presentation.styled'

export const Header: FC = ({ children }) => (
  <Spacer top="large" bottom="mediumSmall">
    <SHeadline level={HeadlineLevel.h4} as="h2">
      {children}
    </SHeadline>
  </Spacer>
)
