import { TEST_IDS } from '@retire/constants'
import { withBaseModal } from '@retire/hocs/withBaseModal'
import type { FC } from 'react'

import { Icon } from '../Icon'
import { PartialLoader } from '../PartialLoader'
import { SLoaderContainer, SLogo } from './presentation.styled'
import type { TLoading } from './types'

export const Loader: FC = () => (
  <SLogo>
    <Icon iconType="Logo" />
  </SLogo>
)

export const Loading: FC<TLoading> = ({ withLogo }) => {
  if (withLogo) {
    const LoadingWithBaseModal = withBaseModal(Loader)
    return <LoadingWithBaseModal withoutModalBox isActive theme="light" dialogType="loading" />
  }
  return (
    <SLoaderContainer data-testid={TEST_IDS.components.loading}>
      <PartialLoader />
    </SLoaderContainer>
  )
}
