import { useThemeContext } from '@retire/hooks'
import type { FC } from 'react'

import { Icon } from '../Icon'
import { SErrorIconContainer, SInlineError } from './presentation.styled'

export const InlineError: FC = ({ children }) => {
  const theme = useThemeContext()

  return (
    <SInlineError>
      <SErrorIconContainer withSpacing>
        <Icon iconType="Error" iconSize={theme.forms.errors.iconSize} />
      </SErrorIconContainer>
      {children}
    </SInlineError>
  )
}
