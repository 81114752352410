import { Button, Segment, Spacer } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import { useTranslationWithOptions } from '@retire/hooks'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TransactionTable } from '../../components'
import { TransactionsHowItWorksCard } from '../../components/TransactionsHowItWorksCard/presentation'
import { useTransactions } from '../../hooks'

export const VISIBLE_TRANSACTIONS_COUNT_STEP = 5

export const TransactionList: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const {
    customerService: { phoneNumber },
  } = usePublicSettings()
  const {
    data: { inProgressTransactions, completedTransactions },
    loading,
    error,
  } = useTransactions()
  const navigate = useNavigate()

  const [visibleCompletedTransactionsCount, setVisibleCompletedTransactionsCount] = useState(
    VISIBLE_TRANSACTIONS_COUNT_STEP
  )
  const showMoreCompletedTransactions = useCallback(
    () => setVisibleCompletedTransactionsCount(previousCount => previousCount + VISIBLE_TRANSACTIONS_COUNT_STEP),
    []
  )
  const visibleCompletedTransactions = completedTransactions.slice(
    0,
    Math.min(visibleCompletedTransactionsCount, completedTransactions.length)
  )
  const onCancelClick = useCallback(
    transactionId => navigate(paths.dashboard.cancelTransaction, { state: { transactionId } }),
    [navigate]
  )

  return (
    <PageTemplate title={t('transactionList.title', 'Transactions title')} loading={loading || !!error} fullWidth>
      <Spacer bottom="extraLarge" />
      <TransactionsHowItWorksCard phoneNumber={phoneNumber} />
      {!!inProgressTransactions.length && (
        <Spacer bottom="extraLarge">
          <Segment whiteBackground>
            <TransactionTable
              translationKey="inProgress"
              displayAmount="amount"
              transactions={inProgressTransactions}
              onCancelClick={onCancelClick}
            />
          </Segment>
        </Spacer>
      )}
      <Segment whiteBackground>
        <TransactionTable
          translationKey="history"
          displayAmount="settledAmount"
          transactions={visibleCompletedTransactions}
          showDetails
        />
      </Segment>
      {visibleCompletedTransactions.length < completedTransactions.length && (
        <Spacer top="medium" bottom="extraLarge">
          <Button buttonStyleType={ButtonStyleType.secondary} onClick={showMoreCompletedTransactions}>
            {t('transactionList.showMore', 'Show more completed transactions')}
          </Button>
        </Spacer>
      )}
    </PageTemplate>
  )
}
