import type { TPickAndRequired } from '@retire/types'
import { DEFAULT_TEXT_ALIGN } from '@retire/utils'

import { DEFAULT_PROPS as TABLE_DEFAULT_PROPS } from '../constants'
import type { TTHCell } from './types'

export const DEFAULT_PROPS: TPickAndRequired<TTHCell, 'textAlign' | 'verticalAlign' | 'width'> = {
  ...TABLE_DEFAULT_PROPS,
  textAlign: DEFAULT_TEXT_ALIGN,
  width: 'auto',
}
