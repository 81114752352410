import type { ChangeEvent, FC } from 'react'
import { useCallback, useMemo } from 'react'

import type { TSearchResults } from '../types'
import { NativeSelect } from './presentation'

export const NativeSelectContainer: FC<TSearchResults> = ({ searchResults, onResultClick }) => {
  const getNextById = useMemo(() => {
    const nextMap = {}
    searchResults.forEach(({ id, next }) => {
      nextMap[id] = next
    })
    return nextMap
  }, [searchResults])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const id = e.target.value
      const next = getNextById[id]

      onResultClick(id, next)
    },
    [onResultClick, getNextById]
  )

  return <NativeSelect onChange={onChange} searchResults={searchResults} />
}
