import { colors } from './colors'

export enum StyleWeight {
  regular = 'regular',
  extraRegular = 'extraRegular',
  semiBold = 'semiBold',
  bold = 'bold',
  extraBold = 'extraBold',
}

const weights: Record<StyleWeight, string> = {
  regular: '400',
  extraRegular: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '900',
}

export const utilities = {
  border: {
    width: '2px',
    radius: '6px',
    style: 'solid',
  },
  scrollbar: {
    color: colors.primary,
    backgroundColor: colors.neutral30,
    width: '8px',
    borderRadius: '4.5px',
  },
  weight: weights,
}
