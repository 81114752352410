import { styled } from '@retire/theme/styledComponents'

export const SLoaderContainer = styled.div`
  display: flex;
`

export const SLogo = styled.div`
  text-align: center;
  svg {
    width: 90%;
  }
`
