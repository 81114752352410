import { useContext, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { PageOverlayContext } from '../../context/pageOverlay'
import { OVERLAY_PATH_PATTERNS } from './constants'
import type { TUsePageOverlay } from './types'

export const usePageOverlay = (): TUsePageOverlay => {
  const { isOverlay: isOverlayContext, activateOverlay } = useContext(PageOverlayContext)
  const { pathname } = useLocation()

  // detect if current page has an overlay
  // - from the current pathname
  // - or from the PageOverlay context
  const isOverlay = useMemo(
    () => OVERLAY_PATH_PATTERNS.some(pathPattern => matchPath(pathPattern, pathname)) || isOverlayContext,
    [pathname, isOverlayContext]
  )

  return {
    isOverlay,
    activateOverlay,
  }
}
