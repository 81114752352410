import { PotName } from '@retire/constants/pots'
import { MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY } from '@retire/gql/projections/queries'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { toCurrency, toPounds } from '@retire/utils/currency'
import { isFlexibleIncomePotDataValid } from '@retire/utils/pots'
import { isLessThanOrEqualToMaxValue } from '@retire/utils/rules'
import { useMemo } from 'react'

import type { TUseFlexibleIncomePotMonthlyAmountValidationRules } from './types'

export const useFlexibleIncomePotMonthlyAmountValidationRules: TUseFlexibleIncomePotMonthlyAmountValidationRules = ({
  flexibleIncomePotAmount,
  flexibleIncomePotStartAge,
}) => {
  const { t } = useTranslationWithOptions('dashboard')

  const skipFlexibleIncomePotProjection = !isFlexibleIncomePotDataValid({
    startAge: flexibleIncomePotStartAge,
    endAge: flexibleIncomePotStartAge + 1,
    potAmount: flexibleIncomePotAmount,
  })
  const { data, loading: projectionLoading } = useQueryWithError(MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotAmount,
      flexibleIncomePotStartAge,
      flexibleIncomePotEndAge: flexibleIncomePotStartAge + 1,
    },
    skip: skipFlexibleIncomePotProjection,
  })
  const minimumPeriodOfOneYearUpperLimit = data?.projections?.monthlyFlexibleIncomeAmount

  const {
    [PotName.flexibleIncome]: { minMonthlyIncome, maxMonthlyIncome: settingsUpperLimit },
  } = useSettings()

  const rules = useMemo(
    () => ({
      required: true,
      min: {
        value: toPounds(minMonthlyIncome),
        message: t(
          'pots.common.manageIncome.validations.amountLowerLimit',
          'Enter a monthly income that is {{limit}} or more',
          {
            limit: toCurrency(minMonthlyIncome, 2),
          }
        ),
      },
      max: {
        value: toPounds(settingsUpperLimit),
        message: t(
          'pots.common.manageIncome.validations.amountUpperLimitFromSettings',
          'Enter a monthly income that is {{limit}} or less',
          {
            limit: toCurrency(settingsUpperLimit, 0),
          }
        ),
      },
      validate: {
        maxPeriod: isLessThanOrEqualToMaxValue({
          maxValue: toPounds(minimumPeriodOfOneYearUpperLimit),
          message: t(
            'pots.common.manageIncome.validations.amountUpperLimitForPeriodOfOneYear',
            'The age at which your monthly income will cease must be at least one year after you start receiving your monthly income. Try entering a monthly income value lower than {{limit}}.',
            {
              limit: toCurrency(minimumPeriodOfOneYearUpperLimit),
            }
          ),
        }),
      },
    }),
    [minMonthlyIncome, minimumPeriodOfOneYearUpperLimit, settingsUpperLimit, t]
  )

  return {
    rules,
    loadingRules: projectionLoading,
  }
}
