import type { TAddressResult } from '@retire/components/Forms/AddressLookup/Search'
import { AddressField } from '@retire/constants'
import type { TAddressData } from '@retire/types'

import type { TAddressFind, TAddressRetrieve } from './types'

const CONFIG = {
  base: process.env.REACT_APP_LOCATE_ADDRESS_LOOKUP_BASE_URL || 'https://api.addressy.com/CapturePlus/Interactive',
  countryCode: process.env.REACT_APP_LOCATE_ADDRESS_LOOKUP_COUNTRY || 'GBR',
  endpoints: {
    find: process.env.REACT_APP_LOCATE_ADDRESS_LOOKUP_FIND_ENDPOINT || 'Find/v2.10/json3.ws',
    retrieve: process.env.REACT_APP_LOCATE_ADDRESS_LOOKUP_RETRIEVE_ENDPOINT || 'Retrieve/v2.10/json3.ws',
  },
  key: process.env.REACT_APP_LOCATE_ADDRESS_LOOKUP_API_KEY || '',
  lang: process.env.REACT_APP_LOCATE_ADDRESS_LOOKUP_LANG_PREFERENCE || 'en',
}

const makeLocationRequest = async <T = unknown>(endpoint: string): Promise<T> => {
  try {
    const response = await fetch(`${CONFIG.base}/${endpoint}`)
    const fullResult = await response.json()
    const items = fullResult.Items
    if (items[0].Error) {
      return Promise.reject({ error: items[0].Resolution || items[0].Description || items[0].Cause || items[0].Error })
    }
    return items
  } catch (error) {
    return Promise.reject({ error: (error as Error).message || 'Something went wrong' })
  }
}

const withQueryParams = (base: string, queryParams: { [key: string]: string }) => {
  const encodedQueryParams = new URLSearchParams(queryParams).toString()
  return `${base}?${encodedQueryParams}`
}

export const findAddress = async ({
  searchTerm,
  id,
}: {
  searchTerm?: string
  id?: string
}): Promise<TAddressResult[]> => {
  const url = withQueryParams(CONFIG.endpoints.find, {
    [searchTerm ? 'SearchTerm' : 'LastId']: searchTerm || id || '',
    Key: CONFIG.key,
    LanguagePreference: CONFIG.lang,
    Country: CONFIG.countryCode,
    SearchFor: 'Everything',
  })
  const items = await makeLocationRequest<TAddressFind[]>(url).catch(error => {
    console.error(error)
  })

  if (items) {
    return items.map(item => ({
      id: item.Id,
      label: item.Text + (item.Description && ` (${item.Description})`),
      next: item.Next === 'Retrieve' ? 'Retrieve' : 'Find',
    }))
  }
  return []
}

export const retrieveAddress = async (addressId: string): Promise<TAddressData | undefined> => {
  const url = withQueryParams(CONFIG.endpoints.retrieve, {
    Id: addressId,
    Key: CONFIG.key,
  })
  const items = await makeLocationRequest<TAddressRetrieve[]>(url).catch(error => {
    console.error(error)
  })

  if (items) {
    const [item] = items
    return {
      [AddressField.line1]: item.Line1,
      [AddressField.line2]: item.Line2,
      [AddressField.line3]: item.Line3,
      [AddressField.city]: item.City,
      [AddressField.county]: item.AdminAreaName,
      [AddressField.postcode]: item.PostalCode,
    }
  }
  return undefined
}
