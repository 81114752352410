import { Spacer } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'
import { Col, Hidden, Row, Visible } from 'react-grid-system'

import { SFooterHeadline, SHR } from '../presentation.styled'
import { MiddleFooterLinks } from './MiddleFooterLinks'

export const MiddleFooter: FC = () => {
  const { t } = useTranslationWithOptions('common')

  return (
    <>
      <Row>
        <Col>
          <SFooterHeadline as="h3" level={HeadlineLevel.h3}>
            {t('footer.help.header', 'Help header')}
          </SFooterHeadline>
        </Col>
      </Row>
      <Visible sm xs>
        <Spacer top="mediumSmall" />
      </Visible>
      <Hidden sm xs>
        <Spacer top="medium" />
      </Hidden>
      <MiddleFooterLinks />
      <Row>
        <Col>
          <SHR />
        </Col>
      </Row>
    </>
  )
}
