import { IDS } from '@retire/constants'
import { HeadlineLevel } from '@retire/theme'
import type { TPickAndRequired } from '@retire/types'

import type { TPageTitle } from './types'

export const DEFAULT_PROPS: TPickAndRequired<TPageTitle, 'headlineAs' | 'headlineLevel' | 'id' | 'bottom'> = {
  headlineAs: 'h1',
  headlineLevel: HeadlineLevel.h1,
  id: IDS.mainContent,
  bottom: 'medium',
}
