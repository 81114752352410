import { SimpleAlert } from '@retire/components/SimpleAlert'
import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import type { TOnShowModal, TUseModalWithTranslation } from './types'
export type { TUseModalWithTranslation }

export const useModalWithTranslation = (): TUseModalWithTranslation => {
  const [isModalVisible, setModalVisibility] = useState(false)
  const [modalTitle, setModalTitle] = useState<TOnShowModal['title']>('')
  const [modalContent, setModalContent] = useState<TOnShowModal['content']>('')
  const [replacementComponents, setReplacementComponents] = useState<TOnShowModal['components']>()
  const [modalOpenerRef, setOpenerRef] = useState<TOnShowModal['modalOpenerRef']>()

  const onShowModal = ({ title, content, components, modalOpenerRef: newModalOpenerRef }: TOnShowModal) => {
    components && setReplacementComponents(components)
    setModalTitle(String(title))
    setModalContent(content)
    setModalVisibility(true)
    setOpenerRef(newModalOpenerRef)
  }

  const closeModal = useCallback(() => {
    setModalVisibility(false)
    setReplacementComponents(undefined)
  }, [])

  const Modal = () =>
    isModalVisible ? (
      <SimpleAlert modalOpenerRef={modalOpenerRef} title={modalTitle} onClose={closeModal}>
        {replacementComponents ? <Trans components={replacementComponents}>{modalContent}</Trans> : modalContent}
      </SimpleAlert>
    ) : null

  return {
    onShowModal,
    Modal,
  }
}
