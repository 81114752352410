import { Button, Copy, DataRow, Divider, Headline, Notification, Spacer, Twister } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import { HeadlineLevel } from '@retire/theme'
import { isEmpty } from 'lodash'
import type { FC } from 'react'
import { Fragment, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { SSection } from './presentation.styled'
import type { TSection } from './types'

export const Section: FC<TSection> = ({ title, subTitle, twister, details, summary, notification, button }) => {
  const navigate = useNavigate()

  const onClick = useCallback(() => button && navigate(button.path, { state: button.state }), [button, navigate])

  return (
    <SSection>
      <Headline level={HeadlineLevel.h3} as="h2">
        {title}
      </Headline>
      {subTitle && (
        <Spacer bottom="medium" top="medium">
          <Copy as="p">{subTitle}</Copy>
        </Spacer>
      )}
      {twister && (
        <Spacer bottom="mediumSmall">
          <Twister {...twister} />
        </Spacer>
      )}
      <Spacer bottom="extraLarge" />
      {details.map((subDetails, index, array) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`details-${index}`}>
          {subDetails
            .filter(({ value }) => !isEmpty(`${value}`))
            .map(({ label, value, twister: subDetailTwister, hasBottomDivider }, subIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`details-${index}-${subIndex}`}>
                <DataRow name={label} value={String(value)} twister={subDetailTwister} />
                {hasBottomDivider && <Divider />}
              </Fragment>
            ))}
          {index !== array.length - 1 && <Divider />}
        </Fragment>
      ))}
      {summary && (
        <Spacer bottom="medium" top="medium">
          <Copy as="p">{summary}</Copy>
        </Spacer>
      )}
      {notification && <Notification {...notification} />}
      {button && (
        <Spacer top="medium">
          <Button buttonStyleType={ButtonStyleType.secondary} onClick={onClick}>
            {button.label}
          </Button>
        </Spacer>
      )}
    </SSection>
  )
}
