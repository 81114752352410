import { FormOptionsGroup } from '@retire/components/Forms/OptionsGroup'
import { Spacer } from '@retire/components/Spacer'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { toCurrency } from '@retire/utils/currency'
import { getTFLSTheoricalAmount } from '@retire/utils/pots'
import type { FC } from 'react'
import { useMemo } from 'react'

import { Info } from './Info'
import type { TQuestion } from './types'

export const Question: FC<TQuestion> = ({ order, question, value }) => {
  const { t } = useTranslationWithOptions('enrolment')
  const stringValue = value ? 'yes' : 'no'

  const {
    lumpSumAllowance: { amount: lumpSumAllowanceAmount },
    lumpSumAndDeathBenefitAllowance: { amount: lumpSumAndDeathBenefitAllowanceAmount },
  } = useSettings()

  const questionInfoKey = useMemo(() => {
    if (t(`cobs.form.questions.${question}.${stringValue}`, '')) {
      return `cobs.form.questions.${question}.${stringValue}`
    }
    return null
  }, [question, stringValue, t])

  return (
    <Spacer bottom="medium">
      <FormOptionsGroup
        fullWidth
        options={[
          {
            label: t('cobs.form.questionsAnswers.yes', 'Yes'),
            value: true,
          },
          {
            label: t('cobs.form.questionsAnswers.no', 'No'),
            value: false,
          },
        ]}
        rules={{
          validate: {
            required: val => val !== null || String(t('cobs.form.validations.required', 'Please select an answer')),
          },
        }}
        label={`${order ? `${order}. ` : ''}${t(`cobs.form.questions.${question}.question`, 'Question')}`}
        name={question}
      />
      {value !== null && questionInfoKey && (
        <Info
          key={stringValue}
          copy={t(questionInfoKey, 'Question info', {
            taxFreeLumpSumAmount: toCurrency(getTFLSTheoricalAmount(lumpSumAndDeathBenefitAllowanceAmount), 0),
            lumpSumAllowanceAmount: toCurrency(lumpSumAllowanceAmount, 0),
            lumpSumAndDeathBenefitAllowanceAmount: toCurrency(lumpSumAndDeathBenefitAllowanceAmount, 0),
          })}
        />
      )}
    </Spacer>
  )
}
