import type { FC } from 'react'
import { Suspense } from 'react'

import { Loading } from '../Loading'
import type { TSuspenseLoader } from './types'

export const SuspenseLoader: FC<TSuspenseLoader> = ({ isLoading, children, ...loadingProps }) => {
  const loading = <Loading {...loadingProps} />
  return <Suspense fallback={loading}>{isLoading ? loading : children}</Suspense>
}
