import { gql } from '@apollo/client'

export const SUITABILITY_CHECK_QUERY = gql`
  query getSuitabilityChecks {
    user {
      id
      suitabilityCheck {
        willContributeToPension
        awareOfTaxFreeLumpSum
        needSpecialInvestmentOptions
        hasSeenWarningScreen
      }
    }
  }
`
