import { Copy, Icon, Link, Segment } from '@retire/components'
import { DateFormat } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { CopyLevel, StyleWeight } from '@retire/theme'
import { formatDate, truncateString } from '@retire/utils'
import isToday from 'date-fns/isToday'
import type { FC } from 'react'
import { useMemo } from 'react'
import { Visible } from 'react-grid-system'

import { DesktopInbox, MobileInbox } from '../../components'
import type { TMessageComponent } from '../../components/types'
import type { TMessages } from './types'

export const Messages: FC<TMessages> = ({ messages, onViewMessage }) => {
  const { t } = useTranslationWithOptions('inbox')

  const Text: FC<{ bold: boolean }> = ({ bold, children }) => (
    <Copy weight={StyleWeight[bold ? 'bold' : 'regular']} level={CopyLevel.body1} as="span">
      {children}
    </Copy>
  )

  const messageComponents: TMessageComponent[] = useMemo(
    () =>
      messages.map(({ id, read, sentAt, subject, files }) => ({
        id,
        from: <Text bold={!read}>{t('message.fromValue', 'Smart')}</Text>,
        subject: (
          // eslint-disable-next-line react/jsx-no-bind
          <Link as="a" title={truncateString(subject, 65)} onClick={() => onViewMessage(id)} noDecoration inline>
            <Text bold={!read}>{truncateString(subject, 65)}</Text>
          </Link>
        ),
        sentAt: <Text bold={!read}>{formatDate(sentAt, isToday(sentAt) ? DateFormat.time : DateFormat.longDate)}</Text>,
        files: (files || []).length ? <Icon iconType="Attachment" /> : null,
      })),
    [messages, onViewMessage, t]
  )

  return (
    <PageTemplate title={t('pageTitle.title', 'Inbox title')} fullWidth>
      <Segment whiteBackground>
        <Visible md lg xl>
          <DesktopInbox messages={messageComponents} />
        </Visible>
        <Visible xs sm>
          <MobileInbox messages={messageComponents} />
        </Visible>
      </Segment>
    </PageTemplate>
  )
}
