import { useTranslationWithOptions } from '@retire/hooks'
import { differentFromValue, EMPTY_DATE_VALUE, getDisplayName, isDate, isDateInPast, minDateAge } from '@retire/utils'
import type { ComponentType, FC } from 'react'

import type { TFormDate } from '../../types'
import type { TWrappedWithDateRules } from './types'

// note: if you need to override a rule copy, use the same `validate` key rule in calling HOC rules
export const withDateRules = (WrappedComponent: ComponentType<TFormDate>): FC<TWrappedWithDateRules> => {
  const Wrapped: FC<TWrappedWithDateRules> = props => {
    const { t } = useTranslationWithOptions('common')
    const { rules: initialRules = {}, minAge, required, isInPast, ...wrappedProps } = props
    let rules = initialRules || {}

    rules = {
      ...rules,
      validate: {
        format: isDate(t('form.date.validations.wrongFormat', 'Wrong date format')),
        ...(rules.validate || {}),
      },
    }

    if (required) {
      rules = {
        ...rules,
        validate: {
          required: differentFromValue({
            forbiddenValue: EMPTY_DATE_VALUE,
            message: t('form.date.validations.required', 'Date is required'),
          }),
          ...rules.validate,
        },
      }
    }

    if (isInPast) {
      rules = {
        ...rules,
        validate: {
          inPast: isDateInPast(t('form.date.validations.mustBeInPast', 'Date must be in the past')),
          ...rules.validate,
        },
      }
    }

    if (minAge) {
      rules = {
        ...rules,
        validate: {
          minAge: minDateAge({
            minAge,
            message: t('form.date.validations.minAge', 'Min age is {{minAge}}', { minAge }),
          }),
          ...rules.validate,
        },
      }
    }

    return <WrappedComponent {...wrappedProps} rules={rules} />
  }

  Wrapped.displayName = `WithDateRules(${getDisplayName(WrappedComponent)})`
  return Wrapped
}
