import { AdditionalPotName } from '@retire/constants'
import { getDisplayName, getPotColor } from '@retire/utils'
import isTouchDevice from 'is-touch-device'
import type { ComponentType, FC } from 'react'

import { PotStyleVariant } from '../constants'
import { SColorBar, SInnerWrapper, SOuterWrapper } from './presentation.styled'
import type { TWithPotCard, TWrappedWithPotCard } from './types'

export const withPotCard = <TProps extends TWithPotCard>(
  WrappedComponent: ComponentType<TWrappedWithPotCard<TProps>>,
  styleVariant: PotStyleVariant
) => {
  const Wrapped: FC<TProps> = ({ greyOutBackground, ...props }) => {
    const { variant, disabled } = props
    const potColor = getPotColor(disabled ? undefined : variant)
    const colorBarPosition = styleVariant === PotStyleVariant.vertical || isTouchDevice() ? 'top' : 'left'

    return (
      <SOuterWrapper data-testid={variant} colorBarPosition={colorBarPosition}>
        <SColorBar color={potColor} colorBarPosition={colorBarPosition} />
        <SInnerWrapper
          styleVariant={styleVariant}
          greyOutBackground={greyOutBackground}
          whiteBackground={variant !== AdditionalPotName.taxFree}
          transparent={variant === AdditionalPotName.taxFree}
          smallPadding={styleVariant !== PotStyleVariant.horizontal}
          colorBarPosition={colorBarPosition}
        >
          <WrappedComponent {...props} />
        </SInnerWrapper>
      </SOuterWrapper>
    )
  }

  Wrapped.displayName = `WithPotCard(${getDisplayName(WrappedComponent)})`
  return Wrapped
}
