import { useContext, useRef } from 'react'

import { IllustrationPlanDataContext } from '../../context/IllustrationPlanDataContext'
import type { TIllustrationPlanData } from '../../types'
import { useIllustrationPlanResetData } from '../useIllustrationPlanResetData'
import { useIllustrationPlanSaveFinalData } from '../useIllustrationPlanSaveFinalData'
import { useIllustrationPlanSavePartialData } from '../useIllustrationPlanSavePartialData'
import { useRemoveSubsequentIllustrationJourneyStepsData } from '../useRemoveSubsequentIllustrationJourneyStepsData'
import type { TUseIllustrationPlanDataResult } from './types'

export const useIllustrationPlanData = (): TUseIllustrationPlanDataResult => {
  const { loadingIllustrationPlanData, illustrationPlanData } = useContext(IllustrationPlanDataContext)
  const illustrationPlanDataRef = useRef<TIllustrationPlanData>(illustrationPlanData)

  const { savePartialIllustrationPlanData, savingPartialIllustrationPlanData } = useIllustrationPlanSavePartialData()
  const { saveFinalIllustrationPlanData, savingFinalIllustrationPlanData } = useIllustrationPlanSaveFinalData()
  const { resetIllustrationPlanData, resettingIllustrationPlanData } = useIllustrationPlanResetData()
  const { removeSubsequentIllustrationJourneyStepsData, removingSubsequentIllustrationJourneyStepsData } =
    useRemoveSubsequentIllustrationJourneyStepsData({
      illustrationPlanData: illustrationPlanDataRef.current,
      savePartialIllustrationPlanData,
      resetIllustrationPlanData,
    })

  return {
    illustrationPlanData,
    savePartialIllustrationPlanData,
    saveFinalIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    resetIllustrationPlanData,
    loadingIllustrationPlanData:
      loadingIllustrationPlanData ||
      savingPartialIllustrationPlanData ||
      savingFinalIllustrationPlanData ||
      resettingIllustrationPlanData,
    removingSubsequentIllustrationJourneyStepsData,
  }
}
