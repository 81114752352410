import { styled } from '@retire/theme/styledComponents'

import type { TColorBar } from './types'

export const SColorBar = styled.div<TColorBar>`
  position: absolute;
  width: ${({ theme }) => theme.pot.colorBar.width};
  height: 100%;
  background-color: ${props => props.theme.pot.potColor[props.color]};
  border-top-left-radius: ${({ theme: { forms } }) => forms.shared.border.radius};
  border-bottom-left-radius: ${({ theme: { forms } }) => forms.shared.border.radius};

  & + i {
    padding-left: calc(${({ theme: { forms, spacing } }) => `${forms.input.padding} + ${spacing.small}`});
  }
`

export const SLabelContainer = styled.span`
  display: flex;
`
