import { colors } from './colors'

export const icons = {
  color: colors.neutral50,
  size: {
    large: '72px',
    medium: '40px',
    mediumSmall: '32px',
    small: '24px',
    extraSmall: '20px',
    mini: '15px',
    micro: '10px',
  },
}
