import { graphql } from '../__generated__'

export const PLAN_QUERY = graphql(`
  query getPlan {
    plan {
      referenceNumber
      flexibleIncomeMonthlyAmount
      laterLifeAge
      laterLifeMonthlyAmount
      valuationAmount
      createdAt
      ...illustrationPlanBody__Plan
    }
  }
`)
