import { graphql } from '@retire/gql/__generated__/gql'

export const MARK_MESSAGE_READ_MUTATION = graphql(`
  mutation updateMessageSent($id: ID!) {
    sentMessageUpdate(id: $id, read: true) {
      id
      read
    }
  }
`)
