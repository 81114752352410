import { useThemeContext } from '@retire/hooks'
import { useMemo, useState } from 'react'

import type { TUseDropdown, TUseDropdownResult } from './types'

export const useDropdown = ({ disabled }: TUseDropdown): TUseDropdownResult => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    forms: {
      shared: {
        states: {
          default: { color: defaultColor },
          disabled: { color: disabledColor },
        },
      },
      dropdown: {
        states: {
          active: { color: dropdownActiveColor },
        },
      },
    },
  } = useThemeContext()

  const iconColor = useMemo(() => {
    const color = isOpen ? dropdownActiveColor : defaultColor
    return disabled ? disabledColor : color
  }, [defaultColor, disabled, disabledColor, dropdownActiveColor, isOpen])

  return {
    iconColor,
    isOpen,
    setIsOpen,
  }
}
