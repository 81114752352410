import { FormOptionsGroup } from '@retire/components/Forms/OptionsGroup'
import { Link } from '@retire/components/Link'
import { Notification } from '@retire/components/Notification'
import { Spacer } from '@retire/components/Spacer'
import { Twister } from '@retire/components/Twister'
import { Copy } from '@retire/components/Typography/Copy'
import { Headline } from '@retire/components/Typography/Headline'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { HeadlineLevel } from '@retire/theme/typography'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { StepWrapper } from '../../components/StepWrapper'
import { EnrolmentStep } from '../../constants'
import { USER_ENROLMENT_MUTATION } from '../../gql/mutations'
import { USER_ENROLMENT_QUERY } from '../../gql/queries'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import type { TLSAOrLSDBAQuestion } from './types'

export const LSAOrLSDBAQuestion: FC = () => {
  const navigate = useNavigate()

  useEnrolmentHeader({ currentStep: EnrolmentStep.lta })
  const { t } = useTranslationWithOptions('enrolment')
  const {
    customerService: { phoneNumber },
  } = usePublicSettings()
  const {
    lumpSumAllowance: { amount: lumpSumAllowanceAmount },
    lumpSumAndDeathBenefitAllowance: { amount: lumpSumAndDeathBenefitAllowanceAmount },
  } = useSettings()

  const [saveResponse, { loading: savingResponse }] = useMutationWithError(USER_ENROLMENT_MUTATION, {
    refetchQueries: [{ query: USER_ENROLMENT_QUERY }],
    onCompleted: () => {
      navigate(paths.enrolment.cobs)
    },
  })

  const methods = useForm<TLSAOrLSDBAQuestion>()
  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    watch,
  } = methods

  const { data: currentResponse, loading: loadingCurrentResponse } = useQueryWithError(USER_ENROLMENT_QUERY)
  useEffect(() => {
    if (!loadingCurrentResponse) {
      setValue('overLsaOrLsdba', currentResponse?.user?.enrolment?.overLsaOrLsdba ?? null)
    }
  }, [currentResponse, loadingCurrentResponse, setValue])

  const onNext = useCallback(async () => {
    await handleSubmit(async ({ overLsaOrLsdba }) => {
      await saveResponse({
        variables: {
          enrolment: {
            overLsaOrLsdba,
          },
        },
      })
    })()
  }, [handleSubmit, saveResponse])
  const response = watch('overLsaOrLsdba')
  const disableNext = useMemo(() => !isValid || false !== response, [isValid, response])

  return (
    <FormProvider {...methods}>
      <StepWrapper
        title={t('lsaOrLsdaQuestion.title', 'LumpSumAndDeathBenefitAllowance Question')}
        nextText={t('general.actions.next', 'Next')}
        backText={t('general.actions.back', 'Back')}
        loading={loadingCurrentResponse || savingResponse}
        onNext={onNext}
        disableNext={disableNext}
        backUrl={paths.enrolment.checkAndConfirm}
      >
        <Spacer bottom="large">
          <Headline as="h2" level={HeadlineLevel.h3}>
            {t(
              'lsaOrLsdaQuestion.header',
              'Value of your pension benefits: LumpSumAllowance amount {{lumpSumAllowanceAmount}}, LumpSumAndDeathBenefitAllowance amount {{lumpSumAndDeathBenefitAllowanceAmount}}',
              {
                lumpSumAllowanceAmount: toCurrency(lumpSumAllowanceAmount, 0),
                lumpSumAndDeathBenefitAllowanceAmount: toCurrency(lumpSumAndDeathBenefitAllowanceAmount, 0),
              }
            )}
          </Headline>
        </Spacer>
        <ContentWrapper>
          <FormOptionsGroup
            name="overLsaOrLsdba"
            fullWidth
            options={[
              {
                label: t('lsaOrLsdaQuestion.options.yes.label', 'Yes'),
                description: t('lsaOrLsdaQuestion.options.yes.description', 'Yes description'),
                value: true,
              },
              {
                label: t('lsaOrLsdaQuestion.options.no.label', 'No'),
                description: t('lsaOrLsdaQuestion.options.no.description', 'No description'),
                value: false,
              },
            ]}
          />
          <Spacer top="medium">
            <Twister title={t('lsaOrLsdaQuestion.twister.title', 'What are the allowances')} bottom="large">
              <Copy as="p">
                <Trans
                  components={{
                    mapsLink: <Link as="a" href={t('common:externalLinks.maps', 'MaPS link')} target="_blank" />,
                  }}
                >
                  {t(
                    'lsaOrLsdaQuestion.twister.description',
                    'The allowances are LumpSumAllowance ({{lumpSumAllowanceAmount}}) and LumpSumAndDeathBenefitAllowance ({{lumpSumAndDeathBenefitAllowanceAmount}})',
                    {
                      lumpSumAllowanceAmount: toCurrency(lumpSumAllowanceAmount, 0),
                      lumpSumAndDeathBenefitAllowanceAmount: toCurrency(lumpSumAndDeathBenefitAllowanceAmount, 0),
                    }
                  )}
                </Trans>
              </Copy>
            </Twister>
          </Spacer>
          {response && <Notification type="warning" title={t('lsaOrLsdaQuestion.alert', 'Alert', { phoneNumber })} />}
        </ContentWrapper>
      </StepWrapper>
    </FormProvider>
  )
}
