import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

export const TaxFreeWithdrawal: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg width="100%" height="100%" viewBox="0 0 32 33" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(-173.000000, -313.000000)" stroke={base} strokeWidth="2">
          <g transform="translate(135.500000, 219.000000)">
            <g transform="translate(0.500000, 0.000000)">
              <g>
                <g transform="translate(37.000000, 95.501782)">
                  <g>
                    <line x1="1" y1="18" x2="1" y2="30" />
                    <path d="M1,28 L23,28 C23,25.7906667 21.2093333,24 19,24 L14,24 C14,21.7906667 12.2093333,20 10,20 L1,20" />
                    <line x1="9" y1="24" x2="14" y2="24" />
                    <path d="M23,0 C27.4186667,0 31,1.79066667 31,4 C31,6.20933333 27.4186667,8 23,8 C18.5813333,8 15,6.20933333 15,4 C15,1.79066667 18.5813333,0 23,0 Z" />
                    <path d="M15,4 L15,14 C15,16.2093333 18.5813333,18 23,18 C27.4186667,18 31,16.2093333 31,14 L31,4" />
                    <path d="M31,9 C31,11.2093333 27.4186667,13 23,13 C18.5813333,13 15,11.2093333 15,9" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
