import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Attachment: FC<TIconColors> = props => {
  const {
    colors: { neutral60 },
  } = useThemeContext()
  const { base = neutral60 } = props

  return (
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Attachment" />
      <path
        d="M8.33118 15.9922C7.44576 16.8776 6.36503 17.3203 5.08899 17.3203C3.81295 17.3203 2.73222 16.8776 1.8468 15.9922C0.935343 15.1068 0.479614 14.026 0.479614 12.75L0.479614 4C0.479614 3.08854 0.818156 2.30729 1.49524 1.65625C2.14628 1.00521 2.92753 0.679688 3.83899 0.679688C4.75045 0.679688 5.5317 1.00521 6.18274 1.65625C6.83378 2.30729 7.1593 3.08854 7.1593 4L7.1593 11.0703C7.1593 11.6432 6.96399 12.138 6.57336 12.5547C6.1567 12.9714 5.66191 13.1797 5.08899 13.1797C4.51607 13.1797 4.02128 12.9714 3.60461 12.5547C3.18795 12.138 2.97961 11.6432 2.97961 11.0703V4.82031H4.6593V11.1484C4.6593 11.3828 4.80253 11.5 5.08899 11.5C5.34941 11.5 5.47961 11.3828 5.47961 11.1484L5.47961 4C5.47961 3.55729 5.32336 3.16667 5.01086 2.82812C4.67232 2.48958 4.2817 2.32031 3.83899 2.32031C3.39628 2.32031 3.00566 2.48958 2.66711 2.82812C2.32857 3.16667 2.1593 3.55729 2.1593 4L2.1593 12.75C2.1593 13.5573 2.44576 14.2474 3.01868 14.8203C3.59159 15.3932 4.2817 15.6797 5.08899 15.6797C5.89628 15.6797 6.58639 15.3932 7.1593 14.8203C7.70618 14.2474 7.97961 13.5573 7.97961 12.75V4.82031L9.6593 4.82031V12.75C9.6593 14.026 9.21659 15.1068 8.33118 15.9922Z"
        fill={base}
      />
    </svg>
  )
}
