import { colors } from './colors'
import { utilities } from './utilities'

export const progressBar = {
  barColors: {
    progress: colors.buttonHover,
    finished: colors.success,
  },
  backgroundColor: colors.neutral30,
  borderRadius: utilities.border.radius,
  height: `calc(${utilities.border.radius} * 2)`,
  width: '150px',
}
