import type { SetStateAction } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import type { TUseMountedState } from './types'

export const useMountedState = <T>(initialState: T | (() => T)): TUseMountedState<T> => {
  const [state, setState] = useState<T>(initialState)
  const ref = useRef(true)

  useEffect(() => {
    return () => {
      ref.current = false
    }
  }, [])

  const setStateIfMounted = useCallback((newState: SetStateAction<T>) => {
    if (ref.current) {
      setState(newState)
    }
  }, [])

  return [state, setStateIfMounted]
}
