import { TEST_IDS } from '@retire/constants'
import type { FC } from 'react'
import { Container } from 'react-grid-system'

import { LowerFooter } from './LowerFooter'
import { MiddleFooter } from './MiddleFooter'
import { SFooter } from './presentation.styled'
import { UpperFooter } from './UpperFooter'

export const Footer: FC = () => (
  <SFooter data-testid={TEST_IDS.components.footer}>
    <Container>
      <UpperFooter />
      <MiddleFooter />
      <LowerFooter />
    </Container>
  </SFooter>
)
