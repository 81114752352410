import { TypographyColor } from '@retire/theme'
import type { FC } from 'react'

import { Column } from '../../../Column'
import { Spacer } from '../../../Spacer'
import { Copy } from '../../../Typography'
import type { TSelectedSubLabels } from './types'

export const SelectedSubLabels: FC<TSelectedSubLabels> = ({ subLabels }) => (
  <Spacer top="mediumSmall">
    <Column>
      {subLabels?.map(label => (
        <Copy key={label} as="span" color={TypographyColor.neutralDark}>
          {label}
        </Copy>
      ))}
    </Column>
  </Spacer>
)
