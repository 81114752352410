import { css, styled } from '@retire/theme/styledComponents'

import type { TSList } from './types'

const CommonListStyle = css<TSList>`
  padding-left: ${({ compact }) => (compact ? '1.25rem' : '2.5rem')};
  margin: ${({ compact }) => (compact ? '0' : '1rem 0')};

  > li {
    &:not(:last-of-type) {
      margin-bottom: ${({ theme, compact }) => (compact ? theme.spacing.none : theme.spacing.mediumSmall)};
    }
  }
`

export const SUnorderedList = styled.ul`
  ${CommonListStyle}
  padding-left: ${({ compact }) => (compact ? '1.25rem' : '1.75rem')};
  list-style: disc;
`

export const SOrderedList = styled.ol`
  ${CommonListStyle}
  list-style: decimal;

  li::marker {
    font-weight: ${({ theme: { utilities } }) => utilities.weight.bold};
  }
`
