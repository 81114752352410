import { DEFAULT_COUNTRY_CODE } from '@retire/constants/telephone'
import type { TCountryCode, TTelephone } from '@retire/types/telephone'
import { countries } from 'countries-list'
import type { CountryCode } from 'libphonenumber-js'
import { parsePhoneNumber } from 'libphonenumber-js'

import type { TCountryData } from './types'

export const getCountryFromISOCode = (ISOCode: TCountryCode): TCountryData => ISOCode && countries[ISOCode]

export const getCallingCodeFromISOCode = (ISOCode: TCountryCode) => getCountryFromISOCode(ISOCode)?.phone || ''

export const getTelephoneString = (telephoneNumber: TTelephone, countryISOCode: TCountryCode = DEFAULT_COUNTRY_CODE) =>
  `+${getCallingCodeFromISOCode(countryISOCode)} ${telephoneNumber}`

export const getFormattedTelephone = (
  telephoneNumber: TTelephone,
  countryISOCode: TCountryCode = DEFAULT_COUNTRY_CODE
) => {
  const telephoneString = getTelephoneString(telephoneNumber, countryISOCode)
  try {
    if (countryISOCode) {
      return parsePhoneNumber(telephoneString, countryISOCode as CountryCode)?.formatInternational()
    }
  } catch (_error) {
    // Do nothing, return default value
  }
  return telephoneString
}
