import type { TPickAndRequired } from '@retire/types'

import type { TSpacer } from './types'

export const DEFAULT_PROPS: TPickAndRequired<TSpacer, 'top' | 'right' | 'bottom' | 'left'> = {
  top: 'none',
  right: 'none',
  bottom: 'none',
  left: 'none',
}
