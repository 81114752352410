import { HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'
import { Visible } from 'react-grid-system'

import { Divider } from '../Divider'
import { Spacer } from '../Spacer'
import { Copy, Headline } from '../Typography'
import { DesktopTable } from './Desktop'
import { MobileTable } from './Mobile'
import type { TTable } from './types'

export const Table: FC<TTable> = ({ title, caption, ...tableProps }) => {
  return (
    <>
      {title && (
        <>
          <Headline level={HeadlineLevel.h3} as="h2">
            {title}
          </Headline>
          {caption && (
            <Spacer top="medium">
              <Copy as="p">{caption}</Copy>
            </Spacer>
          )}
          <Visible md lg xl>
            <Spacer bottom="extraLarge" />
          </Visible>
          <Visible xs sm>
            <Divider bottom="medium" top="large" />
          </Visible>
        </>
      )}
      <Visible md lg xl>
        <DesktopTable {...tableProps} />
      </Visible>
      <Visible xs sm>
        <MobileTable {...tableProps} />
      </Visible>
    </>
  )
}
