import { Copy, Spacer } from '@retire/components'
import { DateFormat } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { formatDate } from '@retire/utils'
import type { FC } from 'react'

import { HeadingTwo, Section, SubSection } from '../Common'
import type { TLead } from './types'

export const Lead: FC<TLead> = ({ refNumber }) => {
  const { t } = useTranslationWithOptions('plan')

  return (
    <Section>
      <SubSection>
        <HeadingTwo key="title">{t('yourplaninfull.refLabel', 'Ref label {{refNumber}}', { refNumber })}</HeadingTwo>
        <Spacer bottom="small">
          <Copy as="span">
            {t('quotationDate', 'Quotation date {{quotationDate}}', {
              quotationDate: formatDate(new Date().getTime(), DateFormat.longDate),
            })}
          </Copy>
        </Spacer>
      </SubSection>
    </Section>
  )
}
