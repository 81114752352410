import { NavButtons } from '@retire/components/NavButtons'
import { Notification } from '@retire/components/Notification'
import { Spacer } from '@retire/components/Spacer'
import { Table } from '@retire/components/Table'
import { Copy } from '@retire/components/Typography/Copy'
import { Headline } from '@retire/components/Typography/Headline'
import { useFullScreenModal } from '@retire/hooks/useFullScreenModal'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { HeadlineLevel } from '@retire/theme/typography'
import { toPennies } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ConfirmMoveMoneyPotTable } from '../../components/ConfirmMoveMoneyRequest/ConfirmMoveMoneyPotTable'
import { useTableDetails } from '../../components/ConfirmMoveMoneyRequest/hooks/useTableDetails'
import { NotificationTitle } from '../../components/ConfirmMoveMoneyRequest/NotificationTitle'
import { BANK_OR_SOCIETY_ACCOUNT } from '../../components/MoveMoneyBetweenPots/constants'
import { MoveMoneyThankYou } from '../../components/MoveMoneyThankYou'
import { instructionPotTypes } from '../../gql/constants'
import { MOVE_MONEY_MUTATION, WITHDRAW_MONEY_MUTATION } from '../../gql/mutations'
import { HOLDINGS_QUERY } from '../../gql/queries'
import type { TConfirmMoveMoneyRequest } from './types'

export const ConfirmMoveMoneyRequest: FC<TConfirmMoveMoneyRequest> = ({ fromPot, toPotOrAccount, amount }) => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const { Modal: ThankYouModal, onShowModal } = useFullScreenModal()
  const {
    data: { regularIncomePaused },
  } = useUserDetails()
  const isBankOrSocietyAccount = toPotOrAccount === BANK_OR_SOCIETY_ACCOUNT
  const penniesAmount = toPennies(amount)

  const { columnHeaders, rows } = useTableDetails(
    [
      {
        potName: fromPot,
        amount: penniesAmount,
      },
    ],
    isBankOrSocietyAccount ? undefined : toPotOrAccount
  )

  const [moveMoney, { loading: moveMoneyLoading }] = useMutationWithError(MOVE_MONEY_MUTATION, {
    onCompleted: () => onShowModal(),
    refetchQueries: [{ query: HOLDINGS_QUERY }],
  })
  const [withdrawMoney, { loading: withdrawMoneyLoading }] = useMutationWithError(WITHDRAW_MONEY_MUTATION, {
    onCompleted: () => onShowModal(),
    refetchQueries: [{ query: HOLDINGS_QUERY }],
  })

  useActivatePageOverlay()

  const onNextClick = useCallback(async () => {
    if (isBankOrSocietyAccount) {
      await withdrawMoney({
        variables: {
          amount: penniesAmount,
        },
      })
    } else {
      await moveMoney({
        variables: {
          instructions: [
            {
              amount: penniesAmount,
              buyPotType: instructionPotTypes[toPotOrAccount],
              sellPotType: instructionPotTypes[fromPot],
            },
          ],
        },
      })
    }
  }, [fromPot, isBankOrSocietyAccount, moveMoney, penniesAmount, toPotOrAccount, withdrawMoney])

  const onBackClick = useCallback(() => navigate(-1), [navigate])
  const handleQuit = useCallback(() => navigate(-3), [navigate])

  return (
    <PageTemplate
      loading={moveMoneyLoading || withdrawMoneyLoading}
      title={t('pots.common.confirmMoveMoneyRequest.title', 'Review')}
      fullWidth
      onQuit={handleQuit}
    >
      <Table
        title={t('pots.common.confirmMoveMoneyRequest.tables.columns.details', 'Details')}
        columnHeaders={columnHeaders}
        rows={rows}
      />
      {isBankOrSocietyAccount && (
        <Spacer top="medium" bottom="medium">
          <Notification type="notice" title={<NotificationTitle />} hasBorder />
        </Spacer>
      )}
      <Spacer top="extraLarge">
        <ConfirmMoveMoneyPotTable
          potName={fromPot}
          fromPotName={fromPot}
          amount={amount}
          isRegularIncomePaused={regularIncomePaused}
        />
      </Spacer>
      <Spacer top="extraLarge">
        {!isBankOrSocietyAccount && (
          <ConfirmMoveMoneyPotTable
            potName={toPotOrAccount}
            fromPotName={fromPot}
            amount={amount}
            isRegularIncomePaused={regularIncomePaused}
          />
        )}
      </Spacer>
      <Spacer bottom="large" top="large">
        <Headline level={HeadlineLevel.h2} as="h2">
          {t('pots.common.confirmMoveMoneyRequest.howLongItTakes.title', 'How long it takes')}
        </Headline>
      </Spacer>
      <Spacer bottom="large">
        <Copy as="div">
          {t(
            'pots.common.confirmMoveMoneyRequest.howLongItTakes.description',
            'It will take up to 8 days to move your money.'
          )}
        </Copy>
      </Spacer>
      <NavButtons
        nextText={t('pots.common.confirmMoveMoneyRequest.actions.complete', 'Complete')}
        onNext={onNextClick}
        backText={t('pots.common.confirmMoveMoneyRequest.actions.back', 'Back')}
        onBack={onBackClick}
      />
      <ThankYouModal>
        <MoveMoneyThankYou />
      </ThankYouModal>
    </PageTemplate>
  )
}
