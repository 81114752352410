import { styled } from '@retire/theme/styledComponents'
import { DEFAULT_TEXT_ALIGN } from '@retire/utils'

import type { TSInput } from './types'

export const SInput = styled.input<TSInput>`
  border-radius: ${({ theme: { forms } }) => forms.shared.border.radius};
  border-style: ${({ theme: { forms } }) => forms.shared.border.style};
  border-width: ${({ theme: { forms } }) => forms.shared.border.width};

  font-size: ${({ as, theme }) =>
    as === 'textarea' ? theme.forms.input.textArea.fontSize : theme.forms.input.fontSize};
  font-weight: ${({ theme: { forms } }) => forms.input.fontWeight};
  font-family: inherit;
  padding: ${({ theme: { forms } }) => forms.input.padding};
  appearance: none;
  box-sizing: border-box;
  display: block;
  width: ${({ width }) => width || '100%'};
  margin: 0;
  resize: vertical;

  background-color: ${({ theme: { forms } }) => forms.shared.states.default.backgroundColor};
  border-color: ${({ theme: { forms } }) => forms.shared.states.default.borderColor};
  color: ${({ theme: { forms } }) => forms.shared.states.default.color};

  &:hover {
    border-color: ${({ theme: { forms } }) => forms.shared.states.hover.borderColor};
  }

  &:disabled {
    background-color: ${({ theme: { forms } }) => forms.shared.states.disabled.backgroundColor};
    border-color: ${({ theme: { forms } }) => forms.shared.states.disabled.borderColor};
    color: ${({ theme: { forms } }) => forms.shared.states.disabled.color};
  }

  &:not(:disabled) {
    &:active,
    &:focus {
      border-color: ${({ theme: { forms } }) => forms.shared.states.active.borderColor};
      outline: none;
    }
  }

  ${({ textAlign = DEFAULT_TEXT_ALIGN }) => `text-align: ${textAlign};`}
  ${({ invalid, theme }) => (invalid ? `border-color: ${theme.forms.shared.states.error.borderColor};` : '')}
`
