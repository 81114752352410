import { useThemeContext } from '@retire/hooks'
import { useEffect, useState } from 'react'

import type { TUseIconColor, TUseIconColorResult } from './types'

export const useIconColor = ({ disabled, selected }: TUseIconColor): TUseIconColorResult => {
  const {
    forms: {
      checkboxradio: {
        states: {
          default: { iconColor: defaultColor },
          hover: { iconColor: hoverColor },
          selected: { iconColor: selectedColor },
          focus: { iconColor: focusColor },
        },
      },
    },
  } = useThemeContext()

  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [iconColor, setIconColor] = useState(defaultColor)

  useEffect(() => {
    switch (true) {
      case disabled:
        setIconColor(defaultColor)
        break
      case selected && focus:
      case hover && focus:
        setIconColor(focusColor)
        break
      case selected:
        setIconColor(selectedColor)
        break
      case focus:
        setIconColor(focusColor)
        break
      case hover:
        setIconColor(hoverColor)
        break
      default:
        setIconColor(defaultColor)
        break
    }
  }, [disabled, hover, focus, selected, defaultColor, focusColor, selectedColor, hoverColor])

  return {
    iconColor,
    setFocus,
    setHover,
  }
}
