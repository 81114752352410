import type { TTable } from '@retire/components/Table'
import { Table } from '@retire/components/Table'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { toCurrency } from '@retire/utils/currency'
import { formatDifference } from '@retire/utils/strings'
import type { FC } from 'react'
import { useMemo } from 'react'

import { ManageIncomeTableRows } from '../constants'
import type { TLaterLifePotReviewTable } from '../types'

export const LaterLifePotReviewTable: FC<TLaterLifePotReviewTable> = ({ monthlyIncome, startAge, rowsByOrder }) => {
  const { t } = useTranslationWithOptions('dashboard')

  const { rows, columnHeaders }: TTable = useMemo(() => {
    const ageDifference = startAge.new - startAge.current
    const rowsMap = {
      [ManageIncomeTableRows.age]: {
        rowHeader: { content: t('pots.common.reviewChanges.table.rows.time', 'Time') },
        cells: [
          {
            content: t('pots.common.reviewChanges.table.cells.laterLifeAge', '{{startAge}}, for life', {
              startAge: startAge.current,
            }),
          },
          {
            content:
              Math.abs(ageDifference) === 1
                ? t('pots.common.reviewChanges.table.cells.oneYearDiff', '{{diff}} year', { diff: ageDifference })
                : t('pots.common.reviewChanges.table.cells.fewYearsDiff', '{{diff}} years', { diff: ageDifference }),
          },
          {
            content: t('pots.common.reviewChanges.table.cells.laterLifeAge', '{{startAge}}, for life', {
              startAge: startAge.new,
            }),
          },
        ],
      },
      [ManageIncomeTableRows.amount]: {
        rowHeader: { content: t('pots.common.reviewChanges.table.rows.monthlyIncome', 'Monthly amount (before tax)') },
        cells: [
          { content: toCurrency(monthlyIncome.current) },
          { content: formatDifference(monthlyIncome.new - monthlyIncome.current) },
          { content: toCurrency(monthlyIncome.new) },
        ],
      },
    }

    return {
      rows: rowsByOrder.map(key => rowsMap[key]),
      columnHeaders: [
        { content: '', width: '25%' },
        { content: t('pots.common.reviewChanges.table.columns.currentValue', 'Current'), width: '25%' },
        { content: t('pots.common.reviewChanges.table.columns.movedAmount', 'Change'), width: '25%' },
        { content: t('pots.common.reviewChanges.table.columns.newValue', 'New'), width: '25%' },
      ],
    }
  }, [startAge, t, monthlyIncome, rowsByOrder])

  return <Table rows={rows} columnHeaders={columnHeaders} />
}
