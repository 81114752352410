import { useMutation } from '@apollo/client'
import { PartialLoader } from '@retire/components'
import { INIT_QUERY, USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'
import { useTranslationWithOptions } from '@retire/hooks'
import { PathParam, paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { isActiveSession } from '@retire/utils'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CONFIRM_EMAIL_MUTATION } from './gql/mutations'
import { PartialLoaderContainer } from './presentation.styled'

export const ConfirmEmail: FC = () => {
  const { t } = useTranslationWithOptions('enrolment')
  const navigate = useNavigate()
  const token = useParams()[PathParam.token]

  // do the token validation
  const [confirmUserEmail] = useMutation(CONFIRM_EMAIL_MUTATION, {
    refetchQueries: isActiveSession() ? [{ query: USER_FULL_DETAILS_QUERY }, { query: INIT_QUERY }] : [],
    onError: () => navigate(paths.other.emailVerificationError),
    onCompleted: data =>
      navigate(data?.confirmUserEmail ? paths.other.emailVerified : paths.other.emailVerificationError),
  })

  useEffect(() => {
    void confirmUserEmail({ variables: { confirmationToken: token || '' } })
  }, [confirmUserEmail, token])

  return (
    <PageTemplate
      title={
        <>
          <PartialLoaderContainer withSpacing>
            <PartialLoader />
          </PartialLoaderContainer>
          {t('emailConfirming.title')}
        </>
      }
      subTitle={t('emailConfirming.description')}
    />
  )
}
