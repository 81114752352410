import { Button } from '@retire/components/Button'
import { Card, CardTitle } from '@retire/components/Card'
import { FundCardContent } from '@retire/components/FundCardContent'
import { Notification } from '@retire/components/Notification'
import { SelectableCard } from '@retire/components/SelectableCard/presentation'
import { Spacer } from '@retire/components/Spacer'
import { ButtonStyleType } from '@retire/constants/button'
import { PotName } from '@retire/constants/pots'
import { PotType } from '@retire/gql/__generated__/graphql'
import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import { usePotFunds } from '@retire/hooks/usePotFunds'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePotsContext } from '../../../hooks/usePotsContext'

export const InheritancePotFundsCard: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const { currentFund } = usePotFunds(PotType.Inheritance)
  const isEnsignUser = useIsEnsignUser()
  const {
    pots: {
      [PotName.inheritance]: { allocatedAmount, isLockedForBuys, isLockedForSells },
    },
  } = usePotsContext()
  const onChangeFundsClick = useCallback(() => {
    navigate(paths.dashboard.inheritancePotFunds)
  }, [navigate])

  const isLocked = isLockedForBuys || isLockedForSells

  return (
    <Card>
      <CardTitle>{t('pots.inheritancePot.investmentFunds.title', 'Investment Funds')}</CardTitle>
      <Notification
        type="notice"
        hasBorder
        title={t('pots.inheritancePot.investmentFunds.notification', 'Investment Funds notification')}
      ></Notification>

      {currentFund && (
        <Spacer top="medium">
          <SelectableCard>
            <FundCardContent {...currentFund} />
          </SelectableCard>
        </Spacer>
      )}

      {!isLocked && !!allocatedAmount && currentFund && isEnsignUser && (
        <Button onClick={onChangeFundsClick} buttonStyleType={ButtonStyleType.secondary}>
          {t('pots.inheritancePot.investmentFunds.button', 'Change funds')}
        </Button>
      )}
    </Card>
  )
}
