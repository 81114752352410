import { graphql } from '@retire/gql/__generated__/gql'

export const USER_CUSTOM_MONTHLY_INCOME_MUTATION = graphql(`
  mutation updateUserCustomMonthlyIncome(
    $regularIncomeAmount: Int!
    $regularIncomeStartAge: Int
    $regularIncomeEndAge: Int
  ) {
    userUpdate(
      input: {
        regularIncomeAmount: $regularIncomeAmount
        regularIncomeStartAge: $regularIncomeStartAge
        regularIncomeEndAge: $regularIncomeEndAge
      }
    ) {
      id
      regularIncomeAmount
      regularIncomeStartAge
      regularIncomeEndAge
    }
  }
`)
