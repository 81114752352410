import { MAX_DATE_PART } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { isNum } from '@retire/utils'
import type { ChangeEvent } from 'react'
import { forwardRef, useCallback } from 'react'

import { FormTextInput } from '../../TextInput'
import { datePartDesiredLength } from '../utils'
import type { TDatePartInput } from './types'

export const DatePartInput = forwardRef<HTMLInputElement, TDatePartInput>(
  ({ invalid, disabled, name, datePart, onDatePartChange, dateObject, onKeyDown }, ref) => {
    const { t } = useTranslationWithOptions('common')
    const desiredLength = datePartDesiredLength(datePart)

    const updateValue = useCallback(
      (value: string) => {
        const ultimateValue = value === '00' ? '01' : isNum(value) ? value : ''
        onDatePartChange(datePart, ultimateValue.slice(0, desiredLength))
      },
      [datePart, desiredLength, onDatePartChange]
    )

    const trimValue = useCallback(() => {
      if (dateObject[datePart]) {
        onDatePartChange(datePart, dateObject[datePart].padStart(desiredLength, '0'))
      } else {
        onDatePartChange(datePart, '')
      }
    }, [dateObject, datePart, desiredLength, onDatePartChange])

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => updateValue(e.target.value.toString()),
      [updateValue]
    )

    return (
      <FormTextInput
        ref={ref}
        value={dateObject[datePart]}
        autocomplete={`bday-${datePart}`}
        type="number"
        name={`${name}-${datePart}`}
        key={`${name}-${datePart}`}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onBlur={trimValue}
        max={MAX_DATE_PART[datePart].toString()}
        min="1"
        disabled={disabled}
        subLabel={t(`form.date.${datePart}.label`, datePart)}
        invalid={invalid}
        fullWidth
      />
    )
  }
)

DatePartInput.displayName = 'DatePartInput'
