import type { FC } from 'react'
import { useCallback } from 'react'

import { Option } from './Option'
import type { TOptionsGroup, TOptionValue } from './types'

export const OptionsGroup: FC<TOptionsGroup> = ({
  name,
  ariaDescribedBy,
  ariaLabelledBy,
  ariaInvalid,
  multiple = false,
  value,
  onChange,
  options,
  id,
}) => {
  const isOptionSelected = (optionValue: TOptionValue) => {
    return multiple ? Array.isArray(value) && value.includes(optionValue) : value === optionValue
  }

  const onOptionChange = useCallback(
    (optionValue: TOptionValue) => {
      if (onChange) {
        if (multiple) {
          if (Array.isArray(value) && value.includes(optionValue)) {
            return onChange(value.filter(val => val !== optionValue))
          }
          return onChange([...(value || []), optionValue])
        }
        return onChange(optionValue)
      }
    },
    [multiple, onChange, value]
  )

  return (
    <div
      id={id}
      role="radiogroup"
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      aria-invalid={ariaInvalid}
    >
      {options.map(({ value: optionValue, label, description, disabled }) => (
        <Option
          id={`options-${name}-${optionValue}`}
          name={name}
          type={multiple ? 'checkbox' : 'radio'}
          value={optionValue}
          label={label}
          selected={isOptionSelected(optionValue)}
          onChange={onOptionChange}
          key={String(optionValue)}
          description={description}
          disabled={disabled}
        />
      ))}
    </div>
  )
}
