import { DataRow } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { formatDifference, toCurrency } from '@retire/utils'
import type { FC } from 'react'

import type { TPendingAmount } from './types'

export const PendingAmount: FC<TPendingAmount> = ({ loading, pendingAmount }) => {
  const { t } = useTranslationWithOptions('dashboard')
  const showPendingAmount = !loading && typeof pendingAmount === 'number' && pendingAmount !== 0

  if (!showPendingAmount) return null

  if (pendingAmount > 0)
    return <DataRow name={t('pots.common.balanceCard.incoming', 'Incoming')} value={toCurrency(pendingAmount)} />

  return <DataRow name={t('pots.common.balanceCard.outgoing', 'Outgoing')} value={formatDifference(pendingAmount)} />
}
