import { useMemo } from 'react'

import { DropdownIndicator, Option, SingleValue } from '../../components'
import type { TUseComponents, TUseComponentsResult } from './types'

export const useComponents = ({ renderSelectedValue, iconColor }: TUseComponents): TUseComponentsResult =>
  useMemo(
    () => ({
      Option,
      SingleValue: props => <SingleValue renderSelectedValue={renderSelectedValue} {...props} />,
      DropdownIndicator: props => <DropdownIndicator iconColor={iconColor} {...props} />,
      IndicatorSeparator: () => null,
    }),
    [iconColor, renderSelectedValue]
  )
