import { useThemeContext } from '@retire/hooks'
import type { FC } from 'react'
import { useCallback, useState } from 'react'

import { Icon, IconContainer } from '../Icon'
import { Copy } from '../Typography'
import { DEFAULT_PROPS } from './constants'
import { SContent, SLeftStrip, SSummary, STwisterWrapper } from './presentation.styled'
import type { TTwister } from './types'

export const Twister: FC<TTwister> = ({
  title,
  children,
  bottom = DEFAULT_PROPS.bottom,
  noTitleWrap,
  isOpen: defaultIsOpen = DEFAULT_PROPS.isOpen,
  onToggleOpen,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const [hover, setHover] = useState(false)
  const {
    twister: {
      summary: {
        active: { color: activeColor },
        default: { color: defaultColor },
        hover: { color: hoverColor },
      },
    },
  } = useThemeContext()

  const onClick = useCallback(() => {
    setIsOpen(previous => !previous)
    onToggleOpen && onToggleOpen()
  }, [setIsOpen, onToggleOpen])

  const onMouseEnter = useCallback(() => setHover(true), [])
  const onMouseLeave = useCallback(() => setHover(false), [])

  return (
    <STwisterWrapper bottom={bottom} isOpen={isOpen} data-cy={`twister-${title}`}>
      <SSummary
        isOpen={isOpen}
        tabIndex={0}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        noTitleWrap={noTitleWrap}
      >
        <IconContainer size="small" withSpacing>
          <Icon
            iconType={isOpen ? 'Minus' : 'Plus'}
            iconColors={{
              base: hover ? hoverColor : isOpen ? activeColor : defaultColor,
            }}
          />
        </IconContainer>
        <u>{title}</u>
      </SSummary>
      {children && isOpen && (
        <SContent>
          <SLeftStrip />
          {typeof children === 'string' ? <Copy as="p">{children}</Copy> : <div>{children}</div>}
        </SContent>
      )}
    </STwisterWrapper>
  )
}
