import { PotName } from '@retire/constants/pots'
import { useGetPotDisplayName } from '@retire/hooks/useGetPotDisplayName'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { getPotAmountKeyFromPotName } from '@retire/utils'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'
import type { TIllustrationPlanData } from '../../../../types'
import { JourneyStepLayout } from '../../JourneyStepLayout'
import type { TPotAmounts } from './types'
import { getFirstSelectedPot } from './utils'

export const PreviewRemaining: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.ready })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()
  const {
    [PotName.flexibleIncome]: {
      defaultStartAge: defaultRegularIncomeStartAge,
      defaultEndAge: defaultRegularIncomeEndAge,
    },
    [PotName.laterLife]: { defaultStartAge: defaultLaterLifeAge },
  } = useSettings()
  const {
    data: { currentAge },
  } = useUserDetails()
  const { getPotDisplayName } = useGetPotDisplayName()

  const { loadingIllustrationPlanData, illustrationPlanData, saveFinalIllustrationPlanData } = useIllustrationPlanData()
  const {
    flexibleIncomeAmount,
    regularIncomeStartAge,
    regularIncomeEndAge,
    laterLifeAmount,
    laterLifeAge,
    inheritanceAmount,
    rainyDayAmount,
    remainingSavings,
  } = illustrationPlanData

  const potAmounts: TPotAmounts = useMemo(
    () => ({
      [PotName.flexibleIncome]: flexibleIncomeAmount,
      [PotName.laterLife]: laterLifeAmount,
      [PotName.inheritance]: inheritanceAmount,
      [PotName.rainyDay]: rainyDayAmount,
    }),
    [flexibleIncomeAmount, inheritanceAmount, laterLifeAmount, rainyDayAmount]
  )

  const firstSelectedPot = getFirstSelectedPot(potAmounts)
  const firstSelectedPotAmountKey = getPotAmountKeyFromPotName(firstSelectedPot)

  const onBack = useCallback(() => navigate(paths.illustration.journeyRainyDayPotIntro), [navigate])
  const onNext = useCallback(async () => {
    const finalIllustrationPlanData: TIllustrationPlanData = {
      ...illustrationPlanData,
      [firstSelectedPotAmountKey]: (potAmounts[firstSelectedPot] || 0) + remainingSavings,
    }
    switch (firstSelectedPot) {
      case PotName.flexibleIncome: {
        finalIllustrationPlanData.regularIncomeStartAge = currentAge
        if (regularIncomeStartAge) {
          finalIllustrationPlanData.regularIncomeStartAge = regularIncomeStartAge
        } else {
          finalIllustrationPlanData.regularIncomeStartAge = Math.max(defaultRegularIncomeStartAge, currentAge)
        }
        finalIllustrationPlanData.regularIncomeEndAge = finalIllustrationPlanData.regularIncomeStartAge + 1
        if (regularIncomeEndAge) {
          finalIllustrationPlanData.regularIncomeEndAge = regularIncomeEndAge
        } else {
          finalIllustrationPlanData.regularIncomeEndAge = Math.max(
            defaultRegularIncomeEndAge,
            finalIllustrationPlanData.regularIncomeStartAge + 1
          )
        }
        break
      }
      case PotName.laterLife: {
        finalIllustrationPlanData.laterLifeAge = currentAge
        if (laterLifeAge) {
          finalIllustrationPlanData.laterLifeAge = laterLifeAge
        } else {
          finalIllustrationPlanData.laterLifeAge = Math.max(defaultLaterLifeAge, currentAge)
        }
        break
      }
    }
    await saveFinalIllustrationPlanData(finalIllustrationPlanData)
  }, [
    currentAge,
    defaultLaterLifeAge,
    defaultRegularIncomeEndAge,
    defaultRegularIncomeStartAge,
    firstSelectedPot,
    firstSelectedPotAmountKey,
    illustrationPlanData,
    laterLifeAge,
    potAmounts,
    regularIncomeEndAge,
    regularIncomeStartAge,
    remainingSavings,
    saveFinalIllustrationPlanData,
  ])

  return (
    // @TODO: update description after retiring sandbox FF removed https://smartpension.atlassian.net/browse/CM-489
    <JourneyStepLayout
      title={t('steps.previewRemaining.title', 'Preview remaining title')}
      description={t(
        'steps.previewRemaining.description',
        'Preview remaining description {{potName}} {{remainingSavings}}',
        {
          remainingSavings: toCurrency(remainingSavings),
          potName: getPotDisplayName(firstSelectedPot).toLowerCase(),
        }
      )}
      onNext={onNext}
      nextText={t('steps.previewRemaining.actions.continue', 'Continue')}
      onBack={onBack}
      backText={t('steps.previewRemaining.actions.back', 'Back')}
      hideOverview
      loading={loadingIllustrationPlanData}
    />
  )
}
