import { colors } from './colors'
import { spacing } from './spacing'
import { PRIMARY_FAMILY } from './typography'
import { utilities } from './utilities'

export const twister = {
  border: utilities.border,
  fontFamily: PRIMARY_FAMILY,
  fontSize: '1rem',
  fontWeight: utilities.weight.regular,
  leftStrip: {
    color: colors.neutral30,
    width: '4px',
  },
  summary: {
    active: {
      color: colors.primary,
    },
    default: {
      color: colors.hyperlink,
    },
    focus: {
      borderColor: colors.primary,
      color: colors.buttonHover,
    },
    hover: {
      color: colors.hyperlinkHover,
    },
    padding: spacing.extraSmall,
  },
}
