import { AddressField, ContactField } from '@retire/constants'
import { DEFAULT_COUNTRY_CODE } from '@retire/constants/telephone'

import { aboutYouServices } from './services'

export const DEFAULT_USE_FORM_ARGS = {
  ...aboutYouServices,
  hookFormOptions: {
    defaultValues: {
      [ContactField.telephoneCountryCode]: DEFAULT_COUNTRY_CODE,
    },
  },
}

export const REQUIRED_ADDRESS_FIELDS = [
  AddressField.line1,
  AddressField.line2,
  AddressField.city,
  AddressField.postcode,
]
export const ADDRESS_FIELDS = Object.values(AddressField)
