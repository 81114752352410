import { useSettings } from '@retire/hooks/useSettings'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import type { TUsePotInsufficientSavings } from './types'

export const usePotInsufficientSavings: TUsePotInsufficientSavings = ({
  remainingSavings,
  potName,
  insufficientSavingsPath,
  skip,
}) => {
  const navigate = useNavigate()
  const {
    [potName]: { minAmount },
  } = useSettings()

  useEffect(() => {
    if (!skip && remainingSavings < minAmount) {
      navigate(insufficientSavingsPath)
    }
  }, [insufficientSavingsPath, navigate, potName, remainingSavings, minAmount, skip])
}
