import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

export const SmallLogo: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Smart Retire</title>
      <path
        d="M14.5281 0C6.50472 0 0 6.50473 0 14.5281C0 22.5515 6.50472 29.0562 14.5281 29.0562C22.5515 29.0562 29.0562 22.552 29.0562 14.5281C29.0562 6.50416 22.552 0 14.5281 0ZM19.0239 16.3288C17.0376 20.3853 12.8545 23.2756 8.00918 24.0883C7.69762 24.1405 7.58241 23.6938 7.87979 23.5866C9.72985 22.9181 13.3085 21.0896 15.1336 18.2044C16.6023 16.0518 17.5109 11.7916 8.48588 12.7439C8.19191 12.7751 8.06422 12.3114 8.33549 12.1962C11.2212 10.9727 13.4209 10.7264 15.629 10.6997C18.2566 10.7258 21.2343 11.9885 19.0239 16.3288ZM19.2384 9.64416C18.0795 9.64416 17.1386 8.60676 17.1386 7.32704C17.1386 6.04732 18.0778 5.00935 19.2384 5.00935C20.3989 5.00935 21.3381 6.04675 21.3381 7.32704C21.3381 8.60733 20.3944 9.64416 19.2384 9.64416Z"
        fill={base}
      />
    </svg>
  )
}
