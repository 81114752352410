import { Link } from '@retire/components/Link'
import { UnorderedList } from '@retire/components/List'
import { Copy } from '@retire/components/Typography/Copy'
import { MEMBERS_BOOKLET_QUERY } from '@retire/gql/membersBooklet/queries'
import type { TMembersBookletQueryResponse } from '@retire/gql/membersBooklet/types'
import { useTranslationWithOptions } from '@retire/hooks'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { HeadingThree, Section, SubSection } from '../Common'

export const FurtherInformation: FC = () => {
  const { t } = useTranslationWithOptions('plan')
  const { data: bookletData } = useQueryWithError<TMembersBookletQueryResponse>(MEMBERS_BOOKLET_QUERY)
  const bookletURL = bookletData?.membersBooklet?.url

  return (
    <Section>
      <SubSection>
        <Trans
          components={{
            memberBooklet: <Link as="a" href={bookletURL} target="_blank" inline />,
          }}
        >
          {t('furtherInformation.description', 'Further information description')}
        </Trans>
      </SubSection>
      <SubSection>
        <HeadingThree>{t('furtherInformation.managingYourAccount.heading', 'Managing your account')}</HeadingThree>
        <Copy as="div">
          <Trans
            components={{
              list: <UnorderedList />,
              listItem: <li />,
            }}
          >
            {t('furtherInformation.managingYourAccount.description', 'Manage your Smart Retire account items')}
          </Trans>
        </Copy>
      </SubSection>
    </Section>
  )
}
