import { styled } from '@retire/theme/styledComponents'

import type { TPageLayout } from './types'

export const SSection = styled.section<Pick<TPageLayout, 'whiteBg'>>`
  ${({
    theme: {
      pot: { potColor },
    },
    color,
    whiteBg,
  }) => `
    flex: 1 1 auto;
    position: relative;
    border-top: ${color ? `12px solid ${potColor[color]}` : ''};
    ${whiteBg ? 'background: #FFF;' : ''}
  `}
`
