import { colors } from './colors'
import { spacing } from './spacing'
import { utilities } from './utilities'

export const overview = {
  numbers: {
    borderWidth: '2px',
    marginRight: spacing.small,
    size: '1.4rem',
    fontSize: '0.875rem',
    fontWeight: utilities.weight.extraBold,
    default: {
      backgroundColor: colors.white,
      borderColor: colors.neutral30,
      color: colors.neutral,
    },
    inProgress: {
      backgroundColor: colors.white,
      borderColor: colors.primary,
      color: colors.primary,
    },
    completed: {
      backgroundColor: colors.success,
      borderColor: colors.success,
      color: colors.white,
    },
    remaining: {
      backgroundColor: colors.infoBackground,
      borderColor: colors.info,
    },
  },
}
