import { Divider, FormDate, FormPercentInput, FormTextInput, Headline, Link, Spacer } from '@retire/components'
import { MAX_BENEFICIARY_SHARE_VALUE, MIN_BENEFICIARY_SHARE_VALUE } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { HeadlineLevel } from '@retire/theme'
import { isDate, isDateInPast } from '@retire/utils'
import type { FC } from 'react'
import { useCallback } from 'react'

import type { TBeneficiaryForm } from '../../types'
import { BeneficiaryFormField } from './constants'

export const BeneficiaryForm: FC<TBeneficiaryForm> = ({ index, removeItem }) => {
  const { t } = useTranslationWithOptions('common')
  const shareRangeErrorMessage = t(
    'account.beneficiaries.form.percentage.validations.range',
    'Share must be between {{min}} and {{max}}%',
    {
      min: MIN_BENEFICIARY_SHARE_VALUE,
      max: MAX_BENEFICIARY_SHARE_VALUE,
    }
  )

  const onClick = useCallback(() => removeItem(index), [index, removeItem])

  return (
    <>
      <Spacer bottom="large">
        <Headline level={HeadlineLevel.h3} as="h2">
          {t('beneficiary.ordinal', {
            count: index + 1,
            ordinal: true,
          })}
        </Headline>
      </Spacer>
      <Spacer bottom="large">
        <FormTextInput
          name={`beneficiaries.${index}.${BeneficiaryFormField.name}`}
          rules={{
            required: String(t('account.beneficiaries.form.name.validation', 'Invalid name')),
          }}
          label={t('account.beneficiaries.form.name.label', 'Beneficiary name')}
          required
        />
      </Spacer>
      <Spacer bottom="large">
        <FormDate
          name={`beneficiaries.${index}.${BeneficiaryFormField.bornOn}`}
          label={t('account.beneficiaries.form.bornOn.label', 'Beneficiary date of birth')}
          rules={{
            validate: {
              format: isDate(t('account.beneficiaries.form.bornOn.validations.wrongFormat', 'Wrong format')),
              inPast: isDateInPast(
                t('account.beneficiaries.form.bornOn.validations.dateMustBeInPast', 'Date must be in the past')
              ),
            },
          }}
        />
      </Spacer>
      <Spacer bottom="large">
        <FormTextInput
          name={`beneficiaries.${index}.${BeneficiaryFormField.relation}`}
          label={t('account.beneficiaries.form.relationship.label', 'Beneficiary relationship')}
        />
      </Spacer>
      <Spacer bottom="extraLarge">
        <FormPercentInput
          name={`beneficiaries.${index}.${BeneficiaryFormField.percentage}`}
          label={t('account.beneficiaries.form.percentage.label', 'Beneficiary pension share')}
          subLabel={t('account.beneficiaries.share.sublabel', 'Beneficiary pension share sublabel')}
          rules={{
            required: `${t('account.beneficiaries.form.percentage.validations.required', 'Missing pension share')}`,
            min: {
              value: MIN_BENEFICIARY_SHARE_VALUE,
              message: shareRangeErrorMessage,
            },
            max: {
              value: MAX_BENEFICIARY_SHARE_VALUE,
              message: shareRangeErrorMessage,
            },
          }}
          required
        />
      </Spacer>
      <Spacer bottom="large">
        <Link as="button" onClick={onClick}>
          {t('account.beneficiaries.actions.remove.label', 'Remove beneficiary')}
        </Link>
      </Spacer>
      <Divider bottom="large" />
    </>
  )
}
