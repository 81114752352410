import { graphql } from '@retire/gql/__generated__/gql'

// @TODO: remove this, and use USER_ENROLMENT_MUTATION instead https://smartpension.atlassian.net/browse/CM-1410
export const USER_COBS_MUTATION = graphql(`
  mutation updateUserCobs($cobs: EnrolmentAttributes) {
    userUpdate(enrolment: $cobs, input: {}) {
      id
      ...cobs__User
    }
  }
`)
