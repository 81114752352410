import type { TOption } from '@retire/components/Forms/Dropdown'
import { Spacer } from '@retire/components/Spacer'
import { PotName } from '@retire/constants/pots'
import { useGetPotDisplayName } from '@retire/hooks/useGetPotDisplayName'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { toCurrency } from '@retire/utils/currency'
import { useMemo } from 'react'

import { usePotsContext } from '../../../../hooks/usePotsContext'
import { BANK_OR_SOCIETY_ACCOUNT } from '../../constants'
import type { TUsePotOptions } from './types'

export const usePotOptions = ({ disabledPot, disableEmptyPots }: TUsePotOptions = {}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const { pots } = usePotsContext()
  const { getPotDisplayLongName } = useGetPotDisplayName()

  const options: TOption[] = useMemo(
    () =>
      Object.values(pots).map(({ allocatedAmount, potName, isLockedForBuys, isLockedForSells }): TOption => {
        const balanceAmountLabel = t('pots.common.moveMoneyBetweenPots.balanceAmount', `Balance ${allocatedAmount}`, {
          amount: toCurrency(allocatedAmount),
        })
        const subLabels = [balanceAmountLabel]
        const isLocked = isLockedForBuys || isLockedForSells

        return {
          label: `${getPotDisplayLongName(potName)}${
            isLocked ? t('pots.common.moveMoneyBetweenPots.lockedOption', ' (Locked for transaction)') : ''
          }`,
          subLabels,
          value: potName,
          // option is disabled if: pot is disabled, or locked, or its amount is `0` (if the `disableEmptyPots` option is true)
          isDisabled: Boolean(potName === disabledPot || isLocked || (!allocatedAmount && disableEmptyPots)),
          description: (
            <>
              {subLabels.map(label => (
                <Spacer key={label} top="extraSmall">
                  {label}
                </Spacer>
              ))}
            </>
          ),
        }
      }),
    [pots, t, getPotDisplayLongName, disabledPot, disableEmptyPots]
  )
  const isBankOptionExist = options.find(({ value }) => value === BANK_OR_SOCIETY_ACCOUNT)

  if (disabledPot === PotName.rainyDay && !isBankOptionExist) {
    options.push({
      label: t('pots.common.moveMoneyBetweenPots.externalAccount', 'External account'),
      subLabels: [t('pots.common.moveMoneyBetweenPots.bankOrSocietyAccount', 'Your bank or building society account')],
      value: BANK_OR_SOCIETY_ACCOUNT,
      description: (
        <Spacer top="extraSmall">
          {t('pots.common.moveMoneyBetweenPots.bankOrSocietyAccount', 'Your bank or building society account')}
        </Spacer>
      ),
    })
  }

  return options
}
