import type { FC, ForwardedRef } from 'react'
import { forwardRef } from 'react'

import { Option } from './Option'
import { SWrapper } from './presentation.styled'
import type { TListBox } from './types'

export const ListBox: FC<TListBox> = forwardRef(
  ({ labelProps, listBoxProps, optionProps }, ref: ForwardedRef<HTMLUListElement>) => (
    <SWrapper ref={ref} {...labelProps} {...listBoxProps}>
      {optionProps.map(props => (
        <Option {...props} />
      ))}
    </SWrapper>
  )
)

ListBox.displayName = 'ListBox'
