import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'
import { filterObjectKeys } from '@retire/utils'

import type {
  TSuitabilityCheck,
  TSuitabilityCheckMutationResponse,
  TSuitabilityCheckMutationVariables,
  TSuitabilityCheckQueryResponse,
} from '../../gql'
import { SUITABILITY_CHECK_MUTATION, SUITABILITY_CHECK_QUERY } from '../../gql'
import { EMPTY_SUITABILITY_CHECK } from './constants'
import { shouldShowWarningScreen } from './utils'

const fetchData: TFormFetchData<TSuitabilityCheck> = async ({ apolloQuery }) => {
  const {
    data: {
      user: { suitabilityCheck },
    },
  } = await apolloQuery<TSuitabilityCheckQueryResponse>({ query: SUITABILITY_CHECK_QUERY })

  return { formData: filterObjectKeys({ ...EMPTY_SUITABILITY_CHECK, ...suitabilityCheck }, ['__typename']) }
}

const submitData: TFormSubmitData<TSuitabilityCheck> = async ({ formData, apolloMutate }) => {
  const formDataWithWarningScreen = {
    ...formData,
    hasSeenWarningScreen: shouldShowWarningScreen(formData),
  }
  await apolloMutate<TSuitabilityCheckMutationResponse, TSuitabilityCheckMutationVariables>({
    mutation: SUITABILITY_CHECK_MUTATION,
    variables: formDataWithWarningScreen,
    refetchQueries: [{ query: SUITABILITY_CHECK_QUERY }],
  })

  return formDataWithWarningScreen
}

export const suitabilityCheckServices = {
  fetchData,
  submitData,
}
