import { extendTheme } from '@chakra-ui/react'
import type { BrightTheme } from '@smartpension/bright__experimental'
import { defaultTheme } from '@smartpension/bright__experimental'

import { colors } from '../colors'
import { progressBar } from '../progressBar'

export const brightTheme = extendTheme(defaultTheme, {
  colors: {
    baseOne: {
      baseOne: {
        core: colors.transparent,
      },
    },
    baseTwo: {
      baseOne: {
        contrast: progressBar.backgroundColor,
      },
      baseTwo: {
        contrast: progressBar.backgroundColor,
      },
    },
    positivePrimary: {
      baseOne: {
        core: progressBar.barColors.progress,
      },
      baseTwo: {
        core: progressBar.barColors.finished,
      },
    },
  },
  sizes: {
    'block-children-max-width': '100%', // to have ProgressIndicator full-width
  },
}) as BrightTheme
