import { styled } from '@retire/theme/styledComponents'

export const SInputLabel = styled.label`
  font-weight: ${props => props.theme.forms.label.fontWeight};
  font-size: ${props => props.theme.forms.label.fontSize};
  line-height: ${props => props.theme.forms.label.lineHeight};
  color: ${props => props.theme.forms.label.color};
`

export const SInputSubLabel = styled.p`
  font-size: ${props => props.theme.forms.subLabel.fontSize};
  line-height: ${props => props.theme.forms.subLabel.lineHeight};
  color: ${props => props.theme.forms.subLabel.color};
`
