import { AuthOutlet } from '@retire/navigation/AuthOutlet'
import { paths, roots } from '@retire/navigation/paths'
import type { RouteObject } from 'react-router'

import { MessageDetailContainer as Message, MessagesContainer as Messages } from './pages'

export const inboxRoutes: RouteObject[] = [
  {
    path: roots.inbox,
    element: <AuthOutlet />,
    children: [
      { path: paths.inbox.messages, element: <Messages /> },
      { path: paths.inbox.message, element: <Message /> },
    ],
  },
]
