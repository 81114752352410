import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import { SkipBanner } from './presentation'

export const SkipBannerContainer: FC = () => {
  const { t } = useTranslationWithOptions('common')

  return <SkipBanner>{t('actions.skip', 'Skip to main content')}</SkipBanner>
}
