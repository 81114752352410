import { Copy, FormNumberInput, Spacer } from '@retire/components'
import type { TGroupOption } from '@retire/components/Forms/OptionsGroup/types'
import { useTranslationWithOptions } from '@retire/hooks'
import { useSettings } from '@retire/hooks/useSettings'
import { CopyLevel } from '@retire/theme'
import { toCurrency, toPercentage, toPounds } from '@retire/utils'
import type { FormEvent } from 'react'
import { useCallback, useMemo } from 'react'

import { AMOUNT_INPUT_NAME, AmountToMoveMethod } from '../../constants'
import type { TUseAmountOptions } from './types'

export const useAmountOptions: TUseAmountOptions = ({
  disabled,
  fromPotBalance = 0,
  moveMoneyMethod,
  setCustomAmount,
  toPotOrAccount,
}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const { maxPotAmountRatio, ...settings } = useSettings()

  const maxCustomAmountToMove = Math.floor(fromPotBalance * maxPotAmountRatio)
  const minCustomAmountToMove =
    toPotOrAccount && toPotOrAccount !== 'bankOrSocietyAccount' ? settings[toPotOrAccount].minAmount : 0

  const translations = useMemo(
    () => ({
      customAmount: {
        label: t('pots.common.moveMoneyBetweenPots.amountSection.customAmountOption.label', 'Choose amount'),
        description: t(
          'pots.common.moveMoneyBetweenPots.amountSection.customAmountOption.description',
          'Up to {{amount}} ({{maximumRatio}} of pot balance)',
          {
            amount: toCurrency(maxCustomAmountToMove),
            maximumRatio: toPercentage(maxPotAmountRatio, 0),
          }
        ),
      },
      amount: {
        errors: {
          min: t(
            'pots.common.moveMoneyBetweenPots.amountSection.customAmountOption.errors.minValue',
            'Move {{amount}} or more',
            {
              amount: toCurrency(minCustomAmountToMove),
            }
          ),
          max: t(
            'pots.common.moveMoneyBetweenPots.amountSection.customAmountOption.errors.maxValue',
            'Move up to {{amount}} or take all your money',
            {
              amount: toCurrency(maxCustomAmountToMove),
            }
          ),
        },
      },
      fullPotBalance: {
        label: t('pots.common.moveMoneyBetweenPots.amountSection.fullPotBalanceOption.label', 'Move pot balance'),
        description: t(
          'pots.common.moveMoneyBetweenPots.amountSection.fullPotBalanceOption.description',
          `${fromPotBalance} - final amount may change`,
          {
            amount: toCurrency(fromPotBalance),
          }
        ),
      },
    }),
    [fromPotBalance, maxCustomAmountToMove, maxPotAmountRatio, minCustomAmountToMove, t]
  )

  const onCustomAmountChange = useCallback(
    (event: FormEvent<HTMLInputElement> | string) => {
      if (typeof event === 'string') {
        setCustomAmount(event)
      } else {
        setCustomAmount(event?.currentTarget?.value)
      }
    },
    [setCustomAmount]
  )

  const options: TGroupOption[] = useMemo(
    () => [
      {
        id: `amount-${AmountToMoveMethod.customAmount}-radio`,
        value: AmountToMoveMethod.customAmount,
        label: translations.customAmount.label,
        description: !disabled && (
          <Copy as="div" level={CopyLevel.body1}>
            {translations.customAmount.description}

            {moveMoneyMethod === AmountToMoveMethod.customAmount && (
              <Spacer top="small" bottom="small">
                <FormNumberInput
                  name={AMOUNT_INPUT_NAME}
                  withoutThousandDelimiter
                  inputWidth="250px"
                  width="250px"
                  isCurrency
                  onChange={onCustomAmountChange}
                  autoFocus
                  rules={{
                    required: true,
                    min: minCustomAmountToMove
                      ? {
                          value: toPounds(minCustomAmountToMove),
                          message: translations.amount.errors.min,
                        }
                      : undefined,
                    max: {
                      value: toPounds(maxCustomAmountToMove),
                      message: translations.amount.errors.max,
                    },
                  }}
                />
              </Spacer>
            )}
          </Copy>
        ),
        disabled,
      },
      {
        id: `amount-${AmountToMoveMethod.fullPotBalance}-radio`,
        value: AmountToMoveMethod.fullPotBalance,
        label: translations.fullPotBalance.label,
        description: !disabled && translations.fullPotBalance.description,
        disabled,
      },
    ],
    [disabled, maxCustomAmountToMove, minCustomAmountToMove, moveMoneyMethod, onCustomAmountChange, translations]
  )

  return options
}
