import { styled } from '@retire/theme/styledComponents'

import { Segment } from '../../Segment'
import { PotStyleVariant } from '../constants'
import type { TColorBar, TInnerWrapper, TOuterWrapper } from './types'

export const SOuterWrapper = styled('div')<TOuterWrapper>`
  display: flex;
  flex-direction: ${({ colorBarPosition }) => (colorBarPosition === 'top' ? 'column' : 'row')};
  width: 100%;
`

export const SInnerWrapper = styled(Segment)<TInnerWrapper>`
  ${({ styleVariant }) => (styleVariant === PotStyleVariant.vertical ? 'display: flex;' : '')}
  flex: 1 1 auto;
  max-width: 100%;
  margin: 0;

  ${({ colorBarPosition }) =>
    colorBarPosition === 'top'
      ? `
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-top: 0;
        `
      : `
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        `}

  ${({ smallPadding, theme }) => (smallPadding ? `padding: ${theme.spacing.medium} !important;` : '')}
  ${({ greyOutBackground, theme }) => (greyOutBackground ? `background-color: ${theme.pot.disabledBg};` : '')}
`

export const SColorBar = styled.div<TColorBar>`
  ${({ theme: { pot }, color }) => `
    border-radius: ${pot.colorBar.radius};
    background-color: ${pot.potColor[color]};
    flex-basis: ${pot.colorBar.width};
  `}

  ${({ colorBarPosition }) =>
    colorBarPosition === 'top'
      ? `
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : `
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          flex-shrink: 0;
        `}
`
