import { styled } from '@retire/theme/styledComponents'

import type { TSSpinner } from './types'

export const SWrapper = styled.div`
  margin: auto;
  inset: 0;
`

export const SSpinner = styled.div<TSSpinner>`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${({ size, theme: { loader, colors } }) => `
    width: ${loader.size[size]};
    height: ${loader.size[size]};
    border-width: ${loader.thickness[size]};
    border-style: solid;
    border-top-color: ${colors.secondary};
    border-right-color: ${colors.secondary};
    border-bottom-color: ${colors.transparent};
    border-left-color: ${colors.transparent};
  `}

  display: inline-block;
  border-radius: 99999px;
  animation: 0.45s linear 0s infinite spinner;
`
