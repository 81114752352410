import { Breakpoint } from '@retire/theme'
import { styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

export const SFieldset = styled.fieldset`
  display: flex;

  > div:nth-of-type(1),
  > div:nth-of-type(2) {
    max-width: 5.375rem;
    margin-right: 0.5rem;

    ${mediaStyles({ marginRight: '0.25rem' }, { max: Breakpoint.small })}
  }

  > div:last-of-type {
    max-width: 7.375rem;
  }
`
