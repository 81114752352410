import { Key } from '@retire/constants'
import { useThemeContext } from '@retire/hooks'
import type { FC, KeyboardEvent } from 'react'
import { useCallback } from 'react'
import type { CSSObjectWithLabel } from 'react-select'
import ReactSelect from 'react-select'

import type { TSelect } from '../types'
import { CLASSNAME_PREFIX } from './constants'
import { useComponents } from './hooks'
import { SSelectWrapper } from './presentation.styled'

export const Select: FC<TSelect> = ({
  iconColor,
  disabled,
  ariaDescribedBy,
  ariaLabelledBy,
  ariaInvalid,
  selectedOption = null,
  renderSelectedValue,
  options,
  placeholder,
  isOpen,
  setIsOpen,
  onChange,
}) => {
  const {
    forms: {
      shared: {
        border: { width: borderWidth },
      },
      dropdown: {
        option: {
          divider: { color: dividerColor },
          states: {
            selected: {
              color: optionSelectedColor,
              backgroundColor: optionSelectedBackgroundColor,
              fontWeight: optionSelectedFontWeight,
            },
          },
        },
      },
    },
  } = useThemeContext()

  const option = useCallback(
    (provided, { data: { underline }, isSelected }) => ({
      ...provided,
      borderBottomColor: underline ? `${dividerColor} !important` : undefined,
      borderBottomWidth: underline ? `${borderWidth} !important` : undefined,
      color: isSelected ? optionSelectedColor : undefined,
      backgroundColor: isSelected ? optionSelectedBackgroundColor : undefined,
      fontWeight: isSelected ? optionSelectedFontWeight : undefined,
    }),
    [borderWidth, dividerColor, optionSelectedBackgroundColor, optionSelectedColor, optionSelectedFontWeight]
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.key === Key.Enter && setIsOpen(true)
    },
    [setIsOpen]
  )

  const onSelectChange = useCallback(({ value } = {}) => onChange && onChange(value), [onChange])
  const onMenuOpen = useCallback(() => setIsOpen(true), [setIsOpen])
  const onMenuClose = useCallback(() => setIsOpen(false), [setIsOpen])
  const components = useComponents({ iconColor, renderSelectedValue })

  return (
    <SSelectWrapper isOpen={isOpen} tabIndex={-1}>
      <ReactSelect
        classNamePrefix={CLASSNAME_PREFIX}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
        aria-disabled={disabled}
        aria-invalid={ariaInvalid}
        value={selectedOption}
        onKeyDown={onKeyDown}
        onChange={onSelectChange}
        options={options}
        styles={{
          container: provided =>
            ({
              ...provided,
              position: undefined,
              border: 0,
            }) as CSSObjectWithLabel,
          option,
        }}
        components={components}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={isOpen}
        menuPlacement="auto"
        isDisabled={disabled}
        placeholder={placeholder}
      />
    </SSelectWrapper>
  )
}
