import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ThankYouModalContent } from '../../ThankYouModalContent'

export const ThankYouModal: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()

  const onNext = useCallback(() => navigate(paths.dashboard.inheritancePotDetails), [navigate])

  return (
    <ThankYouModalContent
      title={t('pots.inheritancePot.investmentFunds.review.modal.title', 'Your funds have been updated')}
      buttonText={t(
        'pots.inheritancePot.investmentFunds.review.modal.actions.goToInheritancePot',
        'Back to Inheritance pot'
      )}
      onButtonClick={onNext}
    />
  )
}
