import { INIT_QUERY, USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'
import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'

import { USER_EMAIL_MUTATION } from './gql/mutations'
import type { TEmailFormFields } from './types'

const fetchData: TFormFetchData<TEmailFormFields> = async () => ({ formData: { email: '' } })

const submitData: TFormSubmitData<TEmailFormFields> = async ({ formData: { email }, apolloMutate }) => {
  await apolloMutate({
    mutation: USER_EMAIL_MUTATION,
    variables: { email },
    refetchQueries: [{ query: USER_FULL_DETAILS_QUERY }, { query: INIT_QUERY }],
  })

  return { email }
}

export const emailChangingServices = {
  fetchData,
  submitData,
}
