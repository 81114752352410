import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

export const ContentWrapper: FC = ({ children }) => (
  <Row>
    <Col sm={12} md={10} xl={8}>
      {children}
    </Col>
  </Row>
)
