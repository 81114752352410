import { useThemeContext } from '@retire/hooks/useThemeContext'
import { CopyLevel, HeadlineLevel, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { Visible } from 'react-grid-system'

import { IconContainer } from '../../Icon'
import { Link } from '../../Link'
import { Spacer } from '../../Spacer'
import { Copy, Headline } from '../../Typography'
import { getPotIcon } from '../utils'
import { PotValueSection } from './PotValueSection'
import { SActionWrapper, SPotContent, SPotContentWrapper, SPotNameWrapper } from './presentation.styled'
import type { THorizontalPot } from './types'
export const HorizontalPot: FC<THorizontalPot> = ({
  potName,
  disabled,
  isLocked,
  lockedText,
  variant,
  sections,
  stackActionOnMobile,
  description,
  openDetailPage,
  children,
}) => {
  const {
    pot: {
      potColor: { disabled: disabledColor },
    },
  } = useThemeContext()
  const Icon = getPotIcon(variant)

  return (
    <>
      <SPotContentWrapper>
        <div>
          <SPotNameWrapper>
            <IconContainer withSpacing size="small">
              <Icon base={disabled ? disabledColor : undefined} />
            </IconContainer>
            <Link as="a" noDecoration onClick={openDetailPage} title={potName}>
              <Headline level={HeadlineLevel.h3} as="h3" color={disabled ? TypographyColor.neutralDark : undefined}>
                {`${potName} ›`}
              </Headline>
            </Link>
          </SPotNameWrapper>
          {description && (
            <Spacer top="small">
              <Copy as="p" level={CopyLevel.body1}>
                {description}
              </Copy>
            </Spacer>
          )}
          <SPotContent data-cy="pot-value-section">
            {sections &&
              sections.map(sectionProps => (
                <PotValueSection key={sectionProps.title} {...sectionProps} variant={variant} />
              ))}
          </SPotContent>
        </div>
        <Visible xs={!stackActionOnMobile} sm={!stackActionOnMobile} md lg xl>
          <SActionWrapper>
            {isLocked && (
              <Spacer top="medium">
                <Copy as="p" level={CopyLevel.caption} textAlign="center">
                  {lockedText}
                </Copy>
              </Spacer>
            )}
          </SActionWrapper>
        </Visible>
      </SPotContentWrapper>
      {children}
    </>
  )
}
