import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'

export const ApplicationClosed: FC = () => {
  const { t } = useTranslationWithOptions('account')
  const {
    customerService: { phoneNumber },
  } = usePublicSettings()

  return (
    <PageTemplate
      title={t('applicationClosed.pageTitle', 'Account closed title')}
      subTitle={t('applicationClosed.description', 'Account closed description {{phoneNumber}}', { phoneNumber })}
    />
  )
}
