import type { TQueryParam } from '@retire/types'

export const getPathname = () => window?.location.pathname || ''

export const getSearchParams = () => new URLSearchParams(window?.location.search)

export const getUrlWithQueryParam = (param: TQueryParam, url: string) => {
  const newUrl = new URL(url)
  const urlParams = new URLSearchParams(newUrl.search)
  urlParams.set(...param)
  newUrl.search = urlParams.toString()
  return newUrl
}
