import { PotName } from '@retire/constants'
import type { GetInstructionsQuery, GetInstructionsQueryVariables } from '@retire/gql/__generated__/graphql'
import { useQuerySelector } from '@retire/hooks'

import { INSTRUCTIONS_QUERY } from '../../gql'
import { getTransactionsTotalAmountForPot } from '../../utils'
import { DISPLAYED_INSTRUCTION_TYPES } from '../useTransactions/constants'
import { DEFAULT_PENDING_TRANSACTIONS, PENDING_INSTRUCTION_STATES } from './constants'
import type { TPendingTransactions } from './types'

export const usePendingTransactions = () =>
  useQuerySelector<GetInstructionsQuery, TPendingTransactions, GetInstructionsQueryVariables>(
    {
      query: INSTRUCTIONS_QUERY,
      options: {
        variables: { instructionTypes: DISPLAYED_INSTRUCTION_TYPES, states: PENDING_INSTRUCTION_STATES },
        skipGlobalError: true,
      },
    },
    ({ instructions }) => {
      if (!instructions) {
        return DEFAULT_PENDING_TRANSACTIONS
      }
      const pendingAmounts = {}
      Object.values(PotName).forEach(
        potName => (pendingAmounts[potName] = getTransactionsTotalAmountForPot(instructions, potName))
      )

      return {
        pendingTransactions: instructions,
        pendingAmounts,
      }
    },
    DEFAULT_PENDING_TRANSACTIONS
  )
