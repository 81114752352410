import { useApolloClient } from '@apollo/client'
import { ErrorCode } from '@retire/constants'
import { useCallback, useEffect, useMemo } from 'react'
import type { FieldValues, Path, UnpackNestedValue } from 'react-hook-form'
import { useForm as useReactHookForm } from 'react-hook-form'

import { useGlobalErrorContext } from '../useGlobalErrorContext'
import { useLocationAlerts } from '../useLocationAlerts'
import { useMountedState } from '../useMountedState'
import { useTranslationWithOptions } from '../useTranslationWithOptions'
import type { TUseForm } from './types'
import { getErrors } from './utils'

export const useForm = <TData extends FieldValues>({
  submitData,
  fetchData,
  onSubmit,
  hookFormOptions,
  customSaveErrorTitle,
  preSubmitData,
}: TUseForm<TData>) => {
  const { setGlobalError } = useGlobalErrorContext()
  const [isFetchLoading, setIsFetchLoading] = useMountedState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useMountedState(false)
  const isLoading = useMemo(() => isFetchLoading || isSubmitLoading, [isFetchLoading, isSubmitLoading])

  const { t } = useTranslationWithOptions('common')
  const { addAlert, resetAlerts } = useLocationAlerts()
  const { query: apolloQuery, mutate: apolloMutate } = useApolloClient()

  const methods = useReactHookForm<TData>(hookFormOptions)
  const { setError, reset, handleSubmit, getValues } = methods

  const handleSaveErrors = useCallback(
    errors => {
      const { formErrors, generalError } = getErrors(errors)
      Object.entries(formErrors).forEach(([formKey, errorMessage]) => {
        setError(formKey as Path<TData>, { type: 'manual', message: errorMessage })
      })
      addAlert({
        id: 'saving-error',
        type: 'error',
        title:
          (generalError?.code === ErrorCode.form && generalError?.message) ||
          t('errors.saving.title', customSaveErrorTitle || 'Saving error'),
        message: generalError?.code !== ErrorCode.form ? t('errors.saving.message', 'Try again') : undefined,
      })
    },
    [setError, addAlert, t, customSaveErrorTitle]
  )

  const handleFetchErrors = useCallback(
    errors => {
      const { generalError } = getErrors(errors)
      setGlobalError(generalError)
    },
    [setGlobalError]
  )

  useEffect(() => {
    setIsFetchLoading(true)
    fetchData({ apolloQuery })
      .then(({ formData }) => {
        reset(formData as UnpackNestedValue<TData>)
      })
      .catch(handleFetchErrors)
      .finally(() => setIsFetchLoading(false))
  }, [fetchData, reset, setIsFetchLoading, handleFetchErrors, apolloQuery])

  const memoizedHandleSubmit = useMemo(
    () =>
      handleSubmit(() => {
        setIsSubmitLoading(true)
        resetAlerts()
        const formData = preSubmitData ? preSubmitData(getValues() as TData) : (getValues() as TData)
        submitData({ formData, apolloMutate })
          .then((data: TData) => onSubmit && onSubmit(data))
          .catch(handleSaveErrors)
          .finally(() => setIsSubmitLoading(false))
      }),
    [
      handleSaveErrors,
      handleSubmit,
      getValues,
      onSubmit,
      resetAlerts,
      submitData,
      preSubmitData,
      setIsSubmitLoading,
      apolloMutate,
    ]
  )

  return {
    handleSubmit: memoizedHandleSubmit,
    methods,
    isLoading,
    isSubmitLoading,
    isFetchLoading,
  }
}
