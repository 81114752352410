import { colors } from './colors'
import { icons } from './icons'
import { spacing } from './spacing'
import { utilities } from './utilities'

export const notification = {
  border: {
    error: colors.error,
    info: colors.info,
    notice: colors.notice,
    success: colors.success,
    warning: colors.warning,
  },
  borderRadius: utilities.border.radius,
  color: {
    error: colors.errorBackground,
    info: colors.infoBackground,
    notice: colors.noticeBackground,
    success: colors.successBackground,
    warning: colors.warningBackground,
  },
  headlineColor: colors.primary,
  iconSize: {
    desktop: icons.size.small,
    mobile: icons.size.extraSmall,
  },
  padding: {
    desktop: spacing.medium,
    mobile: spacing.small,
  },
}
