import type { CountryCode } from 'libphonenumber-js'
import type { FC } from 'react'
import { useCallback } from 'react'

import { FormDropdown } from '../Dropdown'
import { FormTextInput } from '../TextInput'
import { DEFAULT_PROPS } from './constants'
import { CustomInput, SDropdownWrapper, SOuterWrapper } from './presentation.styled'
import type { TFormPhoneInput } from './types'
import { getCountryOptions, getSelectedOptionView } from './utils'

export const PhoneInput: FC<TFormPhoneInput> = ({
  suggestedCountriesISOCodes = DEFAULT_PROPS.suggestedCountriesISOCodes,
  label,
  subLabel,
  countryCodeName,
  onCountryISOCodeChange,
  ...props
}) => {
  const renderSelectedValue = useCallback(val => getSelectedOptionView(val as CountryCode), [])

  return (
    <FormDropdown
      label={label}
      subLabel={subLabel}
      name={countryCodeName}
      onChange={onCountryISOCodeChange}
      renderSelectedValue={renderSelectedValue}
      options={getCountryOptions(suggestedCountriesISOCodes)}
      SDropdownWrapper={SDropdownWrapper}
      SOuterWrapper={SOuterWrapper}
    >
      <FormTextInput {...props} Input={CustomInput} displayError={false} type="tel" />
    </FormDropdown>
  )
}
