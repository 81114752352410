import { useTranslationWithOptions } from '@retire/hooks'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'

import type { TLeavePageConfirmation } from './types'

export const LeavePageConfirmation: FC<TLeavePageConfirmation> = ({ hideConfirmation, leavePage }) => {
  const { t } = useTranslationWithOptions('common')
  const onNext = useCallback(() => {
    leavePage()
    hideConfirmation()
  }, [hideConfirmation, leavePage])

  return (
    <PageTemplate
      title={t('leavePageConfirmation.title', 'Leave page confirmation title')}
      subTitle={t('leavePageConfirmation.subTitle', 'Leave page confirmation subtitle')}
      onNext={onNext}
      nextText={t('leavePageConfirmation.actions.ok', 'Leave')}
      onBack={hideConfirmation}
      backText={t('leavePageConfirmation.actions.cancel', 'Cancel')}
    />
  )
}
