import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import type { FC } from 'react'

import { DEFAULT_PROPS } from './constants'
import { SSpinner, SWrapper } from './presentation.styled'
import type { TPartialLoader } from './types'

export const PartialLoader: FC<TPartialLoader> = ({ size = DEFAULT_PROPS.size }) => {
  const { t } = useTranslationWithOptions('common')
  return (
    <SWrapper role="status" aria-label={t('loader.aria-label', 'Loading')}>
      <SSpinner size={size} />
    </SWrapper>
  )
}
