import { graphql } from '@retire/gql/__generated__/gql'

export const USER_LATER_LIFE_AGE_MUTATION = graphql(`
  mutation updateUserLaterLifeAge($laterLifeAge: Int!) {
    userUpdate(input: { laterLifeAge: $laterLifeAge }) {
      id
      laterLifeAge
    }
  }
`)
