import { Copy, LabelValue, RiskRating } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { toCurrency } from '@retire/utils'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { usePlanContext } from '../../../hooks'
import { Bold, HeadingThree, Paragraph, Section, SubSection } from '../Common'
import type { TInheritancePot } from './types'

export const InheritancePot: FC<TInheritancePot> = ({ fundDetails: { description, riskLevel, name } }) => {
  const { t } = useTranslationWithOptions('plan')
  const {
    plan: { inheritanceAmount },
  } = usePlanContext()

  return (
    <Section>
      <SubSection>
        <Paragraph>{t('inheritancePot.description', 'Inheritance pot description')}</Paragraph>
      </SubSection>
      <SubSection>
        <HeadingThree>{t('inheritancePot.youHaveAskedFor.heading', 'Inheritance pot subheading')}</HeadingThree>
        <LabelValue
          label={t('inheritancePot.youHaveAskedFor.valueLabel', 'Inheritance pot amount')}
          value={toCurrency(inheritanceAmount)}
        />
      </SubSection>
      <SubSection>
        <HeadingThree>
          {t('inheritancePot.howYourMoneyIsInvested.heading', 'Inheritance pot - how your money is invested')}
        </HeadingThree>
        <Paragraph>
          <Trans components={[<Bold key="bold" />]}>
            {t(
              'inheritancePot.howYourMoneyIsInvested.description',
              'Money you put into this pot will be invested in a fund: {{fundName}}.',
              {
                fundName: name,
              }
            )}
          </Trans>
        </Paragraph>
        <Copy as="p">{description}</Copy>
      </SubSection>
      <SubSection>
        <RiskRating rating={riskLevel} />
      </SubSection>
    </Section>
  )
}
