import type { SyntheticEvent } from 'react'

export const scrollToElementWithID = (elementID: string, callback?: () => void) => (e: SyntheticEvent) => {
  e.preventDefault()
  const element = document.getElementById(elementID)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
    element.focus()
    callback && callback()
  }
}
