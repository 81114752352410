import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { paths } from '@retire/navigation/paths'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ManageIncomeAction } from '../../features/ManageIncome/ChooseAction/constants'
import { ChooseAction } from '../../features/ManageIncome/ChooseAction/presentation'

export const FlexibleIncomePotManageIncome = () => {
  const navigate = useNavigate()
  useActivatePageOverlay()

  const onContinue = useCallback(
    ({ action }) => {
      switch (action) {
        case ManageIncomeAction.amount:
          navigate(paths.dashboard.flexibleIncomePotManageIncomeAmount)
          break
        case ManageIncomeAction.time:
          navigate(paths.dashboard.flexibleIncomePotManageIncomePeriod)
      }
    },
    [navigate]
  )

  const onQuit = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return <ChooseAction onContinue={onContinue} onQuit={onQuit} />
}
