import type { TPickAndRequired } from '@retire/types'

import { SInput } from '../index.styled'
import type { TTextInput } from './types'

export const DEFAULT_PROPS: TPickAndRequired<TTextInput, 'type' | 'value' | 'Input'> = {
  type: 'text',
  value: '',
  Input: SInput,
}
