import { FullScreenModal } from '@retire/layout'
import type { TFullScreenModal } from '@retire/layout/Modals/FullScreenModal/types'
import type { FC } from 'react'
import { useCallback, useState } from 'react'

import type { TUseFullScreenModal } from './types'

export const useFullScreenModal = (): TUseFullScreenModal => {
  const [isModalVisible, setModalVisibility] = useState(false)

  const onShowModal = useCallback(() => {
    setModalVisibility(true)
  }, [])

  const onCloseModal = useCallback(() => {
    setModalVisibility(false)
  }, [])

  const Modal: FC<TFullScreenModal> = ({ children, ...props }) =>
    isModalVisible ? <FullScreenModal {...props}> {children} </FullScreenModal> : null

  return {
    onShowModal,
    onCloseModal,
    Modal,
  }
}
