import { Link } from '@retire/components/Link'
import { Copy } from '@retire/components/Typography/Copy'
import { INIT_QUERY } from '@retire/gql/user/queries'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { CopyLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'

export const EmailResent: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.email })
  const { t } = useTranslationWithOptions('enrolment')
  const { data, loading } = useQueryWithError(INIT_QUERY)
  const email = data?.user.unconfirmedEmail

  return (
    <PageTemplate title={t('emailResent.title', 'Email changed')} loading={loading}>
      <Copy as="p" level={CopyLevel.body1}>
        <Trans components={[<Link inline as="a" key="link-1" noDecoration />]}>
          {t('emailResent.description', 'Description', { email })}
        </Trans>
      </Copy>
    </PageTemplate>
  )
}
