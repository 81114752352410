import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const WindowNext: FC<TIconColors> = props => {
  const {
    colors: { footerLink },
  } = useThemeContext()
  const { base = footerLink } = props

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="WindowNext" />
      <path
        fill="none"
        stroke={base}
        strokeWidth="1.6"
        d="M29.012 13.999v-10.996c0-0.53-0.211-1.039-0.586-1.414s-0.884-0.586-1.414-0.586h-24c-0.53 0-1.039 0.211-1.414 0.586s-0.586 0.884-0.586 1.414v22c0 0.53 0.211 1.039 0.586 1.414s0.884 0.586 1.414 0.586h9.001"
      />
      <path fill="none" stroke={base} strokeWidth="1.6" d="M1.012 7.003h28" />
      <path fill="none" stroke={base} strokeWidth="1.6" d="M27.012 19.003l4 4-4 4" />
      <path
        fill="none"
        stroke={base}
        strokeWidth="1.6"
        d="M17.012 31.003c0-2.122 0.843-4.157 2.343-5.657s3.535-2.343 5.657-2.343h6"
      />
    </svg>
  )
}
