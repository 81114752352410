import { Copy, Spacer } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import { useTranslationWithOptions } from '@retire/hooks'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { StyleWeight, TypographyColor } from '@retire/theme'
import type { FC } from 'react'

import type { TBeneficiaryRemovalConfirmation } from './types'

export const BeneficiaryRemovalConfirmation: FC<TBeneficiaryRemovalConfirmation> = ({ name, onCancel, onSave }) => {
  const { t } = useTranslationWithOptions('account')

  return (
    <PageTemplate
      title={t('accountManagement.sections.beneficiaries.remove.confirmation.title', 'Confirm removing beneficiary')}
      onNext={onSave}
      nextText={t('accountManagement.sections.beneficiaries.remove.confirmation.button', 'Remove beneficiary')}
      nextStyleType={ButtonStyleType.destructive}
      onBack={onCancel}
      backText={t('actions.cancel', 'Cancel')}
    >
      <Copy as="p">{t('accountManagement.sections.beneficiaries.remove.confirmation.caption', 'Are you sure?')}</Copy>
      <Spacer top="large" bottom="large">
        <Copy as="p" weight={StyleWeight.bold} color={TypographyColor.primary}>
          {name}
        </Copy>
      </Spacer>
    </PageTemplate>
  )
}
