import type { ElementType, FC } from 'react'
import { Fragment } from 'react'

import { Spacer } from '../../Spacer'
import type { TTable } from '../types'
import { STable, STDCell, STDHeaderCell, STHCell, STRow } from './presentation.styled'
import type { TCell } from './types'

export const DesktopTable: FC<TTable> = ({ columnHeaders, rows, verticalAlign, showExtremeBorders }) => {
  const Cell: FC<TCell> = ({ children, cellType, bottom, ...props }) => {
    let CellComponent: ElementType
    switch (cellType) {
      case 'columnHeader':
        CellComponent = STHCell
        break

      case 'rowHeader':
        CellComponent = STDHeaderCell
        break

      default:
        CellComponent = STDCell
    }

    return (
      <CellComponent verticalAlign={verticalAlign} {...props}>
        <Spacer bottom={bottom || 'medium'} top={cellType === 'subCell' ? 'none' : 'medium'}>
          {children}
        </Spacer>
      </CellComponent>
    )
  }

  return (
    <STable showExtremeBorders={showExtremeBorders}>
      {columnHeaders && columnHeaders.length && (
        <thead>
          <STRow hideBorder>
            {columnHeaders.map(({ content, ...props }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Cell cellType="columnHeader" key={index} {...props}>
                {content}
              </Cell>
            ))}
          </STRow>
        </thead>
      )}
      <tbody>
        {rows &&
          rows.map(({ rowHeader, cells, subCells }, rowIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={rowIndex}>
              <STRow>
                {rowHeader && (
                  <Cell cellType="rowHeader" textAlign={rowHeader.textAlign}>
                    {rowHeader.content}
                  </Cell>
                )}
                {cells.map(({ content, textAlign }, colIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Cell key={colIndex} textAlign={textAlign} bottom={subCells ? 'none' : undefined}>
                    {content}
                  </Cell>
                ))}
              </STRow>
              {subCells && (
                <STRow hideBorder>
                  {rowHeader && <Cell cellType="rowHeader" />}
                  {subCells.map(({ content, textAlign, verticalAlign: subCellVerticalAlign }, colIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Cell key={colIndex} textAlign={textAlign} cellType="subCell" verticalAlign={subCellVerticalAlign}>
                      {content}
                    </Cell>
                  ))}
                </STRow>
              )}
            </Fragment>
          ))}
      </tbody>
    </STable>
  )
}
