import { colors } from './colors'
import { spacing } from './spacing'
import { PRIMARY_FAMILY } from './typography'
import { utilities } from './utilities'

export const tag = {
  default: {
    backgroundColor: colors.neutral10,
    borderColor: colors.neutral40,
  },
  info: {
    backgroundColor: colors.infoBackground,
    borderColor: colors.info,
  },
  warning: {
    backgroundColor: colors.warningBackground,
    borderColor: colors.warning,
  },
  error: {
    backgroundColor: colors.errorBackground,
    borderColor: colors.error,
  },
  borderRadius: spacing.extraSmall,
  color: colors.primary,
  fontFamily: PRIMARY_FAMILY,
  fontSize: '0.8125rem',
  fontWeight: utilities.weight.bold,
  lineHeight: '1.125rem',
}
