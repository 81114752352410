import { Divider, NavButtons, Notification, PageTitle, Spacer, SuspenseLoader } from '@retire/components'
import { QuitAndReturnButton } from '@retire/components/QuitAndReturnButton'
import { useScrollToTop } from '@retire/hooks'
import type { FC } from 'react'
import { Fragment } from 'react'

import { ContentWrapper } from './ContentWrapper'
import { SAnimatedAlerts } from './presentation.styled'
import type { TPageTemplateComponent } from './types'

export const PageTemplateComponent: FC<TPageTemplateComponent> = ({
  children,
  loading,
  fullWidth,
  disableNext,
  alerts,
  ...props
}) => {
  const { nextText, backText, onQuit } = props
  useScrollToTop()

  return (
    <form>
      <SuspenseLoader isLoading={!!loading}>
        <PageTitle {...props} />
        {alerts && (
          <SAnimatedAlerts>
            {alerts.map(({ id, title, type, message }) => (
              <Fragment key={id}>
                <Notification hasBorder type={type} title={title} asAlert={true}>
                  {!!message && message}
                </Notification>
                <Spacer bottom="small" />
              </Fragment>
            ))}
          </SAnimatedAlerts>
        )}
        {children && <>{fullWidth ? children : <ContentWrapper>{children}</ContentWrapper>}</>}
        {(backText || nextText) && (
          <Spacer top="extraLarge">
            <NavButtons {...props} disableNext={disableNext || !!loading} />
          </Spacer>
        )}
        {onQuit && (
          <>
            <Divider top="large" bottom="extraLarge" />
            <QuitAndReturnButton {...props} />
          </>
        )}
      </SuspenseLoader>
    </form>
  )
}
