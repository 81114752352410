import type { PotName } from '@retire/constants'
import { useCallback } from 'react'

import { useTranslationWithOptions } from '../useTranslationWithOptions'

export const useGetPotDisplayName = () => {
  const { t } = useTranslationWithOptions('common')

  const getPotDisplayName = useCallback((pot?: PotName) => t(`pots.names.${pot}`, pot), [t])

  const getPotDisplayLongName = useCallback(
    (pot?: PotName) => t('pots.longName', `${pot} pot`, { potName: t(`pots.names.${pot}`) }),
    [t]
  )

  return { getPotDisplayName, getPotDisplayLongName }
}
