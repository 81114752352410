import { Card, CardTitle } from '@retire/components/Card'
import { FundCardContent } from '@retire/components/FundCardContent'
import { Notification } from '@retire/components/Notification'
import { SelectableCard } from '@retire/components/SelectableCard/presentation'
import { Spacer } from '@retire/components/Spacer'
import { PotType } from '@retire/gql/__generated__/graphql'
import { usePotFunds } from '@retire/hooks/usePotFunds'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import type { FC } from 'react'

export const RainyDayPotFundsCard: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const { currentFund } = usePotFunds(PotType.RainyDay)

  return (
    <Card>
      <CardTitle>{t('pots.rainyDayPot.investmentFunds.title', 'Investment Funds')}</CardTitle>
      <Notification
        type="notice"
        hasBorder
        title={t('pots.rainyDayPot.investmentFunds.notification', 'Investment Funds notification')}
      ></Notification>
      {currentFund && (
        <Spacer top="medium">
          <SelectableCard>
            <FundCardContent {...currentFund} />
          </SelectableCard>
        </Spacer>
      )}
    </Card>
  )
}
