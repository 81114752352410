import type { FC } from 'react'

import { SelectableCardGroup } from './SelectableCardGroup/presentation'
import type { TSelectableCardsGroup } from './types'

export const SelectableCardsGroup: FC<TSelectableCardsGroup> = ({ value, options, onChange }) => {
  return (
    <>
      {options.map(({ value: optionValue, content }) => (
        <SelectableCardGroup key={optionValue} value={value} optionValue={optionValue} onChange={onChange}>
          {content}
        </SelectableCardGroup>
      ))}
    </>
  )
}
