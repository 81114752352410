import { Divider, Spacer } from '@retire/components'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

import type { TMessageComponent } from '../types'
import { SColRight, SFrom } from './presentation.styled'

export const MobileInbox: FC<{ messages: TMessageComponent[] }> = ({ messages }) => (
  <>
    {messages.map(({ id, from, subject, sentAt, files }, index) => (
      <Spacer key={id} top={index === 0 ? 'none' : 'large'}>
        {index !== 0 && <Divider bottom="large" />}
        <Row>
          <Col xs={7}>
            <SFrom>{from}</SFrom>
          </Col>
          <SColRight xs={5}>{sentAt}</SColRight>
        </Row>
        <Row>
          <Col xs={9}>{subject}</Col>
          <SColRight xs={3}>{files}</SColRight>
        </Row>
      </Spacer>
    ))}
  </>
)
