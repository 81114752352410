import { graphql } from '../__generated__'

export const SETTINGS_QUERY = graphql(`
  query getSettings {
    settings {
      adminFees {
        chargeAmount
        minimumPotAmount
      }
      annualPensionContributionAllowance {
        ...all__AllowanceWithYear
      }
      flexibleIncome {
        feePercentage
        totalAmount {
          minimum
        }
        monthlyAmount {
          ...all__PotMonthlyAmount
        }
      }
      illustrationPlan {
        inactivePeriodInDays
      }
      inheritance {
        feePercentage
        totalAmount {
          minimum
        }
      }
      laterLife {
        feePercentage
        totalAmount {
          minimum
        }
      }
      laterLifeAge {
        ...all__PotAgeRange
      }
      lifetimeAllowance {
        ...all__AllowanceWithYear
      }
      lumpSumAllowance {
        ...all__AllowanceWithYear
      }
      lumpSumAndDeathBenefitAllowance {
        ...all__AllowanceWithYear
      }
      moneyPurchaseAnnualAllowance {
        ...all__AllowanceWithYear
      }
      payrollSupport {
        email
        phoneNumber
      }
      potAmount {
        maximumRatio
      }
      rainyDay {
        feePercentage
        totalAmount {
          minimum
        }
      }
      regularIncomeEndAge {
        ...all__PotAgeRange
      }
      regularIncomeStartAge {
        ...all__PotAgeRange
      }
      withdrawalAmount {
        minimum
      }
    }
  }
`)

export const PUBLIC_SETTINGS_QUERY = graphql(`
  query getPublicSettings {
    settings {
      eligibilityRequirements {
        minimumSavings
        minimumAge
      }
      customerService {
        email
        phoneNumber
      }
    }
  }
`)
