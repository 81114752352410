import { styled } from '@retire/theme/styledComponents'

export const SWrapper = styled.ul`
  overflow-y: scroll;

  ${({
    theme: {
      addressLookup: {
        border,
        results: { height, scrollbar },
      },
    },
  }) => `
    height: ${height};
    border-radius: ${border.radius};
    border-width: ${border.width};
    border-style: ${border.style};
    border-color: ${border.color};

    ::-webkit-scrollbar {
      width: ${scrollbar.width};
    }
    ::-webkit-scrollbar-track {
      background: ${scrollbar.backgroundColor};
    }
    ::-webkit-scrollbar-thumb {
      background: ${scrollbar.color};
      border-radius: ${scrollbar.borderRadius};
    }
  `}
`
