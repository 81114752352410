import { Segment } from '@retire/components/Segment'
import { Spacer } from '@retire/components/Spacer'
import { TransList } from '@retire/components/TransList'
import { Headline } from '@retire/components/Typography/Headline'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { HeadlineLevel } from '@retire/theme/typography'
import type { FC } from 'react'

import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { SIMPLE_PLAN_QUERY } from './gql/queries'

export const ThankYou: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.thankYou })
  const { t } = useTranslationWithOptions('enrolment')
  const { data, loading } = useQueryWithError(SIMPLE_PLAN_QUERY)

  // common bulletpoints
  let bulletpoints = t('thankYou.description.bulletpoints.common', 'Common bulletpoints')
  if (!loading && data) {
    // complete common bulletpoints with conditionned ones
    if (data.plan?.taxFreeLumpSum) {
      bulletpoints += t(
        data.plan.flexibleIncomeMonthlyAmount
          ? 'thankYou.description.bulletpoints.condition.both'
          : 'thankYou.description.bulletpoints.condition.lumpsum',
        'TFLS bulletpoints'
      )
    } else if (data.plan?.flexibleIncomeMonthlyAmount) {
      bulletpoints += t('thankYou.description.bulletpoints.condition.monthlyincome', 'Monthly income bulletpoints')
    }
  }

  return (
    <PageTemplate
      title={t('thankYou.title', 'Thank you title')}
      subTitle={t('thankYou.subTitle', 'Thank you subtitle')}
      loading={loading && !!data}
      fullWidth
    >
      <Segment whiteBackground>
        <ContentWrapper>
          <Spacer bottom="large">
            <Headline as="h2" level={HeadlineLevel.h3}>
              {t('thankYou.description.title', 'Description title')}
            </Headline>
          </Spacer>
          <TransList isOrdered>{bulletpoints}</TransList>
        </ContentWrapper>
      </Segment>
    </PageTemplate>
  )
}
