import type { GetInstructionsQuery, GetInstructionsQueryVariables } from '@retire/gql/__generated__/graphql'
import { InstructionPublicState } from '@retire/gql/__generated__/graphql'
import { useQuerySelector } from '@retire/hooks'

import { INSTRUCTIONS_QUERY } from '../../gql'
import { DEFAULT_TRANSACTIONS, DISPLAYED_INSTRUCTION_TYPES } from './constants'
import type { TTransactions } from './types'

export const useTransactions = () =>
  useQuerySelector<GetInstructionsQuery, TTransactions, GetInstructionsQueryVariables>(
    { query: INSTRUCTIONS_QUERY, options: { variables: { instructionTypes: DISPLAYED_INSTRUCTION_TYPES } } },
    ({ instructions }) => ({
      inProgressTransactions: (instructions || []).filter(instruction =>
        [InstructionPublicState.Created, InstructionPublicState.InProgress, InstructionPublicState.Paying].includes(
          instruction.state
        )
      ),
      completedTransactions: (instructions || []).filter(instruction =>
        [InstructionPublicState.Completed, InstructionPublicState.Failed, InstructionPublicState.Cancelled].includes(
          instruction.state
        )
      ),
    }),
    DEFAULT_TRANSACTIONS
  )
