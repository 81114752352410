import { gql } from '@apollo/client'

export const USER_BENEFICIARIES_MUTATION = gql`
  mutation updateUserBeneficiaries($beneficiaries: [BeneficiaryAttributes!]) {
    userUpdate(beneficiaries: $beneficiaries, input: {}) {
      id
      beneficiaries {
        name
        bornOn
        relation
        percentage
      }
    }
  }
`
