import { AddressLookup, FormPhoneInput, FormTextInput, Spacer } from '@retire/components'
import { AddressField, ContactField } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { isRequired, isTelephone } from '@retire/utils'
import type { FC } from 'react'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'

import type { TContactDetailsComponent } from './types'

export const ContactDetails: FC<TContactDetailsComponent> = ({ onBack, methods, handleSubmit }) => {
  const { t } = useTranslationWithOptions(['account', 'common'])

  const countryISOCode = methods.watch(ContactField.telephoneCountryCode)
  const phoneInputRules = useMemo(
    () => ({
      validate: {
        required: isRequired(
          t('common:form.contactDetails.telephone.validations.required', 'Phone number is required')
        ),
        format: isTelephone({
          countryISOCode,
          message: t('common:form.contactDetails.telephone.validations.format', 'Phone number is in incorrect format'),
        }),
      },
    }),
    [countryISOCode, t]
  )

  return (
    <FormProvider {...methods}>
      <PageTemplate
        title={t('common:account.contactDetails.action', 'Edit contact details')}
        onNext={handleSubmit}
        nextText={t('actions.save', 'Save')}
        onBack={onBack}
        disableNext={!methods.formState.isDirty}
        backText={t('actions.cancel', 'Cancel')}
        fullWidth
      >
        <Spacer bottom="medium">
          <FormTextInput
            name={ContactField.email}
            label={t('common:form.personalDetails.email.label', 'Email')}
            disabled
          />
        </Spacer>
        <Spacer bottom="medium">
          <FormPhoneInput
            label={t('common:form.contactDetails.telephone.label', 'Telephone')}
            countryCodeName={ContactField.telephoneCountryCode}
            name={ContactField.telephone}
            rules={phoneInputRules}
          />
        </Spacer>
        <AddressLookup
          isManualMode
          fields={Object.values(AddressField)}
          requiredFields={[AddressField.line1, AddressField.line2, AddressField.city, AddressField.postcode]}
        />
      </PageTemplate>
    </FormProvider>
  )
}
