import type { EnrolmentAttributes } from '@retire/gql/__generated__/graphql'

export enum EnrolmentStep {
  'email' = 'email',
  'personalDetails' = 'personalDetails',
  'bankOrBuildingSociety' = 'bankOrBuildingSociety',
  'moneyLeftOver' = 'moneyLeftOver',
  'checkAndConfirm' = 'checkAndConfirm',
  'lta' = 'lta',
  'cobs' = 'cobs',
  'declaration' = 'declaration',
  'thankYou' = 'thankYou',
}

export const INITIAL_ENROLMENT_RESPONSES: EnrolmentAttributes = {
  medicalCondition: null,
  hadFinancialAdvice: null,
  hadImpartialAdvice: null,
  awareOfInflation: null,
  financialDependents: null,
  performedResearch: null,
  expectsRegularIncome: null,
  awareOfTaxation: null,
  awareOfFeesAndCharges: null,
  awareOfStateBenefits: null,
  awareOfInvestmentScamsProtection: null,
  awareOfDebtEffects: null,
  overLtaAllowance: null,
  overLsaOrLsdba: null,
}

export const PERFECT_ENROLMENT_RESPONSES: EnrolmentAttributes = {
  medicalCondition: false,
  hadFinancialAdvice: true,
  hadImpartialAdvice: true,
  awareOfInflation: true,
  financialDependents: false,
  performedResearch: true,
  expectsRegularIncome: false,
  awareOfTaxation: true,
  awareOfFeesAndCharges: true,
  awareOfStateBenefits: true,
  awareOfInvestmentScamsProtection: true,
  awareOfDebtEffects: true,
  overLtaAllowance: false,
  overLsaOrLsdba: false,
}
