import { useQuery } from '@apollo/client'
import { Button } from '@retire/components/Button'
import { Divider } from '@retire/components/Divider'
import { Icon } from '@retire/components/Icon'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { ButtonStyleType } from '@retire/constants/button'
import { UNREAD_MESSAGES_QUERY } from '@retire/features/Inbox/gql/queries'
import { USER_BRIEF_DETAILS_QUERY } from '@retire/gql/user/queries'
import { usePromiseWithError } from '@retire/hooks/usePromiseWithError'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { TypographyColor } from '@retire/theme/colors'
import { CopyLevel } from '@retire/theme/typography'
import { StyleWeight } from '@retire/theme/utilities'
import { isActiveSession, signOut } from '@retire/utils/session'
import type { FC } from 'react'
import { useMemo } from 'react'
import { ThemeConsumer } from 'styled-components'

import { SIconButton } from '../presentation.styled'
import { MenuLink } from './MenuLink'
import type { TMenuLink } from './MenuLink/types'
import { SMenuModal, SNavBarLayout, SUserInfoLayout } from './presentation.styled'
import type { TMenu } from './types'

export const Menu: FC<TMenu> = ({ isOpen, onClose }) => {
  const { t } = useTranslationWithOptions('common')
  const { data: userData = { user: { title: '', forename: '', surname: '' } } } =
    useQueryWithError(USER_BRIEF_DETAILS_QUERY)
  const {
    user: { title, forename, surname },
  } = userData
  const { data = { sentMessages: [] } } = useQuery(UNREAD_MESSAGES_QUERY, {
    skip: !isActiveSession(),
  })
  const shouldDisplayInboxDot = data.sentMessages.some(message => !message.read)
  const { promise: handleSignOut } = usePromiseWithError('Signout failed', () => signOut(true))

  const links: TMenuLink[] = useMemo(
    () => [
      { title: t('nav.home', 'Home link'), href: paths.dashboard.board },
      {
        title: t('nav.moveMoney', 'Move money link'),
        href: paths.dashboard.moveMoneyBetweenPotsHowItWorks,
      },
      {
        title: t('nav.transactions', 'Transactions link'),
        href: paths.dashboard.transactionList,
      },
      {
        title: t('nav.inbox', 'Inbox link'),
        href: paths.inbox.messages,
        dot: shouldDisplayInboxDot,
      },
      { title: t('nav.account', 'Account link'), href: paths.account.manage },
    ],
    [shouldDisplayInboxDot, t]
  )

  return (
    <ThemeConsumer>
      {theme => (
        <SMenuModal
          ariaHideApp={false}
          isOpen={isOpen}
          closeTimeoutMS={400}
          onRequestClose={onClose}
          style={{
            overlay: {
              zIndex: 1000,
              backgroundColor: theme.modal.darkBackgroundColor,
              overflowX: 'hidden',
              overflowY: 'hidden',
            },
          }}
        >
          <SNavBarLayout>
            <SIconButton onClick={onClose} data-cy="hamburger-menu-close-button">
              <Copy as="span" weight={StyleWeight.bold} color={TypographyColor.primary}>
                {t('nav.close', 'Close menu')}
              </Copy>
              <Spacer right="mediumSmall" />
              <Icon iconType="Close" />
            </SIconButton>
          </SNavBarLayout>

          {links.map(link => (
            <MenuLink {...link} key={link.title} onClick={onClose} />
          ))}

          <Spacer top="extraLarge" right="medium" left="medium">
            <Divider />
            <SUserInfoLayout>
              <Copy as="span" weight={StyleWeight.bold} color={TypographyColor.primary}>
                {t('nav.signedInAs', 'Signed in as')}
              </Copy>
              <Copy as="span" color={TypographyColor.neutral} level={CopyLevel.caption}>
                {title} {forename} {surname}
              </Copy>
            </SUserInfoLayout>
            <Button buttonStyleType={ButtonStyleType.secondary} size="small" onClick={handleSignOut} lockWidth>
              {t('nav.signOut', 'Sign out')}
            </Button>
          </Spacer>
        </SMenuModal>
      )}
    </ThemeConsumer>
  )
}
