import { PotName } from '@retire/constants'
import { useUserDetails } from '@retire/hooks/useUserDetails'

import { usePageBarPotColorEffect } from '../usePageBarPotColorEffect'
import { usePotsContext } from '../usePotsContext'
import type { TUsePotDetailsResult } from './types'

export const usePotDetails = (potName: PotName): TUsePotDetailsResult => {
  usePageBarPotColorEffect(potName)
  const {
    pots: {
      [potName]: potContext,
      [PotName.flexibleIncome]: { surplusAmount: projectedLaterLifeSurplus },
    },
  } = usePotsContext()
  const {
    data: { laterLifeAge },
    loading: loadingUserDetails,
  } = useUserDetails()

  return {
    potDetails: {
      ...potContext,
      laterLifeAge,
      projectedLaterLifeSurplus,
    },
    loadingPotDetails: loadingUserDetails,
  }
}
