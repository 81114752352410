import type { URLSearchParamsInit } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

import type { TUseFormDefaultValuesProps, TUseFormDefaultValuesResult } from './types'

export const useFormDefaultValues = <TFormFields extends URLSearchParamsInit>({
  initialValues,
  checkValuesValidity,
}: TUseFormDefaultValuesProps<TFormFields> = {}): TUseFormDefaultValuesResult<TFormFields> => {
  const [searchParams, setSearchParams] = useSearchParams(initialValues)
  const setDefaultValues = (values: TFormFields) => {
    setSearchParams(values, { replace: true })
  }

  let defaultValues = {} as TFormFields
  searchParams?.forEach((value, key) => {
    defaultValues[key] = value
  })

  if (checkValuesValidity && !checkValuesValidity(defaultValues)) {
    defaultValues = {} as TFormFields
  }

  return [defaultValues, setDefaultValues]
}
