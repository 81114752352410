import { AuthOutlet } from '@retire/navigation/AuthOutlet'
import { paths, roots } from '@retire/navigation/paths'
import type { RouteObject } from 'react-router-dom'

import { EnsignUserAuthOutlet } from './components/EnsignUserAuthOutlet'
import { PotsProvider } from './context/PotsContext'
import {
  Board,
  CancelTransaction,
  ConfirmMoveMoneyRequest,
  FlexibleIncomePotDetails,
  FlexibleIncomePotManageIncome,
  FlexibleIncomePotManageIncomeAmount,
  FlexibleIncomePotManageIncomeAmountReview,
  InheritancePotDetails,
  LaterLifePotDetails,
  MoveMoneyBetweenPots,
  PauseFlexibleIncome,
  RainyDayPotDetails,
  RestartFlexibleIncome,
  TransactionList,
} from './pages'
import { FlexibleIncomePotManageIncomePeriod } from './pages/FlexibleIncomePotManageIncomePeriod'
import { FlexibleIncomePotManageIncomePeriodReview } from './pages/FlexibleIncomePotManageIncomePeriodReview'
import { InheritancePotFunds } from './pages/InheritancePotFunds'
import { InheritancePotFundsReview } from './pages/InheritancePotFundsReview'
import { LaterLifePotManageIncomePeriod } from './pages/LaterLifePotManageIncomePeriod'
import { LaterLifePotManageIncomePeriodReview } from './pages/LaterLifePotManageIncomePeriodReview'
import { MoveMoneyBetweenPotsHowItWorks } from './pages/MoveMoneyBetweenPotsHowItWorks/presentation'

export const dashboardRoutes: RouteObject[] = [
  {
    path: roots.dashboard,
    element: (
      <AuthOutlet>
        <PotsProvider />
      </AuthOutlet>
    ),
    children: [
      { path: paths.dashboard.board, element: <Board /> },
      { path: paths.dashboard.transactionList, element: <TransactionList /> },
      { path: paths.dashboard.cancelTransaction, element: <CancelTransaction /> },
      { path: paths.dashboard.flexibleIncomePotDetails, element: <FlexibleIncomePotDetails /> },
      { path: paths.dashboard.inheritancePotDetails, element: <InheritancePotDetails /> },
      { path: paths.dashboard.laterLifePotDetails, element: <LaterLifePotDetails /> },
      { path: paths.dashboard.rainyDayPotDetails, element: <RainyDayPotDetails /> },
      { path: paths.dashboard.moveMoneyBetweenPotsHowItWorks, element: <MoveMoneyBetweenPotsHowItWorks /> },
      { path: paths.dashboard.moveMoneyBetweenPots, element: <MoveMoneyBetweenPots /> },
      { path: paths.dashboard.confirmMoveMoneyRequest, element: <ConfirmMoveMoneyRequest /> },
      { path: paths.dashboard.pauseFlexibleIncomePot, element: <PauseFlexibleIncome /> },
      { path: paths.dashboard.restartFlexibleIncomePot, element: <RestartFlexibleIncome /> },
      { path: paths.dashboard.flexibleIncomePotManageIncome, element: <FlexibleIncomePotManageIncome /> },
      {
        path: paths.dashboard.flexibleIncomePotManageIncomeAmount,
        element: <FlexibleIncomePotManageIncomeAmount />,
      },
      {
        path: paths.dashboard.flexibleIncomePotManageIncomeAmountReview,
        element: <FlexibleIncomePotManageIncomeAmountReview />,
      },
      {
        path: paths.dashboard.flexibleIncomePotManageIncomePeriod,
        element: <FlexibleIncomePotManageIncomePeriod />,
      },
      {
        path: paths.dashboard.flexibleIncomePotManageIncomePeriodReview,
        element: <FlexibleIncomePotManageIncomePeriodReview />,
      },
      {
        path: paths.dashboard.laterLifePotManageIncomePeriod,
        element: <LaterLifePotManageIncomePeriod />,
      },
      {
        path: paths.dashboard.laterLifePotManageIncomePeriodReview,
        element: <LaterLifePotManageIncomePeriodReview />,
      },
      {
        element: <EnsignUserAuthOutlet />,
        children: [
          { path: paths.dashboard.inheritancePotFunds, element: <InheritancePotFunds /> },
          { path: paths.dashboard.inheritancePotFundsReview, element: <InheritancePotFundsReview /> },
        ],
      },
    ],
  },
]
