export const testIds = {
  components: {
    loading: 'loader',
    outageBannerOverlay: 'outage-banner-overlay',
    footer: 'footer',
    mobileDropdownLabel: 'dropdown-label',
  },
}

export const ids = {
  mainContent: 'maincontent',
  outageBanner: 'outagebanner',
  outOfBalanceWarning: 'oob-long-warning',
}
