import { LabelValue, RiskRating } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { toCurrency } from '@retire/utils'
import type { FC } from 'react'
import { Trans } from 'react-i18next'

import { usePlanContext } from '../../../hooks'
import { Bold, HeadingThree, Paragraph, Section, SubSection } from '../Common'
import type { TRainyDayPot } from './types'

export const RainyDayPot: FC<TRainyDayPot> = ({ fundDetails: { name, riskLevel, description } }) => {
  const { t } = useTranslationWithOptions('plan')
  const {
    plan: { rainyDayAmount },
  } = usePlanContext()

  return (
    <Section>
      <SubSection>
        <Paragraph>{t('rainyDayPot.description', 'Rainy day pot description')}</Paragraph>
      </SubSection>
      <SubSection>
        <HeadingThree>{t('rainyDayPot.youHaveAskedFor.heading', 'Rainy day pot subheading')}</HeadingThree>
        <LabelValue
          label={t('rainyDayPot.youHaveAskedFor.valueLabel', 'Rainy day pot amount')}
          value={toCurrency(rainyDayAmount)}
        />
      </SubSection>
      <SubSection>
        <HeadingThree>
          {t('rainyDayPot.howYourMoneyIsInvested.heading', 'Rainy day pot - how your money is invested')}
        </HeadingThree>
        <Paragraph>
          <Trans components={[<Bold key="bold" />]}>
            {t(
              'rainyDayPot.howYourMoneyIsInvested.description',
              'Money you put in this pot will be invested in a fund.',
              {
                fundName: name,
              }
            )}
          </Trans>
        </Paragraph>
        <Paragraph>{description}</Paragraph>
      </SubSection>
      <SubSection>
        <RiskRating rating={riskLevel} />
      </SubSection>
    </Section>
  )
}
