import { decorateUrl } from '../analytics'
import { API_URL, DEFAULT_FETCH_OPTIONS } from './constants'
import { fib, sleep } from './utils'
export { API_URL, DEFAULT_FETCH_OPTIONS }

export const apiFetchWithRetry = async (
  url: string,
  maxTries: number,
  options: RequestInit = DEFAULT_FETCH_OPTIONS
) => {
  let response: Response | undefined
  let fetchError: Error | undefined
  for (let current = 1; current <= maxTries; current++) {
    try {
      response = await fetch(decorateUrl(url), options)

      if (response.ok) {
        return response
      } else {
        await sleep(fib(current) * 1000)
      }
    } catch (e) {
      fetchError = e as Error
      await sleep(fib(current) * 1000)
    }
  }

  if (response) {
    throw new Error(`Fetch failed with status: ${response.status}`)
  }
  if (fetchError) {
    throw fetchError
  }
  throw new Error('Something unexpected went wrong fetching')
}
