import { SLink } from '@retire/components'
import { styled } from '@retire/theme/styledComponents'

export const SLowerFooterLink = styled(SLink)`
  text-decoration: none;
  color: ${props => props.theme.footer.color};
  font-size: ${props => props.theme.footer.smallText.fontSize};
  font-weight: ${props => props.theme.footer.smallLinks.fontWeight};
  margin: 0 ${props => props.theme.footer.smallLinks.paddingRight} 0 0;
  line-height: ${props => props.theme.footer.smallLinks.lineHeight};

  &:hover {
    color: ${props => props.theme.footer.hoverColor};
  }
`

export const SPipeSeparator = styled.span`
  &:before {
    content: ' | ';
  }
  padding-right: ${props => props.theme.footer.smallLinks.paddingRight};
`
