import { useApolloClient, useReactiveVar } from '@apollo/client'
import { DateFormat } from '@retire/constants/date'
import { sessionExpirationTime } from '@retire/gql/client/vars'
import { INIT_QUERY } from '@retire/gql/user/queries'
import { useGlobalLoadingContext } from '@retire/hooks/useGlobalLoadingContext'
import { useMountedState } from '@retire/hooks/useMountedState'
import { usePromiseWithError } from '@retire/hooks/usePromiseWithError'
import { useTimeout } from '@retire/hooks/useTimeout'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { formatDate } from '@retire/utils/date'
import { signOut } from '@retire/utils/session'
import type { FC } from 'react'
import { createContext, useCallback, useEffect, useMemo } from 'react'

import { SESSION_COUNTDOWN_TIME_SECONDS } from './constants'
import type { TSessionCountdownContext } from './types'

export const SessionCountdownContext = createContext<TSessionCountdownContext>({
  aboutToExpire: false,
  extendSession: undefined,
  getSecondsLeft: undefined,
})

export const SessionCountdownProvider: FC = ({ children }) => {
  const apolloClient = useApolloClient()
  const { activateGlobalLoading } = useGlobalLoadingContext()
  const [aboutToExpire, setAboutToExpire] = useMountedState(false)
  const { t } = useTranslationWithOptions('common')

  const sessionExpirationTimeString = useReactiveVar(sessionExpirationTime)
  const sessionExpirationDatetime = useMemo(
    () => (sessionExpirationTimeString ? new Date(sessionExpirationTimeString).getTime() : undefined),
    [sessionExpirationTimeString]
  )
  const sessionExpirationLongtime = useMemo(
    () => sessionExpirationDatetime && formatDate(sessionExpirationDatetime, DateFormat.longTime),
    [sessionExpirationDatetime]
  )

  const getSecondsLeft = useCallback(() => {
    if (!sessionExpirationDatetime) {
      return undefined
    }
    const secondsLeft = Math.round((sessionExpirationDatetime - new Date().getTime()) / 1000)
    return secondsLeft >= 0 ? secondsLeft : 0
  }, [sessionExpirationDatetime])

  const [memoizedMsBeforeSignout, memoizedMsBeforeAboutToExpire] = useMemo(() => {
    const secondsLeft = getSecondsLeft && getSecondsLeft()
    const msBeforeSignout = secondsLeft && secondsLeft * 1000
    const msBeforeAboutToExpire = secondsLeft && (secondsLeft - SESSION_COUNTDOWN_TIME_SECONDS) * 1000

    return [msBeforeSignout, msBeforeAboutToExpire]
  }, [getSecondsLeft])

  // we need a new handleSignOut when sessionExpirationLongtime changes
  const memoizedSignout = useCallback(() => signOut(true), [])
  const { loading: loadingSignOut, promise: handleSignOut } = usePromiseWithError(
    `Signout failed at ${sessionExpirationLongtime}`,
    memoizedSignout
  )
  useTimeout(handleSignOut, memoizedMsBeforeSignout)

  useEffect(() => {
    loadingSignOut && activateGlobalLoading(t('loading.signOut.title', 'Signing out'))
  }, [activateGlobalLoading, loadingSignOut, t])

  // we need a new handleAboutToExpire when sessionExpirationLongtime changes
  const handleAboutToExpire = useCallback(
    () => setAboutToExpire(true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAboutToExpire, sessionExpirationLongtime]
  )
  useTimeout(handleAboutToExpire, memoizedMsBeforeAboutToExpire)

  useEffect(() => {
    setAboutToExpire(false)
  }, [getSecondsLeft, setAboutToExpire])

  const extendSession = useCallback(
    () => apolloClient.query({ query: INIT_QUERY, fetchPolicy: 'network-only' }),
    [apolloClient]
  )

  const value = useMemo(
    () => ({
      aboutToExpire,
      extendSession,
      getSecondsLeft,
    }),
    [aboutToExpire, extendSession, getSecondsLeft]
  )

  return <SessionCountdownContext.Provider value={value}>{children}</SessionCountdownContext.Provider>
}
