import { styled } from '@retire/theme/styledComponents'

export const SAnimatedAlerts = styled.div`
  animation: 900ms ease-in-out 0s 1 scaleAnimation;

  @keyframes scaleAnimation {
    10% {
      transform: scale(0.95);
    }
    80% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`
