import { TypographyColor } from '@retire/theme'
import { preventDefault } from '@retire/utils'
import type { FC } from 'react'

import { Column } from '../../Column'
import { Link } from '../../Link'
import { Copy } from '../../Typography'
import type { TSelectedValueWithSubLabels } from './types'

export const SelectedValueWithSubLabels: FC<TSelectedValueWithSubLabels> = ({ label, subLabels }) => (
  <Column>
    <Link onClick={preventDefault} bold inline as="span">
      {label}
    </Link>
    {subLabels?.map(subLabel => (
      <Copy key={subLabel} as="span" color={TypographyColor.neutralDark}>
        {subLabel}
      </Copy>
    ))}
  </Column>
)
