import { TaxFreeLumpSumWithdrawalState } from '@retire/constants/pots'
import { useMutationWithError } from '@retire/hooks/useMutationWithError'
import { useCallback, useRef } from 'react'

import { PARTIAL_ILLUSTRATION_PLAN_MUTATION } from '../../gql/mutations'
import { ILLUSTRATION_PLAN_QUERY } from '../../gql/queries'
import type { TIllustrationPlanData } from '../../types'
import { useJourneyOverviewContext } from '../useJourneyOverviewContext'
import type { TUseIllustrationPlanSavePartialDataResult } from './types'

export const useIllustrationPlanSavePartialData = (): TUseIllustrationPlanSavePartialDataResult => {
  const isRedirectingRef = useRef(false)
  const redirectPathRef = useRef<string>()
  const { navigateWithCurrentState } = useJourneyOverviewContext()
  const [partialIllustrationPlanMutation, { loading: savingPartialIllustrationPlanData, error }] = useMutationWithError(
    PARTIAL_ILLUSTRATION_PLAN_MUTATION,
    {
      refetchQueries: [{ query: ILLUSTRATION_PLAN_QUERY }],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        if (redirectPathRef.current) {
          navigateWithCurrentState(redirectPathRef.current)
        }
      },
      onError: async () => {
        if (redirectPathRef.current) {
          isRedirectingRef.current = false
        }
      },
    }
  )

  const savePartialIllustrationPlanData = useCallback(
    async (data: TIllustrationPlanData, redirectPath?: string) => {
      if (redirectPath) {
        isRedirectingRef.current = true
        redirectPathRef.current = redirectPath
      }
      await partialIllustrationPlanMutation({
        variables: {
          taxFreeLumpSumSelected: data.taxFreeLumpSumWithdrawalState === TaxFreeLumpSumWithdrawalState.taken,
          flexibleIncomeAmount: data.flexibleIncomeAmount,
          regularIncomeStartAge: data.regularIncomeStartAge,
          regularIncomeEndAge: data.regularIncomeEndAge,
          laterLifeAmount: data.laterLifeAmount,
          laterLifeAge: data.laterLifeAge,
          inheritanceAmount: data.inheritanceAmount,
          rainyDayAmount: data.rainyDayAmount,
        },
      })
    },
    [partialIllustrationPlanMutation]
  )

  return {
    savePartialIllustrationPlanData,
    savingPartialIllustrationPlanData: savingPartialIllustrationPlanData || (isRedirectingRef.current && !error),
  }
}
