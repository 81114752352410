import { PotName } from '@retire/constants/pots'
import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types/icons'
import type { FC } from 'react'

export const LaterLife: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={`${PotName.laterLife}-icon`}
    >
      <path
        d="M35.7144 1.42773H4.28585C2.7079 1.42773 1.42871 2.70692 1.42871 4.28488V31.4277C1.42871 33.0057 2.7079 34.2849 4.28585 34.2849H35.7144C37.2924 34.2849 38.5716 33.0057 38.5716 31.4277V4.28488C38.5716 2.70692 37.2924 1.42773 35.7144 1.42773Z"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2861 22.8555C27.0476 22.8555 29.2861 20.6169 29.2861 17.8555C29.2861 15.094 27.0476 12.8555 24.2861 12.8555C21.5247 12.8555 19.2861 15.094 19.2861 17.8555C19.2861 20.6169 21.5247 22.8555 24.2861 22.8555Z"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.2861 9.28516V12.8566" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.2861 22.8555V26.4269" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.8576 17.8555H29.2861" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.2863 17.8555H15.7148" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M30.3434 11.7988L27.8291 14.3131"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7428 21.3984L18.2285 23.9127"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3434 23.9127L27.8291 21.3984"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7428 14.3131L18.2285 11.7988"
        stroke={base}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.57129 12.8555V22.8555" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.71484 34.2852V38.5709" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.8574 34.2852V38.5709" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
