import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'
import { JourneyStepLayout } from '../../JourneyStepLayout'

export const AllSavingsAssigned: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.ready })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const { loadingIllustrationPlanData, illustrationPlanData, saveFinalIllustrationPlanData } = useIllustrationPlanData()

  const onNext = useCallback(
    () => saveFinalIllustrationPlanData(illustrationPlanData),
    [illustrationPlanData, saveFinalIllustrationPlanData]
  )
  const onBack = useCallback(() => navigate(paths.illustration.journeyRainyDayPotIntro), [navigate])

  return (
    <JourneyStepLayout
      title={t('steps.allSavingsAssigned.title', 'All savings assigned title')}
      description={t('steps.allSavingsAssigned.description', 'All savings assigned description')}
      onNext={onNext}
      nextText={t('steps.allSavingsAssigned.actions.continue', 'Continue')}
      onBack={onBack}
      backText={t('steps.allSavingsAssigned.actions.back', 'Back')}
      hideOverview
      loading={loadingIllustrationPlanData}
    />
  )
}
