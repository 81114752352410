import { Link } from '@retire/components/Link'
import { Notification } from '@retire/components/Notification'
import { Spacer } from '@retire/components/Spacer'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import type { FC, ReactNode } from 'react'
import { Trans } from 'react-i18next'

const LinkKey: FC<{ linkKey: string; children?: ReactNode }> = ({ linkKey, children }) => {
  const { t } = useTranslationWithOptions('enrolment')

  return (
    <Link
      key={`link-${linkKey}`}
      as="a"
      href={t(`cobs.form.links.${linkKey}`, `Link ${linkKey}`)}
      inline
      target="_blank"
    >
      {children}
    </Link>
  )
}

export const Info: FC<{ copy: ReactNode }> = ({ copy }) => {
  if (!copy) return null

  return (
    <Spacer top="medium" bottom="extraLarge">
      <Notification
        type="info"
        title={
          <Trans
            components={{
              mapsLink: <LinkKey linkKey="maps" />,
              pensionWiseLink: <LinkKey linkKey="pensionWise" />,
              costAndChargeLink: <LinkKey linkKey="costAndCharge" />,
              benefitsLink: <LinkKey linkKey="benefits" />,
              pensionScamLink: <LinkKey linkKey="pensionScam" />,
            }}
          >
            {copy}
          </Trans>
        }
      />
    </Spacer>
  )
}
