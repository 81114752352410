import { Spacer } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { CopyLevel, HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

import { SFooterCopy, SFooterHeadline, SHR } from '../presentation.styled'
import { UpperFooterLinks } from './UpperFooterLinks'

export const UpperFooter: FC = () => {
  const { t } = useTranslationWithOptions('common')

  return (
    <>
      <Row>
        <Col>
          <SFooterHeadline as="h3" level={HeadlineLevel.h3}>
            {t('footer.contactUs.header', 'Contact us header')}
          </SFooterHeadline>
          <Spacer bottom="medium" top="mediumSmall">
            <SFooterCopy as="p" level={CopyLevel.body1}>
              {t('footer.contactUs.copy', 'Contact us copy')}
            </SFooterCopy>
          </Spacer>
        </Col>
      </Row>
      <UpperFooterLinks />
      <Row>
        <Col>
          <SHR />
        </Col>
      </Row>
    </>
  )
}
