import { Spacer } from '@retire/components'
import { ErrorCode } from '@retire/constants'
import { retireGQLCache } from '@retire/gql'
import type { Instruction } from '@retire/gql/__generated__/graphql'
import { useLocationAlerts, useMutationWithError, useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { getErrorCode } from '@retire/utils'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { TransactionTable } from '../../components'
import { HOLDINGS_QUERY, INSTRUCTIONS_QUERY } from '../../gql'
import type { TTransactionDetails } from '../../hooks'
import { DISPLAYED_INSTRUCTION_TYPES, useTransactions } from '../../hooks'
import { PENDING_INSTRUCTION_STATES } from '../../hooks/usePendingTransactions/constants'
import { CANCEL_INSTRUCTION_MUTATION } from './gql/mutations'

export const CancelTransaction: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()
  const { navigateWithAlerts } = useLocationAlerts()
  const { state } = useLocation()
  const transactionId = (state as Pick<TTransactionDetails, 'transactionId'>)?.transactionId || ''
  const {
    error: loadingError,
    data: { inProgressTransactions },
    loading,
  } = useTransactions()
  const transactionToBeCancelled = inProgressTransactions.find(
    (transaction: Instruction) => transaction.id === transactionId
  )

  const [cancelTransaction, { loading: cancellingTransaction }] = useMutationWithError(CANCEL_INSTRUCTION_MUTATION, {
    errorTitle: t('transactionList.cancelTransaction.alerts.error.general', 'Cancel transaction error'),
    refetchQueries: [
      { query: HOLDINGS_QUERY },
      { query: INSTRUCTIONS_QUERY, variables: { instructionTypes: DISPLAYED_INSTRUCTION_TYPES } },
      {
        query: INSTRUCTIONS_QUERY,
        variables: { instructionTypes: DISPLAYED_INSTRUCTION_TYPES, states: PENDING_INSTRUCTION_STATES },
      },
    ],
    onCompleted: () =>
      navigateWithAlerts(paths.dashboard.transactionList, [
        {
          id: `transaction-${transactionId}-cancelled`,
          type: 'success',
          title: t('transactionList.cancelTransaction.alerts.success', 'Transaction cancelled'),
        },
      ]),
    onError: error => {
      if (getErrorCode(error) === ErrorCode.forbidden) {
        retireGQLCache.evict({ fieldName: 'instructions' })
        navigateWithAlerts(paths.dashboard.transactionList, [
          {
            id: `transaction-${transactionId}-cancellation-error`,
            type: 'error',
            title: t(
              'transactionList.cancelTransaction.alerts.error.unableToCancel.title',
              'Unable to cancel transaction'
            ),
            message: t(
              'transactionList.cancelTransaction.alerts.error.unableToCancel.message',
              'Unable to cancel message'
            ),
          },
        ])
      }
    },
  })
  const onNext = useCallback(
    () => cancelTransaction({ variables: { id: transactionId } }),
    [cancelTransaction, transactionId]
  )
  const onBack = useCallback(() => navigate(-1), [navigate])

  if ((!loading && !transactionToBeCancelled) || loadingError) {
    return <Navigate to={paths.dashboard.transactionList} />
  }

  return (
    <PageTemplate
      title={t('transactionList.cancelTransaction.title', 'Cancel transaction')}
      subTitle={t('transactionList.cancelTransaction.subTitle', 'Confirm or abort')}
      fullWidth
      onNext={onNext}
      nextText={t('transactionList.cancelTransaction.actions.confirm', 'Confirm cancellation')}
      onBack={onBack}
      backText={t('transactionList.cancelTransaction.actions.abort', 'Abort cancellation')}
      loading={loading || cancellingTransaction}
    >
      <Spacer bottom="extraLarge" />
      <TransactionTable
        translationKey="cancel"
        displayAmount="amount"
        transactions={transactionToBeCancelled ? [transactionToBeCancelled] : []}
        hideDate
        showExtremeBorders
      />
    </PageTemplate>
  )
}
