import { Overview } from '@retire/components/Overview'
import { PotType } from '@retire/constants/pots'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { sentenceCase } from '@retire/utils/strings'
import type { FC } from 'react'
import { useMemo } from 'react'

import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'
import { IncomePotsStepDetails } from '../../JourneyOverview/IncomePotsStepDetails'
import { SavingsPotsStepDetails } from '../../JourneyOverview/SavingsPotsStepDetails'
import { TFLSStepDetails } from '../../JourneyOverview/TFLSStepDetails'
import { TotalSavingsDetails } from '../../JourneyOverview/TotalSavingsDetails'
import type { TJourneyOverview } from '../../JourneyOverview/types'

export const JourneyOverview: FC<TJourneyOverview> = ({ title, ...overviewProps }) => {
  const { t } = useTranslationWithOptions('illustration')
  const {
    illustrationPlanData: {
      flexibleIncomeAmount,
      inheritanceAmount,
      laterLifeAmount,
      rainyDayAmount,
      taxFreeLumpSumWithdrawalState,
      valuationAmount,
    },
    loadingIllustrationPlanData,
  } = useIllustrationPlanData()

  const sections = useMemo(
    () => [
      {
        key: 'total',
        name: t('overview.steps.totalSavings.name', 'Total savings'),
        details: <TotalSavingsDetails valuationAmount={valuationAmount} />,
      },
      {
        key: 'tfls',
        name: t('overview.steps.tfls.name', 'TFLS'),
        isCompleted: true,
        isInProgress: false,
        isStep: true,
        details: (
          <TFLSStepDetails
            valuationAmount={valuationAmount}
            taxFreeLumpSumWithdrawalState={taxFreeLumpSumWithdrawalState}
          />
        ),
      },
      {
        key: PotType.income,
        name: t('overview.steps.incomePots.name', 'Income pots', `${sentenceCase(PotType.income)} pots`),
        isStep: true,
        isCompleted: true,
        isInProgress: false,
        details: (
          <IncomePotsStepDetails
            flexibleIncomeAmount={flexibleIncomeAmount}
            laterLifeAmount={laterLifeAmount}
            isIncomePotsStepCompleted={true}
            isIncomePotsStepInProgress={false}
          />
        ),
        wrapOnDesktop: true,
      },
      {
        key: PotType.savings,
        name: t('overview.steps.savingsPots.name', 'Savings pots', `${sentenceCase(PotType.savings)} pots`),
        isStep: true,
        isCompleted: true,
        isInProgress: false,
        details: (
          <SavingsPotsStepDetails
            inheritanceAmount={inheritanceAmount}
            rainyDayAmount={rainyDayAmount}
            isSavingsPotsStepCompleted={true}
            isSavingsPotsStepInProgress={false}
          />
        ),
        wrapOnDesktop: true,
      },
    ],
    [
      flexibleIncomeAmount,
      inheritanceAmount,
      laterLifeAmount,
      rainyDayAmount,
      t,
      taxFreeLumpSumWithdrawalState,
      valuationAmount,
    ]
  )

  if (loadingIllustrationPlanData) {
    return null
  }

  return (
    <Overview {...overviewProps} forceExpanded title={title || t('overview.title', 'Overview')} sections={sections} />
  )
}
