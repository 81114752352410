import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

export const PotOut: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { accent, base = primary } = props

  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.74192 18.8528C9.743 19.7441 10.4472 20.4656 11.3171 20.4667H14.9917C15.8606 20.4656 16.5647 19.743 16.5658 18.8528V9.71986C16.5658 9.56668 16.6872 9.44236 16.8367 9.44236H19.9783C20.6576 9.49675 21.2502 8.97617 21.3032 8.28021C21.3336 7.87728 21.1743 7.48435 20.8742 7.22239L14.2681 0.457036C13.6452 -0.152345 12.6637 -0.152345 12.0407 0.457036L5.43458 7.22794C4.91567 7.6786 4.85283 8.47556 5.29267 9.00725C5.54942 9.31582 5.93508 9.4801 6.3305 9.44791H9.47217C9.62167 9.4468 9.74191 9.57001 9.743 9.72319C9.743 9.7243 9.743 9.7243 9.743 9.72541L9.74192 18.8528Z"
        fill={accent || base}
      />
      <path
        d="M0 21.5212C0.00216667 23.9942 1.95758 25.9978 4.37125 26H21.6287C24.0424 25.9978 25.9978 23.9942 26 21.5212V19.4378C25.9794 18.6874 25.3684 18.0958 24.635 18.118C23.9319 18.1391 23.3664 18.7174 23.3469 19.4378V21.5179C23.3458 22.4891 22.5767 23.2772 21.6287 23.2783H4.37125C3.42333 23.2772 2.65417 22.4891 2.65308 21.5179V19.4378C2.6325 18.6874 2.0215 18.0958 1.28917 18.1169C0.585 18.138 0.0205833 18.7174 0 19.4378V21.5212Z"
        fill={base}
      />
    </svg>
  )
}
