import { UnorderedList } from '@retire/components/List'
import { Copy } from '@retire/components/Typography/Copy'
import { CopyLevel } from '@retire/theme'
import type { FC } from 'react'
import { Visible } from 'react-grid-system'
import { Trans } from 'react-i18next'

import type { TTransactionDetail } from './types'

export const TransactionDetail: FC<TTransactionDetail> = ({ detail }) => (
  <>
    <Visible md lg xl>
      <Copy as="span" level={CopyLevel.caption}>
        <Trans components={{ list: <UnorderedList />, listItem: <li /> }}>{detail}</Trans>
      </Copy>
    </Visible>
    <Visible xs sm>
      <Copy as="p" level={CopyLevel.caption} textAlign="right">
        <Trans components={{ list: <UnorderedList />, listItem: <li /> }}>{detail}</Trans>
      </Copy>
    </Visible>
  </>
)
