import { ThemeConsumer } from '@retire/theme/styledComponents'
import { getDisplayName } from '@retire/utils'
import type { ComponentType, FC } from 'react'
import { Col, Container, Row } from 'react-grid-system'

import { DEFAULT_PROPS } from './constants'
import { ModalBox, ModalWrapper } from './presentation.styled'
import type { TBaseModal, TWrappedModal } from './types'

export const withBaseModal = <TPropTypes extends TBaseModal>(
  WrappedComponent: ComponentType<TWrappedModal<TPropTypes>>
) => {
  const Wrapped: FC<TPropTypes> = props => {
    const { isActive, withoutModalBox, dialogType, onClose, ...wrappedComponentProps } = props
    const { ariaLabelledBy, ariaDescribedBy } = wrappedComponentProps

    return (
      <ThemeConsumer>
        {theme => (
          <ModalWrapper
            isOpen={isActive}
            ariaHideApp={false}
            aria={{
              labelledby: ariaLabelledBy,
              describedby: ariaDescribedBy,
            }}
            onRequestClose={onClose}
            style={{
              overlay: {
                zIndex: 1000,
                backgroundColor: theme.modal.darkBackgroundColor,
                overflowX: 'hidden',
                overflowY: 'scroll',
              },
            }}
          >
            <Container>
              <Row>
                <Col md={12}>
                  <ModalBox
                    aria-labelledby={ariaLabelledBy}
                    aria-describedby={ariaDescribedBy}
                    isPlain={!!withoutModalBox}
                    tabIndex={-1}
                    role={dialogType}
                  >
                    <WrappedComponent onClose={onClose} {...wrappedComponentProps} />
                  </ModalBox>
                </Col>
              </Row>
            </Container>
          </ModalWrapper>
        )}
      </ThemeConsumer>
    )
  }

  Wrapped.displayName = `WithBaseModal(${getDisplayName(WrappedComponent)})`
  return Wrapped
}

withBaseModal.defaultProps = DEFAULT_PROPS
