import type { FC } from 'react'

import { SSelect } from './presentation.styled'
import type { TNativeSelect } from './types'

export const NativeSelect: FC<TNativeSelect> = ({ onChange, searchResults }) => (
  <SSelect onChange={onChange}>
    {searchResults.map(({ id, label }) => (
      <option value={id} key={id}>
        {label}
      </option>
    ))}
  </SSelect>
)
