import { DashboardNotFound } from '@retire/features/Dashboard/pages/DashboardNotFound'
import { getDisplayName } from '@retire/utils/components'
import type { FC } from 'react'
import { useLocation } from 'react-router-dom'

export const withLocationRouterStateValidation = <T,>(Component: FC<T>, requiredKeys: Array<keyof T>) => {
  const Wrapped: FC = () => {
    const location = useLocation() as {
      state: T
    }

    return requiredKeys.some(item => location.state?.[item] === undefined) ? (
      <DashboardNotFound />
    ) : (
      <Component {...location.state} key="componentWithLocationRouter" />
    )
  }

  Wrapped.displayName = `WithLocationRouterStateValidation(${getDisplayName(Component)})`
  return Wrapped
}
