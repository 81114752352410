import { graphql } from '@retire/gql/__generated__'

export const CONFIRM_ILLUSTRATION_PLAN_MUTATION = graphql(`
  mutation confirmIllustrationPlan {
    confirmIllustrationPlan {
      createdAt
      flexibleIncomeAmount
      flexibleIncomeMonthlyAmount
      inheritanceAmount
      laterLifeAge
      laterLifeAmount
      laterLifeMonthlyAmount
      rainyDayAmount
      referenceNumber
      taxFreeLumpSum
      valuationAmount
      valuationCalculatedOn
      valuationUpdatedAt
    }
  }
`)

export const ILLUSTRATION_PLAN_MUTATION = graphql(`
  mutation updateIllustrationPlan(
    $taxFreeLumpSumSelected: Boolean!
    $flexibleIncomeAmount: Int!
    $regularIncomeStartAge: Int
    $regularIncomeEndAge: Int
    $laterLifeAmount: Int!
    $laterLifeAge: Int
    $inheritanceAmount: Int!
    $rainyDayAmount: Int!
  ) {
    illustrationPlanUpdate(
      taxFreeLumpSumSelected: $taxFreeLumpSumSelected
      flexibleIncomeAmount: $flexibleIncomeAmount
      regularIncomeStartAge: $regularIncomeStartAge
      regularIncomeEndAge: $regularIncomeEndAge
      laterLifeAmount: $laterLifeAmount
      laterLifeAge: $laterLifeAge
      inheritanceAmount: $inheritanceAmount
      rainyDayAmount: $rainyDayAmount
    ) {
      ...illustrationPlanBody__Plan
    }
  }
`)

export const PARTIAL_ILLUSTRATION_PLAN_MUTATION = graphql(`
  mutation updatePartialIllustrationPlan(
    $taxFreeLumpSumSelected: Boolean!
    $flexibleIncomeAmount: Int
    $regularIncomeStartAge: Int
    $regularIncomeEndAge: Int
    $laterLifeAmount: Int
    $laterLifeAge: Int
    $rainyDayAmount: Int
    $inheritanceAmount: Int
  ) {
    partialIllustrationPlanUpdate(
      taxFreeLumpSumSelected: $taxFreeLumpSumSelected
      flexibleIncomeAmount: $flexibleIncomeAmount
      regularIncomeStartAge: $regularIncomeStartAge
      regularIncomeEndAge: $regularIncomeEndAge
      laterLifeAmount: $laterLifeAmount
      laterLifeAge: $laterLifeAge
      inheritanceAmount: $inheritanceAmount
      rainyDayAmount: $rainyDayAmount
    ) {
      ...illustrationPlanBody__Plan
    }
  }
`)

export const RESET_ILLUSTRATION_PLAN_MUTATION = graphql(`
  mutation resetIllustrationPlan {
    illustrationPlanReset {
      ...illustrationPlanBody__Plan
    }
  }
`)
