import { Copy } from '@retire/components'
import { StyleWeight, TypographyColor } from '@retire/theme'
import { toCurrency } from '@retire/utils'
import type { FC } from 'react'

import type { TJourneyOverviewAmount } from './types'

export const JourneyOverviewAmount: FC<TJourneyOverviewAmount> = ({ amount }) => (
  <Copy as="span" weight={StyleWeight.bold} color={TypographyColor.primary}>
    {toCurrency(amount)}
  </Copy>
)
