import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'
import { filterObjectKeys } from '@retire/utils'

import type { TCobsQuestions } from '../../components/QuestionList/types'
import { EMPTY_COBS } from './constants'
import { USER_COBS_MUTATION } from './gql/mutations'
import { USER_COBS_QUERY } from './gql/queries'

const fetchData: TFormFetchData<TCobsQuestions> = async ({ apolloQuery }) => {
  const {
    data: {
      user: { enrolment: cobs },
    },
  } = await apolloQuery({ query: USER_COBS_QUERY })

  return { formData: { ...EMPTY_COBS, ...filterObjectKeys(cobs || {}, ['__typename']) } }
}

const submitData: TFormSubmitData<TCobsQuestions> = async ({ formData, apolloMutate }) => {
  await apolloMutate({
    mutation: USER_COBS_MUTATION,
    variables: { cobs: formData },
    refetchQueries: [{ query: USER_COBS_QUERY }],
  })

  return formData
}

export const cobsServices = {
  fetchData,
  submitData,
}
