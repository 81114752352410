import { graphql } from '../__generated__/gql'

export const INIT_QUERY = graphql(`
  query init {
    user {
      id
      ...mainAttributes__User
      state
      policyNumber
    }
    illustrationPlan {
      valuationAmount
    }
  }
`)

export const USER_BRIEF_DETAILS_QUERY = graphql(`
  query getUserBriefDetails {
    user {
      id
      title
      ...name__User
      bornOn
      nationalInsuranceNumber
      ...contact__User
      ...address__User
      policyNumber
      gender
      regularIncomePaused
      nextRegularIncomePaymentOn
      beneficiaries {
        name
        relation
        bornOn
        percentage
      }
      ltaAbolished
    }
  }
`)

export const USER_NAME_QUERY = graphql(`
  query getUserName {
    user {
      id
      ...name__User
    }
  }
`)

export const USER_CONTACT_DETAILS_QUERY = graphql(`
  query getUserContactDetails {
    user {
      id
      ...contact__User
      ...address__User
    }
  }
`)

export const USER_FULL_DETAILS_QUERY = graphql(`
  query getUserFullDetails {
    user {
      id
      ...mainAttributes__User
      ...address__User
      nationalInsuranceNumber
      telephone
      telephoneCountryCode
      gender
    }
  }
`)
