import { AddressField } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import { isRequired, sentenceCase } from '@retire/utils'
import type { FC } from 'react'

import { Link } from '../../../Link'
import { Spacer } from '../../../Spacer'
import { FormTextInput } from '../../TextInput'
import type { TAddress, TAddressInput } from './types'

const AddressInput: FC<TAddressInput> = ({ autocomplete, fieldName, required }) => {
  const { t } = useTranslationWithOptions('common')

  return (
    <Spacer bottom="medium">
      <FormTextInput
        name={fieldName}
        rules={{
          validate: required
            ? {
                required: isRequired(
                  t(`form.contactDetails.address.${fieldName}.validation`, `${sentenceCase(fieldName)} error`)
                ),
              }
            : undefined,
        }}
        required={required}
        label={t(`form.contactDetails.address.${fieldName}.label`, `${sentenceCase(fieldName)} label`)}
        autocomplete={autocomplete}
      />
    </Spacer>
  )
}

export const Address: FC<TAddress> = ({ fields, setSearchMode, requiredFields }) => {
  const { t } = useTranslationWithOptions('common')

  const autocompleteList: Partial<Record<AddressField, string>> = {
    [AddressField.line1]: 'address-line1',
    [AddressField.line2]: 'address-line2',
    [AddressField.line3]: 'address-line3',
    [AddressField.postcode]: 'postal-code',
  }

  return (
    <>
      {fields.map(field => (
        <AddressInput
          fieldName={field}
          key={field}
          required={!!requiredFields?.includes(field)}
          autocomplete={autocompleteList[field]}
        />
      ))}
      <Link as="button" onClick={setSearchMode}>
        {t('form.contactDetails.address.links.find', 'Find address by postcode')}
      </Link>
    </>
  )
}
