import { Icon } from '@retire/components/Icon'
import { NavButtons } from '@retire/components/NavButtons'
import { Spacer } from '@retire/components/Spacer'
import { Copy, Headline } from '@retire/components/Typography'
import { CopyLevel, HeadlineLevel } from '@retire/theme'
import type { FC } from 'react'
import { Container } from 'react-grid-system'

import { SPageContainer } from '../common.styled'
import { SButtonsContainer, SInnerContainer, SPageOverlay } from './presentation.styled'
import type { TOverlay } from './types'

export const Overlay: FC<TOverlay> = ({ icon, children, title, subTitle, ...buttonProps }) => (
  <SPageOverlay>
    <SPageContainer>
      <Container>
        <SInnerContainer>
          <Icon iconType={icon} iconSize="large" />
          {title && (
            <>
              <Spacer top="extraLarge" as="div" />
              <Headline level={HeadlineLevel.h1} as="h1" center>
                {title}
              </Headline>
              <Spacer bottom="medium" />
              <Copy as="div" level={CopyLevel.body2} textAlign="center">
                {subTitle}
              </Copy>
            </>
          )}
          <Spacer bottom="medium" />
          {children}
          <Spacer top="extraLarge" as="div" />
          <SButtonsContainer>
            <NavButtons {...buttonProps} justifyContent="center" />
          </SButtonsContainer>
        </SInnerContainer>
      </Container>
    </SPageContainer>
  </SPageOverlay>
)
