import { PotName } from '@retire/constants/pots'
import { useGetPotDisplayName } from '@retire/hooks/useGetPotDisplayName'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'

export const LaterLifePotInsufficientSavings: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.incomePots })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()
  const { getPotDisplayLongName } = useGetPotDisplayName()

  const {
    loadingIllustrationPlanData,
    illustrationPlanData,
    savePartialIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
  } = useIllustrationPlanData()
  const { remainingSavings } = illustrationPlanData

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      laterLifeAmount: null,
      laterLifeAge: null,
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  const {
    [PotName.laterLife]: { minAmount },
  } = useSettings()

  const onNext = useCallback(() => navigate(paths.illustration.journeyIntro), [navigate])
  const onBack = useCallback(
    async () =>
      await savePartialIllustrationPlanData(
        {
          ...illustrationPlanData,
          laterLifeAmount: 0,
        },
        paths.illustration.journeySavingsPotsIntro
      ),
    [illustrationPlanData, savePartialIllustrationPlanData]
  )

  return (
    <JourneyStepLayout
      title={getPotDisplayLongName(PotName.laterLife)}
      description={t(
        'steps.potSteps.insufficientSavings.description',
        'Remaining savings {{remainingSavings}} does not reach the minimum pot amount {{minimumAmountAllowed}}',
        {
          minimumAmountAllowed: toCurrency(minAmount),
          remainingSavings: toCurrency(remainingSavings),
        }
      )}
      onNext={onNext}
      nextText={t('steps.potSteps.insufficientSavings.actions.restart', 'Start over')}
      onBack={onBack}
      backText={t('steps.potSteps.insufficientSavings.actions.continue', 'Continue without this pot')}
      loading={loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData}
    />
  )
}
