import { Button } from '@retire/components/Button'
import { Spacer } from '@retire/components/Spacer'
import { Twister } from '@retire/components/Twister'
import { ButtonStyleType } from '@retire/constants/button'
import { BeneficiaryForm } from '@retire/features-shared/Account/components/Beneficiaries/components/BeneficiaryForm'
import type { TBeneficiariesList } from '@retire/features-shared/Account/components/Beneficiaries/types'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useMemo } from 'react'

import { StepWrapper } from '../StepWrapper'

export const BeneficiariesList: FC<TBeneficiariesList> = ({ beneficiaries, onSave, onAdd, onRemove }) => {
  const { t } = useTranslationWithOptions('enrolment')

  const bottomElement = useMemo(
    () => (
      <>
        <Spacer bottom="medium" />
        <Twister title={t('moneyLeftOver.twister.title', 'Twister title')}>
          {t('moneyLeftOver.twister.description', 'Twister description')}
        </Twister>
      </>
    ),
    [t]
  )

  return (
    <StepWrapper
      title={t('moneyLeftOver.title', 'Money left over')}
      subTitle={t('moneyLeftOver.subTitle', 'Please, indicate your beneficiaries')}
      nextText={t('general.actions.next', 'Next')}
      backText={t('general.actions.back', 'Back')}
      onNext={onSave}
      backUrl={paths.enrolment.bankOrBuildingSociety}
      bottomElement={bottomElement}
    >
      {beneficiaries.map((_, index) => (
        <BeneficiaryForm
          // eslint-disable-next-line react/no-array-index-key
          key={`beneficiary-${index}`}
          index={index}
          removeItem={onRemove}
        />
      ))}
      <Button buttonStyleType={ButtonStyleType.secondary} onClick={onAdd}>
        {t(
          `common:account.beneficiaries.actions.add.${!beneficiaries.length ? 'label' : 'anotherLabel'}`,
          `Add ${!beneficiaries.length ? '' : 'another '}beneficiary`
        )}
      </Button>
    </StepWrapper>
  )
}
