import { colors } from './colors'
import { utilities } from './utilities'

export enum PotColor {
  disabled = 'disabled',
  transparent = 'transparent',
  flexibleIncomePot = 'flexibleIncomePot',
  laterLifePot = 'laterLifePot',
  inheritancePot = 'inheritancePot',
  rainyDayPot = 'rainyDayPot',
}

const potColors: Record<PotColor, string> = {
  disabled: colors.neutral50,
  transparent: colors.transparent,
  flexibleIncomePot: colors.accent1,
  laterLifePot: colors.accent2,
  inheritancePot: colors.accent3,
  rainyDayPot: colors.accent4,
}

export const pot = {
  disabledBg: colors.neutral20,
  potColor: potColors,
  colorBar: {
    width: '8px',
    radius: utilities.border.radius,
  },
}
