export const rejectAfterTimeoutWithMessage = async <T>(
  ms: number,
  promise: Promise<T>,
  message: string
): Promise<T> => {
  const timeout = new Promise<T>((_resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      reject(new Error(message))
    }, ms)
  })

  return Promise.race([promise, timeout])
}
