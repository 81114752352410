import { useQuerySelector } from '@retire/hooks/useQuerySelector'
import { getTFLSStateFromAmount } from '@retire/utils/pots'
import type { FC } from 'react'
import { createContext, useMemo } from 'react'

import { ILLUSTRATION_PLAN_QUERY } from '../../gql/queries'
import type { TGetIllustrationPlanQuery } from '../../gql/types'
import type { TIllustrationPlanData } from '../../types'
import { DEFAULT_ILLUSTRATION_PLAN_DATA } from './constants'
import type { TIllustrationPlanDataContext } from './types'
import { calculateRemainingSavings } from './utils'

export const IllustrationPlanDataContext = createContext<TIllustrationPlanDataContext>({
  illustrationPlanData: DEFAULT_ILLUSTRATION_PLAN_DATA,
  loadingIllustrationPlanData: true,
})

export const IllustrationPlanDataProvider: FC = ({ children }) => {
  const { data: illustrationPlanData, loading: loadingIllustrationPlanData } = useQuerySelector<
    TGetIllustrationPlanQuery,
    TIllustrationPlanData
  >(
    { query: ILLUSTRATION_PLAN_QUERY },
    ({
      illustrationPlan: {
        valuationAmount,
        taxFreeLumpSum: taxFreeLumpSumAmount,
        flexibleIncomeAmount,
        regularIncomeStartAge,
        regularIncomeEndAge,
        laterLifeAmount,
        laterLifeAge,
        inheritanceAmount,
        rainyDayAmount,
        partiallyUpdatedAt,
        valuationUpdatedAt,
      },
    }) => {
      const data: TIllustrationPlanData = {
        valuationAmount,
        flexibleIncomeAmount,
        regularIncomeStartAge,
        regularIncomeEndAge,
        laterLifeAmount,
        rainyDayAmount,
        inheritanceAmount,
        taxFreeLumpSumWithdrawalState: getTFLSStateFromAmount(taxFreeLumpSumAmount as unknown as number),
        laterLifeAge,
        partiallyUpdatedAt,
        valuationUpdatedAt,
        remainingSavings: 0,
      }

      return {
        ...data,
        remainingSavings: calculateRemainingSavings(data),
      }
    },
    DEFAULT_ILLUSTRATION_PLAN_DATA
  )

  const value = useMemo(
    () => ({
      illustrationPlanData,
      loadingIllustrationPlanData,
    }),
    [illustrationPlanData, loadingIllustrationPlanData]
  )

  return <IllustrationPlanDataContext.Provider value={value}>{children}</IllustrationPlanDataContext.Provider>
}
