import type { TInputKeyEvent, TInputKeyEventHandler } from '@retire/types'
import { useCallback } from 'react'

export const useBlockOnKeyDown = (blockCriteria: RegExp, keyDownHandler?: TInputKeyEventHandler) =>
  useCallback(
    (e: TInputKeyEvent) => {
      if (blockCriteria.test(e.key)) {
        e.preventDefault()
      } else if (keyDownHandler) {
        keyDownHandler(e)
      }
    },
    [blockCriteria, keyDownHandler]
  )
