export enum LinkVariant {
  default = 'default',
  dark = 'dark',
  bright = 'bright',
}

export enum LinkSize {
  normal = 'normal',
  large = 'large',
}
