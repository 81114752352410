import { styled } from '@retire/theme/styledComponents'

export const STagsList = styled.span`
  display: inline-block;
  margin: ${({ theme }) => `0 -${theme.spacing.small}`};
`

export const STagItem = styled.span`
  display: inline-block;
  margin: ${({ theme }) => `0 ${theme.spacing.extraSmall}`};
`
