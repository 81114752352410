import type { OperationVariables } from '@apollo/client/core'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { DocumentNode } from 'graphql'

import { useQueryWithError } from '../useQueryWithError'
import type { TUseQueryWithErrorOptions } from '../useQueryWithError/types'
import type { TQuerySelectorResult } from './types'

export const useQuerySelector = <TData, TResponse, TVariables extends OperationVariables = OperationVariables>(
  {
    query,
    options,
  }: {
    query: DocumentNode | TypedDocumentNode<TData, TVariables>
    options?: TUseQueryWithErrorOptions<TData, TVariables>
  },
  selector: (data: TData) => TResponse,
  defaults?: TResponse
): TQuerySelectorResult<TResponse> => {
  const { data, error, networkStatus, loading } = useQueryWithError<TData, TVariables>(query, options)

  return {
    data: ((data && selector(data)) || defaults || undefined) as TResponse,
    error,
    networkStatus,
    loading,
  }
}
