import { extractPotName } from '@retire/utils'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import type { TConfirmPageLocationState } from '../../../MoveMoneyBetweenPots'
import { BANK_OR_SOCIETY_ACCOUNT } from '../../../MoveMoneyBetweenPots'
import type { TUseConfirmMoveMoneyParams } from './types'

export const useConfirmMoveMoneyParams = (): TUseConfirmMoveMoneyParams => {
  const { state } = useLocation() as TConfirmPageLocationState

  const { amount = '', fromPot, toPotOrAccount } = state || {}

  const fromPotName = extractPotName(fromPot)
  const toPotName = extractPotName(toPotOrAccount)
  const toAccountName = toPotOrAccount === BANK_OR_SOCIETY_ACCOUNT ? toPotOrAccount : undefined
  const toPotOrAccountName = toPotName || toAccountName

  return useMemo(() => {
    return {
      fromPot: fromPotName,
      toPotOrAccount: toPotOrAccountName,
      amount,
    }
  }, [amount, fromPotName, toPotOrAccountName])
}
