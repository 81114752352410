import { IDS } from '@retire/constants'
import type { FC } from 'react'
import { Container } from 'react-grid-system'

import { SOutageHeader } from './presentation.styled'

export const OutageBanner: FC = ({ children }) => (
  <SOutageHeader id={IDS.outageBanner} tabIndex={0}>
    <Container>{children}</Container>
  </SOutageHeader>
)
