import { USER_NAME_QUERY } from '@retire/gql/user/queries'
import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'
import { i18n } from '@retire/i18n'
import { generateDefaultTranslationString, rejectAfterTimeoutWithMessage } from '@retire/utils'

import { BANK_DETAILS_MUTATION } from './gql/mutations'
import type { TBankDetails } from './types'

const fetchData: TFormFetchData<TBankDetails> = async ({ apolloQuery }) => {
  const {
    data: {
      user: { forename, surname },
    },
  } = await apolloQuery({ query: USER_NAME_QUERY })

  return { formData: { accountName: `${forename} ${surname}`, accountNumber: '', sortCode: '' } }
}

const submitData: TFormSubmitData<TBankDetails> = async ({ formData, apolloMutate }) => {
  await rejectAfterTimeoutWithMessage(
    30000,
    apolloMutate({
      mutation: BANK_DETAILS_MUTATION,
      variables: formData,
    }),
    i18n.t('enrolment:bankOrBuildingSociety.form.errors.general', generateDefaultTranslationString('Timeout error'))
  )

  return formData
}

export const bankDetailsServices = {
  fetchData,
  submitData,
}
