import { UserState } from '@retire/gql/__generated__/graphql'
import { USER_STATE_MUTATION } from '@retire/gql/user/mutations'
import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'

import type { TDeclaration } from './types'

const fetchData: TFormFetchData<TDeclaration> = async () => ({ formData: { signature: '' } })
const submitData: TFormSubmitData<TDeclaration> = async ({ formData, apolloMutate }) => {
  await apolloMutate({
    mutation: USER_STATE_MUTATION,
    variables: { state: UserState.AwaitingApproval },
  })

  return formData
}

export const declarationServices = {
  fetchData,
  submitData,
}
