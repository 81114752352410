import { Button, Card, CardTitle, Copy, Spacer } from '@retire/components'
import { ButtonStyleType } from '@retire/constants/button'
import { useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { TypographyColor } from '@retire/theme/colors'
import { StyleWeight } from '@retire/theme/utilities'
import { toCurrency } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import type { TBalanceCard } from './types'

export const BalanceCard: FC<TBalanceCard> = ({ balance, buttonText, caveatText, children }) => {
  const navigate = useNavigate()
  const { t } = useTranslationWithOptions('dashboard')
  const formattedBalance = useMemo(() => toCurrency(balance), [balance])
  const navigateToMoveMoney = useCallback(() => {
    navigate(paths.dashboard.moveMoneyBetweenPotsHowItWorks)
  }, [navigate])

  return (
    <Card>
      <CardTitle>{t('pots.common.balanceCard.title', 'Balance')}</CardTitle>
      <Copy as="span" weight={StyleWeight.semiBold} color={TypographyColor.primary}>
        {formattedBalance}
      </Copy>
      <Spacer top="medium" />

      {children}

      <Button onClick={navigateToMoveMoney} buttonStyleType={ButtonStyleType.secondary}>
        {buttonText}
      </Button>
      {caveatText ? (
        <Spacer top="medium">
          <Copy as="p">{caveatText}</Copy>
        </Spacer>
      ) : null}
    </Card>
  )
}
