// https://www.figma.com/file/yEZDiAbzjdNiibzZu2ERnJ/Smart-Retire-(will-be-archived-soon)?node-id=676%3A407

export enum TypographyColor {
  primary = 'primary',
  neutralLight = 'neutralLight',
  neutral = 'neutral',
  neutralDark = 'neutralDark',
  hyperlink = 'hyperlink',
  white = 'white',
}

const basicColors = {
  // primary colors
  primary: '#003357',
  secondary: '#38DAFE',
  // accent colors
  accent1: '#50E3C2',
  accent2: '#009BD4',
  accent3: '#662D91',
  accent4: '#93CF8C',
  // alert colors
  success: '#7ED321',
  successBackground: '#EAFDD6',
  info: '#0075C8',
  infoBackground: '#E6FAFF',
  warning: '#FF9C36',
  warningBackground: '#F8E7D5',
  error: '#BC074C',
  errorBackground: '#F8D9DD',
  notice: '#D8D8D8',
  noticeBackground: '#FFFFFF',
  // other colors
  hyperlink: '#0167A8',
  hyperlinkHover: '#024CA3',
  footerLink: '#50E3C1',
  destructiveHover: '#99043D',
  buttonHover: '#00C7F5',
  rowHover: '#E6FAFF',
  neutral70: '#272F3E',
  neutral60: '#4B4B4D',
  neutral50: '#6C6C6C',
  neutral40: '#BDBDBD',
  neutral30: '#D8D8D8',
  neutral20: '#F2F2F2',
  neutral10: '#F7F7F7',
  white: '#FFFFFF',
  transparent: 'rgba(0, 0, 0, 0)',
  // mercer color
  mercer: '#002C77',
}

// basic + alias colors
export const colors: Record<TypographyColor, string> & Record<string, string> = {
  ...basicColors,
  neutralLight: basicColors.neutral30,
  neutral: basicColors.neutral50,
  neutralDark: basicColors.neutral60,
}
