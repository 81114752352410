import { styled } from '@retire/theme/styledComponents'
import { Col } from 'react-grid-system'

export const SColRight = styled(Col)`
  text-align: right;
`

export const SFrom = styled.span`
  margin-right: ${({ theme }) => theme.spacing.medium};
`
