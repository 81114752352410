import { useLocationAlerts } from '@retire/hooks'
import type { FC } from 'react'
import { useEffect } from 'react'
import { animateScroll } from 'react-scroll'

import { PageTemplateComponent } from './presentation'
import type { TPageTemplate } from './types'

export const PageTemplate: FC<TPageTemplate> = props => {
  const { alerts, resetAlerts } = useLocationAlerts()

  useEffect(() => {
    !!alerts?.length && animateScroll.scrollToTop({ smooth: true, duration: 500, delay: 400 })
  }, [alerts])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetAlerts, [])

  return <PageTemplateComponent {...props} alerts={alerts} />
}
