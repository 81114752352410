import type { FC } from 'react'

import { JourneyOverviewAmount } from '../../../JourneyOverview/Amount'
import { JourneyOverviewDetails } from '../../../JourneyOverview/Details'
import type { TRemainingAmountDetails } from './types'

export const RemainingAmountDetails: FC<TRemainingAmountDetails> = ({ remainingSavings }) => (
  <JourneyOverviewDetails>
    <JourneyOverviewAmount amount={remainingSavings} />
  </JourneyOverviewDetails>
)
