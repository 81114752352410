import { useConfirmModalWithTranslation } from '../useConfirmModalWithTranslation'
import { useTranslationWithOptions } from '../useTranslationWithOptions'
import type { TUseAreYouSureModal } from './types'

export const useAreYouSureModal: TUseAreYouSureModal = ({ onConfirm }) => {
  const { t } = useTranslationWithOptions('common')

  const { Modal: QuitModal, showModal: showQuitModal } = useConfirmModalWithTranslation({
    title: t('quitModal.title', 'Are you sure you want to quit?'),
    content: t('quitModal.content', 'Your changes will not be saved.'),
    confirmText: t('quitModal.actions.confirm', 'Yes, quit'),
    closeText: t('quitModal.actions.cancel', 'No, cancel'),
    onConfirm,
  })
  return { showQuitModal, QuitModal }
}
