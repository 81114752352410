import { Button } from '@retire/components/Button'
import { Card, CardTitle } from '@retire/components/Card'
import { DataRow } from '@retire/components/DataRow'
import { Divider } from '@retire/components/Divider'
import { Notification } from '@retire/components/Notification'
import { Spacer } from '@retire/components/Spacer'
import { ButtonStyleType } from '@retire/constants/button'
import { PotName } from '@retire/constants/pots'
import { useEstimateIncomeModal } from '@retire/hooks/useEstimateIncomeModal'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { Container, Row } from 'react-grid-system'
import { useNavigate } from 'react-router-dom'

import type { TLaterLifePotMonthlyIncomeCard } from './types'

export const LaterLifePotMonthlyIncomeCard: FC<TLaterLifePotMonthlyIncomeCard> = ({
  allocatedAmount,
  monthlyIncomeAmount = 0,
  startAge,
  children,
}) => {
  const { t } = useTranslationWithOptions('dashboard')
  const formattedMonthlyIncomeAmount = useMemo(() => toCurrency(monthlyIncomeAmount), [monthlyIncomeAmount])
  const navigate = useNavigate()
  const { Modal, ModalLink } = useEstimateIncomeModal(PotName.laterLife)

  const manageYourIncome = useCallback(() => {
    navigate(paths.dashboard.laterLifePotManageIncomePeriod)
  }, [navigate])

  return (
    <Card>
      <CardTitle>{t('pots.common.monthlyIncomeCard.title', 'Monthly income')}</CardTitle>
      {!allocatedAmount ? (
        <Notification
          type="notice"
          title={t('pots.common.monthlyIncomeCard.notUsed', 'Move money to the pot to manage the income')}
          hasBorder
        />
      ) : (
        <>
          <ModalLink />
          <DataRow
            name={t('pots.common.monthlyIncomeCard.allocatedAmount', 'Allocated amount')}
            value={formattedMonthlyIncomeAmount}
          />
          <DataRow name={t('pots.common.monthlyIncomeCard.startAge', 'Start age')} value={String(startAge)} />
          <Container>
            <Spacer left="small" />
            <Row>
              <Button onClick={manageYourIncome} buttonStyleType={ButtonStyleType.secondary}>
                {t('pots.common.monthlyIncomeCard.actions.manageIncome', 'Manage income')}
              </Button>
            </Row>
          </Container>
          {children && (
            <>
              <Divider top="extraLarge" bottom="extraLarge" />
              {children}
            </>
          )}
          <Modal />
        </>
      )}
    </Card>
  )
}
