import type { SyntheticEvent } from 'react'

import type { TOnPastePrevent } from './types'

export const onPastePrevent: TOnPastePrevent = (event, preventCriteria) => {
  const pastedText = event.clipboardData.getData('Text')
  const shouldBePrevented = preventCriteria.test(pastedText)
  if (shouldBePrevented) {
    event.preventDefault()
  }
  return shouldBePrevented
}

export const preventDefault = (event: SyntheticEvent) => event.preventDefault()
