import { useMutation } from '@apollo/client'
import { PartialLoader } from '@retire/components/PartialLoader'
import { INIT_QUERY, USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { PartialLoaderContainer } from '../../components/ConfirmEmail'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { USER_EMAIL_MUTATION } from '../EmailChanging/gql/mutations'

export const EmailResending: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.email })
  const { t } = useTranslationWithOptions('enrolment')
  const { unconfirmedEmail } = useUserDetails().data
  const navigate = useNavigate()

  const [resendEmail] = useMutation(USER_EMAIL_MUTATION, {
    refetchQueries: [{ query: USER_FULL_DETAILS_QUERY }, { query: INIT_QUERY }],
    onCompleted: () => navigate(paths.enrolment.emailResent),
    onError: () => navigate(paths.hygienePages.serverError),
  })

  useEffect(() => {
    if (unconfirmedEmail) {
      void resendEmail({ variables: { email: unconfirmedEmail } })
    }
  }, [resendEmail, unconfirmedEmail])

  return (
    <PageTemplate
      title={
        <>
          <PartialLoaderContainer withSpacing>
            <PartialLoader />
          </PartialLoaderContainer>
          {t('emailResending.title', 'Resending email')}
        </>
      }
      subTitle={t('emailResending.description', 'Description')}
    />
  )
}
