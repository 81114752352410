import { useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const EmailVerificationError: FC = () => {
  const { t } = useTranslationWithOptions('enrolment')
  const navigate = useNavigate()
  const onNext = useCallback(() => navigate(paths.enrolment.emailResending), [navigate])

  return (
    <PageTemplate
      title={t('emailVerificationError.title', 'Email verification error')}
      titleIconType="Warning"
      subTitle={t('emailVerificationError.description', 'Email verification error description')}
      nextText={t('emailVerificationError.actions.resend', 'Resend email')}
      onNext={onNext}
    />
  )
}
