import { FormOptionsGroup } from '@retire/components/Forms/OptionsGroup'
import { Notification } from '@retire/components/Notification'
import { Spacer } from '@retire/components/Spacer'
import { TaxFreeLumpSumWithdrawalState } from '@retire/constants/pots'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { toCurrency } from '@retire/utils/currency'
import { getTFLSTheoricalAmount } from '@retire/utils/pots'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { JourneyStepLayout } from '../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../hooks/useJourneyHeader/constants'
import { useJourneyOverviewContext } from '../../../hooks/useJourneyOverviewContext'
import type { TTaxFreeLumpSumFormFields } from './types'

export const TaxFreeLumpSum: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.intros })
  const { navigateWithCurrentState } = useJourneyOverviewContext()
  const { t } = useTranslationWithOptions('illustration')

  const {
    loadingIllustrationPlanData,
    illustrationPlanData,
    savePartialIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
  } = useIllustrationPlanData()

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData()
  }, [removeSubsequentIllustrationJourneyStepsData])

  const {
    lumpSumAllowance: { amount: lumpSumAllowanceAmount },
    lumpSumAndDeathBenefitAllowance: { amount: lumpSumAndDeathBenefitAllowanceAmount },
  } = useSettings()

  const options = useMemo(
    () => [
      {
        label: t('steps.taxFreeLumpSumWithdrawal.options.withdraw', 'TFLS withdraw'),
        value: TaxFreeLumpSumWithdrawalState.taken,
      },
      {
        label: t('steps.taxFreeLumpSumWithdrawal.options.doNotWithdraw', 'TFLS do not withdraw'),
        value: TaxFreeLumpSumWithdrawalState.untaken,
      },
    ],
    [t]
  )

  const optionName = 'taxFreeLumpSumDecision'
  const methods = useForm<TTaxFreeLumpSumFormFields>({
    defaultValues: {
      [optionName]: TaxFreeLumpSumWithdrawalState.undecided,
    },
  })
  const option = methods.watch(optionName)
  const onNext = useCallback(
    async () =>
      await savePartialIllustrationPlanData(
        {
          ...illustrationPlanData,
          taxFreeLumpSumWithdrawalState: option,
        },
        paths.illustration.journeyPotsIntro
      ),
    [illustrationPlanData, option, savePartialIllustrationPlanData]
  )
  const onBack = useCallback(
    () => navigateWithCurrentState(paths.illustration.journeyIntro),
    [navigateWithCurrentState]
  )

  return (
    <FormProvider {...methods}>
      <JourneyStepLayout
        title={t('steps.taxFreeLumpSumWithdrawal.title', 'TFLS title')}
        description={
          <Trans components={{ bold: <strong /> }}>
            {t(
              'steps.taxFreeLumpSumWithdrawal.description',
              'TFLS description: LumpSumAllowance amount {{lumpSumAllowanceAmount}}, LumpSumAndDeathBenefitAllowance amount {{lumpSumAndDeathBenefitAllowanceAmount}}, TaxFreeLumpSum amount {{taxFreeLumpSumAmount}}',
              {
                lumpSumAllowanceAmount: toCurrency(lumpSumAllowanceAmount, 0),
                lumpSumAndDeathBenefitAllowanceAmount: toCurrency(lumpSumAndDeathBenefitAllowanceAmount, 0),
                taxFreeLumpSumAmount: toCurrency(getTFLSTheoricalAmount(lumpSumAndDeathBenefitAllowanceAmount), 0),
              }
            )}
          </Trans>
        }
        nextText={t('common.buttons.continue', 'Continue')}
        onNext={onNext}
        disableNext={option === TaxFreeLumpSumWithdrawalState.undecided}
        onBack={onBack}
        backText={t('common.buttons.back', 'Back')}
        loading={loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData}
      >
        <ContentWrapper>
          <FormOptionsGroup name={optionName} fullWidth options={options} />
        </ContentWrapper>
        {option === TaxFreeLumpSumWithdrawalState.untaken && (
          <Spacer top="mediumSmall">
            <Notification type="warning" title={t('steps.taxFreeLumpSumWithdrawal.alert', 'TFLS alert')} />
          </Spacer>
        )}
      </JourneyStepLayout>
    </FormProvider>
  )
}
