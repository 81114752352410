import { Copy } from '@retire/components'
import { styled } from '@retire/theme/styledComponents'

export const SLaterLifeFigure = styled.div<{ danger: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => (props.danger ? props.theme.colors.error : 'inherit')};
`

export const SSubValue = styled(Copy)`
  // this is needed to align pending values with links
  // as the line heights differ
  margin-top: -6px;
`

export const SPotAgeSection = styled(Copy)`
  ${({ theme }) => `
    border-left: 1px solid ${theme.colors.neutralLight};
    margin-left: ${theme.spacing.mediumSmall};
    padding-left: ${theme.spacing.mediumSmall};
  `}
`
