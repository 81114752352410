import { CopyLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

import { Icon } from '../Icon'
import { Spacer } from '../Spacer'
import { Copy, Headline } from '../Typography'
import { DEFAULT_PROPS } from './constants'
import { SIconContainer } from './presentation.styled'
import type { TPageTitle } from './types'

export const PageTitle: FC<TPageTitle> = ({
  title,
  titleIconType,
  subTitle,
  smallSubTitle,
  bottomElement,
  headlineAs = DEFAULT_PROPS.headlineAs,
  headlineLevel = DEFAULT_PROPS.headlineLevel,
  bottom = DEFAULT_PROPS.bottom,
  id = DEFAULT_PROPS.id,
}) => {
  const Heading: FC = () => (
    <Headline level={headlineLevel} as={headlineAs} id={id} tabIndex={headlineAs === 'h1' ? -1 : undefined}>
      {titleIconType && (
        <SIconContainer>
          <Icon iconType={titleIconType} iconSize="medium" />
        </SIconContainer>
      )}
      {title}
    </Headline>
  )

  return (
    <>
      <Row>
        <Col>
          {title && <Heading />}
          {!!subTitle && (
            <>
              {title && <Spacer bottom="medium" />}
              <Copy as="div" level={smallSubTitle ? undefined : CopyLevel.body2}>
                {subTitle}
              </Copy>
            </>
          )}
          {bottomElement}
        </Col>
      </Row>
      <Spacer bottom={bottom} />
    </>
  )
}
