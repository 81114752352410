import type { Instruction } from '@retire/gql/__generated__/graphql'
import { InstructionPublicState, InstructionType } from '@retire/gql/__generated__/graphql'
import { formatDifference, toCurrency } from '@retire/utils'

import type { TDisplayAmount } from './types'

export const isPaidOut = (transaction: Instruction) => !transaction.buyPotType

export const isPaidIn = (transaction: Instruction) => !transaction.sellPotType

export const getFormattedAmount = (transaction: Instruction, displayedAmount: TDisplayAmount) => {
  const amount = transaction[displayedAmount]
  if (amount) {
    return !isPaidOut(transaction) ? toCurrency(amount) : formatDifference(-amount)
  }
  return '-'
}

export const isCancellable = (transaction: Instruction) => {
  const cancellableTypes: InstructionType[] = [InstructionType.Withdrawal, InstructionType.MoveMoney]
  return transaction.state === InstructionPublicState.Created && cancellableTypes.includes(transaction.instructionType)
}

export const isCancelled = (transaction: Instruction) => transaction.state === InstructionPublicState.Cancelled

export const isFailed = (transaction: Instruction) => transaction.state === InstructionPublicState.Failed
