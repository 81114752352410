import { UnorderedList } from '@retire/components/List'
import { Segment } from '@retire/components/Segment'
import { Copy } from '@retire/components/Typography/Copy'
import { PotType } from '@retire/gql/__generated__/graphql'
import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import { usePotFunds } from '@retire/hooks/usePotFunds'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'
import { isInheritancePotConfigured } from '../../../utils'
import { JourneyOverview } from '../JourneyOverview'
import {
  isLaterLifePotStepAccessible,
  isPotIntroStepAccessible,
  isRainyDayPotStepAccessible,
  isReadyStepAccessible,
  isSavingsPotIntroStepAccessible,
} from './utils'

export const ContinueOrRestart: FC = () => {
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()
  const { illustrationPlanData, loadingIllustrationPlanData, resetIllustrationPlanData } = useIllustrationPlanData()
  const { partiallyUpdatedAt, valuationUpdatedAt } = illustrationPlanData
  const { loading: loadingInheritancePotFunds, availableFunds } = usePotFunds(PotType.Inheritance)
  const isEnsignUser = useIsEnsignUser()

  const onNext = useCallback(() => {
    let redirectPath = paths.illustration.journeyIntro
    if (isReadyStepAccessible(illustrationPlanData, isEnsignUser, availableFunds)) {
      redirectPath = paths.illustration.journeyReady
    } else if (isRainyDayPotStepAccessible(illustrationPlanData, isEnsignUser, availableFunds)) {
      redirectPath = paths.illustration.journeyRainyDayPotIntro
    } else if (isSavingsPotIntroStepAccessible(illustrationPlanData)) {
      if (isEnsignUser && isInheritancePotConfigured(illustrationPlanData)) {
        redirectPath = paths.illustration.journeyInheritancePotFunds
      } else {
        redirectPath = paths.illustration.journeySavingsPotsIntro
      }
    } else if (isLaterLifePotStepAccessible(illustrationPlanData)) {
      redirectPath = paths.illustration.journeyLaterLifePotIntro
    } else if (isPotIntroStepAccessible(illustrationPlanData)) {
      redirectPath = paths.illustration.journeyPotsIntro
    }
    navigate(redirectPath)
  }, [availableFunds, illustrationPlanData, isEnsignUser, navigate])

  const onBack = useCallback(() => resetIllustrationPlanData(), [resetIllustrationPlanData])

  const displayOverview = Boolean(partiallyUpdatedAt && valuationUpdatedAt && valuationUpdatedAt > partiallyUpdatedAt)

  return (
    <PageTemplate
      title={t('continueOrRestart.title', 'Continue or Restart')}
      onNext={onNext}
      nextText={t('continueOrRestart.actions.continue', 'Continue')}
      onBack={onBack}
      backText={t('continueOrRestart.actions.restart', 'Restart')}
      fullWidth
      loading={loadingIllustrationPlanData || loadingInheritancePotFunds}
    >
      <Segment whiteBackground>
        <Copy as="div">
          <Trans components={{ unordered_list: <UnorderedList />, listItem: <li /> }}>
            {t('continueOrRestart.content', 'Continue or restart content')}
          </Trans>
        </Copy>
      </Segment>
      {displayOverview && (
        <JourneyOverview
          title={t('continueOrRestart.overview.title', 'Overview title')}
          forceExpanded
          notification={{ type: 'info', title: t('continueOrRestart.overview.message', 'Overview message') }}
        />
      )}
    </PageTemplate>
  )
}
