import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import { DEFAULT_PROPS } from './constants'
import type { TImage } from './types'

export const Image = styled.img<TImage>`
  ${({ bottom = DEFAULT_PROPS.bottom, theme, desktopOnly }) => css`
    ${desktopOnly ? mediaStyles({ display: 'none' }, { max: Breakpoint.small }) : ''}

    margin-bottom: ${theme.spacing[bottom]};
  `}
`
