import { styled } from '@retire/theme/styledComponents'

export const SIconWrapper = styled.div`
  position: absolute;
  width: ${props => props.theme.forms.dropdown.iconSize};
  height: ${props => props.theme.forms.dropdown.iconSize};
  top: 50%;
  right: ${props => props.theme.forms.input.padding};
  transform: translateY(-50%);
`
