export enum Breakpoint {
  extraSmall = 'extraSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

export const breakpoints: Record<Breakpoint, number> = {
  extraSmall: 408,
  small: 596,
  medium: 784,
  large: 972,
  extraLarge: 1200,
}
