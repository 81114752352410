import { Copy } from '@retire/components'
import { styled } from '@retire/theme/styledComponents'

import type { TJourneyOverviewPotDetailsDot } from './types'

export const SJourneyOverviewPotDetailsName = styled(Copy)`
  position: relative;
`

export const SJourneyOverviewPotDetailsDot = styled.div<TJourneyOverviewPotDetailsDot>`
  position: absolute;
  border-radius: 50%;
  top: 50%;

  ${({ theme: { icons, pot, spacing }, color }) => `
    transform: translate(calc(-${spacing.small} - ${icons.size.small} + 50%), -50%);
    background-color: ${pot.potColor[color]};
    width: ${pot.colorBar.width};
    height: ${pot.colorBar.width};
  `}
`
