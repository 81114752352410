import { mergeProps } from '@react-aria/utils'
import type { FC, Ref } from 'react'
import { forwardRef } from 'react'

import { SAddressResult } from './presentation.styled'
import type { TOption } from './types'

export const Option: FC<TOption> = forwardRef(
  ({ onKeyPress, onClick, optionProps, focusProps, labelProps, rendered }, ref: Ref<HTMLLIElement>) => (
    <SAddressResult
      onKeyPress={onKeyPress}
      onClick={onClick}
      ref={ref}
      {...mergeProps(optionProps, focusProps)}
      {...labelProps}
    >
      {rendered}
    </SAddressResult>
  )
)

Option.displayName = 'Option'
