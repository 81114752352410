import { styled } from '@retire/theme/styledComponents'

export const SSwitcher = styled.div`
  *,
  *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: 0.25s ease-in-out;
    outline: none;
  }

  width: ${({ theme }) => theme.forms.switcher.width};
  text-align: center;

  input[type='checkbox'],
  input[type='checkbox']:active {
    display: none;
    height: 0;
    width: 0;
    opacity: 0;
    border: none;
    outline: none;
  }

  > label {
    display: block;
    position: relative;
    width: 100%;
    height: ${({ theme }) => theme.forms.switcher.height};
    border-radius: calc(${({ theme }) => theme.forms.switcher.height} / 2);
    background: ${({ theme }) => theme.forms.switcher.inactiveBg};
    cursor: pointer;
    border: ${({ theme }) => theme.forms.switcher.borderWidth} solid;
    border-color: ${({ theme }) => theme.colors.neutral30};

    :before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      height: calc(${({ theme }) => `${theme.forms.switcher.height} - 2 * ${theme.forms.switcher.borderWidth}`});
      width: calc(${({ theme }) => `${theme.forms.switcher.height} - 2 * ${theme.forms.switcher.borderWidth}`});
      border-radius: 100%;
      top: 0;
      left: 0;
      right: auto;
      background: white;
      box-shadow:
        0 3px 3px 0 rgba(0, 0, 0, 0.05),
        0 2px 2px 0 rgba(0, 0, 0, 0.1),
        0 3px 1px 0 rgba(0, 0, 0, 0.0511),
        0 0 3px 0 rgba(0, 0, 0, 0.05);
    }
  }

  input[type='checkbox']:checked + label {
    background: ${({ theme }) => theme.forms.switcher.activeBg};
    border-color: ${({ theme }) => theme.forms.switcher.activeBg};
  }

  input[type='checkbox']:checked + label:before {
    left: calc(${({ theme }) => `${theme.forms.switcher.width} - ${theme.forms.switcher.height}`});
    box-shadow:
      0 0 0 2px transparent,
      0 3px 3px rgba(0, 0, 0, 0.3);
  }
`
