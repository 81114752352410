import type { Instruction } from '@retire/gql/__generated__/graphql'

import { EMPTY_DETAILS } from './constants'
import type { TTransactionDetails } from './types'

export const initAllDetails = (transactions: Instruction[]): Record<string, TTransactionDetails> => {
  const allDetails = {}
  transactions.forEach(({ id }) => {
    allDetails[id] = EMPTY_DETAILS
  })
  return allDetails
}
