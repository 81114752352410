import { buttons } from './buttons'
import { colors } from './colors'

const defaultLink = {
  borderColor: 'transparent',
  textDecoration: 'underline',
}

export const links = {
  disabled: buttons.disabled,
  icon: {
    topOffset: '0.156rem',
    size: {
      normal: '1.125rem',
      large: '1.35rem',
    },
    padding: '0.5rem',
  },
  default: {
    states: {
      default: {
        ...defaultLink,
        color: colors.hyperlink,
      },
      hover: {
        ...defaultLink,
        color: colors.hyperlinkHover,
      },
      focus: {
        ...defaultLink,
        borderColor: colors.primary,
        color: colors.buttonHover,
      },
      active: {
        ...defaultLink,
        color: colors.primary,
      },
    },
  },
  dark: {
    states: {
      default: {
        color: colors.primary,
        textDecoration: defaultLink.textDecoration,
      },
      hover: {
        ...defaultLink,
        color: colors.hyperlinkHover,
      },
      focus: {
        ...defaultLink,
        borderColor: colors.primary,
        color: colors.buttonHover,
      },
      active: {
        ...defaultLink,
        color: colors.hyperlinkHover,
      },
    },
  },
  bright: {
    states: {
      default: {
        color: colors.footerLink,
        textDecoration: 'none',
      },
      hover: {
        ...defaultLink,
        color: colors.buttonHover,
      },
      focus: {
        ...defaultLink,
        borderColor: colors.footerLink,
        color: colors.footerLink,
      },
      active: {
        ...defaultLink,
        color: colors.footerLink,
      },
    },
  },
}
