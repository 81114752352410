import { useQuery } from '@apollo/client'
import { INIT_QUERY } from '@retire/gql/user/queries'
import { assignUserId, getDisplayName } from '@retire/utils'
import { useRollbarPerson } from '@rollbar/react'
import type { ComponentType } from 'react'
import { useEffect } from 'react'

export const withUserTracking = <P,>(Component: ComponentType<P>): ComponentType<P> => {
  const Wrapped: ComponentType<P> = props => {
    const { data, loading } = useQuery(INIT_QUERY)
    const userId = data?.user.policyNumber

    useEffect(() => {
      !loading && userId && assignUserId(userId)
    }, [userId, loading])

    useRollbarPerson({
      id: userId,
    })

    return <Component {...props} />
  }

  Wrapped.displayName = `WithUserTracking(${getDisplayName(Component)})`
  return Wrapped
}
