import { useProgressHeader } from '@retire/hooks/useProgressHeader'

import { getEnrolmentStepIndex, getEnrolmentTotalSteps } from '../../utils'
import type { TUseEnrolmentHeader } from './types'

export const useEnrolmentHeader: TUseEnrolmentHeader = ({ currentStep }) => {
  const currentStepIndex = getEnrolmentStepIndex(currentStep)
  const totalSteps = getEnrolmentTotalSteps()

  useProgressHeader({
    currentStep: currentStepIndex + 1,
    totalSteps: totalSteps,
    displayedTotalSteps: totalSteps - 1,
  })
}
