import { useForm } from '@retire/hooks/useForm'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { BeforeYouJoin } from '../../components/BeforeYouJoin'
import { QuestionList } from '../../components/QuestionList'
import type { TCobsQuestions } from '../../components/QuestionList/types'
import { StepWrapper } from '../../components/StepWrapper'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { cobsServices } from './services'
import { allQuestionsAnswered, needAdditionalValidation } from './utils'

export const COBS: FC = () => {
  const { t } = useTranslationWithOptions('enrolment')
  useEnrolmentHeader({ currentStep: EnrolmentStep.cobs })
  const navigate = useNavigate()
  const onSubmit = useCallback(() => navigate(paths.enrolment.declaration), [navigate])
  const { methods, handleSubmit, isLoading } = useForm<TCobsQuestions>({
    ...cobsServices,
    onSubmit,
  })
  const { watch } = methods
  const [beforeYouJoin, setBeforeYouJoin] = useState(false)
  const cobsQuestions = watch()
  const backToForm = () => setBeforeYouJoin(false)
  const onNext = useCallback(async () => {
    if (needAdditionalValidation(cobsQuestions) && !beforeYouJoin && allQuestionsAnswered(cobsQuestions)) {
      setBeforeYouJoin(true)
    } else {
      await handleSubmit()
    }
  }, [beforeYouJoin, cobsQuestions, handleSubmit])

  return (
    <FormProvider {...methods}>
      <StepWrapper
        title={
          beforeYouJoin ? t('cobs.beforeYouJoin.title', 'COBS Before you join') : t('cobs.form.title', 'COBS Questions')
        }
        subTitle={beforeYouJoin ? undefined : t('cobs.form.subTitle', 'Please, answer the following questions')}
        nextText={beforeYouJoin ? t('cobs.beforeYouJoin.actions.next', 'Next') : t('general.actions.next', 'Next')}
        backText={t('general.actions.back', 'Back')}
        loading={isLoading}
        onNext={onNext}
        onBack={beforeYouJoin ? backToForm : undefined}
        backUrl={paths.enrolment.lsaOrLsdQuestion}
      >
        <ContentWrapper>
          {beforeYouJoin ? <BeforeYouJoin /> : <QuestionList cobsQuestions={cobsQuestions} methods={methods} />}
        </ContentWrapper>
      </StepWrapper>
    </FormProvider>
  )
}
