import { styled } from '@retire/theme/styledComponents'

import { DEFAULT_PROPS } from './constants'
import type { TColumn } from './types'

export const SColumn = styled.div<TColumn>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems = DEFAULT_PROPS.alignItems }) => alignItems};
  justify-content: ${({ justifyContent = DEFAULT_PROPS.justifyContent }) => justifyContent};
`
