import type { FC } from 'react'

import { Segment } from '../Segment'
import type { TCard } from './types'

export const Card: FC<TCard> = ({ children, dataCy }) => (
  <Segment whiteBackground data-cy={dataCy}>
    {children}
  </Segment>
)
