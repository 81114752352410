import { styled } from '@retire/theme/styledComponents'

export const SListLink = styled.ul`
  margin-bottom: ${props => props.theme.spacing.extraLarge};

  & > a {
    color: inherit;
    display: list-item;
    font-size: inherit;
    margin-top: ${props => props.theme.spacing.medium};
  }
`
