import { Spacer } from '@retire/components/Spacer'
import { PotName } from '@retire/constants/pots'
import type { FC } from 'react'

import { isFlexibleIncomePotConfigured, isLaterLifePotConfigured } from '../../../utils'
import { JourneyOverviewAmount } from '../Amount'
import { JourneyOverviewDetails } from '../Details'
import { PotDetails } from '../PotDetails'
import type { TIncomePotsStepDetails } from './types'

export const IncomePotsStepDetails: FC<TIncomePotsStepDetails> = ({
  flexibleIncomeAmount,
  laterLifeAmount,
  isIncomePotsStepCompleted,
  isIncomePotsStepInProgress,
}) => {
  if (!isIncomePotsStepInProgress && !isIncomePotsStepCompleted) {
    return (
      <JourneyOverviewDetails>
        <JourneyOverviewAmount amount={0} />
      </JourneyOverviewDetails>
    )
  } else if (!isFlexibleIncomePotConfigured) {
    return null
  }

  return (
    <>
      {isFlexibleIncomePotConfigured({ flexibleIncomeAmount }) && (
        <Spacer key={PotName.flexibleIncome} top="none">
          <PotDetails potName={PotName.flexibleIncome} />
        </Spacer>
      )}
      {isLaterLifePotConfigured({ laterLifeAmount }) && (
        <Spacer key={PotName.laterLife} top="medium">
          <PotDetails potName={PotName.laterLife} />
        </Spacer>
      )}
    </>
  )
}
