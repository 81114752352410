import { Headline } from '@retire/components/Typography/Headline'
import { styled } from '@retire/theme/styledComponents'

export const SLoadingContainer = styled.div`
  display: flex;
`

export const IncomeValue = styled(Headline)`
  align-items: baseline;
`
