import { Currency } from '@retire/components/Formatter/Currency'
import { FormNumberInput } from '@retire/components/Forms/NumberInput'
import { Spacer } from '@retire/components/Spacer'
import { PotName } from '@retire/constants/pots'
import { NONNUMERIC_CHAR_REGEX } from '@retire/constants/regex'
import { AGE_MAX_LENGTH } from '@retire/constants/user'
import { useBlockOnKeyDown } from '@retire/hooks/useBlockOnKeyDown'
import { useBlockOnMaxLength } from '@retire/hooks/useBlockOnMaxLength'
import { useBlockOnPaste } from '@retire/hooks/useBlockOnPaste'
import { useLaterLifePotAgeRules } from '@retire/hooks/useLaterLifePotAgeRules'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { isRequired } from '@retire/utils'
import { toCurrency, toPounds } from '@retire/utils/currency'
import type { FC } from 'react'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { useIllustrationPlanData } from '../../../../../hooks/useIllustrationPlanData'
import { PotEstimatedMonthlyIncome } from '../PotEstimatedMonthlyIncome'
import type { TLaterLifePotConfigurationForm } from './types'

export const LaterLifePotConfigurationForm: FC<TLaterLifePotConfigurationForm> = ({ methods }) => {
  const { t } = useTranslationWithOptions('illustration')

  const {
    [PotName.laterLife]: { minAmount },
  } = useSettings()

  const {
    illustrationPlanData: { remainingSavings },
  } = useIllustrationPlanData()

  const {
    watch,
    formState: { isValid, isValidating },
  } = methods
  const amount = watch('amount')
  const startAge = watch('startAge')

  const { startAgeRules } = useLaterLifePotAgeRules()
  const amountRules = useMemo(
    () => ({
      min: {
        value: toPounds(minAmount),
        message: t('steps.potSteps.potConfiguration.validations.min', 'Amount must be {{minAmount}} or above', {
          minAmount: toCurrency(minAmount || 0),
        }),
      },
      max: {
        value: toPounds(remainingSavings),
        message: t('steps.potSteps.potConfiguration.validations.max', 'Amount must be {{maxAmount}} or below', {
          maxAmount: toCurrency(remainingSavings),
        }),
      },
      validate: {
        required: isRequired(),
      },
    }),
    [minAmount, remainingSavings, t]
  )

  const blockOnMaxLength = useBlockOnMaxLength(AGE_MAX_LENGTH)
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_CHAR_REGEX, blockOnMaxLength)
  const onPaste = useBlockOnPaste({ maxLength: AGE_MAX_LENGTH })

  return (
    <>
      <FormNumberInput
        name="amount"
        isCurrency
        label={t('steps.potSteps.laterLifePot.amount.label', 'Pot amount')}
        subLabel={
          <Trans i18nKey="steps.potSteps.laterLifePot.amount.subLabel" components={[<Currency key="minimum-value" />]}>
            {t('steps.potSteps.laterLifePot.amount.subLabel', 'Minimum amount is {{minAmount}}', {
              minAmount,
            })}
          </Trans>
        }
        rules={amountRules}
      />
      <Spacer bottom="medium" />
      <FormNumberInput
        name="startAge"
        label={t('steps.potSteps.laterLifePot.startAge.label', 'Pot start age')}
        precision={0}
        withoutThousandDelimiter
        required
        rules={startAgeRules}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
      />
      <Spacer bottom="medium" />
      {!!amount && isValid && !isValidating && <PotEstimatedMonthlyIncome amount={amount} startAge={startAge} />}
    </>
  )
}
