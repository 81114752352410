import { Copy, Divider, FormNumberInput, Headline, NavButtons, Spacer } from '@retire/components'
import { PotName } from '@retire/constants/pots'
import { NONNUMERIC_DECIMAL_CHAR_REGEX } from '@retire/constants/regex'
import { LATER_LIFE_AGE_PROJECTION_QUERY } from '@retire/gql/projections/queries'
import { useBlockOnKeyDown } from '@retire/hooks/useBlockOnKeyDown'
import { useBlockOnMaxLength } from '@retire/hooks/useBlockOnMaxLength'
import { useBlockOnPaste } from '@retire/hooks/useBlockOnPaste'
import { useFormDefaultValues } from '@retire/hooks/useFormDefaultValues'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { HeadlineLevel } from '@retire/theme'
import { toPennies, toPounds } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useFlexibleIncomePotMonthlyAmountValidationRules } from '../../../hooks/useFlexibleIncomePotMonthlyAmountValidationRules'
import { usePotsContext } from '../../../hooks/usePotsContext'
import type { TFlexibleIncomePotManageIncomeReviewState } from '../types'
import type { TFlexibleIncomePotAmountFormFields } from './types'

export const FlexibleIncomePotAmount: FC = () => {
  const { t } = useTranslationWithOptions('dashboard')
  const navigate = useNavigate()

  const {
    pots: {
      [PotName.flexibleIncome]: {
        allocatedAmount: flexibleIncomePotAmount = 0,
        customMonthlyIncome: currentMonthlyIncomeAmount = 0,
      },
    },
  } = usePotsContext()
  const {
    data: { currentAge, regularIncomeEndAge, regularIncomeStartAge },
    loading: loadingUserDetails,
  } = useUserDetails()
  const currentStartAge = currentAge > regularIncomeStartAge ? currentAge : regularIncomeStartAge

  const formattedCurrentMonthlyIncomeAmount = useMemo(
    () => toPounds(currentMonthlyIncomeAmount),
    [currentMonthlyIncomeAmount]
  )

  const [defaultValues, setDefaultValues] = useFormDefaultValues<TFlexibleIncomePotAmountFormFields>({
    initialValues: { amount: String(formattedCurrentMonthlyIncomeAmount) },
  })

  const methods = useForm<TFlexibleIncomePotAmountFormFields>({
    mode: 'all',
    defaultValues,
  })

  const {
    formState: { isValid },
    handleSubmit,
    watch,
  } = methods

  const { amount: monthlyAmount } = watch()
  const { data, loading } = useQueryWithError(LATER_LIFE_AGE_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotAmount,
      flexibleIncomePotCustomMonthlyAmount: toPennies(monthlyAmount),
      flexibleIncomePotStartAge: currentStartAge,
      flexibleIncomePotEndAge: regularIncomeEndAge,
    },
    skip: !isValid,
  })
  const projectedLaterLifeAge = data?.projections?.laterLifeAge?.projectedLaterLifeAge

  const onContinue = handleSubmit(() => {
    const reviewTableData: TFlexibleIncomePotManageIncomeReviewState = {
      startAge: {
        current: currentStartAge,
        new: currentStartAge,
      },
      endAge: {
        current: regularIncomeEndAge,
        new: projectedLaterLifeAge || regularIncomeEndAge,
      },
      monthlyIncome: {
        current: currentMonthlyIncomeAmount,
        new: toPennies(monthlyAmount),
      },
    }
    setDefaultValues({ amount: monthlyAmount })

    navigate(paths.dashboard.flexibleIncomePotManageIncomeAmountReview, {
      state: reviewTableData,
    })
  })

  const onQuit = useCallback(() => {
    navigate(-2)
  }, [navigate])

  const onBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const blockOnMaxLength = useBlockOnMaxLength(9)
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_DECIMAL_CHAR_REGEX, blockOnMaxLength)
  const onPaste = useBlockOnPaste({ maxLength: 9 })

  const { rules, loadingRules } = useFlexibleIncomePotMonthlyAmountValidationRules({
    flexibleIncomePotAmount,
    flexibleIncomePotStartAge: currentStartAge,
  })

  return (
    <FormProvider {...methods}>
      <PageTemplate
        fullWidth
        title={t('pots.common.manageIncome.amountTitle', 'Amount')}
        onQuit={onQuit}
        loading={loadingUserDetails || loadingRules}
      >
        <Copy as="p">{t('pots.common.manageIncome.monthlyAmount', 'Monthly amount (before tax)')}</Copy>
        <Spacer top="medium" />
        <FormNumberInput
          name="amount"
          withoutThousandDelimiter
          inputWidth="250px"
          width="250px"
          isCurrency
          autoFocus
          rules={rules}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
        />
        <Spacer top="medium" />
        <Divider bottom="large" />
        {isValid && (
          <>
            <Copy as="p">{t('pots.common.manageIncome.startEndAge', 'Start and end age')}</Copy>
            <Headline level={HeadlineLevel.h3} as="h3">
              {t('pots.common.manageIncome.startEndAgeRange', '{{startAge}} {{endAge}}', {
                startAge: currentStartAge,
                endAge: projectedLaterLifeAge || '',
              })}
            </Headline>
            <Spacer bottom="mediumSmall" />
            <Divider bottom="large" />
          </>
        )}
        <NavButtons
          onNext={onContinue}
          onBack={onBack}
          disableNext={loading || !isValid}
          nextText={t('pots.common.manageIncome.actions.continue', 'Continue')}
          backText={t('pots.common.manageIncome.actions.back', 'Back')}
        />
      </PageTemplate>
    </FormProvider>
  )
}
