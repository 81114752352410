import { DateFormat } from '@retire/constants'
import type { InitQuery } from '@retire/gql/__generated__/graphql'
import { INIT_QUERY } from '@retire/gql/user/queries'
import { formatDate, guessCurrentAge } from '@retire/utils'

import { useQuerySelector } from '../useQuerySelector'
import { DEFAULT_USER_DETAILS } from './constants'
import type { TUserDetails } from './types'

export const useUserDetails = () =>
  useQuerySelector<InitQuery, TUserDetails>(
    { query: INIT_QUERY },
    ({
      user: {
        forename,
        surname,
        email,
        unconfirmedEmail,
        bornOn,
        laterLifeAge,
        state,
        regularIncomeAmount,
        regularIncomeEndAge,
        regularIncomeStartAge,
        regularIncomePaused,
      },
      illustrationPlan,
    }) => ({
      forename,
      surname,
      email,
      unconfirmedEmail,
      laterLifeAge,
      bornOn: formatDate(bornOn, DateFormat.date),
      state,
      currentAge: guessCurrentAge(bornOn),
      valuationAmount: illustrationPlan?.valuationAmount,
      regularIncomeAmount,
      regularIncomeEndAge,
      regularIncomeStartAge,
      regularIncomePaused,
    }),
    DEFAULT_USER_DETAILS
  )
