import { styled } from '@retire/theme/styledComponents'

import type { TSLabel } from './types'

export const SOption = styled.div`
  position: relative;
  margin-bottom: ${props => props.theme.forms.checkboxradio.spacing};
`

export const SInput = styled.input`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);

  label {
    border-style: ${({ theme }) => theme.forms.shared.border.style};
    border-width: ${({ theme }) => theme.forms.shared.border.width};
  }

  &:checked + label {
    border-color: ${({ theme }) => theme.forms.checkboxradio.states.selected.borderColor};
    background-color: ${({ theme }) => theme.forms.checkboxradio.states.selected.backgroundColor};
  }
`

export const SLabel = styled.label<TSLabel>`
  white-space: pre-wrap;
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: flex-start;

  ${({ theme: { forms, spacing }, selected, disabled }) => `
    border-color: ${selected ? forms.shared.states.active.borderColor : forms.shared.states.default.borderColor};
    border-radius: ${forms.shared.border.radius};
    border-style: ${forms.shared.border.style};
    border-width: ${forms.shared.border.width};
    padding: ${spacing.medium};
    
    font-size: ${forms.label.fontSize};
    font-weight: ${forms.label.fontWeight};
    line-height: ${forms.label.lineHeight};
    background-color: ${
      selected ? forms.dropdown.option.states.active.backgroundColor : forms.shared.states.default.backgroundColor
    };
    color: ${forms.shared.states.default.color};

    ${
      disabled
        ? `
          border-color: ${forms.checkboxradio.states.disabled.borderColor};
          span, p {
            color: ${forms.shared.states.disabled.color};
          }
        `
        : `
          &:hover {
            border-color: ${forms.shared.states.hover.borderColor};
          }
    
          &:focus {
            border-color: ${forms.shared.states.focus.borderColor};
          }
        `
    }
  `}
`
