import { setConfiguration } from 'react-grid-system'

setConfiguration({
  breakpoints: [408, 596, 784, 972, 1200],
  // containerWidths: [564, 752, 950, 1168], // based on minus 32px but not sure if needed
  // breakpoints: [576, 768, 992, 1200], // original
  // containerWidths: [540, 750, 960, 1140], // original
  gutterWidth: 16,
  maxScreenClass: 'xl',
})
