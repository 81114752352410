import type { TContactDetails } from '@retire/gql/user/types'
import { useForm, useLocationAlerts, useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContactDetails } from './presentation'
import { contactDetailsServices } from './services'

export const ContactDetailsContainer: FC = () => {
  const { t } = useTranslationWithOptions('common')

  const { navigateWithAlerts } = useLocationAlerts()
  const navigate = useNavigate()

  const onSubmit = useCallback(() => {
    navigateWithAlerts(paths.account.manage, [
      {
        id: 'updated-contact-details',
        title: t('account.contactDetails.notification', 'Contact details updated'),
        type: 'success',
      },
    ])
  }, [navigateWithAlerts, t])

  const onBack = useCallback(() => navigate(paths.account.manage), [navigate])

  const { methods, handleSubmit } = useForm<TContactDetails>({
    ...contactDetailsServices,
    onSubmit,
  })

  return <ContactDetails onBack={onBack} methods={methods} handleSubmit={handleSubmit} />
}
