import { FormSortCode, FormTextInput, Notification, Spacer } from '@retire/components'
import { SPECIAL_NUMERIC_CHAR_REGEX, SPECIAL_NUMERIC_CHARS_REGEX } from '@retire/constants'
import type { TIsCheckAndConfirmOrigin } from '@retire/features-shared/Account'
import { useBlockOnKeyDown, useBlockOnPaste, useForm, useTranslationWithOptions } from '@retire/hooks'
import { paths } from '@retire/navigation/paths'
import { isOfLength, isRequired } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { StepWrapper } from '../../components'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import {
  BankDetail,
  MAX_ACCOUNT_NUMBER_LENGTH,
  MAX_BANK_REFERENCE_NUMBER_LENGTH,
  MIN_ACCOUNT_NUMBER_LENGTH,
  MIN_BANK_REFERENCE_NUMBER_LENGTH,
} from './constants'
import { bankDetailsServices } from './services'
import type { TBankDetailsFormFields } from './types'
import { preSubmitData } from './utils'

export const BankOrBuildingSociety: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.bankOrBuildingSociety })
  const { t } = useTranslationWithOptions('enrolment')
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  const isCheckAndConfirmOrigin = useMemo(
    () => !!(locationState as TIsCheckAndConfirmOrigin)?.isCheckAndConfirmOrigin,
    [locationState]
  )

  const onSubmit = useCallback(() => {
    navigate(isCheckAndConfirmOrigin ? paths.enrolment.checkAndConfirm : paths.enrolment.moneyLeftOver)
  }, [navigate, isCheckAndConfirmOrigin])

  const { methods, handleSubmit, isLoading } = useForm<TBankDetailsFormFields>({
    ...bankDetailsServices,
    onSubmit,
    customSaveErrorTitle: 'Saving payment details error',
    preSubmitData,
  })

  const onKeyDownAccountNumber = useBlockOnKeyDown(SPECIAL_NUMERIC_CHARS_REGEX)
  const onPasteAccountNumber = useBlockOnPaste({ preventCriteria: SPECIAL_NUMERIC_CHAR_REGEX })
  const onChange = useCallback(value => methods.setValue(BankDetail.sortCode, value), [methods])

  return (
    <FormProvider {...methods}>
      <StepWrapper
        title={t('bankOrBuildingSociety.title', 'Bank or building society')}
        subTitle={t('bankOrBuildingSociety.subTitle', 'Please, indicate your bank or building society details')}
        nextText={t('general.actions.next', 'Next')}
        backText={t('general.actions.back', 'Back')}
        loading={isLoading}
        onNext={handleSubmit}
        backUrl={paths.enrolment.personalDetails}
      >
        <FormTextInput
          name={BankDetail.accountName}
          rules={{
            validate: {
              required: isRequired(
                t('bankOrBuildingSociety.form.validations.holderRequired', 'Account holder is required')
              ),
            },
          }}
          label={t('bankOrBuildingSociety.form.fields.accountHolder', 'Account holder')}
          autocomplete="name"
          required
        />
        <Spacer bottom="large" />

        <Notification
          title={t('bankOrBuildingSociety.form.notifications.accountHolder', 'Account holder info')}
          type="info"
        />

        <FormTextInput
          name={BankDetail.accountNumber}
          type="number"
          onKeyDown={onKeyDownAccountNumber}
          onPaste={onPasteAccountNumber}
          min="0"
          rules={{
            pattern: {
              value: /^\d+$/g,
              message: t('bankOrBuildingSociety.form.validations.numberFormat', 'Wrong account number format'),
            },
            validate: {
              required: isRequired(
                t('bankOrBuildingSociety.form.validations.numberRequired', 'Account number is required')
              ),
              length: isOfLength({
                min: MIN_ACCOUNT_NUMBER_LENGTH,
                max: MAX_ACCOUNT_NUMBER_LENGTH,
                message: t('bankOrBuildingSociety.form.validations.numberLength', 'Incorrect account number length', {
                  min: MIN_ACCOUNT_NUMBER_LENGTH,
                  max: MAX_ACCOUNT_NUMBER_LENGTH,
                }),
              }),
            },
          }}
          label={t('bankOrBuildingSociety.form.fields.accountNumber.label', 'Account number')}
          subLabel={t('bankOrBuildingSociety.form.fields.accountNumber.subLabel', 'Account number sublabel')}
          required
        />
        <Spacer bottom="large" />

        <FormSortCode
          name={BankDetail.sortCode}
          onChange={onChange}
          label={t('bankOrBuildingSociety.form.fields.sortCode.label', 'Sort code')}
          subLabel={t('bankOrBuildingSociety.form.fields.sortCode.subLabel', 'Sort code sublabel')}
          rules={{
            pattern: {
              value: /^\d{2}-\d{2}-\d{2}$/g,
              message: t('bankOrBuildingSociety.form.validations.sortCodeFormat', 'Invalid sort code format'),
            },
            validate: {
              required: isRequired(
                t('bankOrBuildingSociety.form.validations.sortCodeRequired', 'Sort code is required')
              ),
            },
          }}
          required
        />
        <Spacer bottom="large" />

        <FormTextInput
          name={BankDetail.bankReference}
          rules={{
            pattern: {
              value: /^[\d .A-Za-z-]+$/g,
              message: t('bankOrBuildingSociety.form.validations.bankReferenceFormat', 'Invalid bank reference format'),
            },
            validate: {
              length: isOfLength({
                min: MIN_BANK_REFERENCE_NUMBER_LENGTH,
                max: MAX_BANK_REFERENCE_NUMBER_LENGTH,
                message: t(
                  'bankOrBuildingSociety.form.validations.bankReferenceLength',
                  'Incorrect bank reference length',
                  {
                    min: MIN_BANK_REFERENCE_NUMBER_LENGTH,
                    max: MAX_BANK_REFERENCE_NUMBER_LENGTH,
                  }
                ),
              }),
            },
          }}
          label={t('bankOrBuildingSociety.form.fields.bankReference.label', 'Bank reference')}
          subLabel={t('bankOrBuildingSociety.form.fields.bankReference.subLabel', 'Bank reference sublabel')}
        />
      </StepWrapper>
    </FormProvider>
  )
}
