import { useLazyQuery } from '@apollo/client'
import type { Instruction, PublicInvestmentType } from '@retire/gql/__generated__/graphql'
import { useTranslationWithOptions } from '@retire/hooks'
import { parseStringToDecimalWithDelimiters, sentenceCase } from '@retire/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { INSTRUCTION_DETAILS_QUERY } from '../../gql'
import { INSTRUCTION_TYPES } from './constants'
import type { TTransactionDetails, TUseTransactionDetails } from './types'
import { initAllDetails } from './utils'

export const useTransactionDetails = (transactions: Instruction[]): TUseTransactionDetails => {
  const { t, tWithoutDefaultString } = useTranslationWithOptions('dashboard')
  const transactionsIds = useMemo(() => transactions.map(({ id }) => Number(id)), [transactions])
  const [allDetails, setAllDetails] = useState<Record<string, TTransactionDetails>>(() => initAllDetails(transactions))
  const [detailsVisibility, setDetailsVisibility] = useState<Record<string, boolean>>({})
  const [getTransactionDetails, { error, data }] = useLazyQuery(INSTRUCTION_DETAILS_QUERY)

  useEffect(() => {
    if (!data || error) return

    const newAllDetailsEntries = data.instructionDetails.map(({ id, investments }) => {
      const detailsData: Partial<Record<PublicInvestmentType, string>> = Object.fromEntries(
        investments.map(({ type, fundUnit, fundPrice, ...investment }) => {
          return [
            type,
            t(
              'transactionList.tables.cells.details.description',
              '{{type}}: {{fundName}} / unit {{fundUnit}} / price {{fundPrice}}',
              {
                type: tWithoutDefaultString(`transactionList.tables.cells.details.types.${type}`, sentenceCase(type)),
                fundUnit: parseStringToDecimalWithDelimiters(String(fundUnit)),
                fundPrice: parseStringToDecimalWithDelimiters(String(fundPrice)),
                ...investment,
              }
            ),
          ]
        })
      )

      const instructionType = transactions.find(
        ({ id: transactionId, instructionType: type }) => id === transactionId && INSTRUCTION_TYPES.includes(type)
      )?.instructionType

      if (instructionType) {
        detailsData.buy =
          t('transactionList.tables.cells.details.instructionTypeLists', `instructionType ${instructionType}`, {
            instructionType: tWithoutDefaultString(
              `transactionList.tables.cells.details.instructionType.${instructionType}`,
              instructionType
            ),
          }) + (detailsData.buy || '')
      }

      return [
        id,
        {
          data: detailsData,
          transactionId: String(id),
        },
      ]
    })
    const newAllDetails = Object.fromEntries(newAllDetailsEntries)

    setAllDetails(prevState => ({ ...prevState, ...newAllDetails }))
  }, [data, error, t, tWithoutDefaultString, transactions, transactionsIds])

  useEffect(() => {
    setDetailsVisibility(prevState => {
      const newDetailsVisibilityEntries = transactionsIds.map(id => [id, prevState[id] ?? true])
      return Object.fromEntries(newDetailsVisibilityEntries)
    })
  }, [allDetails, transactionsIds])

  const onToggle = useCallback(
    async (transactionId: string) => {
      await getTransactionDetails({ variables: { instructionIds: [transactionId] } })
      setDetailsVisibility(prevState => ({
        ...prevState,
        [transactionId]: !prevState[transactionId],
      }))
    },
    [getTransactionDetails]
  )

  return {
    allDetails,
    onToggle,
    detailsVisibility,
  }
}
