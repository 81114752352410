import { graphql } from '../__generated__/gql'

export const FEATURE_FLAGS_QUERY = graphql(`
  query getFeatureFlags {
    featureFlags {
      name
      enabled
    }
  }
`)
