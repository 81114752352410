import { StyleWeight, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

import { Notification } from '../Notification'
import { Spacer } from '../Spacer'
import { Copy } from '../Typography'
import type { TLabelValue } from './types'

export const LabelValue: FC<TLabelValue> = ({ label, value, description, note }) => (
  <Spacer bottom="medium">
    <Row>
      <Col md={4}>
        <Copy as="span" weight={StyleWeight.bold}>
          {label}
        </Copy>
      </Col>
      <Col md={8}>
        <Row>
          <Col>
            <Copy as="span" weight={StyleWeight.bold} color={TypographyColor.primary}>
              {value}
            </Copy>
          </Col>
        </Row>
        {!!description && (
          <Row>
            <Col>
              <Copy as="span">{description}</Copy>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
    {!!note && (
      <Row>
        <Col xl={12}>
          <Spacer top="medium">
            <Notification type="info" title={note} />
          </Spacer>
        </Col>
      </Row>
    )}
  </Spacer>
)
