import { colors } from './colors'
import { spacing } from './spacing'

export const header = {
  backgroundColor: colors.white,
  borderColor: colors.neutral30,
  paddingHorizontalMedium: spacing.large,
  paddingHorizontalMobile: spacing.medium,
  paddingTop: spacing.large,
  paddingTopMobile: spacing.medium,
  logo: {
    marginRight: spacing.medium,
    height: '30px',
  },
}
