import { NONNUMERIC_CHAR_REGEX } from '@retire/constants'
import { useBlockOnKeyDown, useFocusPreviousInputOnBackspace } from '@retire/hooks'
import { absoluteDifference, isMoreThan } from '@retire/utils'
import type { ChangeEvent, RefObject } from 'react'
import { createRef, useCallback, useMemo, useRef } from 'react'

import { DEFAULT_SORT_CODE } from './constants'
import type { TUseSortCode } from './types'

export type { TUseSortCode }

const useGetNextRef = (refs: RefObject<HTMLInputElement>[]) =>
  useCallback(
    (index: number) => {
      const refIndex = index + 1

      if (refIndex > refs.length - 1) {
        return undefined
      }

      return refs[refIndex]
    },
    [refs]
  )

export const useSortCode = (name: string, sortCode?: string, onChange?: (s: string) => void): TUseSortCode => {
  const sortCodeSegs: string[] = useMemo(() => (sortCode || DEFAULT_SORT_CODE).split('-'), [sortCode])
  const refs = useRef(sortCodeSegs.map(() => createRef<HTMLInputElement>()))
  const getNextRef = useGetNextRef(refs.current)

  const inputProps = useMemo(() => {
    return sortCodeSegs.map((segmentValue: string, index: number) => {
      const onChangeSegment = ({ target: { min, max, maxLength, value } }: ChangeEvent<HTMLInputElement>) => {
        const valueIsMoreThanMaxAllowed = !!max && isMoreThan(value, max)
        const valueIsLessThanMinAllowed = !!min && isMoreThan(min, value)

        if (valueIsLessThanMinAllowed || valueIsMoreThanMaxAllowed) {
          return
        }

        const newPart = value.slice(0, maxLength)
        const updatedSegments = [...sortCodeSegs]

        updatedSegments.splice(index, 1, newPart)
        onChange && onChange(updatedSegments.join('-'))

        if (newPart.length === maxLength && absoluteDifference(segmentValue, newPart) !== 1) {
          const toFocusOn = getNextRef(index)

          toFocusOn?.current?.focus()
        }
      }

      return {
        onChange: onChange && onChangeSegment,
        value: sortCode && segmentValue,
        ref: refs.current[index],
        name: `${name}-seg${index}`,
      }
    })
  }, [getNextRef, name, onChange, sortCode, sortCodeSegs])

  const focusHandler = useFocusPreviousInputOnBackspace(refs.current)
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_CHAR_REGEX, focusHandler)

  return { inputProps, onKeyDown }
}
