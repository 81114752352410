import { styled } from '@retire/theme/styledComponents'

import { CommonHeaderStyle } from '../common.styled'

export const SHeader = styled.header`
  background-color: ${props => props.theme.header.backgroundColor};
  padding: ${props => props.theme.header.paddingTop} 0;
  border-bottom: 1px solid ${props => props.theme.header.borderColor};
  ${CommonHeaderStyle};
`

export const SWrapper = styled.div`
  display: flex;
`

export const SLeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const SRightSection = styled.nav`
  display: flex;
  align-items: center;
`
