import type { TLink } from '@retire/components'
import { LinkWithIcon, Spacer } from '@retire/components'
import { LinkVariant } from '@retire/constants'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { Col, Row, Visible } from 'react-grid-system'

export const MiddleFooterLinks: FC = () => {
  const { t } = useTranslationWithOptions('common')
  const WindowNextLink: FC<Pick<TLink, 'href'>> = ({ children, href }) => (
    <LinkWithIcon
      title={String(children)}
      as="a"
      href={href}
      iconType="External"
      position="right"
      variant={LinkVariant.bright}
      target="_blank"
    >
      <Spacer right="small" inline>
        {children}
      </Spacer>
    </LinkWithIcon>
  )

  return (
    <Row>
      <Col md={4}>
        <WindowNextLink href={t('externalLinks.pensionWise.main', 'PensionWise')}>
          {t('footer.help.pensionWise.title', 'Pension Wise link')}
        </WindowNextLink>
        <Visible xs sm>
          <Spacer bottom="mediumSmall" />
        </Visible>
      </Col>
      <Col md={4}>
        <WindowNextLink href={t('footer.help.about.url', 'About')}>
          {t('footer.help.about.title', 'About link')}
        </WindowNextLink>
      </Col>
    </Row>
  )
}
