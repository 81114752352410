import { PotName } from '@retire/constants/pots'
import { UserState } from '@retire/gql/__generated__/graphql'
import { API_URL } from '@retire/utils/api'

import type { TPaths, TPotPaths, TRoots, TStatePaths } from './types'

export enum PathParam {
  pot = 'pot',
  message = 'messageId',
  token = 'token',
}

export const mainRootPath = '/'

export const roots: TRoots = {
  account: '/account',
  suitabilityCheck: '/check-suitability',
  illustration: '/illustration',
  enrolment: '/enrolment',
  dashboard: '/dashboard',
  inbox: '/inbox',
}

export const potPaths: TPotPaths = {
  [PotName.flexibleIncome]: 'flexible-income',
  [PotName.laterLife]: 'later-life',
  [PotName.rainyDay]: 'rainy-day',
  [PotName.inheritance]: 'inheritance',
}
export const { account, suitabilityCheck, illustration, enrolment, dashboard, inbox } = roots

export const paths: TPaths = {
  authentication: {
    signIn: `${API_URL}/auth/smart_pension`,
    signOut: `${API_URL}/sign-out`,
  },

  account: {
    closed: `${account}/closed`,
    contactSupport: `${account}/contact-support`,
    manage: `${account}/manage`,
    manageContactDetails: `${account}/manage/contact-details`,
    manageBeneficiaries: `${account}/manage/beneficiaries`,
    deniedAuthentication: `${account}/auth-denied`,
  },

  suitabilityCheck: {
    check: suitabilityCheck,
    confirm: `${suitabilityCheck}/confirm`,
  },

  illustration: {
    journeyIntro: `${illustration}/journey/intro`,
    journey: `${illustration}/journey`,
    journeyContinueOrRestart: `${illustration}/journey/continue-or-restart`,
    journeyTaxFreeLumpSumWithdrawal: `${illustration}/journey/tfls`,
    journeyPotsIntro: `${illustration}/journey/pots-intro`,
    journeyIncomePotsIntro: `${illustration}/journey/income-pots`,
    journeyFlexibleIncomePotInsufficientSavings: `${illustration}/journey/income-pots/${potPaths.flexibleIncomePot}/insufficient-savings`,
    journeyFlexibleIncomePotIntro: `${illustration}/journey/income-pots/${potPaths.flexibleIncomePot}/intro`,
    journeyFlexibleIncomePotConfiguration: `${illustration}/journey/income-pots/${potPaths.flexibleIncomePot}/configure`,
    journeyLaterLifePotInsufficientSavings: `${illustration}/journey/income-pots/${potPaths.laterLifePot}/insufficient-savings`,
    journeyLaterLifePotIntro: `${illustration}/journey/income-pots/${potPaths.laterLifePot}/intro`,
    journeyLaterLifePotConfiguration: `${illustration}/journey/income-pots/${potPaths.laterLifePot}/configure`,
    journeySavingsPotsIntro: `${illustration}/journey/savings-pots`,
    journeyInheritancePotInsufficientSavings: `${illustration}/journey/savings-pots/${potPaths.inheritancePot}/insufficient-savings`,
    journeyInheritancePotIntro: `${illustration}/journey/savings-pots/${potPaths.inheritancePot}/intro`,
    journeyInheritancePotConfiguration: `${illustration}/journey/savings-pots/${potPaths.inheritancePot}/configure`,
    journeyInheritancePotFunds: `${illustration}/journey/savings-pots/${potPaths.inheritancePot}/funds`,
    journeyRainyDayPotInsufficientSavings: `${illustration}/journey/savings-pots/${potPaths.rainyDayPot}/insufficient-savings`,
    journeyRainyDayPotIntro: `${illustration}/journey/savings-pots/${potPaths.rainyDayPot}/intro`,
    journeyRainyDayPotConfiguration: `${illustration}/journey/savings-pots/${potPaths.rainyDayPot}/configure`,
    journeyReady: `${illustration}/journey/ready`,
    yourIllustration: `${illustration}/your-illustration`,
    yourPlanInFull: `${illustration}/your-plan-in-full`,
  },

  enrolment: {
    introduction: `${enrolment}/introduction`,
    welcomeBack: `${enrolment}/welcome-back`,
    emailCheck: `${enrolment}/email-check`,
    emailChanging: `${enrolment}/change-email`,
    emailChanged: `${enrolment}/check-your-email`,
    emailResending: `${enrolment}/resending-email`,
    emailResent: `${enrolment}/email-sent`,
    personalDetails: `${enrolment}/personal-details`,
    bankOrBuildingSociety: `${enrolment}/bank-or-building-society`,
    moneyLeftOver: `${enrolment}/money-left-over`,
    checkAndConfirm: `${enrolment}/check-and-confirm`,
    lsaOrLsdQuestion: `${enrolment}/lump-sum-allowance-and-lump-sum-and-death-benefit-allowance`,
    cobs: `${enrolment}/cobs-questions`,
    declaration: `${enrolment}/declaration`,
    thankYou: `${enrolment}/thank-you`,
    awaitingApproval: `${enrolment}/awaiting-approval`,
  },

  dashboard: {
    board: dashboard,
    moveMoneyBetweenPotsHowItWorks: `${dashboard}/move-money/how-it-works`,
    moveMoneyBetweenPots: `${dashboard}/move-money`,
    confirmMoveMoneyRequest: `${dashboard}/move-money/confirm`,
    transactionList: `${dashboard}/transactions`,
    cancelTransaction: `${dashboard}/transactions/cancel`,
    pauseFlexibleIncomePot: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}/pause`,
    restartFlexibleIncomePot: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}/restart`,
    flexibleIncomePotDetails: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}`,
    flexibleIncomePotManageIncome: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}/manage-income`,
    flexibleIncomePotManageIncomeAmount: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}/manage-income/amount/configure`,
    flexibleIncomePotManageIncomeAmountReview: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}/manage-income/amount/review`,
    flexibleIncomePotManageIncomePeriod: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}/manage-income/period/configure`,
    flexibleIncomePotManageIncomePeriodReview: `${dashboard}/pot-details/${potPaths.flexibleIncomePot}/manage-income/period/review`,
    laterLifePotDetails: `${dashboard}/pot-details/${potPaths.laterLifePot}`,
    laterLifePotManageIncomePeriod: `${dashboard}/pot-details/${potPaths.laterLifePot}/manage-income/period/configure`,
    laterLifePotManageIncomePeriodReview: `${dashboard}/pot-details/${potPaths.laterLifePot}/manage-income/period/review`,
    inheritancePotDetails: `${dashboard}/pot-details/${potPaths.inheritancePot}`,
    inheritancePotFunds: `${dashboard}/pot-details/${potPaths.inheritancePot}/funds/configure`,
    inheritancePotFundsReview: `${dashboard}/pot-details/${potPaths.inheritancePot}/funds/review`,
    rainyDayPotDetails: `${dashboard}/pot-details/${potPaths.rainyDayPot}`,
  },

  inbox: {
    messages: inbox,
    message: `${inbox}/:${PathParam.message}`,
  },

  hygienePages: {
    serverError: '/500',
    continueApplication: '/continue-application',
    nonEligible: '/non-eligible',
  },

  other: {
    confirmEmail: `/confirm-email/:${PathParam.token}`,
    emailVerified: '/email-verified',
    emailVerificationError: '/unable-to-verify-email',
  },
}

const illustrationPaths = [
  // specials
  paths.illustration.journey,
  paths.illustration.journeyIntro,
  paths.illustration.journeyContinueOrRestart,
  // steps 1
  paths.illustration.journeyTaxFreeLumpSumWithdrawal,
  paths.illustration.journeyPotsIntro,
  // steps 2
  paths.illustration.journeyIncomePotsIntro,
  paths.illustration.journeyFlexibleIncomePotInsufficientSavings,
  paths.illustration.journeyFlexibleIncomePotIntro,
  paths.illustration.journeyFlexibleIncomePotConfiguration,
  paths.illustration.journeyLaterLifePotInsufficientSavings,
  paths.illustration.journeyLaterLifePotIntro,
  paths.illustration.journeyLaterLifePotConfiguration,
  // steps 3
  paths.illustration.journeySavingsPotsIntro,
  paths.illustration.journeyInheritancePotInsufficientSavings,
  paths.illustration.journeyInheritancePotIntro,
  paths.illustration.journeyInheritancePotConfiguration,
  paths.illustration.journeyInheritancePotFunds,
  paths.illustration.journeyRainyDayPotInsufficientSavings,
  paths.illustration.journeyRainyDayPotIntro,
  paths.illustration.journeyRainyDayPotConfiguration,
  // final
  paths.illustration.journeyReady,
]

const enrolmentPaths = [
  paths.enrolment.emailCheck,
  paths.enrolment.emailChanging,
  paths.enrolment.emailChanged,
  paths.enrolment.personalDetails,
  paths.enrolment.bankOrBuildingSociety,
  paths.enrolment.moneyLeftOver,
  paths.enrolment.checkAndConfirm,
  paths.enrolment.lsaOrLsdQuestion,
  paths.enrolment.cobs,
  paths.enrolment.declaration,
  paths.enrolment.thankYou,
]

const commonPaths = [paths.hygienePages.continueApplication]

export const statePaths: TStatePaths = {
  [UserState.SuitabilityCheck]: [paths.suitabilityCheck.check, paths.suitabilityCheck.confirm, ...commonPaths],
  [UserState.IllustrationCreation]: [...illustrationPaths, ...commonPaths],
  [UserState.InitialIllustrationManagement]: [paths.illustration.yourIllustration, ...commonPaths],
  [UserState.IllustrationManagement]: [paths.illustration.yourIllustration, ...commonPaths],
  [UserState.InitialNewPlanCreation]: [paths.illustration.yourPlanInFull, ...commonPaths],
  [UserState.NewPlanCreation]: [paths.illustration.yourPlanInFull, ...commonPaths],
  [UserState.InitialEnrolment]: [paths.enrolment.introduction, ...enrolmentPaths, ...commonPaths],
  [UserState.AwaitingEmailConfirmation]: [
    paths.enrolment.welcomeBack,
    paths.enrolment.emailChanged,
    paths.enrolment.emailChanging,
    paths.enrolment.emailResending,
    paths.enrolment.emailResent,
    ...commonPaths,
  ],
  [UserState.Enrolment]: [paths.enrolment.welcomeBack, ...enrolmentPaths, ...commonPaths],
  [UserState.AwaitingApproval]: [
    paths.enrolment.awaitingApproval,
    paths.enrolment.thankYou,
    paths.enrolment.declaration,
    ...commonPaths,
  ],
  [UserState.AccountLive]: [
    ...Object.values(paths.dashboard),
    ...Object.values(paths.inbox),
    ...Object.values(paths.account),
    ...commonPaths,
  ],
  [UserState.ApplicationClosed]: [paths.account.closed, ...commonPaths],
  [UserState.AccountClosed]: [paths.account.closed, ...commonPaths],
}
