import { useCallback, useState } from 'react'

import type { TPageBarColorContext } from '../../context/pageBarColor/types'

export const usePageBarColor = (): TPageBarColorContext => {
  const [color, setColor] = useState('')

  const setNewColor = useCallback((newColor: string) => {
    setColor(newColor)
  }, [])

  return {
    color,
    setColor: setNewColor,
  }
}
