import { useFocusRing } from '@react-aria/focus'
import { useOption } from '@react-aria/listbox'
import type { FC } from 'react'
import { useRef } from 'react'

import { Option } from './presentation'
import type { TOptionContainer } from './types'

export const OptionContainer: FC<TOptionContainer> = ({ item: { key, rendered }, state, onKeyPress, onClick }) => {
  const ref = useRef<HTMLLIElement>(null)
  const { optionProps, labelProps } = useOption({ key }, state, ref)
  const { focusProps } = useFocusRing()

  return (
    <Option
      ref={ref}
      optionProps={optionProps}
      labelProps={labelProps}
      focusProps={focusProps}
      rendered={rendered}
      onKeyPress={onKeyPress}
      onClick={onClick}
    />
  )
}
