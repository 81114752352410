import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Success: FC<TIconColors> = props => {
  const {
    colors: { success, white },
  } = useThemeContext()
  const { base = success } = props

  return (
    <svg x="0px" y="0px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Success" />
      <g>
        <defs>
          <rect x="-549.8" y="-2790" width="1440" height="4347" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
      </g>
      <g>
        <defs>
          <rect x="-549.8" y="-2790" width="1440" height="4347" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
      </g>
      <g>
        <defs>
          <ellipse cx="-73" cy="11" rx="11" ry="10.9" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
        <g>
          <defs>
            <rect x="-274.8" y="-735.8" width="1453.7" height="1364.1" />
          </defs>
          <clipPath>
            <use style={{ overflow: 'visible' }} />
          </clipPath>
        </g>
      </g>
      <path style={{ fill: base }} d="M11,22c6.1,0,11-4.9,11-11S17.1,0,11,0S0,4.9,0,11S4.9,22,11,22z" />
      <path
        style={{ fill: white }}
        d="M11,16.2c-0.3,0-0.7-0.1-0.9-0.4l-4-4.2c-0.5-0.5-0.5-1.3,0-1.8c0.5-0.5,1.3-0.5,1.8,0l2.9,3l4.1-6.8
	c0.4-0.6,1.1-0.8,1.7-0.4C17.2,6,17.4,6.8,17,7.4l-5,8.2c-0.2,0.3-0.5,0.6-0.9,0.6C11.1,16.2,11,16.2,11,16.2z"
      />
    </svg>
  )
}
