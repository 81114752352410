import { roots } from '@retire/navigation/paths'
import type { FC } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { DashboardNotFound } from '../../../Dashboard/pages/DashboardNotFound'
import { EnrolmentNotFound } from '../../../Enrolment/pages/EnrolmentNotFound'
import { DefaultNotFound } from '../../../HygienePages/pages/DefaultNotFound'
import { IllustrationNotFound } from '../../../Illustration/pages/IllustrationNotFound'
import { SuitabilityCheckNotFound } from '../../../SuitabilityCheck/pages/SuitabilityCheckNotFound'

export const NotFoundByArea: FC = () => {
  const { pathname } = useLocation()

  if (
    matchPath(`${roots['account']}/*`, pathname) ||
    matchPath(`${roots['dashboard']}/*`, pathname) ||
    matchPath(`${roots['inbox']}/*`, pathname)
  ) {
    return <DashboardNotFound />
  } else if (matchPath(`${roots['illustration']}/*`, pathname)) {
    return <IllustrationNotFound />
  } else if (matchPath(`${roots['enrolment']}/*`, pathname)) {
    return <EnrolmentNotFound />
  } else if (matchPath(`${roots['suitabilityCheck']}/*`, pathname)) {
    return <SuitabilityCheckNotFound />
  } else {
    return <DefaultNotFound />
  }
}
