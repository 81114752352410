import { useCallback, useEffect, useRef } from 'react'

// When the identity of either time or callback changes the timeout is reset
// make sure to move them out of the body of a react function or to memoize them
export const useTimeout = (callback: () => void, milliseconds = 1000) => {
  const currentTimeout = useRef<NodeJS.Timeout | null>(null)

  const clearCurrentTimeout = useCallback(() => {
    if (currentTimeout.current) {
      clearTimeout(currentTimeout.current)
      currentTimeout.current = null
    }
  }, [])

  useEffect(() => {
    if (callback && milliseconds) {
      clearCurrentTimeout()
      currentTimeout.current = setTimeout(callback, milliseconds)
    }

    return () => clearCurrentTimeout()
  }, [callback, clearCurrentTimeout, milliseconds])
}
