import type { PotName } from '@retire/constants'
import type { UserState } from '@retire/gql/__generated__/graphql'

import { potPaths, statePaths } from './paths'
import type { TPotPath } from './types'

export const getStateDefaultPath = (state: UserState) => statePaths[state][0]

export const getPotNameFromPotPath = (path: TPotPath) =>
  (Object.keys(potPaths) as PotName[]).find(potName => potPaths[potName] === path) as PotName

export const isValidPotPath = (path: string) => !!Object.values(potPaths).find(potPath => potPath === path)
