import { Headline } from '@retire/components/Typography/Headline'
import { useGlobalLoadingContext } from '@retire/hooks/useGlobalLoadingContext'
import { usePromiseWithError } from '@retire/hooks/usePromiseWithError'
import { useSessionCountdownContext } from '@retire/hooks/useSessionCountdownContext'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { HeadlineLevel } from '@retire/theme/typography'
import { signOut } from '@retire/utils/session'
import { useEffect, useState } from 'react'

import { Overlay } from '../../Overlay'
import { secondsToDisplay } from './utils'

export const SessionCountdown = () => {
  const { t } = useTranslationWithOptions('common')
  const { getSecondsLeft, extendSession } = useSessionCountdownContext()
  const { activateGlobalLoading, deactivateGlobalLoading } = useGlobalLoadingContext()
  const [secondsLeftCounter, setSecondsLeftCounter] = useState(getSecondsLeft && getSecondsLeft())

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeftCounter(getSecondsLeft && getSecondsLeft())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [getSecondsLeft, setSecondsLeftCounter])

  const { loading: loadingSignOut, promise: handleSignOut } = usePromiseWithError('Signout failed', () => signOut(true))
  const { loading: loadingExtendSession, promise: handleExtendSession } = usePromiseWithError(
    'Extend session failed',
    extendSession ? () => extendSession().then(deactivateGlobalLoading) : undefined
  )

  useEffect(() => {
    if (loadingSignOut) {
      activateGlobalLoading(t('loading.signOut.title', 'Signing out'))
    } else if (loadingExtendSession) {
      activateGlobalLoading()
    } else {
      deactivateGlobalLoading()
    }
  }, [activateGlobalLoading, deactivateGlobalLoading, loadingExtendSession, loadingSignOut, t])

  return (
    <Overlay
      icon="Clock"
      title={t('sessionCountdown.title', 'Session countdown title')}
      subTitle={t('sessionCountdown.subTitle', 'Session countdown subtitle')}
      onNext={handleExtendSession}
      nextText={t('sessionCountdown.actions.staySignedIn', 'Stay')}
      onBack={handleSignOut}
      backText={t('sessionCountdown.actions.signOut', 'Sign out')}
    >
      <Headline level={HeadlineLevel.h3} as="h5">
        {secondsToDisplay(secondsLeftCounter)}
      </Headline>
    </Overlay>
  )
}
