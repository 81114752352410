import { useUserDetails } from '@retire/hooks/useUserDetails'
import { useMemo } from 'react'

import type { TUseFlexibleIncomePotAges } from './types'

export const useFlexibleIncomePotAges: TUseFlexibleIncomePotAges = () => {
  const {
    data: { currentAge, regularIncomeEndAge, regularIncomeStartAge },
  } = useUserDetails()

  return useMemo(
    () => ({
      startAge: Math.max(regularIncomeStartAge, currentAge),
      endAge: regularIncomeEndAge,
    }),
    [currentAge, regularIncomeEndAge, regularIncomeStartAge]
  )
}
