import { Link } from '@retire/components/Link'
import { css, styled } from '@retire/theme/styledComponents'

import type { TMenuLinkItem } from './types'

export const SMenuLinkItem = styled(Link)<TMenuLinkItem>`
  ${({
    theme: {
      colors,
      typography: {
        copy: {
          level: { body1 },
        },
      },
      utilities: { weight },
      spacing,
    },
    active,
    dot,
  }) => css`
    display: flex;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    color: ${colors.primary};
    font-size: ${body1.size};
    font-weight: ${weight.bold};
    line-height: ${body1.lineHeight};
    position: relative;
    padding: ${spacing.mediumSmall} ${spacing.medium};
    outline: none;

    ${dot
      ? `
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: ${colors.secondary};
          display: flex;
          position: absolute;
          top: ${spacing.mediumSmall};
          left: 60px;
        }
      `
      : ''}

    ${active
      ? `
        background-color: ${colors.primary};
        color: ${colors.white};
      `
      : ''}

    &:hover {
      background-color: ${colors.white};
      color: ${colors.hyperlinkHover};
    }

    &:focus {
      background-color: ${colors.white};
      color: ${colors.primary};
      border: 1px solid ${colors.primary};
    }
  `}
`
