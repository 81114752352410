import { Link } from '@retire/components/Link'
import { UnorderedList } from '@retire/components/List'
import { Segment } from '@retire/components/Segment'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { Headline } from '@retire/components/Typography/Headline'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { TypographyColor } from '@retire/theme/colors'
import { CopyLevel, HeadlineLevel } from '@retire/theme/typography'
import type { FC } from 'react'

export const NonEligible: FC = () => {
  const { t } = useTranslationWithOptions('common')
  const {
    eligibilityRequirements: { minimumAge: eligibilityMinAge },
  } = usePublicSettings()

  return (
    <PageTemplate title={t('hygienePages.nonEligible.title', 'Non Eligible page title')}>
      <Copy as="p" level={CopyLevel.body2}>
        {t('hygienePages.nonEligible.toJoinYouNeedTo', 'You are not eligible for the Smart Retire.')}
      </Copy>
      <UnorderedList>
        <li>{t('hygienePages.nonEligible.eligibilityList.beMemberOfSmart', 'You should be a member of Smart.')}</li>
        <li>
          {t(
            'hygienePages.nonEligible.eligibilityList.haveAtLeastMinAge',
            'You are at least {{eligibilityMinAge}} years old.',
            { eligibilityMinAge }
          )}
        </li>
      </UnorderedList>
      <Link as="a" href={t('hygienePages.nonEligible.findOutMore.url', 'Find out more URL')}>
        {t('hygienePages.nonEligible.findOutMore.link', 'Find out more')}
      </Link>
      <Spacer top="large" />
      <Segment whiteBackground>
        <Headline as="h2" level={HeadlineLevel.h4} color={TypographyColor.neutralDark}>
          {t('hygienePages.nonEligible.segmentTitle', 'Looking for Smart Pension?')}
        </Headline>
        <Spacer top="medium" />
        <Link as="a" href={t('hygienePages.nonEligible.goToSmartPension.url', 'Go to Smart Pension URL')}>
          {t('hygienePages.nonEligible.goToSmartPension.link', 'Go to Smart Pension')}
        </Link>
      </Segment>
    </PageTemplate>
  )
}
