import { Copy, Link } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import { Trans } from 'react-i18next'

export const NotificationTitle = () => {
  const { t } = useTranslationWithOptions(['dashboard', 'common'])

  return (
    <Copy as="span">
      <Trans
        components={{
          pensionWiseLink: (
            <Link
              as="a"
              key="link"
              href={t('common:externalLinks.pensionWise.taxAndPensions', 'Pension Wise link')}
              target="_blank"
            />
          ),
        }}
      >
        {t('pots.common.confirmMoveMoneyRequest.pensionWise.title', 'Disclaimer')}
      </Trans>
    </Copy>
  )
}
