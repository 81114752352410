import { Breakpoint } from '@retire/theme'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

import type { TNavButtons } from './types'

export const SNavButtons = styled.div<Pick<TNavButtons, 'justifyContent'>>`
  ${({ justifyContent }) => css`
    display: flex;
    justify-content: ${justifyContent};

    ${mediaStyles({ flexDirection: 'column', flex: 'auto' }, { max: Breakpoint.small })}
  `}
`

export const SNextButton = styled.div`
  ${({ theme: { spacing } }) => css`
    margin-right: ${spacing.large};

    ${mediaStyles(
      { marginBottom: spacing.medium, marginLeft: 'unset', marginRight: 'unset' },
      {
        max: Breakpoint.small,
      }
    )}
  `}
`
