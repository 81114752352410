import { disableAnimations } from '@retire/utils/config'

import { colors } from './colors'
import { utilities } from './utilities'

export const general = {
  font: {
    family: 'lato, sans-serif',
    weight: utilities.weight.regular,
    style: 'normal',
    size: '1rem',
  },
  backgroundColor: colors.neutral10,
  dividerColor: colors.neutral30,
  dividerSize: '1px',
  overlayBg: 'rgba(0, 0, 0, 0.3)',
  transitionDuration: disableAnimations ? 'unset' : '200ms',
}
