import { Breakpoint } from '@retire/theme'
import { styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

export const SPotNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SPotContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

export const SActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0;
`

export const SPotContent = styled.div`
  display: flex;
  flex-direction: row;

  ${mediaStyles({ minWidth: '280px' }, { min: Breakpoint.large, selector: '> div:not(:last-child)' })}
  ${mediaStyles({ flexDirection: 'column' }, { max: Breakpoint.large })}
  ${mediaStyles({ width: '100%' }, { max: Breakpoint.large, selector: '> div' })}

  > section {
    min-width: 20%;
  }
`

export const SPotValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
