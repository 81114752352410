import { ProgressIndicator } from '@retire/components/ProgressIndicator/presentation'
import { useCustomHeaderContext } from '@retire/layout/PageLayout/hooks/useCustomHeaderContext'
import { useEffect, useMemo } from 'react'

import { useTranslationWithOptions } from '../useTranslationWithOptions'
import type { TUseProgressHeader } from './types'

export const useProgressHeader: TUseProgressHeader = ({
  activated = true,
  currentStep,
  totalSteps,
  displayedTotalSteps = totalSteps,
}) => {
  const { t } = useTranslationWithOptions('common')
  const { resetCustomHeader, setCustomHeader } = useCustomHeaderContext()
  const finishedText = useMemo(() => t('progressIndicator.finished', 'Ready'), [t])
  const progressText = useMemo(
    () => t('progressIndicator.inProgress', 'In progress', { currentStep, totalSteps: displayedTotalSteps }),
    [currentStep, displayedTotalSteps, t]
  )

  useEffect(() => {
    activated &&
      setCustomHeader(
        <ProgressIndicator
          totalSteps={totalSteps}
          currentStep={currentStep}
          isFinished={currentStep === totalSteps}
          progressText={progressText}
          finishedText={finishedText}
        />
      )
    !activated && resetCustomHeader()

    return resetCustomHeader
  }, [activated, currentStep, finishedText, progressText, resetCustomHeader, setCustomHeader, totalSteps])
}
