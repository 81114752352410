import { LinkSize } from '@retire/constants'
import type { TPickAndRequired } from '@retire/types'

import { DEFAULT_PROPS as LINK_DEFAULT_PROPS } from '../constants'
import type { TLink } from '../types'
import type { TIconLink } from './types'

export const DEFAULT_PROPS: TPickAndRequired<TLink, 'variant'> & TPickAndRequired<TIconLink, 'size'> = {
  ...LINK_DEFAULT_PROPS,
  size: LinkSize.normal,
}
