import { PotName } from '@retire/constants/pots'
import { FEATURE_FLAGS_QUERY } from '@retire/gql/featureFlags/queries'
import { PUBLIC_SETTINGS_QUERY, SETTINGS_QUERY } from '@retire/gql/settings/queries'
import { useGlobalLoadingContext } from '@retire/hooks/useGlobalLoadingContext'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useSessionCheck } from '@retire/hooks/useSessionCheck'
import { isActiveSession } from '@retire/utils/session'
import { formatPhoneNumber, formatPhoneNumberURI } from '@retire/utils/strings'
import type { FC } from 'react'
import { createContext, useEffect, useMemo } from 'react'

import type { TCoreDataContext, TPublicSettings, TSettings } from './types'

export const CoreDataContext = createContext<TCoreDataContext>({
  featureFlags: [],
  publicSettings: {} as TPublicSettings,
  settings: {} as TSettings,
})

export const CoreDataProvider: FC = ({ children }) => {
  const { activateGlobalLoading, deactivateGlobalLoading } = useGlobalLoadingContext()

  const { loading: sessionChecking } = useSessionCheck()
  const { data: featureFlagsData, loading: featureFlagsLoading } = useQueryWithError(FEATURE_FLAGS_QUERY)
  const { data: publicSettingsData, loading: publicSettingsLoading } = useQueryWithError(PUBLIC_SETTINGS_QUERY)
  const { data: settingsData, loading: settingsLoading } = useQueryWithError(SETTINGS_QUERY, {
    skip: !isActiveSession(),
  })

  const loading = useMemo(
    () => featureFlagsLoading || publicSettingsLoading || sessionChecking || settingsLoading,
    [featureFlagsLoading, publicSettingsLoading, sessionChecking, settingsLoading]
  )
  const contextValue = useMemo(
    () => ({
      featureFlags: featureFlagsData?.featureFlags || [],
      publicSettings: {
        ...publicSettingsData?.settings,
        customerService: {
          ...publicSettingsData?.settings?.customerService,
          phoneNumber: formatPhoneNumber(publicSettingsData?.settings?.customerService.phoneNumber),
          uriPhoneNumber: formatPhoneNumberURI(publicSettingsData?.settings?.customerService.phoneNumber),
        },
      },
      settings: {
        adminFees: settingsData?.settings.adminFees,
        annualPensionContributionAllowance: settingsData?.settings.annualPensionContributionAllowance,
        [PotName.flexibleIncome]: {
          feePercentage: settingsData?.settings.flexibleIncome.feePercentage,
          minAmount: settingsData?.settings.flexibleIncome.totalAmount.minimum,
          minMonthlyIncome: settingsData?.settings.flexibleIncome.monthlyAmount.minimum,
          maxMonthlyIncome: settingsData?.settings.flexibleIncome.monthlyAmount.maximum,
          defaultStartAge: settingsData?.settings.regularIncomeStartAge.default,
          minStartAge: settingsData?.settings.regularIncomeStartAge.minimum,
          maxStartAge: settingsData?.settings.regularIncomeStartAge.maximum,
          defaultEndAge: settingsData?.settings.regularIncomeEndAge.default,
          minEndAge: settingsData?.settings.regularIncomeEndAge.minimum,
          maxEndAge: settingsData?.settings.regularIncomeEndAge.maximum,
        },
        inactivePeriodInDays: settingsData?.settings.illustrationPlan.inactivePeriodInDays,
        [PotName.inheritance]: {
          feePercentage: settingsData?.settings.inheritance.feePercentage,
          minAmount: settingsData?.settings.inheritance.totalAmount.minimum,
        },
        [PotName.laterLife]: {
          feePercentage: settingsData?.settings.laterLife.feePercentage,
          minAmount: settingsData?.settings.laterLife.totalAmount.minimum,
          defaultStartAge: settingsData?.settings.laterLifeAge.default,
          minStartAge: settingsData?.settings.laterLifeAge.minimum,
          maxStartAge: settingsData?.settings.laterLifeAge.maximum,
        },
        lifetimeAllowance: settingsData?.settings.lifetimeAllowance,
        lumpSumAllowance: settingsData?.settings.lumpSumAllowance,
        lumpSumAndDeathBenefitAllowance: settingsData?.settings.lumpSumAndDeathBenefitAllowance,
        maxPotAmountRatio: settingsData?.settings.potAmount.maximumRatio,
        minWithdrawalAmount: settingsData?.settings.withdrawalAmount.minimum,
        moneyPurchaseAnnualAllowance: settingsData?.settings.moneyPurchaseAnnualAllowance,
        payrollSupport: {
          email: settingsData?.settings.payrollSupport.email,
          phoneNumber: formatPhoneNumber(settingsData?.settings.payrollSupport.phoneNumber),
        },
        [PotName.rainyDay]: {
          feePercentage: settingsData?.settings.rainyDay.feePercentage,
          minAmount: settingsData?.settings.rainyDay.totalAmount.minimum,
        },
      },
    }),
    [featureFlagsData, publicSettingsData, settingsData]
  )

  useEffect(() => {
    if (loading) {
      activateGlobalLoading()
    } else {
      deactivateGlobalLoading()
    }
  }, [activateGlobalLoading, deactivateGlobalLoading, loading])

  return <CoreDataContext.Provider value={contextValue as TCoreDataContext}>{children}</CoreDataContext.Provider>
}
