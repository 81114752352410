import { DataRow, Divider, Headline, Loading, Spacer } from '@retire/components'
import { PotName } from '@retire/constants'
import { useTranslationWithOptions, useUserDetails } from '@retire/hooks'
import { useEstimateIncomeModal } from '@retire/hooks/useEstimateIncomeModal'
import { HeadlineLevel } from '@retire/theme'
import { formatDifference, toCurrency } from '@retire/utils'
import type { FC } from 'react'
import { Col } from 'react-grid-system'
import { useWatch } from 'react-hook-form'

import { useMoveMoneyMonthlyAmount } from '../../../hooks/useMoveMoneyMonthlyAmount'
import { usePotsContext } from '../../../hooks/usePotsContext'
import { AMOUNT_INPUT_NAME, FROM_INPUT_NAME } from '../constants'
import type { TEstimatedChanges } from './types'

export const EstimatedChanges: FC<TEstimatedChanges> = ({ potName }) => {
  const { t } = useTranslationWithOptions('dashboard')
  const { Modal, ModalLink } = useEstimateIncomeModal(potName)

  const [selectedFromPot, amount] = useWatch({ name: [FROM_INPUT_NAME, AMOUNT_INPUT_NAME] })
  const {
    pots: {
      [potName]: { allocatedAmount },
    },
  } = usePotsContext()
  const {
    data: { currentAge, regularIncomePaused, laterLifeAge, regularIncomeEndAge, regularIncomeStartAge },
    loading: loadingUserDetails,
  } = useUserDetails()
  const isFlexibleIncomePot = potName === PotName.flexibleIncome
  const isPaused = isFlexibleIncomePot && regularIncomePaused

  let yearsLeft = laterLifeAge - currentAge
  let untilAge = laterLifeAge
  if (isFlexibleIncomePot) {
    yearsLeft = regularIncomeEndAge - Math.max(currentAge, regularIncomeStartAge)
    untilAge = regularIncomeEndAge
  }

  const { currentMonthlyAmount, afterMoveMonthlyAmount, afterMovePotAmount, penniesAmount } = useMoveMoneyMonthlyAmount(
    {
      potName,
      selectedFromPot,
      amount,
    }
  )

  if (loadingUserDetails) {
    return <Loading />
  }

  return (
    <Spacer bottom="extraLarge">
      <Divider />
      <Spacer top="large" bottom="medium">
        <Headline as="h2" level={HeadlineLevel.h2}>
          {t('pots.common.moveMoneyBetweenPots.estimatedSection.title', 'Changes')}
        </Headline>
      </Spacer>
      <Col>
        <Headline level={HeadlineLevel.h4} as="h4">
          {t(`pots.common.moveMoneyBetweenPots.estimatedSection.${potName}.title`, potName)}
        </Headline>
        <DataRow
          name={t('pots.common.moveMoneyBetweenPots.estimatedSection.current', 'Current')}
          value={toCurrency(allocatedAmount)}
        />
        <DataRow
          name={t('pots.common.moveMoneyBetweenPots.estimatedSection.amount', 'Amount')}
          value={formatDifference(penniesAmount)}
        />
        <DataRow
          name={t('pots.common.moveMoneyBetweenPots.estimatedSection.new', 'New')}
          value={toCurrency(afterMovePotAmount)}
        />
        <Headline level={HeadlineLevel.h4} as="h4">
          {t('pots.common.moveMoneyBetweenPots.estimatedSection.monthlyIncome', 'Monthly income')}
        </Headline>
        <DataRow
          name={t('pots.common.moveMoneyBetweenPots.estimatedSection.current', 'Current')}
          value={toCurrency(currentMonthlyAmount)}
        />
        <DataRow
          name={t('pots.common.moveMoneyBetweenPots.estimatedSection.new', 'New')}
          value={toCurrency(afterMoveMonthlyAmount)}
        />
        {!isPaused && (
          <DataRow
            name={t('pots.common.moveMoneyBetweenPots.estimatedSection.duration', 'Duration')}
            value={t(
              `pots.common.moveMoneyBetweenPots.estimatedSection.${potName}.subTitle`,
              '{{yearsLeft}} years, until age {{age}}',
              {
                yearsLeft,
                age: untilAge,
              }
            )}
          />
        )}
      </Col>

      <Spacer bottom="medium" />
      <ModalLink />
      <Modal />
    </Spacer>
  )
}
