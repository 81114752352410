import type { FC, SyntheticEvent } from 'react'
import { useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { SMenuLinkItem } from './presentation.styled'
import type { TMenuLink } from './types'

export const MenuLink: FC<TMenuLink> = ({ onClick, href, dot, title }) => {
  const navigate = useNavigate()
  const isActive = !!useMatch(href)

  const memoizedOnClick = useCallback(
    (e: SyntheticEvent) => {
      if (onClick) onClick(e)
      e.preventDefault()
      navigate(href)
    },
    [onClick, href, navigate]
  )

  return (
    <SMenuLinkItem as="a" href={href} active={isActive} dot={dot} onClick={memoizedOnClick} data-cy={title}>
      {title}
    </SMenuLinkItem>
  )
}
