import { CoreDataProvider } from '@retire/contexts/coreData'
import { GlobalErrorProvider } from '@retire/contexts/globalError/context'
import { GlobalLoadingProvider } from '@retire/contexts/globalLoading'
import { LocationAlertsProvider } from '@retire/contexts/locationAlerts/context'
import { SessionCountdownProvider } from '@retire/contexts/sessionCountdown/context'
import { accountRoutes } from '@retire/features/Account/routes'
import { dashboardRoutes } from '@retire/features/Dashboard/routes'
import { ConfirmEmail } from '@retire/features/Enrolment/components/ConfirmEmail'
import { EmailVerificationError } from '@retire/features/Enrolment/components/EmailVerificationError'
import { EmailVerified } from '@retire/features/Enrolment/components/EmailVerified'
import { enrolmentRoutes } from '@retire/features/Enrolment/routes'
import { hygienePagesRoutes } from '@retire/features/HygienePages/routes'
import { illustrationRoutes } from '@retire/features/Illustration/routes'
import { inboxRoutes } from '@retire/features/Inbox/routes'
import { suitabilityCheckRoutes } from '@retire/features/SuitabilityCheck/routes'
import { PageLayout, PageOverlayProvider } from '@retire/layout/PageLayout'
import { useRoutes } from 'react-router-dom'

import { DefaultRoute } from './DefaultRoute'
import { useErrorRedirect } from './hooks/useErrorRedirect'
import { paths } from './paths'

export const RetireRoutes = () => {
  return useRoutes([
    ...accountRoutes,
    ...dashboardRoutes,
    ...hygienePagesRoutes,
    ...suitabilityCheckRoutes,
    ...illustrationRoutes,
    ...enrolmentRoutes,
    ...inboxRoutes,
    { path: paths.other.confirmEmail, element: <ConfirmEmail /> },
    { path: paths.other.emailVerified, element: <EmailVerified /> },
    { path: paths.other.emailVerificationError, element: <EmailVerificationError /> },
    { path: '*', element: <DefaultRoute /> },
  ])
}

export const RetireRouter = () => {
  useErrorRedirect()

  return (
    <GlobalErrorProvider>
      <GlobalLoadingProvider>
        <CoreDataProvider>
          <SessionCountdownProvider>
            <PageOverlayProvider>
              <PageLayout>
                <LocationAlertsProvider>
                  <RetireRoutes />
                </LocationAlertsProvider>
              </PageLayout>
            </PageOverlayProvider>
          </SessionCountdownProvider>
        </CoreDataProvider>
      </GlobalLoadingProvider>
    </GlobalErrorProvider>
  )
}
