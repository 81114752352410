import { useMutation } from '@apollo/client'
import { Copy, NavButtons, Spacer, UnorderedList } from '@retire/components'
import { DateFormat } from '@retire/constants'
import { USER_BRIEF_DETAILS_QUERY } from '@retire/gql/user/queries'
import { useLocationAlerts, useQueryWithError, useTranslationWithOptions } from '@retire/hooks'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { formatDate } from '@retire/utils'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'

import { REGULAR_INCOME_PAUSED_MUTATION } from '../../gql'

export const RestartFlexibleIncome: FC = () => {
  useActivatePageOverlay()
  const { t } = useTranslationWithOptions('dashboard')
  const { navigateWithAlerts } = useLocationAlerts()

  const onCompleted = useCallback(
    () =>
      navigateWithAlerts(paths.dashboard.flexibleIncomePotDetails, [
        {
          id: 'regular_income_paused',
          type: 'success',
          title: t('pots.common.restartFlexibleIncome.success', 'Regular income restarted'),
        },
      ]),
    [navigateWithAlerts, t]
  )

  const [onRestartIncomeClick] = useMutation(REGULAR_INCOME_PAUSED_MUTATION, {
    variables: {
      regularIncomePaused: false,
    },
    onCompleted,
    refetchQueries: [{ query: USER_BRIEF_DETAILS_QUERY }],
  })

  const onBackClick = useCallback(
    () => navigateWithAlerts(paths.dashboard.flexibleIncomePotDetails, []),
    [navigateWithAlerts]
  )

  const { data: userData, loading } = useQueryWithError(USER_BRIEF_DETAILS_QUERY)

  return (
    <PageTemplate
      fullWidth
      title={t('pots.common.restartFlexibleIncome.title', 'Restart monthly income')}
      loading={loading}
    >
      <>
        <Copy as="p">
          {t(
            'pots.common.restartFlexibleIncome.descriptionOne',
            'You will get your next payment on {{nextPaymentDate}}',
            {
              nextPaymentDate: userData ? formatDate(userData.user.nextRegularIncomePaymentOn, DateFormat.date) : '',
              interpolation: { escapeValue: false },
            }
          )}
        </Copy>
        <Spacer top="medium" />
        <Copy as="p">{t('pots.common.restartFlexibleIncome.descriptionTwo', 'Once you restart:')}</Copy>
        <Spacer top="medium" />
        <Copy as="div">
          <Trans components={{ list: <UnorderedList />, listItem: <li /> }}>
            {t(
              'pots.common.restartFlexibleIncome.descriptionList',
              "<list><listItem>we'll show your estimated income again</listItem><listItem>you can transfer money in and out of the pot</listItem></list>"
            )}
          </Trans>
        </Copy>
        <NavButtons
          onNext={onRestartIncomeClick}
          onBack={onBackClick}
          nextText={t('pots.common.restartFlexibleIncome.actions.restart', 'Restart income')}
          backText={t('pots.common.restartFlexibleIncome.actions.back', 'Back')}
        />
      </>
    </PageTemplate>
  )
}
