import { FormOptionsGroup } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import type { TQuestion } from './types'

export const Question: FC<TQuestion> = ({ index, name, ...props }) => {
  const { t } = useTranslationWithOptions('suitabilityCheck')

  return (
    <FormOptionsGroup
      name={name}
      label={`${index}. ${t(`beforeYouStart.form.questions.${name}`, 'Before you start form questions')}`}
      options={[
        {
          label: t('beforeYouStart.form.yes', 'Before you start form yes'),
          value: true,
        },
        {
          label: t('beforeYouStart.form.no', 'Before you start form no'),
          value: false,
        },
      ]}
      fullWidth
      {...props}
    />
  )
}
