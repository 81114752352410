import { useTranslationWithOptions } from '@retire/hooks'
import { paths, roots } from '@retire/navigation/paths'
import { matchPath, useLocation } from 'react-router-dom'

import type { TUsePageTitle } from './types'

export const usePageTitle = (): TUsePageTitle => {
  const { t } = useTranslationWithOptions('common')
  const { pathname } = useLocation()
  let transKey: string | undefined

  const pageTitlePathPatterns: Record<string, string> = {
    transactions: paths.dashboard.transactionList,
  }
  transKey = Object.keys(pageTitlePathPatterns).find(key => matchPath(`${pageTitlePathPatterns[key]}/*`, pathname))
  if (!transKey) {
    transKey = Object.keys(roots).find(key => matchPath(`${roots[key]}/*`, pathname))
  }

  return {
    title: t(`pageTitle.${transKey || 'default'}`, transKey || 'default'),
  }
}
