import { UnorderedList } from '@retire/components/List'
import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'

export const Intro: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.savingsPots })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()
  const isEnsignUser = useIsEnsignUser()

  const {
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
    loadingIllustrationPlanData,
  } = useIllustrationPlanData()

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  const onNext = useCallback(() => navigate(paths.illustration.journeyInheritancePotIntro), [navigate])
  const onBack = useCallback(() => navigate(paths.illustration.journeyLaterLifePotIntro), [navigate])

  return (
    <JourneyStepLayout
      title={t(
        `steps.savingsPotsIntro.title.${isEnsignUser ? 'ensignUser' : 'standardUser'}`,
        `Savings pots intro title for ${isEnsignUser ? 'ensign user' : 'standard user'} `
      )}
      description={
        <Trans components={{ list: <UnorderedList />, listItem: <li /> }}>
          {t(
            `steps.savingsPotsIntro.description.${isEnsignUser ? 'ensignUser' : 'standardUser'}`,
            `Savings pots intro description for ${isEnsignUser ? 'ensign user' : 'standard user'} `
          )}
        </Trans>
      }
      onNext={onNext}
      nextText={t('common.buttons.continue', 'Continue')}
      backText={t('common.buttons.back', 'Back')}
      onBack={onBack}
      loading={loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData}
    />
  )
}
