import { colors } from './colors'
import { spacing } from './spacing'
import { utilities } from './utilities'

export const footer = {
  backgroundColor: colors.neutral70,
  contactNoColor: colors.buttonHover,
  copy: {
    padding: spacing.medium,
  },
  color: colors.white,
  hoverColor: colors.buttonHover,
  hrColor: colors.neutral50,
  minHeight: '450px',
  linkLineHeight: '1.688rem',
  smallLinks: {
    fontWeight: utilities.weight.regular,
    paddingLeft: spacing.small,
    paddingRight: spacing.small,
    lineHeight: '1.313rem',
  },
  smallText: {
    fontSize: '0.875rem',
  },
  standard: {
    spacing: {
      small: spacing.small,
      big: spacing.extraLarge,
    },
  },
  mobile: {
    spacing: {
      small: spacing.small,
      medium: spacing.medium,
      big: spacing.large,
    },
    sectionTitle: {
      fontSize: '1.375rem',
      lineHeight: '1.375rem',
    },
  },
}
