import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Check: FC<TIconColors> = props => {
  const {
    colors: { primary },
  } = useThemeContext()
  const { base = primary } = props

  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Check" />
      <path d="M1 6.19231L5.56522 10L11 1" stroke={base} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
