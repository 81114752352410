import { ConfirmModal } from '@retire/layout/Modals/ConfirmModal'
import { useCallback, useState } from 'react'

import type { TUseConfirmModalWithTranslation } from './types'

export const useConfirmModalWithTranslation = ({
  title,
  content,
  onConfirm,
  confirmText,
  closeText,
}: TUseConfirmModalWithTranslation) => {
  const [isModalVisible, setModalVisibility] = useState(false)

  const showModal = useCallback(() => setModalVisibility(true), [])
  const closeModal = useCallback(() => setModalVisibility(false), [])

  const confirmAndCloseModal = useCallback(() => {
    onConfirm && onConfirm()
    closeModal()
  }, [closeModal, onConfirm])

  const Modal = () => (
    <ConfirmModal
      isActive={isModalVisible}
      dialogType="alertdialog"
      title={title}
      onClose={closeModal}
      onConfirm={confirmAndCloseModal}
      confirmText={confirmText}
      closeText={closeText}
    >
      {content}
    </ConfirmModal>
  )

  return {
    showModal,
    Modal,
  }
}
