import {
  INIT_QUERY,
  USER_BRIEF_DETAILS_QUERY,
  USER_CONTACT_DETAILS_QUERY,
  USER_FULL_DETAILS_QUERY,
  USER_NAME_QUERY,
} from '@retire/gql/user/queries'
import type { TFormFetchData, TFormSubmitData } from '@retire/hooks'

import { USER_FULL_DETAILS_MUTATION } from './gql/mutations'
import type { TPersonalDetails } from './types'

const fetchData: TFormFetchData<TPersonalDetails> = async ({ apolloQuery }) => {
  const {
    data: { user },
  } = await apolloQuery({ query: USER_FULL_DETAILS_QUERY })

  return { formData: user }
}

const submitData: TFormSubmitData<TPersonalDetails> = async ({ formData, apolloMutate }) => {
  const {
    forename,
    surname,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    county,
    postcode,
    telephone,
    telephoneCountryCode,
    gender,
  } = formData
  await apolloMutate({
    mutation: USER_FULL_DETAILS_MUTATION,
    variables: {
      input: {
        forename,
        surname,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        county,
        postcode,
        telephone,
        telephoneCountryCode,
        gender,
      },
    },
    refetchQueries: [
      { query: USER_NAME_QUERY },
      { query: USER_BRIEF_DETAILS_QUERY },
      { query: USER_CONTACT_DETAILS_QUERY },
      { query: USER_FULL_DETAILS_QUERY },
      { query: INIT_QUERY },
    ],
  })

  return formData
}

export const aboutYouServices = {
  fetchData,
  submitData,
}
