export enum DatePart {
  day = 'day',
  month = 'month',
  year = 'year',
}

export enum DateFormat {
  longDate,
  longDatetime,
  datetime,
  date,
  time,
  longTime,
  shortYear,
  yearMonthDay,
}

export const MAX_DATE_PART = {
  [DatePart.year]: 9999,
  [DatePart.month]: 12,
  [DatePart.day]: 31,
}
