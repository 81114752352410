import type { TGeneralError } from '@retire/types'
import { isGlobalErrorCode } from '@retire/utils'
import type { FC } from 'react'
import { createContext, useCallback, useMemo, useState } from 'react'

import type { TGlobalErrorContext } from './types'

export const GlobalErrorContext = createContext<TGlobalErrorContext>({
  globalError: undefined,
  setGlobalError: () => null,
  resetGlobalError: () => null,
})

export const GlobalErrorProvider: FC = ({ children }) => {
  const [globalError, setGlobalError] = useState<TGeneralError>()
  const setGlobalErrorIfCode = useCallback(error => isGlobalErrorCode(error?.code) && setGlobalError(error), [])
  const resetGlobalError = useCallback(() => setGlobalError(undefined), [])

  const value = useMemo(
    () => ({
      globalError,
      setGlobalError: setGlobalErrorIfCode,
      resetGlobalError,
    }),
    [globalError, setGlobalErrorIfCode, resetGlobalError]
  )

  return <GlobalErrorContext.Provider value={value}>{children}</GlobalErrorContext.Provider>
}
