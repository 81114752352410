import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import { Paragraph, Section, SubSection } from '../Common'

export const WhenYouDie: FC = () => {
  const { t } = useTranslationWithOptions('plan')

  return (
    <Section>
      <SubSection>
        <Paragraph>
          {t('whenYouDie.description.paragraphOne', 'What happens to your pension when you die (p1)')}
        </Paragraph>
        <Paragraph>
          {t('whenYouDie.description.paragraphTwo', 'What happens to your pension when you die (p2)')}
        </Paragraph>
        <Paragraph>
          {t('whenYouDie.description.paragraphThree', 'What happens to your pension when you die (p3)')}
        </Paragraph>
      </SubSection>
    </Section>
  )
}
