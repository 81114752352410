import { Box, Flex } from '@chakra-ui/react'
import type { FC } from 'react'

import { mapResponsiveStyle } from '../utils/mapResponsiveStyle'
import { widthsMap } from '../utils/widthsMap'
import type { TBlock } from './types'

export type TWidth = '1/8' | '1/4' | '1/3' | '1/2' | '2/3' | '3/4' | 'full' | 'fluid' | 'content'

type TFrameWidth = Exclude<TWidth, 'width' | 'fluid' | 'content'>

const getWidth = (width: TFrameWidth): string => widthsMap[width]

export const Block: FC<TBlock> = ({ children }) => {
  const frameWidth = mapResponsiveStyle(getWidth, 'full')

  return (
    <Flex alignItems="flex-start" justifyContent="center" paddingX="none" paddingY="none" width={frameWidth}>
      <Box maxWidth="block-children-max-width" width="100%" paddingX={{ base: 'md', sm: 'lg', md: 'none' }}>
        {children}
      </Box>
    </Flex>
  )
}
