import { styled } from '@retire/theme/styledComponents'

export const SNativeSelectWrapper = styled.div`
  padding-left: ${({ theme }) => theme.forms.input.padding};
  height: 100%;
  font-size: ${props => props.theme.forms.input.fontSize};
  position: relative;

  select {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 100; // be sure it's on the top to trigger open/close
  }
`

export const SNativeSelectSelectedValueWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
`

export const SNativeSelectSelectedValue = styled.div`
  margin: auto;
  padding-right: ${props => props.theme.forms.dropdown.iconSize};
`
