import { InstructionPublicState } from '@retire/gql/__generated__/graphql'

import type { TPendingTransactions } from './types'

export const PENDING_INSTRUCTION_STATES: InstructionPublicState[] = [
  InstructionPublicState.Created,
  InstructionPublicState.InProgress,
]

export const DEFAULT_PENDING_TRANSACTIONS: TPendingTransactions = {
  pendingTransactions: [],
  pendingAmounts: {},
}
