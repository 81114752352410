import { DatePart, NONNUMERIC_CHAR_REGEX } from '@retire/constants'
import { useBlockOnKeyDown, useFocusPreviousInputOnBackspace } from '@retire/hooks'
import { absoluteDifference, getDateFromObject, getDateObject, getNextObjectKey } from '@retire/utils'
import type { FC, MutableRefObject } from 'react'
import { createRef, useCallback, useMemo } from 'react'

import { DatePartInput } from './DatePartInput'
import { SFieldset } from './presentation.styled'
import type { TDate } from './types'
import { datePartDesiredLength } from './utils'

export const Date: FC<TDate> = ({
  id,
  name,
  ariaInvalid,
  ariaDescribedBy,
  ariaLabelledBy,
  onChange,
  value,
  disabled,
  invalid,
}) => {
  const dateObject = getDateObject(value)
  const dateRefs: Record<DatePart, MutableRefObject<HTMLInputElement | null>> = useMemo(
    () => ({
      [DatePart.day]: createRef<HTMLInputElement>(),
      [DatePart.month]: createRef<HTMLInputElement>(),
      [DatePart.year]: createRef<HTMLInputElement>(),
    }),
    []
  )

  const onDatePartChange = useCallback(
    (datePart: DatePart, partValue: string) => {
      const newDate = getDateObject(value)
      const oldValue = newDate[datePart]
      newDate[datePart] = partValue
      // if value is empty, getDateFromObject will return '--' (EMPTY_DATE_VALUE)
      const newValue = getDateFromObject(newDate)

      if (newValue !== value) {
        onChange && onChange(newValue)
        // automatically focus on the next datePart input when filling the field
        const nextKey = getNextObjectKey(dateRefs, datePart)

        partValue.length === datePartDesiredLength(datePart) &&
          (absoluteDifference(oldValue, partValue) !== 1 || partValue === '01') &&
          nextKey &&
          dateRefs[nextKey].current?.focus()
      }
    },
    [dateRefs, onChange, value]
  )

  const focusHandler = useFocusPreviousInputOnBackspace(Object.values(dateRefs))
  const onKeyDown = useBlockOnKeyDown(NONNUMERIC_CHAR_REGEX, focusHandler)

  return (
    <SFieldset
      id={id}
      role="group"
      aria-invalid={ariaInvalid}
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
    >
      {Object.values(DatePart).map(datePart => (
        <DatePartInput
          key={datePart}
          ref={dateRefs[datePart]}
          datePart={datePart}
          onDatePartChange={onDatePartChange}
          dateObject={dateObject}
          name={name}
          onKeyDown={onKeyDown}
          disabled={disabled}
          invalid={invalid}
        />
      ))}
    </SFieldset>
  )
}
