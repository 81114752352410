import { AdditionalPotName, PotName } from '@retire/constants'
import type { TAllPotVariants, TIconColors } from '@retire/types'
import { createError } from '@retire/utils'
import type { ComponentType } from 'react'

import { Icons } from '../assets/icons'

export const getPotIcon = (variant: TAllPotVariants): ComponentType<TIconColors> => {
  switch (variant) {
    case PotName.inheritance:
      return Icons.Inheritance

    case PotName.flexibleIncome:
      return Icons.FlexibleIncome

    case PotName.laterLife:
      return Icons.LaterLife

    case PotName.rainyDay:
      return Icons.RainyDay

    case AdditionalPotName.taxFree:
      return Icons.TaxFreeWithdrawal

    default:
      throw createError(`variant passed to RetirementPot component is invalid. Got: ${variant}`)
  }
}
