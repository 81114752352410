import { graphql } from '@retire/gql/__generated__/gql'

export const USER_ENROLMENT_MUTATION = graphql(`
  mutation updateUserEnrolment($enrolment: EnrolmentAttributes) {
    userUpdate(enrolment: $enrolment, input: {}) {
      id
      ...enrolment__User
    }
  }
`)
