import { TaxFreeLumpSumWithdrawalState } from '@retire/constants/pots'
import type { TApolloMutate } from '@retire/gql'
import type { UserState } from '@retire/gql/__generated__/graphql'
import { USER_STATE_MUTATION } from '@retire/gql/user/mutations'
import { INIT_QUERY, USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'

import type {
  TIsFlexibleIncomePotConfigured,
  TIsInheritancePotConfigured,
  TIsInheritancePotFundsConfigured,
  TIsLaterLifePotConfigured,
  TIsRainyDayPotConfigured,
  TIsTFLSConfigured,
} from './types'

export const updateUserState = (apolloMutate: TApolloMutate, state: UserState) =>
  apolloMutate({
    mutation: USER_STATE_MUTATION,
    variables: { state },
    refetchQueries: [{ query: USER_FULL_DETAILS_QUERY }, { query: INIT_QUERY }],
  })

export const isTFLSConfigured: TIsTFLSConfigured = ({ taxFreeLumpSumWithdrawalState }) => {
  return taxFreeLumpSumWithdrawalState !== TaxFreeLumpSumWithdrawalState.undecided
}

export const isFlexibleIncomePotConfigured: TIsFlexibleIncomePotConfigured = ({ flexibleIncomeAmount }) => {
  return flexibleIncomeAmount !== null
}

export const isLaterLifePotConfigured: TIsLaterLifePotConfigured = ({ laterLifeAmount }) => {
  return laterLifeAmount !== null
}

export const isInheritancePotConfigured: TIsInheritancePotConfigured = ({ inheritanceAmount }) => {
  return inheritanceAmount !== null
}

export const isInheritancePotFundsConfigured: TIsInheritancePotFundsConfigured = ({
  availableFunds,
  inheritanceAmount,
}) => {
  return inheritanceAmount === 0 || !!(availableFunds && availableFunds.some(({ selected }) => selected))
}

export const isRainyDayPotConfigured: TIsRainyDayPotConfigured = ({ rainyDayAmount }) => {
  return rainyDayAmount !== null
}
