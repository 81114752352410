import { Card, CardTitle, Copy } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import type { TWhatItsForCard } from './types'

export const WhatItsForCard: FC<TWhatItsForCard> = ({ potName }) => {
  const { t } = useTranslationWithOptions('dashboard')

  return (
    <Card>
      <CardTitle>{t('pots.common.whatItsFor', "What it's for")}</CardTitle>
      <Copy as="span">{t(`pots.${potName}.whatItsFor`, `What it's for description for ${potName}`)}</Copy>
    </Card>
  )
}
