import { Spacer } from '@retire/components'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'
import { Col, Row } from 'react-grid-system'

import { SFooterCopy } from '../../presentation.styled'
import { SLowerFooterLink, SPipeSeparator } from './presentation.styled'

export const LowerFooterLinks: FC = () => {
  const { t } = useTranslationWithOptions('common')

  return (
    <>
      <Row>
        <Col md={12}>
          <SLowerFooterLink as="a" href={t('footer.help.terms.url', 'terms URL')}>
            {t('footer.help.terms.title', 'Terms')}
          </SLowerFooterLink>
          <SPipeSeparator />
          <SLowerFooterLink as="a" href={t('footer.help.cookies.url', 'cookies URL')}>
            {t('footer.help.cookies.title', 'Cookies')}
          </SLowerFooterLink>
          <SPipeSeparator />
          <SLowerFooterLink as="a" href={t('footer.help.privacy.url', 'privacy URL')}>
            {t('footer.help.privacy.title', 'Privacy')}
          </SLowerFooterLink>
          <SPipeSeparator />
          <SLowerFooterLink as="a" href={t('footer.help.accessibility.url', 'accessibility URL')}>
            {t('footer.help.accessibility.title', 'Accessibility')}
          </SLowerFooterLink>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Spacer top="medium">
            <SFooterCopy as="p">
              {t('footer.help.registration', 'Registration details {{year}}', { year: new Date().getFullYear() })}
            </SFooterCopy>
          </Spacer>
        </Col>
      </Row>
    </>
  )
}
