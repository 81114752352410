import { graphql } from '@retire/gql/__generated__/gql'

export const USER_ENROLMENT_QUERY = graphql(`
  query getUserEnrolment {
    user {
      id
      ...enrolment__User
    }
  }
`)
