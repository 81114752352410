import { css, styled } from '@retire/theme/styledComponents'

import { IconContainer } from '../Icon'

export const SInlineError = styled.div`
  ${({ theme }) => `
    font-size: ${theme.forms.errors.fontSize};
    color: ${theme.forms.errors.color};
    font-weight: ${theme.forms.errors.fontWeight};
    line-height: ${theme.forms.errors.lineHeight};
  `}

  position: relative;

  a,
  button {
    display: inline;
    font-size: inherit;
  }
`

export const SErrorIconContainer = styled(IconContainer)`
  ${({ theme }) => css`
    width: auto;
    height: ${theme.forms.errors.lineHeight};
    max-width: ${theme.forms.errors.lineHeight};
  `};
`
