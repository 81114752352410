import type { FC } from 'react'
import { createContext, useMemo, useState } from 'react'
import type { To } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'

import type { TJourneyOverviewContext, TJourneyOverviewLocationState } from './types'

export const JourneyOverviewContext = createContext<TJourneyOverviewContext>({
  openState: false,
  toggleOpenState: () => null,
  navigateWithCurrentState: () => null,
})

export const JourneyOverviewProvider: FC = ({ children }) => {
  const { state } = useLocation() as TJourneyOverviewLocationState
  const { journeyOverviewOpenState: initialOpenState } = state || { journeyOverviewOpenState: false }

  const [openState, setOpenState] = useState(!!initialOpenState)
  const navigate = useNavigate()

  const value = useMemo(
    () => ({
      openState,
      toggleOpenState: () => {
        setOpenState(previousOpenState => !previousOpenState)
      },
      navigateWithCurrentState: (to: To) =>
        navigate(to, {
          state: { journeyOverviewOpenState: openState },
        }),
    }),
    [navigate, openState]
  )

  return <JourneyOverviewContext.Provider value={value}>{children}</JourneyOverviewContext.Provider>
}
