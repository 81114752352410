import tinycolor from 'tinycolor2'

import { colors } from './colors'
import { spacing } from './spacing'
import { utilities } from './utilities'

export const modal = {
  backgroundColor: tinycolor(colors.neutral10).setAlpha(0.5).toRgbString(),
  darkBackgroundColor: tinycolor(colors.neutral50).setAlpha(0.5).toRgbString(),
  backgroundOpacity: '1',
  standardModal: {
    backgroundColor: colors.white,
    borderColor: colors.secondary,
    borderRadius: utilities.border.radius,
    borderSize: utilities.border.width,
    closeRight: spacing.large,
    closeSpacingDesktop: spacing.extraExtraLarge,
    closeTop: spacing.medium,
    headerTopPaddingDesktop: spacing.medium,
    paddingDesktop: spacing.large,
    paddingTouch: spacing.medium,
  },
}
