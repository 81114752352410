import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { mainRootPath } from '@retire/navigation/paths'
import { NotFoundTemplate } from '@retire/templates/NotFoundTemplate'
import type { FC } from 'react'

export const DefaultNotFound: FC = () => {
  const { t } = useTranslationWithOptions('common')

  return (
    <NotFoundTemplate title={t('hygienePages.notFound.title', 'Default Not found title')} buttonPath={mainRootPath} />
  )
}
