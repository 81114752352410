import { Link } from '@retire/components/Link'
import { UnorderedList } from '@retire/components/List'
import { Segment } from '@retire/components/Segment'
import { Copy } from '@retire/components/Typography/Copy'
import { UserState } from '@retire/gql/__generated__/graphql'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUpdateUserState } from '@retire/hooks/useUpdateUserState'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { toCurrency } from '@retire/utils/currency'
import type { FC } from 'react'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Header } from '../../components/Header'

export const CheckItsRightForYou: FC = () => {
  const { t } = useTranslationWithOptions('suitabilityCheck')
  const navigate = useNavigate()
  const {
    moneyPurchaseAnnualAllowance: { amount: moneyPurchaseAnnualAllowanceAmount },
  } = useSettings()
  const { updateUserState, loading: userStateLoading } = useUpdateUserState()

  const onNext = useCallback(
    () => updateUserState({ variables: { state: UserState.IllustrationCreation } }),
    [updateUserState]
  )
  const onBack = useCallback(() => navigate(paths.suitabilityCheck.check), [navigate])

  return (
    <PageTemplate
      title={t('checkItsRightForYou.title', 'Check its right for you title')}
      subTitle={t('checkItsRightForYou.subTitle', 'Check its right for you subtitle')}
      nextText={t('checkItsRightForYou.actions.continue', 'Check its right for you continue')}
      onNext={onNext}
      backText={t('checkItsRightForYou.actions.back', 'Check its right for you back')}
      onBack={onBack}
      fullWidth
      loading={userStateLoading}
    >
      <Segment whiteBackground>
        <ContentWrapper>
          <Copy as="p">{t('checkItsRightForYou.body.pre', 'Check its right for you body pre')}</Copy>
          <Header>
            {t('checkItsRightForYou.body.willContributeToPension.heading', 'Keep paying into a pension scheme')}
          </Header>
          <Copy as="p">
            {t(
              'checkItsRightForYou.body.willContributeToPension.caption',
              'Keep paying into a pension scheme description - MoneyPurchaseAnnualAllowance amount = {{moneyPurchaseAnnualAllowanceAmount}}',
              {
                moneyPurchaseAnnualAllowanceAmount: toCurrency(moneyPurchaseAnnualAllowanceAmount, 0),
              }
            )}
          </Copy>
          <Header>{t('checkItsRightForYou.body.awareOfTaxFreeLumpSum.heading', 'Are you entitled to TFLS?')}</Header>
          <Copy as="p">{t('checkItsRightForYou.body.awareOfTaxFreeLumpSum.caption', 'TFLS description')}</Copy>
          <Header>
            {t(
              'checkItsRightForYou.body.needSpecialInvestmentOptions.heading',
              'Do you need control over yout investment options?'
            )}
          </Header>
          <Copy as="p">
            {t(
              'checkItsRightForYou.body.needSpecialInvestmentOptions.caption',
              'Special investment options description'
            )}
          </Copy>
          <Header>
            {t(
              'checkItsRightForYou.body.needShariahCompliantInvestmentOption.heading',
              'Do you need a Shariah-compliant investment option?'
            )}
          </Header>
          <Copy as="p">
            {t(
              'checkItsRightForYou.body.needShariahCompliantInvestmentOption.caption',
              'Shariah-compliant investment option description'
            )}
          </Copy>

          <Copy as="div">
            <Trans
              components={{
                list: <UnorderedList />,
                listItem: <li />,
                pensionWiseLink: (
                  <Link as="a" href={t('common:externalLinks.pensionWise.main', 'Pension Wise link')} target="_blank" />
                ),
                mapsLink: <Link as="a" href={t('common:externalLinks.maps', 'MaPS link')} target="_blank" />,
              }}
            >
              {t('checkItsRightForYou.body.help', 'Check its right for you help')}
            </Trans>
          </Copy>
        </ContentWrapper>
      </Segment>
    </PageTemplate>
  )
}
