import { DEFAULT_COUNTRY_CODE } from '@retire/constants/telephone'
import { i18n } from '@retire/i18n'
import type { TCountryCode } from '@retire/types/telephone'
import type { CountryCode } from 'libphonenumber-js'
import { isValidPhoneNumber } from 'libphonenumber-js'
import type { Message, ValidateResult } from 'react-hook-form'

import { DEFAULT_DECIMAL_DELIMITER, DEFAULT_THOUSAND_DELIMITER } from '../currency'
import { checkDate, EMPTY_DATE_VALUE, getDateObject, guessCurrentAge } from '../date'
import { generateDefaultTranslationString } from '../strings'
import { getFormattedTelephone } from '../telephone'
import type {
  TIsConditionTrueOrStrictlyLessThanMaxValueProps,
  TIsConditionTrueOrStrictlyMoreThanMinValueProps,
  TIsLessThanOrEqualToMaxValueProps,
  TIsMoreThanOrEqualToMinValueProps,
  TMaxValueOrValueProps,
  TMessage,
  TMinValueOrValueProps,
  TRule,
} from './types'

// need to be minValue or more
export const isMoreThanOrEqualToMinValue = ({ minValue, message }: TIsMoreThanOrEqualToMinValueProps): TRule => {
  return inputValue => {
    const numericInputValue = parseFloat(inputValue)
    return (!inputValue.length || numericInputValue >= minValue || message) as ValidateResult
  }
}

// need to be minValue or more
export const isConditionTrueOrStrictlyMoreThanMinValue = ({
  condition,
  minValue,
  message,
}: TIsConditionTrueOrStrictlyMoreThanMinValueProps): TRule => {
  return inputValue => {
    const numericInputValue = parseFloat(inputValue)
    return condition || ((!inputValue.length || numericInputValue > minValue || message) as ValidateResult)
  }
}

// need to be maxValue or below
export const isLessThanOrEqualToMaxValue = ({ maxValue, message }: TIsLessThanOrEqualToMaxValueProps): TRule => {
  return inputValue => {
    const numericInputValue = parseFloat(inputValue)
    return (!inputValue.length || numericInputValue <= maxValue || message) as ValidateResult
  }
}

// condition is true, or need to be maxValue or below
export const isConditionTrueOrStrictlyLessThanMaxValue = ({
  condition,
  maxValue,
  message,
}: TIsConditionTrueOrStrictlyLessThanMaxValueProps): TRule => {
  return inputValue => {
    const numericInputValue = parseFloat(inputValue)
    return condition || ((!inputValue.length || numericInputValue < maxValue || message) as ValidateResult)
  }
}

// need to be minValue or more, or equal to allowedValue
export const minValueOrValue = ({ minValue, allowedValue, message }: TMinValueOrValueProps): TRule => {
  if (minValue && allowedValue >= minValue) {
    console.warn(`Allowed value (${allowedValue}) should be bigger than min value (${minValue})`)
  }
  return inputValue => {
    const numericInputValue = parseFloat(inputValue)
    return (!inputValue.length ||
      numericInputValue === allowedValue ||
      numericInputValue >= minValue ||
      message) as ValidateResult
  }
}

// need to be maxValue or below, or equal to allowedValue
// the allowedValue represents the full pot balance amount
export const maxValueOrValue = ({ maxValue, allowedValue, message }: TMaxValueOrValueProps): TRule => {
  if (maxValue && allowedValue <= maxValue) {
    console.warn(`Allowed value (${allowedValue}) should be bigger than max value (${maxValue})`)
  }
  return inputValue => {
    const numericInputValue = parseFloat(inputValue)
    return (!inputValue.length ||
      numericInputValue === allowedValue ||
      numericInputValue <= maxValue ||
      message) as ValidateResult
  }
}

// must be a formatted number
export const isFormattedNumber = ({
  decimalDelimiter = DEFAULT_DECIMAL_DELIMITER,
  thousandDelimiter = DEFAULT_THOUSAND_DELIMITER,
  precision,
  message = i18n.t(
    'common:form.numberInput.validations.invalid',
    generateDefaultTranslationString('This is not a valid number')
  ),
}: {
  decimalDelimiter?: string
  thousandDelimiter?: string
  precision?: number
  message?: Message
}): TRule => {
  const regexPattern = `^((\\d+)([${thousandDelimiter}]\\d{3})*(?:[${decimalDelimiter}]\\d${
    precision === undefined ? '+' : `{0,${precision}}`
  })?)$`

  return inputValue => !inputValue || !inputValue.length || new RegExp(regexPattern).test(inputValue) || message
}

// must be a date
export const isDate = (message: Message): TRule => {
  return inputValue => {
    if (!inputValue || inputValue === EMPTY_DATE_VALUE) {
      return true
    }
    const dateValue = getDateObject(inputValue)
    const isNumberChecker = (value: string) => !!value && !Number.isNaN(Number(value))
    if (!isNumberChecker(dateValue.year) || !isNumberChecker(dateValue.month) || !isNumberChecker(dateValue.day)) {
      return message
    }
    return checkDate(dateValue) || message
  }
}

// date must be in the past
export const isDateInPast = (message: Message): TRule => {
  return inputValue => {
    const isValidOrMessage = isDate(message)(inputValue)
    if (isValidOrMessage !== true) {
      return isValidOrMessage
    }
    const today = new Date()
    const dateValue = getDateObject(inputValue)
    const birthDate = new Date(parseInt(dateValue.year), parseInt(dateValue.month) - 1, parseInt(dateValue.day))
    return birthDate.getTime() > today.getTime() ? message : true
  }
}

// must differ from a value
export const differentFromValue = ({
  forbiddenValue,
  message,
}: {
  forbiddenValue: string
  message: TMessage
}): TRule => {
  return inputValue => (inputValue !== forbiddenValue || message) as ValidateResult
}

// must match value
export const matchesValue = ({
  matchingValue,
  message,
  isCaseSensitive = true,
}: {
  matchingValue: string
  message: TMessage
  isCaseSensitive?: boolean
}): TRule => {
  return isCaseSensitive
    ? inputValue => (inputValue.trim() === matchingValue.trim() || message) as ValidateResult
    : inputValue =>
        (inputValue.trim().toLowerCase() === matchingValue.trim().toLowerCase() || message) as ValidateResult
}

// minimum age
export const minDateAge = ({ minAge, message }: { minAge: number; message: Message }): TRule => {
  return inputValue => {
    const age = guessCurrentAge(inputValue)
    return age >= minAge || message
  }
}

export const isEmail = (message: Message): TRule => {
  return inputValue => (inputValue && /^\S+@\S+\.+([a-zA-Z0-9]{2,4})+$/.test(inputValue.toString())) || message
}

export const isOfLength = ({ min, max = min, message }: { min: number; max: number; message: Message }): TRule => {
  return inputValue => (inputValue && inputValue.length >= min && inputValue.length <= max) || !inputValue || message
}

export const isTelephone = ({
  countryISOCode = DEFAULT_COUNTRY_CODE,
  message,
}: {
  countryISOCode?: TCountryCode
  message: Message
}): TRule => {
  return inputValue =>
    (inputValue &&
      /^-?\d+$/.test(inputValue) &&
      isValidPhoneNumber(getFormattedTelephone(inputValue, countryISOCode), countryISOCode as CountryCode)) ||
    message
}

export const isRequired = (message: Message = ''): TRule => {
  return inputValue => (inputValue && inputValue.trim().length > 0) || message
}
