import { Copy } from '@retire/components/Typography'
import { useTranslationWithOptions } from '@retire/hooks'
import type { FC } from 'react'

import { ConfirmModal } from '../../Modals'
import type { TSignOutConfirmation } from './types'

export const SignOutConfirmation: FC<TSignOutConfirmation> = ({ isOpen, onCancel, onConfirm, modalOpenerRef }) => {
  const { t } = useTranslationWithOptions('common')

  return (
    <ConfirmModal
      ariaLabelledBy="label-signout"
      ariaDescribedBy="desc-signout"
      modalOpenerRef={modalOpenerRef}
      isActive={isOpen}
      title={t('signOutModal.title')}
      dialogType="dialog"
      onClose={onCancel}
      onConfirm={onConfirm}
      confirmText={t('signOutModal.actions.ok')}
      closeText={t('signOutModal.actions.cancel')}
    >
      <Copy as="p">{t('signOutModal.description')}</Copy>
    </ConfirmModal>
  )
}
