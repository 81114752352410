import { Loading } from '@retire/components/Loading'
import { PotType } from '@retire/gql/__generated__/graphql'
import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import { usePotFunds } from '@retire/hooks/usePotFunds'
import { paths } from '@retire/navigation/paths'
import type { ReactElement } from 'react'
import { cloneElement } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'
import {
  isFlexibleIncomePotConfigured,
  isInheritancePotConfigured,
  isInheritancePotFundsConfigured,
  isLaterLifePotConfigured,
  isRainyDayPotConfigured,
  isTFLSConfigured,
} from '../../../utils'

/**
 * This component manages:
 * - load illustration data from query (so that it will be also cached)
 * - check that current path is accessible to current illustration data
 *   if yes: do nothing
 *   if not: redirect to the main "journey" path
 */
export const JourneyAuthOutlet = ({ children }: { children?: ReactElement }) => {
  const { pathname } = useLocation()
  const isEnsignUser = useIsEnsignUser()

  const { illustrationPlanData, loadingIllustrationPlanData } = useIllustrationPlanData()
  const { availableFunds, loading: loadingAvailableFunds } = usePotFunds(PotType.Inheritance)

  if (loadingIllustrationPlanData || loadingAvailableFunds) {
    return <Loading />
  }

  const { taxFreeLumpSumWithdrawalState, flexibleIncomeAmount, laterLifeAmount, inheritanceAmount, rainyDayAmount } =
    illustrationPlanData

  if (pathname === paths.illustration.journey) {
    if (isTFLSConfigured(illustrationPlanData)) {
      // redirect user to the "continue or restart" path, if there's at least a decided TFLS
      return <Navigate to={paths.illustration.journeyContinueOrRestart} />
    }

    // redirect user to the "intro" path, if there's no existing plan yet
    return <Navigate to={paths.illustration.journeyIntro} />
  }

  // current path is accessible for current illustration data
  let isPathAccessible = false
  switch (pathname) {
    case paths.illustration.journeyIntro:
    case paths.illustration.journeyTaxFreeLumpSumWithdrawal:
      isPathAccessible = true
      break
    case paths.illustration.journeyContinueOrRestart:
    case paths.illustration.journeyPotsIntro:
    case paths.illustration.journeyIncomePotsIntro:
    case paths.illustration.journeyFlexibleIncomePotInsufficientSavings:
    case paths.illustration.journeyFlexibleIncomePotIntro:
    case paths.illustration.journeyFlexibleIncomePotConfiguration:
      isPathAccessible = isTFLSConfigured({ taxFreeLumpSumWithdrawalState })
      break
    case paths.illustration.journeyLaterLifePotInsufficientSavings:
    case paths.illustration.journeyLaterLifePotIntro:
    case paths.illustration.journeyLaterLifePotConfiguration:
      isPathAccessible =
        isTFLSConfigured({ taxFreeLumpSumWithdrawalState }) && isFlexibleIncomePotConfigured({ flexibleIncomeAmount })
      break
    case paths.illustration.journeySavingsPotsIntro:
    case paths.illustration.journeyInheritancePotInsufficientSavings:
    case paths.illustration.journeyInheritancePotIntro:
    case paths.illustration.journeyInheritancePotConfiguration:
      isPathAccessible =
        isTFLSConfigured({ taxFreeLumpSumWithdrawalState }) &&
        isFlexibleIncomePotConfigured({ flexibleIncomeAmount }) &&
        isLaterLifePotConfigured({ laterLifeAmount })
      break
    case paths.illustration.journeyInheritancePotFunds:
      isPathAccessible =
        isEnsignUser &&
        isTFLSConfigured({ taxFreeLumpSumWithdrawalState }) &&
        isFlexibleIncomePotConfigured({ flexibleIncomeAmount }) &&
        isLaterLifePotConfigured({ laterLifeAmount }) &&
        isInheritancePotConfigured({ inheritanceAmount })
      break
    case paths.illustration.journeyRainyDayPotInsufficientSavings:
    case paths.illustration.journeyRainyDayPotIntro:
    case paths.illustration.journeyRainyDayPotConfiguration:
      isPathAccessible =
        isTFLSConfigured({ taxFreeLumpSumWithdrawalState }) &&
        isFlexibleIncomePotConfigured({ flexibleIncomeAmount }) &&
        isLaterLifePotConfigured({ laterLifeAmount }) &&
        isInheritancePotConfigured({ inheritanceAmount }) &&
        (isEnsignUser ? isInheritancePotFundsConfigured({ availableFunds, inheritanceAmount }) : true)
      break
    case paths.illustration.journeyReady:
      isPathAccessible =
        isTFLSConfigured({ taxFreeLumpSumWithdrawalState }) &&
        isFlexibleIncomePotConfigured({ flexibleIncomeAmount }) &&
        isLaterLifePotConfigured({ laterLifeAmount }) &&
        isInheritancePotConfigured({ inheritanceAmount }) &&
        isRainyDayPotConfigured({ rainyDayAmount })
      break
  }

  if (isPathAccessible) {
    if (children) {
      return cloneElement(children, { children: <Outlet /> })
    }
    return <Outlet />
  }

  // redirect user to the main "journey" path
  return <Navigate to={paths.illustration.journey} />
}
