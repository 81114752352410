import { OrderedList } from '@retire/components/List'
import { Segment } from '@retire/components/Segment'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { Headline } from '@retire/components/Typography/Headline'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { PageTemplate } from '@retire/templates/PageTemplate'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { TypographyColor } from '@retire/theme/colors'
import { HeadlineLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { useCallback, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'

export const Intro: FC = () => {
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()

  const {
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
    loadingIllustrationPlanData,
  } = useIllustrationPlanData()

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData()
  }, [removeSubsequentIllustrationJourneyStepsData])

  const onNext = useCallback(() => navigate(paths.illustration.journeyTaxFreeLumpSumWithdrawal), [navigate])

  return (
    <PageTemplate
      title={t('pageTitle.journey', 'Intro step title')}
      nextText={t('intro.actions.continue', 'Get started')}
      onNext={onNext}
      fullWidth
      loading={loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData}
    >
      <Segment whiteBackground>
        <ContentWrapper>
          <Headline level={HeadlineLevel.h2} as="h2" color={TypographyColor.primary}>
            {t('intro.title', 'Journey intro title')}
          </Headline>
          <Spacer bottom="medium" />
          <Copy as="div">
            <Trans components={{ list: <OrderedList />, listItem: <li /> }}>
              {t('intro.description', 'Journey intro description')}
            </Trans>
          </Copy>
        </ContentWrapper>
      </Segment>
    </PageTemplate>
  )
}
