import { useProgressHeader } from '@retire/hooks/useProgressHeader'

import type { TUseJourneyHeader } from './types'
import { getIllustrationStepIndex, getIllustrationTotalSteps } from './utils'

export const useJourneyHeader: TUseJourneyHeader = ({ currentStep }) => {
  const currentStepIndex = getIllustrationStepIndex(currentStep)
  const totalSteps = getIllustrationTotalSteps()

  useProgressHeader({
    currentStep: currentStepIndex + 1,
    totalSteps: totalSteps,
    displayedTotalSteps: totalSteps - 1,
  })
}
