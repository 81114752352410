import { graphql } from '@retire/gql/__generated__'

export const SELECT_FUND_FOR_POT_MUTATION = graphql(`
  mutation selectFundForPot($potType: PotType!, $fundId: ID!) {
    selectFundForPot(potType: $potType, fundId: $fundId) {
      id
      name
    }
  }
`)
