import { graphql } from '@retire/gql/__generated__/gql'

export const WITHDRAW_MONEY_MUTATION = graphql(`
  mutation withdrawMoney($amount: Int!) {
    createWithdrawalInstruction(amount: $amount) {
      id
      ...moneyInstruction__Instruction
    }
  }
`)

export const MOVE_MONEY_MUTATION = graphql(`
  mutation moveMoney($instructions: [MoveMoneyInstructionAttributes!]!) {
    createMoveMoneyInstructions(instructions: $instructions) {
      id
      ...moneyInstruction__Instruction
    }
  }
`)

export const REGULAR_INCOME_PAUSED_MUTATION = graphql(`
  mutation pauseRegularIncome($regularIncomePaused: Boolean) {
    userUpdate(input: { regularIncomePaused: $regularIncomePaused }) {
      id
      regularIncomePaused
    }
  }
`)
