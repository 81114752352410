import { css, styled } from '@retire/theme/styledComponents'
import type { TPickAndRequired } from '@retire/types'

import { Copy } from '../../Typography'
import type { TTag } from './types'

export const SContainer = styled.span<TPickAndRequired<TTag, 'variant'>>`
  display: inline-block;

  ${({ variant, theme: { tag } }) => css`
    border-radius: ${tag.borderRadius};
    background: ${tag[variant].backgroundColor};
    border: 2px solid ${tag[variant].borderColor};
  `}
`

export const SText = styled(Copy)`
  display: block;
  font-weight: ${({ theme: { utilities } }) => utilities.weight.bold};
  white-space: nowrap;

  ${({
    theme: {
      tag: { color, fontSize, lineHeight },
    },
  }) => css`
    color: ${color};
    font-size: ${fontSize};
    line-height: ${lineHeight};
  `}
`
