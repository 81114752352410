import type { FC } from 'react'

import { Icon } from '../../../Icon'
import { SIconWrapper } from './presentation.styled'
import type { TArrowIcon } from './types'

export const ArrowIcon: FC<TArrowIcon> = ({ iconColor }) => (
  <SIconWrapper>
    <Icon iconType="ArrowDown" iconColors={{ base: iconColor }} />
  </SIconWrapper>
)
