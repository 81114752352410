import { AuthOutlet } from '@retire/navigation/AuthOutlet'
import { paths } from '@retire/navigation/paths'
import type { RouteObject } from 'react-router-dom'

import {
  AccountManagement,
  ApplicationClosed,
  Beneficiaries,
  ContactDetails,
  ContactSupport,
  DeniedAuthentication,
} from './pages'

export const accountRoutes: RouteObject[] = [
  {
    path: paths.account.manage,
    element: (
      <AuthOutlet>
        <AccountManagement />
      </AuthOutlet>
    ),
  },
  {
    path: paths.account.manageContactDetails,
    element: (
      <AuthOutlet>
        <ContactDetails />
      </AuthOutlet>
    ),
  },
  {
    path: paths.account.manageBeneficiaries,
    element: (
      <AuthOutlet>
        <Beneficiaries />
      </AuthOutlet>
    ),
  },
  { path: paths.account.closed, element: <ApplicationClosed /> },
  { path: paths.account.contactSupport, element: <ContactSupport /> },
  { path: paths.account.deniedAuthentication, element: <DeniedAuthentication /> },
]
