import { PageBarColorContext } from '@retire/layout/PageLayout'
import type { TAllPotVariants } from '@retire/types'
import { getPotColor } from '@retire/utils'
import { useContext, useEffect } from 'react'

export const usePageBarPotColorEffect = (variant: TAllPotVariants) => {
  // update top-header layout color
  const { setColor } = useContext(PageBarColorContext)

  useEffect(() => {
    setColor(getPotColor(variant))
    return () => setColor('')
  }, [variant, setColor])
}
