import { FormOptionsGroup } from '@retire/components/Forms/OptionsGroup'
import { Spacer } from '@retire/components/Spacer'
import { Copy } from '@retire/components/Typography/Copy'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { paths } from '@retire/navigation/paths'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import { CopyLevel } from '@retire/theme/typography'
import type { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { StepWrapper } from '../../components/StepWrapper'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'
import { SList } from './presentation.styled'
import type { TEmailFormFields } from './types'

export const EmailCheck: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.email })
  const { t } = useTranslationWithOptions('enrolment')
  const {
    data: { email },
    loading,
  } = useUserDetails()

  const inputName = 'emailType'
  const methods = useForm<TEmailFormFields>()
  const option = methods.watch(inputName)

  return (
    <FormProvider {...methods}>
      <StepWrapper
        title={t('emailCheck.title', 'Email check')}
        nextText={t('emailCheck.actions.next', 'Next')}
        backText={t('general.actions.back', 'Back')}
        nextUrl={option === 'personal' ? paths.enrolment.personalDetails : paths.enrolment.emailChanging}
        backUrl={paths.enrolment.introduction}
        disableNext={!option}
        loading={loading}
      >
        <ContentWrapper>
          <Spacer bottom="extraLarge">
            <Copy as="p" level={CopyLevel.body2}>
              {t('emailCheck.description', 'Description')}
            </Copy>
            <Copy as="p" level={CopyLevel.body1}>
              {email}
            </Copy>
          </Spacer>
          <Spacer bottom="extraLarge">
            <Copy as="div" level={CopyLevel.body2}>
              <Trans
                components={{ list: <SList />, listItem: <li />, small: <Copy as="span" level={CopyLevel.body1} /> }}
              >
                {t('emailCheck.usage', 'Email usage')}
              </Trans>
            </Copy>
          </Spacer>
          <Spacer bottom="medium">
            <Copy as="p" level={CopyLevel.body2}>
              {t('emailCheck.question', 'Question', { email: email })}
            </Copy>
          </Spacer>
          <FormOptionsGroup
            name={inputName}
            fullWidth
            options={[
              {
                label: t('emailCheck.options.personal.label', 'Personal email'),
                value: 'personal',
              },
              {
                description: t('emailCheck.options.work.description', 'Work email description'),
                label: t('emailCheck.options.work.label', 'Work email'),
                value: 'work',
              },
            ]}
          />
        </ContentWrapper>
      </StepWrapper>
    </FormProvider>
  )
}
