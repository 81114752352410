import type { FC } from 'react'
import { components } from 'react-select'

import { ArrowIcon } from '../../../ArrowIcon'
import type { TDropdownIndicator } from './types'

export const DropdownIndicator: FC<TDropdownIndicator> = ({ iconColor, ...props }) => (
  <components.DropdownIndicator {...props}>
    <ArrowIcon iconColor={iconColor} />
  </components.DropdownIndicator>
)
