import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Info: FC<TIconColors> = props => {
  const {
    colors: { info, white },
  } = useThemeContext()
  const { base = info } = props

  return (
    <svg x="0px" y="0px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Info" />
      <g>
        <defs>
          <rect x="-507.8" y="-2790" width="1440" height="4347" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
      </g>
      <g>
        <defs>
          <rect x="-507.8" y="-2790" width="1440" height="4347" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
      </g>
      <g>
        <defs>
          <ellipse cx="-31" cy="11" rx="11" ry="10.9" />
        </defs>
        <clipPath>
          <use style={{ overflow: 'visible' }} />
        </clipPath>
        <g>
          <defs>
            <rect x="-232.8" y="-735.8" width="1453.7" height="1364.1" />
          </defs>
          <clipPath>
            <use style={{ overflow: 'visible' }} />
          </clipPath>
        </g>
      </g>
      <path style={{ fill: base }} d="M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0z" />
      <rect x="9.8" y="9.2" style={{ fill: white }} width="2.4" height="8.1" />
      <circle style={{ fill: white }} cx="11" cy="6.3" r="1.3" />
    </svg>
  )
}
