export const BANK_OR_SOCIETY_ACCOUNT = 'bankOrSocietyAccount'
export const FROM_INPUT_NAME = 'fromPot'
export const TO_INPUT_NAME = 'toPotOrAccount'
export const AMOUNT_OPTIONS_GROUP_NAME = 'moveMoneyMethod'
export const AMOUNT_INPUT_NAME = 'amount'

export enum AmountToMoveMethod {
  customAmount = 'customAmount',
  fullPotBalance = 'fullPotBalance',
}
