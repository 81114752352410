import { styled } from '@retire/theme/styledComponents'

import type { TTextInput } from './types'

export const SWrapper = styled.div<{ withPrefix: boolean; withSuffix: boolean; wrapperWidth?: TTextInput['width'] }>`
  display: flex;
  position: relative;
  align-items: flex-end;

  ${({ wrapperWidth }) => (wrapperWidth ? `width: ${wrapperWidth};` : '')}
  ${({
    withPrefix,
    withSuffix,
    theme: {
      forms: { shared, input },
    },
  }) =>
    withPrefix || withSuffix
      ? `
          > i {
            position: absolute;
            font-size: ${input.fontSize};
            color: ${shared.states.default.color};
            padding: ${input.padding};
            ${withPrefix ? `padding-right: ${input.paddingRight};` : ''}
            transform: translateY(-50%);
            top: 50%;
            ${withSuffix ? 'right: 0;' : ''}
          }
          input {
            ${withPrefix ? `padding-left: ${input.prefix.paddingSides};` : ''}
            ${withSuffix ? `padding-right: ${input.prefix.paddingSides};` : ''}
            &::-webkit-inner-spin-button {
              margin: 0;
              -webkit-appearance: none;
            }
          }
        `
      : ''}
`
