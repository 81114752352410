import type { FC } from 'react'

import { Currency } from './presentation'
import { SFraction } from './presentation.styled'
import type { TCurrencyComponent } from './types'

const PotCurrency: FC<TCurrencyComponent> = props => <Currency {...props} CustomFraction={SFraction} />

export { Currency, PotCurrency, SFraction }
export type { TCurrencyComponent }
