import type { FC } from 'react'
import { useCallback } from 'react'

import { ArrowIcon } from '../ArrowIcon'
import type { TSelect } from '../types'
import {
  SNativeSelectSelectedValue,
  SNativeSelectSelectedValueWrapper,
  SNativeSelectWrapper,
} from './presentation.styled'

export const NativeSelect: FC<TSelect> = ({
  iconColor,
  disabled,
  ariaDescribedBy,
  ariaLabelledBy,
  ariaInvalid,
  selectedOption,
  renderSelectedValue,
  options,
  placeholder,
  onChange,
}) => {
  const onSelectChange = useCallback(({ target: { value } }) => onChange && onChange(value), [onChange])

  return (
    <SNativeSelectWrapper>
      <select
        onChange={onSelectChange}
        value={selectedOption?.value}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
        aria-invalid={ariaInvalid}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
      <SNativeSelectSelectedValueWrapper>
        <SNativeSelectSelectedValue>
          {selectedOption?.value && renderSelectedValue
            ? renderSelectedValue(selectedOption?.value)
            : selectedOption?.label}
        </SNativeSelectSelectedValue>
      </SNativeSelectSelectedValueWrapper>
      <ArrowIcon iconColor={iconColor} />
    </SNativeSelectWrapper>
  )
}
