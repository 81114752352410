import type { FC } from 'react'

import { useSyncPhoneValidations } from './hooks'
import { PhoneInput } from './presentation'
import type { TFormPhoneInput } from './types'

export const PhoneInputContainer: FC<TFormPhoneInput> = ({ name, countryCodeName, ...props }) => {
  useSyncPhoneValidations(name, countryCodeName)
  return <PhoneInput name={name} countryCodeName={countryCodeName} {...props} />
}
