import { styled } from '@retire/theme/styledComponents'

import {
  SDropdownWrapper as SDefaultDropdownWrapper,
  SNativeSelectSelectedValueWrapper,
  SOuterWrapper as SDefaultOuterWrapper,
} from '../Dropdown'
import { SInput } from '../index.styled'
import type { TSInput } from '../types'

export const CustomInput = styled(SInput)<TSInput>`
  display: flex;
  align-self: stretch;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

export const SOuterWrapper = styled(SDefaultOuterWrapper)`
  height: unset;
`

export const SDropdownWrapper = styled(SDefaultDropdownWrapper)`
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${({ theme, disabled, isOpen }) => {
    return `
      ${
        !isOpen &&
        !disabled &&
        `
          &:focus, &:focus-within, &:hover {
            border-right-width: ${theme.forms.shared.border.width};
          }
        `
      }
    `
  }}

  ${SNativeSelectSelectedValueWrapper} {
    position: relative;
    white-space: nowrap;
    margin-right: ${props => props.theme.forms.dropdown.iconSize};
  }
`
