import { Divider } from '@retire/components/Divider'
import type { TCopy, THeadline } from '@retire/components/Typography'
import { Copy, Headline } from '@retire/components/Typography'
import type { theme } from '@retire/theme'
import { Breakpoint } from '@retire/theme'
import type { StyledComponent } from '@retire/theme/styledComponents'
import { css, styled } from '@retire/theme/styledComponents'
import { mediaStyles } from '@retire/utils'

export const SCopy = styled(((props: TCopy) => <Copy {...props} />) as StyledComponent<'div', typeof theme, TCopy>)

const SHeadline = styled(((props: THeadline) => <Headline {...props} />) as StyledComponent<
  'div',
  typeof theme,
  THeadline
>)

export const SFooter = styled.footer`
  ${({
    theme: {
      footer: { color, backgroundColor, standard, mobile },
    },
  }) => css`
    align-self: stretch;
    color: ${color};
    background-color: ${backgroundColor};
    padding: ${standard.spacing.big} ${standard.spacing.small};

    ${mediaStyles({ padding: `${mobile.spacing.big} ${mobile.spacing.small}` }, { max: Breakpoint.small })}
  `}
`

export const SFooterCopy = SCopy`
  font-size: ${props => props.theme.footer.smallText.fontSize};
  color: ${props => props.theme.footer.color};
`

export const SFooterHeadline = SHeadline`
  ${({
    theme: {
      footer: { color, mobile },
    },
  }) => css`
    color: ${color};

    ${mediaStyles(
      { fontSize: mobile.sectionTitle.fontSize, lineHeight: mobile.sectionTitle.lineHeight },
      {
        max: Breakpoint.small,
      }
    )}
  `}
`

export const SHR = styled(Divider)`
  ${({
    theme: {
      footer: { hrColor, standard, mobile },
    },
  }) => css`
    color: ${hrColor};
    background-color: ${hrColor};
    margin: ${standard.spacing.big} 0;

    ${mediaStyles({ margin: `${mobile.spacing.medium} 0` }, { max: Breakpoint.small })}
  `}
`
