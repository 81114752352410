import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { ConfirmModal } from '@retire/layout/Modals'
import type { FC } from 'react'

import { Copy } from '../Typography/Copy'
import type { TSimpleAlert } from './types'

export const SimpleAlert: FC<TSimpleAlert> = ({ onClose, children, ...props }) => {
  const { t } = useTranslationWithOptions('common')

  return (
    <ConfirmModal
      {...props}
      ariaLabelledBy="label-simplealert"
      ariaDescribedBy="desc-simplealert"
      isActive
      confirmText={t('modal.close', 'Close modal button')}
      onClose={onClose}
      onConfirm={onClose}
      dialogType="alertdialog"
      theme="dark"
    >
      <Copy as="span">{children}</Copy>
    </ConfirmModal>
  )
}
