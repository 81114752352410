import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const External: FC<TIconColors> = props => {
  const {
    colors: { footerLink },
  } = useThemeContext()
  const { base = footerLink } = props

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="External" />
      <path
        d="M11.2534 2.79333H14.7534V6.29333M7.75342 9.79333L14.5034 3.04333M8.25342 4.79333H4.00342C3.6719 4.79333 3.35395 4.92503 3.11953 5.15945C2.88511 5.39387 2.75342 5.71181 2.75342 6.04333V13.5433C2.75342 13.8749 2.88511 14.1928 3.11953 14.4272C3.35395 14.6616 3.6719 14.7933 4.00342 14.7933H11.5034C11.6676 14.7933 11.8301 14.761 11.9818 14.6982C12.1334 14.6354 12.2712 14.5433 12.3873 14.4272C12.5034 14.3111 12.5954 14.1733 12.6583 14.0217C12.7211 13.87 12.7534 13.7075 12.7534 13.5433V8.79333"
        stroke={base}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
