import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import { IconTitle } from './IconTitle'

export const Error: FC<TIconColors & { title?: string }> = ({ title, ...props }) => {
  const {
    colors: { error },
  } = useThemeContext()
  const { base = error } = props

  return (
    <svg x="0px" y="0px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Error" title={title} />
      <path
        style={{ fill: base }}
        d="M11,22c6.1,0,11-4.9,11-11S17.1,0,11,0S0,4.9,0,11S4.9,22,11,22z M9.8,4.6h2.4v8.1H9.8V4.6z M10,14.9
   c0.3-0.2,0.6-0.4,1-0.3c0.4,0,0.7,0.1,1,0.3c0.2,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.4,0.9c-0.3,0.2-0.6,0.4-1,0.3
   c-0.4,0-0.7-0.1-1-0.3C9.5,16.1,9.5,15.4,10,14.9C10,14.9,10,14.9,10,14.9L10,14.9z"
      />
    </svg>
  )
}
