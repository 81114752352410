import { Overview } from '@retire/components/Overview'
import { PotType } from '@retire/constants/pots'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { sentenceCase } from '@retire/utils/strings'
import type { FC } from 'react'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { useIllustrationPlanData } from '../../../hooks/useIllustrationPlanData'
import { useJourneyOverviewContext } from '../../../hooks/useJourneyOverviewContext'
import {
  isFlexibleIncomePotConfigured,
  isInheritancePotConfigured,
  isLaterLifePotConfigured,
  isRainyDayPotConfigured,
  isTFLSConfigured,
} from '../../../utils'
import { IncomePotsStepDetails } from '../../JourneyOverview/IncomePotsStepDetails'
import { SavingsPotsStepDetails } from '../../JourneyOverview/SavingsPotsStepDetails'
import { TFLSStepDetails } from '../../JourneyOverview/TFLSStepDetails'
import { TotalSavingsDetails } from '../../JourneyOverview/TotalSavingsDetails'
import type { TJourneyOverview } from '../../JourneyOverview/types'
import { RemainingAmountDetails } from './RemainingAmountDetails'

export const JourneyOverview: FC<TJourneyOverview> = ({ title, ...overviewProps }) => {
  const { t } = useTranslationWithOptions('illustration')
  const { pathname } = useLocation()
  const { openState, toggleOpenState } = useJourneyOverviewContext()

  const {
    illustrationPlanData: {
      flexibleIncomeAmount,
      inheritanceAmount,
      laterLifeAmount,
      rainyDayAmount,
      remainingSavings,
      taxFreeLumpSumWithdrawalState,
      valuationAmount,
    },
    loadingIllustrationPlanData,
  } = useIllustrationPlanData()

  const isFlexibleIncomePotStepCompleted = useMemo(
    () => isFlexibleIncomePotConfigured({ flexibleIncomeAmount }),
    [flexibleIncomeAmount]
  )
  const isIncomePotsStepCompleted = useMemo(() => isLaterLifePotConfigured({ laterLifeAmount }), [laterLifeAmount])
  const isIncomePotsStepInProgress = useMemo(
    () =>
      !!matchPath(`${paths.illustration.journeyIncomePotsIntro}/*`, pathname) ||
      (paths.illustration.journeyContinueOrRestart === pathname && isFlexibleIncomePotStepCompleted),
    [isFlexibleIncomePotStepCompleted, pathname]
  )

  const isInheritancePotStepCompleted = useMemo(
    () => isInheritancePotConfigured({ inheritanceAmount }),
    [inheritanceAmount]
  )
  const isSavingsPotsStepCompleted = useMemo(() => isRainyDayPotConfigured({ rainyDayAmount }), [rainyDayAmount])
  const isSavingsPotsStepInProgress = useMemo(
    () =>
      !!matchPath(`${paths.illustration.journeySavingsPotsIntro}/*`, pathname) ||
      (paths.illustration.journeyContinueOrRestart === pathname && isInheritancePotStepCompleted),
    [isInheritancePotStepCompleted, pathname]
  )

  const sections = useMemo(
    () => [
      {
        key: 'total',
        name: t('overview.steps.totalSavings.name', 'Total savings'),
        details: <TotalSavingsDetails valuationAmount={valuationAmount} />,
      },
      {
        key: 'tfls',
        name: t('overview.steps.tfls.name', 'TFLS'),
        isCompleted: isTFLSConfigured({ taxFreeLumpSumWithdrawalState }),
        isInProgress: !!matchPath(paths.illustration.journeyTaxFreeLumpSumWithdrawal, pathname),
        isStep: true,
        details: (
          <TFLSStepDetails
            valuationAmount={valuationAmount}
            taxFreeLumpSumWithdrawalState={taxFreeLumpSumWithdrawalState}
          />
        ),
      },
      {
        key: PotType.income,
        name: t('overview.steps.incomePots.name', 'Income pots', `${sentenceCase(PotType.income)} pots`),
        isStep: true,
        isCompleted: isIncomePotsStepCompleted,
        isInProgress: isIncomePotsStepInProgress,
        details: (
          <IncomePotsStepDetails
            flexibleIncomeAmount={flexibleIncomeAmount}
            laterLifeAmount={laterLifeAmount}
            isIncomePotsStepCompleted={isIncomePotsStepCompleted}
            isIncomePotsStepInProgress={isIncomePotsStepInProgress}
          />
        ),
        wrapOnDesktop: isFlexibleIncomePotStepCompleted,
      },
      {
        key: PotType.savings,
        name: t('overview.steps.savingsPots.name', 'Savings pots', `${sentenceCase(PotType.savings)} pots`),
        isStep: true,
        isCompleted: isSavingsPotsStepCompleted,
        isInProgress: isSavingsPotsStepInProgress,
        details: (
          <SavingsPotsStepDetails
            inheritanceAmount={inheritanceAmount}
            rainyDayAmount={rainyDayAmount}
            isSavingsPotsStepCompleted={isSavingsPotsStepCompleted}
            isSavingsPotsStepInProgress={isSavingsPotsStepInProgress}
          />
        ),
        wrapOnDesktop: isInheritancePotStepCompleted,
      },
      {
        key: 'left',
        name: t('overview.steps.left.name', 'Left to use'),
        details: <RemainingAmountDetails remainingSavings={remainingSavings} />,
      },
    ],
    [
      flexibleIncomeAmount,
      inheritanceAmount,
      isFlexibleIncomePotStepCompleted,
      isIncomePotsStepCompleted,
      isIncomePotsStepInProgress,
      isInheritancePotStepCompleted,
      isSavingsPotsStepCompleted,
      isSavingsPotsStepInProgress,
      laterLifeAmount,
      pathname,
      rainyDayAmount,
      remainingSavings,
      t,
      taxFreeLumpSumWithdrawalState,
      valuationAmount,
    ]
  )

  if (loadingIllustrationPlanData) {
    return null
  }

  return (
    <Overview
      {...overviewProps}
      expanded={openState}
      onClick={toggleOpenState}
      title={title || t('overview.title', 'Overview')}
      expandText={t('overview.show', 'Show overview')}
      collapseText={t('overview.hide', 'Hide overview')}
      sections={sections}
    />
  )
}
