import { Copy } from '@retire/components'
import { HeadlineLevel } from '@retire/theme'
import { styled } from '@retire/theme/styledComponents'

export const SMessage = styled(Copy)`
  ${({
    theme: {
      colors,
      spacing,
      typography: { headline },
      utilities,
    },
  }) => `
    margin-top: ${spacing.extraLarge};

    p {
      margin-top: ${spacing.large};

      a {
        color: ${colors.hyperlink};
        font-weight: ${utilities.weight.bold};
      }
    }

    ul {
      list-style-type: disc;
      padding-left: ${spacing.extraLarge};
      margin-top: ${spacing.medium};
    }

    li {
      margin-top: ${spacing.mediumSmall};
    }

    h4 {
      margin-top: ${spacing.extraLarge};
      font-family: ${headline.level[HeadlineLevel.h4].family};
      font-size: ${headline.level[HeadlineLevel.h4].size};
      line-height: ${headline.level[HeadlineLevel.h4].lineHeight};
      letter-spacing: ${headline.level[HeadlineLevel.h4].letterSpacing};
      font-weight: ${headline.level[HeadlineLevel.h4].weight};
      color: ${headline.color};
    }
  `}
`
