import { SuspenseLoader } from '@retire/components'
import type { GetMessagesQuery, SentMessage } from '@retire/gql/__generated__/graphql'
import { useQuerySelector } from '@retire/hooks/useQuerySelector'
import { PathParam, paths } from '@retire/navigation/paths'
import type { FC } from 'react'
import { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { MESSAGES_QUERY } from '../../gql/queries'
import { Messages } from './presentation'

export const MessagesContainer: FC = () => {
  const navigate = useNavigate()
  const { data: messages, loading } = useQuerySelector<GetMessagesQuery, SentMessage[]>(
    { query: MESSAGES_QUERY },
    ({ sentMessages }) =>
      sentMessages.map(({ sentAt, ...message }) => ({
        ...message,
        sentAt: new Date(sentAt).getTime(),
      }))
  )
  const onViewMessage = useCallback(
    messageId => navigate(generatePath(paths.inbox.message, { [PathParam.message]: messageId })),
    [navigate]
  )

  return (
    <SuspenseLoader isLoading={loading}>
      <Messages messages={messages || []} onViewMessage={onViewMessage} />
    </SuspenseLoader>
  )
}
