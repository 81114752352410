import { FormOptionsGroup } from '@retire/components/Forms/OptionsGroup'
import { FundCardContent } from '@retire/components/FundCardContent'
import { UnorderedList } from '@retire/components/List'
import { SelectableCard } from '@retire/components/SelectableCard/presentation'
import { PotName } from '@retire/constants/pots'
import { useFundDetails } from '@retire/hooks/useFundDetails'
import { useGetPotDisplayName } from '@retire/hooks/useGetPotDisplayName'
import { useIsEnsignUser } from '@retire/hooks/useIsEnsignUser'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { ContentWrapper } from '@retire/templates/PageTemplate/ContentWrapper'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { JourneyStepLayout } from '../../../../components/Journey/JourneyStepLayout'
import { useIllustrationPlanData } from '../../../../hooks/useIllustrationPlanData'
import { useJourneyHeader } from '../../../../hooks/useJourneyHeader'
import { IllustrationStep } from '../../../../hooks/useJourneyHeader/constants'
import { usePotInsufficientSavings } from '../../../../hooks/usePotInsufficientSavings'
import type { TInheritancePotIntroFormFields } from './types'

export const InheritancePotIntro: FC = () => {
  useJourneyHeader({ currentStep: IllustrationStep.savingsPots })
  const { t } = useTranslationWithOptions('illustration')
  const navigate = useNavigate()
  const { getPotDisplayName } = useGetPotDisplayName()

  const {
    loadingIllustrationPlanData,
    illustrationPlanData,
    savePartialIllustrationPlanData,
    removeSubsequentIllustrationJourneyStepsData,
    removingSubsequentIllustrationJourneyStepsData,
  } = useIllustrationPlanData()

  useEffect(() => {
    void removeSubsequentIllustrationJourneyStepsData({
      inheritanceAmount: null,
      rainyDayAmount: null,
    })
  }, [removeSubsequentIllustrationJourneyStepsData])

  const isEnsignUser = useIsEnsignUser()
  const { fundDetails, loading: loadingFundDetails } = useFundDetails(PotName.inheritance, isEnsignUser)

  usePotInsufficientSavings({
    remainingSavings: illustrationPlanData.remainingSavings,
    potName: PotName.inheritance,
    insufficientSavingsPath: paths.illustration.journeyInheritancePotInsufficientSavings,
    skip: loadingIllustrationPlanData || removingSubsequentIllustrationJourneyStepsData,
  })

  const options = useMemo(
    () => [
      {
        label: t('steps.potSteps.potIntro.actions.usePot', 'Use pot'),
        value: true,
      },
      {
        label: t('steps.potSteps.potIntro.actions.doNotUsePot', 'Do not use pot'),
        value: false,
      },
    ],
    [t]
  )

  const inputName = 'option'
  const methods = useForm<TInheritancePotIntroFormFields>()
  const { watch } = methods
  const option = watch(inputName)

  const onNext = useCallback(async () => {
    if (option) {
      navigate(paths.illustration.journeyInheritancePotConfiguration)
    } else {
      await savePartialIllustrationPlanData(
        {
          ...illustrationPlanData,
          inheritanceAmount: 0,
        },
        paths.illustration.journeyRainyDayPotIntro
      )
    }
  }, [illustrationPlanData, navigate, option, savePartialIllustrationPlanData])
  const onBack = useCallback(() => navigate(paths.illustration.journeySavingsPotsIntro), [navigate])

  return (
    <FormProvider {...methods}>
      <JourneyStepLayout
        title={t('steps.potSteps.potIntro.title', `Pot intro title ${PotName.inheritance}`, {
          potName: getPotDisplayName(PotName.inheritance).toLowerCase(),
        })}
        description={
          <Trans
            components={{
              list: <UnorderedList />,
              listItem: <li />,
            }}
          >
            {t(
              `steps.potSteps.potIntro.description.${PotName.inheritance}.${
                isEnsignUser ? 'ensignUser' : 'standardUser'
              }`,
              `Pot intro description ${PotName.inheritance} for ${isEnsignUser ? 'Ensign user' : 'standard user'}`
            )}
          </Trans>
        }
        onNext={onNext}
        nextText={t('common.buttons.continue', 'Continue')}
        disableNext={option === undefined}
        onBack={onBack}
        backText={t('common.buttons.back', 'Back')}
        loading={loadingIllustrationPlanData || loadingFundDetails}
      >
        <ContentWrapper>
          {!isEnsignUser && fundDetails && (
            <SelectableCard>
              <FundCardContent {...fundDetails} />
            </SelectableCard>
          )}
          <FormOptionsGroup name={inputName} fullWidth options={options} />
        </ContentWrapper>
      </JourneyStepLayout>
    </FormProvider>
  )
}
