import { useThemeContext } from '@retire/hooks'
import { CopyLevel, HeadlineLevel, StyleWeight, TypographyColor } from '@retire/theme'
import type { FC } from 'react'
import { Visible } from 'react-grid-system'
import { Trans } from 'react-i18next'

import { PotCurrency } from '../../Formatter/Currency'
import { IconContainer } from '../../Icon'
import { Spacer } from '../../Spacer'
import { Copy, Headline } from '../../Typography'
import { getPotIcon } from '../utils'
import {
  PotValue,
  SHidden,
  SLockedTextWrapper,
  SPotContentWrapper,
  SPotNameWrapper,
  Wrapper,
} from './presentation.styled'
import type { TVerticalPot } from './types'

export const VerticalPot: FC<TVerticalPot> = ({
  potName,
  variant,
  disabled,
  potValue,
  pendingAmount,
  monthlyAllocation,
  isLocked,
  lockedText,
  isClosed,
  closedText,
  children,
  shouldDisplayLockedText,
}) => {
  const {
    pot: {
      potColor: { disabled: disabledColor },
    },
  } = useThemeContext()
  const Icon = getPotIcon(variant)
  const LockedTextWrapper = isLocked ? SLockedTextWrapper : SHidden

  return (
    <SPotContentWrapper>
      <SPotNameWrapper>
        <Spacer bottom="mediumSmall">
          <IconContainer size="medium">
            <Icon base={disabled ? disabledColor : undefined} />
          </IconContainer>
        </Spacer>
        <Headline level={HeadlineLevel.h3} as="h2" color={disabled ? TypographyColor.neutralDark : undefined}>
          {potName}
        </Headline>
      </SPotNameWrapper>
      <Wrapper>
        {!disabled && (
          <Spacer top="medium">
            {(pendingAmount && (
              <Copy as="span" color={TypographyColor.primary}>
                <Trans
                  components={{
                    bold: (
                      <Copy
                        as="span"
                        color={TypographyColor.primary}
                        weight={StyleWeight.bold}
                        level={CopyLevel.body2}
                      />
                    ),
                  }}
                >
                  {pendingAmount}
                </Trans>
              </Copy>
            )) || <Spacer top="large" />}
            <PotValue level={HeadlineLevel.h2} as="h3" data-testid="pot-value" color={TypographyColor.neutralDark}>
              {isClosed ? closedText : <PotCurrency>{potValue}</PotCurrency>}
            </PotValue>
            <Visible md lg xl>
              <Copy as="span" level={CopyLevel.body2} color={TypographyColor.neutral}>
                {monthlyAllocation}
              </Copy>
            </Visible>
            <Visible xs sm>
              <Copy as="span" level={CopyLevel.caption} color={TypographyColor.neutral}>
                {monthlyAllocation}
              </Copy>
            </Visible>
          </Spacer>
        )}
        {children}
      </Wrapper>
      {shouldDisplayLockedText && (
        <LockedTextWrapper aria-hidden={!isLocked}>
          <Copy as="span" level={CopyLevel.body2} weight={StyleWeight.bold}>
            {lockedText}
          </Copy>
        </LockedTextWrapper>
      )}
    </SPotContentWrapper>
  )
}
