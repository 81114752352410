import { PotName } from '@retire/constants'
import { PotType } from '@retire/gql/__generated__/graphql'

type TInstructionPotsMap = {
  [key in PotName]: PotType
}

export const instructionPotTypes: TInstructionPotsMap = {
  [PotName.flexibleIncome]: PotType.FlexibleIncome,
  [PotName.laterLife]: PotType.LaterLife,
  [PotName.inheritance]: PotType.Inheritance,
  [PotName.rainyDay]: PotType.RainyDay,
}
