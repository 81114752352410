import { getTFLSAmount } from '@retire/utils/pots'

import type { TCalculateRemainingSavings } from './types'

export const calculateRemainingSavings: TCalculateRemainingSavings = ({
  valuationAmount,
  taxFreeLumpSumWithdrawalState,
  flexibleIncomeAmount,
  laterLifeAmount,
  inheritanceAmount,
  rainyDayAmount,
}) =>
  valuationAmount -
  (getTFLSAmount(valuationAmount, taxFreeLumpSumWithdrawalState) || 0) -
  (flexibleIncomeAmount || 0) -
  (laterLifeAmount || 0) -
  (inheritanceAmount || 0) -
  (rainyDayAmount || 0)
