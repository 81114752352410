import { useThemeContext } from '@retire/hooks/useThemeContext'
import type { TIconColors } from '@retire/types'
import type { FC } from 'react'

import type { TIcon } from '../../Icon/types'
import { IconTitle } from './IconTitle'

export const Checkbox: FC<TIconColors & Pick<TIcon, 'selected'>> = props => {
  const {
    colors: { primary, white },
  } = useThemeContext()
  const { selected, base = primary } = props

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <IconTitle iconType="Checkbox" />
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3Z"
        fill={selected ? base : white}
      />
      {selected && (
        <path
          d="M15 7L8.51652 13.4835C8.42843 13.5717 8.32379 13.6418 8.2086 13.6896C8.09341 13.7373 7.96993 13.7619 7.84522 13.7619C7.72051 13.7619 7.59702 13.7373 7.48183 13.6896C7.36664 13.6418 7.26201 13.5717 7.17391 13.4835L5 11.3084"
          stroke={white}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <path
        d="M3 2H17V-2H3V2ZM18 3V17H22V3H18ZM17 18H3V22H17V18ZM2 17V3H-2V17H2ZM3 18C2.44772 18 2 17.5523 2 17H-2C-2 19.7614 0.238575 22 3 22V18ZM18 17C18 17.5523 17.5523 18 17 18V22C19.7614 22 22 19.7614 22 17H18ZM17 2C17.5523 2 18 2.44771 18 3H22C22 0.238577 19.7614 -2 17 -2V2ZM3 -2C0.238577 -2 -2 0.238575 -2 3H2C2 2.44772 2.44771 2 3 2V-2Z"
        fill={base}
      />
    </svg>
  )
}
