import { withLocationRouterStateValidation } from '@retire/hocs/withLocationRouterStateValidation'
import { useFullScreenModal } from '@retire/hooks/useFullScreenModal'
import { useActivatePageOverlay } from '@retire/layout/PageLayout'

import { MoveMoneyThankYou } from '../../components'
import { LaterLifePotPeriodReview } from '../../features/ManageIncome/LaterLifePotPeriodReview/presentation'
import type { TLaterLifePotManageIncomeReviewState } from '../../features/ManageIncome/types'

export const LaterLifePotManageIncomePeriodReview =
  withLocationRouterStateValidation<TLaterLifePotManageIncomeReviewState>(
    props => {
      const { Modal: FullScreenModal, onShowModal } = useFullScreenModal()

      useActivatePageOverlay()

      return (
        <>
          <LaterLifePotPeriodReview {...props} onShowModal={onShowModal} />

          <FullScreenModal>
            <MoveMoneyThankYou />
          </FullScreenModal>
        </>
      )
    },
    ['startAge', 'monthlyIncome']
  )
